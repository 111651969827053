<mat-card appearance="outlined" [class]="survey.chatRequest ? 'chat' : survey.meetingRequest? 'meeting' : ''">
  <mat-card-header>
    <div *ngIf="!hideAvatar" mat-card-avatar>
      <inthraction-profile-image [employeeImage]="respondentImage" class="w40"></inthraction-profile-image>
    </div>
    <mat-card-title *ngIf="!hideAvatar" class="employee-name">{{respondent?.firstName}}&nbsp;{{respondent?.lastName}}</mat-card-title>
    <button *ngIf="showCommentButton && survey.responseReceived && !note" mat-icon-button class="standup-communication-btn" title="Stand-Up Communication" (click)="commentButtonClickEvent.emit()">
      <mat-icon>chat</mat-icon>
    </button>
  </mat-card-header>
  <mat-card-content *ngIf="configuration" class="display-content">
    <div class="questions">
      <div *ngIf="survey.chatRequest" title="Please stop by for a chat" class="request-icon-container">
        <mat-icon>connect_without_contact</mat-icon>
        <mat-card-subtitle>Please stop by for a chat</mat-card-subtitle>
      </div>
      <div *ngIf="survey.meetingRequest" title="Please schedule a meeting" class="request-icon-container">
        <mat-icon>perm_contact_calendar</mat-icon>
        <mat-card-subtitle>Please schedule a meeting</mat-card-subtitle>
      </div>
      <div *ngFor="let questionMap of survey.questionMaps; let i = index">
        <mat-card-subtitle>{{getStandupQuestion(configuration, questionMap)}}</mat-card-subtitle>
        <textarea readonly rows="3" cdkTextareaAutosize>{{ survey.responseReceived ? survey.feedbackArray[i] : ""}}</textarea>
      </div>
    </div>
    <div *ngIf="note" class="notes">
      <inthraction-notes
        [user]="user"
        [options]="{title:'Stand-Up Communication', enableCreate:false, enableEdit: user && user.id != survey.respondentID, enableReview: false}"
        [notes]="[note]"
        (editNoteEvent)="editStandUpNote($event)"
        (reviewNoteEvent)="reviewStandUpNote($event)"
      ></inthraction-notes>
    </div>
  </mat-card-content>
</mat-card>
