<mat-card appearance="raised" class="notes">
  <mat-card-header>
    <mat-card-title>{{options.title}}</mat-card-title>
    <button *ngIf="options.enableCreate" mat-icon-button (click)="createNoteEvent.emit($event)" class="add-note-btn" title="Add New Note">
      <mat-icon>add_box</mat-icon>
    </button>
  </mat-card-header>
  <mat-card-content>
    <ng-container *ngIf="_notes && page">
      <mat-tab-group *ngIf="_notes.length > 1" vertical [(selectedIndex)]="currentNoteIndex" mat-stretch-tabs="false">
        <mat-tab *ngIf="_notes.length > 6" disabled>
          <ng-template mat-tab-label>
            <button mat-raised-button [disabled]="pageNumber == 0" (click)="previousPage(); currentNoteIndex = 6">
              <mat-icon>keyboard_arrow_up</mat-icon>
            </button>
          </ng-template>
        </mat-tab>
        <mat-tab *ngFor="let note of page" label="{{note.createdAt | amParse: 'YYYY-MM-DDTHH:mm:ssZ' | amDateFormat: 'MMM DD, YYYY'}}">
          <div class="notes-bubble-container">
            <mat-card appearance="outlined" class="left-bubble">
              <mat-card-content>
                <ng-container *ngIf="note.options?.title">
                  <h3>{{note.options.title}}</h3>
                  <mat-card-subtitle *ngIf="note.noteType == NoteTypes.PROJECT_PRIVATE" class="private">Private</mat-card-subtitle>
                </ng-container>
                <div class="full-width right-pad-inherit">
                  <textarea matInput readonly cdkTextareaAutosize [value]="note.text" class="normal-line-height full-width"></textarea>
                </div>
              </mat-card-content>
              <mat-card-footer class="note-footer">
                <div>
                  <span>{{employeeNameMap.get(note.createdBy)}}</span>
                  <span>{{(note.updatedAt ? note.updatedAt : note.createdAt) | amParse: 'YYYY-MM-DDTHH:mm:ssZ' | amDateFormat: 'lll'}}</span>
                </div>
                <div>
                  <div *ngIf="(options.enableCreate || options.enableEdit) && note.id === noteIDToEdit">
                    <button mat-raised-button (click)="editNote(note.id)" class="note-edit-btn">Edit</button>
                  </div>
                  <div *ngIf="options.enableReview && (note.id !== noteIDToEdit || (note.id === noteIDToEdit && !(options.enableCreate || options.enableEdit)))">
                    <button mat-raised-button (click)="reviewNote(note.id)" class="note-edit-btn">View</button>
                  </div>
                  <div *ngIf="note.historys.length > 0">
                    <button mat-button (click)="openNoteHistory(note.id)">History</button>
                  </div>
                </div>
              </mat-card-footer>
            </mat-card>
            <mat-card appearance="outlined" *ngIf="note.id === noteIDToEdit && !options.disableComments" [class]="options.enableCreate ? 'left-bubble mat-card' : 'right-bubble mat-card'">
              <form (ngSubmit)="saveNoteComment(note.id, note.organizationID, commentText.value, $event);">
                <mat-card-content>
                  <mat-form-field class="full-width">
                    <mat-label>Comment</mat-label>
                    <div class="full-width right-pad-inherit">
                      <textarea matInput #commentText cdkTextareaAutosize class="full-width"></textarea>
                    </div>
                  </mat-form-field>
                </mat-card-content>
                <mat-card-actions>
                  <div class="form-action-buttons">
                    <button mat-raised-button type="submit" [disabled]="commentText.value.length <= 0">Submit</button>
                  </div>
                </mat-card-actions>
              </form>
            </mat-card>
            <ng-container *ngIf="note.comments && note.comments.length">
              <ng-container *ngFor="let comment of note.comments">
                <mat-card appearance="outlined" [class]="comment.createdBy === note.createdBy ? 'left-bubble mat-card' : 'right-bubble mat-card'">
                  <mat-card-content>
                    <div class="full-width right-pad-inherit">
                      <textarea matInput readonly cdkTextareaAutosize [value]="comment.comment" class="normal-line-height full-width"></textarea>
                    </div>
                  </mat-card-content>
                  <mat-card-footer>
                    <span>{{employeeNameMap.get(comment.createdBy)}}</span>
                    <span>{{comment.createdAt | amParse: 'YYYY-MM-DDTHH:mm:ssZ' | amDateFormat: 'lll'}}</span>
                  </mat-card-footer>
                </mat-card>
              </ng-container>
            </ng-container>
          </div>
        </mat-tab>
        <mat-tab *ngIf="_notes.length > 6" disabled>
          <ng-template mat-tab-label>
            <button mat-raised-button [disabled]="!hasNextPage()" (click)="nextPage(); currentNoteIndex = 1">
              <mat-icon>keyboard_arrow_down</mat-icon>
            </button>
          </ng-template>
        </mat-tab>
      </mat-tab-group>
      <ng-container *ngIf="_notes.length == 1">
        <ng-container *ngFor="let note of page">
          <div class="notes-bubble-container">
            <mat-card appearance="outlined" class="left-bubble">
              <mat-card-content>
                <ng-container *ngIf="note.options?.title">
                  <h3>{{note.options.title}}</h3>
                  <mat-card-subtitle *ngIf="note.noteType == NoteTypes.PROJECT_PRIVATE" class="private">Private</mat-card-subtitle>
                </ng-container>
                <div class="full-width right-pad-inherit">
                  <textarea matInput readonly cdkTextareaAutosize [value]="note.text" class="normal-line-height full-width"></textarea>
                </div>
              </mat-card-content>
              <mat-card-footer class="note-footer">
                <div>
                  <span>{{employeeNameMap.get(note.createdBy)}}</span>
                  <span>{{(note.updatedAt ? note.updatedAt : note.createdAt) | amParse: 'YYYY-MM-DDTHH:mm:ssZ' | amDateFormat: 'lll'}}</span>
                </div>
                <div>
                  <div *ngIf="(options.enableCreate || options.enableEdit) && note.id === noteIDToEdit">
                    <button mat-raised-button (click)="editNote(note.id)" class="note-edit-btn">Edit</button>
                  </div>
                  <div *ngIf="options.enableReview && (note.id !== noteIDToEdit || (note.id === noteIDToEdit && !(options.enableCreate || options.enableEdit)))">
                    <button mat-raised-button (click)="reviewNote(note.id)" class="note-edit-btn">View</button>
                  </div>
                  <div *ngIf="note.historys.length > 0">
                    <button mat-button (click)="openNoteHistory(note.id)">History</button>
                  </div>
                </div>
              </mat-card-footer>
            </mat-card>
            <mat-card appearance="outlined" *ngIf="note.id === noteIDToEdit && !options.disableComments" [class]="options.enableCreate ? 'left-bubble mat-card' : 'right-bubble mat-card'">
              <form (ngSubmit)="saveNoteComment(note.id, note.organizationID, commentText.value, $event);">
                <mat-card-content>
                  <mat-form-field class="full-width">
                    <mat-label>Comment</mat-label>
                    <div class="full-width right-pad-inherit">
                      <textarea matInput #commentText cdkTextareaAutosize class="full-width"></textarea>
                    </div>
                  </mat-form-field>
                </mat-card-content>
                <mat-card-actions>
                  <div class="form-action-buttons">
                    <button mat-raised-button type="submit" [disabled]="commentText.value.length <= 0">Submit</button>
                  </div>
                </mat-card-actions>
              </form>
            </mat-card>
            <ng-container *ngIf="note.comments && note.comments.length">
              <ng-container *ngFor="let comment of note.comments">
                <mat-card appearance="outlined" [class]="comment.createdBy === note.createdBy ? 'left-bubble mat-card' : 'right-bubble mat-card'">
                  <mat-card-content>
                    <div class="full-width right-pad-inherit">
                      <textarea matInput readonly cdkTextareaAutosize [value]="comment.comment" class="normal-line-height full-width"></textarea>
                    </div>
                  </mat-card-content>
                  <mat-card-footer>
                    <span>{{employeeNameMap.get(comment.createdBy)}}</span>
                    <span>{{comment.createdAt | amParse: 'YYYY-MM-DDTHH:mm:ssZ' | amDateFormat: 'lll'}}</span>
                  </mat-card-footer>
                </mat-card>
              </ng-container>
            </ng-container>
          </div>
        </ng-container>
      </ng-container>
    </ng-container>
  </mat-card-content>
</mat-card>
