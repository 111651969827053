<inthraction-page-header><h1 class="page-title">insp<span class='inthraction-highlight'>HR</span>ation Board</h1><span class="toolbar-spacer"></span><mat-slide-toggle *ngIf="isManager" (change)="toggleTeam($event)">My Team</mat-slide-toggle></inthraction-page-header>
<mat-tab-group (selectedTabChange)="changeTab($event)" [selectedIndex]="indexMap.get(selectedType)" mat-stretch-tabs="false">
  <mat-tab *ngFor="let option of tabOptions" label="{{tabOptionLabels[option]}}">
    <div *ngIf="employeeIDs" class="insphraction-leader-board-wrapper">
      <mat-grid-list [cols]="insphrations.length + 2" rowHeight="130px">
        <ng-container *ngFor="let id of employeeIDs; let i = index">
          <mat-grid-tile [class]="'profile-tile' + (i%2 === 0 ? '' : ' dark-row')" colspan="2">
            <inthraction-profile-image compact [employeeImage]="employeeImageMap.get(id)" [matBadge]="getScoreTotal(id) || 0" matBadgeSize="large" matBadgeColor="primary" matBadgeDescription="Number of inspHRations received" matBadgeOverlap="true"></inthraction-profile-image>
            <mat-card-subtitle>{{employeeMap.get(id).firstName}} {{employeeMap.get(id).lastName}}</mat-card-subtitle>
          </mat-grid-tile>
          <mat-grid-tile *ngFor="let insphration of insphrations" [class]="'insphration-tile' + (i%2 === 0 ? '' : ' dark-row') + (getScore(''+id+'_'+insphration.id) ? ' pointer':'')" (click)="getScore(''+id+'_'+insphration.id) ? openInspHRationResponseList(id, insphration, selectedType) : null">
            <inthraction-employee-insphration *ngIf="getScore(''+id+'_'+insphration.id)" [count]="getScore(''+id+'_'+insphration.id) ? getScore(''+id+'_'+insphration.id) : 0" [inspHRation]="insphration" matTooltip="{{insphration.overrideTitle}} : {{insphration.description}}"></inthraction-employee-insphration>
          </mat-grid-tile>
        </ng-container>
      </mat-grid-list>
    </div>

    <inthraction-loading className="insphraction-leader-board-wrapper" [initDone]="employeeIDs ? true : false"></inthraction-loading>

  </mat-tab>
</mat-tab-group>
