<h1>Objective Assignments: {{domain}} - {{title}}</h1>
<table *ngIf="tableData" mat-table [dataSource]="tableData" class="mat-elevation-z8">

  <ng-container matColumnDef="department">
    <th mat-header-cell *matHeaderCellDef> Department</th>
    <td mat-cell *matCellDef="let element"> {{element.department}} </td>
  </ng-container>

  <ng-container matColumnDef="manager">
    <th mat-header-cell *matHeaderCellDef> Manager</th>
    <td mat-cell *matCellDef="let element"> {{element.manager}} </td>
  </ng-container>

  <ng-container matColumnDef="name">
    <th mat-header-cell *matHeaderCellDef> Name</th>
    <td mat-cell *matCellDef="let element"> {{element.name}} </td>
  </ng-container>

  <ng-container matColumnDef="domain">
    <th mat-header-cell *matHeaderCellDef> Domain</th>
    <td mat-cell *matCellDef="let element"> {{element.domain}} </td>
  </ng-container>

  <ng-container matColumnDef="objectiveType">
    <th mat-header-cell *matHeaderCellDef> Type</th>
    <td mat-cell *matCellDef="let element"> {{element.objectiveType}} </td>
  </ng-container>

  <ng-container matColumnDef="objectiveTitle">
    <th mat-header-cell *matHeaderCellDef> Title</th>
    <td mat-cell *matCellDef="let element"> {{element.objectiveTitle}} </td>
  </ng-container>

  <ng-container matColumnDef="focus">
    <th mat-header-cell *matHeaderCellDef> Focus</th>
    <td mat-cell *matCellDef="let element"> {{element.focus}} </td>
  </ng-container>

  <ng-container matColumnDef="priority">
    <th mat-header-cell *matHeaderCellDef> Priority</th>
    <td mat-cell *matCellDef="let element"> {{element.timeframe}} </td>
  </ng-container>

  <ng-container matColumnDef="score">
    <th mat-header-cell *matHeaderCellDef> Score</th>
    <td mat-cell *matCellDef="let element"> {{element.score}} </td>
  </ng-container>

  <ng-container matColumnDef="rank">
    <th mat-header-cell *matHeaderCellDef> Rank</th>
    <td mat-cell *matCellDef="let element"> {{OBJECTIVE_SURVEY_RESPONSE_LABELS[element.rank]}} </td>
  </ng-container>

  <ng-container matColumnDef="start">
    <th mat-header-cell *matHeaderCellDef> Start Date</th>
    <td mat-cell *matCellDef="let element"> {{element.startDate | amDateFormat:'l'}} </td>
  </ng-container>

  <ng-container matColumnDef="end">
    <th mat-header-cell *matHeaderCellDef> End Date</th>
    <td mat-cell *matCellDef="let element"> {{element.endDate | amDateFormat:'l'}} </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
