export enum ShoutOutIconLabels {
  ACCOUNTABILITY = "Accountability",
  CHANGE_CHAMPION = "Change Champion",
  COMMUNICATION = "Communication",
  COURAGE = "Courage",
  CUSTOMER_EXPERIENCE = "Customer Experience",
  EMPATHY = "Empathy",
  EMPOWERMENT = "Empowerment",
  FUN = "Fun",
  GROWTH_MINDSET = "Growth Mindset",
  HUMILITY = "Humility",
  INCLUSION = "Inclusion",
  INITIATIVE = "Initiative",
  INNOVATION = "Innovation",
  INTEGRITY = "Integrity",
  LEADERSHIP = "Leadership",
  QUALITY = "Quality",
  RESPECT = "Respect",
  SAFETY = "Safety",
  TEAMWORK = "Teamwork",
  VOLUNTEER = "Volunteer",
  PASSION = "Passion",
  CREATING = "Creating"
}

export enum InspHRationTypeLabels {
  WEEK = "Week",
  MONTH = "Month",
  QTR = "Quarter",
  YEAR = "Year"
}
