import { CADENCE_TYPE_LABELS, OBJECTIVE_TYPE_LABELS, ProgressStatusLabels } from "@inthraction/labels";

export type ObjectiveType = keyof typeof OBJECTIVE_TYPE_LABELS;

export const OBJECTIVE_TYPES = Object.keys(OBJECTIVE_TYPE_LABELS) as ObjectiveType[];

export type CadenceType = keyof typeof CADENCE_TYPE_LABELS;

export const CADENCE_TYPES = Object.keys(CADENCE_TYPE_LABELS) as CadenceType[];

export type ProgressStatus = keyof typeof ProgressStatusLabels;

export const ProgressStatusTypes = Object.keys(ProgressStatusLabels) as ProgressStatus[];

export enum ObjectiveTypeCodes {
  QUANTIFIABLE = "QUANTIFIABLE",
  DEFINED_MANAGER = "DEFINED_MANAGER",
  DEFINED_SELF = "DEFINED_SELF",
  DEFINED_EXTERNAL = "DEFINED_EXTERNAL",
  DEFINED = "DEFINED"
}

export enum UserDefinedObjectiveDomainTypes {
  ORG_DEFINED = "ORG_DEFINED",
  CONSULTANT_DEFINED = "CONSULTANT_DEFINED"
}

export enum DealHRShiptObjectiveDomainTypes {
  SALES = "SALES",
  SERVICE = "SERVICE",
  PARTS = "PARTS",
  ADMIN = "ADMIN"
}
