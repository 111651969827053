import { NoteComment } from "./note-comment";
import { NoteHistory } from "./note-history";
import { CreateNoteMutation, GetNoteQuery } from "@inthraction/services";
import { NoteDataMapper } from "@inthraction/data-mappers/notes/NoteDataMapper";

export class Note implements NoteDataMapper {
  id;
  __typename = "Note";
  organizationID;
  objectID;
  createdAt;
  createdBy;
  updatedAt?;
  updatedBy?;
  text;
  noteType;
  options?;
  comments?: NoteComment[] | null;
  historys?: NoteHistory[] | null;

  constructor() {
  }

  static constructFromCreateNoteMutation(createNoteMutation: CreateNoteMutation | GetNoteQuery): Note {
    const note = new Note();
    note.id = createNoteMutation.id;
    note.organizationID = createNoteMutation.organizationID;
    note.objectID = createNoteMutation.objectID;
    note.createdAt = createNoteMutation.createdAt;
    note.createdBy = createNoteMutation.createdBy;
    note.updatedAt = createNoteMutation.updatedAt;
    note.updatedBy = createNoteMutation.updatedBy;
    note.text = createNoteMutation.text;
    note.noteType = createNoteMutation.noteType;
    note.options = Note.convertOptionsToObject(createNoteMutation.options);
    note.comments = createNoteMutation.comments.items.sort((b, a) => a.createdAt < b.createdAt ? -1 : a.createdAt > b.createdAt ? 1 : 0);
    note.historys = createNoteMutation.historys.items.sort((b, a) => a.createdAt > b.createdAt ? -1 : a.createdAt < b.createdAt ? 1 : 0);
    return note;
  }

  private static convertOptionsToObject(options: any): any {
    if (options && (typeof options) === "string") {
      return JSON.parse(options);
    } else {
      return options;
    }
  }

}
