import { Directive, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from "@angular/core";
import { BehaviorSubject, Subscription } from "rxjs";
import { EmployeeImpl } from "@inthraction/data-models";
import { AuthService, EmployeeService, OrganizationService } from "@inthraction/services";

@Directive({ selector: "[inthractionShowSuper]" })
export class ShowSuperDirective implements OnInit, OnDestroy {

  condition: boolean;
  private isSuperUserSubject = new BehaviorSubject<boolean>(false);
  public isSuperUser = this.isSuperUserSubject.asObservable();
  private superAdminSubscription: Subscription;

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private organizationService: OrganizationService,
    private employeeService: EmployeeService,
    private authService: AuthService
  ) {
  }

  ngOnDestroy(): void {
    if (this.superAdminSubscription) {
      this.superAdminSubscription.unsubscribe();
    }
  }

  @Input() set inthractionShowSuper(condition: boolean) {
    this.condition = condition;
  }

  async ngOnInit(): Promise<void> {
    const user = await this.employeeService.getCurrentEmployee();
    let isSuperAdmin = false;
    const employee: EmployeeImpl = new EmployeeImpl(user);
    if (user && employee && employee.isAdmin()) {
      isSuperAdmin = await this.employeeService.isSuper();
    }
    this.isSuperUserSubject.next(isSuperAdmin);

    this.superAdminSubscription = this.isSuperUser.subscribe(
      (isSuperUser) => {
        this.viewContainer.clear();
        if (isSuperUser && this.condition || !isSuperUser && !this.condition) {
          this.viewContainer.createEmbeddedView(this.templateRef);
        }
      }
    );
  }

}
