import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Organization } from "@inthraction/data-models";
import { ObjectiveService, OrganizationService } from "@inthraction/services";
import { ToastrService } from "ngx-toastr";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MatButton } from "@angular/material/button";
import { SiteCodes, UserDefinedObjectiveDomainTypes } from "@inthraction/codes";

@Component({
  selector: "inthraction-edit-organization-dialog",
  templateUrl: "./edit-organization-dialog.component.html",
  styleUrls: ["./edit-organization-dialog.component.scss"]
})
export class EditOrganizationDialogComponent implements OnInit {

  static ExcludeObjectives: string[] = Object.keys(UserDefinedObjectiveDomainTypes);

  SiteOptions =  Object.keys(SiteCodes);

  addMode: boolean;
  organization: Organization;
  organizationFG = new FormGroup({
    name: new FormControl("", [Validators.required]),
    domains: new FormControl("", [Validators.required]),
    site: new FormControl("", [Validators.required]),
    disabled: new FormControl(false)
  });

  constructor(
    public dialogRef: MatDialogRef<EditOrganizationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private organizationService: OrganizationService,
    private objectiveService: ObjectiveService,
    private toastrService: ToastrService
  ) {
    this.addMode = this.data.addMode;
    if (this.addMode) {
      this.organization = new Organization();
      this.organization.domains = [];
    } else {
      const organizationCopy = new Organization();
      organizationCopy.domains = [];
      organizationCopy.domains.push(...this.data.organization.domains);
      organizationCopy.id = this.data.organization.id;
      organizationCopy.orgName = this.data.organization.orgName;
      organizationCopy.site = this.data.organization.site;
      organizationCopy.disabled = this.data.organization.disabled;
      this.organization = organizationCopy;
      this.organizationFG.get("name").setValue(this.organization.orgName);
      this.organizationFG.get("domains").setValue(this.organization.domains.toString());
      this.organizationFG.get("site").setValue(this.organization.site);
      this.organizationFG.get("disabled").setValue(this.organization.disabled);
    }
  }

  ngOnInit(): void {
  }

  onCancelClick(): void {
    this.dialogRef.close();
  }

  async onSaveClick(value: any, submitBtn: MatButton | HTMLButtonElement): Promise<void> {
    submitBtn.disabled = true;
    try {
      this.organization.site = value.site;
      this.organization.orgName = value.name;
      this.organization.domains = value.domains.split(",");
      this.organization.disabled = value.disabled;
      // Check for duplicate domains
      for (const domain of this.organization.domains) {
        if (!await this.validateUniqueDomain(domain, this.organization.id)) {
          this.organization.domains = this.organization.domains.filter(item => item !== domain);
          this.toastrService.warning(`Removing ${domain}, this domain exists in another organization.`);
        }
      }
      if (this.organization.domains.length) {
        if (this.addMode) {
          let newOrganization = null;
          if (this.organization.orgName && this.organization.domains && this.organization.domains.length && this.organization.site) {
            newOrganization = await this.organizationService.createOrganization(this.organization);
          }
          if (newOrganization) {
            await this.assignDefaultObjectivesToOrganization(newOrganization);
            this.toastrService.success("New organization created");
            this.dialogRef.close({ organization: this.organization });
          } else {
            this.toastrService.error("Failed to create new organization");
            this.updateDomains();
            submitBtn.disabled = this.organizationFG.invalid;
          }
        } else {
          await this.organizationService.updateOrganization(this.organization);
          this.toastrService.success("Organization updated");
          this.dialogRef.close({ organization: this.organization });
        }
      } else {
        // Error no domains set
        this.updateDomains();
        submitBtn.disabled = this.organizationFG.invalid;
      }
    } catch (err) {
      submitBtn.disabled = false;
      this.toastrService.error("Failed to create or update organization");
      throw err;
    }
  }

  async validateUniqueDomain(domain: string, organizationID?: string): Promise<boolean> {
    let valid = false;
    const organizations = await this.organizationService.getOrganizationsByDomain(domain);
    valid = organizations?.length <= 0;
    if (!valid && organizationID && organizations.length === 1) {
      // Valid if for the same organization
      valid = organizations[0].id === organizationID;
    }
    return valid;
  }

  public hasError = (form: FormGroup, errorName: string, controlName?: string) => {
    if (controlName) {
      if ("null" === errorName) {
        return !(!form.controls[controlName].errors);
      }
      return form.controls[controlName].hasError(errorName);
    }
    if ("null" === errorName) {
      return !(!form.errors);
    }
    return form.hasError(errorName);
  };

  public isAValidUrl(value: string): boolean {
    try {
      const url = new URL(`http:\\\\${value}`);
      return true;
    } catch (TypeError) {
      return false;
    }
  }

  updateDomains() {
    this.organization.domains = this.organization.domains
      .map(i => i.toLowerCase()
        .replace(/\s/g, "")
        .replace(/[^0-9a-zA-Z_.-]/g, "")
        .replace(/[\\]/g, ""))
      .filter(i => i.length > 0 && this.isAValidUrl(i.replace(/\s/g, "")))
      .reduce((unique, item) => unique.includes(item) ? unique : [...unique, item], []);
    this.organizationFG.get("domains").setValue(this.organization.domains.toString());
  }

  private async assignDefaultObjectivesToOrganization(newOrganization: Organization) {
    const allCoreObjectives = await this.objectiveService.getObjectivesMemoize();
    for (const objective of allCoreObjectives) {
      if (!EditOrganizationDialogComponent.ExcludeObjectives.includes(objective.domain.key)) {
        await this.objectiveService.createNewOrganizationObjective({
          organizationID: newOrganization.id,
          organizationObjectiveObjectiveId: objective.id,
          enabled: true
        });
      }
    }
  }
}
