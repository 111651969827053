<h1 mat-dialog-title>Edit User</h1>
<mat-toolbar class="emp-tool-bar">

  <button mat-button (click)="employee.disabled? enableEmployeeClick() : disableEmployeeClick()" [disabled]="isEditingSelf" title="{{employee.disabled? 'Enable' : 'Disable'}} User">
    <mat-icon>{{employee.disabled ? 'person' : 'person_outline'}}</mat-icon>
    {{employee.disabled ? 'Enable' : 'Disable'}}
  </button>

  <mat-button-toggle-group #group="matButtonToggleGroup" aria-label="Profile Options" name="profile-options" [(value)]="selectedToggle">
    <mat-button-toggle value="profile">Profile</mat-button-toggle>
    <mat-button-toggle value="image" [disabled]="employee.disabled">Image</mat-button-toggle>
    <mat-button-toggle value="manager" [disabled]="employee.disabled">Manager</mat-button-toggle>
  </mat-button-toggle-group>
</mat-toolbar>
<div *ngIf="group.value == 'profile'">
  <form [formGroup]="editEmployeeFG" (ngSubmit)="onSaveClick(editEmployeeFG.value)">
    <div mat-dialog-content>
      <mat-form-field class="full-width">
        <mat-label>Email</mat-label>
        <input matInput #emailFC formControlName="email" placeholder="jane.doe@example.com" type="email"
               (input)="emailFC.value = emailFC.value.toLowerCase()" (change)="validateUpdatedEmail()">
        <mat-error *ngIf="hasError(editEmployeeFG, 'required', 'email')">Email is required</mat-error>
        <mat-error *ngIf="hasError(editEmployeeFG, 'email', 'email')">Must be a valid email address</mat-error>
        <mat-error *ngIf="hasError(editEmployeeFG, 'duplicate', 'email')">Email address is already used by a different employee or organization.</mat-error>
        <mat-error *ngIf="hasError(editEmployeeFG, 'domain', 'email')">Email address must match a domain that belongs to your organization.</mat-error>
      </mat-form-field>
      <mat-form-field class="half-width left-padding">
        <mat-label>First Name</mat-label>
        <input matInput formControlName="firstName">
        <mat-error *ngIf="hasError(editEmployeeFG, 'required', 'firstName')">First Name is required</mat-error>
      </mat-form-field>
      <mat-form-field class="half-width right-padding">
        <mat-label>Last Name</mat-label>
        <input matInput formControlName="lastName">
        <mat-error *ngIf="hasError(editEmployeeFG, 'required', 'lastName')">Last Name is required</mat-error>
      </mat-form-field>
      <mat-form-field class="half-width left-padding">
        <mat-label>Title</mat-label>
        <input matInput formControlName="title">
        <mat-error *ngIf="hasError(editEmployeeFG, 'required', 'title')">Title is required</mat-error>
      </mat-form-field>
      <mat-form-field class="half-width right-padding">
        <mat-label>Department</mat-label>
        <mat-select formControlName="department">
          <mat-option *ngFor="let departmentType of departmentTypes" [value]="departmentType">{{departmentTypeLabels[departmentType]}}</mat-option>
        </mat-select>
        <mat-error *ngIf="hasError(editEmployeeFG, 'required', 'department')">Department is required</mat-error>
      </mat-form-field>
      <mat-form-field class="half-width left-padding">
        <mat-label>Date Hired</mat-label>
        <input matInput formControlName="dateHired" [matDatepicker]="hireDate">
        <mat-datepicker-toggle [for]="hireDate" matSuffix></mat-datepicker-toggle>
        <mat-datepicker #hireDate></mat-datepicker>
      </mat-form-field>
      <mat-form-field class="half-width right-padding">
        <mat-label>Position Start Date</mat-label>
        <input matInput formControlName="positionStartDate" [matDatepicker]="positionDate">
        <mat-datepicker-toggle [for]="positionDate" matSuffix></mat-datepicker-toggle>
        <mat-datepicker #positionDate></mat-datepicker>
      </mat-form-field>
      <mat-form-field floatLabel="always" appearance="outline" class="slide-toggle-form-field full-width">
        <mat-label>Administrator</mat-label>
        <mat-slide-toggle formControlName="admin" color="primary"></mat-slide-toggle>
        <input matInput hidden height="0" style="height: 0" />
        <mat-hint>Designates an Administrator user, allowing abilities such as add / edit users and OrgCharts</mat-hint>
      </mat-form-field>
      <mat-form-field floatLabel="always" appearance="outline" class="slide-toggle-form-field full-width">
        <mat-label>HR</mat-label>
        <mat-slide-toggle formControlName="hr" color="primary"></mat-slide-toggle>
        <input matInput hidden height="0" style="height: 0" />
        <mat-hint>Designates HR user, allowing access to all User's Interaction Data</mat-hint>
      </mat-form-field>
      <mat-form-field floatLabel="always" appearance="outline" class="slide-toggle-form-field full-width">
        <mat-label>Project Manager</mat-label>
        <mat-slide-toggle formControlName="pm" color="primary"></mat-slide-toggle>
        <input matInput hidden height="0" style="height: 0" />
        <mat-hint>Designates Project Manager user, allowing Project Creation abilities</mat-hint>
      </mat-form-field>
    </div>
    <div mat-dialog-actions class="no-wrap">
      <button *ngIf="!employee.authId && !employee.disabled" (click)="sendInvite()" mat-raised-button class="right" type="button">{{employee.inviteSent ? 'Resend' : 'Send'}} Account Invite</button>
      <mat-icon *ngIf="employee.authId && !employee.disabled && !employee.validEmailToken" class="right normal-pointer" color="primary" title="Calendar Not Authorized">event_busy</mat-icon>
      <mat-icon *ngIf="employee.authId && !employee.disabled && employee.validEmailToken" class="right normal-pointer" title="Calendar Authorized">event_available</mat-icon>
      <div style="flex-grow: 2"></div>
      <button (click)="onCancelClick()" mat-button type="button">Cancel</button>
      <button *ngIf="!employee.disabled" cdkFocusInitial mat-raised-button color="primary" type="submit" [disabled]="editEmployeeFG.invalid">Save</button>
    </div>
  </form>
</div>
<div *ngIf="group.value == 'image'">
  <div mat-dialog-content>
    <!-- Display Image -->
    <inthraction-profile-image *ngIf="showPhoto" [employeeImage]="employeeImage"></inthraction-profile-image>
    <!-- Photo Picker -->
    <input *ngIf="!showPhoto" type="file" id="imageUpload" name="imageUpload" accept="image/png, image/jpeg" (change)="imageSelected($event)" />
  </div>
  <div mat-dialog-actions>
    <button *ngIf="editingPhoto" (click)="cancelEditPhoto()" mat-button type="button">
      <mat-icon>cancel</mat-icon>&nbsp;Cancel Upload
    </button>
    <button *ngIf="editingPhoto" (click)="uploadImage()" mat-button type="button" [disabled]="!selectedFile" color="accent">
      <mat-icon>cloud_upload</mat-icon>
      Upload
    </button>
    <button *ngIf="showPhoto" (click)="editPhoto()" mat-button type="button">
      <mat-icon>cloud_upload</mat-icon>Change Photo
    </button>
  </div>
</div>
<div *ngIf="group.value == 'manager'">
  <div mat-dialog-content *ngIf="manager">
    <mat-form-field class="full-width">
      <mat-label>Manager</mat-label>
      <input matInput readonly value="{{manager.firstName}} {{manager.lastName}}">
    </mat-form-field>
    <mat-form-field class="full-width">
      <mat-label>Manager Email</mat-label>
      <input matInput readonly value="{{manager.email}}">
    </mat-form-field>
  </div>
  <div mat-dialog-actions *ngIf="manager">
    <div style="flex-grow: 2"></div>
    <button (click)="changeManager()" mat-button type="button">Change Manager</button>
  </div>
  <form *ngIf="!manager" [formGroup]="changeManagerFG" (ngSubmit)="onChangeManagerClick(changeManagerFG.value)">
    <div mat-dialog-content>
      <mat-form-field class="full-width">
        <mat-label>Search for Manager by Email</mat-label>
        <mat-autocomplete #emailSearch="matAutocomplete">
          <mat-option *ngFor="let email of filteredManagerEmails | async" [value]="email">{{email}}</mat-option>
        </mat-autocomplete>
        <input matInput lowercase [matAutocomplete]="emailSearch" placeholder="jane.doe@example.com" #emailSearchFC formControlName="email"
               (input)="emailSearchFC.value = emailSearchFC.value.toLowerCase()" (ngModelChange)="managerEmailSelected($event)">
        <mat-error *ngIf="hasError(changeManagerFG, 'required', 'email')">Email is required</mat-error>
        <mat-error *ngIf="hasError(changeManagerFG, 'email', 'email')">Must be a valid email address</mat-error>
        <mat-error *ngIf="hasError(changeManagerFG, 'manager', 'email')">Please enter an email address that is different than the selected Manager.</mat-error>
        <mat-error *ngIf="hasError(changeManagerFG, 'domain', 'email')">Email address must match a domain that belongs to your organization.</mat-error>
        <mat-error *ngIf="hasError(changeManagerFG, 'disabled', 'email')">An account matching this email already exists and is currently disabled.</mat-error>
      </mat-form-field>
    </div>
    <div mat-dialog-actions>
      <button *ngIf="!employee.disabled" mat-button color="primary" type="button" class="btn-right" (click)="removeManagerClick()">Remove Manager</button>
      <div *ngIf="!employee.disabled" style="flex-grow: 2"></div>
      <button (click)="onCancelManagerChangeClick()" mat-button type="button">Cancel</button>
      <button *ngIf="!employee.disabled" cdkFocusInitial mat-raised-button color="primary" type="submit" [disabled]="changeManagerFG.invalid">Save</button>
    </div>
  </form>
</div>
