import { Component, NgZone, OnDestroy, OnInit } from "@angular/core";
import { EmployeeImpl, Organization } from "@inthraction/data-models";
import { EmployeeService, OrganizationService } from "@inthraction/services";
import { MatDialog } from "@angular/material/dialog";
import { Subscription } from "rxjs";
import { EditOrganizationDialogComponent } from "../../../components/support/edit-organization-dialog/edit-organization-dialog.component";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import moment from "moment";

@Component({
  selector: "inthraction-manage-organizations",
  templateUrl: "./manage-organizations.component.html",
  styleUrls: ["./manage-organizations.component.scss"]
})
export class ManageOrganizationsComponent implements OnInit, OnDestroy {

  organizations: Organization[];
  displayedColumns: string[] = ["orgName", "domains", "site", "disabled", "edit", "configurations", "inspHRations", "objectives", "users", "resend", "consultants"];
  private subscriptions: Subscription[] = [];

  constructor(
    private organizationService: OrganizationService,
    private employeeService: EmployeeService,
    private toastr: ToastrService,
    public dialog: MatDialog,
    private ngZone: NgZone,
    private router: Router
  ) {
  }

  ngOnDestroy(): void {
    if (this.subscriptions.length) {
      for (const sub of this.subscriptions) {
        sub.unsubscribe();
      }
    }
  }

  async ngOnInit() {
    await this.initializeOrganization();
  }

  async initializeOrganization() {
    this.organizations = await this.organizationService.getOrganizations();
  }

  editOrganization(organization: Organization) {

    const dialog = this.dialog.open(EditOrganizationDialogComponent, {
      width: "600px",
      data: { organization }
    });

    this.subscriptions.push(
      dialog.afterClosed().subscribe(async result => {
        if (result) {
          await this.initializeOrganization();
        }
      }));
  }

  async editOrganizationConfigurations(organization: Organization) {
    await this.ngZone.run(async () => {
      await this.router.navigate([`super/manage-configurations/${organization.id}`]);
    });
  }

  addOrganization() {

    const dialog = this.dialog.open(EditOrganizationDialogComponent, {
      width: "600px",
      data: { addMode: true }
    });

    this.subscriptions.push(
      dialog.afterClosed().subscribe(async result => {
        if (result) {
          await this.initializeOrganization();
        }
      }));
  }

  async editOrganizationEmployees(organization: Organization) {
    await this.ngZone.run(async () => {
      await this.router.navigate([`super/manage-workforce/${organization.id}`]);
    });
  }

  async editOrganizationConsultants(organization: Organization) {
    await this.ngZone.run(async () => {
      await this.router.navigate([`super/manage-consultants/${organization.id}`]);
    });
  }

  async editOrganizationinspHRations(organization: Organization) {
    await this.ngZone.run(async () => {
      await this.router.navigate([`super/manage-insphrations/${organization.id}`]);
    });
  }

  async editOrganizationObjectives(organization: Organization) {
    await this.ngZone.run(async () => {
      await this.router.navigate([`super/manage-objective-configuration/${organization.id}`]);
    });
  }

  async resendInvites(organization: Organization) {
    for (const employee of (await this.employeeService.getEmployeesForOrganizationByOrganization({organizationID:organization.id, includeDisabled:true})).map(emp => new EmployeeImpl(emp)).sort((a, b) => {
      return `${a.disabled} ${a.lastName}, ${a.firstName}`.localeCompare(`${b.disabled} ${b.lastName}, ${b.firstName}`);
    }).filter(e => !e.authId && !e.disabled && (!e.inviteSent || e.inviteSent < moment().subtract(24, 'hours').toISOString()))) {
      try {
        await this.employeeService.sendAccountInvite(employee);
      } catch (err) {
        this.toastr.warning(`Account Invite Failed to Send for ${employee.firstName } ${employee.lastName}`);
        console.error(err);
      }
    }
    this.toastr.success("Account Invite Sent");

  }

}
