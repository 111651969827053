import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { CADENCE_TYPE_LABELS, OBJECTIVE_TYPE_LABELS } from "@inthraction/labels";
import { Employee, QuantifiableObjectiveAssignment } from "@inthraction/data-models";
import { ObjectiveResponseComponent } from "../objective-response/objectiveResponse.component";
import { MatDialog } from "@angular/material/dialog";
import { EmployeeService } from "@inthraction/services";
import { ObjectiveScoreChartOptions } from "../objective-score-chart/objective-score-chart.component";
import * as moment from "moment";

@Component({
  selector: "inthraction-quantifiable-objective",
  templateUrl: "./quantifiable-objective.component.html",
  styleUrls: ["./quantifiable-objective.component.scss"]
})
export class QuantifiableObjectiveComponent implements OnInit {

  @Input() objectiveAssignment: QuantifiableObjectiveAssignment;
  @Input() employee: Employee;
  @Input() options: QuantifiableObjectiveAssignmentOptions = {};
  @Output() openEdit: EventEmitter<any> = new EventEmitter();

  readonly objectiveCadenceTypeLabels = CADENCE_TYPE_LABELS;
  readonly OBJECTIVE_TYPE_LABELS = OBJECTIVE_TYPE_LABELS;
  expired: boolean = false;

  constructor(
    public dialog: MatDialog,
    private employeeService: EmployeeService
  ) {
  }

  ngOnInit(): void {
    if (this.objectiveAssignment?.endDate && this.objectiveAssignment?.endDate < moment().format("YYYY-MM-DD")) {
      this.expired = true;
    }
  }

  openEditClick(editId: string): void {
    this.openEdit.emit(editId);
  }

  async openObjectiveSurveyResponse(objectiveResponseID: any, employeeID: any) {
    this.dialog.open(ObjectiveResponseComponent, {
      width: "600px",
      data: {
        objectiveResponseID,
        employee: employeeID ? await this.employeeService.getEmployeeByIDMemoize(employeeID) : this.employee
      }
    });
  }
}

export interface QuantifiableObjectiveAssignmentOptions extends ObjectiveScoreChartOptions {
  managerAccess?: boolean;
  hideChart?: boolean;
}
