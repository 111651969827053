import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { EventSurvey, Survey } from "@inthraction/data-models";
import { EmployeeService } from "@inthraction/services";
import { OBJECTIVE_SURVEY_RESPONSE_TYPES, SurveyTypeCodes } from "@inthraction/codes";
import { OBJECTIVE_SURVEY_RESPONSE_LABELS } from "@inthraction/labels";

@Component({
  selector: "inthraction-survey-comments-dialog",
  templateUrl: "./survey-comments-dialog.component.html",
  styleUrls: ["./survey-comments-dialog.component.scss"]
})
export class SurveyCommentsDialogComponent implements OnInit {

  readonly surveyResponseOptionsDisplay = EventSurvey.SURVEY_RESPONSE_OPTIONS_DISPLAY;
  readonly objectiveResponseOptionsDisplay: string[] = [];
  surveyNamesMap: Map<string, string>;
  isEventSurvey = false;

  constructor(public dialogRef: MatDialogRef<SurveyCommentsDialogComponent>,
              private employeeService: EmployeeService,
              @Inject(MAT_DIALOG_DATA) public surveys: Survey[]) {

    this.objectiveResponseOptionsDisplay.push("");
    for (const key of OBJECTIVE_SURVEY_RESPONSE_TYPES) {
      this.objectiveResponseOptionsDisplay.push(OBJECTIVE_SURVEY_RESPONSE_LABELS[key]);
    }

  }

  async ngOnInit() {
    const nameMap = new Map<string, string>();
    for (const survey of this.surveys) {
      nameMap.set(survey.id, await this.getEmployeeName(survey.respondentEmail));
      this.isEventSurvey = survey.surveyType === SurveyTypeCodes.EVENT;
    }
    this.surveyNamesMap = nameMap;
  }

  async getEmployeeName(respondentEmail: string): Promise<string> {
    const employee = await this.employeeService.getEmployeeByEmailMemoize(respondentEmail);
    return `${employee.firstName} ${employee.lastName}`;
  }

}
