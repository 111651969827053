import { Employee } from "./employee";
import { EmployeeImpl } from "./EmployeeImpl";

export class IEmployeeImpl extends EmployeeImpl {
  subordinateEmployees: IEmployeeImpl[];

  constructor(employee: Employee, subordinateEmployees: IEmployeeImpl[]) {
    super(employee);
    this.subordinateEmployees = subordinateEmployees;
  }
}
