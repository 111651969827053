import { DEPARTMENT_TYPE_LABELS, ROLE_TYPE_LABELS } from "@inthraction/labels";


export type DEPARTMENT_TYPE = keyof typeof DEPARTMENT_TYPE_LABELS;
export const DEPARTMENT_TYPES = Object.keys(DEPARTMENT_TYPE_LABELS) as DEPARTMENT_TYPE[];
export type RoleType = keyof typeof ROLE_TYPE_LABELS;
export const ROLE_TYPES = Object.keys(ROLE_TYPE_LABELS) as RoleType[];

export enum EmployeeDataTypes {
  MOVE_ROLE = "MOVE_ROLE",
  IDEAL_ROLE = "IDEAL_ROLE",
  KNOWLEDGE = "KNOWLEDGE",
  SKILLS = "SKILLS",
  CAREER_PATH = "CAREER_PATH",
  STRENGTHS = "STRENGTHS",
  PREFERENCES = "PREFERENCES",
  CAREER_GOALS = "CAREER_GOALS",
  BIOGRAPHY = "BIOGRAPHY",
  RELOCATABLE = "RELOCATABLE",
  WORK_STATUS = "WORK_STATUS",
  LAST_ACTIVITY_DATE = "LAST_ACTIVITY_DATE",
}
