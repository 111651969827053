<div class="survey-page-container">
  <div></div>
  <mat-card appearance="outlined" *ngIf="attendee && event && survey">
    <mat-card-header>
      <inthraction-profile-image [employeeImage]="attendeeImage"></inthraction-profile-image>
      <div class="employee-info">
        <mat-card-title>{{attendee.firstName}}&nbsp;{{attendee.lastName}}</mat-card-title>
        <mat-card-subtitle>{{event.summary}}</mat-card-subtitle>
        <mat-card-subtitle>{{event.start | amParse:'YYYY-MM-DDTHH:mm:ssZ' | amDateFormat:'LLL'}}</mat-card-subtitle>
        <button #didNotAttendBtn (click)="submitDidNotAttend(didNotAttendBtn, submitBtn)" mat-button mat-raised-button type="submit">I Did Not Attend</button>
      </div>
    </mat-card-header>
    <form (ngSubmit)="submitSurvey(surveyForm.value, submitBtn)" [formGroup]="surveyForm" autocomplete="off" novalidate>
      <mat-card-content class="survey-container">
        <label id="survey-radio-group-label">In your opinion, how did {{attendee.firstName}} intHRact with the other attendees?</label>
        <mat-radio-group aria-labelledby="survey-radio-group-label" class="survey-radio-group" formControlName="surveyValue">
          <mat-radio-button *ngFor="let surveyOption of surveyResponseOptionsValues" [matTooltipClass]="'multi-line-tooltip'"
                            [matTooltipShowDelay]="500" [value]="surveyOption"
                            class="survey-radio-button" matTooltip="{{surveyResponseOptionsTips[surveyOption].join('\n')}}">
            <ng-container><span class="color-{{surveyOption}}">{{surveyResponseOptionsDisplay[surveyOption]}}</span></ng-container>
          </mat-radio-button>
        </mat-radio-group>
        <mat-form-field class="full-width">
          <mat-label>Feedback</mat-label>
          <textarea matInput formControlName="feedback" id="feedback" maxlength="500" name="Feedback" rows="3"></textarea>
          <mat-error *ngIf="hasError('feedback', 'required')">Feedback is required</mat-error>
        </mat-form-field>
      </mat-card-content>
      <mat-card-actions>
        <button #submitBtn [disabled]="!surveyForm.valid" color="primary" mat-raised-button type="submit">Submit</button>
      </mat-card-actions>
    </form>
    <mat-card-content class="footer">
      <span class="btm-cnr-right" *ngIf="remainingSurveysCount && remainingSurveysCount > 0">1 of {{remainingSurveysCount}}</span>
      <mat-card-subtitle>Your name and comments are only shared with this individuals Manager and Human Resources. Any information provided is intended to aid in the constructive development of our Workforce.</mat-card-subtitle>
    </mat-card-content>
  </mat-card>
  <div></div>
</div>
