import { Component, OnDestroy, OnInit } from "@angular/core";
import { ShoutOutType } from "@inthraction/data-models";
import { ShoutOutService } from "@inthraction/services";
import { MatDialog } from "@angular/material/dialog";
import { EditShoutOutTypeDialogComponent } from "../../../components/shared/dialogs/edit-shout-out-type-dialog/edit-shout-out-type-dialog.component";
import { ConfirmationDialogComponent } from "../../../components/shared/dialogs/confirmation-dialog/confirmation-dialog.component";

@Component({
  selector: "inthraction-system-shoutout-editor",
  templateUrl: "./system-shoutout-editor.component.html",
  styleUrls: ["./system-shoutout-editor.component.scss"]
})
export class SystemShoutoutEditorComponent implements OnInit, OnDestroy {

  initialized = false;

  shoutOutTypes: ShoutOutType[];

  constructor(
    private shoutOutService: ShoutOutService,
    private dialog: MatDialog
  ) {
  }

  ngOnDestroy(): void {
  }

  async ngOnInit() {
    await this.loadShoutOutTypes();
    this.initialized = true;
  }

  async loadShoutOutTypes() {
    this.shoutOutTypes = await this.shoutOutService.getShoutOutTypes();
    this.shoutOutTypes.sort((a, b) => a.title.localeCompare(b.title));
  }

  addNewShoutOut() {
    const editModal = this.dialog.open(EditShoutOutTypeDialogComponent, {
      width: "600px",
      data: { addMode: true }
    });
    editModal.afterClosed().subscribe(
      async result => {
        if (result) {
          await this.loadShoutOutTypes();
        }
      }
    );
  }

  editShoutOut(shoutOutType: ShoutOutType) {
    const editModal = this.dialog.open(EditShoutOutTypeDialogComponent, {
      width: "600px",
      data: { addMode: false, shoutOutType }
    });
    editModal.afterClosed().subscribe(
      async result => {
        if (result) {
          await this.loadShoutOutTypes();
        }
      }
    );
  }

  deleteShoutOut(id: string): void {
    const deleteDialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: "350px",
      data: "Are you sure you want to Delete this InspHRation type?  It will affect all Organizations that may be using it."
    });
    deleteDialogRef.afterClosed().subscribe(async result => {
      if (result) {
        await this.shoutOutService.deleteShoutOutType(id);
        await this.loadShoutOutTypes();
      }
    });
  }


}
