import { Component, OnDestroy, OnInit } from "@angular/core";
import { EmployeeService, OrganizationService } from "@inthraction/services";

declare global {
  interface Window {
    zESettings: any; // Zendesk
    zE: any;
  }
}

const delay = ms => new Promise(res => setTimeout(res, ms));

@Component({
  selector: "inthraction-zendesk",
  templateUrl: "./zendesk.component.html",
  styleUrls: ["./zendesk.component.scss"]
})
export class ZendeskComponent implements OnInit, OnDestroy {

  constructor(
    private employeeService: EmployeeService,
    private organizationService: OrganizationService
  ) {
  }

  async ngOnInit() {
    const employee = await this.employeeService.getCurrentEmployee();
    if (employee) {
      const organization = await this.organizationService.getOrganizationByIDMemoize(employee.orgId);
      this.loadScriptByUrl("https://static.zdassets.com/ekr/snippet.js?key=f1be8e04-acbd-411b-9d81-2c468a7e538f");
      await this.loadZendeskWidget(`${employee.firstName} ${employee.lastName}`, employee.email, organization.orgName);
    }
  }

  public async loadZendeskWidget(name: string, email: string, organization: string) {
    const ze = await this.getZe();
    if (ze) {
      if (ze.identify) {
        ze.identify({ name, email, organization });
      }
      ze("webWidget", "updateSettings", {
        webWidget: {
          contactForm: {
            fields: [{
              id: 360041335231,
              prefill: { "*": `${organization}` },
              readOnly: true
            }]
          }
        }
      });
      if (ze.show) {
        ze.show();
      }
    }
  }

  private async getZe(counter: number = 10): Promise<any> {
    const script = window.zE;
    if (script || !counter) {
      return script;
    } else if (!script && counter) {
      await delay(500);
      return await this.getZe(--counter);
    }
  }

  async ngOnDestroy() {
    const ze = await this.getZe();
    if (ze) {
      ze("webWidget", "logout");
      if (ze.hide) {
        ze.hide();
      }

      const script = document.getElementById("ze-snippet");
      if (script) {
        script.remove();
      }

    } else {
      console.debug("zE not available to hide");
    }
  }

  loadScriptByUrl(url) {
    let dynamicScript = document.createElement("script");
    dynamicScript.type = "text/javascript";
    dynamicScript.async = true;
    dynamicScript.src = url;
    dynamicScript.id = "ze-snippet";
    document.body.appendChild(dynamicScript);
  }

}
