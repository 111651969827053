import { Component, NgZone, OnDestroy, OnInit } from "@angular/core";
import { AuthenticatorService } from "@aws-amplify/ui-angular";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthService, AuthState, EmployeeService } from "@inthraction/services";
import { Subscription } from "rxjs";
import { SiteCodes } from "@inthraction/codes";

@Component({
  selector: "inthraction-auth",
  templateUrl: "./auth.component.html",
  styleUrls: ["./auth.component.scss"]
})
export class AuthComponent implements OnInit, OnDestroy {

  private returnUrl: string;
  private authStateSubscription: Subscription;
  readonly hostname: string;
  readonly logo: String;

  constructor(
    public authenticator: AuthenticatorService,
    private router: Router,
    private route: ActivatedRoute,
    private ngZone: NgZone,
    private employeeService: EmployeeService,
    private authService: AuthService
  ) {
    this.hostname = window.location.hostname.toLowerCase();
    this.logo = this.getLogo();
  }

  ngOnInit(): void {
    this.returnUrl = this.route.snapshot.queryParamMap.get("returnUrl") || "/dashboard";
    this.authStateSubscription = this.authService.authStateChange$
      .subscribe(async authState => {
        if (authState === AuthState.SignedIn) {
          let result;
          try {
            result = await this.employeeService.getCurrentEmployee();
          } catch (e) {
            console.error("AuthComponent", e);
          }
          if (!result || (!result.validEmailToken && !this.returnUrl.startsWith("/calendar-access"))) {
            await this.goTo("/profile");
          } else {
            await this.goTo(this.returnUrl);
          }
        }
      });
  }

  getLogo(): String {
    for(const site of Object.keys(SiteCodes)) {
      if(this.hostname.toLowerCase().includes(site.toString().toLowerCase())) {
        return `/assets/${site}.png`;
      }
    }

    // Dev mode uses local host, use a random site
    return `/assets/${this.getRandomElement(Object.keys(SiteCodes))}.png`;
  }

  getRandomElement = (arr: any[]) =>
    arr[Math.floor(Math.random() * arr.length)]

  /**
   * Angular stopped taking normal query parameter strings with ? and & in the router.navigate route parameter.
   * This function splits expected query parameters from the authorization response from Cronofy before navigating
   * back to the authorization page that will process them.
   * @param route
   * @private
   */
  private extractCalendarAccessQueryParameters(route: string) {
    const queryParamsObj: CalendarAccessQueryParams = {};
    for (const queryParamPair of route.substr(route.indexOf("?") + 1).split("&")) {
      const nameValuePair = queryParamPair.split("=");
      if (nameValuePair[0] === "code") {
        queryParamsObj.code = nameValuePair[1];
      } else if (nameValuePair[0] === "error") {
        queryParamsObj.error = nameValuePair[1];
      } else if (nameValuePair[0] === "state") {
        queryParamsObj.state = nameValuePair[1];
      }
    }
    return queryParamsObj;
  }

  async goTo(route: string) {
    await this.ngZone.run(async () => {
      if (route.startsWith("/calendar-access") && route.includes("?")) {
        await this.router.navigate([route.substr(0, route.indexOf("?"))], { queryParams: this.extractCalendarAccessQueryParameters(route) });
      } else {
        await this.router.navigate([route]);
      }
    });
  }

  ngOnDestroy(): void {
    this.authStateSubscription?.unsubscribe();
  }

}

interface CalendarAccessQueryParams {
  code?: string,
  state?: string,
  error?: string
}
