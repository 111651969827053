<inthraction-page-header [pageTitle]="organization?.orgName + ' Users'" addBtnTitle="Add User" (addBtnClickEvent)="add()" [downloadBtn]="tableData?.data.length > 0" (downloadBtnClickEvent)="download()">
  <mat-form-field class="no-subscript-wrapper">
    <mat-label>Filter</mat-label>
    <input matInput [disabled]="tableData?.data.length <= 0" (keyup)="applyFilter($event)" [placeholder]="'Ex. ' + (filterExample || 'Jane Doe')" #input>
  </mat-form-field>
</inthraction-page-header>
<div class="wrapper mat-elevation-z8" [hidden]="!tableData.data.length">
  <table mat-table matSort [dataSource]="tableData">
    <ng-container matColumnDef="rownum">
      <th mat-header-cell *matHeaderCellDef>#</th>
      <td mat-cell *matCellDef="let element; let i = index">{{i + 1}}</td>
    </ng-container>
    <ng-container matColumnDef="name">
      <th mat-header-cell mat-sort-header *matHeaderCellDef sortActionDescription="Sort by Name">Name</th>
      <td mat-cell *matCellDef="let element"> {{element.name}}</td>
    </ng-container>

    <ng-container matColumnDef="email">
      <th mat-header-cell mat-sort-header *matHeaderCellDef sortActionDescription="Sort by Email">Email</th>
      <td mat-cell *matCellDef="let element">{{element.email}}</td>
    </ng-container>

    <ng-container matColumnDef="disabled">
      <th mat-header-cell mat-sort-header *matHeaderCellDef sortActionDescription="Sort by Status">Status</th>
      <td mat-cell *matCellDef="let element">{{element.disabled}}</td>
    </ng-container>

    <ng-container matColumnDef="admin">
      <th mat-header-cell mat-sort-header *matHeaderCellDef sortActionDescription="Sort by Admin">Admin</th>
      <td mat-cell *matCellDef="let element"> {{element.admin}} </td>
    </ng-container>

    <ng-container matColumnDef="lastActivity">
      <th mat-header-cell mat-sort-header *matHeaderCellDef sortActionDescription="Sort by Last Activity Date">Last Activity Date</th>
      <td mat-cell *matCellDef="let element"> {{element.lastActivity ? (element.lastActivity | amParse:'YYYY-MM-DD' | amDateFormat:'ll') : ""}} </td>
    </ng-container>

    <ng-container matColumnDef="calendarAuthorized">
      <th mat-header-cell mat-sort-header *matHeaderCellDef sortActionDescription="Sort by Authorized Calendar">Calendar Authorized</th>
      <td mat-cell *matCellDef="let element"> {{element.calendarAuthorized}} </td>
    </ng-container>

    <ng-container matColumnDef="inviteSentDate">
      <th mat-header-cell mat-sort-header *matHeaderCellDef sortActionDescription="Sort by Invite Sent Date">Invite Sent Date</th>
      <td mat-cell *matCellDef="let element"> {{element.inviteSentDate ? (element.inviteSentDate | amParse:'YYYY-MM-DD' | amDateFormat:'ll') : ""}} </td>
    </ng-container>

    <ng-container matColumnDef="inviteAccepted">
      <th mat-header-cell mat-sort-header *matHeaderCellDef sortActionDescription="Sort by Invite Accepted">Invite Accepted</th>
      <td mat-cell *matCellDef="let element">{{element.inviteAccepted}}</td>
    </ng-container>

    <ng-container matColumnDef="edit">
      <th mat-header-cell *matHeaderCellDef>Edit</th>
      <td mat-cell *matCellDef="let element">
        <button mat-stroked-button (click)="edit(element.employee)">Edit</button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
</div>

<inthraction-loading className="wrapper" [initDone]="tableData.data.length > 0"></inthraction-loading>
