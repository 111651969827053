<div class="employee-dashboard dashboard">
  <div class="dashboard-page-container">
    <div class="db-container-left">
      <div class="row-1">
        <div class="emp-info-container">
          <inthraction-employee-card-component [employee]="employee"></inthraction-employee-card-component>
        </div>
      </div>
      <div class="row-1 inspHRation-row" *ngIf="organizationInspHRations && inspHRationCountMap && organizationInspHRations.length">
        <div class="insphration-button-container">
          <inthraction-help-bubble [message]="INSPHRATION_ICONS_HELP" tip="inspHRations Help" title="inspHRations"></inthraction-help-bubble>
          <button mat-button title="inspHRation Board" routerLink="/reports/inspHRation-board">
            <mat-icon>article</mat-icon>
            inspHRation Board
          </button>
        </div>
        <ng-container *ngIf="inspHRationCountMap.size">
        <mat-grid-list [cols]="organizationInspHRations.length / 5 <= 1 ? organizationInspHRations.length : 5" rowHeight="120px">
          <ng-container *ngFor="let inspHRation of organizationInspHRations">
            <mat-grid-tile *ngIf="inspHRationCountMap.has(inspHRation.id)" (click)="openInspHRationResponseList(inspHRation)" class="clickable">
              <inthraction-employee-insphration [inspHRation]="inspHRation" [count]="inspHRationCountMap.has(inspHRation.id) ? inspHRationCountMap.get(inspHRation.id) : 0" matTooltip="{{inspHRation.overrideTitle}} : {{inspHRation.description}}"></inthraction-employee-insphration>
            </mat-grid-tile>
          </ng-container>
        </mat-grid-list>
        </ng-container>
      </div>
      <div class="row-2">
        <div class="recent-results-container">
          <div>
            <mat-accordion>
              <mat-expansion-panel (afterExpand)="expandOpenIntHRactions()" [disabled]="!openInteractionsCount">
                <mat-expansion-panel-header>
                  <inthraction-help-bubble [message]="OPEN_INTHRACTIONS_HELP" title="Open intHRactions" tip="Open intHRactions Help"></inthraction-help-bubble>
                  <mat-panel-title>
                    <mat-icon svgIcon="intHRaction-bw"></mat-icon>&nbsp;<span>Open int<span class="inthraction-highlight">HR</span>actions ({{openInteractionsCount}})</span>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <div *ngIf="!openInteractionsView || openInteractionsView.length <= 0">
                  <mat-progress-spinner diameter="50" color="primary" mode="indeterminate"></mat-progress-spinner>
                </div>
                <div *ngIf="openInteractionsView && openInteractionsView.length > 0">
                  <mat-list>
                    <mat-list-item *ngIf="openInteractionsMultiplier > 0">
                      <button mat-raised-button (click)="pageOpenIntHrActions(openInteractionsMultiplier - 1)" style="width: 100%">
                        <mat-icon>keyboard_arrow_up</mat-icon>
                      </button>
                    </mat-list-item>
                    <ng-container *ngFor="let survey of openInteractionsView; let i = index">
                      <mat-list-item *ngIf="managerAccess">
                        <div class="list-item-container">
                          <span class="event-title">{{survey.intHRactionTitle}}</span>
                          <span class="event-date" *ngIf="survey.surveyType != 'STANDUP'">{{ survey.intHRactionDate | amParse:'YYYY-MM-DDTHH:mm:ssZ' | amDateFormat:'lll'}}</span>
                          <span class="event-date" *ngIf="survey.surveyType == 'STANDUP'">{{ survey.intHRactionDate | amParse:'YYYY-MM-DD' | amDateFormat:'lll'}}</span>
                        </div>
                      </mat-list-item>
                      <mat-list-item *ngIf="!managerAccess" class="link" routerLink="/{{surveyRouterLink(survey.surveyType)}}/{{survey.surveyIDs[0]}}">
                        <div class="list-item-container">
                          <span class="event-title">{{survey.intHRactionTitle}}</span>
                          <span class="event-date">{{ survey.intHRactionDate | amParse:'YYYY-MM-DDTHH:mm:ssZ' | amDateFormat:'lll'}}</span>
                        </div>
                      </mat-list-item>
                      <mat-divider *ngIf="(i+1) < openInteractionsView.length"></mat-divider>
                    </ng-container>
                    <mat-list-item *ngIf="openInteractions && openInteractions.length > 8 && (openInteractionsMultiplier + 1) * 8 < openInteractions.length">
                      <button mat-raised-button (click)="pageOpenIntHrActions(openInteractionsMultiplier + 1)" style="width: 100%">
                        <mat-icon>keyboard_arrow_down</mat-icon>
                      </button>
                    </mat-list-item>
                  </mat-list>
                </div>
              </mat-expansion-panel>
              <mat-expansion-panel (afterExpand)="expandRecentIntHRactions()" [disabled]="!recentIntHRactionsCount">
                <mat-expansion-panel-header>
                  <inthraction-help-bubble [message]="RECENT_INTHRACTIONS_HELP" title="Recent intHRactions" tip="Recent intHRactions Help"></inthraction-help-bubble>
                  <mat-panel-title>
                    <mat-icon
                      svgIcon="intHRaction"></mat-icon>&nbsp;<span>Recent int<span class="inthraction-highlight">HR</span>actions ({{recentIntHRactionsCount}})</span>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <div *ngIf="!recentIntHRactionEventIDsView || recentIntHRactionEventIDsView.length <= 0">
                  <mat-progress-spinner diameter="50" color="primary" mode="indeterminate"></mat-progress-spinner>
                </div>
                <div *ngIf="recentIntHRactionEventIDsView && recentIntHRactionEventIDsView.length > 0">
                  <mat-list>
                    <mat-list-item *ngIf="recentViewMultiplier > 0">
                      <button mat-raised-button (click)="pageRecentIntHrActions(recentViewMultiplier - 1)" style="width: 100%">
                        <mat-icon>keyboard_arrow_up</mat-icon>
                      </button>
                    </mat-list-item>
                    <ng-container *ngFor="let eventID of recentIntHRactionEventIDsView; let i = index">
                      <mat-list-item>
                        <div (click)="interactionEvents.get(eventID).type == 'OBJECTIVE' ? openObjectiveSurveyResponse(eventID) : openRecentIntHRaction(eventID)" class="recent-inthraction-flex-row">
                          <mat-icon *ngIf="managerAccess && eventsWithComments.has(eventID)" color="primary">feedback</mat-icon>
                          <div class="recent-inthraction-flex-grow">
                            <span class="event-title">{{(interactionEvents.get(eventID) ? interactionEvents.get(eventID).summary : '')}}</span>
                            <span class="event-date">{{(interactionEvents.get(eventID) ? (interactionEvents.get(eventID).start | amParse:'YYYY-MM-DDTHH:mm:ssZ' | amDateFormat:'lll') : '')}}</span>
                          </div>
                        </div>
                      </mat-list-item>
                      <mat-divider *ngIf="(i+1) < recentIntHRactionEventIDsView.length"></mat-divider>
                    </ng-container>
                    <mat-list-item *ngIf="recentIntHRactionObjects && recentIntHRactionObjects.length > 8 && (recentViewMultiplier + 1) * 8 < recentIntHRactionObjects.length">
                      <button mat-raised-button (click)="pageRecentIntHrActions(recentViewMultiplier + 1)" style="width: 100%">
                        <mat-icon>keyboard_arrow_down</mat-icon>
                      </button>
                    </mat-list-item>
                  </mat-list>
                </div>
              </mat-expansion-panel>
              <mat-expansion-panel (afterExpand)="expandMissedMeetings()" [disabled]="!missedEventsCount">
                <mat-expansion-panel-header>
                  <inthraction-help-bubble [message]="MISSED_MEETINGS_HELP" title="Missed intHRactions" tip="Missed intHRactions Help"></inthraction-help-bubble>
                  <mat-panel-title>
                    <mat-icon>work_off</mat-icon>&nbsp;<span>Missed int<span class="inthraction-highlight">HR</span>actions ({{missedEventsCount}})</span>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <div *ngIf="!missedEventIDsView || missedEventIDsView.length <= 0">
                  <mat-progress-spinner diameter="50" color="primary" mode="indeterminate"></mat-progress-spinner>
                </div>
                <div *ngIf="missedEventIDsView && missedEventIDsView.length > 0">
                  <mat-list>
                    <mat-list-item *ngIf="missedEventsMultiplier > 0">
                      <button mat-raised-button (click)="pageMissedEvents(missedEventsMultiplier - 1)" style="width: 100%">
                        <mat-icon>keyboard_arrow_up</mat-icon>
                      </button>
                    </mat-list-item>
                    <mat-list-item *ngFor="let eventID of missedEventIDsView; let i = index">
                      <div class="list-item-container">
                        <span class="event-title">{{(interactionEvents.get(eventID) ? interactionEvents.get(eventID).summary : '')}}</span>
                        <span class="event-date">{{(interactionEvents.get(eventID) ? (interactionEvents.get(eventID).start | amParse:'YYYY-MM-DDTHH:mm:ssZ' | amDateFormat:'lll') : '')}}</span>
                      </div>
                    </mat-list-item>
                    <mat-list-item *ngIf="missedEventIDs && missedEventIDs.length > 8 && (missedEventsMultiplier + 1) * 8 < missedEventIDs.length">
                      <button mat-raised-button (click)="pageMissedEvents(missedEventsMultiplier + 1)" style="width: 100%">
                        <mat-icon>keyboard_arrow_down</mat-icon>
                      </button>
                    </mat-list-item>
                  </mat-list>
                </div>
              </mat-expansion-panel>
            </mat-accordion>
          </div>
          <div #shoutOutAccordion *ngIf="organizationInspHRations && organizationInspHRations.length">
            <mat-accordion style="position: relative">
              <mat-expansion-panel [disabled]="!openInspHRations?.length || managerAccess">
                <mat-expansion-panel-header *ngIf="!openInspHRations?.length || managerAccess">
                  <inthraction-help-bubble [message]="OPEN_INSPHRATIONS_HELP" title="Open inspHRations" tip="Open inspHRations Help"></inthraction-help-bubble>
                  <mat-panel-title>
                    <mat-icon>thumbs_up_down</mat-icon>&nbsp;<span>Open insp<span class="inthraction-highlight">HR</span>ations</span>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <mat-expansion-panel-header *ngIf="openInspHRations?.length && !managerAccess" routerLink="/inspHRation-survey/{{openInspHRations? openInspHRations[0].id : 0}}">
                  <inthraction-help-bubble [message]="OPEN_INSPHRATIONS_HELP" title="Open inspHRations" tip="Open inspHRations Help"></inthraction-help-bubble>
                  <mat-panel-title>
                    <mat-icon>thumbs_up_down</mat-icon>&nbsp;<span>Open insp<span class="inthraction-highlight">HR</span>ations</span>
                  </mat-panel-title>
                </mat-expansion-panel-header>
              </mat-expansion-panel>
              <mat-expansion-panel (afterExpand)="expandGivenInspHRations()" [disabled]="!givenInspHRations || givenInspHRations.length <= 0">
                <mat-expansion-panel-header>
                  <inthraction-help-bubble [message]="INSPHRATIONS_GIVEN_HELP" title="inspHRations Given" tip="inspHRations Given Help"></inthraction-help-bubble>
                  <mat-panel-title>
                    <mat-icon>thumb_up</mat-icon>&nbsp;<span>insp<span class="inthraction-highlight">HR</span>ations Given ({{givenInspHRations?.length || 0}})</span>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <div *ngIf="!givenInspHRationsView || givenInspHRationsView.length <= 0">
                  <mat-progress-spinner diameter="50" color="primary" mode="indeterminate"></mat-progress-spinner>
                </div>
                <div *ngIf="givenInspHRationsView && givenInspHRationsView.length > 0">
                  <mat-list>
                    <mat-list-item *ngIf="givenInspHRationsMultiplier > 0">
                      <button mat-raised-button (click)="pageGivenInspHRations(givenInspHRationsMultiplier - 1)" style="width: 100%">
                        <mat-icon>keyboard_arrow_up</mat-icon>
                      </button>
                    </mat-list-item>
                    <ng-container *ngFor="let survey of givenInspHRationsView; let i = index">
                      <mat-list-item *ngIf="organizationInspHRationsMap.has(survey.objectID)" class="insphration-list-item">
                        <div (click)="openInspHRationResult(survey)" class="insphration-record">
                          <inthraction-employee-insphration [inspHRation]="organizationInspHRationsMap.get(survey.objectID)" horizontal center icon-only></inthraction-employee-insphration>
                          <div class="list-item-container">
                            <span>{{organizationInspHRationsMap.get(survey.objectID).overrideTitle}}</span>
                            <span class="event-date">{{ survey.responseReceivedDate | amParse:'YYYY-MM-DDTHH:mm:ssZ' | amDateFormat:'lll'}}</span>
                            <span>{{survey.participantFirstName}} {{survey.participantLastName}}</span>
                          </div>
                        </div>
                      </mat-list-item>
                      <mat-divider *ngIf="(i+1) < givenInspHRationsView.length"></mat-divider>
                    </ng-container>
                    <mat-list-item *ngIf="givenInspHRations && givenInspHRations.length > 8 && (givenInspHRationsMultiplier + 1) * 8 < givenInspHRations.length">
                      <button mat-raised-button (click)="pageGivenInspHRations(givenInspHRationsMultiplier + 1)" style="width: 100%">
                        <mat-icon>keyboard_arrow_down</mat-icon>
                      </button>
                    </mat-list-item>
                  </mat-list>
                </div>
              </mat-expansion-panel>
              <mat-expansion-panel (afterExpand)="expandReceivedInspHRations()" [disabled]="!receivedInspHRations || receivedInspHRations.length <= 0">
                <mat-expansion-panel-header>
                  <inthraction-help-bubble [message]="INSPHRATIONS_RECEIVED_HELP" title="inspHRations Received" tip="inspHRations Received Help"></inthraction-help-bubble>
                  <mat-panel-title>
                    <mat-icon>system_update_alt</mat-icon>&nbsp;<span>insp<span class="inthraction-highlight">HR</span>ations Received ({{receivedInspHRations?.length || 0}})</span>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <div *ngIf="!receivedInspHRationsView || receivedInspHRationsView.length <= 0">
                  <mat-progress-spinner diameter="50" color="primary" mode="indeterminate"></mat-progress-spinner>
                </div>
                <div *ngIf="receivedInspHRationsView && receivedInspHRationsView.length > 0">
                  <mat-list>
                    <mat-list-item *ngIf="receivedInspHRationsMultiplier > 0">
                      <button mat-raised-button (click)="pageReceivedInspHRations(receivedInspHRationsMultiplier - 1)" style="width: 100%">
                        <mat-icon>keyboard_arrow_up</mat-icon>
                      </button>
                    </mat-list-item>
                    <ng-container *ngFor="let survey of receivedInspHRationsView; let i = index">
                      <mat-list-item *ngIf="organizationInspHRationsMap.has(survey.objectID)">
                        <div (click)="openInspHRationResult(survey)" class="insphration-record">
                          <inthraction-employee-insphration [inspHRation]="organizationInspHRationsMap.get(survey.objectID)" horizontal center icon-only></inthraction-employee-insphration>
                          <div class="list-item-container">
                            <span>{{organizationInspHRationsMap.get(survey.objectID).overrideTitle}}</span>
                            <span class="event-date">{{ survey.surveyDate | amParse:'YYYY-MM-DDTHH:mm:ssZ' | amDateFormat:'lll'}}</span>
                            <span>{{survey.respondentFirstName}} {{survey.respondentLastName}}</span>
                          </div>
                        </div>
                      </mat-list-item>
                      <mat-divider *ngIf="(i+1) < receivedInspHRationsView.length"></mat-divider>
                    </ng-container>
                    <mat-list-item *ngIf="receivedInspHRations && receivedInspHRations.length > 8 && (receivedInspHRationsMultiplier + 1) * 8 < receivedInspHRations.length">
                      <button mat-raised-button (click)="pageReceivedInspHRations(receivedInspHRationsMultiplier + 1)" style="width: 100%">
                        <mat-icon>keyboard_arrow_down</mat-icon>
                      </button>
                    </mat-list-item>
                  </mat-list>
                </div>
              </mat-expansion-panel>
            </mat-accordion>
          </div>
        </div>
        <div class="trend-container" *ngIf="showIntHRactionTrendChart" #trendContainer hidden="hidden">
          <mat-card appearance="outlined" class="trend">
            <mat-card-header>
              <mat-card-title>int<span class="inthraction-highlight">HR</span>action Trend</mat-card-title>
            </mat-card-header>
            <mat-card-content>
              <div class="inthraction-score-chart-holder">
                <inthraction-help-bubble [message]="INTHRACTION_TREND_HELP" title="intHRaction Trend" tip="intHRaction Trend Help"></inthraction-help-bubble>
                <div></div>
                <inthraction-interaction-score-chart (openEventEmitter)="openRecentIntHRaction($event.scoreID, $event.employeeID)"
                                                     *ngIf="clickCount%2 != 0"
                                                     [employee]="employee"
                                                     [orgID]="employee.orgId"
                                                     [employees]="interactionGraphEmployeesArray"
                                                     [managerView]="managerAccess" (noDataEmitter)="$event.nodata ?  trendContainer.hidden = true : trendContainer.hidden = false"></inthraction-interaction-score-chart>
                <inthraction-interaction-score-chart (openEventEmitter)="openRecentIntHRaction($event.scoreID, $event.employeeID)"
                                                     *ngIf="clickCount%2 == 0"
                                                     [employee]="employee"
                                                     [orgID]="employee.orgId"
                                                     [employees]="interactionGraphEmployeesArray"
                                                     [managerView]="managerAccess"></inthraction-interaction-score-chart>
                <div></div>
              </div>
            </mat-card-content>
          </mat-card>
        </div>
      </div>
      <div class="row-3">
        <div *ngIf="managerAccess" class="bio-goal-container" style="position: relative">
          <inthraction-help-bubble [message]="BIO_ASPIRATIONS_GROWTH_HELP" title="Personal Biography, Career Aspirations, & Personal Growth" tip="Bio, Aspirations, & Growth Help"></inthraction-help-bubble>
          <inthraction-app-employee-personal-goals [employeeID]="employee.id" [managerAccess]="managerAccess"></inthraction-app-employee-personal-goals>
        </div>
        <div [class]="managerAccess ? 'team-container' : 'full-width team-container'">
          <mat-card appearance="outlined" class="team-org-chart-card">
            <mat-card-header>
              <mat-card-title>Team</mat-card-title>
              <div class="team-org-chart-actions">
                <button *ngIf="managerAccess" (click)="graphTeam()" [disabled]="!(managerWithHierarchy && managerWithHierarchy.subordinateEmployees && managerWithHierarchy.subordinateEmployees.length > 0)" class="graph-team-btn"
                        mat-raised-button>
                  Graph Team
                </button>
              </div>
            </mat-card-header>
            <mat-card-content>
              <div *ngIf="managerWithHierarchy" class="project-team-container">
                <ng-container *ngFor="let teamMember of managerWithHierarchy.subordinateEmployees">
                  <inthraction-mini-employee-card [options]="{showScoreColorIndicator:managerAccess, clickable:managerAccess}" [employee]="teamMember" (clickEvent)="managerAccess ? showEmployeeChart($event) : null"></inthraction-mini-employee-card>
                </ng-container>
                <ng-container *ngIf="employee.id == manager.id">
                  <inthraction-mini-employee-card [options]="{showScoreColorIndicator:managerAccess, clickable:true}" [employee]="employee" (clickEvent)="showEmployeeChart($event)"></inthraction-mini-employee-card>
                </ng-container>
              </div>
            </mat-card-content>
          </mat-card>
        </div>
      </div>
      <div class="row-4">
        <div [style.position]="'relative'" class="notes-container" *ngIf="managerAccess && oneOnOneNotes?.length > 0">
          <inthraction-help-bubble [message]="MANAGER_COMMUNICATION_HELP" title="Manager Feedback & 1 on 1's" tip="Manager Feedback & 1 on 1's Help"></inthraction-help-bubble>
          <inthraction-notes
            [user]="(managerAccess ? manager : employee)"
            [options]="{title:'Manager Feedback & 1 on 1\'s', enableCreate:false, enableEdit: managerAccess, enableReview: managerAccess}"
            [notes]="oneOnOneNotes"
            (editNoteEvent)="editOneOnOneNote($event)"
            (reviewNoteEvent)="reviewOneOnOneNote($event)"></inthraction-notes>
        </div>
        <div [style.position]="'relative'" class="notes-container" *ngIf="!managerAccess && (oneOnOneNotes?.length || hrOneOnOneNotes?.length || consultantOneOnOneNotes?.length)">
          <inthraction-help-bubble [message]="COMMUNICATION_HELP" title="Communication Channels" tip="Communication Channels Help"></inthraction-help-bubble>
          <mat-card appearance="outlined">
            <mat-card-content>
              <mat-tab-group class="communications-tab-group" mat-stretch-tabs="false">
                <mat-tab label="Manager Feedback & 1 on 1's" *ngIf="oneOnOneNotes?.length">
                  <ng-template mat-tab-label>
                    <span>Manager Feedback & 1 on 1's</span>
                    <div class="mat-tab-label-help-wrapper">
                      <inthraction-help-bubble [message]="MANAGER_COMMUNICATION_HELP" title="Manager Feedback & 1 on 1's" tip="Manager Feedback & 1 on 1's Help"></inthraction-help-bubble>
                    </div>
                  </ng-template>
                  <inthraction-notes
                    [user]="(employee)"
                    [options]="{title:'', enableCreate:false, enableEdit: false, enableReview: false}"
                    [notes]="oneOnOneNotes"
                    (editNoteEvent)="editOneOnOneNote($event)"
                    (reviewNoteEvent)="reviewOneOnOneNote($event)"></inthraction-notes>
                </mat-tab>
                <mat-tab label="HR Communication" *ngIf="hrOneOnOneNotes?.length">
                  <ng-template mat-tab-label>
                    <span>HR Communication</span>
                    <div class="mat-tab-label-help-wrapper">
                      <inthraction-help-bubble [message]="HR_COMMUNICATION_HELP" title="HR Communication" tip="HR Communication Help"></inthraction-help-bubble>
                    </div>
                  </ng-template>
                  <inthraction-notes
                    [user]="(employee)"
                    [options]="{title:'', enableCreate:false, enableEdit: false, enableReview: false}"
                    [notes]="hrOneOnOneNotes"
                    (editNoteEvent)="editOneOnOneNote($event)"
                    (reviewNoteEvent)="reviewOneOnOneNote($event)"></inthraction-notes>
                </mat-tab>
                <mat-tab label="Consultant Communication" *ngIf="consultantOneOnOneNotes?.length">
                  <ng-template mat-tab-label>
                    <span>Consultant Communication</span>
                    <div class="mat-tab-label-help-wrapper">
                      <inthraction-help-bubble [message]="CONSULTANT_COMMUNICATION_HELP" title="Consultant Communication" tip="Consultant Communication Help"></inthraction-help-bubble>
                    </div>
                  </ng-template>
                  <inthraction-notes
                    [user]="(employee)"
                    [options]="{title:'', enableCreate:false, enableEdit: false, enableReview: false}"
                    [notes]="consultantOneOnOneNotes"
                    (editNoteEvent)="editOneOnOneNote($event)"
                    (reviewNoteEvent)="reviewOneOnOneNote($event)"></inthraction-notes>
                </mat-tab>
              </mat-tab-group>
            </mat-card-content>
          </mat-card>
        </div>
        <div [style.position]="'relative'" class="standup-container" *ngIf="!managerAccess && latestStandup?.surveyDate">
          <mat-card appearance="outlined" class="standup-card">
            <mat-card-header>
              <div class="half-header">
                <mat-card-title>My Stand-Up Surveys</mat-card-title>
                <mat-card-subtitle>{{latestStandup.surveyDate | amParse:'YYYY-MM-DDTHH:mm:ssZ' | amDateFormat:'dddd, MMMM Do YYYY'}}</mat-card-subtitle>
              </div>
              <div class="half-header">
                <button mat-icon-button class="right" (click)="showStandupHistory()">
                  <mat-icon>history</mat-icon>
                </button>
              </div>
            </mat-card-header>
            <mat-card-content class="card-wrapper">
              <ng-container>
                <inthraction-stand-up-survey-display [survey]="latestStandup" [hideAvatar]="true" [note]="standupNote" [user]="employee"></inthraction-stand-up-survey-display>
              </ng-container>
            </mat-card-content>
          </mat-card>
        </div>
      </div>
      <div class="row-5">
        <div *ngIf="!managerAccess && sharedStandupManagers.length" class="shared-standup-container full-width">
          <mat-card>
            <mat-card-header>
              <mat-card-title>Shared Stand-Up Surveys</mat-card-title>
            </mat-card-header>
            <mat-card-content>
              <mat-accordion multi>
                <mat-expansion-panel *ngFor="let standupManager of sharedStandupManagers">
                  <mat-expansion-panel-header>
                    <mat-panel-title>{{standupManager.firstName}} {{standupManager.lastName}}</mat-panel-title>
                  </mat-expansion-panel-header>
                  <ng-template matExpansionPanelContent>
                    <inthraction-standup-history [manager]="standupManager"></inthraction-standup-history>
                  </ng-template>
                </mat-expansion-panel>
              </mat-accordion>
            </mat-card-content>
          </mat-card>
        </div>
      </div>
    </div>
    <div class="db-container-right">
      <div [style.position]="'relative'" class="db-actions-container">
        <div class="dashboard-actions">
          <div *ngIf="managerAccess" class="relative-btn-wrapper">
            <inthraction-help-bubble [message]="ONE_ON_ONE_HELP" title="One on One" tip="One on One Help"></inthraction-help-bubble>
            <button routerLink="./one-on-one" mat-raised-button>One-on-One</button>
          </div>
          <div *ngIf="managerAccess" class="relative-btn-wrapper">
            <inthraction-help-bubble [message]="QUARTERLY_REVIEW_HELP" title="Quarterly Review" tip="Quarterly Review Help"></inthraction-help-bubble>
            <button routerLink="./quarterly-review" mat-raised-button>Quarterly Review</button>
          </div>
          <div *ngIf="managerAccess" class="relative-btn-wrapper">
            <inthraction-help-bubble [message]="ANNUAL_REVIEW_HELP" title="Annual Review" tip="Annual Review Help"></inthraction-help-bubble>
            <button routerLink="./annual-review" mat-raised-button>Annual Review</button>
          </div>
          <div *ngIf="managerAccess" class="relative-btn-wrapper">
            <inthraction-help-bubble [message]="OPEN_MANAGER_COMMUNICATION_HELP" title="Communication" tip="Communication Help"></inthraction-help-bubble>
            <button routerLink="./communication" mat-raised-button>Communication</button>
          </div>
          <div *ngIf="managerAccess && managerObjectivesEnabled" class="relative-btn-wrapper">
            <inthraction-help-bubble [message]="ADD_EMPLOYEE_OBJECTIVES_HELP" title="Objectives" tip="Objectives Help"></inthraction-help-bubble>
            <button (click)="openAddObjective()" class="" mat-raised-button>Assign Objectives</button>
          </div>
          <div *ngIf="managerAccess" class="relative-btn-wrapper wide-btn">
            <inthraction-help-bubble [message]="OBJECTIVE_RESPONSE_DISTRIBUTION_HELP" title="Objective Response Distributions" tip="Objective Response Distributions Help"></inthraction-help-bubble>
            <button (click)="showObjectiveResponseDistributions.emit(employee)" class="" mat-raised-button>Objective Response Distributions</button>
          </div>
        </div>
      </div>
      <div class="emp-objective-container">
        <div style="position: relative">
          <inthraction-help-bubble [message]="OBJECTIVE_PANEL_HELP" title="Objectives" tip="Objectives Help"></inthraction-help-bubble>
        </div>
        <ng-container *ngFor="let objectiveAssignment of employeeObjectives">
          <inthraction-quantifiable-objective *ngIf="objectiveAssignment.objectiveType === 'QUANTIFIABLE'" [objectiveAssignment]="objectiveAssignment" [options]="{'managerAccess': managerAccess && !objectiveAssignment.consultantId}" (openEdit)="openEditObjective($event)" [employee]="employee"></inthraction-quantifiable-objective>
          <inthraction-objective *ngIf="!objectiveAssignment.objectiveType || objectiveAssignment.objectiveType === 'DEFINED'" [objectiveAssignment]="objectiveAssignment" [options]="{'managerAccess': managerAccess && !objectiveAssignment.consultantId}" (openEdit)="openEditObjective($event)" [employee]="employee"></inthraction-objective>
          <inthraction-cadence-objective *ngIf="objectiveAssignment.objectiveType === 'DEFINED_MANAGER' || objectiveAssignment.objectiveType === 'DEFINED_SELF'" [objectiveAssignment]="objectiveAssignment" [options]="{'managerAccess': managerAccess && !objectiveAssignment.consultantId}" (openEdit)="openEditObjective($event)" [employee]="employee"></inthraction-cadence-objective>
          <inthraction-cadence-objective *ngIf="objectiveAssignment.objectiveType === 'DEFINED_EXTERNAL'" [objectiveAssignment]="objectiveAssignment" [options]="{'managerAccess': managerAccess && !objectiveAssignment.consultantId}" (openEdit)="openEditObjective($event)" [employee]="employee"></inthraction-cadence-objective>
        </ng-container>
      </div>
    </div>
  </div>
</div>
