import {
  QuestionsMap,
  StandupSurveyConfigurationDataMapper,
  StandupSurveyDataMapper,
  VersionedQuestion
} from "@inthraction/data-mappers";
import { Survey } from "./survey";

export class StandupSurvey extends Survey implements StandupSurveyDataMapper {
  __typename: string = "StandupSurvey";
  surveyType: string = "STANDUP";
  declare surveyDate: string;
  declare respondentEmail: string;
  respondentID: string;
  feedbackArray: string[];
  declare organizationID: string;
  questionMaps: QuestionsMap[];
  standupSurveyConfigurationID: string;
  chatRequest: boolean;
  meetingRequest: boolean;

  constructor(graphObject: any) {
    super();
    if (graphObject) {
      this.id = graphObject.id;
      this.surveyDate = graphObject.surveyDate;
      this.respondentEmail = graphObject.respondentEmail;
      this.respondentID = graphObject.respondentID;
      this.feedbackArray = graphObject.feedbackArray;
      this.organizationID = graphObject.organizationID;
      this.questionMaps = graphObject.questionMaps;
      this.standupSurveyConfigurationID = graphObject.standupSurveyConfigurationID;
      this.status = graphObject.status;
      this.createdAt = graphObject.createdAt;
      this.updatedAt = graphObject.updatedAt;
      this.chatRequest = graphObject.chatRequest;
      this.meetingRequest = graphObject.meetingRequest;
    }
  }

}

export class StandupSurveyConfiguration implements StandupSurveyConfigurationDataMapper {
  __typename: string = "StandupSurveyConfiguration";
  createdAt: string;
  employeeID: string;
  frequency: string;
  id: string;
  organizationID: string;
  questions: VersionedQuestion[];
  shared?: string[];
  excluded?: string[];
  status: string;
  lastSurveyedDate?: string;
  isoWeekday?: number;
  updatedAt: string;
}
