<div *ngIf="event" class="recent-inthractions-container">
  <h1 mat-dialog-title>Recent int<span style="color: #FE5305;">HR</span>action</h1>
  <h2 *ngIf="managerAccess" mat-dialog-title>{{employee.firstName}} {{employee.lastName}}</h2>
  <div mat-dialog-content>
    <div class="inthraction-score-container">
      <inthraction-score-card [score]="totalScore" scale="6"></inthraction-score-card>
    </div>
    <mat-form-field class="three-qtr-width">
      <mat-label>Event Title</mat-label>
      <input matInput readonly value="{{event.summary}}">
    </mat-form-field>
    <mat-form-field class="three-qtr-width">
      <mat-label>Event Date</mat-label>
      <input matInput readonly value="{{event.start | amParse:'YYYY-MM-DDTHH:mm:ssZ' | amDateFormat:'dddd, MMMM Do YYYY hh:mm a'}}">
    </mat-form-field>
    <div class="two-columns">
      <div class="score-list">
        <ng-container *ngFor="let option of displayOptions; let i = index">
          <p class="inthraction-response-type-wrapper color-{{(displayOptions.length-1) - i}}">
            <button
              [matTooltipClass]="'multi-line-tooltip'"
              mat-raised-button
              matBadge="{{eventResponseMap.get((displayOptions.length-1) - i) ? eventResponseMap.get((displayOptions.length-1) - i).count : null}}" matBadgeColor="accent" matBadgePosition="before"
              matTooltip="{{eventResponseMap.get((displayOptions.length-1) - i) && eventResponseMap.get((displayOptions.length-1) - i).respondents.length > 0 ?  eventResponseMap.get((displayOptions.length-1) - i).respondents.join('\n') : null}}">{{option}}</button>
          </p>
        </ng-container>
      </div>
      <div *ngIf="managerAccess" class="survey-comments">
        <div *ngFor="let commentObj of surveysWithComments">
          <mat-card appearance="outlined">
            <div class="two-columns">
              <mat-card-title class="full-width">{{commentObj.respondent}}</mat-card-title>
              <mat-card-subtitle class="color-{{commentObj.event.surveyResponse}}">{{displayOptions[(displayOptions.length - 1) - commentObj.event.surveyResponse]}}</mat-card-subtitle>
            </div>
            <mat-card-content>
              <textarea matInput readonly value="{{commentObj.event.feedback}}"></textarea>
            </mat-card-content>
          </mat-card>
        </div>
      </div>
    </div>
  </div>
</div>

<inthraction-loading className="recent-inthractions-container" [initDone]="event ? true : false"></inthraction-loading>
