export enum ScoreTypes {
  EventScore = "EventScore",
  YTDEventScore = "YTDEventScore",
  MTDEventScore = "MTDEventScore",
  ObjectiveScore = "ObjectiveScore",
  YTDObjectiveScore = "YTDObjectiveScore",
  MTDObjectiveScore = "MTDObjectiveScore",
  ProjectScore = "ProjectScore",
  YTDProjectScore = "YTDProjectScore",
  MilestoneScore = "MilestoneScore",
  YTDMilestoneScore = "YTDMilestoneScore",
  // Organization Experience Surveys
  YTDNewHire14DayScore = "YTDNewHire14DayScore",
  YTDNewPosition60DayScore = "YTDNewPosition60DayScore",
  YTDNewManager30DayScore = "YTDNewManager30DayScore",
  YTDNewHire90DayScore = "YTDNewHire90DayScore",
  YTDNewManager90DayScore = "YTDNewManager90DayScore"
}
