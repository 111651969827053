<div class="comment" *ngFor="let comment of comments">
  <mat-form-field class="full-width">
    <mat-label>
      <div class="contact">
        <mat-card-title class="employee-name" *ngIf="employeeNameMap.has(comment.createdBy)">{{employeeNameMap.get(comment.createdBy)}}</mat-card-title>
        <mat-card-subtitle class="comment-date">{{(comment.updatedAt ? comment.updatedAt : comment.createdAt) | amParse: 'YYYY-MM-DDTHH:mm:ssZ' | amDateFormat: 'lll'}}</mat-card-subtitle>
      </div>
    </mat-label>
    <textarea matInput readonly cdkTextareaAutosize [value]="comment.comment"></textarea>
  </mat-form-field>
</div>
