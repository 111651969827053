<h1 mat-dialog-title>Add Project Milestone</h1>
<form [formGroup]="milestoneFG" (ngSubmit)="onSaveClick(milestoneFG.value)" autocomplete="off" novalidate>
  <div mat-dialog-content>

    <mat-form-field class="full-width">
      <mat-label>Title</mat-label>
      <input matInput formControlName="title" maxlength="256">
      <mat-error *ngIf="hasError('title', 'required')">Title is required</mat-error>
    </mat-form-field>

    <mat-form-field class="full-width">
      <mat-label>Description</mat-label>
      <textarea matInput formControlName="description" maxlength="2000" cdkTextareaAutosize></textarea>
      <mat-error *ngIf="hasError('description', 'required')">Description is required</mat-error>
    </mat-form-field>

    <mat-form-field class="half-width">
      <mat-label>Start Date</mat-label>
      <input [matDatepicker]="startDate" formControlName="startDate" matInput>
      <mat-datepicker-toggle [for]="startDate" matSuffix></mat-datepicker-toggle>
      <mat-datepicker #startDate></mat-datepicker>
      <mat-error *ngIf="hasError('startDate', 'required')">Start Date is required</mat-error>
      <mat-error *ngIf="hasError('startDate', 'notCurrent')">Start Date must be greater than or equal to the current date</mat-error>
    </mat-form-field>

    <mat-form-field class="half-width">
      <mat-label>End Date</mat-label>
      <input [matDatepicker]="endDate" formControlName="endDate" matInput>
      <mat-datepicker-toggle [for]="endDate" matSuffix></mat-datepicker-toggle>
      <mat-datepicker #endDate></mat-datepicker>
      <mat-error *ngIf="hasError('endDate', 'required')">End Date is required</mat-error>
      <mat-error *ngIf="hasError('endDate', 'notCurrent')">End Date must be greater than or equal to the current date</mat-error>
    </mat-form-field>

    <mat-form-field class="half-width">
      <mat-label>Status Frequency</mat-label>
      <mat-select formControlName="cadence" [(value)]="selectedCadence">
        <mat-option *ngFor="let id of cadenceTypes" [value]="id">{{cadenceTypeLabels[id]}}</mat-option>
      </mat-select>
      <mat-error *ngIf="hasError('cadence', 'required')">Status Frequency is required</mat-error>
    </mat-form-field>

    <mat-form-field class="full-width">
      <mat-label>Team</mat-label>
      <mat-selection-list formControlName="team">
        <mat-list-option *ngFor="let member of teamMembers" [value]="member.id">{{member.firstName}} {{member.lastName}}</mat-list-option>
      </mat-selection-list>
      <input matInput hidden>
    </mat-form-field>

  </div>
  <div mat-dialog-actions>
    <button (click)="onCancelClick()" mat-button type="button">Cancel</button>
    <button [disabled]="!milestoneFG.valid" color="primary" mat-raised-button type="submit">Save</button>
  </div>
</form>
