import { Component, OnDestroy, OnInit } from "@angular/core";
import { Objective, ObjectiveDomain, Organization } from "@inthraction/data-models";
import { MatDialog } from "@angular/material/dialog";
import { EmployeeService, ObjectiveService, OrganizationService } from "@inthraction/services";
import { UserDefinedObjectiveDomainTypes } from "@inthraction/codes";
import { EditObjectiveDialogComponent } from "../../../components/shared/add-edit-objective/edit-objective-dialog.component";
import { Subscription } from "rxjs";
import { ConfirmationDialogComponent } from "../../../components/shared/dialogs/confirmation-dialog/confirmation-dialog.component";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "inthraction-consultant-objective-configuration",
  templateUrl: "./consultant-objective-configuration.component.html",
  styleUrls: ["./consultant-objective-configuration.component.scss"]
})
export class ConsultantObjectiveConfigurationComponent implements OnInit, OnDestroy {

  ready = false;
  consultantObjectivesList: Objective[] = [];
  activeObjectiveIdSet: Set<string>;

  private organization: Organization;
  private consultantObjectiveDomain: ObjectiveDomain;
  private subscriptions: Subscription[] = [];

  constructor(
    public dialog: MatDialog,
    private employeeService: EmployeeService,
    private objectiveService: ObjectiveService,
    private organizationService: OrganizationService,
    private toastr: ToastrService
  ) {
  }

  ngOnDestroy(): void {
    if (this.subscriptions.length) {
      for (const sub of this.subscriptions) {
        sub.unsubscribe();
      }
    }
  }

  async ngOnInit() {
    this.organization = await this.organizationService.getOrganizationForCurrentUser();
    this.activeObjectiveIdSet = await this.objectiveService.getActiveObjectives();
    this.consultantObjectiveDomain = await this.objectiveService.getObjectiveDomainByKey(UserDefinedObjectiveDomainTypes.CONSULTANT_DEFINED);
    await this.refreshObjectivesList();

    this.ready = true;
  }

  addNewObjective(): void {
    const editModal = this.dialog.open(EditObjectiveDialogComponent, {
      width: "600px",
      data: { domain: this.consultantObjectiveDomain, addMode: true, organizationID: this.organization.id }
    });

    this.subscriptions.push(
      editModal.afterClosed().subscribe(
        result => {
          if (result && result.objective) {
            this.consultantObjectivesList.push(result.objective);
          }
        }
      )
    );

  }

  deleteObjective(objectiveID: string) {
    const deleteDialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: "350px",
      data: "Are you sure you want to Delete this objective?"
    });
    this.subscriptions.push(
      deleteDialogRef.afterClosed().subscribe(async result => {
        if (result) {
          this.activeObjectiveIdSet = await this.objectiveService.getActiveObjectives();
          if (!this.activeObjectiveIdSet.has(objectiveID)) {
            await this.objectiveService.deleteObjective(objectiveID, this.organization.id);
            this.toastr.success("Objective Deleted");
          } else {
            this.toastr.error("Unable to Delete this Objective, it is currently assigned to a user.");
          }
          await this.refreshObjectivesList();
        }
      }));
  }

  editObjective(objective: Objective): void {
    const editModal = this.dialog.open(EditObjectiveDialogComponent, {
      width: "600px",
      data: { objective }
    });

    this.subscriptions.push(
      editModal.afterClosed().subscribe(
        async result => {
          if (result) {
            await this.refreshObjectivesList();
          }
        }
      )
    );
  }

  private async refreshObjectivesList() {
    this.consultantObjectivesList = await this.objectiveService.getObjectivesForOrganizationByDomainMemoize(this.organization.id, UserDefinedObjectiveDomainTypes.CONSULTANT_DEFINED);
  }
}
