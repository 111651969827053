<inthraction-employee-insphration mat-dialog-title [inspHRation]="orgInspHRation" horizontal center></inthraction-employee-insphration>
<h1 mat-dialog-title>{{employee.firstName}} {{employee.lastName}}</h1>
<mat-dialog-content>
  <div *ngIf="receivedInspHRations" class="inspHRation-results-list-wrapper">
    <ng-container *ngFor="let survey of receivedInspHRations">
      <mat-card appearance="outlined">
        <mat-card-content>
          <mat-form-field class="full-width">
            <textarea matInput readonly cdkTextareaAutosize [value]="survey.feedback"></textarea>
          </mat-form-field>
        </mat-card-content>
        <mat-card-footer>
          <mat-card-title>{{respondentMap.get(survey.respondentEmail).firstName}} {{respondentMap.get(survey.respondentEmail).lastName}}</mat-card-title>
          <mat-card-subtitle><span class="event-date">{{ survey.responseReceivedDate | amParse:'YYYY-MM-DDTHH:mm:ssZ' | amDateFormat:'lll'}}</span></mat-card-subtitle>
        </mat-card-footer>
      </mat-card>
    </ng-container>
  </div>

  <inthraction-loading className="inspHRation-results-list-wrapper" [initDone]="receivedInspHRations ? true: false"></inthraction-loading>

</mat-dialog-content>
