<div class="side-nav-container">
  <mat-drawer-container class="example-container mat-typography" autosize>
    <mat-drawer #drawer mode="side" disableClose="true" opened="true">
      <button mat-mini-fab (click)="isExpanded = !isExpanded" color="primary" style="margin: 10px;">
        <mat-icon aria-label="Menu">menu</mat-icon>
      </button>
      <ng-container *ngTemplateOutlet="listItem; context: {'$implicit': menuOptions}"></ng-container>
    </mat-drawer>

    <div class="example-sidenav-content">
      <router-outlet></router-outlet>
    </div>
  </mat-drawer-container>
</div>

<ng-template #listItem let-menuOptions>
  <mat-button-toggle-group #group="matButtonToggleGroup" class="button-container">
    <ng-template ngFor let-menuOption [ngForOf]="menuOptions">
      <ng-container [ngSwitch]="menuOption.type">
        <mat-button-toggle *ngSwitchCase="SideNavDrawerMenuOptionType.LINK" [value]="menuOption.title" [aria-label]="menuOption.title" routerLink="{{menuOption.routerLink}}" [title]="menuOption.title">
          <mat-icon *ngIf="menuOption.icon" matListItemIcon>{{menuOption.icon}}</mat-icon>
          <h4 mat-line *ngIf="isExpanded">{{menuOption.child ? '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;' : ''}}{{menuOption.label ? menuOption.label : menuOption.title}}</h4>
        </mat-button-toggle>
          <mat-button-toggle *ngSwitchCase="SideNavDrawerMenuOptionType.ANCHOR" [value]="menuOption.title" [aria-label]="menuOption.title" (click)="hiddenAnchor.click()"  [title]="menuOption.title">
            <mat-icon *ngIf="menuOption.icon" matListItemIcon>{{menuOption.icon}}</mat-icon>
            <h4 mat-line *ngIf="isExpanded">{{menuOption.child ? '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;' : ''}}{{menuOption.label ? menuOption.label : menuOption.title}}</h4>
            <a #hiddenAnchor [href]="menuOption.href" target="_blank" rel="noopener noreferrer"></a>
          </mat-button-toggle>
        <ng-container *ngSwitchCase="SideNavDrawerMenuOptionType.GROUP">
          <mat-button-toggle [value]="menuOption.title" [aria-label]="menuOption.title"  [title]="menuOption.title">
            <mat-icon *ngIf="group.value == menuOption.title" matListItemIcon>expand_less</mat-icon>
            <mat-icon *ngIf="group.value != menuOption.title" matListItemIcon>expand_more</mat-icon>
            <h4 mat-line *ngIf="isExpanded">{{menuOption.child ? '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;' : ''}}{{menuOption.label ? menuOption.label : menuOption.title}}</h4>
          </mat-button-toggle>
          <ng-container *ngIf="group.value == menuOption.title">
            <ng-container *ngTemplateOutlet="listItem; context: {'$implicit':menuOption.children}"></ng-container>
          </ng-container>
        </ng-container>
        <ng-container *ngSwitchCase="SideNavDrawerMenuOptionType.DIVIDER">
          <hr />
        </ng-container>
      </ng-container>
    </ng-template>
  </mat-button-toggle-group>
</ng-template>
