export const feedbackRequired = "(Feedback Required)";

export const SURVEY_RESPONSE_OPTIONS = {
  DID_NOT_ATTEND: "Did Not Attend",
  PROBLEM: "Problem",
  DISRUPTED: "Disrupted",
  DISTRACTED: "Distracted",
  PARTICIPATED: "Participated",
  CONTRIBUTED: "Contributed",
  LEAD: "Drove / Lead"
} as const;

export const SURVEY_RESPONSE_OPTIONS_DISPLAY = [
  SURVEY_RESPONSE_OPTIONS.DID_NOT_ATTEND,
  SURVEY_RESPONSE_OPTIONS.PROBLEM,
  SURVEY_RESPONSE_OPTIONS.DISRUPTED,
  SURVEY_RESPONSE_OPTIONS.DISTRACTED,
  SURVEY_RESPONSE_OPTIONS.PARTICIPATED,
  SURVEY_RESPONSE_OPTIONS.CONTRIBUTED,
  SURVEY_RESPONSE_OPTIONS.LEAD
];

export const SURVEY_RESPONSE_OPTIONS_TIPS = [
  [`Did not attend`],
  [`Was argumentative or condescending to a peer`, `Negatively affected attendees`, `Made the time uncomfortable for others`, `Should be addressed by manager`],
  [`Showed up late`, `Needed to be caught up`, `Was unprepared`],
  [`Disengaged`, `Spent the time on electronic device`, `Carried on personal side conversations`],
  [`Was present, attentive and respectful`, `Actively listened, considered the opinions of others `, `Took notes while still showing engagement`],
  [`Took part in discussions, contributed opinions`, `Asked questions, encouraged diversity of thought`, `Stoked discussion and encouraged debate`],
  [`Demonstrated authentic leadership`, `Presented a meaningful solution`, `Built consensus among other attendees`, `Should be recognized by manager`]
];

export const SURVEY_RESPONSE_TIPS = {
  DID_NOT_ATTEND: "Did not attend",
  PROBLEM: "Was argumentative or condescending to a peer&#013;&#010;Negatively affected attendees&#013;&#010;Made the time uncomfortable for others&#013;&#010;Should be addressed by manager",
  DISRUPTED: "Showed up late&#013;&#010;Needed to be caught up&#013;&#010;Was unprepared",
  DISTRACTED: "Disengaged&#013;&#010;Spent the time on electronic device&#013;&#010;Carried on personal side conversations",
  PARTICIPATED: "Was present, attentive and respectful&#013;&#010;Actively listened, considered the opinions of others&#013;&#010;Took notes while still showing engagement",
  CONTRIBUTED: "Took part in discussions, contributed opinions&#013;&#010;Asked questions, encouraged diversity of thought&#013;&#010;Stoked discussion and encouraged debate",
  LEAD: "Demonstrated authentic leadership&#013;&#010;Presented a meaningful solution&#013;&#010;Built consensus among other attendees&#013;&#010;Should be recognized by Manager"
} as const;

export const SurveyResponseOptionsTips = [
  SURVEY_RESPONSE_TIPS.DID_NOT_ATTEND,
  SURVEY_RESPONSE_TIPS.PROBLEM,
  SURVEY_RESPONSE_TIPS.DISRUPTED,
  SURVEY_RESPONSE_TIPS.DISTRACTED,
  SURVEY_RESPONSE_TIPS.PARTICIPATED,
  SURVEY_RESPONSE_TIPS.CONTRIBUTED,
  SURVEY_RESPONSE_TIPS.LEAD
];

export const OBJECTIVE_SURVEY_RESPONSE_TIPS = {
  NEEDS_IMPROVEMENT: "Failing Expectations",
  LOW_MEETS: "Low Meets Expectations",
  MEETS: "Meets Expectations",
  HIGH_MEETS: "High Meets Expectations",
  EXCEEDS: "Exceeds Expectations"
} as const;

export const OBJECTIVE_SURVEY_RESPONSE_LABELS = {
  NEEDS_IMPROVEMENT: "Failing",
  LOW_MEETS: "Low Meets",
  MEETS: "Meets",
  HIGH_MEETS: "High Meets",
  EXCEEDS: "Exceeds"
} as const;

export const ObjectiveSurveyResponseOptionsDisplay = ["0", "Failing", "Low Meets", "Meets", "High Meets", "Exceeds"];

export const ObjectiveSurveyResponseOptionsTips = [
  "0",
  "Failing Expectations",
  "Low Meets Expectations",
  "Meets Expectations",
  "High Meets Expectations",
  "Exceeds Expectations"
];

export const ONBOARDING_14_SURVEY_RESPONSE_LABELS = {
  NEEDS_IMPROVEMENT: "Time to update my resume.",
  LOW_MEETS: "Meh, the Company tried.",
  MEETS: "I got what I needed.",
  HIGH_MEETS: "The experience was great.",
  EXCEEDS: "Exceeded my expectations!"
} as const;

export const POSITION_60_SURVEY_RESPONSE_LABELS = {
  NEEDS_IMPROVEMENT: "Where's the exit?",
  LOW_MEETS: "Something's not right.",
  MEETS: "I like my job.",
  HIGH_MEETS: "There's real potential here.",
  EXCEEDS: "I found my happy place!"
} as const;

export const NEW_MANAGER_30_SURVEY_RESPONSE_LABELS = {
  NEEDS_IMPROVEMENT: "Do I even have a Manager?",
  LOW_MEETS: "My Manager is just checking the boxes.",
  MEETS: "I got enough to do my job.",
  HIGH_MEETS: "My Manager \"gets\" me.",
  EXCEEDS: "I feel exceptionally special!"
} as const;

export const ONBOARDING_90_SURVEY_RESPONSE_LABELS = {
  NEEDS_IMPROVEMENT: "The shine has worn off.",
  LOW_MEETS: "I'm just working for the weekends.",
  MEETS: "I still like my job.",
  HIGH_MEETS: "I love my job.",
  EXCEEDS: "I wish my friends & family worked here!"
} as const;

export const NEW_MANAGER_90_SURVEY_RESPONSE_LABELS = {
  NEEDS_IMPROVEMENT: "My Manager needs help.",
  LOW_MEETS: "My Manager is trying to help.",
  MEETS: "My Manager does a good job.",
  HIGH_MEETS: "My Manager could be a mentor to other Managers.",
  EXCEEDS: "My Manager should get Manager of the Year!"
} as const;

export const ONBOARDING_14_SURVEY_TITLE = "We're Glad You're Here!";
export const ONBOARDING_90_SURVEY_TITLE = "Wow! 90 Days On Your New Job";
export const POSITION_60_SURVEY_TITLE = "Congratulations On 60 Days In Your New Position!";
export const NEW_MANAGER_30_SURVEY_TITLE = "You have had 30 Days With Your New Manager";
export const NEW_MANAGER_90_SURVEY_TITLE = "90 Days With A New Manager";

export const ONBOARDING_14_SURVEY_DESCRIPTION = "We are excited and honored that you have chosen to join our Organization. We hope your on-boarding experience reflects our commitment to making you feel welcome and prepared. Please let us know how we did.";
export const ONBOARDING_90_SURVEY_DESCRIPTION = "Can you believe it's been 90 days? This is a BIG DEAL and we need your help. Your feedback ensures you are supported and can continue to shine. How would you describe the last 90 days.";
export const POSITION_60_SURVEY_DESCRIPTION = "When you are engaged and challenged, time flies. We hope that is the case as you have been in your new position for 60 days. Let us know your feelings on your position and responsibilities.";
export const NEW_MANAGER_30_SURVEY_DESCRIPTION = "Exceptional intHRaction with your Manager is critical. You're feedback helps us ensure it's happening. Let us know if the communication, engagement, and connection exhibited by your Manager supports who we believe is most important, YOU!";
export const NEW_MANAGER_90_SURVEY_DESCRIPTION = "Management is not a cake walk. Our goal is to continually enhance the skills of our leaders. To assist with the growth of your Manager, feedback about your experiences with them over the last 90 days is appreciated and valued.";

export const NEW_HIRE_14_DAY_FOOTER = "This information is shared with HR in an aggregated format to ensure all future hires receive the best on-boarding experience possible.";
export const NEW_POSITION_60_DAY_FOOTER = "This information is shared with HR in an aggregated format to ensure everyone is provided with the support and resources they need to thrive.";
export const NEW_MANAGER_30_DAY_FOOTER = "This information is shared with HR in an aggregated format to assist Managers in delivering exceptional leadership.";
export const NEW_HIRE_90_DAY_FOOTER = "This information is shared with HR in an aggregated format to help ensure you are feeling valued. Our goal is to continually provide a happy, productive, and engaging workplace.";
export const NEW_MANAGER_90_DAY_FOOTER = "This information is shared with HR in an aggregated format to assist Managers in delivering exceptional leadership.";
