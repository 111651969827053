<h2 class="total-responses" *ngIf="distributions">Total Responses: {{totalResponses}}</h2>
<div class="objective-response-distribution-details">
  <mat-tab-group vertical class="overflow-visible" mat-stretch-tabs="false">
    <mat-tab *ngFor="let distribution of distributions">
      <ng-template mat-tab-label class="distribution-label">
        <div class="distribution-label-container">
          <div class="distribution-title">
            <span class="distribution-name">{{distribution.domainName}}</span>
            <span class="distribution-response-count">{{distribution.responseCount}} Responses</span>
          </div>
          <inthraction-score-card class="distribution-score" [score]="distribution.score" scale="5"></inthraction-score-card>
        </div>
      </ng-template>
      <ng-template matTabContent>
        <div class="objectiveGroup">
          <mat-tab-group vertical mat-stretch-tabs="false">
            <mat-tab *ngFor="let objective of distribution.objectiveScores">
              <ng-template mat-tab-label class="distribution-label">
                <div class="distribution-label-container">
                  <div class="distribution-title">
                    <span class="distribution-name">{{objective.objectiveName}}</span>
                    <span class="distribution-response-count">{{objective.responseCount}} Responses</span>
                  </div>
                  <inthraction-score-card class="distribution-score" [score]="objective.score" scale="5"></inthraction-score-card>
                </div>
              </ng-template>
              <ng-template matTabContent>
                <div class="distribution-card-container">
                  <ng-container *ngFor="let group of objective.groupScores">
                    <mat-card appearance="outlined" class="response-card">
                      <mat-card-content>
                        <div (click)="cardClickEvent(group)" [class.clickable]="!employee && distributionBy === 'PEER'">
                          <mat-card-title>{{group.groupName}}</mat-card-title>
                          <mat-card-subtitle *ngIf="distributionBy === 'PEER'">{{group.subGroupName}}</mat-card-subtitle>
                          <mat-card-subtitle>{{group.responseCount}} Responses</mat-card-subtitle>
                        </div>
                        <div class="distribution-graph-container">
                          <div *ngIf="group.responseStratification" echarts [options]="group.responseStratification" class="distribution-graph"></div>
                          <inthraction-score-card class="group-score" [score]="group.groupScore" scale="5"></inthraction-score-card>
                        </div>
                      </mat-card-content>
                    </mat-card>
                  </ng-container>
                </div>
              </ng-template>
            </mat-tab>
          </mat-tab-group>
        </div>
      </ng-template>
    </mat-tab>
  </mat-tab-group>

  <div *ngIf="distributions?.length === 0">
    <div class="no-data-container">
      <mat-icon class="no-data-icon">error_outline</mat-icon>
      <span class="no-data-text">No data available</span>
    </div>
  </div>

  <inthraction-loading className="" [initDone]="distributions ? true : false"></inthraction-loading>

</div>
