import { Component, NgZone, OnDestroy, OnInit } from "@angular/core";
import { ConsultantAssociation, Organization } from "@inthraction/data-models";
import { EmployeeService, OrganizationService } from "@inthraction/services";
import { Subscription } from "rxjs";
import { ActivatedRoute, Router } from "@angular/router";
import { MatDialog } from "@angular/material/dialog";
import { AddConsultantAssignmentComponent } from "../../../components/support/add-consultant-assignment/add-consultant-assignment.component";
import { ToastrService } from "ngx-toastr";
import { EditConsultantAssignmentComponent } from "../../../components/support/edit-consultant-assignment/edit-consultant-assignment.component";

@Component({
  selector: "inthraction-manage-consultants",
  templateUrl: "./manage-consultants.component.html",
  styleUrls: ["./manage-consultants.component.scss"]
})
export class ManageConsultantsComponent implements OnInit, OnDestroy {

  static organizationIDParameterName = "organizationID";
  organization: Organization;
  consultants: ConsultantAssociation[];
  nameMap: Map<string, string> = new Map<string, string>();
  emailMap: Map<string, string> = new Map<string, string>();
  displayedColumns: string[] = ["name", "email", "status", "incentive", "edit"];
  private subscriptions: Subscription[] = [];

  constructor(
    private router: Router,
    private ngZone: NgZone,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    private organizationService: OrganizationService,
    private employeeService: EmployeeService
  ) {
  }

  ngOnDestroy(): void {
    if (this.subscriptions.length) {
      for (const sub of this.subscriptions) {
        sub.unsubscribe();
      }
    }
  }

  ngOnInit(): void {
    this.subscriptions.push( this.route.paramMap.subscribe(async queryParams => {
      if (queryParams.has(ManageConsultantsComponent.organizationIDParameterName)) {
        this.organization = await this.organizationService.getOrganizationByIDMemoize(queryParams.get(ManageConsultantsComponent.organizationIDParameterName));
        this.consultants = await this.initializeConsultants(this.organization);
      } else {
        await this.ngZone.run(async () => {
          await this.router.navigate([`dashboard`]);
        });
      }
    }));
  }

  edit(consultantAssociation: ConsultantAssociation) {
    const dialog = this.dialog.open(EditConsultantAssignmentComponent, {
      width: "600px",
      data: {
        consultantAssociation
      }
    });

    this.subscriptions.push(
      dialog.afterClosed().subscribe(async result => {
        if (result) {
          this.consultants = await this.initializeConsultants(this.organization);
        }
      }));
  }

  add() {
    const dialog = this.dialog.open(AddConsultantAssignmentComponent, {
      width: "600px",
      data: { organization: this.organization }
    });

    this.subscriptions.push(
      dialog.afterClosed().subscribe(async result => {
        if (result) {
          this.consultants = await this.initializeConsultants(this.organization);
        }
      }));
  }

  private async initializeConsultants(organization: Organization): Promise<ConsultantAssociation[]> {
    const consultantList = await this.employeeService.getConsultantsForOrganizationByOrganization(organization.id);
    for (const consultant of consultantList) {
      const employee = await this.employeeService.getEmployeeByIDMemoize(consultant.employeeID);
      this.nameMap.set(consultant.employeeID, `${employee.lastName}, ${employee.firstName}`);
      this.emailMap.set(consultant.employeeID, employee.email);
    }
    return consultantList.sort((a, b) =>
      this.nameMap.get(a.employeeID).localeCompare(this.nameMap.get(b.employeeID)));
  }
}
