import { Component, Input, OnInit } from "@angular/core";
import { Employee, EmployeeData, EmployeeImpl } from "@inthraction/data-models";
import { EmployeeDataTypes } from "@inthraction/codes";
import { EmployeeService } from "@inthraction/services";

@Component({
  selector: "inthraction-app-employee-personal-goals",
  templateUrl: "./employee-personal-goals.component.html",
  styleUrls: ["./employee-personal-goals.component.scss"]
})
export class EmployeePersonalGoalsComponent implements OnInit {


  constructor(
    private employeeService: EmployeeService
  ) {
  }

  @Input() employeeID: string;
  @Input() managerAccess: boolean;
  readonly employeeDataTypes = EmployeeDataTypes;
  employee: Employee;
  employeeDataMap: Map<EmployeeDataTypes, EmployeeData>;

  async ngOnInit() {
    this.employee = new EmployeeImpl(await this.employeeService.getEmployeeByIDMemoize(this.employeeID));
    await this.findEmployeeData();
  }

  private async findEmployeeData() {
    if (this.employeeDataMap) {
      this.employeeDataMap.clear();
    }
    const map: Map<EmployeeDataTypes, EmployeeData> = new Map<EmployeeDataTypes, EmployeeData>();
    const aspirationsDataTypes = [EmployeeDataTypes.BIOGRAPHY];
    const employeeDataList = await this.employeeService.getEmployeeData(this.employee.orgId, this.employee.id, aspirationsDataTypes, true);
    if (employeeDataList) {
      for (const data of employeeDataList) {
        map.set(data.dataCode as EmployeeDataTypes, data);
      }
    }

    for (const dataType of aspirationsDataTypes.filter((i) => !Array.from<EmployeeDataTypes>(map.keys()).includes(i))) {
      map.set(dataType, {
        dataCode: dataType,
        employeeID: this.employee.id,
        organizationID: this.employee.orgId,
        stringValue: ""
      });
    }

    this.employeeDataMap = map;
  }
}
