<span class="last-modified">Last modified on February 11, 2020</span>
<h2>Acceptance of Terms</h2>
<p>intHRaction, LLC, a Georgia limited liability company ("us" or "we" or "our") owns, operates and provides the https://www.inthraction.com website and domain including, without limitation, all related webpages, applications, subdomains, subparts, and content of such website and domain (collectively, this "Site") and the services made available through this Site (collectively, the
  "Services").</p>
<p>We provide the Services to each business subscribing to the Services (each a "Subscriber") and to each person that is authorized by such Subscriber to use the Services (each a "User") for the period agreed to by us and the Subscriber (the "Subscription Period") in accordance with the terms and conditions of such Subscriber's subscription with us as described in this Site and/or in any other
  applicable agreement (the "Subscription"), and in accordance with these Terms of Use & Services (these "Terms of Use"). A User may include a Subscriber's employees, consultants, contractors, agents, and third parties with which Subscriber transacts business. A specific Subscriber and its Users are collectively referred to as "you" or "your."</p>
<p>These Terms of Use are a binding legal agreement between us and you concerning your use of this Site, your use of the Services, and the Subscription. BY USING THIS SITE, USING OR ACCESSING ANY PART OF THE SERVICES, AND/OR AGREEING TO A SUBSCRIPTION, YOU AGREE TO BE BOUND BY THESE TERMS OF USE WITHOUT LIMITATION OR QUALIFICATION.</p>
<p>We reserve the right to modify these Terms of Use from time to time by posting modified Terms of Use on this Site without notice to you. You acknowledge and agree that it is your responsibility to review this Site and these Terms of Use periodically and to be aware of any modifications. Your continued use of this Site and/or the Services after such modifications will constitute your agreement
  to be bound by the modified Terms of Use.</p>
<br>

<h2>Provision of Services</h2>
<p>We agree to use commercially reasonable efforts to provide the Services to you during the Subscription Period subject to your compliance with the applicable Subscription, your compliance with these Terms of Use, and Subscriber's timely and full payment for the Subscription. Notwithstanding the foregoing, we reserve the right to restrict, suspend, or terminate the Services, the Subscription,
  and/or your access to this Site at any time before the scheduled end of the then current Subscription Period upon notice to the Subscriber. Upon termination of the Services, other than the provisions in these Terms of Use pertaining to our providing Services to you, all other provisions of these Terms of Use shall survive in remain in full force and effect.</p>
<br>

<h2>Payment for Services</h2>
<p>Subscriber agrees to timely and fully pay all fees for the Subscription, along with all taxes associated with the Subscription excluding only our income taxes, with time being of the essence. We may increase such fees upon notification to Subscriber. We may bill Subscriber directly or bill Subscriber using a third-party billing service. Unless we or the Subscriber cancels the Subscription in
  accordance with the terms of the Subscription and/or these Terms of Use, each Subscription Period shall automatically renew for the same time period as the prior Subscription Period (ex: month to month, year to year, etc.) and we shall bill Subscriber for the renewed Subscription Period. The payment obligations of Subscriber for the current or a renewed Subscription Period are absolute and
  non-cancellable upon making/renewing such Subscription and shall survive any termination of such Subscription Period. All payments are non-refundable. In the event that Subscriber fails to timely and fully pay for any Subscription, we shall have the right at any time to do any one or more of the following: (i) suspend or restrict the Services; (ii) terminate the Services; (iii) charge interest
  at the rate of 1.5% per month (i.e. or the highest interest rate permitted by applicable law if less than 1.5% per month) on the balance owed until such balance is paid; (iv) pursue collection actions, in which case, Subscriber agrees to pay our reasonable attorney fees and court costs; and/or (v) take or pursue any other legal action(s).</p>
<br>

<h2>Conduct on Site</h2>
<p>Your use of this Site and the Services are subject to all applicable laws and regulations. You are solely responsible for the content of your communications through this Site. You agree that you will not disclose, upload, share, post, email, make available, or otherwise distribute or facilitate distribution of any content, data, or information that:</p>
<ol>
  <li>Is unlawful, threatening, abusive, harassing, defamatory, libelous, deceptive, fraudulent, invasive of another's privacy, tortious, contains explicit or graphic descriptions or accounts of sexual acts (including but not limited to sexual language of a violent or threatening nature directed at another individual or group of individuals), or otherwise violates our rules or policies;</li>
  <li>Victimizes, harasses, degrades, or intimidates an individual or group of individuals on the basis of religion, gender, sexual orientation, race, ethnicity, age, or disability;</li>
  <li>Infringes on or in any violates any patent, trademark, trade secret, copyright, right of publicity, license, or other proprietary right of any party;</li>
  <li>Constitutes unauthorized or unsolicited advertising, junk or bulk email, spamming, chain letters, any other form of unauthorized solicitation, or any form of lottery or gambling;</li>
  <li>Contains software viruses or any other computer code, files, or programs that are designed or intended to disrupt, damage, or limit the functioning of any software, hardware, or telecommunications equipment or to damage or obtain unauthorized access to any data or other information of any third party;</li>
  <li>Impersonates any person or entity, including any of our employees or representatives; and/or</li>
  <li>Is harmful to minors.</li>
</ol>
<br>

<h2>Our Intellectual Property</h2>
<p>You agree that we own and shall retain all right, title, and interest in and to all the intellectual property, other than Subscriber's Data (defined below), of this Site and/or that is used to provide the Services (collectively, "Our IP"). All discoveries, improvements, inventions, enhancements, error corrections, updates, recommendations/suggestions, and trade secrets, made or conceived by us,
  you, or any third party, arising out of or relating to Our IP, shall be our sole and exclusive property and we shall retain any and all rights therein, including, without limitation, the right to file any patent or copyright applications thereon. You agree to execute and deliver all documents and provide all testimony reasonably requested by us to register, maintain, and enforce Our IP in our
  name. You irrevocably designate and appoint us as your agent and attorney-in-fact to act for and on your behalf to execute, register and file any applications, and to do all other lawfully permitted acts, to further the registration, prosecution, issuance and enforcement of Our IP. All rights not expressly granted to you are reserved to us and no rights or licenses shall be deemed granted by
  implication, estoppel or otherwise.</p>
<p>Our IP includes, without limitation, the following registered trademarks, unregistered trademarks or service marks: intHRaction, LLC, intHRaction, intHRaction.com, intHRaction.net. In addition, all custom graphics, icons, logos and service names used in this Site and/or as part of the Services are registered trademarks, unregistered trademarks or service marks belonging to us. All other
  trademarks or service marks are property of their respective owners. Nothing in these Terms of Use grants you any right to use any trademark, service mark, logo, and/or name used in this Site or as part of the Services.</p>
<p>You may use Our IP during the Subscription Period solely as an end user for Subscriber's internal business use consistent with the Subscription and these Terms of Use, and you may not use Our IP at any other time and/or for any other purpose. Your use of Our IP is "as is" and we do not represent or warrant or covenant that your use of Our IP will not infringe upon the rights of third
  parties.</p>
<p>You may not, and may not allow others to: (i) use Our IP for resale or distribution, or for the benefit or on behalf of any other party other than Subscriber; (ii) alter, copy, reproduce, modify, republish, upload, post, transmit, reverse engineer, decompile, disassemble, attempt to discover the source code, rent, assign, lease, resell, distribute, or otherwise create derivatives of Our IP;
  (iii) seek to compromise the security of Our IP; (iv) use Our IP in violation of applicable law, rule, and/or regulation, including the laws of copyright, trademark, obscenity, defamation; (v) violate the rights of any person through the transmission, storage, and/or display of Our IP; (vi) transmit, use, or process any information or data that is infringing, violates the privacy or other rights
  of any third party, and/or that is comprised of ‘Protected Health Information' subject to and defined by the Health Insurance Portability and Accountability Act ("HIPAA"), and/or that would otherwise require Our IP or the Services to be HIPAA-compliant, (vii) create large bandwidth-consuming actions and/or put an unusually large load on all or any part of Our IP; and/or (viii) remove, cover,
  and/or alter any of our copyright notices and/or any trademarks, trade names, service marks and/or service names included in Our IP.</p>
<br>

<h2>Subscriber's Data</h2>
<p>"Subscriber's Data" means the information and data that Subscriber transmits to us as part of the Services. We agree that Subscriber owns and shall retain ownership of Subscriber's Data. We further agree to use commercial reasonable efforts to maintain and protect Subscriber's Data in our possession during the Subscription Period. Notwithstanding the foregoing, this Site and the Services are
  not designed or intended to be used as a disaster recovery facility or as an emergency data storage facility.</p>
<p>Accordingly, you should not rely on this Site or the Services as your only storage facility. You should preserve backup copies of all Subscriber Data and any other digital data, information or other materials that you transmit to us. YOU AGREE NOT TO HOLD US LIABLE FOR ANY CLAIMS, DAMAGES, EXPENSES, LIABILITIES, AND LOSSES ARISING FROM DAMAGE TO, ANY DELETION OF, OR ANY FAILURE TO STORE YOUR
  SUBSCRIBER DATA.</p>
<p>You hereby grant us a worldwide, perpetual, royalty-free, non-exclusive, right and license to use, process, manipulate and create derivative works from Subscriber's Data to provide the Services and to comply with our other obligations under these Terms of Use and under applicable law. You further grant us the right to access, compile and use Subscriber's Data for research and data compilation,
  provided however, that at all times, the results of such data compilation shall not identify you (the "Research Data"). You acknowledge that the Research Data shall not be deemed to be a derivative work of Subscriber's Data and that we own all rights in and to the Research Data and use of such Research Data shall be in our sole discretion.</p>
<p>We neither endorse nor assume any liability for Subscriber's Data. We generally do not pre-screen, monitor, or edit Subscriber's Data. However, we have the right, at our sole discretion, to remove any Subscriber's Data that, in our sole judgment, does not comply with these Terms of Use and any other rules of conduct for this Site, or is otherwise harmful, objectionable, or inaccurate. We are
  not responsible for any failure or delay in removing Subscriber's Data. You hereby consent to such removal and waive any claim against us arising out of such removal.</p>
<p>You represent and warrant that all Subscriber's Data is owned or licensed by you, that you are authorized to transmit all Subscriber's Data to us, that all Subscriber's Data is current, accurate, and complete, that we can license all Subscriber's Data and use all Subscriber's Data to perform the Services, and that none of Subscriber's Data infringes on any intellectual property rights of any
  third party.</p>
<p>Upon termination of the applicable Subscription, we agree to use commercially reasonable efforts to make Subscriber's Data associated with such Subscription available to Subscriber for retrieval in a format selected by us for a period of at least thirty (30) days after such termination.</p>
<br>

<h2>Your Privacy</h2>
<p>The information we obtain through your use of this Site and/or the Services is subject to our privacy policy made available through this Site</p>

<h2>Other Restrictions</h2>
<p>You may not allow anyone to use this Site, use the Services, or become a User, that competes against us or provides services that are similar to the Services.</p>
<p>Only companies that are organized or incorporated in the United States of America (the "United States") or that are United States' citizens or residents, are permitted to use this Site, use the Services, and/or agree to a Subscription.</p>
<p>For any individual User, such individual must be at least eighteen (18) years of age to use this Site and/or the Services.</p>
<p>Any information that you provide to us must be current, complete, or accurate. If we have reasonable grounds to suspect the information you provide to us is not current, complete, or accurate, then we shall have the right to immediately suspend or terminate your access to this Site and/or Services</p>
<p>You are solely responsible for maintaining the confidentiality of your user name and password and for any and all statements made and acts or omissions that occur through the use of your user name and password. Therefore, you must take steps to ensure that others do not improperly gain access to your user name and password. You agree to notify us immediately of any unauthorized use of your user
  name and password. WE WILL NOT BE LIABLE FOR ANY CLAIMS, DAMAGES, EXPENSES, LIABILITIES, AND LOSSES ARISING FROM YOUR FAILURE TO COMPLY WITH THE FOREGOING OBLIGATIONS IN THIS PARAGRAPH.</p>
<br>

<h2>Export Controls</h2>
<p>The Services are subject to United States' export controls. Accordingly, no Services may be downloaded or exported (a) into (or to a resident of) Cuba, Iraq, Libya, North Korea, Iran, Syria, or any other country which the United States has embargoed goods; or (b) anyone on the United States Treasury Department's list of Specially Designated Nationals or the United States Commerce Department's
  Table of Deny Orders. By downloading or using the Services, you represent and warrant that you are not located in, under the control of, or a national or resident of any such country or on any such list.</p>
<br>

<h2>No International Use</h2>
<p>Although this Site may be accessible worldwide, this Site is not intended to be accessed or used outside of the United States. Accordingly, we make no representation or warranty that this Site and/or the Services are appropriate or available for access or use in locations outside the United States. You are prohibited from accessing or using this Site and/or the Services outside of the United
  States if such access or use would in any way violate these Terms of Use or otherwise create any additional obligations for us.</p>
<br>

<h2>Disclaimer of Warranties</h2>
<p>THIS SITE AND THE SERVICES ARE PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS WITHOUT WARRANTY OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OF MERCHANTABILITY, TITLE, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT. WITHOUT LIMITING THE FOREGOING, WE MAKE NO WARRANTY THAT (A) THIS SITE AND/OR THE SERVICES WILL MEET YOUR REQUIREMENTS, (B)
  THIS SITE AND/OR THE SERVICES WILL BE UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE, (C) THE RESULTS THAT MAY BE OBTAINED FROM THE USE OF THIS SITE AND/OR THE SERVICES WILL BE EFFECTIVE, ACCURATE OR RELIABLE, OR (D) THE QUALITY OF THIS SITE, THE SERVICES, AND ANY PRODUCTS OR INFORMATION PURCHASED OR OBTAINED BY YOU FROM THIS SITE FROM US OR OUR AFFILIATES WILL MEET YOUR EXPECTATIONS OR BE FREE
  FROM MISTAKES, ERRORS OR DEFECTS.
  THIS SITE AND/OR THE SERVICES COULD INCLUDE TECHNICAL OR OTHER MISTAKES, INACCURACIES OR TYPOGRAPHICAL ERRORS. THIS SITE AND/OR THE SERVICES MAY BE OUT OF DATE, AND WE MAKE NO COMMITMENT TO UPDATE THIS SITE AND/OR THE SERVICES. THE USE OF THIS SITE AND/OR THE SERVICES OR THE DOWNLOADING OR OTHER ACQUISITION OF ANY INFORMATION THROUGH THIS SITE IS DONE AT YOUR OWN DISCRETION AND RISK AND WITH
  YOUR AGREEMENT THAT YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR COMPUTER SYSTEM OR LOSS OF DATA THAT RESULTS FROM SUCH ACTIVITIES.</p>
<br>

<h2>LIMITATION OF LIABILITY</h2>
<p>TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT WILL WE OR OUR OWNERS, MANAGERS, OFFICERS, AGENTS, EMPLOYEES, CONTRACTORS, AND EACH OF OUR AND/OR THEIR RESPECTIVE SUCCESSORS AND ASSIGNS, BE LIABLE FOR ANY CLAIMS AND/OR DAMAGES, WHETHER ARISING IN TORT, CONTRACT, OR OTHERWISE, AND WHETHER A PARTY HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH CLAIMS AND/OR DAMAGES, IN CONNECTION
  WITH: ANY CLAIMS AND/OR DAMAGES BASED UPON A THIRD PARTY CLAIM; ANY INCIDENTAL, CONSEQUENTIAL, SPECIAL, INDIRECT, EXEMPLARY OR PUNITIVE CLAIMS AND/OR DAMAGES; ANY CLAIMS AND/OR DAMAGES ARISING OUT OF OR IN CONNECTION WITH ANY MALFUNCTIONS, DELAYS, LOSS OF DATA, LOST PROFITS, LOST SAVINGS, INTERRUPTION OF SERVICE, LOSS OF BUSINESS OR ANTICIPATORY PROFITS; ANY MATTERS OUT OF OUR AND/OR THEIR
  REASONABLE CONTROL; ANY SUBSCRIBER DATA; AND/OR ANY AND ALL DIRECT CLAIMS AND/OR DAMAGES IN EXCESS OF THE FEES PAID OR PAYABLE BY THE APPLICABLE SUBSCRIBER TO US FOR THE SERVICES DURING THE THREE (3) MONTH PERIOD IMMEDIATELY PRIOR TO THE APPLICABLE EVENT GIVING RISE TO THE APPLICABLE CLAIM(S) AND/OR DAMAGES.</p>
<br>

<h2>Indemnification</h2>
<p>You agree to defend, indemnify, and hold harmless us and our owners, managers, officers, agents, employees, contractors, and each of our and/or their respective successors and assigns from and against all claims, damages, expenses, liabilities, and losses including without limitation, reasonable attorney's fees, that arise from: (i) your use or misuse of this Site and/or the Services; (ii) your
  breach of your Subscription and/or your failure to strictly comply with these Terms of Use; (iii) your violation of any applicable laws, rules, and/or regulations; and/or (iv) your negligence or intentional misconduct. We reserve the right to assume the exclusive defense and control of any matter otherwise subject to indemnification by you, in which event you will cooperate with us in asserting
  any available defenses.</p>
<br>

<h2>Governing Law & Jurisdiction</h2>
<p>These Terms of Use shall be controlled, construed and enforced in accordance with the substantive laws of the State of Georgia, without regard to any laws related to choice or conflicts of laws. Any claim, dispute, or legal proceeding ("Litigation") arising out of or in any way related to these Terms of Use, this Site, and/or the Services shall be brought in any state or federal court within
  the State of Georgia, to the exclusion of all other jurisdictions. For any Litigation, in which a party prevails over another party, the reasonable attorneys' fees, courts costs and Litigation expenses incurred by the prevailing party in connection with such Litigation shall be paid for or reimbursed by the non-prevailing party.</p>
<br>

<h2>Notices</h2>
<p>All notices to a party shall be in writing and shall be made either via email or conventional mail. Notices to us must be sent by email to: legal@inthraction.com; or be sent by conventional mail to: intHRaction, LLC, Attn: Legal, 1360 Union Hill Rd., Suite 6E, Alpharetta, GA 30004. Notices to you may be sent to the email address supplied to us or to the email address or conventional mail
  address of the applicable Subscriber. In addition, we may broadcast notices or messages through this Site to inform you of changes to this Site or the Services or other matters of importance, and such broadcasts shall constitute notice to you.</p>
<br>

<h2>Miscellaneous</h2>
<p>These Terms of Use may not be altered, supplemented, or amended by the use of any other document(s) that are not otherwise provided by us. To the extent that anything in or associated with this Site is in conflict or inconsistent with these Terms of Use, these Terms of Use shall take precedence.</p>
<p>Any cause of action brought by you against us and/or our owners, managers, officers, agents, employees, contractors, and/or each of our and/or their respective successors and assigns must be instituted by you with one (1) year after the cause of action arises or be deemed forever waived and barred.</p>
<p>You may not assign your rights and obligations under these Terms of Use to any third party, and any purported attempt to do so shall be null and void. We may freely assign our rights and obligations under these Terms of Use.</p>
<p>In addition to any excuse provided by applicable law, we shall be excused from liability for non-delivery or delay in delivery of the Services arising from any event beyond our reasonable control, whether or not foreseeable by either party, including but not limited to, labor disturbance, war, fire, accident, adverse weather, inability to secure transportation, and/or governmental act or
  regulation.</p>
<p>If any part of these Terms of Use is held invalid or unenforceable, that portion shall be construed in a manner consistent with applicable law to reflect, as nearly as possible, the original intentions of the parties, and the remaining portions shall remain in full force and effect.</p>
<p>Any failure by us to enforce or exercise any provision of these Terms of Use or related rights shall not constitute a waiver of that right or provision.</p>
