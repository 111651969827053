<h1 mat-dialog-title>{{addMode ? 'Add' : 'Edit'}} Objective Domain</h1>
<form class="add-org-defined-objective-domain" (ngSubmit)="onSaveClick(form.value, submitBtn)" [formGroup]="form" autocomplete="off" novalidate>
  <div mat-dialog-content>
    <mat-form-field class="full-width">
      <mat-label>Objective Domain Title</mat-label>
      <input matInput formControlName="domainTitle">
      <mat-error *ngIf="hasError('domainTitle', 'required')">Title is required</mat-error>
    </mat-form-field>
    <mat-form-field *ngIf="addMode" class="full-width">
      <mat-label>Objective Domain Key</mat-label>
      <input matInput formControlName="domainKey">
      <mat-error *ngIf="hasError('domainKey', 'required')">Key is required</mat-error>
    </mat-form-field>
    <mat-form-field floatLabel="always" appearance="outline" class="slide-toggle-form-field full-width">
      <mat-label>Exclude from TPS</mat-label>
      <mat-slide-toggle formControlName="excludeFromTPS"></mat-slide-toggle>
      <input matInput hidden height="0" style="height: 0" />
    </mat-form-field>

    <div formGroupName="sites">
      <h2>Sites</h2>
      <mat-divider></mat-divider>
      <br/>
      <mat-form-field *ngFor="let site of siteCodes" floatLabel="always" appearance="outline" class="slide-toggle-form-field half-width">
        <mat-label>{{site}}</mat-label>
        <mat-slide-toggle [formControlName]="site"></mat-slide-toggle>
        <input matInput hidden height="0" style="height: 0" />
      </mat-form-field>
      <mat-error *ngIf="hasError('sites', 'required')">A site is required</mat-error>
    </div>

  </div>
  <div mat-dialog-actions>
    <button (click)="onCancelClick()" mat-button type="button">Cancel</button>
    <button #submitBtn [disabled]="!form.valid" color="primary" mat-raised-button type="submit">Save</button>
  </div>
</form>
