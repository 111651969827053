import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  selector: 'inthraction-page-header',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.scss']
})
export class PageHeaderComponent {

  @Input() pageTitle: string;
  @Input() addBtnTitle: string;
  @Input() downloadBtn: boolean = false;
  @Input() addBtnDisabled:boolean = false;
  @Input() downloadBtnDisabled:boolean = false;
  @Output() addBtnClickEvent = new EventEmitter();
  @Output() downloadBtnClickEvent = new EventEmitter();

}
