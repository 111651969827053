<div *ngIf="chartData.length > 0" class="interaction-score-chart-container">
  <inthraction-score-card [score]="score" scale="6" class="current-score"></inthraction-score-card>
  <mat-icon *ngIf="startingScore && startingScore > score" style="color: red" class="trend-icon">trending_down</mat-icon>
  <mat-icon *ngIf="startingScore && startingScore == score" class="trend-icon">trending_flat</mat-icon>
  <mat-icon *ngIf="startingScore && startingScore < score" style="color: green" class="trend-icon">trending_up</mat-icon>
  <inthraction-score-card [score]="startingScore" scale="6" class="starting-score"></inthraction-score-card>
  <ngx-charts-line-chart
    (activate)="activate($event)"
    (deactivate)="deactivate($event)"
    (select)="select($event)"
    [animations]="animations"
    [autoScale]="autoScale"
    [curve]="curve"
    [gradient]="gradient"
    [legendPosition]="legendPosition"
    [legendTitle]="legendTitle"
    [legend]="showLegend"
    [maxXAxisTickLength]="maxXAxisTickLength"
    [maxYAxisTickLength]="maxYAxisTickLength"
    [rangeFillOpacity]="rangeFillOpacity"
    [results]="chartData"
    [rotateXAxisTicks]="rotateXAxisTicks"
    [roundDomains]="roundDomains"
    [schemeType]="schemeType"
    [scheme]="colorScheme"
    [showGridLines]="showGridLines"
    [showXAxisLabel]="showXAxisLabel"
    [showYAxisLabel]="showYAxisLabel"
    [timeline]="timeline"
    [tooltipDisabled]="tooltipDisabled"
    [trimXAxisTicks]="trimXAxisTicks"
    [trimYAxisTicks]="trimYAxisTicks"
    [view]="view"
    [xAxisLabel]="xAxisLabel"
    [xAxis]="showXAxis"
    [xScaleMax]="xScaleMax"
    [xScaleMin]="xScaleMin"
    [yAxisLabel]="yAxisLabel"
    [yAxis]="showYAxis"
    [yScaleMax]="yScaleMax"
    [yScaleMin]="yScaleMin"
    class="chart-container"
  >
  </ngx-charts-line-chart>
</div>
<div *ngIf="chartData.length > 0" class="chart-tip"><mat-icon>help_outline</mat-icon> Click a point on the chart to view specific details about that intHRaction</div>
