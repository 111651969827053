<inthraction-page-header pageTitle="Org Chart" addBtnTitle="Add User" (addBtnClickEvent)="openAdd()">
  <nav mat-tab-nav-bar [tabPanel]="tabPanel" *ngIf="topEmployees?.length">
    <a mat-tab-link *ngFor="let topE of topEmployees" aria-label="{{topE.title}}" [active]="activeLink == topE.id" (click)="activeLink = topE.id">{{topE.title}}</a>
    <a mat-tab-link *ngIf="disabledEmployees.length > 0" aria-label="Disabled Users" [active]="activeLink == 'disabled'" (click)="activeLink = 'disabled'">Disabled Users</a>
    <a mat-tab-link *ngIf="consultantAssociations?.length > 0" aria-label="Consultants"  [active]="activeLink == 'consultants'" (click)="activeLink = 'consultants'">Consultants</a>
  </nav>
  <mat-tab-nav-panel #tabPanel></mat-tab-nav-panel>
</inthraction-page-header>

<div class="orgchart-container" *ngIf="topEmployees?.length">
  <ng-container *ngFor="let topE of topEmployees">
    <div class="oc-scroll-wrapper" *ngIf="activeLink == topE.id">
      <inthraction-ng-org-chart (chartChangeEvent)="reloadOrgChart()" [topEmployee]="topE"></inthraction-ng-org-chart>
    </div>
  </ng-container>
  <div class="employee-list-container" *ngIf="activeLink == 'disabled'">
    <mat-card appearance="outlined" (click)="openEditDisabledEmployee(emp)" *ngFor="let emp of disabledEmployees" aria-label="Edit Disabled User" class="employee-link-card" title="Edit Disabled User">
      <mat-card-content>
        <div class="employee-link-image ">
          <inthraction-profile-image [employeeImage]="disabledEmployeeImageMap.get(emp.id)"></inthraction-profile-image>
        </div>
        <div>
          <mat-card-subtitle class="employee-name">{{emp.firstName}} {{emp.lastName}}</mat-card-subtitle>
          <mat-card-subtitle class="employee-title">{{emp.title}}</mat-card-subtitle>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
  <div class="employee-list-container" *ngIf="activeLink == 'consultants'">
    <mat-card appearance="outlined" (click)="consultantEdit(assn)" *ngFor="let assn of consultantAssociations" aria-label="Edit Consultant Status" [class]="'employee-link-card ' + assn.status" title="Edit Consultant Status">
      <mat-card-content>
        <div class="employee-link-image ">
          <inthraction-profile-image [employeeImage]="consultantEmployeeImageMap.get(assn.employeeID)"></inthraction-profile-image>
        </div>
        <div>
          <mat-card-subtitle class="employee-name">{{consultantsMap.get(assn.employeeID).firstName}} {{consultantsMap.get(assn.employeeID).lastName}}</mat-card-subtitle>
          <mat-card-subtitle class="employee-title">{{consultantsMap.get(assn.employeeID).title}}</mat-card-subtitle>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>

<inthraction-loading className="orgchart-container" [initDone]="topEmployees?.length > 0"></inthraction-loading>
