<inthraction-page-header pageTitle="Consultant Objective Configuration" addBtnTitle="Add Consultant Objective" (addBtnClickEvent)="addNewObjective()" [addBtnDisabled]="!ready"></inthraction-page-header>
<div *ngIf="ready" class="consultant-objective-configuration-container">
  <div class="objectivesContainer">
    <mat-card appearance="outlined" *ngFor="let objective of consultantObjectivesList">
      <mat-card-header>
        <div class="title-wrapper">
          <mat-card-title>{{objective.display}}</mat-card-title>
        </div>
        <div class="objectiveSubtitleWrapper">
          <mat-card-subtitle>{{objective.definition}}</mat-card-subtitle>
        </div>
      </mat-card-header>
      <mat-card-content>
        <ul class="objectiveObservables">
          <li *ngFor="let observable of objective.observables">{{observable}}</li>
        </ul>
      </mat-card-content>
      <mat-card-actions>
        <button (click)="editObjective(objective)" color="primary" mat-stroked-button>Edit</button>
        <div class="spacer"></div>
        <button (click)="deleteObjective(objective.id)" *ngIf="!activeObjectiveIdSet.has(objective.id)" mat-button>Delete</button>
      </mat-card-actions>
    </mat-card>
  </div>
</div>

<inthraction-loading className="consultant-objective-configuration-container" [initDone]="ready"></inthraction-loading>
