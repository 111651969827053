export const INSPHRATION_ICONS_HELP = "inspHRations recognize people who exemplify Company Core Values.  Any inspHRations that you have received from others are displayed with a corresponding icon and a count of how many times you have been recognized for each Core Value.  You can view the Organizational summary of inspHRations received by clicking on the \"Page\" icon in the top right corner of the panel.";

export const OPEN_INTHRACTIONS_HELP = "intHRaction (meetings / events), Objective, Project and Milestone surveys appear here and are available to be responded to based on expiration times defined by a system administrator.";

export const RECENT_INTHRACTIONS_HELP = "Recent surveys that have been responded to, about you, appear as \"Recent intHRactions\"";

export const MISSED_MEETINGS_HELP = "Any intHRaction (meetings / events) Surveys that you respond to as \"I did not attend\" appear here.";

export const OPEN_INSPHRATIONS_HELP = "inspHRations recognize people who exemplify Company Core Values. A single inspHRation is created for you each week to be able to recognize someone in the Organization.  Should you choose not to share your inspHRation, it will be cancelled and a new inspHRation created the following week.  This provides you 52 opportunities, each year, to share recognition with others.";

export const INSPHRATIONS_GIVEN_HELP = "When you share an inspHRation with someone in the Organization, a summary of each inspHRation appears here in chronological order.";

export const INSPHRATIONS_RECEIVED_HELP = "When you receive an inspHRation from someone in the Organization, a summary of each inspHRation appears here in chronological order.";

export const INTHRACTION_TREND_HELP = "Your intHRaction rating, based on survey feedback, is recorded and charted in this graph.";

export const OBJECTIVE_PANEL_HELP = "The Objectives that you and your Manager have collaborated on, Developmental Objectives provided by Human Resources, and Learning / Project Objectives provided by internal /external Consultants, appear here.  As you receive feedback on Objectives, your progress is tracked and presented to you, by Objective, in these panels.";

export const BIO_ASPIRATIONS_GROWTH_HELP = "We encourage you to share your Personal Biography, Career Aspirations and any Learning or Personal Growth activities here, which will be visible to both your Manager and HR.";

export const COMMUNICATION_HELP = "Your dashboard is equipped with three private communication channels that enable you to communicate with your Manager, Human Resources, and Project Leaders / Consultants.";

export const MANAGER_COMMUNICATION_HELP = "Communication between you and your Manager may take the form of impromptu notes, recaps of 1 on 1's, updates on objective progress, and more. This channel is also viewable by HR.";

export const HR_COMMUNICATION_HELP = "Communication between you and your Human Resources team. This channel is not viewable by anyone else in the Organization.";

export const CONSULTANT_COMMUNICATION_HELP = "Communication between you and Project Leaders / Consultants that use the Project Dashboard. If you are not a member of any Learning or Project Teams, you will not see any communication in this channel.  This information is not available for your Manager to view, but is available to HR team members.";
