import { Component, OnInit } from "@angular/core";

@Component({
  selector: "inthraction-executive-dashboard",
  templateUrl: "./executive-dashboard.component.html",
  styleUrls: ["./executive-dashboard.component.scss"]
})
export class ExecutiveDashboardComponent implements OnInit {

  constructor() {
  }

  ngOnInit() {
  }

}
