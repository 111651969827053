import { Component, OnInit } from "@angular/core";
import { ObjectiveDistributionDetailsModes } from "../../../components/objective-distribution/DistributionDetails";

@Component({
  selector: "inthraction-hr-objective-distribution",
  templateUrl: "./hr-objective-distribution.component.html",
  styleUrls: ["./hr-objective-distribution.component.scss"]
})
export class HrObjectiveDistributionComponent implements OnInit {

  readonly ObjectiveDistributionDetailsModes = ObjectiveDistributionDetailsModes;

  constructor() {
  }

  ngOnInit() {
  }

}
