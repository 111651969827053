<h1 mat-dialog-title>{{editMode ? 'Edit' : 'Add'}} Project Note</h1>
<form [formGroup]="noteFG" (ngSubmit)="onSaveClick(noteFG.value)" autocomplete="off" novalidate>
  <div mat-dialog-content>
    <mat-form-field class="full-width">
      <mat-label>Title</mat-label>
      <input matInput formControlName="title" maxlength="256">
      <mat-error *ngIf="hasError('title', 'required')">Title is required</mat-error>
    </mat-form-field>

    <mat-form-field class="full-width">
      <mat-label>Description</mat-label>
      <textarea matInput formControlName="description" maxlength="2000" rows="4" cdkTextareaAutosize></textarea>
      <mat-error *ngIf="hasError('description', 'required')">Description is required</mat-error>
    </mat-form-field>

    <div class="full-width">
      <mat-slide-toggle formControlName="displayPublic">Public</mat-slide-toggle>
    </div>

  </div>
  <div mat-dialog-actions>
    <button (click)="onCancelClick()" mat-button type="button">Cancel</button>
    <button [disabled]="!noteFG.valid" color="primary" mat-raised-button type="submit">Save</button>
  </div>
</form>
