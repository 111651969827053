import { Component, Input, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { MilestoneComment } from "@inthraction/data-models";
import { EmployeeService } from "@inthraction/services";

@Component({
  selector: "inthraction-milestone-comments",
  templateUrl: "./milestone-comments.component.html",
  styleUrls: ["./milestone-comments.component.scss"]
})
export class MilestoneCommentsComponent implements OnInit, OnChanges {

  @Input() comments: MilestoneComment[];
  employeeNameMap = new Map<string, string>();

  constructor(
    private employeeService: EmployeeService
  ) {
  }

  async ngOnInit() {
    await this.loadEmployeeNames(this.comments);
  }

  private async loadEmployeeNames(comments) {
    if (comments) {
      for (const comment of comments) {
        if (!this.employeeNameMap.has(comment.createdBy)) {
          const employee = await this.employeeService.getEmployeeByIDMemoize(comment.createdBy);
          this.employeeNameMap.set(comment.createdBy, `${employee.firstName} ${employee.lastName}`);
        }
      }
    }
  }

  async getEmployeeName(createdBy: string): Promise<string> {
    if (!this.employeeNameMap.has(createdBy)) {
      const employee = await this.employeeService.getEmployeeByIDMemoize(createdBy);
      this.employeeNameMap.set(createdBy, `${employee.firstName} ${employee.lastName}`);
    }
    return this.employeeNameMap.get(createdBy);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.loadEmployeeNames(changes.comments.currentValue);
  }
}
