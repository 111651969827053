import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";
import { Employee, Note } from "@inthraction/data-models";
import { EmployeeService, NoteService } from "@inthraction/services";
import { NoteHistoryDialogComponent } from "../note-history-dialog/note-history-dialog.component";
import { MatDialog } from "@angular/material/dialog";
import { NoteTypes } from "@inthraction/codes";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "inthraction-notes",
  templateUrl: "./notes.component.html",
  styleUrls: ["./notes.component.scss"]
})
export class NotesComponent implements OnInit, OnChanges {

  @Input() notes: Note[];
  @Input() options: NotesOptions;
  @Input() user: Employee;

  @Output() createNoteEvent = new EventEmitter();
  @Output() editNoteEvent = new EventEmitter();
  @Output() reviewNoteEvent = new EventEmitter();

  _notes: Note[];
  page: Note[];
  pageNumber = 0;
  noteIDToEdit: string;
  employeeNameMap = new Map<string, string>();
  currentNoteIndex = 0;
  NoteTypes = NoteTypes;

  constructor(
    private employeeService: EmployeeService,
    private noteService: NoteService,
    private toastr: ToastrService,
    public dialog: MatDialog
  ) {
  }

  async ngOnInit() {
    if (this.notes) {
      await this.initializeNotes(this.notes);
    }
  }

  async initializeNotes(notes: Note[]) {

    this._notes = notes.sort((a, b) => a.createdAt > b.createdAt ? -1 : a.createdAt < b.createdAt ? 1 : 0);
    this.noteIDToEdit = this._notes[0]?.id;

    for (const note of notes) {
      if (!this.employeeNameMap.has(note.createdBy)) {
        const employee = await this.employeeService.getEmployeeByIDMemoize(note.createdBy);
        this.employeeNameMap.set(note.createdBy, `${employee.firstName} ${employee.lastName}`);
      }
      if (note.comments && note.comments.length > 0) {
        for (const comment of note.comments) {
          if (!this.employeeNameMap.has(comment.createdBy)) {
            const employee = await this.employeeService.getEmployeeByIDMemoize(comment.createdBy);
            this.employeeNameMap.set(comment.createdBy, `${employee.firstName} ${employee.lastName}`);
          }
        }
      }
    }
    this.pageNotes(0);
    this.currentNoteIndex = this._notes?.length > 6 ? 1 : 0;
  }

  previousPage() {
    this.pageNotes(this.pageNumber - 1);
  }

  hasNextPage(): boolean {
    return (this.pageNumber + 1) < Math.ceil(this._notes.length / 6);
  }

  nextPage() {
    this.pageNotes(this.pageNumber + 1);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.notes && changes.notes.currentValue) {
      this.initializeNotes(changes.notes.currentValue);
    }
  }

  async saveNoteComment(noteID: string, organizationID: string, value: string, event: any) {
    const newComment = await this.noteService.createNoteComment(noteID, organizationID, value, this.user.id);
    const note = this._notes.find(elm => elm.id === noteID);
    note.comments.unshift(newComment);
    this.toastr.success("Comment Added");
    event.target.reset();
  }

  openNoteHistory(id: string) {
    this.dialog.open(NoteHistoryDialogComponent, {
      width: "600px",
      data: {
        note: this._notes.find(elm => elm.id === id)
      }
    });
  }

  editNote(id: string) {
    this.editNoteEvent.emit({ noteId: id });
  }

  reviewNote(id: string) {
    this.reviewNoteEvent.emit({ noteId: id });
  }

  private pageNotes(page: number) {
    if (page * 6 > this._notes.length) {
      page = 0;
    }
    this.pageNumber = page;
    const start = page * 6;
    const view = [];
    for (let i = 0; i < 6 && start + i < this._notes.length; i++) {
      view.push(this._notes[start + i]);
    }
    this.page = view;
  }
}

export interface NotesOptions {
  title: string;
  enableCreate?: boolean;
  enableEdit?: boolean;
  enableReview?: boolean;
  disableComments?: boolean;
}
