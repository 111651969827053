import { STANDUP_FREQUENCY_LABELS } from "@inthraction/labels";

export type StandupFrequencyType = keyof typeof STANDUP_FREQUENCY_LABELS;

export const STANDUP_FREQUENCY_TYPES = Object.keys(STANDUP_FREQUENCY_LABELS) as StandupFrequencyType[];

export enum STANDUP_FREQUENCY_TYPE_CODES {
  DAILY = "DAILY",
  WEEKLY = "WEEKLY",
  BI_WEEKLY = "BI_WEEKLY",
  MONTHLY = "MONTHLY",
  QUARTERLY = "QUARTERLY",
  ANNUALLY = "ANNUALLY"
}
