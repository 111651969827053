import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { ObjectiveResponseDistributionMode } from "../objective-response-distribution.component";
import { Employee } from "@inthraction/data-models";

@Component({
  selector: "app-objective-response-distribution-dialog",
  templateUrl: "./objective-response-distribution-dialog.component.html",
  styleUrls: ["./objective-response-distribution-dialog.component.scss"]
})
export class ObjectiveResponseDistributionDialogComponent implements OnInit {

  readonly employee: Employee;
  readonly mode: ObjectiveResponseDistributionMode;

  constructor(
    public dialogRef: MatDialogRef<ObjectiveResponseDistributionDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ObjectiveResponseDistributionDialogData
  ) {
    this.mode = data.mode;
    this.employee = data.employee;
  }

  ngOnInit(): void {
  }

}

export interface ObjectiveResponseDistributionDialogData {
  employee: Employee;
  mode: ObjectiveResponseDistributionMode;
}
