import { Component, Input, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { EmployeeImageInterface } from "@inthraction/data-models";
import { Storage } from "aws-amplify";

@Component({
  selector: "inthraction-profile-image",
  templateUrl: "./profile-image.component.html",
  styleUrls: ["./profile-image.component.scss"]
})
export class ProfileImageComponent implements OnInit, OnChanges {

  @Input() employeeImage: EmployeeImageInterface;

  signedURL: string;
  readonly isDealHRShip: boolean = window.location.hostname.toLowerCase().includes("dealhrship");

  constructor() {
  }

  async ngOnInit() {
    await this.initializeOptions(this.employeeImage);
  }

  async initializeOptions(employeeImage: EmployeeImageInterface) {
    if (employeeImage && employeeImage.imageIdentityId) {
      this.signedURL = await Storage.get(employeeImage.image, {
        level: "protected",
        download: false,
        identityId: employeeImage.imageIdentityId
      });
    } else {
      this.signedURL = null;
    }
  }

  async ngOnChanges(changes: SimpleChanges) {
    if (changes.employeeImage) {
      await this.initializeOptions(changes.employeeImage.currentValue);
    }
  }

}

export interface s3ImageComponentOptions {
  level: string;
  identityId: string;
}
