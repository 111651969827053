<div class="survey-page-container">
  <div></div>
  <mat-card appearance="outlined">
    <mat-card-title>Unsubscribe</mat-card-title>
    <mat-card-subtitle>You can update your preferences for receiving performance feedback requests below.</mat-card-subtitle>
    <div class="survey-container">
      <form (ngSubmit)="submitSurvey(form.value, submitButtonElement)" [formGroup]="form" autocomplete="off" novalidate>
        <mat-form-field class="full-width">
          <input matInput style="display: none">
          <mat-radio-group aria-label="Select an option" formControlName="doNotDisturb">
            <mat-radio-button value="1">Do NOT send me feedback requests.</mat-radio-button>
            <mat-radio-button value="0">Please continue to send me feedback requests.</mat-radio-button>
          </mat-radio-group>
        </mat-form-field>

        <mat-form-field class="full-width">
          <mat-label>Email Address</mat-label>
          <input matInput formControlName="email" lowercase placeholder="jane.doe@example.com">
          <mat-error *ngIf="hasError('email', 'required')">Email Address is required</mat-error>
          <mat-error *ngIf="hasError('email', 'email') || hasError('email', 'pattern')">Please enter a valid email address</mat-error>
        </mat-form-field>
        <button #submitButtonElement [disabled]="!form.valid" color="primary" mat-raised-button type="submit">Submit</button>
      </form>
    </div>
  </mat-card>
  <div></div>
</div>
