import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from "@angular/core";
import { Employee, EmployeeImageInterface, StandupSurvey, StandupSurveyConfiguration } from "@inthraction/data-models";
import { SurveyCompleteEvent } from "../survey/survey.component";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { EmployeeService, StandupService } from "@inthraction/services";
import { ToastrService } from "ngx-toastr";
import { MatButton } from "@angular/material/button";
import { QuestionsMap } from "@inthraction/data-mappers";
import { MatCheckbox, MatCheckboxChange } from "@angular/material/checkbox";
import moment from "moment";

@Component({
  selector: "inthraction-standup-survey",
  templateUrl: "./standup-survey.component.html",
  styleUrls: ["./standup-survey.component.scss"]
})
export class StandupSurveyComponent implements OnInit, OnChanges {

  @Input() survey: StandupSurvey;
  @Input() employee: Employee;
  @Input() remainingSurveysCount? = 0;
  @Output() surveyComplete: EventEmitter<SurveyCompleteEvent> = new EventEmitter<SurveyCompleteEvent>();

  @ViewChild("submitBtn") submitBtn: ElementRef<MatButton | HTMLButtonElement>;

  surveyForm: FormGroup;
  standupConfiguration: StandupSurveyConfiguration;
  employeeImage: EmployeeImageInterface;
  errorMessage = "Please try again.";

  constructor(
    private employeeService: EmployeeService,
    private standupService: StandupService,
    protected toastr: ToastrService
  ) {

  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.survey && changes.survey.currentValue) {
      this.surveyForm = null;
      this.standupConfiguration = null;
      this.employeeImage = null;
      this.initialize(changes.survey.currentValue);
    }
  }

  private async initialize(survey: StandupSurvey) {
    if (survey) {
      this.employeeImage = await this.employeeService.getEmployeeImageMemoize(this.employee.id, this.employee.orgId);
      this.standupConfiguration = await this.standupService.getStandupConfigurationByID(survey.standupSurveyConfigurationID);

      const controls = {};
      controls["chatRequest"] = new FormControl(false, []);
      controls["meetingRequest"] = new FormControl(false, []);
      let i = 0;
      for (const questionMap of survey.questionMaps) {
        controls[`feedback${i}`] = new FormControl("", [Validators.required]);
        i++;
      }
      this.surveyForm = new FormGroup(controls);
    }
  }

  async submitSurvey(formValue: any, submitBtn: MatButton | HTMLButtonElement): Promise<void> {
    submitBtn.disabled = true;
    if (this.surveyForm.valid) {
      this.survey.responseReceived = true;
      this.survey.responseReceivedDate = moment().milliseconds(0).toISOString().replace(".000Z", "Z");

      let i = 0;
      const feedbackArray = [];
      for (const questionMap of this.survey.questionMaps) {
        feedbackArray.push(formValue[`feedback${i}`]);
        i++;
      }
      this.survey.feedbackArray = feedbackArray;

      await this.standupService.updateSurvey(
        {
          id: this.survey.id,
          responseReceived: true,
          responseReceivedDate: this.survey.responseReceivedDate,
          feedbackArray: this.survey.feedbackArray,
          chatRequest: formValue.chatRequest,
          meetingRequest: formValue.meetingRequest
        }
      );
      this.toastr.success("Thank you for your response", "Stand-Up Received");
      this.surveyComplete.emit({ completedSurveyIDs: [this.survey.id] });
    } else {
      submitBtn.disabled = !this.surveyForm.valid;
      this.toastr.error(this.errorMessage);
    }
  }

  getStandupQuestion(configuration: StandupSurveyConfiguration, questionMap: QuestionsMap): string {
    const question = configuration.questions.find(q => q.id == questionMap.id);
    return question.questionTextArray[questionMap.version];
  }

  public hasError = (controlName: string, errorName: string) =>
    this.surveyForm.controls[controlName].hasError(errorName);

  changeCheckBoxEvent(event: MatCheckboxChange, checkbox: MatCheckbox, formControlName: string) {
    if (event.checked && checkbox.checked) {
      checkbox.checked = false;
      this.surveyForm.controls[formControlName].setValue(false);
    }
  }
}
