import { AfterViewInit, Component, Input, OnInit, ViewChild } from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import { EmployeeObjectiveAssignmentDetailRecord } from "./EmployeeObjectiveAssignmentRecord";
import { MatSort } from "@angular/material/sort";
import { MatDialog } from "@angular/material/dialog";
import { ObjectiveDialogComponent } from "../objective-dialog/objective-dialog.component";

@Component({
  selector: "inthraction-employee-objective-assignments-detail",
  templateUrl: "./employee-objective-assignments-detail.component.html",
  styleUrls: ["./employee-objective-assignments-detail.component.scss"]
})
export class EmployeeObjectiveAssignmentsDetailComponent implements OnInit, AfterViewInit {

  @Input() objectiveAssignmentData: MatTableDataSource<EmployeeObjectiveAssignmentDetailRecord>;
  @ViewChild(MatSort) sort: MatSort;

  subgridColumns: string[] = ["domain", "objective", "objectiveType", "surveyType", "start", "end", "score", "responseCount"];

  constructor(
    private dialog: MatDialog
  ) {
  }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    if (this.objectiveAssignmentData) {
      this.objectiveAssignmentData.sort = this.sort;
    }
  }

  showObjectiveDetail(objectiveAssignmentId: string) {
    this.dialog.open(ObjectiveDialogComponent, {
      width: "800px",
      data: { objectiveAssignmentID: objectiveAssignmentId }
    });

  }
}
