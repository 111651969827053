<h1 mat-dialog-title>Assign Readiness</h1>
<div mat-dialog-content class="two-columns">
  <div class="col">
    <mat-radio-group
      aria-labelledby="example-radio-group-label"
      class="example-radio-group"
      [(ngModel)]="readinessValue">
      <mat-radio-button class="example-radio-button" *ngFor="let type of readinessTypes" [value]="type">
        {{readinessLabels[type]}}
      </mat-radio-button>
    </mat-radio-group>
  </div>
  <div class="col">
    <mat-form-field class="full-width">
      <mat-label>Comments</mat-label>
      <textarea matInput cdkTextareaAutosize [(ngModel)]="readinessComments"></textarea>
    </mat-form-field>
    <div *ngIf="readinessValue === 'READY_NOW' || readinessValue === 'SIX_EIGHTEEN_MO'">
      <mat-form-field class="full-width">
        <mat-label>Opportunity 1</mat-label>
        <textarea matInput cdkTextareaAutosize [(ngModel)]="readinessOpportunity1"></textarea>
      </mat-form-field>
      <mat-form-field class="full-width">
        <mat-label>Opportunity 2</mat-label>
        <textarea matInput cdkTextareaAutosize [(ngModel)]="readinessOpportunity2"></textarea>
      </mat-form-field>
      <mat-form-field class="full-width">
        <mat-label>Opportunity 3</mat-label>
        <textarea matInput cdkTextareaAutosize [(ngModel)]="readinessOpportunity3"></textarea>
      </mat-form-field>
    </div>
  </div>
</div>
<div mat-dialog-actions>
  <button (click)="onCancelClick()" mat-button type="button">Cancel</button>
  <button [disabled]="!readinessValue" color="primary" mat-raised-button type="submit" (click)="onSaveClick()">Save</button>
</div>
