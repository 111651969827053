import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

export interface StandardDialogData {
  title: string;
  message: string;
}

@Component({
  selector: "inthraction-help-dialog",
  templateUrl: "./help-dialog.component.html",
  styleUrls: ["./help-dialog.component.scss"]
})
export class HelpDialogComponent {

  constructor(
    public dialogRef: MatDialogRef<HelpDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: StandardDialogData
  ) {
  }

  onCloseClick(): void {
    this.dialogRef.close();
  }

}
