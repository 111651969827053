<mat-table matSort [dataSource]="objectiveAssignmentData">
  <ng-container matColumnDef="domain">
    <mat-header-cell mat-sort-header *matHeaderCellDef sortActionDescription="Sort by Domain">Domain</mat-header-cell>
    <mat-cell *matCellDef="let cellData"> {{cellData.domain}}</mat-cell>
  </ng-container>
  <ng-container matColumnDef="objective">
    <mat-header-cell mat-sort-header *matHeaderCellDef sortActionDescription="Sort by Objective">Objective</mat-header-cell>
    <mat-cell *matCellDef="let cellData"> {{cellData.objective}}</mat-cell>
  </ng-container>
  <ng-container matColumnDef="objectiveType">
    <mat-header-cell mat-sort-header *matHeaderCellDef sortActionDescription="Sort by Objective Type">Objective Type</mat-header-cell>
    <mat-cell *matCellDef="let cellData"> {{cellData.objectiveType}}</mat-cell>
  </ng-container>
  <ng-container matColumnDef="surveyType">
    <mat-header-cell mat-sort-header *matHeaderCellDef sortActionDescription="Sort by Survey Type">Survey Type</mat-header-cell>
    <mat-cell *matCellDef="let cellData"> {{cellData.surveyType}}</mat-cell>
  </ng-container>
  <ng-container matColumnDef="start">
    <mat-header-cell mat-sort-header *matHeaderCellDef matSortingDataAccessor sortActionDescription="Sort by Start">Start</mat-header-cell>
    <mat-cell *matCellDef="let cellData"> {{cellData.start ? (cellData.start | amDateFormat:'ll') : ''}}</mat-cell>
  </ng-container>
  <ng-container matColumnDef="end">
    <mat-header-cell mat-sort-header *matHeaderCellDef matSortingDataAccessor sortActionDescription="Sort by End">End</mat-header-cell>
    <mat-cell *matCellDef="let cellData"> {{cellData.end ? (cellData.end | amDateFormat:'ll') : ''}}</mat-cell>
  </ng-container>
  <ng-container matColumnDef="score">
    <mat-header-cell mat-sort-header *matHeaderCellDef sortActionDescription="Sort by Score">Score</mat-header-cell>
    <mat-cell *matCellDef="let cellData"> {{cellData.score}}</mat-cell>
  </ng-container>
  <ng-container matColumnDef="responseCount">
    <mat-header-cell mat-sort-header *matHeaderCellDef sortActionDescription="Sort by Responses">Responses</mat-header-cell>
    <mat-cell *matCellDef="let cellData"> {{cellData.responseCount}}</mat-cell>
  </ng-container>

  <mat-header-row *matHeaderRowDef="subgridColumns; sticky: true"></mat-header-row>
  <mat-row *matRowDef="let row; columns: subgridColumns;" (click)="showObjectiveDetail(row.objectiveAssignmentId)" class="clickable"></mat-row>

</mat-table>
