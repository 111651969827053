import { SurveyTypeCodes } from "@inthraction/codes";

export class OpenIntHRaction {

  intHRactionTitle: string;
  intHRactionDate: string;
  surveyIDs: string[] = [];
  surveyType: SurveyTypeCodes;

  constructor(intHRactionTitle: string, intHRactionDate: string, surveyType: SurveyTypeCodes, surveyIDs?: string[]) {
    this.intHRactionTitle = intHRactionTitle;
    this.intHRactionDate = intHRactionDate;
    this.surveyType = surveyType;
    if (surveyIDs) {
      this.surveyIDs.push(...surveyIDs);
    }
  }
}
