import { Component, OnDestroy, OnInit } from "@angular/core";
import { AddEmployeeComponent } from "../../../components/org-chart-module/add-employee/add-employee.component";
import { EditEmployeeComponent } from "../../../components/org-chart-module/edit-employee/edit-employee.component";
import {
  ConsultantAssociation,
  Employee,
  EmployeeImageInterface,
  EmployeeImpl,
  IEmployeeImpl
} from "@inthraction/data-models";
import { EmployeeService } from "@inthraction/services";
import { Subscription } from "rxjs";
import { MatDialog } from "@angular/material/dialog";
import {
  ConfirmationDialogComponent
} from "../../../components/shared/dialogs/confirmation-dialog/confirmation-dialog.component";

@Component({
  selector: "inthraction-orgchart",
  templateUrl: "./orgchart.component.html",
  styleUrls: ["./orgchart.component.scss"]
})
export class OrgchartComponent implements OnInit, OnDestroy {

  activeLink:string;
  private _topEmployees: IEmployeeImpl[] = [];
  disabledEmployees: Employee[] = [];
  consultantsMap: Map<string, Employee> = new Map<string, Employee>();
  private subscriptions: Subscription[] = [];
  consultantAssociations: ConsultantAssociation[];
  disabledEmployeeImageMap: Map<string, EmployeeImageInterface> = new Map<string, EmployeeImageInterface>();
  consultantEmployeeImageMap: Map<string, EmployeeImageInterface> = new Map<string, EmployeeImageInterface>();

  constructor(
    public dialog: MatDialog,
    private employeeService: EmployeeService
  ) {
  }

  get topEmployees(): IEmployeeImpl[] {
    return this._topEmployees?.sort((a, b) => (a.department + a.lastName + a.firstName).localeCompare(b.department + b.lastName + b.firstName));
  }

  ngOnDestroy(): void {
    if (this.subscriptions.length) {
      for (const sub of this.subscriptions) {
        sub.unsubscribe();
      }
    }
  }

  async ngOnInit(): Promise<void> {
    this.retrieveDisabledEmployees();
    this.retrieveConsultants();
    await this.retrieveOrgChartForUsersDomain();
    if (this.topEmployees?.length) {
      this.activeLink = this.topEmployees[0].id;
    }
  }

  async reloadOrgChart(): Promise<void> {
    this._topEmployees.length = 0;
    this.retrieveDisabledEmployees();
    await this.retrieveOrgChartForUsersDomain();
    if (this.topEmployees?.length) {
      this.activeLink = this.topEmployees[0].id;
    }
  }

  openAdd(): void {
    const addDialog = this.dialog.open(AddEmployeeComponent, {
      width: "680px",
      data: { manager: null }
    });

    this.subscriptions.push(
      addDialog.afterClosed().subscribe(async result => {
        if (result) {
          await this.reloadOrgChart();
        }
      }));
  }

  openEditDisabledEmployee(employee: Employee): void {
    const editDialog = this.dialog.open(EditEmployeeComponent, {
      width: "620px",
      data: { employee }
    });

    this.subscriptions.push(
      editDialog.afterClosed().subscribe(async result => {
        if (!employee.disabled) {
          this.disabledEmployees = this.disabledEmployees.filter(e => e.email !== employee.email);
          await this.reloadOrgChart();
        } else if (result && result.disableEdited) {
          await this.reloadOrgChart();
        }
      }));
  }

  private async retrieveDisabledEmployees(): Promise<void> {
    const disabledUsers = await this.employeeService.getEmployeesForOrganizationByOrganization({includeDisabled:true, memoize:true, onlyDisable:true});
    for (const user of disabledUsers) {
      this.disabledEmployeeImageMap.set(user.id, await this.employeeService.getEmployeeImageMemoize(user.id, user.orgId));
    }
    this.disabledEmployees = disabledUsers.map(employee => new EmployeeImpl(employee));
  }

  private async retrieveConsultants(): Promise<void> {
    const employee = await this.employeeService.getCurrentEmployee();
    const consultantAssociations = await this.employeeService.getConsultantsForOrganizationByOrganization(employee.orgId);
    if (consultantAssociations) {
      for (const association of consultantAssociations) {
        const consultant = await this.employeeService.getEmployeeByIDMemoize(association.employeeID);
        this.consultantsMap.set(consultant.id, consultant);
        this.consultantEmployeeImageMap.set(consultant.id, await this.employeeService.getEmployeeImageMemoize(consultant.id, consultant.orgId));
      }
      this.consultantAssociations = consultantAssociations;
    }
  }

  private async retrieveOrgChartForUsersDomain(): Promise<void> {

    const topLevelEmployees = await this.employeeService.getSubordinatesByEmployeeIDForOrganization({ memoize:true});
    //Get Employees where Manager is disabled
    const disabledUsers = await this.employeeService.getEmployeesForOrganizationByOrganization({includeDisabled:true, onlyDisable:true, memoize:true});
    for (const disabledUser of disabledUsers) {
      const subordinates = await this.employeeService.getSubordinatesByEmployeeIDForOrganization({managerID:disabledUser.id, memoize: true});
      if (subordinates?.length > 0) {
        topLevelEmployees.push(...subordinates);
      }
    }

    for (const employee of topLevelEmployees) {
      this._topEmployees.push(await this.employeeService.buildEmployeeHierarchy(employee));
    }

  }

  consultantEdit(association: ConsultantAssociation) {
    if (association && association.status !== "DISABLED") {
      const deleteDialogRef = this.dialog.open(ConfirmationDialogComponent, {
        width: "350px",
        data: "Disable access for this consultant?"
      });

      this.subscriptions.push(
        deleteDialogRef.afterClosed().subscribe(async result => {
          if (result) {
            association.status = "DISABLED";
            association = await this.employeeService.updateConsultantAssociation(association);
          }
        }));
    } else {
      const deleteDialogRef = this.dialog.open(ConfirmationDialogComponent, {
        width: "350px",
        data: "Enable access for this consultant?"
      });

      this.subscriptions.push(
        deleteDialogRef.afterClosed().subscribe(async result => {
          if (result) {
            association.status = "ENABLED";
            association = await this.employeeService.updateConsultantAssociation(association);
          }
        }));
    }

  }

}
