<h1 mat-dialog-title>Objective Rating</h1>
<ng-container *ngIf="objectiveAssignment && objectiveResponse && respondent">
  <div mat-dialog-content class="recent-inthractions-container">
    <h2 *ngIf="managerAccess">{{employee.firstName}} {{employee.lastName}}</h2>
    <div class="objective-score-container">
      <inthraction-score-card [score]="totalScore"></inthraction-score-card>
      <div class="rating">
        <span class="color-{{objectiveResponse.surveyResponse}}">{{objectiveSurveyResponseLabels[objectiveSurveyResponseTypes[objectiveResponse.surveyResponse - 1]]}}</span>
      </div>
    </div>
    <div>
      <mat-form-field class="full-width">
        <mat-label>Objective Title</mat-label>
        <input matInput readonly value="{{objectiveAssignment.objectiveType == 'QUANTIFIABLE' ? objectiveAssignment.title : objectiveAssignment.orgObjective.objective.display}}">
      </mat-form-field>
      <mat-form-field *ngIf="objectiveAssignment.objectiveType != 'QUANTIFIABLE'" class="full-width">
        <mat-label>Definition</mat-label>
        <textarea matInput readonly cdkTextareaAutosize value="{{objectiveAssignment.orgObjective.objective.definition}}"></textarea>
      </mat-form-field>
      <mat-form-field *ngIf="objectiveAssignment.objectiveType != 'QUANTIFIABLE' && objectiveAssignment.orgObjective.businessObjective" class="full-width">
        <mat-label>Definition</mat-label>
        <textarea matInput readonly cdkTextareaAutosize value="{{objectiveAssignment.orgObjective.businessObjective}}"></textarea>
      </mat-form-field>
      <mat-form-field *ngIf="objectiveAssignment.managerDescription" class="full-width">
        <mat-label>Manager Objective</mat-label>
        <textarea matInput readonly cdkTextareaAutosize value="{{objectiveAssignment.managerDescription}}"></textarea>
      </mat-form-field>
      <mat-form-field class="full-width">
        <mat-label>Rating Date</mat-label>
        <input matInput readonly value="{{objectiveResponse.responseReceivedDate | amParse:'YYYY-MM-DDTHH:mm:ssZ' | amDateFormat:'MMMM Do YYYY'}}">
      </mat-form-field>
      <mat-form-field *ngIf="managerAccess && objectiveAssignment.objectiveType != 'DEFINED_EXTERNAL'" class="full-width">
        <mat-label>Respondent</mat-label>
        <input matInput readonly value="{{respondent.firstName}} {{respondent.lastName}}">
      </mat-form-field>
      <mat-form-field *ngIf="managerAccess && objectiveAssignment.objectiveType == 'DEFINED_EXTERNAL'" class="full-width">
        <mat-label>Respondent</mat-label>
        <input matInput readonly value="{{respondent.externalRespondentEmail}}">
      </mat-form-field>
      <mat-form-field *ngIf="managerAccess" class="full-width">
        <mat-label>Feedback</mat-label>
        <textarea matInput readonly cdkTextareaAutosize value="{{objectiveResponse.feedback}}"></textarea>
      </mat-form-field>
    </div>
  </div>
</ng-container>

<inthraction-loading className="recent-inthractions-container" [initDone]="((objectiveAssignment && objectiveResponse && respondent) ? true : false)"></inthraction-loading>
