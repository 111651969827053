<inthraction-page-header pageTitle="Stand-Up Survey Configuration">
  <button (click)="onExcludeClick()" mat-icon-button type="button" class="top-button-1" aria-label="Exclude" title="Exclude">
    <mat-icon>person_remove</mat-icon>
  </button>
  <button (click)="onShareClick()" mat-icon-button type="button" class="top-button" aria-label="Share" title="Share">
    <mat-icon>ios_share</mat-icon>
  </button>
</inthraction-page-header>
<div *ngIf="initialized">
  <form [formGroup]="form" (ngSubmit)="onSaveClick(form.value)" autocomplete="off" novalidate>
    <div mat-dialog-content>
      <div class="holder">
        <mat-form-field class="half-width">
          <mat-label>Status Frequency</mat-label>
          <mat-select formControlName="frequency">
            <mat-option *ngFor="let id of StandupFrequencyTypes" [value]="id">{{ StandupFrequencyTypeLabels[id] }}</mat-option>
          </mat-select>
          <mat-error *ngIf="hasError('frequency', 'required')">Status Frequency is required</mat-error>
        </mat-form-field>
        <mat-form-field floatLabel="always" appearance="outline" class="slide-toggle-form-field half-width right">
          <mat-label>Enabled</mat-label>
          <mat-slide-toggle formControlName="enabled"></mat-slide-toggle>
          <input matInput hidden height="0" style="height: 0" />
        </mat-form-field>

    <mat-form-field *ngIf="showDayOfWeekPicker" floatLabel="always" appearance="outline" class="slide-toggle-form-field half-width">
          <mat-label>Day of Week</mat-label>
          <mat-button-toggle-group aria-label="Day of Week" multiple="false" formControlName="isoWeekday">
            <mat-button-toggle value="Monday">Monday</mat-button-toggle>
            <mat-button-toggle value="Tuesday">Tuesday</mat-button-toggle>
            <mat-button-toggle value="Wednesday">Wednesday</mat-button-toggle>
            <mat-button-toggle value="Thursday">Thursday</mat-button-toggle>
            <mat-button-toggle value="Friday">Friday</mat-button-toggle>
          </mat-button-toggle-group>
          <input matInput hidden height="0" style="height: 0" />
        </mat-form-field>
        <mat-expansion-panel hideToggle *ngIf="sharedEmployees.length">
          <mat-expansion-panel-header>
            <mat-panel-title>
              Shared with...
            </mat-panel-title>
            <mat-panel-description>
              Manage who can see survey results
            </mat-panel-description>
          </mat-expansion-panel-header>
          <div *ngFor="let employee of sharedEmployees" class="employee-row">
            <p class="employee-name">{{ employee.firstName }} {{ employee.lastName }}</p>
            <button mat-icon-button (click)="removeEmployeeShare(employee)" title="Delete Share" class="remove-employee-share-btn">
              <mat-icon>delete</mat-icon>
            </button>
          </div>
        </mat-expansion-panel>
        <mat-expansion-panel hideToggle *ngIf="excludedEmployees.length">
          <mat-expansion-panel-header>
            <mat-panel-title>
              Excluded...
            </mat-panel-title>
            <mat-panel-description>
              Manage who is excluded from receiving the survey
            </mat-panel-description>
          </mat-expansion-panel-header>
          <div *ngFor="let employee of excludedEmployees" class="employee-row">
            <p class="employee-name">{{ employee.firstName }} {{ employee.lastName }}</p>
            <button mat-icon-button (click)="removeEmployeeExclude(employee)" title="Delete Exclusion" class="remove-employee-exclude-btn">
              <mat-icon>delete</mat-icon>
            </button>
          </div>
        </mat-expansion-panel>
      </div>
      <ng-container *ngFor="let question of questions; let i = index" class="full-width">
        <ng-container *ngIf="question.enabled">
          <button mat-icon-button (click)="moveQuestion(question, 'up', i)" type="button" title="Move Up" [disabled]="i == 0">
            <mat-icon>keyboard_arrow_up</mat-icon>
          </button>
          <button mat-icon-button (click)="moveQuestion(question, 'down', i)" type="button" title="Move Down" [disabled]="i == questions.length-1">
            <mat-icon>keyboard_arrow_down</mat-icon>
          </button>
          <button mat-icon-button class="right" type="button" (click)="deleteQuestion(question)" title="Delete Question">
            <mat-icon>delete</mat-icon>
          </button>
          <mat-form-field appearance="outline" class="full-width">
            <mat-label>Question {{ i + 1 }}</mat-label>
            <input matInput formControlName="{{question.id}}">
            <mat-error *ngIf="hasError(question.id, 'required')">A question is required</mat-error>
          </mat-form-field>
        </ng-container>
      </ng-container>
      <br>
      <button mat-button (click)="addQuestion()" type="button" [disabled]="questions.length >= 10">
        <mat-label>Add Question</mat-label>
        <mat-icon>add</mat-icon>
      </button>
    </div>
    <div mat-dialog-actions>
      <button (click)="onCancelClick()" mat-button type="button">Cancel</button>
      <button [disabled]="!form.valid" color="primary" mat-raised-button type="submit">Save</button>
    </div>
  </form>
</div>
<inthraction-loading className="" [initDone]="initialized"></inthraction-loading>
