import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ScoreCardComponent } from "./score-card.component";
import { MatCardModule } from "@angular/material/card";

@NgModule({
  imports: [CommonModule, MatCardModule],
  declarations: [ScoreCardComponent],
  exports: [ScoreCardComponent]
})
export class ScoreCardModule {
}
