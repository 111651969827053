import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { EmployeeSearchOptions } from "../../search/employee-search/employee-search.component";
import { Employee } from "@inthraction/data-models";

@Component({
  selector: 'app-search-employee-dialog',
  templateUrl: './search-employee-dialog.component.html',
  styleUrls: ['./search-employee-dialog.component.scss']
})
export class SearchEmployeeDialogComponent {

  constructor(
    public dialogRef: MatDialogRef<SearchEmployeeDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public options: EmployeeSearchOptions) {
  }

  searchEmployee(employee: Employee) {
    this.dialogRef.close(employee);
  }
}
