import { Component, OnInit } from "@angular/core";
import { Employee, EmployeeImpl } from "@inthraction/data-models";
import { AuthService, EmployeeService } from "@inthraction/services";

@Component({
  selector: "inthraction-employee-dashboard-loader",
  templateUrl: "./employee-dashboard-loader.component.html",
  styleUrls: ["./employee-dashboard-loader.component.scss"]
})
export class EmployeeDashboardLoaderComponent implements OnInit {

  manager: Employee;
  employee: Employee;

  ready = false;

  constructor(
    private employeeService: EmployeeService,
    private authService: AuthService
  ) {
  }

  async ngOnInit(): Promise<void> {
    const employeeResult = await this.employeeService.getCurrentEmployee();
    this.employee = new EmployeeImpl(employeeResult);
    let managerSearchResult;
    if (this.employee.managerID) {
      managerSearchResult = await this.employeeService.getEmployeeByIDMemoize(this.employee.managerID);
      if (managerSearchResult) {
        this.manager = managerSearchResult;
      }
    }
    this.ready = true;
  }

}
