import packageInfo from "../../../../package.json";

export const environment = {
  production: true,
  environmentName: "test",
  sentryDNS: "https://c02217876fa94f79a4e5841c0d79dfc5@sentry.io/3568254",
  cronofyClientID: "sr96C38-9CeIy4t58hEGVBTgfGm8eskW",
  version: `${packageInfo.version}-test`,
  googleAnalyticsMeasurementID: "G-HZ0QSPXB0F",
  heaedwayAccountID: "7NlK9y",
  stripePortal: "https://billing.stripe.com/p/login/test_00g00S2IP8NG5HOfYY"
};
