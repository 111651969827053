import { Component, Inject, OnInit } from "@angular/core";
import { NoteService, ProjectService } from "@inthraction/services";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { NoteTypes } from "@inthraction/codes";
import { Note, Project } from "@inthraction/data-models";

@Component({
  selector: "inthraction-add-edit-project-note",
  templateUrl: "./add-edit-project-note.component.html",
  styleUrls: ["./add-edit-project-note.component.scss"]
})
export class AddEditProjectNoteComponent implements OnInit {

  noteFG = new FormGroup({
    title: new FormControl("", [Validators.required]),
    description: new FormControl("", [Validators.required]),
    displayPublic: new FormControl(false, [])
  });

  editMode = false;

  private note: Note;

  constructor(
    private projectService: ProjectService,
    private noteService: NoteService,
    private dialogRef: MatDialogRef<AddEditProjectNoteComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
  }

  async ngOnInit() {
    if (this.data.noteId) {
      this.editMode = true;
      this.note = await this.noteService.getNoteByID(this.data.noteId);
      this.noteFG.get("title").setValue(this.note.options.title);
      this.noteFG.get("description").setValue(this.note.text);
      this.noteFG.get("displayPublic").setValue(this.note.noteType === NoteTypes.PROJECT);
    }
  }

  onCancelClick() {
    this.dialogRef.close();
  }

  async onSaveClick(value) {
    const project: Project = this.data.project;
    if (this.editMode) {
      let update = false;
      if (this.note.options.title !== value.title) {
        this.note.options.title = value.title;
        update = true;
      }
      if (this.note.text !== value.description) {
        this.note.text = value.description;
        update = true;
      }
      if (this.note.noteType !== (value.displayPublic ? NoteTypes.PROJECT : NoteTypes.PROJECT_PRIVATE)) {
        this.note.noteType = value.displayPublic ? NoteTypes.PROJECT : NoteTypes.PROJECT_PRIVATE;
        update = true;
      }
      if (update) {
        this.note = await this.noteService.updateNote(this.note);
      }
    } else {
      const note = new Note();
      note.organizationID = project.organizationID;
      note.noteType = value.displayPublic ? NoteTypes.PROJECT : NoteTypes.PROJECT_PRIVATE;
      note.objectID = project.id;
      note.text = value.description;
      if (value.title && value.title.length > 0) {
        note.options = {
          title: value.title
        };
      }
      this.note = await this.noteService.createNote(note);
    }
    this.projectService.clearMemoizedNotes(this.note.objectID);
    this.dialogRef.close({ note: this.note });
  }

  public hasError = (controlName: string, errorName: string) => {
    if (controlName && this.noteFG.controls[controlName]) {
      return this.noteFG.controls[controlName].hasError(errorName);
    }
    return this.noteFG.hasError(errorName);
  };

}
