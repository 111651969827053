import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Employee, IEmployeeImpl, Organization, OrganizationConfiguration } from "@inthraction/data-models";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { CreateEmployee, EmployeeService, OrganizationService } from "@inthraction/services";
import * as moment from "moment";
import { Moment } from "moment";
import { ToastrService } from "ngx-toastr";
import { MatStepper } from "@angular/material/stepper";
import { MatDialog } from "@angular/material/dialog";
import { Observable } from "rxjs";
import { map, startWith } from "rxjs/operators";
import { DEPARTMENT_TYPES, OrganizationConfigurationCodes } from "@inthraction/codes";
import { DEPARTMENT_TYPE_LABELS } from "@inthraction/labels";
import { ConfirmationDialogComponent } from "../../shared/dialogs/confirmation-dialog/confirmation-dialog.component";

@Component({
  selector: "inthraction-oc-add-employee",
  templateUrl: "./add-employee.component.html",
  styleUrls: ["./add-employee.component.scss"]
})
export class AddEmployeeComponent implements OnInit {

  @ViewChild("stepper") private addEmployeeStepper: MatStepper;

  addExisting = false;
  manager: IEmployeeImpl;
  managerUser: Employee;
  employee: IEmployeeImpl;
  private _employeeId: string;

  departmentTypes = DEPARTMENT_TYPES;
  departmentTypeLabels = DEPARTMENT_TYPE_LABELS;

  private email = new FormControl("", [Validators.required, Validators.email]);
  private managerEmail = new FormControl("", [Validators.required, Validators.email]);
  private emails: string[];
  filteredEmails: Observable<string[]>;
  filteredManagerEmails: Observable<string[]>;

  searchForEmployeeFG = new FormGroup({
    email: this.email,
    isValidEmail: new FormControl(false, [Validators.requiredTrue])
  });

  searchForManagerFG = new FormGroup({
    email: this.managerEmail,
    isValidEmail: new FormControl(false, [Validators.requiredTrue])
  });

  addEmployeeFG = new FormGroup({
    firstName: new FormControl("", [Validators.required]),
    lastName: new FormControl("", [Validators.required]),
    email: new FormControl("", [Validators.required]),
    title: new FormControl("", [Validators.required]),
    dateHired: new FormControl("", []),
    isExistingEmployee: new FormControl(false, []),
    isValidOrgStructure: new FormControl(true, [Validators.requiredTrue]),
    department: new FormControl("", [Validators.required])
  });

  employeeOptionsFG = new FormGroup({
    admin: new FormControl(false, []),
    hr: new FormControl(false, []),
    pm: new FormControl(false, []),
    sendInvite: new FormControl(false, [])
  });

  private organization: Organization;
  private searchedManager: IEmployeeImpl;
  private allowAllEmailDomainsConfiguration: OrganizationConfiguration;

  constructor(
    public dialogRef: MatDialogRef<AddEmployeeComponent>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private employeeService: EmployeeService,
    private organizationService: OrganizationService,
    private toastrService: ToastrService
  ) {
    this.addEmployeeFG.get("email").disable();

    if (data.manager != null) {
      this.manager = JSON.parse(JSON.stringify(data.manager));
    }
    this.employee = new IEmployeeImpl(
      {
        authId: null,
        disabled: false,
        disabledBy: null,
        disabledDate: null,
        email: null,
        firstName: null,
        hireDate: null,
        department: null,
        id: null,
        lastAnnual: null,
        lastName: null,
        lastOneOnOne: null,
        orgId: null,
        positionDate: null,
        previousPositionStartDate: null,
        previousPositionTitle: null,
        title: null
      }, []);

    if (data.organization) {
      this.organization = data.organization;
    }
  }

  async ngOnInit(): Promise<void> {
    if (!this.organization) {
      this.organization = await this.organizationService.getOrganizationForCurrentUser();
    }
    this.allowAllEmailDomainsConfiguration = await this.organizationService.getOrganizationConfiguration(OrganizationConfigurationCodes.OPEN_EMAIL_DOMAIN, this.organization.id);
    if (this.manager != null) {
      this.managerUser = await this.employeeService.getEmployeeByEmailMemoize(this.manager.email.toLowerCase());
    }
    const employees = await this.employeeService.getEmployeesForOrganizationByOrganization({organizationID:this.organization.id, includeDisabled:true, memoize:true});
    this.emails = employees.map(emp => emp.email);
    this.filteredEmails = this.email.valueChanges.pipe(
      startWith(""),
      map(value => this._filter(value))
    );
    this.filteredManagerEmails = this.managerEmail.valueChanges.pipe(
      startWith(""),
      map(value => this._filter(value))
    );
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.emails.filter(option => option.toLowerCase().includes(filterValue));
  }

  onCancelClick(): void {
    this.dialogRef.close();
  }

  async onSaveClick(saveBtn: any): Promise<void> {
    saveBtn.disable = true;
    // @ts-ignore
    const hireDate: Moment = this.addEmployeeFG.get("dateHired").value;
    if (hireDate && hireDate.isValid()) {
      this.employee.hireDate = hireDate.format("YYYY-MM-DD");
    }
    if (this._employeeId) {
      this.addExisting = true;
      if (this.manager == null || !(await this.verifyNewManagerIsNotAlreadyASubordinate(this._employeeId, this.managerUser.id))) {
        if (this.manager != null) {
          await this.employeeService.updateEmployee({
            id: this._employeeId,
            managerID: this.manager.id,
            managerChangeDate: moment().format("YYYY-MM-DD")
          });
          this.manager.subordinateEmployees.push(this.employee);
          this.toastrService.success("User assigned to new manager");
          this.dialogRef.close({ employee: this.manager, addExisting: this.addExisting });
        } else {
          // Remove Manager
          this.toastrService.success("User updated");
          const employee = await this.employeeService.updateEmployee({
            id: this._employeeId,
            managerID: null,
            managerChangeDate: moment().format("YYYY-MM-DD")
          });
          this.dialogRef.close({ employee: employee, addExisting: this.addExisting });
        }
      } else {
        this.toastrService.error("Invalid Organization Chart Structure");
        this.addEmployeeFG.get("isValidOrgStructure").setValue(false);
      }
    } else {
      this.populateNewEmployeeFromForm();

      if (!this.manager && this.searchedManager) {
        this.manager = this.searchedManager;
      }

      this._employeeId = await this.createNewUserFromEmployee(this.employee, this.manager);
      if (this._employeeId && this.manager != null) {
        this.manager.subordinateEmployees.push(this.employee);
      }

      if (this._employeeId && this.employeeOptionsFG.get("sendInvite").value) {
        await this.employeeService.createUserAction({
          employeeID: this._employeeId,
          organizationID: this.employee.orgId,
          action: "SEND_INVITE"
        });
      }

      if (this._employeeId) {
        this.toastrService.success("New user created");
        if (this.manager != null) {
          this.dialogRef.close({ employee: this.manager, addExisting: this.addExisting });
        } else {
          this.dialogRef.close({ employee: this.employee, addExisting: this.addExisting });
        }
      } else {
        this.toastrService.error("Failed to create new user");
        this.dialogRef.close();
      }
    }
    saveBtn.disable = this.addEmployeeFG.invalid
  }

  async onManagerSearchClick(): Promise<void> {
    const emailSearchFC = this.searchForManagerFG.get("email");
    if (this.allowAllEmailDomainsConfiguration?.configBooleanValue || await this.verifyDomain(emailSearchFC.value)) {
      this.searchForManagerFG.get("isValidEmail").setValue(true);
      const employee = await this.searchForEmployee(emailSearchFC.value, this.searchForManagerFG);
      if (employee) {
        this.searchedManager = employee;
      } else {
        this.searchedManager = null;
      }
    } else {
      emailSearchFC.setErrors({ domain: true });
    }
  }

  async onEmployeeSearchClick(): Promise<void> {
    const emailSearchFC = this.searchForEmployeeFG.get("email");
    if (this.managerUser && this.managerUser.email === emailSearchFC.value.toLocaleLowerCase()) {
      emailSearchFC.setErrors({ manager: true });
    } else if (await this.verifyDomain(emailSearchFC.value)) {
      await this.employeeSearchImpl(emailSearchFC);
    } else if (this.allowAllEmailDomainsConfiguration?.configBooleanValue) {
      if (await this.employeeService.doesEmployeeExistByEmail(emailSearchFC.value.trim().toLowerCase())) {
        emailSearchFC.setErrors({ inUse: true });
      } else {
        const confirmDialogRef = this.dialog.open(ConfirmationDialogComponent, {
          width: "350px",
          data: "The domain in this email address does not match your organization. Are you sure you want to add this account?"
        });
        confirmDialogRef.afterClosed().subscribe(async result => {
          if (result) {
            await this.employeeSearchImpl(emailSearchFC);
          } else {
            emailSearchFC.setErrors({ domain: true });
          }
        });
      }
    } else {
      emailSearchFC.setErrors({ domain: true });
    }
  }

  private async employeeSearchImpl(emailSearchFC: any) {
    this.searchForEmployeeFG.get("isValidEmail").setValue(true);
    const employee = await this.searchForEmployee(emailSearchFC.value, this.searchForEmployeeFG);
    if (employee && employee.orgId == this.organization.id) {
      this._employeeId = employee.id;
      this.employee = employee;
      this.populateAddEmployeeFormForExistingEmployee(employee);
      this.addEmployeeStepper.next();
    } else if (employee) { // Employee Exists in a different organization
      emailSearchFC.setErrors({ inUse: true });
    } else {
      this._employeeId = null;
      this.addEmployeeFG.reset();
      this.addEmployeeFG.reset({
        // @ts-ignore
        firstName: { value: "", disabled: false },
        // @ts-ignore
        lastName: { value: "", disabled: false },
        // @ts-ignore
        title: { value: "", disabled: false },
        // @ts-ignore
        dateHired: { value: "", disabled: false },
        // @ts-ignore
        isValidOrgStructure: { value: true }
      });
      this.addEmployeeFG.get("email").setValue(emailSearchFC.value.toLowerCase());
      this.addEmployeeFG.get("isValidOrgStructure").setValue(true);

      this.employeeOptionsFG.reset();
      this.employeeOptionsFG.reset({
        // @ts-ignore
        pm: { value: false, disabled: false },
        // @ts-ignore
        hr: { value: false, disabled: false },
        // @ts-ignore
        admin: { value: false, disabled: false },
        // @ts-ignore
        sendInvite: { value: false, disabled: false }
      });
    }
    this.addEmployeeStepper.next();
  }

  private populateAddEmployeeFormForExistingEmployee(employee: IEmployeeImpl) {
    this.addEmployeeFG.get("isExistingEmployee").setValue(true);
    this.addEmployeeFG.get("isValidOrgStructure").setValue(true);
    const firstNameFC = this.addEmployeeFG.get("firstName");
    firstNameFC.setValue(employee.firstName);
    firstNameFC.disable();
    const lastNameFC = this.addEmployeeFG.get("lastName");
    lastNameFC.setValue(employee.lastName);
    lastNameFC.disable();
    const emailFC = this.addEmployeeFG.get("email");
    emailFC.setValue(employee.email);
    emailFC.disable();
    const titleFC = this.addEmployeeFG.get("title");
    titleFC.setValue(employee.title);
    titleFC.disable();
    const hireDateFC = this.addEmployeeFG.get("dateHired");
    if (employee.hireDate) {
      // @ts-ignore
      hireDateFC.setValue(moment(employee.hireDate, "YYYY-MM-DD"));
    }
    hireDateFC.disable();

    const departmentFC = this.addEmployeeFG.get("department");
    departmentFC.setValue(employee.department);
    departmentFC.disable();

    const hrFC = this.employeeOptionsFG.get("hr");
    hrFC.setValue(employee.isHR());
    hrFC.disable();

    const adminFC = this.employeeOptionsFG.get("admin");
    adminFC.setValue(employee.isAdmin());
    adminFC.disable();

    const pmFC = this.employeeOptionsFG.get("pm");
    pmFC.setValue(employee.isProjectManager());
    pmFC.disable();

    const sendInviteFC = this.employeeOptionsFG.get("sendInvite");
    sendInviteFC.setValue(false);
    sendInviteFC.disable();

  }

  private async verifyNewManagerIsNotAlreadyASubordinate(employeeID: string, managerID: string): Promise<boolean> {
    let matched = false;
    const subordinates = await this.employeeService.getSubordinatesByEmployeeIDForOrganization({managerID:employeeID, includeDisabled:true});
    if (subordinates.map(s => s.id).includes(managerID)) {
      matched = true;
    } else {
      for (const sId of subordinates.map(s => s.id)) {
        matched = await this.verifyNewManagerIsNotAlreadyASubordinate(sId, managerID);
        if (matched) {
          break;
        }
      }
    }
    return matched;
  }

  private populateNewEmployeeFromForm() {
    this.employee.firstName = this.addEmployeeFG.get("firstName").value;
    this.employee.lastName = this.addEmployeeFG.get("lastName").value;
    this.employee.title = this.addEmployeeFG.get("title").value;
    this.employee.email = this.addEmployeeFG.get("email").value;
    this.employee.setAdmin(this.employeeOptionsFG.get("admin").value);
    this.employee.setHR(this.employeeOptionsFG.get("hr").value);
    this.employee.setPM(this.employeeOptionsFG.get("pm").value);
    this.employee.department = this.addEmployeeFG.get("department").value;
  }

  private async createNewUserFromEmployee(employee: Employee, manager?: Employee): Promise<string> {
    let organization;
    if (this.organization) {
      organization = this.organization;
    } else {
      organization = await this.organizationService.getOrganizationForCurrentUser();
    }
    employee.orgId = organization.id;
    const newEmployee: CreateEmployee = {
      email: employee.email.toLowerCase(),
      firstName: employee.firstName,
      lastName: employee.lastName,
      title: employee.title,
      orgId: organization.id,
      department: employee.department,
      subordinates: [],
      disabled: false,
      disabledBy: null,
      disabledDate: null,
      hireDate: employee.hireDate,
      groups: [`HR-${organization.id}`, `ADMIN-${organization.id}`], //Groups allowed to add / edit
      managerID: manager?.id
    };
    if (employee.roles) {
      newEmployee.roles = employee.roles;
    }

    const userSearch = await this.employeeService.doesEmployeeExistByEmail(newEmployee.email.trim().toLowerCase());
    if (userSearch) {
      this.toastrService.error("Unable to create new user.");
      return null;
    }

    let user;
    try {
      user = await this.employeeService.createEmployee(newEmployee);
    } catch (e) {
      console.error(e);
      this.toastrService.error("Unable to create new user.");
      throw e;
    }
    if (!user) {
      return null;
    }
    this.employee = await this.employeeService.buildEmployeeHierarchy(user, this.manager);
    return user.id;
  }

  private async verifyDomain(email: string): Promise<boolean> {
    const splitEmail = email.split("@");
    if (splitEmail.length < 2) {
      return false;
    }
    const domain = splitEmail[1].toLowerCase();
    let organization: Organization;
    if (this.organization) {
      organization = this.organization;
    } else {
      organization = await this.organizationService.getOrganizationForCurrentUser();
    }
    return organization.domains.includes(domain);
  }

  private async searchForEmployee(email: string, formGroup: FormGroup): Promise<IEmployeeImpl> {
    let employee;
    const searchResult = await this.employeeService.getEmployeeByEmailMemoize(email.toLowerCase());
    if (searchResult && !searchResult.disabled) {
      employee = await this.employeeService.buildEmployeeHierarchy(searchResult, this.manager);
    } else if (searchResult && searchResult.disabled) {
      formGroup.get("email").setErrors({ disabled: true });
    }
    return employee;
  }

  public hasError = (form: FormGroup, errorName: string, controlName?: string) => {
    if (controlName) {
      if ("null" === errorName) {
        return !(!form.controls[controlName].errors);
      }
      return form.controls[controlName].hasError(errorName);
    }
    if ("null" === errorName) {
      return !(!form.errors);
    }
    return form.hasError(errorName);
  };

  onClearManagerSearchClick() {
    this.searchedManager = null;
  }
}
