import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Employee } from "@inthraction/data-models";

@Component({
  selector: "inthraction-ithraction-graph-dialog",
  templateUrl: "./ithraction-graph-dialog.component.html",
  styleUrls: ["./ithraction-graph-dialog.component.scss"]
})
export class IthractionGraphDialogComponent implements OnInit {

  readonly employee: Employee;
  readonly ratesOthers: boolean;

  constructor(
    public dialogRef: MatDialogRef<IthractionGraphDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IntHRactionGraphDialogData
  ) {
    this.employee = data.employee;
    this.ratesOthers = data.ratesOthers;
  }

  ngOnInit() {
  }

}

export interface IntHRactionGraphDialogData {
  employee: Employee;
  ratesOthers: boolean;
}
