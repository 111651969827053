import { Component, Input, OnInit } from "@angular/core";
import { Employee } from "@inthraction/data-models";

@Component({
  selector: "inthraction-objective-response-distribution",
  templateUrl: "./objective-response-distribution.component.html",
  styleUrls: ["./objective-response-distribution.component.scss"]
})
export class ObjectiveResponseDistributionComponent implements OnInit {

  readonly ObjectiveResponseDistributionMode = ObjectiveResponseDistributionMode;

  @Input() mode: ObjectiveResponseDistributionMode;

  @Input() employee?: Employee;

  constructor() {
  }

  ngOnInit() {
  }

}

export enum ObjectiveResponseDistributionMode {
  DEPARTMENT = "DEPARTMENT",
  PEER = "PEER",
}

export enum ObjectiveResponseDistributionMode {
  MANAGER = "MANAGER",
  HR = "HR",
}
