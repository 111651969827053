import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { ConsultantAssociation, Employee, StandupSurveyConfiguration } from "@inthraction/data-models";
import { EmployeeService, StandupService } from "@inthraction/services";
import { STANDUP_FREQUENCY_TYPE_CODES, STANDUP_FREQUENCY_TYPES } from "@inthraction/codes";
import { VersionedQuestion } from "@inthraction/data-mappers";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { STANDUP_FREQUENCY_LABELS } from "@inthraction/labels";
import { ConfirmationDialogComponent } from "../shared/dialogs/confirmation-dialog/confirmation-dialog.component";
import { Subscription } from "rxjs";
import { v4 as uuidv4 } from "uuid";
import { EmployeeSearchOptions } from "../shared/search/employee-search/employee-search.component";
import {
  SearchEmployeeDialogComponent
} from "../shared/dialogs/search-employee-dialog/search-employee-dialog.component";
import { ToastrService } from "ngx-toastr";


@Component({
  selector: "app-standup-configuration",
  templateUrl: "./standup-configuration.component.html",
  styleUrls: ["./standup-configuration.component.scss"]
})
export class StandupConfigurationComponent implements OnInit, OnDestroy {

  private manager: Employee;
  readonly StandupFrequencyTypeCodes = STANDUP_FREQUENCY_TYPE_CODES;
  readonly StandupFrequencyTypes = STANDUP_FREQUENCY_TYPES;
  readonly StandupFrequencyTypeLabels = STANDUP_FREQUENCY_LABELS;
  private subscriptions: Subscription[] = [];
  private configuration: StandupSurveyConfiguration;
  private consultants: ConsultantAssociation[];
  private subordinates: Employee[];

  questions: QuestionView[] = [];
  sharedEmployees: Employee[] = [];
  excludedEmployees: Employee[] = [];

  form: FormGroup = new FormGroup({
      frequency: new FormControl("", [Validators.required]),
      enabled: new FormControl(true),
      isValidQuestion: new FormControl(false, [Validators.requiredTrue]),
      isoWeekday: new FormControl(null)
    }
  );
  initialized: boolean = false;
  showDayOfWeekPicker: boolean;

  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<StandupConfigurationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: StandupConfigurationComponentDialogData,
    private standupService: StandupService,
    private employeeService: EmployeeService,
    private toastrService: ToastrService
  ) {
    dialogRef.disableClose = true;
  }

  async ngOnInit() {
    this.manager = this.data.manager;
    this.subordinates = await this.employeeService.getSubordinatesByEmployeeIDForOrganization({managerID:this.manager.id, memoize:true});
    let configuration = await this.standupService.getStandupConfigurationByManager(this.data.manager);

    if (configuration) {
      this.configuration = configuration;
    } else {
      this.configuration = this.getDefaultConfiguration(this.manager);
    }

    if (configuration?.shared?.length) {
      for (const id of configuration.shared) {
        const employee = await this.employeeService.getEmployeeByIDMemoize(id);
        if (!employee.disabled && (employee.orgId == this.manager.orgId || this.isActiveConsultantForOrganization(employee))) {
          this.addSharedEmployee(employee);
        }
      }
    }

    if (configuration?.excluded?.length) {
      for (const id of configuration.excluded) {
        const employee = this.subordinates.find(e => e.id == id);
        if (employee && !employee.disabled) {
          this.addExcludedEmployee(employee);
        }
      }
    }

    const consultants = await this.employeeService.getConsultantsForOrganizationByOrganization(this.manager.orgId);
    this.consultants = consultants.filter(c => c.status !== "DISABLED");
    this.initializeFormFromConfiguration(this.configuration);

    this.form.controls["frequency"].valueChanges.subscribe(value => {
      if (value == this.StandupFrequencyTypeCodes.WEEKLY || value == this.StandupFrequencyTypeCodes.BI_WEEKLY) {
        this.showDayOfWeekPicker = true;
      } else {
        this.showDayOfWeekPicker = false;
      }
    });
  }

  ngOnDestroy(): void {
    if (this.subscriptions.length) {
      for (const sub of this.subscriptions) {
        sub.unsubscribe();
      }
    }
  }

  private initializeFormFromConfiguration(configuration: StandupSurveyConfiguration) {
    this.form.controls["enabled"].setValue("DISABLED" != configuration.status);
    this.form.controls["frequency"].setValue(configuration.frequency);
    if (configuration.frequency == this.StandupFrequencyTypeCodes.WEEKLY || configuration.frequency == this.StandupFrequencyTypeCodes.BI_WEEKLY) {
      switch (configuration.isoWeekday) {
        case 1 : {
          this.form.controls["isoWeekday"].setValue("Monday");
          break;
        }
        case 2 : {
          this.form.controls["isoWeekday"].setValue("Tuesday");
          break;
        }
        case 3 : {
          this.form.controls["isoWeekday"].setValue("Wednesday");
          break;
        }
        case 4 : {
          this.form.controls["isoWeekday"].setValue("Thursday");
          break;
        }
        case 5 : {
          this.form.controls["isoWeekday"].setValue("Friday");
          break;
        }
      }
      this.showDayOfWeekPicker = true;
    } else {
      this.form.controls["isoWeekday"].setValue(null);
      this.showDayOfWeekPicker = false;
    }

    let i = 0;
    for (const q of this.configuration.questions) {
      if (q.enabled) {
        this.questions.push({
          id: q.id,
          enabled: q.enabled,
          index: i
        });

        const control = new FormControl(`${q.questionTextArray[q.questionTextArray.length - 1]}`, [Validators.required]);
        this.form.addControl(`${q.id}`, control);

        i++;
      }
    }
    this.form.controls["isValidQuestion"].setValue(this.questions.length > 0);
    this.initialized = true;
  }

  private getDefaultConfiguration(manager: Employee): StandupSurveyConfiguration {
    const configuration = new StandupSurveyConfiguration();

    configuration.employeeID = manager.id;
    configuration.organizationID = manager.orgId;
    configuration.frequency = this.StandupFrequencyTypeCodes.DAILY;
    configuration.status = "DISABLED";
    configuration.shared = [];

    const question1: VersionedQuestion = {
      id: uuidv4(),
      enabled: true,
      questionTextArray: ["Yesterday I worked on..."]
    };
    const question2: VersionedQuestion = {
      id: uuidv4(),
      enabled: true,
      questionTextArray: ["Today I'm working on..."]
    };
    const question3: VersionedQuestion = {
      id: uuidv4(),
      enabled: true,
      questionTextArray: ["I could use some assistance on..."]
    };

    configuration.questions = [question1, question2, question3];
    return configuration;
  }

  public hasError = (controlName: string, errorName: string) => {
    if (controlName && this.form.controls[controlName]) {
      return this.form.controls[controlName].hasError(errorName);
    }
    return this.form.hasError(errorName);
  };

  onCancelClick() {
    this.dialogRef.close();
  }

  async onSaveClick(value: any) {

    this.configuration.status = value.enabled ? "ACTIVE" : "DISABLED";
    this.configuration.frequency = value.frequency;

    if (value.frequency == this.StandupFrequencyTypeCodes.WEEKLY || value.frequency == this.StandupFrequencyTypeCodes.BI_WEEKLY) {
      this.configuration.isoWeekday = value.isoWeekday;
      switch (value.isoWeekday) {
        case "Monday" : {
          this.configuration.isoWeekday = 1;
          break;
        }
        case "Tuesday" : {
          this.configuration.isoWeekday = 2;
          break;
        }
        case "Wednesday" : {
          this.configuration.isoWeekday = 3;
          break;
        }
        case "Thursday" : {
          this.configuration.isoWeekday = 4;
          break;
        }
        case "Friday" : {
          this.configuration.isoWeekday = 5;
          break;
        }
        default: {
          this.configuration.isoWeekday = null;
          break;
        }
      }
    } else {
      this.configuration.isoWeekday = null;
    }

    // Add New Questions
    for (const question of this.questions.filter(q => q.isNew)) {
      this.configuration.questions.push({
        id: question.id,
        questionTextArray: [value[`${question.id}`]],
        enabled: question.enabled
      });
    }

    // Update Changed Questions
    for (const question of this.configuration.questions) {
      const foundQuestions = this.questions.find(q => q.id == question.id);
      if (foundQuestions) {
        if (value[`${foundQuestions.id}`].localeCompare(question.questionTextArray[question.questionTextArray.length - 1])) {
          question.questionTextArray.push(value[`${foundQuestions.id}`]);
        }
      } else {
        question.enabled = false;
      }
    }

    // Order Questions
    const questionsArray: VersionedQuestion[] = [];
    for (const question of this.questions) {
      questionsArray.push(this.configuration.questions.find(q => q.id == question.id));
    }
    const disabledQuestions = this.configuration.questions.filter(q => !q.enabled);
    if (disabledQuestions && disabledQuestions.length) {
      questionsArray.push(...disabledQuestions);
    }
    this.configuration.questions = questionsArray;

    // Update Share
    this.configuration.shared = [];
    if (this.sharedEmployees) {
      this.sharedEmployees.forEach(employee => this.configuration.shared.push(employee.id));
    }

    // Update Excluded
    this.configuration.excluded = [];
    if (this.excludedEmployees) {
      this.excludedEmployees.forEach(employee => this.configuration.excluded.push(employee.id));
    }

    this.configuration = await this.standupService.putStandupConfiguration(this.configuration);

    this.toastrService.success("Stand-Up Configuration Saved");

    this.dialogRef.close();
  }

  moveQuestion(question: QuestionView, direction: string, index: number) {
    if (direction === "up") {
      if (index) {
        const second = this.questions.find(q => q.index == index - 1);
        const first = this.questions.find(q => q.index == index);

        first.index = first.index - 1;
        second.index = second.index + 1;
      }
    } else {
      if (index != this.questions.length - 1) {
        const second = this.questions.find(q => q.index == index + 1);
        const first = this.questions.find(q => q.index == index);

        first.index = first.index + 1;
        second.index = second.index - 1;
      }
    }
    this.questions.sort((a, b) => a.index > b.index ? 1 : -1);
  }

  addQuestion() {
    const q = {
      id: uuidv4(),
      index: this.questions.length,
      enabled: true,
      isNew: true
    };
    this.questions.push(q);
    const control = new FormControl("", [Validators.required]);
    this.form.addControl(`${q.id}`, control);
    this.form.controls["isValidQuestion"].setValue(this.questions.length > 0);

  }

  deleteQuestion(question: QuestionView) {
    const deleteDialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: "350px",
      data: "Are you sure you want to Delete this question?"
    });
    this.subscriptions.push(
      deleteDialogRef.afterClosed().subscribe(async result => {
        if (result) {
          question.enabled = false;
          const questions = this.questions.filter(q => q.enabled);
          let i = 0;
          for (const q of questions) {
            q.index = i;
            i++;
          }
          this.form.removeControl(`${question.id}`);
          this.questions = questions;
          this.form.controls["isValidQuestion"].setValue(this.questions.length > 0);
        }
      })
    );
  }

  onExcludeClick() {
    const searchDialogRef = this.dialog.open(SearchEmployeeDialogComponent, {
      width: "900px",
      data: {
        clearAfterSelect: true,
        organizationID: this.manager.orgId,
        employeeSet: this.subordinates
      } as EmployeeSearchOptions
    });

    this.subscriptions.push(
      searchDialogRef.afterClosed().subscribe(async employee => {
        this.addExcludedEmployee(employee);
      })
    );
  }

  onShareClick() {

    const searchDialogRef = this.dialog.open(SearchEmployeeDialogComponent, {
      width: "900px",
      data: {
        clearAfterSelect: true,
        includeConsultants: true,
        organizationID: this.manager.orgId
      } as EmployeeSearchOptions
    });

    this.subscriptions.push(
      searchDialogRef.afterClosed().subscribe(async employee => {
        this.addSharedEmployee(employee);
      })
    );
  }

  private addSharedEmployee(employee: Employee) {
    if (employee) {
      this.sharedEmployees.push(employee);
      this.sharedEmployees = Array.from(new Set(this.sharedEmployees.map(e => JSON.stringify(e)))).map(s => JSON.parse(s));
    }
  }

  private addExcludedEmployee(employee: Employee) {
    if (employee) {
      this.excludedEmployees.push(employee);
      this.excludedEmployees = Array.from(new Set(this.excludedEmployees.map(e => JSON.stringify(e)))).map(s => JSON.parse(s));
    }
  }

  private isActiveConsultantForOrganization(employee: Employee): boolean {
    return this.consultants.map(c => c.employeeID).includes(employee.id);
  }

  removeEmployeeShare(employee: Employee) {
    if (employee) {
      this.sharedEmployees = this.sharedEmployees?.filter(e => e.id !== employee.id);
    }
  }

  removeEmployeeExclude(employee: Employee) {
    if (employee) {
      this.excludedEmployees = this.excludedEmployees?.filter(e => e.id !== employee.id);
    }
  }

}

export interface StandupConfigurationComponentDialogData {
  manager: Employee;
}

export interface QuestionView {
  id: string;
  index: number;
  enabled: boolean;
  isNew?: boolean;
}
