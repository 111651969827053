import { Injectable } from "@angular/core";
import { APIService, CreateNoteInput, CreateNoteMutation, GetNoteQuery, UpdateNoteInput } from "./API.service";
import { Note, NoteComment } from "@inthraction/data-models";
import * as moment from "moment";
import { Memoize, MEMOIZE_FN_MAP } from "@inthraction/utils";
import { BaseService } from "./base.service";
import { NoteTypes } from "@inthraction/codes";
import { AuthService } from "./auth.service";

@Injectable({
  providedIn: "root"
})
export class NoteService extends BaseService {

  constructor(
    protected api: APIService,
    protected authService: AuthService
  ) {
    super(api, authService);
  }

  async createNote(note: Note): Promise<Note> {
    note.createdAt = moment().toISOString();
    note.createdBy = (await this.getCurrentUser()).id;

    const newNote: CreateNoteInput = {
      objectID: note.objectID,
      organizationID: note.organizationID,
      createdAt: note.createdAt,
      createdBy: note.createdBy,
      text: note.text,
      noteType: note.noteType
    };

    if (note.options) {
      newNote.options = JSON.stringify(note.options);
    }

    return Note.constructFromCreateNoteMutation(await this.api.CreateNote(newNote));
  }

  async getOneOnOneNotesByObjectID(objectID: string, noteType?: NoteTypes): Promise<Note[]> {
    if (!noteType) {
      noteType = NoteTypes.ONE_ON_ONE;
    }
    const filter = { and: [{ objectID: { eq: objectID } }, { noteType: { eq: noteType } }] };
    const results = await this.getAll<CreateNoteMutation | GetNoteQuery>(this.api.ListNotes, filter);
    return results.map(Note.constructFromCreateNoteMutation);
  }

  @Memoize({ maxAge: 600000, preFetch: true })
  async getOneOnOneNotesByObjectIDMemoize(objectID: string, noteType?: NoteTypes): Promise<Note[]> {
    return this.getOneOnOneNotesByObjectID(objectID, noteType);
  }

  async createNoteComment(noteID: string, organizationID: string, value: string, commentingEmployeeID: string): Promise<NoteComment> {
    return this.api.CreateNoteComment({
      noteID: noteID,
      organizationID: organizationID,
      createdBy: commentingEmployeeID,
      createdAt: moment().toISOString(),
      comment: value
    });
  }

  clearOneOnOneNotesByObjectID(objectID: string) {
    if (MEMOIZE_FN_MAP.has("getOneOnOneNotesByObjectIDMemoize")) {
      MEMOIZE_FN_MAP.get("getOneOnOneNotesByObjectIDMemoize").clear();
    }
  }

  async getNoteByID(noteID: string): Promise<Note> {
    const noteRecord = await this.api.GetNote(noteID);
    return Note.constructFromCreateNoteMutation(noteRecord);
  }

  async updateNote(note: Note): Promise<Note> {
    note.updatedAt = moment().toISOString();
    note.updatedBy = (await this.getCurrentUser()).id;
    const update: UpdateNoteInput = {
      id: note.id,
      updatedBy: note.updatedBy,
      updatedAt: note.updatedAt,
      text: note.text,
      noteType: note.noteType
    };
    if (note.options) {
      update.options = JSON.stringify(note.options);
    }
    const updated = await this.api.UpdateNote(update);
    return Note.constructFromCreateNoteMutation(updated);
  }
}
