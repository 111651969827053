import { Component, OnDestroy, OnInit } from "@angular/core";
import { EmployeeService, OrganizationService, ShoutOutService } from "@inthraction/services";
import { MatDialog } from "@angular/material/dialog";
import { Employee, Organization, OrganizationShoutOutType } from "@inthraction/data-models";
import { MatSlideToggleChange } from "@angular/material/slide-toggle";
import { EditOrganizationShoutOutTypeDialogComponent } from "../../../components/shared/dialogs/edit-organization-shout-out-type-dialog/edit-organization-shout-out-type-dialog.component";
import { Subscription } from "rxjs";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "inthraction-shoutout-configuration",
  templateUrl: "./shoutout-configuration.component.html",
  styleUrls: ["./shoutout-configuration.component.scss"]
})
export class ShoutoutConfigurationComponent implements OnInit, OnDestroy {

  static organizationIDParameterName = "organizationID";

  private subscriptions: Subscription[] = [];
  private organization: Organization;
  private currentUser: Employee;

  initialized: boolean;
  organizationShoutOutTypes: OrganizationShoutOutType[];

  constructor(
    private route: ActivatedRoute,
    private organizationService: OrganizationService,
    private employeeService: EmployeeService,
    private shoutOutService: ShoutOutService,
    private dialog: MatDialog
  ) {
  }

  async ngOnInit() {
    this.subscriptions.push( this.route.paramMap.subscribe(async queryParams => {
      this.initialized = false;
      if (queryParams.has(ShoutoutConfigurationComponent.organizationIDParameterName)) {
        this.organization = await this.organizationService.getOrganizationByIDMemoize(queryParams.get(ShoutoutConfigurationComponent.organizationIDParameterName));
      } else {
        this.organization = await this.organizationService.getOrganizationForCurrentUser();
      }
      await this.loadOrganizationShoutOutTypes(this.organization.id);
      this.initialized = true;
    }));
    this.currentUser = await this.employeeService.getCurrentEmployee();
  }

  ngOnDestroy(): void {
    if (this.subscriptions.length) {
      for (const sub of this.subscriptions) {
        sub.unsubscribe();
      }
    }
  }

  get isShowOrganizationName(): boolean {
    return this.organization && this.currentUser?.orgId !== this.organization?.id;
  }

  get organizationName(): string {
    return this.organization?.orgName;
  }

  async loadOrganizationShoutOutTypes(organizationID?: string) {
    this.organizationShoutOutTypes = await this.shoutOutService.getOrganizationShoutOutTypes(organizationID);
    this.organizationShoutOutTypes.sort((a, b) => a.overrideTitle.localeCompare(b.overrideTitle));
  }

  addNewShoutOut() {
    const editModal = this.dialog.open(EditOrganizationShoutOutTypeDialogComponent, {
      width: "600px",
      data: { addMode: true, organizationID: this.organization.id }
    });
    editModal.afterClosed().subscribe(
      async result => {
        if (result) {
          await this.loadOrganizationShoutOutTypes(this.organization.id);
        }
      }
    );
  }

  editShoutOut(organizationShoutOutType: OrganizationShoutOutType) {
    const editModal = this.dialog.open(EditOrganizationShoutOutTypeDialogComponent, {
      width: "600px",
      data: { addMode: false, organizationShoutOutType }
    });
    editModal.afterClosed().subscribe(
      async result => {
        if (result) {
          await this.loadOrganizationShoutOutTypes(this.organization.id);
        }
      }
    );
  }

  async updateOrgShoutOut(orgShoutOutType: OrganizationShoutOutType, $event: MatSlideToggleChange) {
    await this.shoutOutService.updateOrganizationShoutOutType({ id: orgShoutOutType.id, enabled: $event.checked, organizationID: this.organization.id });
    await this.loadOrganizationShoutOutTypes(this.organization.id);
  }
}
