import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { EmployeeService } from "@inthraction/services";
import { Employee, ReadinessHistory } from "@inthraction/data-models";
import { READINESS_TYPE_LABELS } from "@inthraction/labels";

@Component({
  selector: "app-readiness-history",
  templateUrl: "./readiness-history.component.html",
  styleUrls: ["./readiness-history.component.scss"]
})
export class ReadinessHistoryComponent implements OnInit {

  private employee: Employee;
  readonly readinessLabels = READINESS_TYPE_LABELS;
  historyRecords: ReadinessHistory[];
  authors: Map<string, string> = new Map<string, string>();

  constructor(
    public dialogRef: MatDialogRef<ReadinessHistoryComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private employeeService: EmployeeService
  ) {
    this.employee = data.employee;
  }

  async ngOnInit() {
    const historyRecords = (await this.employeeService.getEmployeeReadinessHistory(this.employee.id))
      .sort((a, b) => {
        return a.createdAt < b.createdAt ? 1 : -1;
      });
    for (const history of historyRecords) {
      if (history.createdBy && !this.authors.has(history.createdBy)) {
        const author = await this.employeeService.getEmployeeByIDMemoize(history.createdBy);
        this.authors.set(history.createdBy, `${author.firstName} ${author.lastName}`);
      }
    }
    this.authors.set("", "Unknown");
    this.historyRecords = historyRecords;
  }

}
