<h2 mat-dialog-title class="relative-title-wrapper">Objective Progress Check
  <inthraction-help-bubble [message]="OBJECTIVE_PROGRESS_CHECK" title="Objective Progress Check" tip="Objective Progress Check Help"></inthraction-help-bubble>
</h2>
<form (ngSubmit)="onSaveClick(progressCheckForm.value)" [formGroup]="progressCheckForm">
  <mat-dialog-content>
    <mat-form-field class="full-width">
      <mat-label>Objective</mat-label>
      <mat-select formControlName="objectiveID">
        <mat-option *ngFor="let objective of objectives" [value]="objective.id" (onSelectionChange)="selectObjective($event)">
          <ng-container
            *ngIf="objective.objectiveType != 'QUANTIFIABLE'">{{objective.orgObjective.objective.display}}</ng-container>
          <ng-container *ngIf="objective.objectiveType == 'QUANTIFIABLE'">{{objective.title}}</ng-container>
        </mat-option>
      </mat-select>
    </mat-form-field>
    <div>
      <mat-radio-group formControlName="status">
        <mat-radio-button *ngFor="let statusValue of progressStatusValues" [value]="statusValue">{{progressStatusLabels[statusValue]}}</mat-radio-button>
      </mat-radio-group>
    </div>
    <mat-form-field class="full-width">
      <mat-label>Progress Check Notes</mat-label>
      <textarea matInput cdkTextareaAutosize cdkAutosizeMinRows="2" cdkAutosizeMaxRows="10" formControlName="progressCheckText"></textarea>
    </mat-form-field>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button mat-button (click)="onCancelClick()" type="button">Cancel</button>
    <button mat-raised-button [disabled]="!progressCheckForm.valid" type="submit">Add</button>
  </mat-dialog-actions>
</form>
