<h1 mat-dialog-title>Filter Organizations for {{objectiveDisplay}}</h1>
<form class="add-org-defined-objective" (ngSubmit)="onSaveClick(form.value, submitBtn)" [formGroup]="form" autocomplete="off" novalidate>
  <div mat-dialog-content>

    <inthraction-organization-search  [options]="{clearAfterSelect: true, sites}" (organizationSelected)="searchOrganization($event)"></inthraction-organization-search>

    <mat-table *ngIf="showGrid" [dataSource]="dataSource">
      <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef> Name </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.orgName}} </mat-cell>
      </ng-container>
      <ng-container matColumnDef="delete">
        <mat-header-cell *matHeaderCellDef> Remove </mat-header-cell>
        <mat-cell *matCellDef="let element">
          <button mat-icon-button type="button" (click)="removeOrganization(element)"><mat-icon>delete</mat-icon></button>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
    </mat-table>

    <div *ngIf="!showGrid">
      <h3>Objective will be enabled for all Organizations</h3>
    </div>

  </div>
  <div mat-dialog-actions>
    <button (click)="onCancelClick()" mat-button type="button">Cancel</button>
    <button #submitBtn [disabled]="!form.valid" color="primary" mat-raised-button type="submit">Save</button>
  </div>
</form>
