import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { AuthenticatorService } from "@aws-amplify/ui-angular";
import { Auth, Amplify } from 'aws-amplify';
import { ICredentials } from "@aws-amplify/core";
import { CognitoUser } from "amazon-cognito-identity-js";
import { AWSConfig } from "./aws-config";

@Injectable({
  providedIn: "root"
})
export class AuthService {

  public authStateChange$ = new BehaviorSubject(this.authState);
  public currentUserChange$ = new BehaviorSubject(this.currentUser);

  constructor(
    private authenticatorService: AuthenticatorService
  ) {
    Amplify.configure(AWSConfig);
  }

  private _authState;

  get authState(): AuthState {
    return this._authState;
  }

  set authState(value: AuthState) {
    this._authState = value;
    this.authStateChange$.next(value);
  }

  private _currentUser;

  get currentUser(): CognitoUser | undefined {
    return this._currentUser;
  }

  set currentUser(value: CognitoUser | undefined) {
    this._currentUser = value;
    this.currentUserChange$.next(value);
  }

  public async currentAuthenticatedUser(bypassCache = false): Promise<CognitoUser> {
    let cognitoUser: CognitoUser;
    try {
      cognitoUser = await Auth.currentAuthenticatedUser({ bypassCache: bypassCache });
    } catch (e) {
      console.debug("AuthService.currentAuthenticatedUser", e);
    }
    return cognitoUser;
  }

  public async currentCredentials(): Promise<ICredentials> {
    let credentials;
    try {
      credentials = await Auth.currentCredentials();
    } catch (e) {
      console.debug("AuthService.currentCredentials", e);
    }
    return credentials;
  }

  public async changePassword(oldPassword, newPassword): Promise<string> {
    const currentUser = await this.currentAuthenticatedUser(true);
    return Auth.changePassword(currentUser, oldPassword, newPassword);
  }

  public async signOut(global = false) {
    this.authenticatorService.signOut();
    // await Auth.signOut({ global: global });
    this.authState = AuthState.SignedOut;
    this.currentUser = undefined;
  }

  public authListener = (data) => {
    switch (data.payload.event) {
      case "signIn":
        console.debug("user signed in", data.payload);
        this.currentUser = data.payload.data;
        this.authState = AuthState.SignedIn;
        break;
      case "signUp":
        // console.info("user signed up");
        break;
      case "signOut":
        console.debug("user signed out");
        this.authState = AuthState.SignedOut;
        this.currentUser = undefined;
        break;
      case "signIn_failure":
        // console.error("user sign in failed");
        break;
      case "tokenRefresh":
        // console.info("token refresh succeeded");
        break;
      case "tokenRefresh_failure":
        // console.error("token refresh failed");
        break;
      case "configured":
      // console.info("the Auth module is configured");
    }
  };

}

export enum AuthState {
  SignUp = "signup",
  SignOut = "signout",
  SignIn = "signin",
  Loading = "loading",
  SignedOut = "signedout",
  SignedIn = "signedin",
  SigningUp = "signingup",
  ConfirmSignUp = "confirmSignUp",
  confirmingSignUpCustomFlow = "confirmsignupcustomflow",
  ConfirmSignIn = "confirmSignIn",
  confirmingSignInCustomFlow = "confirmingsignincustomflow",
  VerifyingAttributes = "verifyingattributes",
  ForgotPassword = "forgotpassword",
  ResetPassword = "resettingpassword",
  SettingMFA = "settingMFA",
  TOTPSetup = "TOTPSetup",
  CustomConfirmSignIn = "customConfirmSignIn",
  VerifyContact = "verifyContact"
}
