import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import {
  CadenceObjectiveAssignment,
  Employee,
  Note,
  ObjectiveAssignment,
  QuantifiableObjectiveAssignment
} from "@inthraction/data-models";
import { EmployeeService, NoteService, ObjectiveService } from "@inthraction/services";
import {
  AddEditEmployeeObjectiveComponent
} from "../../../components/employee-settings/add-edit-employee-objective.component";
import { MatDialog } from "@angular/material/dialog";
import { EmployeeReadinessComponent } from "../../../components/shared/employee-readiness/employee-readiness.component";
import { InthractionComponent } from "../../../components/shared/inthraction/inthraction.component";
import { Subscription } from "rxjs";
import { NoteTypes } from "@inthraction/codes";
import {
  ASSIGN_OBJECTIVES_HELP,
  ASSIGN_READINESS_LEVEL_HELP,
  HR_DASHBOARD_COMMUNICATION_HELP,
  OBJECTIVE_RESPONSE_DISTRIBUTION_HELP,
  READINESS_HISTORY_HELP
} from "@inthraction/labels";
import { ReadinessHistoryComponent } from "../../../components/shared/readiness-history/readiness-history.component";
import {
  ObjectiveResponseDistributionDialogComponent
} from "../../../components/objective-response-distribution/objective-response-distribution-dialog/objective-response-distribution-dialog.component";
import {
  ObjectiveResponseDistributionMode
} from "../../../components/objective-response-distribution/objective-response-distribution.component";

@Component({
  selector: "inthraction-hr-employee-edit",
  templateUrl: "./hr-employee-edit.component.html",
  styleUrls: ["./hr-employee-edit.component.scss"]
})
export class HrEmployeeEditComponent implements OnInit, OnDestroy {

  static EMPLOYEE_ID_PARAMETER = "employee-id";
  readonly COMMUNICATION_HELP = HR_DASHBOARD_COMMUNICATION_HELP;
  readonly ASSIGN_OBJECTIVES_HELP = ASSIGN_OBJECTIVES_HELP;
  readonly ASSIGN_READINESS_LEVEL_HELP = ASSIGN_READINESS_LEVEL_HELP;
  readonly READINESS_HISTORY_HELP = READINESS_HISTORY_HELP;
  readonly OBJECTIVE_RESPONSE_DISTRIBUTION_HELP = OBJECTIVE_RESPONSE_DISTRIBUTION_HELP;

  currentUser: Employee;
  employee: Employee;
  employeeObjectives: (ObjectiveAssignment | QuantifiableObjectiveAssignment | CadenceObjectiveAssignment)[];
  readonly PageMode = PageMode;
  oneOnOneNotes: Note[];
  managerOneOnOneNotes: Note[];
  consultantOneOnOneNotes: Note[];
  private subscriptions: Subscription[] = [];
  ratedGraph = false;
  ratesGraph = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private employeeService: EmployeeService,
    private objectiveService: ObjectiveService,
    private noteService: NoteService,
    public dialog: MatDialog
  ) {
  }

  get mode(): PageMode {
    return PageMode.HR
  }

  get notSelf(): boolean {
    return this.employee.id !== this.currentUser.id;
  }

  ngOnDestroy(): void {
    if (this.subscriptions.length) {
      for (const sub of this.subscriptions) {
        sub.unsubscribe();
      }
    }
  }

  async ngOnInit() {
    this.currentUser = await this.employeeService.getCurrentEmployee();
    const employeeID = this.route.snapshot.paramMap.get(HrEmployeeEditComponent.EMPLOYEE_ID_PARAMETER);
    this.employee = await this.employeeService.getEmployeeByID(employeeID);

    const objectives = await this.objectiveService.getEmployeeObjectiveAssignmentsMemoize(this.employee.id);
    if (objectives && objectives.length > 0) {
      this.employeeObjectives = objectives;
    } else {
      this.employeeObjectives = [];
    }

    this.oneOnOneNotes = await this.findOneOnOneNotes(this.mode === PageMode.HR ? NoteTypes.HR_ONE_ON_ONE : NoteTypes.CONSULTANT_ONE_ON_ONE);
    if (this.mode === PageMode.HR) {
      this.managerOneOnOneNotes = await this.findOneOnOneNotes(NoteTypes.ONE_ON_ONE);
      this.consultantOneOnOneNotes = await this.findOneOnOneNotes(NoteTypes.CONSULTANT_ONE_ON_ONE);
    }

  }

  openAddObjective(): void {
    const addDialog = this.dialog.open(AddEditEmployeeObjectiveComponent, {
      width: "600px",
      data: {
        employee: this.employee,
        manager: this.currentUser,
        callingPage: this.mode === PageMode.HR ? "HR" : "CONSULTANT",
        objectives: this.employeeObjectives,
        mode: "add"
      }
    });

    this.subscriptions.push(
      addDialog.afterClosed().subscribe(result => {
        if (result && result.objective) {
          this.employeeObjectives.push(result.objective);
          this.employeeService.clearMemoizedEmployee(this.employee);
        }
      }));
  }

  openEditObjective(editId: string): void {
    const addDialog = this.dialog.open(AddEditEmployeeObjectiveComponent, {
      width: "600px",
      data: {
        employee: this.employee,
        callingPage: this.mode === PageMode.HR ? "HR" : "CONSULTANT",
        manager: this.currentUser,
        objectives: this.employeeObjectives.filter(ug => ug.id === editId),
        mode: "edit"
      }
    });

    this.subscriptions.push(
      addDialog.afterClosed().subscribe(result => {
        if (result && result.objective) {
          this.employeeObjectives = this.employeeObjectives.filter(ug => ug.id !== result.objective.id);
          // Populate new version if not deleted
          if (!result.deleted) {
            this.employeeObjectives.push(result.objective);
          }
          this.employeeService.clearMemoizedEmployee(this.employee);
        }
      }));
  }

  openSetReadiness() {
    if (this.mode === PageMode.HR) {
      const dialog = this.dialog.open(EmployeeReadinessComponent, {
        width: "600px",
        data: {
          employee: this.employee
        }
      });

      this.subscriptions.push(
        dialog.afterClosed().subscribe(result => {
          if (result && result.readiness) {
            this.employee.readiness = result.readiness.id;
          }
        }));
    }
  }

  async openRecentIntHRaction(eventID: string, employeeID?: string) {
    this.dialog.open(InthractionComponent, {
      width: "600px",
      data: {
        event: eventID,
        employee: employeeID ? await this.employeeService.getEmployeeByIDMemoize(employeeID) : this.employee
      }
    });
  }

  openNotes(noteID?: string) {
    this.router.navigate([`one-on-one${noteID ? "/" + noteID : ""}`], { relativeTo: this.route });
  }

  async editOneOnOneNote($event: any) {
    let noteTypeRoute = "one-on-one";
    const note = await this.noteService.getNoteByID($event.noteId);
    switch (note.options.reviewType) {
      case "ONE": {
        noteTypeRoute = "one-on-one";
        break;
      }
    }
    this.router.navigate([`${noteTypeRoute}/${$event.noteId}`], { relativeTo: this.route });
  }

  async reviewOneOnOneNote($event: any) {
    let noteTypeRoute = "view-one-on-one";
    const note = await this.noteService.getNoteByID($event.noteId);
    switch (note.options.reviewType) {
      case "ONE": {
        noteTypeRoute = "view-one-on-one";
        break;
      }
    }
    this.router.navigate([`${noteTypeRoute}/${$event.noteId}`], { relativeTo: this.route });
  }

  private async findOneOnOneNotes(noteType: NoteTypes): Promise<Note[]> {
    const notes = await this.noteService.getOneOnOneNotesByObjectIDMemoize(this.employee.id, noteType);
    return notes.sort((a, b) => a.createdAt > b.createdAt ? -1 : a.createdAt < b.createdAt ? 1 : 0);
  }

  openReadinessHistory() {
    this.dialog.open(ReadinessHistoryComponent, {
      width: "600px",
      data: {
        employee: this.employee
      }
    });
  }

  showObjectiveResponseDistributions(employee: Employee) {
    this.dialog.open(ObjectiveResponseDistributionDialogComponent, {
      width: "100%",
      height: "90%",
      data: { employee, mode: ObjectiveResponseDistributionMode.HR }
    });
  }

}

export enum PageMode {
  CONSULTANT,
  HR
}
