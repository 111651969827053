import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormControl } from "@angular/forms";
import { Observable } from "rxjs";
import { Organization } from "@inthraction/data-models";
import { OrganizationService } from "@inthraction/services";
import { map, startWith } from "rxjs/operators";

@Component({
  selector: 'inthraction-organization-search',
  templateUrl: './organization-search.component.html',
  styleUrls: ['./organization-search.component.scss']
})
export class OrganizationSearchComponent implements OnInit {


  control = new FormControl();
  filteredOrganizations: Observable<Organization[]>;

  private allOrganizations: Organization[] = [];

  @Input()
  options: OrganizationSearchOptions = {};

  @Output()
  organizationSelected: EventEmitter<Organization> = new EventEmitter<Organization>();

  constructor(
    private organizationService: OrganizationService
  ) {
  }

  async ngOnInit() {
    const organizations = await this.organizationService.getOrganizations();
    this.allOrganizations.push(...organizations);

    if (this.options.sites?.length) {
      this.allOrganizations = this.allOrganizations.filter(o => this.options.sites.includes(o.site));
    }

    if(!this.options.includeDisabled ) {
      this.allOrganizations = this.allOrganizations.filter( o => !o.disabled);
    }

    this.allOrganizations.sort((a, b) => a.orgName.localeCompare(b.orgName));
    this.control.valueChanges.subscribe(async (value) => {
      if (value && !(value instanceof String || typeof value === "string")) {
        this.organizationSelected.emit(value);
        if (this.options.clearAfterSelect) {
          this.control.reset("", { emitEvent: false });
        }
      }
    });

    this.filteredOrganizations = this.control.valueChanges.pipe(
      startWith(""),
      map(value => this._filter(value))
    );

  }

  displayFn(organization: Organization): string {
    return organization ? `${organization.orgName}` : "";
  }

  private _filter(value: string): Organization[] {
    const filterValue = this._normalizeValue(value);
    return this.allOrganizations.filter(organization => this._normalizeValue(`${organization.orgName}`).includes(filterValue));
  }

  private _normalizeValue(value: string): string {
    if (value && (value as any instanceof String || typeof value === "string")) {
      return value?.toLowerCase().replace(/\s/g, "");
    }
  }

}

export interface OrganizationSearchOptions {
  clearAfterSelect?: boolean;
  includeDisabled?: boolean;
  sites?: string[];
}
