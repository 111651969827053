<div class="qrcodeImage" *ngIf="qr">
  <mat-card>
    <mat-card-header class="center">
      <mat-card-title>{{qr.title}}</mat-card-title>
    </mat-card-header>
    <mat-card-content class="center">
      <qrcode
        [qrdata]="qr.qrdata"
        [allowEmptyString]="true"
        [ariaLabel]="qr.label"
        [cssClass]="'center'"
        [colorDark]="'#000000ff'"
        [colorLight]="'#ffffffff'"
        [elementType]="'canvas'"
        [errorCorrectionLevel]="'M'"
        [imageSrc]="qr.imageSrc"
        [imageHeight]="75"
        [imageWidth]="75"
        [margin]="4"
        [scale]="1"
        [title]="qr.title"
        [width]="300"
      ></qrcode>
    </mat-card-content>
    <mat-card-footer>
      <ng-content></ng-content>
    </mat-card-footer>
  </mat-card>
</div>
