import { AfterViewInit, Component, OnInit, ViewChild } from "@angular/core";
import { EmployeeService, SurveyService } from "@inthraction/services";
import { ScoreTypes, SurveyTypeCodes } from "@inthraction/codes";
import * as moment from "moment";
import { Employee } from "@inthraction/data-models";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import {
  NEW_MANAGER_30_SURVEY_RESPONSE_LABELS,
  NEW_MANAGER_90_SURVEY_RESPONSE_LABELS,
  OBJECTIVE_SURVEY_RESPONSE_LABELS,
  ONBOARDING_14_SURVEY_RESPONSE_LABELS,
  ONBOARDING_90_SURVEY_RESPONSE_LABELS,
  POSITION_60_SURVEY_RESPONSE_LABELS
} from "@inthraction/labels";
import { saveAs } from "file-saver";


const OrganizationExperienceScoreTypes = [ScoreTypes.YTDNewHire14DayScore, ScoreTypes.YTDNewHire90DayScore, ScoreTypes.YTDNewManager30DayScore, ScoreTypes.YTDNewManager90DayScore, ScoreTypes.YTDNewPosition60DayScore];

@Component({
  selector: "app-organization-experience-report",
  templateUrl: "./organization-experience-report.component.html",
  styleUrls: ["./organization-experience-report.component.scss"]
})
export class OrganizationExperienceReportComponent implements OnInit, AfterViewInit {

  multi: any[];
  view: any[] = [700, 300];

  // options
  legend: boolean = true;
  showLabels: boolean = true;
  animations: boolean = true;
  xAxis: boolean = true;
  yAxis: boolean = true;
  showYAxisLabel: boolean = true;
  showXAxisLabel: boolean = true;
  xAxisLabel: string = "Date";
  yAxisLabel: string = "Score";
  timeline: boolean = true;

  selectedRowIndex: string = "";

  dataSource = new MatTableDataSource([]);
  displayedColumns = ["experienceName", "name", "manager", "date", "score", "response", "comment"];
  noResults = false;
  private employeesMap = new Map<string, Employee>();

  colorScheme = {
    domain: ["#5AA454", "#E44D25", "#CFC0BB", "#7aa3e5", "#a8385d", "#aae3f5"]
  };

  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private employeeService: EmployeeService,
    private surveyService: SurveyService
  ) {
  }

  async ngOnInit() {
    const multi: any[] = [];
    const datasource: any[] = [];
    for (const scoreType of OrganizationExperienceScoreTypes) {
      const parent = {
        name: this.getNameFromScoreType(scoreType),
        series: []
      };
      const scores = await this.employeeService.getYTDOrganizationExperienceScoresByOrganization(scoreType);

      for (const score of scores) {
        if (score.details) {
          const details: OrganizationExperienceScoreDetail[] = JSON.parse(score.details.substring(1, score.details.length - 1).replace(/\\\"/g, "\""));
          if (details) {
            for (const detail of details) {

              const survey = await this.surveyService.getSurveyByID(detail.id);

              if (detail.score) {
                parent.series.push({
                  experienceName: parent.name,
                  name: moment(detail.surveyDate).format("ll"),
                  value: detail.score,
                  surveyDate: detail.surveyDate,
                  surveyID: detail.id,
                  employee: await this.getEmployee(score.employeeID),
                  manager: await this.getEmployee(detail.managerID),
                  comment: survey.feedback,
                  response: this.getSurveyResponseDisplay(survey.surveyType, survey.surveyResponse)
                });
              }
            }
          }
        }
      }
      parent.series.sort((a, b) => (a.surveyDate > b.surveyDate) ? 1 : ((b.surveyDate > a.surveyDate) ? -1 : 0));
      multi.push(parent);

      datasource.push(...parent.series);
    }

    this.multi = multi;

    this.dataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        case "experience":
          return item.experienceName ? item.experienceName : "";
        case "manager":
          return item.manager ? `${item.manager.lastName}${item.manager.firstName}` : "";
        case "name":
          return item.employee ? `${item.employee.lastName}${item.employee.firstName}` : "";
        case "date":
          return item.surveyDate;
        case "score":
          return item.value;
        default:
          return item[property];
      }
    };

    this.dataSource.data = datasource;
    this.noResults = !(datasource.length);
  }

  ngAfterViewInit() {
    if (this.dataSource) {
      this.dataSource.sort = this.sort;
    }
  }

  private async getEmployee(id: string): Promise<Employee> {
    if (id && !this.employeesMap.has(id)) {
      const employee = await this.employeeService.getEmployeeByIDMemoize(id);
      this.employeesMap.set(employee.id, employee);
    }
    return this.employeesMap.get(id);
  }

  getNameFromScoreType(scoreType: ScoreTypes) {
    switch (scoreType) {
      case ScoreTypes.YTDNewHire14DayScore: {
        return "New Hire 14 Days";
      }
      case ScoreTypes.YTDNewHire90DayScore: {
        return "New Hire 90 Days";
      }
      case ScoreTypes.YTDNewPosition60DayScore: {
        return "New Position 60 Days";
      }
      case ScoreTypes.YTDNewManager30DayScore: {
        return "New Manager 30 Days";
      }
      case ScoreTypes.YTDNewManager90DayScore: {
        return "New Manager 90 Days";
      }
    }
  }

  onSelect(data): void {
    this.selectedRowIndex = data.surveyID;
  }

  private getSurveyResponseDisplay(surveyType, surveyResponse): string {
    let surveyResponseLabels;
    switch (surveyType) {
      case SurveyTypeCodes.NEW_HIRE_14_DAY : {
        surveyResponseLabels = ONBOARDING_14_SURVEY_RESPONSE_LABELS;
        break;
      }
      case SurveyTypeCodes.NEW_POSITION_60_DAY : {
        surveyResponseLabels = POSITION_60_SURVEY_RESPONSE_LABELS;
        break;
      }
      case SurveyTypeCodes.NEW_MANAGER_30_DAY : {
        surveyResponseLabels = NEW_MANAGER_30_SURVEY_RESPONSE_LABELS;
        break;
      }
      case SurveyTypeCodes.NEW_HIRE_90_DAY : {
        surveyResponseLabels = ONBOARDING_90_SURVEY_RESPONSE_LABELS;
        break;
      }
      case SurveyTypeCodes.NEW_MANAGER_90_DAY : {
        surveyResponseLabels = NEW_MANAGER_90_SURVEY_RESPONSE_LABELS;
        break;
      }
      default: {
        surveyResponseLabels = OBJECTIVE_SURVEY_RESPONSE_LABELS;
      }
    }
    return (Object.values(surveyResponseLabels) as string[])[surveyResponse - 1];
  }

  downloadCSV() {
    const fileName = `Organization Experience ${moment().format("l")}`;
    const csvData = [];
    const csvHeaders = [
      "Experience",
      "Name",
      "Manager",
      "Date",
      "Score",
      "Response",
      "Comments"
    ];

    csvData.push(csvHeaders);
    for (const row of this.dataSource.data) {
      const rowData = [
        row.experienceName || "",
        `${row.employee.firstName} ${row.employee.lastName}` || "",
        row.manager ? `${row.manager.firstName} ${row.manager.lastName}` : "",
        `"${row.name}"` || "",
        row.value || "",
        row.response || "",
        `"${row.comment}"` || ""
      ];

      csvData.push(rowData);
    }
    let csvArray = csvData.join("\r\n");
    const blob = new Blob([csvArray], { type: "text/csv" });
    saveAs(blob, `${fileName}.csv`.replace(" ", "_"));
  }

}

interface OrganizationExperienceScoreDetail {
  id: string,
  surveyDate: string,
  surveyResponse: number,
  score: number,
  managerID?: string
}
