import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from "@angular/router";
import { AuthService } from "@inthraction/services";

@Injectable({
  providedIn: "root"
})
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private authService: AuthService
  ) {
  }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    let securityUser = this.authService.currentUser;
    if (!securityUser) {
      securityUser = await this.authService.currentAuthenticatedUser(true);
      if (securityUser) {
        this.authService.currentUser = securityUser;
      }
    }
    if (securityUser) {
      return true;
    } else { // Unauthenticated, redirect to login
      const uTree = this.router.parseUrl("/login");
      uTree.queryParams["returnUrl"] = state.url;
      return uTree;
    }
  }
}
