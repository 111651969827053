import { Component, Input } from "@angular/core";

@Component({
  selector: 'inthraction-side-nav-drawer',
  templateUrl: './side-nav-drawer.component.html',
  styleUrls: ['./side-nav-drawer.component.scss']
})
export class SideNavDrawerComponent {

  @Input() menuOptions: SideNavDrawerMenuOption[];
  isExpanded = true;
  readonly SideNavDrawerMenuOptionType = SideNavDrawerMenuOptionType;


}

export interface SideNavDrawerMenuOption {
  type: SideNavDrawerMenuOptionType;
  routerLink?: string;
  href?: string;
  title: string;
  label?: string;
  icon?: string;
  children?: SideNavDrawerMenuOption[];
  child?: boolean;
}

export enum SideNavDrawerMenuOptionType {
  LINK,
  ANCHOR,
  GROUP,
  DIVIDER
}
