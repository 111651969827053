<div class="survey-page-container">
  <div></div>
  <mat-card appearance="outlined" *ngIf="attendee && survey && project">
    <mat-card-header>
      <inthraction-profile-image [employeeImage]="attendeeImage"></inthraction-profile-image>
      <div class="employee-info">
        <mat-card-title>{{attendee.firstName}}&nbsp;{{attendee.lastName}}</mat-card-title>
        <mat-card-subtitle>{{project.title}}</mat-card-subtitle>
        <mat-card-subtitle>{{survey.surveyDate | amParse:'YYYY-MM-DDTHH:mm:ssZ' | amDateFormat:'LL'}}</mat-card-subtitle>
      </div>
    </mat-card-header>
    <form (ngSubmit)="submitSurvey(surveyForm.value, submitButtonElement)" [formGroup]="surveyForm" autocomplete="off" novalidate>
      <mat-card-content>
        <div class="survey-container">
          <label id="survey-radio-group-label">In your opinion, how has {{attendee.firstName}} performed on the project "{{project.title}}" since your last Check-In?</label>
          <div class="survey-group">
            <ng-container *ngFor="let id of surveyResponseTypes">
              <div id="{{id}}-btn" (click)="surveyForm.get('surveyValue').setValue(surveyResponseValues[id])"
                   [className]="surveyForm.get('surveyValue').value === surveyResponseValues[id] ? 'selected':''"
                   [matTooltipShowDelay]="500" [matTooltipPosition]="'left'"
                   matTooltip="{{surveyResponseOptionsTips[id]}}">
                <button mat-mini-fab id="{{id}}-icon" type="button">
                  <mat-icon svgIcon="{{surveyResponseIcons[id]}}"></mat-icon>
                </button>
                <span>{{surveyResponseLabels[id] + (surveyResponseValues[id] === 1 || surveyResponseValues[id] === 5 ? ' (Feedback Required)' : '')}}</span>
              </div>
            </ng-container>
          </div>
          <mat-form-field class="full-width">
            <mat-label>Feedback</mat-label>
            <textarea matInput formControlName="feedback" id="feedback" maxlength="500" name="Feedback" rows="3"></textarea>
            <mat-error *ngIf="hasError('feedback', 'required')">Feedback is required</mat-error>
          </mat-form-field>
        </div>
      </mat-card-content>
      <mat-card-actions>
        <button #submitButtonElement [disabled]="!surveyForm.valid" color="primary" mat-raised-button type="submit">Submit</button>
      </mat-card-actions>
    </form>
    <mat-card-content class="footer">
      <span class="btm-cnr-right">1 of {{remainingSurveysCount}}</span>
    </mat-card-content>
  </mat-card>
  <div></div>
</div>
