import { Component } from '@angular/core';
import { OneOnOneComponent, PageMode } from "../../../components/shared/one-on-one/one-on-one.component";

@Component({
  selector: 'app-standup-one-on-one',
  templateUrl: '../../../components/shared/one-on-one/one-on-one.component.html',
  styleUrls: ['../../../components/shared/one-on-one/one-on-one.component.scss']
})
export class StandupOneOnOneComponent  extends OneOnOneComponent {

  get mode(): PageMode {
    return PageMode.STANDUP;
  }

}
