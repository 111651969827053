import { Component, OnInit } from "@angular/core";
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";
import { environment } from "src/environments/environment";
import { NavigationEnd, Router } from "@angular/router";
import { AuthService, AuthState } from "@inthraction/services";
import { ShoutOutIcons } from "@inthraction/codes";
import { AuthenticatorService } from "@aws-amplify/ui-angular";
import { Hub } from "aws-amplify";

// eslint-disable-next-line @typescript-eslint/ban-types
declare let gtag: Function;


@Component({
  selector: "inthraction-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"]
})
export class AppComponent implements OnInit {
  title = "intHRactionApp";

  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private authService: AuthService,
    public router: Router,
    public authenticator: AuthenticatorService
  ) {
    this.matIconRegistry.addSvgIcon("intHRaction", this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/intHRaction-favicon.svg"));
    this.matIconRegistry.addSvgIcon("intHRaction-bw", this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/intHRaction-favicon-bw.svg"));

    // Shout Out Icons
    for (const key of Object.keys(ShoutOutIcons)) {
      this.matIconRegistry.addSvgIcon(ShoutOutIcons[key], this.domSanitizer.bypassSecurityTrustResourceUrl(`../assets/shoutout-icons/${ShoutOutIcons[key]}.svg`));
    }

    if (environment.googleAnalyticsMeasurementID) {
      this.loadGoogleAnalytics();
      this.router.events.subscribe(event => {
          if (event instanceof NavigationEnd) {
            gtag("config", environment.googleAnalyticsMeasurementID, { "page_path": event.urlAfterRedirects });
          }
        }
      );
    }
  }

  async ngOnInit() {
    const securityUser = await this.authService.currentAuthenticatedUser(true);
    this.authService.currentUser = securityUser;
    this.authService.authState = securityUser ? AuthState.SignedIn : AuthState.SignedOut;
    Hub.listen("auth", this.authService.authListener);
  }

  loadGoogleAnalytics() {
    const node = document.createElement("script"); // creates the script tag
    node.src = `https://www.googletagmanager.com/gtag/js?id=${environment.googleAnalyticsMeasurementID}`; // sets the source (insert url in between quotes)
    node.type = "text/javascript"; // set the script type
    node.async = true; // makes script run asynchronously
    node.charset = "utf-8";
    // append to head of document
    document.getElementsByTagName("head")[0].appendChild(node);

    const node2 = document.createElement("script"); // creates the script tag
    node2.type = "text/javascript"; // set the script type
    node2.innerText = `window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments);} gtag('js', new Date());`;

    // append to head of document
    document.getElementsByTagName("head")[0].appendChild(node2);
  }

}
