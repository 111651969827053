import {
  AfterViewInit,
  Component,
  NgZone,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
  ViewContainerRef
} from "@angular/core";
import { Subscription } from "rxjs";
import { ConsultantFavorites, Employee, Organization, StandupSurveyConfiguration } from "@inthraction/data-models";
import { ActivatedRoute, Router } from "@angular/router";
import { EmployeeService, OrganizationService, StandupService } from "@inthraction/services";
import { Location } from "@angular/common";
import { CONSULTANT_EMPLOYEES_HELP } from "@inthraction/labels";
import { TemplatePortal } from "@angular/cdk/portal";
import { ProjectDashboardMode } from "../project-dashboard/project-dashboard.component";
import { SideNavDrawerMenuOption } from "../../../components/shared/side-nav-drawer/side-nav-drawer.component";

enum ConsultantDashboardContext {
  FAVORITES= 'FAVORITES',
  WORKFORCE = 'WORKFORCE',
  PROJECTS = 'PROJECTS',
  STANDUPS = 'STANDUPS',
  OBJECTIVE_ASSIGNMENTS_RPT = 'OBJECTIVE_ASSIGNMENTS_RPT'
}

@Component({
  selector: "inthraction-consultant-dashboard",
  templateUrl: "./consultant-dashboard.component.html",
  styleUrls: ["./consultant-dashboard.component.scss"]
})
export class ConsultantDashboardComponent implements OnInit, OnDestroy, AfterViewInit {
  static organizationIDParameterName = "organization-id";
  static employeeIDParameterName = "employee-id";

  private subscriptions: Subscription[] = [];
  readonly CONSULTANT_EMPLOYEES_HELP = CONSULTANT_EMPLOYEES_HELP;
  readonly ProjectDashboardMode = ProjectDashboardMode;
  readonly ConsultantDashboardContext = ConsultantDashboardContext;

  organization: Organization;
  subordinates: Employee[] = [];
  topEmployees: Employee[] = [];
  favorites: string[] = [];
  navToEmployeeID: string;
  consultantFavorites: ConsultantFavorites;
  standupConfigrations: StandupSurveyConfiguration[];
  _selectedPortal: TemplatePortal<any>;

  @ViewChild('favoritesContent') favoritesPortalContent: TemplateRef<unknown>;
  @ViewChild('workforceContent') workforcePortalContent: TemplateRef<unknown>;
  @ViewChild('projectsContent') projectsPortalContent: TemplateRef<unknown>;
  @ViewChild('standupsContent') standupsPortalContent: TemplateRef<unknown>;
  @ViewChild('objectivesAssignmentsReportContent') objectivesAssignmentsReportPortalContent: TemplateRef<unknown>;
  favoritesPortal: TemplatePortal<unknown>;
  workforcePortal: TemplatePortal<unknown>;
  projectsPortal: TemplatePortal<unknown>;
  standupsPortal: TemplatePortal<unknown>;
  objectivesAssignmentsReportPortal: TemplatePortal<unknown>;


  constructor(
    private route: ActivatedRoute,
    private ngZone: NgZone,
    private router: Router,
    private location: Location,
    private organizationService: OrganizationService,
    private employeeService: EmployeeService,
    private standupService: StandupService,
    private _viewContainerRef: ViewContainerRef
  ) {
  }

  ngOnDestroy(): void {
    if (this.subscriptions.length) {
      for (const sub of this.subscriptions) {
        sub.unsubscribe();
      }
    }
  }

  ngAfterViewInit() {
    this.favoritesPortal = new TemplatePortal(this.favoritesPortalContent, this._viewContainerRef, ConsultantDashboardContext.FAVORITES);
    this.workforcePortal = new TemplatePortal(this.workforcePortalContent, this._viewContainerRef, ConsultantDashboardContext.WORKFORCE);
    this.projectsPortal = new TemplatePortal(this.projectsPortalContent, this._viewContainerRef, ConsultantDashboardContext.PROJECTS);
    this.standupsPortal = new TemplatePortal(this.standupsPortalContent, this._viewContainerRef, ConsultantDashboardContext.STANDUPS);
    this.objectivesAssignmentsReportPortal = new TemplatePortal(this.objectivesAssignmentsReportPortalContent, this._viewContainerRef, ConsultantDashboardContext.OBJECTIVE_ASSIGNMENTS_RPT);
  }

  ngOnInit(): void {

    this.subscriptions.push(this.route.paramMap.subscribe(async queryParams => {
      if (queryParams.has(ConsultantDashboardComponent.organizationIDParameterName)) {
        this.organization = await this.organizationService.getOrganizationByIDMemoize(queryParams.get(ConsultantDashboardComponent.organizationIDParameterName));
        await this.initializeDashboardData(this.organization);
      } else {
        console.error("Consultant Dashboard missing Organization ID")
        await this.ngZone.run(async () => {
          await this.router.navigate([`dashboard`]);
        });
      }
      if (queryParams.has(ConsultantDashboardComponent.employeeIDParameterName)) {
        const routeEmployee = await this.employeeService.getEmployeeByIDMemoize(queryParams.get(ConsultantDashboardComponent.employeeIDParameterName));
        if (routeEmployee && !routeEmployee.disabled) {
          this.navToEmployeeID = routeEmployee.id;
        }
      }

      if (this.route.routeConfig.path.includes('workforce')) {
        this.selectedPortal = this.workforcePortal;
      } else if (this.route.routeConfig.path.endsWith('standups')) {
        this.selectedPortal = this.standupsPortal;
      } if (this.route.routeConfig.path.endsWith('favorites')) {
        if(this.favorites.length) {
          this.selectedPortal = this.favoritesPortal
        } else {
          this.selectedPortal = this.workforcePortal
        }
      } if (this.route.routeConfig.path.includes('project')) {
        this.selectedPortal = this.projectsPortal;
      } if (this.route.routeConfig.path.endsWith('objective-assignments-report')){
        this.selectedPortal = this.objectivesAssignmentsReportPortal;
      }

      if (!this.selectedPortal){
        if(this.favorites?.length) {
          this.selectedPortal = this.favoritesPortal;
        } else {
          this.selectedPortal = this.workforcePortal;
        }
      }
    }));
  }

  set selectedPortal(portal: TemplatePortal<any>) {
    this._selectedPortal = portal;
    let statePath:string;
    switch (portal.context as ConsultantDashboardContext) {
      case ConsultantDashboardContext.FAVORITES : {
         statePath = `consultant/${this.organization.id}/favorites`;
        break;
      }
      case ConsultantDashboardContext.WORKFORCE : {
        statePath = `consultant/${this.organization.id}/workforce`;
        break;
      }
      case ConsultantDashboardContext.PROJECTS : {
        statePath = `consultant/${this.organization.id}/project`;
        break;
      }
      case ConsultantDashboardContext.STANDUPS : {
        statePath = `consultant/${this.organization.id}/standups`;
        break;
      }
      case ConsultantDashboardContext.OBJECTIVE_ASSIGNMENTS_RPT : {
        statePath = `consultant/${this.organization.id}/objective-assignments-report`;
        break;
      }
    }
    if(!this.location.path().includes(statePath)) {
      this.location.replaceState(statePath);
    }
  }

  get selectedPortal():TemplatePortal<any> {
    return this._selectedPortal;
  }

  employeeChanged(event: string) {
    this.location.go(`consultant/${this.organization.id}/workforce/${event}`);
  }

  hrEditEmployee(employee: Employee) {
    this.router.navigate([`consultant/${this.organization.id}/workforce/${employee.id}/edit`]);
  }

  private async initializeDashboardData(organization: Organization) {
    this.consultantFavorites = await this.employeeService.getConsultantFavorites(organization.id);
    this.standupConfigrations = await this.standupService.getStandupConfigurationBySharedWithEmployee(organization.id);

    this.topEmployees = [];
    this.subordinates = [];
    this.favorites = [];
    await this.retrieveOrgChartForUsersDomain(organization);
    if (this.consultantFavorites) {
      this.favorites = this.consultantFavorites.favorites;
    }
  }

  private async retrieveOrgChartForUsersDomain(organization: Organization): Promise<void> {
    const allOrgUsers = await this.employeeService.getEmployeesForOrganizationByOrganization({includeDisabled:false, organizationID:organization.id, memoize:true});
    const subordinatesList: string[] = [];
    for (const u of allOrgUsers) {
      const subordinates = await this.employeeService.getSubordinatesByEmployeeIDForOrganization({managerID: u.id, memoize: true});
      for (const s of subordinates) {
        const selection = allOrgUsers.filter(l => l.id === s.id)[0];
        if (selection && !selection.disabled) {
          subordinatesList.push(s.id);
        }
      }
    }
    const topUsers = allOrgUsers.filter(i => (!subordinatesList.includes(i.id)) && !i.disabled);
    if (topUsers && topUsers.length > 0) {
      for (const u of topUsers) {
        this.topEmployees.push(u);
      }
    }
  }

  async favoriteToggle(employee: Employee) {
    if (this.favorites.includes(employee.id)) {
      this.favorites = this.favorites.filter(id => id !== employee.id);
    } else {
      this.favorites.push(employee.id);
    }
    if (this.consultantFavorites) {
      this.consultantFavorites.favorites = this.favorites;
      this.consultantFavorites = await this.employeeService.updateConsultantFavorites(this.consultantFavorites);
    } else {
      this.consultantFavorites = await this.employeeService.createConsultantFavorites({
          employeeID: null,
          organizationID: this.organization.id,
          favorites: this.favorites
        }
      );
    }
  }

  searchEmployee(employee: Employee) {
    this.navToEmployeeID = employee.id;
  }
}
