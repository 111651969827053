import { OBJECTIVE_SURVEY_RESPONSE_LABELS } from "@inthraction/labels";

export enum SurveyStatus {
  DELETED = "DELETED",
  MISSED = "MISSED",
  PENDING = ""
}

export enum SurveyTypeCodes {
  DAILY_SUMMARY = "DAILY_SUMMARY",
  EVENT = "EVENT",
  PROJECT = "PROJECT",
  INSPHRATION = "INSPHRATION",
  OBJECTIVE = "OBJECTIVE",
  MILESTONE = "MILESTONE",
  EXTERNAL_EVENT = "EXTERNAL_EVENT",
  EXTERNAL_OBJECTIVE = "EXTERNAL_OBJECTIVE",
  // Organization Experience Surveys
  NEW_HIRE_14_DAY = "NEW_HIRE_14_DAY",
  NEW_POSITION_60_DAY = "NEW_POSITION_60_DAY",
  NEW_MANAGER_30_DAY = "NEW_MANAGER_30_DAY",
  NEW_HIRE_90_DAY = "NEW_HIRE_90_DAY",
  NEW_MANAGER_90_DAY = "NEW_MANAGER_90_DAY",
  STANDUP = "STANDUP"
}

// export enum SurveyTypes {
//   EventSurvey,
//   ObjectiveSurvey,
//   ProjectSurvey,
//   MilestoneSurvey
// }

export enum EventSurveyResponseOptions {
  DID_NOT_ATTEND = 0,
  PROBLEM, // Score: 0
  DISRUPTED, // Score: 1.67
  DISTRACTED, // Score: 3.33
  PARTICIPATED, // Score: 6.67
  CONTRIBUTED, // Score: 8.33
  LEAD, // Score: 10
}

export enum EventSurveyResponseScores {
  PROBLEM = 0,
  DISRUPTED = 1.67,
  DISTRACTED = 3.33,
  PARTICIPATED = 6.67,
  CONTRIBUTED = 8.33,
  LEAD = 10
}

export enum SurveyResponseFilterDirection {
  ne = "ne",
  eq = "eq",
  le = "le",
  lt = "lt",
  ge = "ge",
  gt = "gt"
}

/* Objective Survey Response Scoring
 Exceeds: 10
 High Meets: 7.7
 Meets: 5
 Low Meets: 3.7
 Failing: 1.5
 */

export enum ObjectiveSurveyResponseOptions {
  NEEDS_IMPROVEMENT = 1,
  LOW_MEETS,
  MEETS,
  HIGH_MEETS,
  EXCEEDS
}

export enum ObjectiveSurveyResponseScores {
  NEEDS_IMPROVEMENT = 1.5,
  LOW_MEETS = 3.7,
  MEETS = 5,
  HIGH_MEETS = 7.7,
  EXCEEDS = 10
}


export const OBJECTIVE_SURVEY_RESPONSE_VALUES = {
  NEEDS_IMPROVEMENT: 1,
  LOW_MEETS: 2,
  MEETS: 3,
  HIGH_MEETS: 4,
  EXCEEDS: 5
} as const;

export const OBJECTIVE_SURVEY_RESPONSE_ICONS = {
  NEEDS_IMPROVEMENT: "south",
  LOW_MEETS: "south-east",
  MEETS: "east",
  HIGH_MEETS: "north-east",
  EXCEEDS: "north"
} as const;

export const  OBJECTIVE_SURVEY_RESPONSE_ICON_PATHS= {
  NEEDS_IMPROVEMENT: "assets/font-icons/arrow_south-24px.svg",
  LOW_MEETS: "assets/font-icons/arrow_south_east-24px.svg",
  MEETS: "assets/font-icons/arrow_east-24px.svg",
  HIGH_MEETS: "assets/font-icons/arrow_north_east-24px.svg",
  EXCEEDS: "assets/font-icons/arrow_north-24px.svg"
}

export type ObjectiveSurveyResponseType = keyof typeof OBJECTIVE_SURVEY_RESPONSE_LABELS;

export const OBJECTIVE_SURVEY_RESPONSE_TYPES = Object.keys(OBJECTIVE_SURVEY_RESPONSE_LABELS) as ObjectiveSurveyResponseType[];

export function round(value: number, precision: number): number {
  const multiplier = Math.pow(10, precision || 0);
  return Math.round(value * multiplier) / multiplier;
}
