import { Component, Input, OnInit } from "@angular/core";
import { EmployeeDataTypes } from "@inthraction/codes";
import { Employee, EmployeeData, EmployeeImpl } from "@inthraction/data-models";
import { EmployeeService } from "@inthraction/services";

@Component({
  selector: 'inthraction-career-aspirations',
  templateUrl: './career-aspirations.component.html',
  styleUrls: ['./career-aspirations.component.scss']
})
export class CareerAspirationsComponent implements OnInit {

  @Input() managerAccess: boolean;
  @Input() employeeID: string;

  constructor(
    private employeeService: EmployeeService
  ) {
  }

  readonly employeeDataTypes = EmployeeDataTypes;
  employee: Employee;
  employeeDataMap: Map<EmployeeDataTypes, EmployeeData>;

  async ngOnInit() {
    this.employee = new EmployeeImpl(await this.employeeService.getEmployeeByIDMemoize(this.employeeID));
    await this.findEmployeeData();
  }

  private async findEmployeeData() {
    if (this.employeeDataMap) {
      this.employeeDataMap.clear();
    }
    const map: Map<EmployeeDataTypes, EmployeeData> = new Map<EmployeeDataTypes, EmployeeData>();
    const aspirationsDataTypes = [EmployeeDataTypes.CAREER_GOALS, EmployeeDataTypes.CAREER_PATH, EmployeeDataTypes.IDEAL_ROLE, EmployeeDataTypes.KNOWLEDGE, EmployeeDataTypes.MOVE_ROLE, EmployeeDataTypes.SKILLS, EmployeeDataTypes.STRENGTHS];
    const employeeDataList = await this.employeeService.getEmployeeData(this.employee.orgId, this.employee.id, aspirationsDataTypes, true);
    if (employeeDataList) {
      for (const data of employeeDataList) {
        map.set(data.dataCode as EmployeeDataTypes, data);
      }
    }

    for (const dataType of aspirationsDataTypes.filter((i) => !Array.from<EmployeeDataTypes>(map.keys()).includes(i))) {
      map.set(dataType, {
        dataCode: dataType,
        employeeID: this.employee.id,
        organizationID: this.employee.orgId,
        stringValue: ""
      });
    }

    this.employeeDataMap = map;
  }

  async submitCareerGoals() {
    if (this.employeeDataMap) {
      for (const value of this.employeeDataMap.values()) {
        if (value.dataCode !== EmployeeDataTypes.BIOGRAPHY) {
          this.employeeDataMap.set(value.dataCode as EmployeeDataTypes, await this.employeeService.putEmployeeData(value));
        }
      }
    }
  }

  async cancelEditCareerGoals() {
    await this.findEmployeeData();
  }

}
