import { Injectable } from "@angular/core";
import { AuthService } from "./auth.service";
import {
  APIService,
  ModelStandupSurveyConfigurationFilterInput,
  ModelStandupSurveyFilterInput,
  UpdateStandupSurveyInput,
  VersionedQuestionInput
} from "./API.service";
import { BaseService } from "./base.service";
import { Employee, StandupSurvey, StandupSurveyConfiguration } from "@inthraction/data-models";
import { Memoize } from "@inthraction/utils";
import { SurveyStatus } from "@inthraction/codes";

@Injectable({
  providedIn: "root"
})
export class StandupService extends BaseService {

  constructor(
    protected api: APIService,
    protected authService: AuthService
  ) {
    super(api, authService);
  }

  async getStandupConfigurationByManager(manager: Employee): Promise<StandupSurveyConfiguration> {
    const filter: ModelStandupSurveyConfigurationFilterInput = { employeeID: { eq: manager.id } };
    const items = (await this.api.ListStandupSurveyConfigurations(filter)).items;
    return items.length ? items[0] as StandupSurveyConfiguration : null;
  }

  async putStandupConfiguration(configuration: StandupSurveyConfiguration): Promise<StandupSurveyConfiguration> {

    const questions: VersionedQuestionInput[] = [];
    for (const q of configuration.questions) {
      const vq: VersionedQuestionInput = { id: null, questionTextArray: undefined };
      if (q.id) {
        vq.id = q.id;
      }
      vq.enabled = q.enabled;
      vq.questionTextArray = q.questionTextArray;
      questions.push(vq);
    }

    if (configuration.id) {
      return (await this.api.UpdateStandupSurveyConfiguration({
        status: configuration.status,
        id: configuration.id,
        questions: questions,
        frequency: configuration.frequency,
        shared: configuration.shared,
        excluded: configuration.excluded,
        isoWeekday: configuration.isoWeekday
      })) as StandupSurveyConfiguration;
    } else {
      return (await this.api.CreateStandupSurveyConfiguration({
        status: configuration.status,
        employeeID: configuration.employeeID,
        organizationID: configuration.organizationID,
        questions: questions,
        frequency: configuration.frequency,
        shared: configuration.shared,
        excluded: configuration.excluded,
        isoWeekday: configuration.isoWeekday
      })) as StandupSurveyConfiguration;
    }

  }

  async getAllStandupSurveysForEmployee(employeeID: string): Promise<StandupSurvey[]> {
    const filter: ModelStandupSurveyFilterInput = {
      and: [
        {
          respondentID: {
            eq: employeeID
          }
        }
      ]
    };
    const surveys = await super.getAll<StandupSurvey>(this.api.ListStandupSurveys, filter);
    return surveys.sort((a, b) => a.surveyDate.localeCompare(b.surveyDate) * -1);
  }

  @Memoize({ maxAge: 1200, preFetch: true })
  async getAllStandupSurveysForEmployeeMemoize(employeeID: string): Promise<StandupSurvey[]> {
    return this.getAllStandupSurveysForEmployee(employeeID);
  }

  async getLatestCompletedStandupSurveyByEmployee(employeeID: string): Promise<StandupSurvey> {
    let surveys = await this.getAllStandupSurveysForEmployeeMemoize(employeeID);
    surveys = surveys.filter(s => s.responseReceived);
    return surveys.length ? surveys[0] : null;
  }

  async getAllStandupSurveysForConfiguration(id: string, lastSurveyedDate?: string): Promise<StandupSurvey[]> {
    const filter: ModelStandupSurveyFilterInput = {
      and: [
        {
          standupSurveyConfigurationID: {
            eq: id
          }
        }
      ]
    };
    if (lastSurveyedDate) {
      filter.and.push({ surveyDate: { eq: lastSurveyedDate } });
    }
    const surveys = await super.getAll<StandupSurvey>(this.api.ListStandupSurveys, filter);
    return surveys.sort((a, b) => a.surveyDate.localeCompare(b.surveyDate) * -1);
  }

  async getStandupConfigurationByID(standupSurveyConfigurationID: string): Promise<StandupSurveyConfiguration> {
    return (await this.api.GetStandupSurveyConfiguration(standupSurveyConfigurationID)) as StandupSurveyConfiguration;
  }

  @Memoize({ maxAge: 1200, preFetch: true })
  async getStandupConfigurationByIDMemoize(standupSurveyConfigurationID: string): Promise<StandupSurveyConfiguration> {
    return this.getStandupConfigurationByID(standupSurveyConfigurationID);
  }

  async getStandupConfigurationBySharedWithEmployee(organizationID: string): Promise<StandupSurveyConfiguration[]> {
    const employee = await super.getCurrentUser();
   const filter: ModelStandupSurveyConfigurationFilterInput = {
     and: [
       {organizationID: {eq: organizationID}},
       {shared: {contains: employee.id}}
     ]
   };
    return await super.getAll<StandupSurveyConfiguration>(this.api.ListStandupSurveyConfigurations, filter);
  }

  async updateSurvey(param: UpdateStandupSurveyInput): Promise<StandupSurvey> {
    return (await this.api.UpdateStandupSurvey(param)) as unknown as StandupSurvey;
  }

  async getPendingStandupSurveyByEmployee(id: string): Promise<StandupSurvey> {
    const filter: ModelStandupSurveyFilterInput = {
      and: [
        { respondentID: { eq: id } },
        { status: { ne: SurveyStatus.DELETED } },
        { status: { ne: SurveyStatus.MISSED } },
        { responseReceived: { eq: false } }
      ]
    };
    const surveys = await super.getAll<StandupSurvey>(this.api.ListStandupSurveys, filter);
    surveys.sort((a, b) => a.surveyDate.localeCompare(b.surveyDate) * -1);
    return surveys.length ? new StandupSurvey(surveys[0]) : null;
  }

  async getLatestStandupSurveysByConfiguration(id: string, lastSurveyedDate: string): Promise<StandupSurvey[]> {
    return this.getAllStandupSurveysForConfiguration(id, lastSurveyedDate);
  }

}
