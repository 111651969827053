<ng-template [cdkPortalOutlet]="selectedPortal"></ng-template>

<ng-template #workforceContent>
  <inthraction-page-header pageTitle="Workforce">
    <div class="search-wrapper no-subscript-wrapper">
      <inthraction-employee-search [options]="{clearAfterSelect: true}" (employeeSelected)="searchEmployee($event)"></inthraction-employee-search>
    </div>
  </inthraction-page-header>
<inthraction-team-view-component
  *ngIf="topEmployees && topEmployees.length > 0"
  [employees]="topEmployees"
  (selectedEmployeeID)="employeeChanged($event)"
  [navToEmployeeID]="navToEmployeeID"
  [employeeCardOptions]="{showCriticalPosition:true, showReadiness:true, showEdit:true, showOpenIntHRactionCount:true, showFavoriteToggle:true, favorites:favorites, showOrgNav: true}"
  (employeeEditClickEvent)="hrEditEmployee($event)"
  (profileImageClickEvent)="hrEditEmployee($event)"
  (favoriteClickEvent)="favoriteToggle($event)">
</inthraction-team-view-component>
</ng-template>
<ng-template #favoritesContent>
  <inthraction-page-header pageTitle="Favorites"></inthraction-page-header>
  <inthraction-team-view-component
    *ngIf="favoriteEmployees && favoriteEmployees.length > 0"
    [employees]="favoriteEmployees"
    (selectedEmployeeID)="favoriteEmployeeChanged($event)"
    [employeeCardOptions]="{showCriticalPosition:true, showReadiness:true, showEdit:true, showOpenIntHRactionCount:true, showFavoriteToggle:true, favorites:favorites, showOrgNav:false}"
    (employeeEditClickEvent)="hrEditEmployee($event)"
    (profileImageClickEvent)="hrEditEmployee($event)"
    (favoriteClickEvent)="favoriteToggle($event)">
  </inthraction-team-view-component>
</ng-template>
