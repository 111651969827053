<form>
  <mat-form-field class="full-width" appearance="outline">
    <mat-label>Search for a user</mat-label>
    <input type="text" matInput
           [formControl]="control"
           [matAutocomplete]="auto">
    <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" class="employee-search-auto">
      <mat-option *ngFor="let employee of filteredEmployees | async" [value]="employee" class="employee-search-option">
        <inthraction-profile-image [employeeImage]="getEmployeeImage(employee.id)" class="emp-image-search-option"></inthraction-profile-image>
        <span class="emp-name-search-option">{{employee.firstName}} {{employee.lastName}}</span>
        <small class="emp-email-search-option">{{employee.email}}</small>
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
</form>
