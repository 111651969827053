import { Component, Input, OnInit } from "@angular/core";
import { ObjectiveAssignmentProgressCheck } from "@inthraction/data-models";
import { ProgressStatusTypes } from "@inthraction/codes";
import { ProgressStatusLabels } from "@inthraction/labels";

@Component({
  selector: "inthraction-progress-check",
  templateUrl: "./progress-check.component.html",
  styleUrls: ["./progress-check.component.scss"]
})
export class ProgressCheckComponent implements OnInit {

  @Input()
  progressCheckData: ObjectiveAssignmentProgressCheck;

  progressStatusValues = ProgressStatusTypes;
  progressStatusLabels = ProgressStatusLabels;

  constructor() {
  }

  ngOnInit(): void {
  }

}
