export const ADD_EMPLOYEE_OBJECTIVES_HELP = "Objectives to be assigned to individuals are created and authorized by your HR Administrators based on pre-defined Objectives in the intHRaction system and additional Objectives that are created by HR.  If, as a Manager, you determine that you need an additional Objective created in the system, contact your HR Department.";

export const OBJECTIVE_TYPE_HELP = "There are 5 Objective Types available to be assigned.  \n" +
  "\n" +
  "* Pre-Defined (Peer Rated)\n" +
  "\n" +
  "   * Use a pre-defined Objective that will be answered by all the users that the person interacts with.\n" +
  "\n" +
  "* Pre-Defined (Self Rated)\n" +
  "\n" +
  "   * Use a pre-defined Objective that will be periodically answered exclusively by the person.\n" +
  "\n" +
  "* Pre-Defined (Manager Rated)\n" +
  "\n" +
  "   * Use a pre-defined Objective that will be periodically answered exclusively by you, the Manager.\n" +
  "\n" +
  "* Pre-Defined (Choice Rated)\n" +
  "\n" +
  "   * Use a pre-defined Objective that will be answered by a defined list of users selected by you, the Manager.\n" +
  "\n" +
  "* Manager Defined / Quantifiable\n" +
  "\n" +
  "   * This is where a Manager can create Objectives that are answered by you, the Manager, and represents Goals / Objectives that have a quantifiable value.  Example: you want the person to make X number of calls per week, bi-weekly, monthly etc.";

export const OBJECTIVE_DOMAIN_HELP = "The intHRaction Objective catalog contains Goals and Objectives grouped by categories.\n" +
  "By hovering over the Objective domain name, you can see what Objectives are included in the domain.";

export const OBJECTIVE_HELP = "Each Objective domain contains numerous Objectives / Goals that relate the the Objective domain.  By hovering over the Objective name, you can see a description of each Objective.";

export const FOCUS_HELP = "There are 3 focus types. You can think of them as levels of achievement or competency.\n" +
  "\n" +
  "* Capacity (Having the Ability)\n" +
  "\n" +
  "   * The ability to understand and demonstrate learning in the Objective.\n" +
  "\n" +
  "* Proficiency (Level of Skill)\n" +
  "\n" +
  "   * An advanced level of capacity.  Increased expertise in the Objective.  \n" +
  "\n" +
  "* Delivery (Execution of Skill)\n" +
  "\n" +
  "   * Demonstrate advanced knowledge.  Gaining expertise in the Objective that can be shared with others.";

export const TIME_FRAME_HELP = "* Current\n" +
  "\n" +
  "   * Applies to Objectives specific to the person’s current role and are included in the Organization’s performance review processes.\n" +
  "\n" +
  "* Future\n" +
  "\n" +
  "   * Applies to future-focused developmental Objectives.  These are part of the Succession Planning / Talent Development processes and ratings are not included in the person’s Total Performance Score.";

export const MANAGER_OBJECTIVE_HELP = "Add details that are specific to the person.  As a Manager, what additional unique observables do you want others to see in your direct report.";

export const DUE_DATE_HELP = "The date the Objective is no longer reported on.  For an \"annual review\" set the due date to the end of the current year.";

export const MANAGER_DEFINED_QUANTIFIABLE_HELP = "For direct reports that have defined measurable or \"quantifiable\" goals, use of this Objective Type generates a survey to be reported on by you, the Manager.";

export const OBJECTIVE_TITLE_HELP = "The name of the Objective you are creating.";

export const START_DATE_HELP = "When should this Objective begin.";

export const END_DATE_HELP = "When should this Objective end and no longer receive any survey feedback.";

export const FREQUENCY_HELP = "Sets the cadence for how often you will be notified to update the status of the objective.";

export const QUANTITY_HELP = "What is the target to be achieved based on the status frequency.  Example: Sell 25 widgets per week, or make 100 calls per month.";

export const OBJECTIVE_RESPONSE_DISTRIBUTION_HELP = "View the distribution of the person’s Developmental Objectives.";
