import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { ObjectiveAssignment, ObjectiveAssignmentProgressCheck } from "@inthraction/data-models";
import { ProgressStatusTypes } from "@inthraction/codes";
import { OBJECTIVE_PROGRESS_CHECK, ProgressStatusLabels } from "@inthraction/labels";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MatOptionSelectionChange } from "@angular/material/core";

@Component({
  selector: "inthraction-progress-check-dialog",
  templateUrl: "./progress-check-dialog.component.html",
  styleUrls: ["./progress-check-dialog.component.scss"]
})
export class ProgressCheckDialogComponent implements OnInit {

  readonly OBJECTIVE_PROGRESS_CHECK = OBJECTIVE_PROGRESS_CHECK;

  objectives: ObjectiveAssignment[];
  objectiveProgressCheckMap: Map<string, ObjectiveAssignmentProgressCheck>;

  progressStatusValues = ProgressStatusTypes;
  progressStatusLabels = ProgressStatusLabels;

  progressCheckForm = new FormGroup({
    status: new FormControl("", [Validators.required]),
    objectiveID: new FormControl("", [Validators.required]),
    progressCheckText: new FormControl("", [])
  });

  constructor(
    public dialogRef: MatDialogRef<ProgressCheckDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.objectives = data.objectives;
    this.objectiveProgressCheckMap = data.progressChecks;
  }

  ngOnInit() {
  }

  onCancelClick(): void {
    this.dialogRef.close();
  }

  onSaveClick(value: any): void {
    const pc: ObjectiveAssignmentProgressCheck = {
      statusCode: value.status,
      objectiveAssignmentId: value.objectiveID,
      note: value.progressCheckText
    };
    this.dialogRef.close(pc);
  }

  selectObjective($event: MatOptionSelectionChange) {
    if (this.objectiveProgressCheckMap && this.objectiveProgressCheckMap.has($event?.source?.value)) {
      const pc: ObjectiveAssignmentProgressCheck = this.objectiveProgressCheckMap.get($event.source.value);
      this.progressCheckForm.get("status").setValue(pc.statusCode);
      this.progressCheckForm.get("objectiveID").setValue(pc.objectiveAssignmentId);
      this.progressCheckForm.get("progressCheckText").setValue(pc.note);
    }
  }
}
