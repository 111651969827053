import {
  EmployeeObjectiveScoreDetails,
  EmployeeScoreDataMapper,
  EmployeeTotalEventScoreDetails,
  EmployeeTotalObjectiveScoreDetails
} from "@inthraction/data-mappers";

export interface EmployeeScore extends EmployeeScoreDataMapper {
}

export class TotalEmployeeObjectiveScore implements EmployeeScore {
  __typename: string;
  details: string;
  employeeID: string;
  id: string;
  organizationID: string;
  score: number;
  scoreEnd: string;
  scoreID: string;
  scoreStart: string;
  scoreType: string;
  specifier: string;

  get employeeTotalObjectiveScoreDetails(): EmployeeTotalObjectiveScoreDetails {
    return this.details ? JSON.parse(this.details) : undefined;
  }

  constructor(employeeScore: EmployeeScore) {
    this.__typename = employeeScore.__typename;
    this.details = employeeScore.details;
    this.employeeID = employeeScore.employeeID;
    this.id = employeeScore.id;
    this.organizationID = employeeScore.organizationID;
    this.score = employeeScore.score;
    this.scoreEnd = employeeScore.scoreEnd;
    this.scoreID = employeeScore.scoreID;
    this.scoreStart = employeeScore.scoreStart;
    this.scoreType = employeeScore.scoreType;
    this.specifier = employeeScore.specifier;
  }

}

export class EmployeeObjectiveScore implements EmployeeScore {
  __typename: string;
  details: string;
  employeeID: string;
  id: string;
  organizationID: string;
  score: number;
  scoreEnd: string;
  scoreID: string;
  scoreStart: string;
  scoreType: string;
  specifier: string;

  get employeeObjectiveScoreDetails(): EmployeeObjectiveScoreDetails {
    return this.details ? JSON.parse(this.details) : undefined;
  }

  constructor(employeeScore: EmployeeScore) {
    this.__typename = employeeScore.__typename;
    this.details = employeeScore.details;
    this.employeeID = employeeScore.employeeID;
    this.id = employeeScore.id;
    this.organizationID = employeeScore.organizationID;
    this.score = employeeScore.score;
    this.scoreEnd = employeeScore.scoreEnd;
    this.scoreID = employeeScore.scoreID;
    this.scoreStart = employeeScore.scoreStart;
    this.scoreType = employeeScore.scoreType;
    this.specifier = employeeScore.specifier;
  }

}

export class TotalEmployeeEventScore implements EmployeeScore {
  __typename: string;
  details: string;
  employeeID: string;
  id: string;
  organizationID: string;
  score: number;
  scoreEnd: string;
  scoreID: string;
  scoreStart: string;
  scoreType: string;
  specifier: string;

  get employeeTotalEventScoreDetails(): EmployeeTotalEventScoreDetails {
    return this.details ? JSON.parse(this.details) : undefined;
  }

  constructor(employeeScore: EmployeeScore) {
    this.__typename = employeeScore.__typename;
    this.details = employeeScore.details;
    this.employeeID = employeeScore.employeeID;
    this.id = employeeScore.id;
    this.organizationID = employeeScore.organizationID;
    this.score = employeeScore.score;
    this.scoreEnd = employeeScore.scoreEnd;
    this.scoreID = employeeScore.scoreID;
    this.scoreStart = employeeScore.scoreStart;
    this.scoreType = employeeScore.scoreType;
    this.specifier = employeeScore.specifier;
  }

}
