<inthraction-oc-employee *ngIf="topEmployee" (addSubordinateEvent)="updateData($event)" (click)="clickEmitter()"
                         (collapseEvent)="collapseSubordinates($event)" (editEvent)="editData($event)"
                         [collapseSubordinatesFunction]="collapseSubordinates"
                         [employee]="topEmployee" [hasManager]="hasManager" [showActions]="showActions"></inthraction-oc-employee>
<div *ngIf="!isCollapsed && levels != 0" class="oc-reports">
  <ng-container *ngFor="let employee of subordinateEmployees; first as isFirst; last as isLast">
    <div class="oc-org-container">
      <div class="oc-h-bar-container">
        <div [style.border-color]="isFirst?'transparent':''" class="oc-h-bar oc-border"></div>
        <div class="oc-border"></div>
        <div [style.border-color]="isLast?'transparent':''" class="oc-h-bar oc-border"></div>
      </div>
      <inthraction-ng-org-chart (chartChangeEvent)="chartChanged($event)" (clickEvent)="clickEmitterPassThru($event)"
                                [hasManager]="true" [levels]="levels-1" [showActions]="showActions"
                                [topEmployee]="employee"></inthraction-ng-org-chart>
    </div>
  </ng-container>
</div>
