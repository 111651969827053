<inthraction-page-header [pageTitle]="pageTitle" downloadBtn="true" (downloadBtnClickEvent)="downloadCSV(startDate, endDate)" [downloadBtnDisabled]="!organizationObjectiveDomainsMap || disableDownload">
  <span class="toolbar-spacer"></span>
  <div class="date-range-wrapper">
    <mat-slide-toggle [(ngModel)]="showAll">Show All</mat-slide-toggle>
    <mat-form-field class="half-width no-subscript-wrapper">
      <mat-label>From</mat-label>
      <input [matDatepicker]="startDateDP" matInput [(ngModel)]="startDate">
      <mat-datepicker-toggle [for]="startDateDP" matSuffix></mat-datepicker-toggle>
      <mat-datepicker #startDateDP></mat-datepicker>
    </mat-form-field>
    <mat-form-field class="half-width no-subscript-wrapper">
      <mat-label>Through</mat-label>
      <input [matDatepicker]="endDateDP" matInput [(ngModel)]="endDate">
      <mat-datepicker-toggle [for]="endDateDP" matSuffix></mat-datepicker-toggle>
      <mat-datepicker #endDateDP></mat-datepicker>
    </mat-form-field>
  </div>
</inthraction-page-header>
<div *ngIf="organizationObjectiveDomainsMap" class="objective-distribution-container">
  <div class="page-contents">
    <mat-tab-group [selectedIndex]="0" (selectedTabChange)="tabChanged($event.index)" mat-stretch-tabs="false">
      <mat-tab *ngFor="let domain of objectiveDomains">
        <ng-template mat-tab-label><span title="{{domain.display}}: {{getObjectiveTitles(domain.id)}}">{{domain.display}}</span></ng-template>
        <div class="objectivesContainer" *ngIf="selectedObjectiveDomain.id === domain.id">
          <div #ref *ngFor="let orgObjective of organizationObjectiveDomainsMap.get(domain.id) | keyvalue">
            <inthraction-objective-distribution-detail *ngIf="showAll" [orgObjective]="orgObjective.value" [mode]="mode != undefined ? mode : ObjectiveDistributionDetailsModes.HR" [startDate]="startDate" [endDate]="endDate"
                                                       (assignmentTotalEvent)="assignmentTotalChange($event, orgObjective.value, ref)"></inthraction-objective-distribution-detail>
            <inthraction-objective-distribution-detail *ngIf="!showAll" [orgObjective]="orgObjective.value" [mode]="mode != undefined ? mode : ObjectiveDistributionDetailsModes.HR" [startDate]="startDate" [endDate]="endDate"
                                                       (assignmentTotalEvent)="assignmentTotalChange($event, orgObjective.value, ref)"></inthraction-objective-distribution-detail>
          </div>
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>

<inthraction-loading className="objective-distribution-container" [initDone]="organizationObjectiveDomainsMap ? true: false"></inthraction-loading>
