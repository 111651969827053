import { Component, OnInit } from "@angular/core";
import { environment } from "../../../../environments/environment";

declare global {
  interface Window {
    Headway: any; // https://docs.headwayapp.co/widget
  }
}

const delay = ms => new Promise(res => setTimeout(res, ms));

@Component({
  selector: "inthraction-headway",
  templateUrl: "./headway.component.html",
  styleUrls: ["./headway.component.scss"]
})
export class HeadwayComponent implements OnInit {

  headwayConfig: any;

  constructor() {
    this.headwayConfig = {
      selector: ".headway-holder", // CSS selector where to inject the badge
      account: environment.heaedwayAccountID
    };
    this.loadHeadwayWidget();
  }

  async ngOnInit() {
    await this.initHeadway();
  }

  public loadHeadwayWidget(): void {
    const s = document.createElement("script");
    s.src = "https://cdn.headwayapp.co/widget.js";
    s.async = true;
    s.type = "text/javascript";
    const x = document.getElementsByTagName("script")[0];
    x.parentNode.insertBefore(s, x);
  }

  public async initHeadway(): Promise<void> {
    const headway = await this.getHeadway();
    if (headway) {
      headway.init(this.headwayConfig);
    }
  }

  private async getHeadway(counter: number = 10): Promise<any> {
    const script = window.Headway;
    if (script || !counter) {
      return script;
    } else if (!script && counter) {
      await delay(500);
      return await this.getHeadway(--counter);
    }
  }

}
