import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ProjectMilestoneAssignment } from "@inthraction/data-models";
import { CADENCE_TYPE_LABELS } from "@inthraction/labels";
import { MatDialog } from "@angular/material/dialog";
import { MilestoneScoreChartSelection } from "../milestone-score-chart/milestone-score-chart.component";
import { MilestoneSurveyResultsComponent } from "../dialogs/milestone-survey-results/milestone-survey-results.component";
import { AddCommentDialogComponent } from "../dialogs/add-comment-dialog/add-comment-dialog.component";

@Component({
  selector: "inthraction-project-milestone",
  templateUrl: "./project-milestone.component.html",
  styleUrls: ["./project-milestone.component.scss"]
})
export class ProjectMilestoneComponent implements OnInit {

  @Input() milestone: ProjectMilestoneAssignment;
  @Input() managerAccess = false;
  @Output() openEdit: EventEmitter<any> = new EventEmitter();

  readonly cadenceTypeLabels = CADENCE_TYPE_LABELS;

  constructor(
    public dialog: MatDialog
  ) {
  }

  async ngOnInit() {
  }

  openEditClick(editId: string): void {
    this.openEdit.emit(editId);
  }

  async openSurveyResponse(event: MilestoneScoreChartSelection) {
    this.dialog.open(MilestoneSurveyResultsComponent, {
      width: "600px",
      data: { selection: event, isProjectManager: this.managerAccess, organizationID: this.milestone.organizationID }
    });
  }

  openAddComment(milestone: ProjectMilestoneAssignment) {
    const addCommentDialog = this.dialog.open(AddCommentDialogComponent, {
      width: "600px",
      data: milestone
    });
    addCommentDialog.afterClosed().subscribe(
      async result => {
        if (result) {
          if (this.milestone.comments) {
            this.milestone.comments.unshift(result);
            // New array to get on change event to fire.
            const newArray = [];
            newArray.push(...this.milestone.comments);
            this.milestone.comments = newArray;
          } else {
            this.milestone.comments = [result];
          }
        }
      }
    );
  }
}
