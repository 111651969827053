import { ReviewTypeLabels } from "@inthraction/labels";

export const ReviewTypeKeys = {
  COMMUNICATION: "COMMUNICATION" as ReviewType,
  ONE: "ONE" as ReviewType,
  QUARTER: "QUARTER" as ReviewType,
  ANNUAL: "ANNUAL" as ReviewType
};

export type ReviewType = keyof typeof ReviewTypeLabels;

export const ReviewTypes = Object.keys(ReviewTypeLabels) as ReviewType[];
