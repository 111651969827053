import { AfterViewInit, Component, NgZone, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Subscription } from "rxjs";
import { Employee, EmployeeImpl, Organization } from "@inthraction/data-models";
import { EmployeeService, OrganizationService } from "@inthraction/services";
import { MatDialog } from "@angular/material/dialog";
import { AddEmployeeComponent } from "../../../components/org-chart-module/add-employee/add-employee.component";
import { EditEmployeeComponent } from "../../../components/org-chart-module/edit-employee/edit-employee.component";
import { MatTableDataSource } from "@angular/material/table";
import { MatSort } from "@angular/material/sort";
import { EmployeeDataTypes } from "@inthraction/codes";
import moment from "moment/moment";
import { saveAs } from "file-saver";

@Component({
  selector: "inthraction-manage-organization-employees",
  templateUrl: "./manage-organization-employees.component.html",
  styleUrls: ["./manage-organization-employees.component.scss"]
})
export class ManageOrganizationEmployeesComponent implements OnInit, OnDestroy, AfterViewInit {

  static organizationIDParameterName = "organizationID";
  displayedColumns: string[] = ["rownum", "name", "email", "calendarAuthorized", "disabled", "admin", "edit", "lastActivity", "inviteSentDate", "inviteAccepted"];
  organization: Organization;
  tableData: MatTableDataSource<TableData> = new MatTableDataSource<TableData>([]);
  private routeParamMapSubscription: Subscription;
  private subscriptions: Subscription[] = [];
  filterExample: string;

  @ViewChild(MatSort) sort: MatSort;
  initDone = false;

  constructor(
    private router: Router,
    private ngZone: NgZone,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    private organizationService: OrganizationService,
    private employeeService: EmployeeService
  ) {
  }

  ngAfterViewInit() {
    if (this.tableData) {
      this.tableData.sort = this.sort;
    }
  }

  ngOnDestroy(): void {
    if (this.routeParamMapSubscription) {
      this.routeParamMapSubscription.unsubscribe();
    }
    if (this.subscriptions.length) {
      for (const sub of this.subscriptions) {
        sub.unsubscribe();
      }
    }
  }

  ngOnInit(): void {

    this.routeParamMapSubscription = this.route.paramMap.subscribe(async queryParams => {
      if (queryParams.has(ManageOrganizationEmployeesComponent.organizationIDParameterName)) {
        this.initDone = false;
        this.organization = await this.organizationService.getOrganizationByIDMemoize(queryParams.get(ManageOrganizationEmployeesComponent.organizationIDParameterName));
        await this.loadEmployeeList();
        this.initDone = true;
      } else {
        await this.ngZone.run(async () => {
          await this.router.navigate([`dashboard`]);
        });
      }
    });

  }

  applyFilter(event: KeyboardEvent) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.tableData.filter = filterValue.trim().toLowerCase();
    if (this.tableData.paginator) {
      this.tableData.paginator.firstPage();
    }
  }

  download() {
    const csvData = [];
    const csvHeaders = [
      "Name",
      "Email",
      "Calendar Authorized",
      "Status",
      "Admin",
      "Last Activity Date",
      "Invite Sent Date",
      "Invite Accepted"
    ];

    csvData.push(csvHeaders);
    for (const record of this.tableData.data) {
        const rowData = [
          `"${record.name}"` || "",
          `"${record.email}"` || "",
          record.calendarAuthorized ? "true":"false",
          record.disabled ? "DISABLED":"ENABLED",
          record.admin ? "true":"false",
          record.lastActivity ? moment(record.lastActivity, "YYYY-MM-DD").format("l") : "",
          record.inviteSentDate ? moment(record.inviteSentDate, "YYYY-MM-DD").format("l") : "",
          record.inviteAccepted ? "true":"false"
        ];
        csvData.push(rowData);
    }
    let csvArray = csvData.join("\r\n");
    const blob = new Blob([csvArray], { type: "text/csv" });
    saveAs(blob, `Employee_List-${moment().format("YYYYMMDDHHmmss")}.csv`.replace(" ", "_"));
  }

  add() {
    const dialog = this.dialog.open(AddEmployeeComponent, {
      width: "680px",
      data: { organization: this.organization }
    });

    this.subscriptions.push(
      dialog.afterClosed().subscribe(async result => {
        if (result) {
          await this.loadEmployeeList();
        }
      }));
  }

  edit(employee: Employee) {
    const dialog = this.dialog.open(EditEmployeeComponent, {
      width: "620px",
      data: {
        organization: this.organization,
        employee
      }
    });

    this.subscriptions.push(
      dialog.afterClosed().subscribe(async result => {
        if (result) {
          await this.loadEmployeeList();
        }
      }));
  }

  private async loadEmployeeList() {
    const employees = (await this.employeeService.getEmployeesForOrganizationByOrganization({organizationID:this.organization.id, includeDisabled:true, memoize:true})).map(emp => new EmployeeImpl(emp)).sort((a, b) => {
      return `${a.disabled} ${a.lastName}, ${a.firstName}`.localeCompare(`${b.disabled} ${b.lastName}, ${b.firstName}`);
    });

    const data: TableData[] = [];
    for (let employee of employees) {
      const lastActivity = await this.employeeService.getEmployeeDataMemoize(employee.orgId, employee.id, EmployeeDataTypes.LAST_ACTIVITY_DATE);
      data.push({
        employee: employee,
        lastActivity: lastActivity ? lastActivity.stringValue : undefined,
        name: `${employee.lastName}, ${employee.firstName}`,
        email: employee.email,
        disabled: employee.disabled,
        admin: employee.isAdmin(),
        calendarAuthorized: employee.validEmailToken,
        inviteSentDate: employee.inviteSent,
        inviteAccepted: (employee.authId ? true : false)
      } as TableData);
    }

    this.tableData.data = data;
  }
}

interface TableData {
  employee: EmployeeImpl;
  calendarAuthorized: boolean;
  inviteSentDate: string;
  inviteAccepted: boolean;
  name: string;
  email: string;
  disabled: boolean;
  admin: boolean;
  lastActivity: string;
}
