import { Component, OnInit } from "@angular/core";
import { Objective, ObjectiveDetails, ObjectiveDomain } from "@inthraction/data-models";
import { ObjectiveService } from "@inthraction/services";
import { ConfirmationDialogComponent } from "../../../components/shared/dialogs/confirmation-dialog/confirmation-dialog.component";
import { MatDialog } from "@angular/material/dialog";
import { EditObjectiveDialogComponent } from "../../../components/shared/add-edit-objective/edit-objective-dialog.component";
import { FilterOrganizationDialogComponent } from "../../../components/shared/filter-organization-dialog/filter-organization-dialog.component";
import { MatTabChangeEvent } from "@angular/material/tabs";
import { UserDefinedObjectiveDomainTypes } from "@inthraction/codes";
import {
  EditObjectiveDomainDialogComponent
} from "../../../components/shared/edit-objective-domain-dialog/edit-objective-domain-dialog.component";
import { AddQrCodeDialogComponent } from '../../../components/shared/add-qr-code-dialog/add-qr-code-dialog.component';

@Component({
  selector: "inthraction-system-objective-editor",
  templateUrl: "./system-objective-editor.component.html",
  styleUrls: ["./system-objective-editor.component.scss"]
})
export class SystemObjectiveEditorComponent implements OnInit {

  objectiveDomainsMap: Map<string, ObjectiveDetails[]> = new Map<string, ObjectiveDetails[]>();
  objectiveDomains: ObjectiveDomain[] = [];
  objectivesMap: Map<string, Objective> = new Map<string, Objective>();

  selectedObjectiveDomain: ObjectiveDomain;

  initialized = false;

  constructor(
    public dialog: MatDialog,
    private objectiveService: ObjectiveService
  ) {
  }

  async ngOnInit(): Promise<void> {
    this.objectiveDomains = (await this.objectiveService.getObjectiveDomainsMemoize()).filter(d => !Object.keys(UserDefinedObjectiveDomainTypes).includes(d.key)).sort((a,b) => a.display.localeCompare(b.display));
    const allObjectives = await this.objectiveService.getObjectivesMemoize();

    const objectiveIdSet = await this.objectiveService.getActiveObjectives();
    for (const objective of allObjectives) {
      this.objectivesMap.set(objective.id, objective);
      const editObjective = new ObjectiveDetails(objective.id, objectiveIdSet.has(objective.id), objective.display);
      if (!this.objectiveDomainsMap.has(objective.objectiveDomainId)) {
        this.objectiveDomainsMap.set(objective.objectiveDomainId, [editObjective]);
      } else {
        const list = this.objectiveDomainsMap.get(objective.objectiveDomainId);
        list.push(editObjective);
        this.objectiveDomainsMap.set(objective.objectiveDomainId, list.sort((a,b) => a.display.localeCompare(b.display)));
      }
    }
    this.selectedObjectiveDomain = this.objectiveDomains[0];

    this.initialized = true;
  }

  tabChanged(event: MatTabChangeEvent) {
    this.selectedObjectiveDomain = this.objectiveDomains[event.index];
  }

  deleteObjective(objectiveID: string): void {
    const deleteDialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: "350px",
      data: "Are you sure you want to Delete this objective?"
    });
    deleteDialogRef.afterClosed().subscribe(async result => {
      if (result) {
        await this.objectiveService.deleteObjective(objectiveID);
        this.objectivesMap.delete(objectiveID);
      }
    });
  }

  editObjectiveDomain(domain: ObjectiveDomain): void {
    const editModal = this.dialog.open(EditObjectiveDomainDialogComponent, {
      width: "600px",
      data: {domain}
    });

    editModal.afterClosed().subscribe(
      async result => {
        if (result) {

          this.initialized = false;
          this.objectiveDomains = (await this.objectiveService.getObjectiveDomainsMemoize()).filter(d => !Object.keys(UserDefinedObjectiveDomainTypes).includes(d.key)).sort((a, b) => a.display.localeCompare(b.display));
          this.initialized = true;

        }
      }
    )
  }

  editObjective(objective: Objective): void {
    const editModal = this.dialog.open(EditObjectiveDialogComponent, {
      width: "600px",
      data: { objective }
    });
    editModal.afterClosed().subscribe(
      result => {
        if (result) {
          this.objectivesMap.set(result.objective.id, result.objective);
          const detail = this.objectiveDomainsMap.get(result.objective.objectiveDomainId).find(i => i.objectiveID == result.objective.id);
          const list =  this.objectiveDomainsMap.get(result.objective.objectiveDomainId).filter(i => i.objectiveID != result.objective.id);
          detail.display = result.objective.display;
          list.push(detail);
          this.objectiveDomainsMap.set(result.objective.objectiveDomainId, list.sort((a,b) => a.display.localeCompare(b.display)));
        }
      }
    );
  }

  filterOrganization(objective: Objective) {
    const filterModal = this.dialog.open(FilterOrganizationDialogComponent, {
      width: "600px",
      data: { objective }
    });

    filterModal.afterClosed().subscribe(
      result => {
        if (result) {
          this.objectivesMap.set(result.objective.id, result.objective);
        }
      }
    );

  }

  addNewObjectiveDomain(): void {
    const editModal = this.dialog.open(EditObjectiveDomainDialogComponent, {
      width: "600px",
      data: {addMode: true}
    });

    editModal.afterClosed().subscribe(
      async result => {
        if (result) {
          this.initialized = false;
          this.objectiveDomains = (await this.objectiveService.getObjectiveDomainsMemoize()).filter(d => !Object.keys(UserDefinedObjectiveDomainTypes).includes(d.key)).sort((a, b) => a.display.localeCompare(b.display));
          this.initialized = true;
        }
      }
    )

  }

  addNewObjective(domain: ObjectiveDomain): void {
    const editModal = this.dialog.open(EditObjectiveDialogComponent, {
      width: "600px",
      data: { domain, addMode: true }
    });
    editModal.afterClosed().subscribe(
      result => {
        if (result) {
          this.objectivesMap.set(result.objective.id, result.objective);
          const editObjective = new ObjectiveDetails(result.objective.id, false, result.objective.display);
          if (this.objectiveDomainsMap.has(result.objective.objectiveDomainId)) {
            const list = this.objectiveDomainsMap.get(result.objective.objectiveDomainId);
            list.push(editObjective);
            this.objectiveDomainsMap.set(result.objective.objectiveDomainId, list.sort((a,b) => a.display.localeCompare(b.display)));
          } else {
            this.objectiveDomainsMap.set(result.objective.objectiveDomainId, [editObjective]);
          }
        }
      }
    );
  }

  getObjectiveTitles(id: string): string {
    const objectiveDisplays: string[] = [];
    if (this.objectiveDomainsMap.get(id)) {
      for (const entry of this.objectiveDomainsMap.get(id).entries()) {
        objectiveDisplays.push(this.objectivesMap.get(entry[1].objectiveID).display);
      }
    }
    return objectiveDisplays.join(", ");
  }

  addQRCode(objective: Objective): void {
    const editModal = this.dialog.open(AddQrCodeDialogComponent, {
      width: "600px",
      data: { objective }
    });
    editModal.afterClosed().subscribe(
      result => {
        if (result) {
          this.objectivesMap.set(result.objective.id, result.objective);
          const detail = this.objectiveDomainsMap.get(result.objective.objectiveDomainId).find(i => i.objectiveID == result.objective.id);
          const list =  this.objectiveDomainsMap.get(result.objective.objectiveDomainId).filter(i => i.objectiveID != result.objective.id);
          detail.display = result.objective.display;
          list.push(detail);
          this.objectiveDomainsMap.set(result.objective.objectiveDomainId, list.sort((a,b) => a.display.localeCompare(b.display)));
        }
      }
    );
  }

}
