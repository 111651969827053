export class ObjectiveDetails {
  objectiveID: string;
  isInUse: boolean;
  display: string;

  constructor(objectiveID: string, isInUse: boolean = false, display: string) {
    this.isInUse = isInUse;
    this.objectiveID = objectiveID;
    this.display = display;
  }
}
