import { Component } from '@angular/core';
import {
  SideNavDrawerMenuOption,
  SideNavDrawerMenuOptionType
} from "../../../components/shared/side-nav-drawer/side-nav-drawer.component";

@Component({
  selector: 'app-support-dashboard',
  templateUrl: './support-dashboard.component.html',
  styleUrls: ['./support-dashboard.component.scss']
})
export class SupportDashboardComponent {

  menuOptions: SideNavDrawerMenuOption[] = [
    {
      type: SideNavDrawerMenuOptionType.LINK,
      routerLink: 'manage-organizations',
      title: "Manage Organizations",
      icon: "add_business"
    },
    {
      type: SideNavDrawerMenuOptionType.LINK,
      routerLink: 'system-objective-editor',
      title: "Edit Objectives for Platform",
      icon: "domain_add"
    },
    {
      type: SideNavDrawerMenuOptionType.LINK,
      routerLink: 'system-insphration-editor',
      title: "Edit inspHRation Types for Platform",
      icon: "person_celebrate"
    },
    {
      type: SideNavDrawerMenuOptionType.LINK,
      routerLink: 'organization-survey-stats',
      title: "Organization Survey Statistics",
      icon: "query_stats"
    }
  ];

}
