import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { EmployeeService, OrganizationService } from "@inthraction/services";
import { ToastrService } from "ngx-toastr";
import { ConsultantAssociation, Employee, Organization } from "@inthraction/data-models";
import { FormControl, FormGroup } from "@angular/forms";
import { ConfirmationDialogComponent } from "../../shared/dialogs/confirmation-dialog/confirmation-dialog.component";
import { Subscription } from "rxjs";
import { MatButton } from "@angular/material/button";

@Component({
  selector: "inthraction-edit-consultant-assignment",
  templateUrl: "./edit-consultant-assignment.component.html",
  styleUrls: ["./edit-consultant-assignment.component.scss"]
})
export class EditConsultantAssignmentComponent implements OnInit, OnDestroy {

  private consultantAssociation: ConsultantAssociation;
  private subscriptions: Subscription[] = [];

  employee: Employee;
  organization: Organization;

  editFG = new FormGroup({
    isIncentivized: new FormControl(false),
    isDisabled: new FormControl(false)
  });

  constructor(
    public dialogRef: MatDialogRef<EditConsultantAssignmentComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    private employeeService: EmployeeService,
    private organizationService: OrganizationService,
    private toastrService: ToastrService
  ) {
    this.consultantAssociation = data.consultantAssociation;
    this.editFG.get("isIncentivized").setValue(this.consultantAssociation.incentive);
    this.editFG.get("isDisabled").setValue(this.consultantAssociation.status === "DISABLED");
  }

  ngOnDestroy(): void {
    if (this.subscriptions.length) {
      for (const sub of this.subscriptions) {
        sub.unsubscribe();
      }
    }
  }

  async ngOnInit() {
    this.employee = await this.employeeService.getEmployeeByIDMemoize(this.consultantAssociation.employeeID);
    this.organization = await this.organizationService.getOrganizationByIDMemoize(this.consultantAssociation.organizationID);
  }

  onCancelClick(): void {
    this.dialogRef.close();
  }

  onDeleteClick(deleteBtn: MatButton | HTMLButtonElement) {
    deleteBtn.disabled = true;
    const deleteDialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: "350px",
      data: "Delete this Consultant Association?"
    });

    this.subscriptions.push(
      deleteDialogRef.afterClosed().subscribe(async result => {
        if (result) {
          try {
            await this.employeeService.deleteConsultantAssociation(this.consultantAssociation);
            this.toastrService.success("Association Deleted");
            this.dialogRef.close({ deleted: true });
          } catch (err) {
            this.toastrService.error("Unable to delete association");
            console.error(err);
            deleteBtn.disabled = false;
            throw err;
          }
        } else {
          deleteBtn.disabled = false;
        }
      }));
  }

  async onSaveClick(value: any, submitBtn: MatButton | HTMLButtonElement) {
    submitBtn.disabled = true;
    this.consultantAssociation.status = value.isDisabled ? "DISABLED" : "ENABLED";
    this.consultantAssociation.incentive = value.isIncentivized;
    try {
      if (await this.employeeService.updateConsultantAssociation(this.consultantAssociation)) {
        this.toastrService.success("Association Updated");
        this.dialogRef.close({ consultantAssociation: this.consultantAssociation });
        return;
      }
    } catch (exception) {
      console.error(exception);
      submitBtn.disabled = this.editFG.invalid;
      this.toastrService.error("Unable to update association");
      this.dialogRef.close({ deleted: false });
      throw exception;
    }
  }
}
