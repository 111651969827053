<h1 mat-dialog-title>Readiness History</h1>
<div mat-dialog-content class="overflow-container">
  <mat-card appearance="outlined" *ngFor="let history of historyRecords">
    <mat-card-header>
      <mat-card-title>{{readinessLabels[history.readiness]}}</mat-card-title>
    </mat-card-header>
    <mat-card-footer>
      <mat-card-subtitle>{{authors.get(history.createdBy || "")}}</mat-card-subtitle>
      <mat-card-subtitle>{{history.createdAt | amParse: 'YYYY-MM-DDTHH:mm:ssZ' | amDateFormat: 'lll'}}</mat-card-subtitle>
    </mat-card-footer>
    <mat-card-content>
      <mat-form-field class="full-width">
        <mat-label>Comments</mat-label>
        <textarea matInput readonly [value]="history.comments" cdkTextareaAutosize></textarea>
      </mat-form-field>
      <mat-form-field class="full-width" *ngFor="let opportunity of history.opportunities || []; let i = index">
        <mat-label>Opportunity {{i + 1}}</mat-label>
        <textarea matInput readonly [value]="opportunity" cdkTextareaAutosize></textarea>
      </mat-form-field>
    </mat-card-content>
  </mat-card>
</div>
