<div class="main-header">

  <img alt="site logo" class="logo" [src]="logo" width="150px">
  <div class="main-nav-container" *ngIf="!disabledUser && !disabledOrgainization">
    <nav class="main-nav-bar" mat-tab-nav-bar [tabPanel]="tabPanel" mat-stretch-tabs="false">
      <a #rla="routerLinkActive"                       [active]="rla.isActive" aria-label="My Dashboard"      mat-tab-link (click)="goTo('/dashboard')"         routerLinkActive>My Dashboard</a>
      <a #rla="routerLinkActive" *ngIf="isManager"     [active]="rla.isActive" aria-label="Manager"           mat-tab-link (click)="goTo('/manager')"           routerLinkActive>Manager</a>
      <a #rla="routerLinkActive" *ngIf="isProjectUser" [active]="rla.isActive" aria-label="Project Dashboard" mat-tab-link (click)="goTo('/project-dashboard')" routerLinkActive>Project Dashboard</a>
      <a #rla="routerLinkActive" *ngIf="isHRUser"      [active]="rla.isActive" aria-label="HR"                mat-tab-link (click)="goTo('/hr')"                routerLinkActive>HR</a>
      <!--      <a #rla="routerLinkActive" *ngIf="isExecutive && isSuperUser" [active]="rla.isActive" aria-label="Executive Dashboard" mat-tab-link (click)="goTo('/executive-dashboard')" routerLinkActive>Executive Dashboard</a>-->
      <a #rla="routerLinkActive" *ngIf="isConsultant"  [active]="rla.isActive" aria-label="consultant"        mat-tab-link (click)="goTo('/consultant')"        routerLinkActive>Consultant</a>
      <a #rla="routerLinkActive" *ngIf="isAdminUser"   [active]="rla.isActive" aria-label="admin"             mat-tab-link (click)="goTo('/admin')"             routerLinkActive>Admin</a>
      <a #rla="routerLinkActive" *ngIf="isSuperUser"   [active]="rla.isActive" aria-label="support"           mat-tab-link (click)="goTo('/support')"           routerLinkActive>Support</a>
    </nav>
  </div>

  <div class="user-menu-container" *ngIf="!disabledUser">

    <button mat-icon-button (click)="toggleHelp()">
      <mat-icon *ngIf="isShowHelp">help</mat-icon>
      <mat-icon *ngIf="!isShowHelp">help_outline</mat-icon>
    </button>
    <button [matMenuTriggerFor]="profileMenu3" aria-label="User Menu" class="user-button" mat-button>
      <mat-icon>person</mat-icon>
      <span>{{ userFirstName }}</span>
    </button>
    <mat-menu #profileMenu3="matMenu">
      <button *ngIf="!disabledUser && !disabledOrgainization" aria-label="profile" mat-menu-item (click)="goTo('/profile')">Profile</button>
      <button (click)="logOut()" aria-label="Log Out" mat-menu-item>Log Out</button>
    </mat-menu>
  </div>
  <div class="user-menu-container" *ngIf="disabledUser">
    <button (click)="logOut()" aria-label="Log Out" mat-menu-item>Log Out</button>
  </div>
</div>

<mat-tab-nav-panel #tabPanel></mat-tab-nav-panel>
