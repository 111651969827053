<div (onResize)="onResize($event)">
  <div *ngIf="employee" class="org-nav-top-button-container">
    <button mat-fab color="gray" *ngIf="parent || employees.length > 1" (click)="loadPrevious()" class="org-nav-top-button">
      <mat-icon>keyboard_arrow_up</mat-icon>
    </button>
    <inthraction-employee-card-component [employee]="employee" class="manager" [options]="Object.assign({disableOrgNav:true, showOrgNav:false, isFavorite:employeeCardOptions.favorites?.includes(employee.id)},employeeCardOptions)" (editClickEvent)="employeeEditClickEvent.emit($event)" (profileImageClickEvent)="profileImageClickEvent.emit($event)"
                                         (favoriteClickEvent)="favoriteClickEvent.emit($event)"></inthraction-employee-card-component>
  </div>
  <mat-grid-list *ngIf="employee && subordinates?.length > 0" rowHeight="258px" [cols]="breakpoint">
    <ng-container *ngFor="let child of subordinates; let i = index">
      <mat-grid-tile>
        <inthraction-employee-card-component [employee]="child" [options]="Object.assign({showOrgNav:true, isFavorite:employeeCardOptions.favorites?.includes(child.id)},employeeCardOptions)" (orgNavEvent)="loadNext($event)" (editClickEvent)="employeeEditClickEvent.emit($event)" (profileImageClickEvent)="profileImageClickEvent.emit($event)"
                                             (favoriteClickEvent)="favoriteClickEvent.emit($event)" compact></inthraction-employee-card-component>
      </mat-grid-tile>
    </ng-container>
  </mat-grid-list>

  <mat-grid-list *ngIf="!employee && employees.length > 1" rowHeight="258px" [cols]="breakpoint">
    <mat-grid-tile *ngFor="let emp of sortedEmployees">
      <inthraction-employee-card-component [employee]="emp" [options]="Object.assign({showOrgNav:true, isFavorite:employeeCardOptions.favorites?.includes(emp.id)},employeeCardOptions)" (orgNavEvent)="loadParent($event)" (editClickEvent)="employeeEditClickEvent.emit($event)" (profileImageClickEvent)="profileImageClickEvent.emit($event)" (favoriteClickEvent)="favoriteClickEvent.emit($event)"
                                           compact></inthraction-employee-card-component>
    </mat-grid-tile>
  </mat-grid-list>
</div>
