<ng-container *ngIf="employee && survey">
  <inthraction-standup-survey *ngIf="survey.surveyType === SurveyTypes.STANDUP" [employee]="employee" [survey]="survey" [remainingSurveysCount]="remainingSurveysCount" (surveyComplete)="loadNextSurvey($event)"></inthraction-standup-survey>
  <inthraction-event-survey *ngIf="survey.surveyType === SurveyTypes.EVENT" [employee]="employee" [survey]="survey" [remainingSurveysCount]="remainingSurveysCount" [surveyResponse]="surveyResponse" (surveyComplete)="loadNextSurvey($event)"></inthraction-event-survey>
  <inthraction-milestone-survey *ngIf="survey.surveyType === SurveyTypes.MILESTONE" [employee]="employee" [survey]="survey" [remainingSurveysCount]="remainingSurveysCount" [surveyResponse]="surveyResponse" (surveyComplete)="loadNextSurvey($event)"></inthraction-milestone-survey>
  <inthraction-objective-survey *ngIf="survey.surveyType === SurveyTypes.OBJECTIVE" [employee]="employee" [survey]="survey" [remainingSurveysCount]="remainingSurveysCount" [surveyResponse]="surveyResponse" (surveyComplete)="loadNextSurvey($event)"></inthraction-objective-survey>
  <inthraction-project-survey *ngIf="survey.surveyType === SurveyTypes.PROJECT" [employee]="employee" [survey]="survey" [remainingSurveysCount]="remainingSurveysCount" [surveyResponse]="surveyResponse" (surveyComplete)="loadNextSurvey($event)"></inthraction-project-survey>
  <inthraction-organization-experience-survey *ngIf="survey.surveyType === SurveyTypes.NEW_MANAGER_90_DAY || survey.surveyType === SurveyTypes.NEW_HIRE_14_DAY || survey.surveyType === SurveyTypes.NEW_HIRE_90_DAY || survey.surveyType === SurveyTypes.NEW_POSITION_60_DAY || survey.surveyType === SurveyTypes.NEW_MANAGER_30_DAY" [employee]="employee" [survey]="survey"
                                              [remainingSurveysCount]="remainingSurveysCount" [surveyResponse]="surveyResponse"
                                              (surveyComplete)="loadNextSurvey($event)"></inthraction-organization-experience-survey>
</ng-container>

<inthraction-loading className="" [initDone]="(survey && employee)? true : false"></inthraction-loading>
