<div class="{{options.clickable ? 'click': ''}}">
  <mat-card appearance="outlined" *ngIf="employee" class="team-member-card" (click)="clickEvent.emit(employee)">
    <mat-card-header>
      <inthraction-profile-image *ngIf="employee" [employeeImage]="employeeImage"></inthraction-profile-image>
      <div>
        <mat-card-subtitle title="{{employee?.firstName}} {{employee?.lastName}}">{{employee?.firstName}} {{employee?.lastName}}</mat-card-subtitle>
        <mat-card-subtitle class="employee-title" title="{{employee?.title}}">{{employee?.title}}</mat-card-subtitle>
      </div>
      <div *ngIf="scoreColor" class="circle {{scoreColor}}"></div>
    </mat-card-header>
    <mat-card-actions *ngIf="options.showRemoveButton">
      <button class="panel-footer-button" (click)="removeEvent.emit(employee.id)" aria-label="Remove Team Member" mat-icon-button title="Remove Team Member">
        <mat-icon>remove_circle</mat-icon>
      </button>
    </mat-card-actions>
  </mat-card>
</div>
