import { Objective, ObjectiveDomain } from "./objective";

export class ObjectiveImpl implements Objective {

  domain: ObjectiveDomain | any | null;
  organizationID: string;
  definition: string;
  display: string;
  id: string;
  __typename = "Objective";
  observables: string[];
  hints: string[];
  status: string;
  objectiveDomainId: string;

  constructor(private _objective?: Objective) {
    if (this._objective) {
      Object.assign(this, this._objective);
    }
  }

}


export class ObjectiveDomainImpl implements ObjectiveDomain {
    id: string;
    __typename: "ObjectiveDomain";
    createdAt?: string;
    display: string;
    key: string;
    updatedAt?: string;
    excludeFromTPS?: boolean;
    sites: string[] = [];

    constructor(private _objectiveDomain?: ObjectiveDomain) {
      if(this._objectiveDomain) {
        Object.assign(this, this._objectiveDomain);
      }
    }

}
