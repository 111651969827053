import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { QuestionsMap } from "@inthraction/data-mappers";
import {
  Employee,
  EmployeeImageInterface,
  Note,
  StandupSurvey,
  StandupSurveyConfiguration
} from "@inthraction/data-models";
import { EmployeeService, StandupService } from "@inthraction/services";
import { ActivatedRoute, Router } from "@angular/router";

@Component({
  selector: "inthraction-stand-up-survey-display",
  templateUrl: "./stand-up-survey-display.component.html",
  styleUrls: ["./stand-up-survey-display.component.scss"]
})
export class StandUpSurveyDisplayComponent implements OnInit {

  @Input() survey: StandupSurvey;
  @Input() user: Employee;
  @Input() hideAvatar?: boolean;
  @Input() note?: Note;
  @Input() showCommentButton?: boolean;
  @Output() commentButtonClickEvent = new EventEmitter();

  protected configuration: StandupSurveyConfiguration;
  protected respondent: Employee;
  protected respondentImage: EmployeeImageInterface;

  constructor(
    private standupService: StandupService,
    private employeeService: EmployeeService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
  }

  async ngOnInit() {
    this.configuration = await this.standupService.getStandupConfigurationByIDMemoize(this.survey.standupSurveyConfigurationID);
    this.respondent = await this.employeeService.getEmployeeByIDMemoize(this.survey.respondentID);
    this.respondentImage = await this.employeeService.getEmployeeImageMemoize(this.respondent.id, this.respondent.orgId);
  }


  getStandupQuestion(configuration: StandupSurveyConfiguration, questionMap: QuestionsMap): string {
    const question = configuration.questions.find(q => q.id == questionMap.id);
    return question.questionTextArray[questionMap.version];
  }

  async editStandUpNote($event: any) {
    let noteTypeRoute = "communication";
    this.router.navigate([`${this.survey.respondentID}/${noteTypeRoute}/${this.survey.id}/${this.note.id}`], { relativeTo: this.activatedRoute });
  }


  reviewStandUpNote($event: any) {
    let noteTypeRoute = "view-communication";
    this.router.navigate([`${this.survey.respondentID}/${noteTypeRoute}/${this.survey.id}/${this.note.id}`], { relativeTo: this.activatedRoute });
  }

}
