import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from "@angular/core";
import { Employee, OrganizationObjective } from "@inthraction/data-models";
import { OBJECTIVE_SURVEY_RESPONSE_TYPES, OBJECTIVE_SURVEY_RESPONSE_VALUES } from "@inthraction/codes";
import { OBJECTIVE_SURVEY_RESPONSE_LABELS } from "@inthraction/labels";
import { EmployeeService, ObjectiveService, OrganizationService, SurveyService } from "@inthraction/services";
import { Moment } from "moment";
import { Subscription } from "rxjs";
import { MatDialog } from "@angular/material/dialog";
import { ObjectiveDistributionListComponent } from "../objective-distribution-list/objective-distribution-list.component";
import { DistributionDetails, ObjectiveDistributionDetailsModes } from "../DistributionDetails";
import { ObjectiveDistributionCsvExportAbstractComponent } from "../objective-distribution-csv-export.abstract-component";

@Component({
  selector: "inthraction-objective-distribution-detail",
  templateUrl: "./objective-distribution-detail.component.html",
  styleUrls: ["./objective-distribution-detail.component.scss"]
})
export class ObjectiveDistributionDetailComponent extends ObjectiveDistributionCsvExportAbstractComponent implements OnInit, OnChanges, OnDestroy {

  @Input() orgObjective: OrganizationObjective;
  @Input() mode?: ObjectiveDistributionDetailsModes;
  @Input() startDate?: Moment;
  @Input() endDate?: Moment;
  @Output() assignmentTotalEvent = new EventEmitter<number>();

  detailsMap: Map<string, DistributionDetails> = new Map<string, DistributionDetails>();
  totalUserCount: number = 0;

  readonly OBJECTIVE_SURVEY_RESPONSE_VALUES = OBJECTIVE_SURVEY_RESPONSE_VALUES;
  readonly _OBJECTIVE_SURVEY_RESPONSE_TYPES = [];
  readonly OBJECTIVE_SURVEY_RESPONSE_LABELS = OBJECTIVE_SURVEY_RESPONSE_LABELS;

  private employeeList: Employee[];
  private subscriptions: Subscription[] = [];

  constructor(
    protected employeeService: EmployeeService,
    protected organizationService: OrganizationService,
    protected objectiveService: ObjectiveService,
    public dialog: MatDialog
  ) {

    super(objectiveService, employeeService, organizationService);

    this._OBJECTIVE_SURVEY_RESPONSE_TYPES.push(...OBJECTIVE_SURVEY_RESPONSE_TYPES);
    this._OBJECTIVE_SURVEY_RESPONSE_TYPES.reverse();
  }

  ngOnDestroy(): void {
    if (this.subscriptions.length) {
      for (const sub of this.subscriptions) {
        sub.unsubscribe();
      }
    }
  }

  async ngOnInit() {
    if (this.mode && (this.mode === ObjectiveDistributionDetailsModes.HR || this.mode === ObjectiveDistributionDetailsModes.CONSULTANT)) {
      this.employeeList = await this.employeeService.getEmployeesForOrganizationByOrganization({includeDisabled:false, organizationID:this.orgObjective.organizationID, memoize:true});
    } else {
      this.employeeList = await this.employeeService.getSubordinatesByEmployeeIDForOrganization({managerID: (await this.employeeService.getCurrentEmployee()).id, memoize: true});
    }
    this.totalUserCount = this.employeeList.length;
    this.detailsMap = await this.gatherStats(this.employeeList, this.orgObjective, this.startDate, this.endDate);
    this.assignmentTotalEvent.next(this.assignmentTotal);
  }

  async ngOnChanges(changes: SimpleChanges) {
    if (changes.startDate && changes.endDate) {
      this.detailsMap = await this.gatherStats(this.employeeList, this.orgObjective, changes.startDate.currentValue, changes.endDate.currentValue);
    } else if (changes.startDate) {
      this.detailsMap = await this.gatherStats(this.employeeList, this.orgObjective, changes.startDate.currentValue, this.endDate);
    } else if (changes.endDate) {
      this.detailsMap = await this.gatherStats(this.employeeList, this.orgObjective, this.startDate, changes.endDate.currentValue);
    }
  }

  getPercentage(assigneeCount: number): number | string {
    if (this.totalUserCount) {
      return (SurveyService.round(assigneeCount / this.totalUserCount, 2) * 100).toFixed(0);
    }
    return 0;
  }

  showDistributionList(distributionType: string) {
    if (this.detailsMap.get(distributionType)?.tableData?.length) {
      this.dialog.open(ObjectiveDistributionListComponent, {
        data: {
          tableData: this.detailsMap.get(distributionType).tableData,
          domain: this.orgObjective.objective.domain.display,
          title: this.orgObjective.objective.display
        }
      });
    }
  }

  downloadCSV() {
    super.download(this.detailsMap, `${this.orgObjective.objective.domain.display}-${this.orgObjective.objective.display}-${this.startDate?.format("l")} to ${this.endDate?.format("l")}.csv`);
  }

}
