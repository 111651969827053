import { Component, HostListener, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { ShoutOutService } from "@inthraction/services";
import { ShoutOutType, UpdateShoutOutType } from "@inthraction/data-models";
import { ShoutOutIconLabels } from "@inthraction/labels";
import { ShoutOutIcons } from "@inthraction/codes";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MatButton } from "@angular/material/button";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "inthraction-edit-shout-out-type-dialog",
  templateUrl: "./edit-shout-out-type-dialog.component.html",
  styleUrls: ["./edit-shout-out-type-dialog.component.scss"]
})
export class EditShoutOutTypeDialogComponent implements OnInit {

  addMode: boolean;
  readonly shoutOutIcons = ShoutOutIcons;
  readonly shoutOutIconLabels = ShoutOutIconLabels;
  readonly shoutOutIconKeys = Object.keys(ShoutOutIcons) as string[];
  readonly shoutOutValueToKeyMap: Map<string, string> = new Map<string, string>();
  shoutOutTypeForm: FormGroup;
  attributes: string[] = [];
  private shoutOutType: ShoutOutType;
  private titleFC = new FormControl("", [Validators.required]);
  private formalDescriptionFC = new FormControl("", [Validators.required]);
  private informalDescriptionFC = new FormControl("", [Validators.required]);
  private iconFC = new FormControl("", [Validators.required]);

  constructor(
    public dialogRef: MatDialogRef<EditShoutOutTypeDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private shoutOutService: ShoutOutService,
    private toastr: ToastrService
  ) {

    for (const key of this.shoutOutIconKeys) {
      this.shoutOutValueToKeyMap.set(ShoutOutIcons[key], key);
    }

    this.shoutOutTypeForm = new FormGroup({
      title: this.titleFC,
      formalDescription: this.formalDescriptionFC,
      informalDescription: this.informalDescriptionFC,
      icon: this.iconFC
    });

    if (data.addMode) {
      this.addMode = true;
      this.shoutOutType = {
        id: null,
        __typename: "OrganizationShoutOutType",
        title: null,
        formalDescription: null,
        informalDescription: null,
        icon: null,
        attributes: []
      };
    } else {
      this.addMode = false;
      this.shoutOutType = JSON.parse((JSON.stringify(data.shoutOutType)));
      this.attributes.push(...this.shoutOutType.attributes);
      this.titleFC.setValue(this.shoutOutType.title);
      this.formalDescriptionFC.setValue(this.shoutOutType.formalDescription);
      this.informalDescriptionFC.setValue(this.shoutOutType.informalDescription);
      this.iconFC.setValue(this.shoutOutType.icon);
    }
  }

  ngOnInit() {
  }

  async onSaveClick(value: any, submitBtn: MatButton | HTMLButtonElement) {
    submitBtn.disabled = true;
    try {
      let result: ShoutOutType;
      const attributes: string[] = [];
      for (const attribute of this.attributes) {
        if (attribute.trim().length) {
          attributes.push(attribute);
        }
      }
      if (this.addMode) {
        this.shoutOutType.title = value.title;
        this.shoutOutType.formalDescription = value.formalDescription;
        this.shoutOutType.informalDescription = value.informalDescription;
        this.shoutOutType.icon = value.icon;
        this.shoutOutType.attributes = attributes;
        result = await this.shoutOutService.createShoutOutType(this.shoutOutType);
        this.toastr.success("inspHRation Created");
      } else {
        const updateType: UpdateShoutOutType = { id: this.shoutOutType.id };
        if (this.shoutOutType.title !== value.title) {
          updateType.title = value.title;
        }
        if (this.shoutOutType.icon !== value.icon) {
          updateType.icon = value.icon;
        }
        if (this.shoutOutType.formalDescription !== value.formalDescription) {
          updateType.formalDescription = value.formalDescription;
        }
        if (this.shoutOutType.informalDescription !== value.informalDescription) {
          updateType.informalDescription = value.informalDescription;
        }
        updateType.attributes = attributes;
        result = await this.shoutOutService.updateShoutOutType(updateType);
        this.toastr.success("inspHRation Updated");
      }
      this.dialogRef.close({ shoutOutType: result });
    } catch (err) {
      submitBtn.disabled = !this.shoutOutTypeForm.valid;
      console.error(err);
      this.toastr.error("Unable to create or update inspHRation");
      throw err;
    }
  }

  onCancelClick() {
    this.dialogRef.close(null);
  }

  @HostListener("keydown.esc")
  public onEsc() {
    this.onCancelClick();
  }

  public hasError = (controlName: string, errorName: string) =>
    this.shoutOutTypeForm.controls[controlName].hasError(errorName);

}
