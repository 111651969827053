import { NgModule } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { UI_PIPES } from "./pipes";
import { UI_COMPONENTS } from "./components";
import { CommonModule } from "@angular/common";

const MODULES = [TranslateModule, CommonModule];

@NgModule({
  imports: [...MODULES],
  declarations: [...UI_PIPES, ...UI_COMPONENTS],
  exports: [...MODULES, ...UI_PIPES, ...UI_COMPONENTS]
})
export class UISharedModule {
}
