<inthraction-page-header pageTitle="Objective Assignments" downloadBtn="true" (downloadBtnClickEvent)="download()" [downloadBtnDisabled]="tableData === undefined || !tableData.data.length">
  <mat-form-field class="no-subscript-wrapper">
    <mat-label>Filter</mat-label>
    <input matInput (keyup)="applyFilter($event)" [placeholder]="'Ex. ' + (filterExample || 'Ready Now')" #input>
  </mat-form-field>
</inthraction-page-header>
<h5 *ngIf="tableData.data.length">{{assignedCount}} of {{tableData.data.length}} Workforce Assigned</h5>
<div class="wrapper mat-elevation-z8" [hidden]="!tableData.data.length">
  <table mat-table *ngIf="tableData" matSort multiTemplateDataRows [dataSource]="tableData">
    <ng-container matColumnDef="department">
      <th mat-header-cell mat-sort-header *matHeaderCellDef sortActionDescription="Sort by Department">Department</th>
      <td mat-cell *matCellDef="let element"> {{element.department}}</td>
    </ng-container>
    <ng-container matColumnDef="manager">
      <th mat-header-cell mat-sort-header *matHeaderCellDef sortActionDescription="Sort by Manager">Manager</th>
      <td mat-cell *matCellDef="let element"> {{element.managerName}}</td>
    </ng-container>
    <ng-container matColumnDef="employee">
      <th mat-header-cell mat-sort-header *matHeaderCellDef sortActionDescription="Sort by User">User</th>
      <td mat-cell *matCellDef="let element"> {{element.employeeName}}</td>
    </ng-container>
    <ng-container matColumnDef="totalObjectives">
      <th mat-header-cell mat-sort-header *matHeaderCellDef sortActionDescription="Sort by Total Objectives">Total Objectives</th>
      <td mat-cell *matCellDef="let element"> {{element.totalObjectives}}</td>
    </ng-container>
    <ng-container matColumnDef="totalCurrent">
      <th mat-header-cell mat-sort-header *matHeaderCellDef sortActionDescription="Sort by Total Current">Total Current</th>
      <td mat-cell *matCellDef="let element"> {{element.totalCurrent}}</td>
    </ng-container>
    <ng-container matColumnDef="totalFuture">
      <th mat-header-cell mat-sort-header *matHeaderCellDef sortActionDescription="Sort by Total Future">Total Future</th>
      <td mat-cell *matCellDef="let element"> {{element.totalFuture}}</td>
    </ng-container>
    <ng-container matColumnDef="currentScore">
      <th mat-header-cell mat-sort-header *matHeaderCellDef sortActionDescription="Sort by Current Score">Current Score</th>
      <td mat-cell *matCellDef="let element"> {{element.currentScore}}</td>
    </ng-container>
    <ng-container matColumnDef="futureScore">
      <th mat-header-cell mat-sort-header *matHeaderCellDef sortActionDescription="Sort by Future Score">Future Score</th>
      <td mat-cell *matCellDef="let element"> {{element.futureScore}}</td>
    </ng-container>
    <ng-container matColumnDef="lastOneOnOne">
      <th mat-header-cell mat-sort-header *matHeaderCellDef matSortingDataAccessor sortActionDescription="Sort by Last 1-1">Last 1-1</th>
      <td mat-cell *matCellDef="let element"> {{element.lastOneOnOneDate ? (element.lastOneOnOneDate | amParse: "YYYY-MM-DD" | amDateFormat:'ll') : ''}}</td>
    </ng-container>
    <ng-container matColumnDef="currentReadiness">
      <th mat-header-cell mat-sort-header *matHeaderCellDef sortActionDescription="Sort by Current Readiness">Current Readiness</th>
      <td mat-cell *matCellDef="let element"> {{element.currentReadiness}}</td>
    </ng-container>
    <ng-container matColumnDef="view">
      <th mat-header-cell *matHeaderCellDef>View</th>
      <td mat-cell *matCellDef="let element">
        <button mat-stroked-button (click)="view(element.employee)">View</button>
      </td>
    </ng-container>

    <ng-container matColumnDef="expandedDetail">
      <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
        <div class="element-detail" *ngIf="element.totalObjectives">
          <inthraction-employee-objective-assignments-detail [objectiveAssignmentData]="element.objectiveAssignmentData"></inthraction-employee-objective-assignments-detail>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let element; columns: displayedColumns;" [class.clickable]="element.totalObjectives" [class.expanded-row]="expandedElement === element" (click)="expandedElement = expandedElement === element ? null : element" class="element-row"></tr>
    <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="detail-row" [@detailExpand]="row == expandedElement ? 'expanded' : 'collapsed'"></tr>
  </table>
</div>

<inthraction-loading className="wrapper" [initDone]="tableData.data.length > 0 || noDataFound"></inthraction-loading>
<ng-container *ngIf="noDataFound">
  <h3>Sorry! No Assignments were found.</h3>
</ng-container>
