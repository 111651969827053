import { Component } from '@angular/core';
import {
  SideNavDrawerMenuOption,
  SideNavDrawerMenuOptionType
} from "../../../components/shared/side-nav-drawer/side-nav-drawer.component";

@Component({
  selector: 'app-manager-dashboard',
  templateUrl: './manager-dashboard.component.html',
  styleUrls: ['./manager-dashboard.component.scss']
})
export class ManagerDashboardComponent {

  menuOptions: SideNavDrawerMenuOption[] = [
    {
      type: SideNavDrawerMenuOptionType.LINK,
      routerLink: 'dashboard',
      title: 'Dashboard',
      icon: 'person'
    },
    {
      type: SideNavDrawerMenuOptionType.LINK,
      routerLink: 'objective-distribution',
      title: 'Objective Distribution',
      icon: 'domain'
    },
    {
      type: SideNavDrawerMenuOptionType.LINK,
      routerLink: 'objective-response-distribution',
      title: 'Objective Response Distribution',
      icon: 'dataset'
    },
    {
      type: SideNavDrawerMenuOptionType.LINK,
      routerLink: 'objective-assignments-report',
      title: 'Objective Assignments',
      icon: 'patient_list'
    }
  ];

}
