export const READINESS_CADENCE_LABELS = {
  YEARLY: "Yearly (Based on position start date)",
  ANNUAL: "Annual",
  BIANNUAL: "Biannual",
  QUARTERLY: "Quarterly",
  DISABLED: "Disabled"
};

export const READINESS_TYPE_LABELS = {
  ACTION_NEEDED: "Action Needed",
  NEW_IN_POSITION: "New in Position",
  WELL_PLACED: "Well Placed",
  READY_NOW: "Ready Now",
  SIX_EIGHTEEN_MO: "6 - 18 Months",
  ONE_POINT_FIVE_THREE_YEARS: "1.5 - 3 Years",
  THREE_FIVE_YEARS: "3 - 5 Years"
};
