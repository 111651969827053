import { Injectable } from "@angular/core";
import {
  APIService,
  CreateConsultantAssociationInput,
  CreateEmployeeCalendarCodeInput,
  CreateEmployeeCalendarCodeMutation,
  CreateEmployeeImageInput,
  CreateEmployeeInput,
  CreateUserActionInput,
  CreateUserActionMutation,
  GetConsultantAssociationQuery,
  ModelConsultantAssociationFilterInput, ModelEmployeeDataFilterInput,
  ModelEmployeeFilterInput, ModelEmployeeImageFilterInput,
  ModelEmployeeScoreFilterInput, ModelEmployeeScorePrimaryCompositeKeyConditionInput,
  ModelReadinessHistoryFilterInput,
  ModelStringKeyConditionInput,
  UpdateConsultantAssociationInput,
  UpdateConsultantFavoritesInput,
  UpdateEmployeeImageInput,
  UpdateEmployeeInput
} from "./API.service";
import {
  ConsultantAssociation,
  ConsultantFavorites,
  Employee,
  EmployeeData,
  EmployeeImageInterface,
  EmployeeObjectiveScore,
  EmployeeScore,
  IEmployeeImpl,
  ReadinessHistory,
  TotalEmployeeEventScore,
  TotalEmployeeObjectiveScore
} from "@inthraction/data-models";
import { Memoize, MEMOIZE_FN_MAP } from "@inthraction/utils";
import * as moment from "moment";
import { BaseService, GetAllOptions } from "./base.service";
import { EmployeeDataTypes, EventSurveyResponseScores, READINESS_TYPE, round, ScoreTypes } from "@inthraction/codes";
import { AuthService } from "./auth.service";
import { BehaviorSubject } from "rxjs";
import {
  EmployeeEventScoreDetails,
  EmployeeObjectiveScoreDetails,
  EmployeePreferences
} from "@inthraction/data-mappers";

export interface CreateEmployee extends CreateEmployeeInput {
}

export interface UpdateEmployee extends UpdateEmployeeInput {
}

export interface GetEmployeeCriteria {
  includeDisabled?: boolean;
  organizationID?: string;
  onlyDisable?: boolean;
  memoize?: boolean;
}

export interface GetSubordinatesCriteria {
  organizationID?: string;
  managerID?: string;
  includeDisabled?: boolean;
  memoize?: boolean;
}

const TEN_MIN = 600000;

@Injectable({ providedIn: "root" })
export class EmployeeService extends BaseService {

  private static employeeColorMap = new Map<string, string>();
  private _isShowHelp: boolean = true;
  private initializedShowHelp: string;

  public showHelpStateChange$ = new BehaviorSubject(this._isShowHelp);

  constructor(
    protected api: APIService,
    protected authService: AuthService
  ) {
    super(api, authService);
  }

  async isShowHelp(): Promise<boolean> {
    const user = await this.getCurrentUser();
    if (user && this.initializedShowHelp !== user.id) {
      const preferences = await this.getEmployeeData(user.orgId, user.id, [EmployeeDataTypes.PREFERENCES], true);
      if (preferences && preferences.length) {
        const userPreferences: EmployeeData = preferences[0];
        const employeePreferences: EmployeePreferences = JSON.parse(userPreferences.stringValue);
        this._isShowHelp = !employeePreferences.disableShowHelpIcons;
        this.showHelpStateChange$.next(this._isShowHelp);
      }
      this.initializedShowHelp = user.id;
    }
    return Promise.resolve(this._isShowHelp);
  }

  setShowHelp(isShowHelp: boolean) {
    if (this._isShowHelp !== isShowHelp) {
      this._isShowHelp = isShowHelp;
      this.showHelpStateChange$.next(this._isShowHelp);
    }
  }

  @Memoize()
  public static getScoreColor(score: number, scale?: number): string {
    let color;
    if (scale && scale == 6) {
      color = "red";
      if (score >= EventSurveyResponseScores.DISRUPTED && score < EventSurveyResponseScores.DISTRACTED) {
        color = "orange";
      } else if (score >= EventSurveyResponseScores.DISTRACTED && score < EventSurveyResponseScores.PARTICIPATED) {
        color = "yellow";
      } else if (score >= EventSurveyResponseScores.PARTICIPATED && score < EventSurveyResponseScores.CONTRIBUTED) {
        color = "green";
      } else if (score >= EventSurveyResponseScores.CONTRIBUTED && score < EventSurveyResponseScores.LEAD) {
        color = "darkgreen";
      } else if (score >= EventSurveyResponseScores.LEAD) {
        color = "blue";
      }
    } else if (!scale || scale == 5) {
      color = "red";
      if (score >= 2 && score < 4.5) {
        color = "orange";
      } else if (score >= 4.5 && score < 6.0) {
        color = "yellow";
      } else if (score >= 6.0 && score < 8.5) {
        color = "green";
      } else if (score >= 8.5) {
        color = "blue";
      }
    }
    return color;
  }

  private static getRandomColor(): string {
    const letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  async getSubordinatesByEmployeeIDForOrganization(criteria: GetSubordinatesCriteria): Promise<Employee[]> {
    if (!criteria.organizationID) {
      criteria.organizationID = (await this.getCurrentUser()).orgId;
    }

    let results: Employee[];
    if (criteria.memoize) {
      results = await this._getEmployeesForOrganizationByOrganizationMemoize(criteria.organizationID);
    } else {
      if (MEMOIZE_FN_MAP.has("_getEmployeesForOrganizationByOrganizationMemoize")) {
        MEMOIZE_FN_MAP.get("_getEmployeesForOrganizationByOrganizationMemoize").clear();
      }
      results = await this._getEmployeesForOrganizationByOrganizationMemoize(criteria.organizationID);
    }
    if (!criteria.includeDisabled) {
      results = results.filter(e => !e.disabled);
    }
    if (criteria.managerID) {
      results = results.filter(e => e.managerID == criteria.managerID);
    } else {
      results = results.filter(e => !e.managerID);
    }

    return results;
  }

  async getEmployeesForOrganizationByOrganization(criteria: GetEmployeeCriteria): Promise<Employee[]> {
    if (!criteria.organizationID) {
      criteria.organizationID = (await this.getCurrentUser()).orgId;
    }
    let results: Employee[];
    if (criteria.memoize) {
      results = await this._getEmployeesForOrganizationByOrganizationMemoize(criteria.organizationID);
    } else {
      if (MEMOIZE_FN_MAP.has("_getEmployeesForOrganizationByOrganizationMemoize")) {
        MEMOIZE_FN_MAP.get("_getEmployeesForOrganizationByOrganizationMemoize").clear();
      }
      results = await this._getEmployeesForOrganizationByOrganizationMemoize(criteria.organizationID);
    }
    if (!criteria.includeDisabled) {
      results = results.filter(e => !e.disabled);
    }
    if (criteria.onlyDisable && criteria.includeDisabled) {
      results = results.filter(e => e.disabled);
    }
    return results;
  }

  @Memoize({ maxAge: TEN_MIN, preFetch: true })
  private async _getEmployeesForOrganizationByOrganizationMemoize(organizationID: string): Promise<Employee[]> {
    const searchFilter: ModelEmployeeFilterInput = { orgId: { eq: organizationID } };
    return this.getAll<Employee>(this.api.ListEmployees, searchFilter);
  }

  async getEmployeeByID(employeeID: string, memoize?:boolean): Promise<Employee> {
    if (!employeeID) {
      const missingParameterError = new Error("Missing parameter: employeeID");
      console.error(missingParameterError);
      return null;
    }

    let employees: Employee[];
    if(memoize) {
      employees = await this.getEmployeesForOrganizationByOrganization({memoize:true, includeDisabled:true});
    } else {
      employees = await this.getEmployeesForOrganizationByOrganization({includeDisabled: true})
    }
    let employee = employees.find( e => e.id == employeeID);
    if(!employee) {
      employee = await this.api.GetEmployee(employeeID);
    }
    return employee;
  }

  @Memoize({ maxAge: TEN_MIN, preFetch: true })
  async getEmployeeByIDMemoize(employeeID: string): Promise<Employee> {
    return this.getEmployeeByID(employeeID, true);
  }

  async doesEmployeeExistByEmail(email: string): Promise<boolean> {
    if (!email) {
      return false;
    }
    const filter: ModelEmployeeFilterInput = { and: [{ email: { eq: email } }] };
    const list = await this.getAll<Employee>(this.api.ListEmployees, filter);
    return list.length > 0;
  }

  async getEmployeeByEmailMemoize(email: string, organizationID?: string): Promise<Employee> {
    if (!organizationID) {
      organizationID = (await this.getCurrentUser()).orgId;
    }
    return this._getEmployeeByEmailMemoize(email, organizationID);
  }

  @Memoize({ maxAge: TEN_MIN, preFetch: true })
  private async _getEmployeeByEmailMemoize(email: string, organizationID: string): Promise<Employee> {
    return this.getEmployeeByEmail(email, organizationID, true);
  }


  async getEmployeeByEmail(email: string, organizationID: string, memoize?: boolean): Promise<Employee> {
    if (!email) {
      return null;
    }
    let employees: Employee[];
    if (memoize) {
      employees = await this._getEmployeesForOrganizationByOrganizationMemoize(organizationID);
    } else {
      if (MEMOIZE_FN_MAP.has("_getEmployeesForOrganizationByOrganizationMemoize")) {
        MEMOIZE_FN_MAP.get("_getEmployeesForOrganizationByOrganizationMemoize").clear();
      }
      employees = await this._getEmployeesForOrganizationByOrganizationMemoize(organizationID);
    }
    return employees.find(e => e.email == email);
  }

  async isSuper(): Promise<boolean> {
    return (await this.getUserGroups()).includes("superadmin");
  }

  async isConsultant(): Promise<boolean> {
    const groups = await this.getUserGroups();
    return groups.filter(g => g.startsWith("CONSULTANT-")).length >= 1;
  }

  @Memoize({ maxAge: TEN_MIN, preFetch: true })
  async getEmployeeYTDTeamScoreMemoize(employeeID: string): Promise<number> {
    let subCount = 0;
    let sum = 0;
    const subordinates = await this.getSubordinatesByEmployeeIDForOrganization({ managerID: employeeID, memoize:true });
    for (const subordinate of subordinates) {
      const subScore = await this.getEmployeeYTDScoreMemoize(subordinate.orgId, subordinate.id);
      if (subScore && subScore > 0) {
        sum = sum + subScore;
        subCount++;
      }
    }
    let score = 0;
    if (subCount > 0) {
      score = round(sum / subCount, 2);
    }
    return score;
  }

  @Memoize({ maxAge: TEN_MIN, preFetch: true })
  async getEmployeeMTDTeamScoreMemoize(employeeID: string): Promise<number> {
    let subCount = 0;
    let sum = 0;
    const subordinates = await this.getSubordinatesByEmployeeIDForOrganization({ managerID: employeeID, memoize:true });
    for (const subordinate of subordinates) {
      const subScore = await this.getEmployeeMTDScoreMemoize(subordinate.orgId, subordinate.id);
      if (subScore && subScore > 0) {
        sum = sum + subScore;
        subCount++;
      }
    }
    let score = 0;
    if (subCount > 0) {
      score = round(sum / subCount, 2);
    }
    return score;
  }

  async getEmployeeMTDTeamTotalScoreMemoize(employeeID: string): Promise<number> {
    let subCount = 0;
    let sum = 0;
    const subordinates = await this.getSubordinatesByEmployeeIDForOrganization({ managerID: employeeID, memoize:true });
    for (const subordinate of subordinates) {
      const subScore = await this.getEmployeeMTDTotalScoreByEmployeeIDMemoize(subordinate.orgId, subordinate.id);
      if (subScore && subScore > 0) {
        sum = sum + subScore;
        subCount++;
      }
    }
    let score = 0;
    if (subCount > 0) {
      score = round(sum / subCount, 2);
    }
    return score;
  }

  @Memoize({ maxAge: TEN_MIN, preFetch: true })
  async getEmployeeYTDTeamTotalScoreMemoize(employeeID: string): Promise<number> {
    let subCount = 0;
    let sum = 0;
    const subordinates = await this.getSubordinatesByEmployeeIDForOrganization({ managerID: employeeID, memoize:true });
    for (const subordinate of subordinates) {
      const subScore = await this.getEmployeeYTDTotalScoreByEmployeeIDMemoize(subordinate.orgId, subordinate.id);
      if (subScore && subScore > 0) {
        sum = sum + subScore;
        subCount++;
      }
    }
    let score = 0;
    if (subCount > 0) {
      score = round(sum / subCount, 2);
    }
    return score;
  }

  async getMTDTotalObjectiveScoreForOrganization(organizationID?: string): Promise<TotalEmployeeObjectiveScore[]> {
    if (!organizationID) {
      organizationID = (await this.getCurrentUser()).orgId;
    }

    const startDate = moment().startOf("month").subtract(1, "year").endOf("month").add(1, "day");
    const endDate = moment().endOf("month");

    const scores = await this.getEmployeeScores(organizationID, ScoreTypes.MTDObjectiveScore, ScoreTypes.MTDObjectiveScore);
    const results = scores.filter(s => s.scoreStart >= startDate.toISOString() && s.scoreStart <= endDate.toISOString()).map(s => new TotalEmployeeObjectiveScore(s));

    const employeeScoreMap = new Map<string, TotalEmployeeObjectiveScore>();
    for (const employeeScore of results) {
      if (employeeScoreMap.has(employeeScore.employeeID)) {
        const existingScore = employeeScoreMap.get(employeeScore.employeeID);
        if (existingScore.scoreStart > employeeScore.scoreStart) {
          existingScore.scoreStart = employeeScore.scoreStart;
        }
        if (existingScore.scoreEnd < employeeScore.scoreStart) {
          existingScore.scoreEnd = employeeScore.scoreStart;
        }
        existingScore.score = round((existingScore.score + employeeScore.score) / 2, 2);
        if (employeeScore.employeeTotalObjectiveScoreDetails) {
          if (existingScore.employeeTotalObjectiveScoreDetails) {
            const employeeTotalObjectiveScoreDetails = existingScore.employeeTotalObjectiveScoreDetails;
            employeeTotalObjectiveScoreDetails.responses.push(...employeeScore.employeeTotalObjectiveScoreDetails.responses);
            existingScore.details = JSON.stringify(employeeTotalObjectiveScoreDetails);
          } else {
            existingScore.details = employeeScore.details;
          }
        }

        employeeScoreMap.set(employeeScore.employeeID, existingScore);
      } else {
        employeeScoreMap.set(employeeScore.employeeID, new TotalEmployeeObjectiveScore({
          id: employeeScore.employeeID,
          __typename: "EmployeeScore",
          organizationID: employeeScore.organizationID,
          employeeID: employeeScore.employeeID,
          score: employeeScore.score,
          scoreType: employeeScore.scoreType,
          scoreID: employeeScore.scoreID,
          scoreStart: employeeScore.scoreStart,
          scoreEnd: employeeScore.scoreStart,
          specifier: employeeScore.specifier,
          details: employeeScore.details
        }));
      }
    }
    const employeeScores = [];
    employeeScores.push(...employeeScoreMap.values());
    return employeeScores;
  }


  async getMTDTotalInthractionActionScoreForOrganization(organizationID?: string): Promise<TotalEmployeeEventScore[]> {
    if (!organizationID) {
      organizationID = (await this.getCurrentUser()).orgId;
    }

    const startDate = moment().startOf("month").subtract(1, "year").endOf("month").add(1, "day");
    const endDate = moment().endOf("month");

    const scores = await this.getEmployeeScores(organizationID, ScoreTypes.MTDEventScore, ScoreTypes.MTDEventScore);
    const results = scores.filter(s => s.scoreStart >= startDate.toISOString() && s.scoreStart <= endDate.toISOString()).map(s => new TotalEmployeeEventScore(s));

    const employeeScoreMap = new Map<string, TotalEmployeeEventScore>();
    for (const employeeScore of results) {
      if (employeeScoreMap.has(employeeScore.employeeID)) {
        const existingScore = employeeScoreMap.get(employeeScore.employeeID);
        if (existingScore.scoreStart > employeeScore.scoreStart) {
          existingScore.scoreStart = employeeScore.scoreStart;
        }
        if (existingScore.scoreEnd < employeeScore.scoreStart) {
          existingScore.scoreEnd = employeeScore.scoreStart;
        }
        existingScore.score = round((existingScore.score + employeeScore.score) / 2, 2);
        if (employeeScore.employeeTotalEventScoreDetails) {
          if ((existingScore as TotalEmployeeEventScore).employeeTotalEventScoreDetails) {
            const employeeTotalEventScoreDetails = (existingScore as TotalEmployeeEventScore).employeeTotalEventScoreDetails;
            employeeTotalEventScoreDetails.responses.push(...employeeScore.employeeTotalEventScoreDetails.responses);
            existingScore.details = JSON.stringify(employeeTotalEventScoreDetails);
          } else {
            existingScore.details = employeeScore.details;
          }
        }
        employeeScoreMap.set(employeeScore.employeeID, existingScore);
      } else {
        employeeScoreMap.set(employeeScore.employeeID, new TotalEmployeeEventScore({
          id: employeeScore.employeeID,
          __typename: "EmployeeScore",
          organizationID: employeeScore.organizationID,
          employeeID: employeeScore.employeeID,
          score: employeeScore.score,
          scoreType: employeeScore.scoreType,
          scoreID: employeeScore.scoreID,
          scoreStart: employeeScore.scoreStart,
          scoreEnd: employeeScore.scoreStart,
          specifier: employeeScore.specifier,
          details: employeeScore.details
        }));
      }
    }
    const employeeScores = [];
    employeeScores.push(...employeeScoreMap.values());
    return employeeScores;
  }

  async getEmployeeYTDTotalScoreByEmployeeIDMemoize(organizationID: string, employeeID: string): Promise<number> {
    const ytdEventScore = await this.getEmployeeYTDScoreMemoize(organizationID, employeeID);
    const ytdWeightedObjectiveScore = await this.getEmployeeYTDTotalObjectiveScoreMemoize(organizationID, employeeID);
    return EmployeeService.calculateScore(ytdEventScore, ytdWeightedObjectiveScore);
  }

  async getEmployeeMTDTotalScoreByEmployeeIDMemoize(organizationID: string, employeeID: string): Promise<number> {
    const ytdEventScore = await this.getEmployeeMTDScoreMemoize(organizationID, employeeID);
    const ytdWeightedObjectiveScore = await this.getEmployeeMTDTotalObjectiveScoreMemoize(organizationID, employeeID);
    return EmployeeService.calculateScore(ytdEventScore, ytdWeightedObjectiveScore);
  }

  static calculateScore(eventScore: number, objectiveScore: number): number {
    let score;
    if (objectiveScore != null) {
      if (objectiveScore > 0 && eventScore > 0) {
        score = round((eventScore + objectiveScore) / 2, 2);
      } else if (objectiveScore > 0) {
        score = objectiveScore;
      } else {
        score = eventScore;
      }
    } else {
      score = eventScore;
    }
    return score;
  }

  async buildEmployeeHierarchy(employee: Employee, manager?: IEmployeeImpl, level?: number): Promise<IEmployeeImpl> {
    if (level === undefined || level == null) {
      level = -1;
    }
    if (employee && !employee.disabled) {
      const iEmployee = new IEmployeeImpl(employee, []);
      if (level !== 0) {
        const subordinates = await this.getSubordinatesByEmployeeIDForOrganization({ managerID: employee.id, memoize:true });
        for (const s of subordinates) {
          const sub = await this.buildEmployeeHierarchy(s, iEmployee, (level - 1));
          if (sub != null) {
            iEmployee.subordinateEmployees.push(sub);
          }
        }
      }
      return iEmployee;
    } else {
      if (!employee) {
        console.debug("Manager", manager.id, "missing", employee);
      }
      return null;
    }
  }

  public getEmployeeColor(email: string): string {
    let color: string;
    if (EmployeeService.employeeColorMap.has(email)) {
      color = EmployeeService.employeeColorMap.get(email);
    } else {
      color = EmployeeService.getRandomColor();
      while (Array.from(EmployeeService.employeeColorMap.values()).includes(color)) {
        color = EmployeeService.getRandomColor();
      }
      EmployeeService.employeeColorMap.set(email, color);
    }
    return color;
  }

  clearMemoizedEmployee(employee: Employee): void {
    if (MEMOIZE_FN_MAP.has("_getEmployeesForOrganizationByOrganizationMemoize")) {
      MEMOIZE_FN_MAP.get("_getEmployeesForOrganizationByOrganizationMemoize").clear();
    }
    if (MEMOIZE_FN_MAP.has("getEmployeeDataMemoize")) {
      MEMOIZE_FN_MAP.get("getEmployeeDataMemoize").clear();
    }
    if (MEMOIZE_FN_MAP.has("getSubordinatesByEmployeeIDForOrganization")) {
      MEMOIZE_FN_MAP.get("getSubordinatesByEmployeeIDForOrganization").clear();
    }
    if (MEMOIZE_FN_MAP.has("getEmployeeByIDMemoize")) {
      MEMOIZE_FN_MAP.get("getEmployeeByIDMemoize").delete(employee.id);
    }
    if (MEMOIZE_FN_MAP.has("_getEmployeeByEmailMemoize")) {
      MEMOIZE_FN_MAP.get("_getEmployeeByEmailMemoize").delete(employee.email, employee.orgId);
    }
    if (MEMOIZE_FN_MAP.has("getEmployeeYTDTeamScoreMemoize")) {
      MEMOIZE_FN_MAP.get("getEmployeeYTDTeamScoreMemoize").delete(employee.id);
    }
    if (MEMOIZE_FN_MAP.has("getEmployeeMTDTeamScoreMemoize")) {
      MEMOIZE_FN_MAP.get("getEmployeeMTDTeamScoreMemoize").delete(employee.id);
    }
    if (MEMOIZE_FN_MAP.has("getEmployeeYTDTeamTotalScoreMemoize")) {
      MEMOIZE_FN_MAP.get("getEmployeeYTDTeamTotalScoreMemoize").delete(employee.id);
    }
    if (MEMOIZE_FN_MAP.has("getEmployeeScores")) {
      MEMOIZE_FN_MAP.get("getEmployeeScores").clear();
    }
  }

  async sendAccountInvite(employee: Employee): Promise<void> {
    await this.api.CreateUserAction({ employeeID: employee.id, organizationID: employee.orgId, action: "SEND_INVITE" });
  }

  async createEmployee(newEmployee: CreateEmployeeInput): Promise<Employee> {
    const empCheck = await this.doesEmployeeExistByEmail(newEmployee.email);
    let employee;
    if (!empCheck) {
      employee = await this.api.CreateEmployee(newEmployee);
      if (employee) {
        this.clearMemoizedEmployee(employee);
      }
    } else {
      MEMOIZE_FN_MAP.get("_getEmployeeByEmailMemoize").clear();
    }
    return employee;
  }

  async setReadiness(employee: Employee, readinessType: string | READINESS_TYPE, comments: string, opportunities?: string[]): Promise<[Employee, ReadinessHistory]> {
    const creator = await this.getCurrentEmployee();
    const _opportunities = [];
    if (opportunities) {
      for (const opportunity of opportunities) {
        if (opportunity && opportunity.trim().length > 0) {
          _opportunities.push(opportunity.trim());
        }
      }
    }
    const readiness = await this.api.CreateReadinessHistory({
      employeeID: employee.id,
      organizationID: employee.orgId,
      readiness: readinessType,
      comments: comments,
      opportunities: _opportunities,
      createdBy: creator.id,
      groups: [`HR-${employee.orgId}`]
    });

    const employeeResult = await this.api.UpdateEmployee({
      id: employee.id,
      readiness: readiness.id
    });
    this.clearMemoizedEmployee(employeeResult);
    return [employeeResult, readiness];
  }

  async updateLastOneOnOne(employeeID: string, updatedAt: string): Promise<Employee> {
    const employee = await this.api.UpdateEmployee({ id: employeeID, lastOneOnOne: updatedAt });
    this.clearMemoizedEmployee(employee);
    return employee;
  }

  async updateEmployee(input: UpdateEmployeeInput): Promise<Employee> {
    const employee = await this.api.UpdateEmployee(input);
    this.clearMemoizedEmployee(employee);
    return employee;
  }

  async createEmployeeCalendarCode(param: CreateEmployeeCalendarCodeInput): Promise<CreateEmployeeCalendarCodeMutation> {
    return await this.api.CreateEmployeeCalendarCode(param);
  }

  async createUserAction(param: CreateUserActionInput): Promise<CreateUserActionMutation> {
    return this.api.CreateUserAction(param);
  }

  async getCurrentEmployee(): Promise<Employee> {
    return this.getCurrentUser();
  }

  async getEmployeeDataMemoize(organizationID: string, employeeID: string, dataCode: string): Promise<EmployeeData> {
    const employeeData = await this._getEmployeeDataMemoize(organizationID, dataCode);
    return employeeData.find(ed => ed.employeeID == employeeID);
  }

  @Memoize({ maxAge: TEN_MIN, preFetch: true })
  private async _getEmployeeDataMemoize(organizationID: string, dataCode: string): Promise<EmployeeData[]> {
    const filter: ModelEmployeeDataFilterInput = {
      and: [
        { organizationID: { eq: organizationID } },
        { dataCode: { eq: dataCode } }
      ]
    };
    return this.getAllWithHashKeyAndRangeKey(this.api.ListEmployeeDatas, null, null, filter);
  }

  async getEmployeeData(organizationID: string, employeeID: string, dataCodes: string[], memoize?: boolean): Promise<EmployeeData[]> {
    const list: EmployeeData[] = [];
    for (const dataCode of dataCodes) {
      if (memoize) {
        const employeeData = await this.getEmployeeDataMemoize(organizationID, employeeID, dataCode);
        if (employeeData) {
          list.push(employeeData);
        }
      } else {
        list.push(...await this.getAllEmployeeData<EmployeeData>(organizationID, employeeID, { eq: dataCode }, null));
      }
    }
    return list;
  }

  private async getAllEmployeeData<T>(organizationID: string, employeeID: string, dataCode?: ModelStringKeyConditionInput, filter?: any, options?: GetAllOptions): Promise<T[]> {
    return this.getAllWithHashKeyAndRangeKey(this.api.ListEmployeeDatas, employeeID, dataCode, filter, options);
  }

  async putEmployeeData(employeeData: EmployeeData): Promise<EmployeeData> {

    if (MEMOIZE_FN_MAP.has("_getEmployeeDataMemoize")) {
      MEMOIZE_FN_MAP.get("_getEmployeeDataMemoize").delete(employeeData.organizationID, employeeData.dataCode);
    }

    if (!employeeData.createdAt) {
      try {
        return await this.api.CreateEmployeeData({
          employeeID: employeeData.employeeID,
          organizationID: employeeData.organizationID,
          dataCode: employeeData.dataCode,
          booleanValue: employeeData.booleanValue,
          intValue: employeeData.intValue,
          stringValue: employeeData.stringValue,
          groups: [`ADMIN-${employeeData.organizationID}`, `HR-${employeeData.organizationID}`]
        });
      } catch (e) {
        console.error(e);
      }
    }
    return await this.api.UpdateEmployeeData({
      employeeID: employeeData.employeeID,
      dataCode: employeeData.dataCode,
      booleanValue: employeeData.booleanValue,
      intValue: employeeData.intValue,
      stringValue: employeeData.stringValue
    });
  }

  async getConsultantsForOrganizationByOrganization(organizationID: string): Promise<ConsultantAssociation[]> {
    return (await this.getAllConsultantAssociations<GetConsultantAssociationQuery>(this.api.ListConsultantAssociations, null, organizationID))
      .map(association => ConsultantAssociation.constructFromCreateMutation(association));
  }

  async createConsultantAssociation(newAssociation: ConsultantAssociation): Promise<ConsultantAssociation> {
    const input: CreateConsultantAssociationInput = {
      organizationID: newAssociation.organizationID,
      employeeID: newAssociation.employeeID,
      incentive: newAssociation.incentive
    };

    if (newAssociation.options) {
      input.options = JSON.stringify(newAssociation.options);
    }

    const result = await this.api.CreateConsultantAssociation(input);
    if (result) {
      return ConsultantAssociation.constructFromCreateMutation(result);
    }
    return undefined;
  }

  async updateConsultantAssociation(association: ConsultantAssociation): Promise<ConsultantAssociation> {
    const input: UpdateConsultantAssociationInput = {
      organizationID: association.organizationID,
      employeeID: association.employeeID,
      incentive: association.incentive
    };

    if (association.options) {
      input.options = JSON.stringify(association.options);
    }

    if (association.updatedAt) {
      input.updatedAt = association.updatedAt;
    }

    if (association.status) {
      input.status = association.status;
    }

    const result = await this.api.UpdateConsultantAssociation(input);
    if (result) {
      return ConsultantAssociation.constructFromCreateMutation(result);
    }
    return undefined;

  }

  async deleteConsultantAssociation(consultantAssociation: ConsultantAssociation): Promise<boolean> {
    const association = await this.api.DeleteConsultantAssociation({
      organizationID: consultantAssociation.organizationID,
      employeeID: consultantAssociation.employeeID
    });
    return !!association;
  }

  async getConsultantAssociationsByEmployee(employeeID: string, includeDisabled: boolean): Promise<ConsultantAssociation[]> {
    const filter: ModelConsultantAssociationFilterInput = {
      and: [
        { employeeID: { eq: employeeID } }
      ]
    };

    if (!includeDisabled) {
      filter.and.push({ or: [{ status: { ne: "DISABLED" } }, { status: { attributeExists: false } }] });
    }

    return (await this.getAllConsultantAssociations<GetConsultantAssociationQuery>(this.api.ListConsultantAssociations, filter))
      .map(association => ConsultantAssociation.constructFromCreateMutation(association));
  }

  private async getAllConsultantAssociations<T>(fn: Function, filter: any, organizationID?: string, employeeID?: ModelStringKeyConditionInput, options?: GetAllOptions): Promise<T[]> {
    let results = [];
    let response;
    const limit = options?.limit ? options.limit : 1000;
    do {
      response = await fn(organizationID, employeeID, filter, limit, (response?.nextToken ? response.nextToken : null));
      results.push(...response.items);
    } while (response.nextToken);
    return results.filter((elm, pos, array) => {
      return array.indexOf(elm) == pos;
    });
  }

  async getConsultantFavorites(organizationID: string): Promise<ConsultantFavorites> {
    const employee = await this.getCurrentEmployee();
    return this.api.GetConsultantFavorites(organizationID, employee.id);
  }

  async getHRFavorites(): Promise<ConsultantFavorites> {
    const employee = await this.getCurrentEmployee();
    return this.api.GetConsultantFavorites(`HR-${employee.orgId}`, employee.id);
  }

  async updateConsultantFavorites(consultantFavorites: ConsultantFavorites): Promise<ConsultantFavorites> {
    const favorites: UpdateConsultantFavoritesInput = {
      favorites: consultantFavorites.favorites,
      employeeID: consultantFavorites.employeeID,
      organizationID: consultantFavorites.organizationID
    };
    return this.api.UpdateConsultantFavorites(favorites);
  }

  async createConsultantFavorites(consultantFavorites: ConsultantFavorites): Promise<ConsultantFavorites> {
    const employee = await this.getCurrentEmployee();
    consultantFavorites.employeeID = employee.id;
    return this.api.CreateConsultantFavorites(consultantFavorites);
  }

  async getEmployeeImageMemoize(employeeID: string, organizationID: string): Promise<EmployeeImageInterface> {
    if (!employeeID) {
      return null;
    }
    const images = await this.getEmployeeImagesByOrganizationMemoize(organizationID);
    return images.find(i => i.employeeID == employeeID);
  }

  @Memoize({maxAge: TEN_MIN, preFetch: true})
  async getEmployeeImagesByOrganizationMemoize(organizationID: string): Promise<EmployeeImageInterface[]> {
    const filter: ModelEmployeeImageFilterInput = {
      organizationID: { eq: organizationID }
    };
    return this.getAllWithHashKey<EmployeeImageInterface>(this.api.ListEmployeeImages, null, filter);
  }

  async putEmployeeImage(userImageData: EmployeeImageInterface): Promise<EmployeeImageInterface> {
    if (userImageData.employeeID && userImageData.organizationID) {
      if (MEMOIZE_FN_MAP.has("getEmployeeImagesByOrganizationMemoize")) {
        MEMOIZE_FN_MAP.get("getEmployeeImagesByOrganizationMemoize").clear();
      }
      userImageData.groups = [`ADMIN-${userImageData.organizationID}`];
      let recordToUpdate;
      try {
        recordToUpdate = await this.api.GetEmployeeImage(userImageData.employeeID);
      } catch (e) {
        // Ingnore missing record
      }

      if (recordToUpdate) {
        return this.api.UpdateEmployeeImage({
          employeeID: userImageData.employeeID,
          organizationID: userImageData.organizationID,
          image: userImageData.image,
          imageIdentityId: userImageData.imageIdentityId,
          groups: userImageData.groups
        } as UpdateEmployeeImageInput);
      }
      return this.api.CreateEmployeeImage({
        employeeID: userImageData.employeeID,
        organizationID: userImageData.organizationID,
        image: userImageData.image,
        imageIdentityId: userImageData.imageIdentityId,
        groups: userImageData.groups
      } as CreateEmployeeImageInput);
    }
    console.error("putEmployeeImage: Missing employeeID or organizationID");
    return null;
  }

  async getEmployeeReadiness(readinessHistoryID: string): Promise<ReadinessHistory> {
    return this.api.GetReadinessHistory(readinessHistoryID);
  }

  async getEmployeeReadinessHistories(organizationID?: string): Promise<ReadinessHistory[]> {
    if (!organizationID) {
      organizationID = (await this.getCurrentUser()).orgId;
    }
    const filter: ModelReadinessHistoryFilterInput = {
      organizationID: { eq: organizationID }
    };
    return this.getAll<ReadinessHistory>(this.api.ListReadinessHistorys, filter);
  }

  async getEmployeeReadinessHistory(employeeID: string): Promise<ReadinessHistory[]> {
    const filter: ModelReadinessHistoryFilterInput = {
      employeeID: { eq: employeeID }
    };
    return this.getAll<ReadinessHistory>(this.api.ListReadinessHistorys, filter);
  }

  async getEmployeeReadinessHistoryForOrganization(organizationID?: string): Promise<ReadinessHistory[]> {
    if (!organizationID) {
      organizationID = (await this.getCurrentUser()).orgId;
    }
    const filter: ModelReadinessHistoryFilterInput = {
      organizationID: { eq: organizationID }
    };
    return this.getAll<ReadinessHistory>(this.api.ListReadinessHistorys, filter);
  }

  async updateHRFavorites(hrFavorites: ConsultantFavorites): Promise<ConsultantFavorites> {
    const employee = await this.getCurrentEmployee();
    hrFavorites.organizationID = `HR-${employee.orgId}`;
    return this.updateConsultantFavorites(hrFavorites);
  }

  async createHRFavorites(favorites: string[]): Promise<ConsultantFavorites> {
    const employee = await this.getCurrentEmployee();
    return this.createConsultantFavorites({
      organizationID: `HR-${employee.orgId}`,
      employeeID: employee.id,
      favorites: favorites
    });
  }

  async getMTDTotalEmployeeObjectiveScoresByEmployee(organizationID: string, employeeID: string): Promise<TotalEmployeeObjectiveScore[]> {
    const scores = await this.getEmployeeScores(organizationID, ScoreTypes.MTDObjectiveScore, ScoreTypes.MTDObjectiveScore);
    return scores.filter(s => s.scoreStart >=  moment().startOf("month").subtract(1, "year").endOf("month").add(1, "day").format("YYYY-MM-DD")).filter(s => s.employeeID == employeeID).map(s => new TotalEmployeeObjectiveScore(s));
  }

  async getMTDTotalEmployeeObjectiveScoresByOrganization(organizationID?: string): Promise<TotalEmployeeObjectiveScore[]> {
    if (!organizationID) {
      const employee = await this.getCurrentEmployee();
      organizationID = employee.orgId;
    }

    const scores = await this.getEmployeeScores(organizationID, ScoreTypes.MTDObjectiveScore, ScoreTypes.MTDObjectiveScore);
    return scores.filter(s => s.scoreStart >=  moment().startOf("month").subtract(1, "year").endOf("month").add(1, "day").format("YYYY-MM-DD")).map(s => new TotalEmployeeObjectiveScore(s));
  }

  async getYTDTotalEmployeeObjectiveScoresByEmployee(organizationID: string, employeeID: string): Promise<TotalEmployeeObjectiveScore[]> {
    const scores = await this.getEmployeeScores(organizationID, ScoreTypes.YTDObjectiveScore, ScoreTypes.YTDObjectiveScore);
    return scores.filter(s => s.scoreStart >=  moment().startOf("month").subtract(1, "year").endOf("month").add(1, "day").format("YYYY-MM-DD")).filter(s => s.employeeID == employeeID).map(s => new TotalEmployeeObjectiveScore(s));
  }

  async getYTDTotalEmployeeObjectiveScoresByOrganization(organizationID?: string): Promise<TotalEmployeeObjectiveScore[]> {
    if (!organizationID) {
      const employee = await this.getCurrentEmployee();
      organizationID = employee.orgId;
    }

    const scores = await this.getEmployeeScores(organizationID, ScoreTypes.YTDObjectiveScore, ScoreTypes.YTDObjectiveScore);
    return scores.filter(s => s.scoreStart >=  moment().startOf("month").subtract(1, "year").endOf("month").add(1, "day").format("YYYY-MM-DD")).map(s => new TotalEmployeeObjectiveScore(s));
  }

  async getYTDOrganizationExperienceScoresByOrganization(scoreType: ScoreTypes, organizationID?: string): Promise<EmployeeScore[]> {
    if (!organizationID) {
      const employee = await this.getCurrentEmployee();
      organizationID = employee.orgId;
    }

    const scores = await this.getEmployeeScores(organizationID, scoreType, scoreType);
    return scores.filter(s => s.scoreStart >= moment().startOf("year").format("YYYY-MM-DD"));
  }

  async getEmployeeEventScores(organizationID: string, employeeID: string, scoreId: string, start?: string): Promise<TotalEmployeeEventScore[]> {
    let scores = await this.getEmployeeScores(organizationID, ScoreTypes.EventScore, scoreId);
    scores = scores.filter(s => s.employeeID == employeeID);
    if (start) {
      scores = scores.filter(s => s.scoreStart >= start);
    }
    return scores.map(s => new TotalEmployeeEventScore(s));
  }

  async getYTDTotalInthractionActionScoreForOrganization(organizationID?: string): Promise<TotalEmployeeEventScore[]> {
    if (!organizationID) {
      organizationID = (await this.getCurrentUser()).orgId;
    }
    const scores = await this.getEmployeeScores(organizationID, ScoreTypes.YTDEventScore, ScoreTypes.YTDEventScore);
    return scores.filter(s => s.scoreStart >= `${moment().year()}`).map(s => new TotalEmployeeEventScore(s));
  }

  async getYTDTotalObjectiveScoreForOrganization(organizationID?: string): Promise<TotalEmployeeObjectiveScore[]> {
    if (!organizationID) {
      organizationID = (await this.getCurrentUser()).orgId;
    }
    const scores = await this.getEmployeeScores(organizationID, ScoreTypes.YTDObjectiveScore);
    return scores.filter(s => s.scoreStart >= `${moment().year()}`).map(s => new TotalEmployeeObjectiveScore(s));
  }

  async getEmployeeYTDTotalObjectiveScoreMemoize(organizationID: string, employeeID: string): Promise<number> {

    const scores = await this.getEmployeeScores(organizationID, ScoreTypes.YTDObjectiveScore, ScoreTypes.YTDObjectiveScore);
    const result = scores.filter(s => s.employeeID == employeeID).filter(s => s.scoreStart >= `${moment().year()}`)[0];

    const employeeScore = result ? new TotalEmployeeObjectiveScore(result) : undefined;
    if (employeeScore?.employeeTotalObjectiveScoreDetails?.responses?.length) {
      const list = employeeScore.employeeTotalObjectiveScoreDetails.responses;
      return round(list.reduce((a, b) => a + b.score, 0) / list.length, 2);
    }
    return (employeeScore ? employeeScore.score : 0);
  }

  async getEmployeeMTDTotalObjectiveScoreMemoize(organizationID: string, employeeID: string): Promise<number> {
    const scores = await this.getEmployeeScores(organizationID, ScoreTypes.MTDObjectiveScore, ScoreTypes.MTDObjectiveScore);
    const employeeScores = scores.filter(s => s.employeeID == employeeID && s.scoreStart >= moment().startOf("month").subtract(1, "year").endOf("month").add(1, "day").toISOString()).map(s => new TotalEmployeeObjectiveScore(s));

    if (employeeScores.length) {
      const list = employeeScores.map(e => e.employeeTotalObjectiveScoreDetails).reduce((a: EmployeeObjectiveScoreDetails[], b) => a.concat(b ? b.responses : []), []);
      if (list.length) {
        return round(list.reduce((a, b) => a + b.score, 0) / list.length, 2);
      }
      return round((employeeScores.map(s => s.score).reduce((a, b) => a + b)) / employeeScores.length, 2);
    }
    return 0;
  }

  async getEmployeeMTDScoreMemoize(organizationID: string, employeeID: string): Promise<number> {
    const scores = await this.getEmployeeScores(organizationID, ScoreTypes.MTDEventScore, ScoreTypes.MTDEventScore);
    const employeeScores = scores.filter(s => s.employeeID == employeeID && s.scoreStart >= moment().startOf("month").subtract(1, "year").endOf("month").add(1, "day").toISOString()).map(s => new TotalEmployeeEventScore(s));

    if (employeeScores.length) {
      const list = employeeScores.map(e => e.employeeTotalEventScoreDetails).reduce((a: EmployeeEventScoreDetails[], b) => a.concat(b ? b.responses : []), []);
      if (list.length) {
        return round(list.reduce((a, b) => a + b.score, 0) / list.length, 2);
      }
      return round((employeeScores.map(s => s.score).reduce((a, b) => a + b)) / employeeScores.length, 2);
    }
    return 0;
  }

  async getEmployeeEventScoreMemoize(organizationID: string, employeeID: string, eventID: string): Promise<number> {
    const scores = await this.getEmployeeScores(organizationID, ScoreTypes.EventScore, eventID);
    const result = scores.filter(s => s.employeeID == employeeID)[0];

    const employeeScore = result ? new TotalEmployeeEventScore(result) : null;
    if (employeeScore?.employeeTotalEventScoreDetails?.responses?.length) {
      return round(employeeScore.employeeTotalEventScoreDetails.responses.reduce((a, b) => a + b.score, 0) / employeeScore.employeeTotalEventScoreDetails.responses.length, 2);
    }
    return (employeeScore ? employeeScore.score : 0);
  }

  async getEmployeeObjectiveScoresMemoize(organizationID: string, employeeID: string, scoreStart: string, objectiveAssignmentID: string, scoreEnd?: string): Promise<EmployeeObjectiveScore[]> {
    const scores = await this.getEmployeeScores(organizationID, ScoreTypes.ObjectiveScore, objectiveAssignmentID);
    return  scores.filter(s => s.employeeID == employeeID && s.scoreStart >= scoreStart).map(s => new EmployeeObjectiveScore(s));
  }

  async getEmployeeYTDScoreMemoize(organizationID: string, employeeID: string): Promise<number> {
    const scores = await this.getEmployeeScores(organizationID, ScoreTypes.YTDEventScore, ScoreTypes.YTDEventScore);
    const result = scores.filter(s => s.employeeID == employeeID && s.scoreStart >= `${moment().year()}`)[0];

    const employeeScore = result ? new TotalEmployeeEventScore(result) : null;
    if (employeeScore?.employeeTotalEventScoreDetails?.responses?.length) {
      return round(employeeScore.employeeTotalEventScoreDetails.responses.reduce((a, b) => a + b.score, 0) / employeeScore.employeeTotalEventScoreDetails.responses.length, 2);
    }
    return (employeeScore ? employeeScore.score : 0);
  }

  async getEmployeeEventScoresMemoize(organizationID: string, employeeID: string, scoreStart: string, scoreEnd?: string): Promise<TotalEmployeeEventScore[]> {
    let scores = await this.getEmployeeScores(organizationID, ScoreTypes.EventScore);
    if (scoreEnd) {
      scores = scores.filter(s => s.scoreStart <= scoreEnd);
    }
    return scores.filter(s => s.employeeID == employeeID && s.scoreStart >= scoreStart).map(s => new TotalEmployeeEventScore(s));
  }

  @Memoize({maxAge: TEN_MIN, preFetch:true})
  private async getEmployeeScores(organizationID: string, scoreType: ScoreTypes, scoreID?: string): Promise<EmployeeScore[]> {

    const rangeFilter: ModelEmployeeScorePrimaryCompositeKeyConditionInput = {
      beginsWith: {scoreType}
    }
    if (scoreID) {
      rangeFilter.beginsWith.scoreID = scoreID;
    }

    return this.getAllWithHashKeyAndRangeKey<EmployeeScore>(this.api.ListEmployeeScores, organizationID, rangeFilter, null, { limit: 100000 });
  }

}
