<h1 mat-dialog-title>Edit Consultant Association</h1>
<form [formGroup]="editFG">
  <div mat-dialog-content>
    <mat-form-field class="full-width" *ngIf="employee">
      <mat-label>Name</mat-label>
      <input readonly matInput value="{{employee.firstName}} {{employee.lastName}}">
    </mat-form-field>

    <mat-form-field class="full-width" *ngIf="organization">
      <mat-label>Consulting for</mat-label>
      <input readonly matInput value="{{organization.orgName}}">
    </mat-form-field>

    <mat-slide-toggle class="full-width" formControlName="isDisabled">Disabled</mat-slide-toggle>

    <mat-slide-toggle *inthractionShowSuper="true" class="full-width" formControlName="isIncentivized">Receive Incentives</mat-slide-toggle>
  </div>
  <div mat-dialog-actions>
    <button (click)="onCancelClick()" mat-button>Cancel</button>
    <button #submitBtn type="submit" (click)="onSaveClick(editFG.value, submitBtn)" cdkFocusInitial mat-raised-button color="primary" [disabled]="editFG.invalid">Save</button>
    <div class="divider"></div>
    <button #deleteBtn *inthractionShowSuper="true" type="submit" (click)="onDeleteClick(deleteBtn)" mat-button>Delete</button>
  </div>
</form>
