import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Employee, EmployeeImageInterface, OrganizationShoutOutType, Survey } from "@inthraction/data-models";
import { EmployeeService, ShoutOutService } from "@inthraction/services";

@Component({
  selector: "inthraction-insphration-result-dialog",
  templateUrl: "./insphration-result-dialog.component.html",
  styleUrls: ["./insphration-result-dialog.component.scss"]
})
export class InsphrationResultDialogComponent implements OnInit {
  public participant: Employee;
  public respondent: Employee;
  public survey: Survey;
  public inspHRation: OrganizationShoutOutType;
  participantImage: EmployeeImageInterface;
  respondentImage: EmployeeImageInterface;

  constructor(
    private dialogRef: MatDialogRef<InsphrationResultDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: InspHRationResultsData,
    private employeeService: EmployeeService,
    private shoutOutService: ShoutOutService
  ) {
  }

  async ngOnInit() {
    this.participant = await this.employeeService.getEmployeeByEmailMemoize(this.data.survey.participantEmail);
    this.respondent = await this.employeeService.getEmployeeByEmailMemoize(this.data.survey.respondentEmail);
    this.participantImage = await this.employeeService.getEmployeeImageMemoize(this.participant.id, this.participant.orgId);
    this.respondentImage = await this.employeeService.getEmployeeImageMemoize(this.respondent.id, this.respondent.orgId);
    this.inspHRation = await this.shoutOutService.getMemoizedOrganizationShoutOutType(this.participant.orgId, this.data.survey.objectID);
    this.survey = this.data.survey;
  }

}

export interface InspHRationResultsData {
  survey: Survey;
}
