import { Component, Input, OnInit } from "@angular/core";
import { EmployeeService, NoteService, StandupService } from "@inthraction/services";
import { Employee, Note, StandupSurvey, StandupSurveyConfiguration } from "@inthraction/data-models";
import { QuestionsMap } from "@inthraction/data-mappers";
import { NoteTypes } from "@inthraction/codes";

@Component({
  selector: "inthraction-standup-history",
  templateUrl: "./standup-history.component.html",
  styleUrls: ["./standup-history.component.scss"]
})
export class StandupHistoryComponent implements OnInit {

  private currentUser: Employee;
  private subordinates: Employee[] = [];
  private configuration: StandupSurveyConfiguration;

  @Input() manager?: Employee;

  surveysByDateMap = new Map<string, StandupSurvey[]>();
  notesMap = new Map<string, Note>();

  initialized = false;
  selectedSurveyDate: string;
  index = 0;

  constructor(
    private employeeService: EmployeeService,
    private standupService: StandupService,
    private noteService: NoteService
  ) {
  }

  async ngOnInit() {
    if (this.manager) {
      this.currentUser = this.manager;
    } else {
      this.currentUser = await this.employeeService.getCurrentEmployee();
    }
    this.subordinates = await this.employeeService.getSubordinatesByEmployeeIDForOrganization({managerID: this.currentUser.id, memoize:true});
    this.configuration = await this.standupService.getStandupConfigurationByManager(this.currentUser);

    const surveys: StandupSurvey[] = await this.standupService.getAllStandupSurveysForConfiguration(this.configuration.id);
    surveys.filter(s => this.subordinates.map(s => s.id).includes(s.respondentID));
    for (const survey of surveys) {
      if (!this.surveysByDateMap.has(survey.surveyDate)) {
        this.surveysByDateMap.set(survey.surveyDate, []);
      }
      this.surveysByDateMap.get(survey.surveyDate).push(survey);
    }
    this.selectedSurveyDate = this.surveyDates[0];
    await this.initializeNotesForSelectedSurveyDate(this.selectedSurveyDate);
    this.initialized = true;
  }

  async initializeNotesForSelectedSurveyDate(surveyDate: string) {
    for (const survey of this.surveysByDateMap.get(surveyDate)) {
      if (!this.notesMap.has(survey.id)) {
        const notes = await this.noteService.getOneOnOneNotesByObjectIDMemoize(survey.id, NoteTypes.STANDUP);
        if (notes?.length) {
          this.notesMap.set(survey.id, notes[0]);
        } else {
          this.notesMap.set(survey.id, null);
        }
      }
    }
  }

  getSubordinate(id: string): Employee {
    return this.subordinates.find(s => s.id == id);
  }

  getStandupQuestion(configuration: StandupSurveyConfiguration, questionMap: QuestionsMap): string {
    const question = configuration.questions.find(q => q.id == questionMap.id);
    return question.questionTextArray[questionMap.version];
  }

  get surveyDates(): string[] {
    const keys = [];
    keys.push(...this.surveysByDateMap.keys());
    return keys;
  }

  async moveSurvey(direction: string, index: number) {

    if ((direction == "up" && index == 0) || (direction == "down" && index == this.surveyDates.length - 1)) {
      return;
    }
    if (direction == "up") {
      index--;
    } else {
      index++;
    }
    this.selectedSurveyDate = this.surveyDates[index];
    await this.initializeNotesForSelectedSurveyDate(this.selectedSurveyDate);
    this.index = index;
  }
}
