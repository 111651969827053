<div class="survey-page-container objective-survey-page-container">
  <div></div>
  <mat-card appearance="outlined" *ngIf="survey && employee">
    <mat-card-header>
      <div class="employee-info">
        <mat-card-title>{{employee.firstName}}&nbsp;{{employee.lastName}}</mat-card-title>
        <mat-card-title *ngIf="survey.surveyType == SURVEY_TYPES.NEW_HIRE_14_DAY">{{ONBOARDING_14_SURVEY_TITLE}}</mat-card-title>
        <mat-card-title *ngIf="survey.surveyType == SURVEY_TYPES.NEW_POSITION_60_DAY">{{POSITION_60_SURVEY_TITLE}}</mat-card-title>
        <mat-card-title *ngIf="survey.surveyType == SURVEY_TYPES.NEW_MANAGER_30_DAY">{{NEW_MANAGER_30_SURVEY_TITLE}}</mat-card-title>
        <mat-card-title *ngIf="survey.surveyType == SURVEY_TYPES.NEW_HIRE_90_DAY">{{ONBOARDING_90_SURVEY_TITLE}}</mat-card-title>
        <mat-card-title *ngIf="survey.surveyType == SURVEY_TYPES.NEW_MANAGER_90_DAY">{{NEW_MANAGER_90_SURVEY_TITLE}}</mat-card-title>
      </div>
    </mat-card-header>
    <form (ngSubmit)="submitSurvey(surveyForm.value, submitButtonElement)" [formGroup]="surveyForm" autocomplete="off" novalidate>
      <mat-card-content>
        <div class="survey-container">
          <label id="survey-radio-group-label">
            <span *ngIf="survey.surveyType == SURVEY_TYPES.NEW_HIRE_14_DAY">{{ONBOARDING_14_SURVEY_DESCRIPTION}}</span>
            <span *ngIf="survey.surveyType == SURVEY_TYPES.NEW_POSITION_60_DAY">{{POSITION_60_SURVEY_DESCRIPTION}}</span>
            <span *ngIf="survey.surveyType == SURVEY_TYPES.NEW_MANAGER_30_DAY">{{NEW_MANAGER_30_SURVEY_DESCRIPTION}}</span>
            <span *ngIf="survey.surveyType == SURVEY_TYPES.NEW_HIRE_90_DAY">{{ONBOARDING_90_SURVEY_DESCRIPTION}}</span>
            <span *ngIf="survey.surveyType == SURVEY_TYPES.NEW_MANAGER_90_DAY">{{NEW_MANAGER_90_SURVEY_DESCRIPTION}}</span>
          </label>
          <div class="survey-group">
            <ng-container *ngFor="let id of surveyResponseTypes">
              <div id="{{id}}-btn" (click)="surveyValue.setValue(surveyResponseValues[id])"
                   [className]="surveyValue.value === surveyResponseValues[id] ? 'selected':''"
                   [matTooltipShowDelay]="500" [matTooltipPosition]="'left'"
                   matTooltip="{{surveyResponseTips[id]}}">
                <button mat-mini-fab id="{{id}}-icon" type="button">
                  <mat-icon svgIcon="{{surveyResponseIcons[id]}}"></mat-icon>
                </button>
                <span>{{surveyResponseLabels[id]}}</span>
              </div>
            </ng-container>
          </div>
          <mat-form-field class="full-width">
            <mat-label>Comments</mat-label>
            <textarea matInput formControlName="feedback" id="feedback" maxlength="500" name="Comments" rows="3"></textarea>
            <mat-error *ngIf="hasError('feedback', 'required')">Comments are required</mat-error>
          </mat-form-field>
        </div>
      </mat-card-content>
      <mat-card-actions>
        <button #submitButtonElement [disabled]="!surveyForm.valid" color="primary" mat-raised-button type="submit">Submit</button>
      </mat-card-actions>
    </form>
    <mat-card-content class="footer">
      <span class="btm-cnr-right">1 of {{remainingSurveysCount}}</span>
      <mat-card-subtitle *ngIf="survey.surveyType == SURVEY_TYPES.NEW_HIRE_14_DAY">{{NEW_HIRE_14_DAY_FOOTER}}</mat-card-subtitle>
      <mat-card-subtitle *ngIf="survey.surveyType == SURVEY_TYPES.NEW_POSITION_60_DAY">{{NEW_POSITION_60_DAY_FOOTER}}</mat-card-subtitle>
      <mat-card-subtitle *ngIf="survey.surveyType == SURVEY_TYPES.NEW_MANAGER_30_DAY">{{NEW_MANAGER_30_DAY_FOOTER}}</mat-card-subtitle>
      <mat-card-subtitle *ngIf="survey.surveyType == SURVEY_TYPES.NEW_HIRE_90_DAY">{{NEW_HIRE_90_DAY_FOOTER}}</mat-card-subtitle>
      <mat-card-subtitle *ngIf="survey.surveyType == SURVEY_TYPES.NEW_MANAGER_90_DAY">{{NEW_MANAGER_90_DAY_FOOTER}}</mat-card-subtitle>
    </mat-card-content>
  </mat-card>
  <div></div>
</div>
