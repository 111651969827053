import { Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from "@angular/core";
import { EmployeeImageInterface, EventSurvey, ExternalSurvey } from "@inthraction/data-models";
import {
  EventSurveyResponseOptions,
  OBJECTIVE_SURVEY_RESPONSE_ICONS,
  OBJECTIVE_SURVEY_RESPONSE_TYPES,
  OBJECTIVE_SURVEY_RESPONSE_VALUES
} from "@inthraction/codes";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";
import { Subscription } from "rxjs";
import { MatButton } from "@angular/material/button";
import * as moment from "moment";
import { ExternalService } from "@inthraction/services";
import { ActivatedRoute } from "@angular/router";
import { OBJECTIVE_SURVEY_RESPONSE_LABELS, OBJECTIVE_SURVEY_RESPONSE_TIPS } from "@inthraction/labels";

@Component({
  selector: "app-external-survey",
  templateUrl: "./external-survey.component.html",
  styleUrls: ["./external-survey.component.scss"]
})
export class ExternalSurveyComponent implements OnInit, OnDestroy, OnChanges {

  errorMessage = "Please try again.";
  attendeeImage: EmployeeImageInterface;
  surveyResponseOptionsValues = Object.values(EventSurveyResponseOptions).filter(e => typeof (e) === "number").reverse();
  surveyForm: FormGroup;
  private feedback = new FormControl("", []);
  private surveyValue = new FormControl("", [Validators.required]);

  readonly objectiveSurveyResponseTypes = Object.assign([], OBJECTIVE_SURVEY_RESPONSE_TYPES).reverse();
  readonly objectiveSurveyResponseLabels = OBJECTIVE_SURVEY_RESPONSE_LABELS;
  readonly objectiveSurveyResponseValues = OBJECTIVE_SURVEY_RESPONSE_VALUES;
  readonly objectiveSurveyResponseTips = OBJECTIVE_SURVEY_RESPONSE_TIPS;
  readonly objectiveSurveyResponseIcons = OBJECTIVE_SURVEY_RESPONSE_ICONS;

  surveyResponseOptionsTips = EventSurvey.SURVEY_RESPONSE_OPTIONS_TIPS;
  private subscriptions: Subscription[] = [];

  static surveyIDParameterName = "survey-id";
  static surveyResponseParameterName = "survey-response";

  @Input() survey: ExternalSurvey;
  @Input() surveyResponse?: number;

  @ViewChild("didNotAttendBtn") didNotAttendBtn: ElementRef<MatButton | HTMLButtonElement>;
  @ViewChild("submitButtonElement") submitButtonElm: ElementRef;
  invalidSurvey: boolean = false;

  constructor(
    protected toastr: ToastrService,
    protected iconRegistry: MatIconRegistry,
    protected sanitizer: DomSanitizer,
    private externalService: ExternalService,
    private route: ActivatedRoute
  ) {
    iconRegistry.addSvgIcon("north", sanitizer.bypassSecurityTrustResourceUrl("assets/font-icons/arrow_north-24px.svg"));
    iconRegistry.addSvgIcon("north-east", sanitizer.bypassSecurityTrustResourceUrl("assets/font-icons/arrow_north_east-24px.svg"));
    iconRegistry.addSvgIcon("east", sanitizer.bypassSecurityTrustResourceUrl("assets/font-icons/arrow_east-24px.svg"));
    iconRegistry.addSvgIcon("south-east", sanitizer.bypassSecurityTrustResourceUrl("assets/font-icons/arrow_south_east-24px.svg"));
    iconRegistry.addSvgIcon("south", sanitizer.bypassSecurityTrustResourceUrl("assets/font-icons/arrow_south-24px.svg"));

    this.surveyForm = new FormGroup(
      {
        surveyValue: this.surveyValue,
        feedback: this.feedback
      });
  }

  async ngOnInit() {
    if (!this.route.snapshot.params || !this.route.snapshot.params["survey-id"]) {
      return;
    }

    this.subscriptions.push(this.route.paramMap.subscribe(async queryParams => {
      const surveyID = queryParams.get(ExternalSurveyComponent.surveyIDParameterName);
      if (!this.survey || this.survey.id !== surveyID) {
        this.survey = await this.externalService.getSurvey(surveyID);
        if (!this.survey || this.survey.responseReceived) {
          this.invalidSurvey = true;
          return;
        }
        await this.initialize(this.survey, this.surveyResponse);
      }
    }));
    this.subscriptions.push(this.surveyForm.get("surveyValue").valueChanges.subscribe(surveyValue => {
      if (surveyValue === OBJECTIVE_SURVEY_RESPONSE_VALUES.NEEDS_IMPROVEMENT || surveyValue === OBJECTIVE_SURVEY_RESPONSE_VALUES.EXCEEDS) {
        this.surveyForm.get("feedback").setValidators([Validators.required]);
        this.surveyForm.get("feedback").markAsTouched();
      } else {
        this.surveyForm.get("feedback").setValidators(null);
      }
      this.surveyForm.get("feedback").updateValueAndValidity();

      /*
       * Move focus to submit button after selecting an option to allow enter button to submit the form.
       * This is necessary because the options are using a custom styling that makes them not part of the form.
       */
      if (this.submitButtonElm) {
        setTimeout((elementRef) => {
            elementRef.nativeElement.focus();
          }, 100,
          // @ts-ignore //Submit button is wrapped with mat-button and is not a standard element.
          this.submitButtonElm._elementRef);
      }

    }));


    await this.initialize(this.survey, this.surveyResponse);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.survey && changes.survey.currentValue) {
      this.surveyForm.reset();
      this.attendeeImage = null;
      this.initialize(changes.survey.currentValue, this.surveyResponse);
    }
    if (changes.surveyResponse && changes.surveyResponse.currentValue) {
      this.initializeResponse(changes.surveyResponse.currentValue);
    }
  }

  ngOnDestroy(): void {
    if (this.subscriptions) {
      for (const subscription of this.subscriptions) {
        subscription.unsubscribe();
      }
    }
  }

  async initialize(survey: ExternalSurvey, response: number) {
    if (survey) {
      this.attendeeImage = await this.externalService.getEmployeeImageMemoize(survey.participant.id);
    }
    await this.initializeResponse(response);
  }

  async initializeResponse(response: number) {
    if ((response || response === 0) && !Number.isNaN(response) && response <= EventSurveyResponseOptions.LEAD) { //TODO: Change response options
      if (response > 0) {
        this.surveyForm.get("surveyValue").setValue(response);
      }
    } else {
      this.surveyResponse = null;
    }
  }

  public hasError = (controlName: string, errorName: string) =>
    this.surveyForm.controls[controlName].hasError(errorName);

  async submitSurvey(formValue: any, submitBtn: MatButton | HTMLButtonElement): Promise<void> {
    submitBtn.disabled = true;
    if (this.surveyForm.valid) {
      this.survey.surveyResponse = formValue.surveyValue;
      this.survey.feedback = formValue.feedback;
      this.survey.responseReceived = true;
      this.survey.responseReceivedDate = moment().milliseconds(0).toISOString().replace(".000Z", "Z");
      await this.externalService.updateExternalSurvey(
        {
          id: this.survey.id,
          feedback: ((this.survey.feedback == null || this.survey.feedback.length <= 0) ? null : this.survey.feedback),
          surveyResponse: this.survey.surveyResponse,
          responseReceived: true,
          responseReceivedDate: this.survey.responseReceivedDate
        });
      this.toastr.success("Thank you for your response", "intHRaction Received");
    } else {
      submitBtn.disabled = !this.surveyForm.valid;
      this.toastr.error(this.errorMessage);
    }
  }

}
