import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { OrgChartComponent } from "./org-chart/org-chart.component";
import { EmployeeComponent } from "./employee/employee.component";
import { MatButtonModule } from "@angular/material/button";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatSelectModule } from "@angular/material/select";
import { MatToolbarModule } from "@angular/material/toolbar";
import { EditEmployeeComponent } from "./edit-employee/edit-employee.component";
import { AddEmployeeComponent } from "./add-employee/add-employee.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatStepperModule } from "@angular/material/stepper";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { UISharedModule } from "@inthraction/features";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatDialogModule } from "@angular/material/dialog";
import { LowercaseModule } from "../shared/lowercase/lowercase.module";

@NgModule({
  imports: [
    CommonModule,
    MatIconModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    MatExpansionModule,
    MatToolbarModule,
    MatButtonToggleModule,
    MatCheckboxModule,
    MatSelectModule,
    MatDatepickerModule,
    ReactiveFormsModule,
    MatStepperModule,
    MatSlideToggleModule,
    UISharedModule,
    MatAutocompleteModule,
    MatDialogModule,
    LowercaseModule
  ],
  declarations: [
    OrgChartComponent,
    EmployeeComponent,
    EditEmployeeComponent,
    AddEmployeeComponent
  ],
  exports: [
    OrgChartComponent
  ]
})
export class OrgChartModule {
}
