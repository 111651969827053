import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanLoad,
  Route,
  Router,
  RouterStateSnapshot,
  UrlSegment,
  UrlTree
} from "@angular/router";
import { Observable } from "rxjs";
import { EmployeeService } from "@inthraction/services";

@Injectable({
  providedIn: "root"
})
export class ConsultantUserGuard implements CanLoad, CanActivate {

  constructor(
    private router: Router,
    private employeeService: EmployeeService
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.isConsultant();
  }

  canLoad(route: Route, segments: UrlSegment[]): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.isConsultant();
  }

  private isConsultant(): Promise<boolean> {
    return new Promise(async (resolve) => {
      if (await this.employeeService.isConsultant()) {
        resolve(true);
      } else {
        this.router.navigate(["/dashboard"]);
        resolve(false);
      }
    });
  }

}
