<mat-card appearance="outlined" class="employee-objective-card cadence-objective" [class]="expired ? 'expired': ''">
  <mat-expansion-panel class="mat-elevation-z0 card-header">
    <mat-expansion-panel-header class="employee-objective-expansion-panel-header">
      <mat-card-header class="full-width">
        <div class="title-wrapper">
          <div class="title">
            <mat-card-title>{{objectiveAssignment.orgObjective.objective.display}}</mat-card-title>
          </div>
          <div class="title-due-date-wrapper">
            <mat-card-subtitle *ngIf="objectiveAssignment.endDate">
              End: {{objectiveAssignment.endDate | amParse:'YYYY-MM-DD' | amDateFormat:'ll'}}</mat-card-subtitle>
          </div>
          <div class="objectiveTimeFrameFocusWrapper">
            <mat-card-subtitle>{{(objectiveAssignment.focus.display | slice:0:objectiveAssignment.focus.display.indexOf('(')) + ' / ' + objectiveAssignment.priority.display}}</mat-card-subtitle>
            <mat-card-subtitle>{{OBJECTIVE_TYPE_LABELS[objectiveAssignment.objectiveType]}}</mat-card-subtitle>
          </div>
        </div>
      </mat-card-header>
    </mat-expansion-panel-header>
    <mat-card-content>

      <div *ngIf="objectiveAssignment.objectiveType == 'DEFINED_EXTERNAL'">
        <mat-list>
          <mat-list-item *ngFor="let externalEmail of objectiveAssignment.externalEmails">
            <h5 matListItemTitle>External Contact: {{externalEmail}}</h5>
          </mat-list-item>
        </mat-list>
      </div>

      <div class="half-width side-by-side">
        <mat-card-subtitle *ngIf="objectiveAssignment.startDate">
          Start: {{objectiveAssignment.startDate | amParse:'YYYY-MM-DD' | amDateFormat:'ll'}}</mat-card-subtitle>
      </div>

      <div class="half-width text-right side-by-side">
        <mat-card-subtitle>Frequency: {{objectiveCadenceTypeLabels[objectiveAssignment.cadence]}}</mat-card-subtitle>
      </div>

      <div class="objectiveSubtitleWrapper">
        <mat-label>{{objectiveAssignment.orgObjective.objective.definition}}</mat-label>
        <mat-label
          *ngIf="objectiveAssignment.orgObjective.businessObjective">{{objectiveAssignment.orgObjective.businessObjective}}</mat-label>
      </div>
      <div class="manager-objective-wrapper" *ngIf="objectiveAssignment.managerDescription">
        <mat-label>Manager Objective</mat-label>
        <p>{{objectiveAssignment.managerDescription}}</p>
      </div>
      <div class="observables-wrapper" *ngIf="objectiveAssignment.orgObjective.objective.observables && objectiveAssignment.orgObjective.objective.observables.length > 0">
        <mat-label>Observables</mat-label>
        <ul class="objectiveObservables">
          <li *ngFor="let observable of objectiveAssignment.orgObjective.objective.observables">{{observable}}</li>
        </ul>
      </div>
      <div *ngIf="options.managerAccess && objectiveAssignment.orgObjective.objective.hints && objectiveAssignment.orgObjective.objective.hints.length > 0" class="manager-hints-wrapper">
        <mat-label>Manager Hints</mat-label>
        <ul class="objectiveObservables">
          <li *ngFor="let hint of objectiveAssignment.orgObjective.objective.hints">{{hint}}</li>
        </ul>
      </div>
      <ng-container *ngFor="let qr of objectiveAssignment.orgObjective?.objective.qrCodes">
        <inthraction-qr [qr]="qr"></inthraction-qr>
      </ng-container>
    </mat-card-content>
  </mat-expansion-panel>
  <mat-card-footer>
    <div class="objective-score-chart-holder">
      <inthraction-objective-score-chart [employee]="employee" [options]="options" (openResponseEmitter)="openObjectiveSurveyResponse($event.objectiveResponseID, $event.employeeID)"
                                         [objectiveAssignmentID]="objectiveAssignment.id"></inthraction-objective-score-chart>
    </div>
    <inthraction-progress-check *ngIf="options?.objectiveAssignmentProgressCheck" [progressCheckData]="options.objectiveAssignmentProgressCheck"></inthraction-progress-check>
  </mat-card-footer>
  <button *ngIf="options.managerAccess" mat-icon-button class="panel-footer-button" (click)="openEditClick(objectiveAssignment.id)" aria-label="Edit Objective">
    <mat-icon>edit</mat-icon>
  </button>
</mat-card>
