import { Component, NgZone, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { EmployeeImpl, Organization } from "@inthraction/data-models";
import { AuthService, AuthState, EmployeeService, OrganizationService, ProjectService } from "@inthraction/services";
import { Subject, Subscription } from "rxjs";
import { environment } from "src/environments/environment";
import { SiteCodes } from "@inthraction/codes";

@Component({
  selector: "inthraction-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"]
})
export class HeaderComponent implements OnInit, OnDestroy {

  public static initUserSubject = new Subject<boolean>();
  userFirstName = "Welcome";
  encodedBillingEmail: string;
  stripeCustomerPortal: string;
  isAdminUser = false;
  isConsultant = false;
  isHRUser = false;
  isManager = false;
  isSuperUser = false;
  isExecutive = false;
  isProjectUser = false;
  conultantOrganizatons: Organization[] = [];
  private authStateChangedSubscription: Subscription;
  private initUserSubscription: Subscription;
  isShowHelp = false;
  disabledUser: boolean = true;
  disabledOrgainization: boolean = true;
  readonly hostname: string;
  readonly logo: String;

  constructor(
    private router: Router,
    private employeeService: EmployeeService,
    private projectService: ProjectService,
    private authService: AuthService,
    private organizationService: OrganizationService,
    private ngZone: NgZone
  ) {
    this.hostname = window.location.hostname.toLowerCase();
    this.logo = this.getLogo();
  }

  /**
   * Header is outside the router so it need to subscribe to each login to re-check the users menu options.
   */
  async ngOnInit(): Promise<void> {
    await this.initHeaderForUserSignIn();
    this.authStateChangedSubscription = this.authService.authStateChange$
      .subscribe(async authState => {
        if (authState === AuthState.SignedIn) {
          await this.employeeService.clearAllCache();
          this.userFirstName = "Welcome";
          await this.initHeaderForUserSignIn();
        } else if (authState === AuthState.SignedOut) {
          await this.employeeService.clearAllCache();
        }
      });

    this.initUserSubscription = HeaderComponent.initUserSubject.subscribe(async isInit => {
      if (isInit) {
        await this.initHeaderForUserSignIn();
      }
    });

  }


  getLogo(): String {
    for(const site of Object.keys(SiteCodes)) {
      if(this.hostname.toLowerCase().includes(site.toString().toLowerCase())) {
        return `/assets/${site}.png`;
      }
    }
    // Dev mode uses local host, use a random site
    return `/assets/${this.getRandomElement(Object.keys(SiteCodes))}.png`;
  }

  getRandomElement = (arr: any[]) =>
    arr[Math.floor(Math.random() * arr.length)]

  async initHeaderForUserSignIn(): Promise<void> {
    this.isAdminUser = false;
    this.isConsultant = false;
    this.isHRUser = false;
    this.isManager = false;
    this.isSuperUser = false;
    this.isExecutive = false;
    this.isProjectUser = false;
    this.isShowHelp = true;
    try {
      const user = await this.employeeService.getCurrentEmployee();
      if (user) {
        this.disabledUser = user.disabled;

        const organization = await this.organizationService.getOrganizationByIDMemoize(user.orgId);
        this.disabledOrgainization = organization.disabled;

        const employee: EmployeeImpl = new EmployeeImpl(user);
        this.userFirstName = user.firstName;
        this.isAdminUser = employee.isAdmin();
        this.isHRUser = employee.isHR();
        this.isProjectUser = employee.isProjectManager();
        if (!this.isProjectUser) {
          const userProjects = await this.projectService.getAllProjectsForUserMemoized(employee.id);
          if (userProjects.length > 0) {
            this.isProjectUser = true;
          }
        }
        this.isConsultant = await this.employeeService.isConsultant();
        if (this.isConsultant) {
          const conultantOrganizatons = [];
          const associations = await this.employeeService.getConsultantAssociationsByEmployee(employee.id, false);
          for await (const association of associations) {
            conultantOrganizatons.push(await this.organizationService.getOrganizationByIDMemoize(association.organizationID));
          }
          this.conultantOrganizatons = conultantOrganizatons;
        } else {
          this.conultantOrganizatons = [];
        }
        const subordinates = await this.employeeService.getSubordinatesByEmployeeIDForOrganization({managerID:user.id, memoize:true});
        if (subordinates?.length > 0) {
          this.isManager = true;
          this.isExecutive = false;
          for (const subordinate of subordinates) {
            const children = await this.employeeService.getSubordinatesByEmployeeIDForOrganization({managerID:subordinate.id, memoize:true});
            if (children?.length) {
              this.isExecutive = true;
              break;
            }
          }
        } else {
          this.isManager = false;
          this.isExecutive = false;
        }

        if (this.isAdminUser) {
          const stripeRecord = await this.organizationService.getOrganizationStripeCustomer();
          if (stripeRecord && stripeRecord.billingEmail) {
            this.encodedBillingEmail = encodeURIComponent(stripeRecord.billingEmail);
          }
          this.stripeCustomerPortal = environment.stripePortal;
          this.isSuperUser = await this.employeeService.isSuper();
        }
      }
      this.isShowHelp = await this.employeeService.isShowHelp();

    } catch (e) {
      this.disabledUser = true;
      console.error("initHeaderForUserSignIn", e);
    }
  }

  async logOut() {
    try {
      await this.employeeService.clearAllCache();
      await this.authService.signOut(true);
    } catch (e) {
      console.error("Signout Error", e);
    }
    await this.goTo("/login");
  }

  async goTo(route: string) {
    await this.ngZone.run(async () => {
      await this.router.navigate([route]);
    });
  }

  ngOnDestroy(): void {
    this.initUserSubscription?.unsubscribe();
    this.authStateChangedSubscription?.unsubscribe();
  }

  toggleHelp() {
    this.isShowHelp = !this.isShowHelp;
    this.employeeService.setShowHelp(this.isShowHelp);
  }
}
