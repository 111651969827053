import { Component, OnInit } from "@angular/core";

@Component({
  selector: "inthraction-subscription",
  templateUrl: "./subscription.component.html",
  styleUrls: ["./subscription.component.scss"]
})
export class SubscriptionComponent implements OnInit {

  constructor() {
  }

  ngOnInit(): void {
  }

}
