<h1 *ngIf="isShowOrganizationName">{{organizationName}}</h1>
<inthraction-page-header [pageTitle]="'inspHRation Configuration'+(isShowOrganizationName? ' for ' +organizationName : '')" [addBtnDisabled]="!initialized" addBtnTitle="Add New inspHRation" (addBtnClickEvent)="addNewShoutOut()"></inthraction-page-header>

<div *ngIf="initialized" class="configuration-shoutout-editor-container">
  <div class="editShoutoutTypeContainer">
    <ng-container *ngFor="let orgShoutOutType of organizationShoutOutTypes">
      <mat-card appearance="outlined">
        <mat-card-header>
          <div class="title-wrapper">
            <div>
              <ng-container *ngIf="orgShoutOutType.shoutOutType.title !== orgShoutOutType.overrideTitle">
                <mat-card-title>{{orgShoutOutType.overrideTitle}}</mat-card-title>
                <mat-card-subtitle>{{orgShoutOutType.shoutOutType.title}}</mat-card-subtitle>
              </ng-container>
              <ng-container *ngIf="orgShoutOutType.shoutOutType.title === orgShoutOutType.overrideTitle">
                <mat-card-title>{{orgShoutOutType.overrideTitle}}</mat-card-title>
              </ng-container>
            </div>
            <mat-slide-toggle (change)="updateOrgShoutOut(orgShoutOutType, $event)" [checked]="orgShoutOutType.enabled" title="Enable inspHRation for Use"></mat-slide-toggle>
          </div>
          <div mat-card-avatar class="header-image">
            <mat-icon [svgIcon]="orgShoutOutType.overrideIcon? orgShoutOutType.overrideIcon : orgShoutOutType.shoutOutType.icon"></mat-icon>
          </div>
        </mat-card-header>
        <mat-card-content>
          <mat-form-field class="full-width">
            <mat-label>Description</mat-label>
            <textarea matInput [value]="orgShoutOutType.description" readonly cdkTextareaAutosize></textarea>
          </mat-form-field>
          <mat-card-subtitle>Attributes</mat-card-subtitle>
          <ng-container *ngFor="let attribute of orgShoutOutType.shoutOutType.attributes;  let i = index;">
            <mat-form-field *ngIf="!orgShoutOutType.disabledAttributes?.includes(i)" class="full-width">
              <textarea matInput [value]="attribute" readonly cdkTextareaAutosize></textarea>
            </mat-form-field>
          </ng-container>
          <ng-container *ngFor="let attribute of orgShoutOutType.organizationAttributes;  let i = index">
            <mat-form-field class="full-width">
              <textarea matInput [value]="attribute" readonly cdkTextareaAutosize></textarea>
            </mat-form-field>
          </ng-container>
        </mat-card-content>
        <mat-card-actions>
          <button mat-icon-button (click)="editShoutOut(orgShoutOutType)" aria-label="Edit inspHRation Type" title="Edit inspHRation Type">
            <mat-icon>edit</mat-icon>
          </button>
        </mat-card-actions>
      </mat-card>
    </ng-container>
  </div>
</div>

<inthraction-loading className="configuration-shoutout-editor-container" [initDone]="initialized"></inthraction-loading>
