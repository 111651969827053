import { Component, Input, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { EmployeeService } from "@inthraction/services";
import { HelpDialogComponent } from "../dialogs/help-dialog/help-dialog.component";

@Component({
  selector: "inthraction-help-bubble",
  templateUrl: "./help-bubble.component.html",
  styleUrls: ["./help-bubble.component.scss"]
})
export class HelpBubbleComponent implements OnInit {

  @Input() tip: string;
  @Input() title: string;
  @Input() message: string;
  private infoDialogRef: any;

  show = true;

  constructor(
    private dialog: MatDialog,
    private employeeService: EmployeeService
  ) {
    this.employeeService.showHelpStateChange$.subscribe((show) => {
      this.show = show;
    });
  }

  ngOnInit(): void {
  }

  openInfoDialog(): void {
    if (!this.infoDialogRef) {
      this.infoDialogRef = this.dialog.open(HelpDialogComponent, {
        panelClass: "topRightCloseButton",
        width: "450px",
        autoFocus: false,
        data: { message: this.message, title: this.title }
      });
    }
    this.infoDialogRef.afterClosed().subscribe(() => this.infoDialogRef = null);
  }
}
