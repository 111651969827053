<inthraction-page-header pageTitle="Edit inspHRations" addBtnTitle="Add New inspHRation Type" (addBtnClickEvent)="addNewShoutOut()" [addBtnDisabled]="!initialized"></inthraction-page-header>
<div *ngIf="initialized" class="system-shoutout-editor-container">
  <div class="editShoutoutTypeContainer">
    <ng-container *ngFor="let shoutOutType of shoutOutTypes">
      <mat-card appearance="outlined">
        <mat-card-header>
          <mat-card-title>{{shoutOutType.title}}</mat-card-title>
          <div mat-card-avatar class="header-image">
            <mat-icon [svgIcon]="shoutOutType.icon"></mat-icon>
          </div>
        </mat-card-header>
        <mat-card-content>
          <mat-form-field class="full-width">
            <mat-label>Formal Description</mat-label>
            <textarea matInput [value]="shoutOutType.formalDescription" readonly cdkTextareaAutosize></textarea>
          </mat-form-field>
          <mat-form-field class="full-width">
            <mat-label>Informal Description</mat-label>
            <textarea matInput [value]="shoutOutType.informalDescription" readonly cdkTextareaAutosize></textarea>
          </mat-form-field>
          <mat-card-subtitle>Attributes</mat-card-subtitle>
          <ng-container *ngFor="let attribute of shoutOutType.attributes;  let i = index">
            <mat-form-field class="full-width">
              <mat-label>Attribute {{i + 1}}</mat-label>
              <textarea matInput [value]="attribute" readonly cdkTextareaAutosize></textarea>
            </mat-form-field>
          </ng-container>
        </mat-card-content>
        <mat-card-actions>
          <a mat-button (click)="deleteShoutOut(shoutOutType.id)" aria-label="Delete inspHRation Type" title="Delete inspHRation Type">Delete</a>
          <div class="spacer"></div>
          <a mat-icon-button (click)="editShoutOut(shoutOutType)" aria-label="Edit inspHRation Type" title="Edit inspHRation Type">
            <mat-icon>edit</mat-icon>
          </a>
        </mat-card-actions>
      </mat-card>
    </ng-container>
  </div>
</div>

<inthraction-loading className="system-shoutout-editor-container" [initDone]="initialized"></inthraction-loading>
