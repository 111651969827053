import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { Employee, EmployeeImpl, Organization } from "@inthraction/data-models";
import { MatTableDataSource } from "@angular/material/table";
import { MatSort } from "@angular/material/sort";
import { EmployeeService, OrganizationService } from "@inthraction/services";
import { EmployeeDataTypes } from "@inthraction/codes";
import moment from "moment";
import { saveAs } from "file-saver";
import { AddEmployeeComponent } from "../../../components/org-chart-module/add-employee/add-employee.component";
import { EditEmployeeComponent } from "../../../components/org-chart-module/edit-employee/edit-employee.component";
import { Subscription } from "rxjs";
import { MatDialog } from "@angular/material/dialog";

@Component({
  selector: "app-users-report",
  templateUrl: "./users-report.component.html",
  styleUrls: ["./users-report.component.scss"]
})
export class UsersReportComponent implements OnInit, AfterViewInit, OnDestroy {

  displayedColumns: string[] = ["rownum", "name", "email", "disabled", "admin", "lastActivity", "calendarAuthorized", "inviteSentDate", "inviteAccepted", "edit"];
  organization: Organization;
  tableData: MatTableDataSource<TableData> = new MatTableDataSource<TableData>([]);
  filterExample: string;
  private subscriptions: Subscription[] = [];

  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private organizationService: OrganizationService,
    private employeeService: EmployeeService,
    public dialog: MatDialog
  ) {
  }

  ngOnDestroy(): void {
    if (this.subscriptions.length) {
      for (const sub of this.subscriptions) {
        sub.unsubscribe();
      }
    }
  }

  async ngOnInit() {
    this.organization = await this.organizationService.getOrganizationForCurrentUser();
    this.tableData.data = await this.loadEmployeeList();
  }

  ngAfterViewInit() {
    if (this.tableData) {
      this.tableData.sort = this.sort;
    }
  }

  private async loadEmployeeList(): Promise<TableData[]> {
    const employees = (await this.employeeService.getEmployeesForOrganizationByOrganization({organizationID:this.organization.id, includeDisabled:true, memoize:true})).map(emp => new EmployeeImpl(emp)).sort((a, b) => {
      return `${a.disabled} ${a.lastName}, ${a.firstName}`.localeCompare(`${b.disabled} ${b.lastName}, ${b.firstName}`);
    });

    const data: TableData[] = [];
    for (let employee of employees) {
      const lastActivity = await this.employeeService.getEmployeeDataMemoize(employee.orgId, employee.id, EmployeeDataTypes.LAST_ACTIVITY_DATE);
      data.push({
        lastActivity: lastActivity ? lastActivity.stringValue : undefined,
        name: `${employee.lastName}, ${employee.firstName}`,
        email: employee.email,
        disabled: employee.disabled ? "DISABLED" : "ENABLED",
        admin: employee.isAdmin() ? "ADMIN" : "",
        calendarAuthorized: employee.validEmailToken ? "AUTHORIZED" : "",
        inviteSentDate: employee.inviteSent,
        inviteAccepted: (employee.authId ? true : false),
        employee: employee
      } as TableData);
    }
    return data;
  }

  applyFilter(event: KeyboardEvent) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.tableData.filter = filterValue.trim().toLowerCase();
    if (this.tableData.paginator) {
      this.tableData.paginator.firstPage();
    }
  }

  download() {
    const csvData = [];
    const csvHeaders = [
      "Name",
      "Email",
      "Status",
      "Admin",
      "Last Activity",
      "Calendar Authorized",
      "Invite Sent Date",
      "Invite Accepted"
    ];

    csvData.push(csvHeaders);
    for (const record of this.tableData.data) {
      const rowData = [
        `"${record.name}"` || "",
        `"${record.email}"` || "",
        record.disabled || "",
        record.admin || "",
        record.lastActivity ? moment(record.lastActivity, "YYYY-MM-DD").format("l") : "",
        record.calendarAuthorized || "",
        record.inviteSentDate ? moment(record.inviteSentDate, "YYYY-MM-DD").format("l") : "",
        record.inviteAccepted ? "TRUE" : "FALSE"
      ];
      csvData.push(rowData);
    }
    let csvArray = csvData.join("\r\n");
    const blob = new Blob([csvArray], { type: "text/csv" });
    saveAs(blob, `Users-${moment().format("YYYYMMDDHHmmss")}.csv`.replace(" ", "_"));
  }

  add() {
    const dialog = this.dialog.open(AddEmployeeComponent, {
      width: "680px",
      data: { organization: this.organization }
    });

    this.subscriptions.push(
      dialog.afterClosed().subscribe(async result => {
        if (result) {
          await this.loadEmployeeList();
        }
      }));
  }

  edit(employee: Employee) {
    const dialog = this.dialog.open(EditEmployeeComponent, {
      width: "620px",
      data: {
        organization: this.organization,
        employee
      }
    });

    this.subscriptions.push(
      dialog.afterClosed().subscribe(async result => {
        if (result) {
          await this.loadEmployeeList();
        }
      }));
  }

}

interface TableData {
  name: string;
  email: string;
  disabled: string;
  admin: string;
  lastActivity: string;
  calendarAuthorized: string;
  inviteSentDate: string;
  inviteAccepted: boolean;
  employee: Employee;
}
