import { AbstractControl, ValidatorFn } from "@angular/forms";
import * as moment from "moment";

export class DateValidators {
  static dateLessThan(dateField1: string, dateField2: string, validatorField: { [key: string]: boolean }): ValidatorFn {
    return (c: AbstractControl): { [key: string]: boolean } | null => {
      const date1 = c.get(dateField1).value;
      const date2 = c.get(dateField2).value;
      if ((date1 !== null && date2 !== null) && date1 > date2) {
        return validatorField;
      }
      return null;
    };
  }

  static dateGreaterThanEqualToCurrentDate(validatorField: { [key: string]: boolean }): ValidatorFn {
    return (c: AbstractControl): { [key: string]: boolean } | null => {
      if (c.value && c.value.format("YYYY-MM-DD") < moment().format("YYYY-MM-DD")) {
        return validatorField;
      }
      return null;
    };
  }

}
