<h1>Consultant Associations for {{organization?.orgName}}</h1>
<div class="addButtonContainer">
  <button (click)="add()" aria-label="Add Organization" mat-icon-button title="Add Organization">
    <mat-icon>add_box</mat-icon>
  </button>
</div>
<table *ngIf="consultants" mat-table [dataSource]="consultants">
  <ng-container matColumnDef="name">
    <th mat-header-cell *matHeaderCellDef>Name</th>
    <td mat-cell *matCellDef="let element"> {{nameMap.get(element.employeeID)}} </td>
  </ng-container>

  <ng-container matColumnDef="email">
    <th mat-header-cell *matHeaderCellDef>Name</th>
    <td mat-cell *matCellDef="let element"> {{emailMap.get(element.employeeID)}} </td>
  </ng-container>

  <ng-container matColumnDef="status">
    <th mat-header-cell *matHeaderCellDef>Status</th>
    <td mat-cell *matCellDef="let element"> {{element.status ? element.status : 'ENABLED'}} </td>
  </ng-container>

  <ng-container matColumnDef="incentive">
    <th mat-header-cell *matHeaderCellDef>Incentive</th>
    <td mat-cell *matCellDef="let element"> {{element.incentive ? 'true' : 'false'}} </td>
  </ng-container>

  <ng-container matColumnDef="edit">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell *matCellDef="let element">
      <button mat-stroked-button (click)="edit(element)">Edit</button>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>

