<div *ngIf="chartData.length > 0 || (options.hideChart && score)">
  <mat-label>Objective Trend</mat-label>
  <inthraction-score-card [score]="score" class="current-score"></inthraction-score-card>
  <mat-icon *ngIf="startingScore && startingScore > score" style="color: red" class="trend-icon">trending_down</mat-icon>
  <mat-icon *ngIf="startingScore && startingScore == score" class="trend-icon">trending_flat</mat-icon>
  <mat-icon *ngIf="startingScore && startingScore < score" style="color: green" class="trend-icon">trending_up</mat-icon>
  <inthraction-score-card [score]="startingScore" class="starting-score"></inthraction-score-card>
  <div *ngIf="options.showRecentResponses && (lowResponses.length || highResponses.length)" class="comments-container no-print">
        <div class="survey-comments-container">
          <ng-container *ngIf="highResponses.length">
            <mat-card appearance="outlined">
              <mat-card-subtitle>Highlight Feedback</mat-card-subtitle>
              <div class="two-columns">
                <mat-card-subtitle class="">{{emailToNameMap.get(highResponses[0].respondentEmail)}}</mat-card-subtitle>
                <div>
                  <mat-card-subtitle class="color-{{highResponses[0].surveyResponse}}">{{surveyResponseOptionsDisplay[surveyResponseOptions[highResponses[0].surveyResponse-1]]}}</mat-card-subtitle>
                  <mat-card-subtitle>{{highResponses[0].responseReceivedDate | amParse:'YYYY-MM-DDTHH:mm:ssZ' | amDateFormat:'ll' }}</mat-card-subtitle>
                </div>
              </div>
              <mat-card-content>
                <textarea matInput readonly value="{{highResponses[0].feedback}}" cdkTextareaAutosize></textarea>
              </mat-card-content>
              <mat-card-actions>
                <button mat-button *ngIf="highResponses.length > 1" (click)="showSurveyComments(highResponses)" class="show-more-btn">Show More</button>
              </mat-card-actions>
            </mat-card>
          </ng-container>
        </div>
        <div class="survey-comments-container">
          <ng-container *ngIf="lowResponses.length">
            <mat-card appearance="outlined">
              <mat-card-subtitle>Challenge Feedback</mat-card-subtitle>
              <div class="two-columns">
                <mat-card-subtitle class="">{{emailToNameMap.get(lowResponses[0].respondentEmail)}}</mat-card-subtitle>
                <div>
                  <mat-card-subtitle class="color-{{lowResponses[0].surveyResponse}}">{{surveyResponseOptionsDisplay[surveyResponseOptions[lowResponses[0].surveyResponse-1]]}}</mat-card-subtitle>
                  <mat-card-subtitle>{{lowResponses[0].responseReceivedDate | amParse:'YYYY-MM-DDTHH:mm:ssZ' | amDateFormat:'ll' }}</mat-card-subtitle>
                </div>
              </div>
              <mat-card-content>
                <textarea matInput readonly value="{{lowResponses[0].feedback}}" cdkTextareaAutosize></textarea>
              </mat-card-content>
              <mat-card-actions>
                <button mat-button *ngIf="lowResponses.length > 1" (click)="showSurveyComments(lowResponses)" class="show-more-btn">Show More</button>
              </mat-card-actions>
            </mat-card>
          </ng-container>
        </div>
  </div>
  <div class="objective-score-chart-container">
    <div *ngIf="!options.hideChart" class="chart-container">
      <ngx-charts-line-chart
        (activate)="activate($event)"
        (deactivate)="deactivate($event)"
        (select)="select($event)"
        [animations]="animations"
        [autoScale]="autoScale"
        [curve]="curve"
        [gradient]="gradient"
        [legendPosition]="legendPosition"
        [legendTitle]="legendTitle"
        [legend]="showLegend"
        [maxXAxisTickLength]="maxXAxisTickLength"
        [maxYAxisTickLength]="maxYAxisTickLength"
        [rangeFillOpacity]="rangeFillOpacity"
        [results]="chartData"
        [rotateXAxisTicks]="rotateXAxisTicks"
        [roundDomains]="roundDomains"
        [schemeType]="schemeType"
        [scheme]="colorScheme"
        [showGridLines]="showGridLines"
        [showXAxisLabel]="showXAxisLabel"
        [showYAxisLabel]="showYAxisLabel"
        [timeline]="timeline"
        [tooltipDisabled]="tooltipDisabled"
        [trimXAxisTicks]="trimXAxisTicks"
        [trimYAxisTicks]="trimYAxisTicks"
        [view]="view"
        [xAxisLabel]="xAxisLabel"
        [xAxis]="showXAxis"
        [xScaleMax]="xScaleMax"
        [xScaleMin]="xScaleMin"
        [yAxisLabel]="yAxisLabel"
        [yAxis]="showYAxis"
        [yScaleMax]="yScaleMax"
        [yScaleMin]="yScaleMin"
        class="chart-container"
      >
      </ngx-charts-line-chart>
    </div>
    <div *ngIf="!options.hideChart" class="chart-tip"><mat-icon>help_outline</mat-icon> Click a point on the chart to view specific details about that Objective Survey Response</div>
  </div>
</div>
