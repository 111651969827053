<inthraction-page-header pageTitle="Projects" [addBtnTitle]="projectManagerAccess ? 'Add Project' : undefined" (addBtnClickEvent)="openAddProject()"></inthraction-page-header>
<div class="project-dashboard-container">
  <mat-tab-group *ngIf="projects.length" [selectedIndex]="currentProjectIndex" (selectedTabChange)="tabChanged($event)" mat-stretch-tabs="false">
    <mat-tab *ngFor="let project of projects" label="{{project.title}}">
      <div class="tab-container" *ngIf="currentProject">
        <div class="main-panel">
          <div class="panel-row">
            <mat-card appearance="outlined" class="project-description half-card">
              <inthraction-help-bubble [message]="PROJECT_DESCRIPTION_HELP" tip="Project Help" title="Project"></inthraction-help-bubble>
              <mat-card-header>
                <mat-card-title aria-label="Project Title">{{project.title}}</mat-card-title>
                <inthraction-score-card *ngIf="currentProjectScore > 0" [score]="currentProjectScore"></inthraction-score-card>
              </mat-card-header>
              <mat-card-content>
                <div class="card-content-container">
                  <div class="container-left">
                    <mat-form-field>
                      <mat-label>Project Manager</mat-label>
                      <input matInput readonly [disabled]="editMode" type="text" value="{{employeeMap.get(project.projectManagerID)?.firstName}} {{employeeMap.get(project.projectManagerID)?.lastName}}">
                    </mat-form-field>
                    <mat-form-field *ngIf="editMode">
                      <mat-label>Title</mat-label>
                      <input matInput type="text" [(ngModel)]="currentProject.title">
                    </mat-form-field>
                    <mat-form-field>
                      <mat-label>Description</mat-label>
                      <textarea matInput [readonly]="!editMode" aria-label="Project Description" rows="5" [(ngModel)]="currentProject.description"></textarea>
                    </mat-form-field>
                  </div>
                  <div class="container-right">
                    <mat-form-field>
                      <mat-label>Start Date</mat-label>
                      <input matInput [readonly]="!editMode" [matDatepicker]="startDate" [(ngModel)]="currentProject.startDate">
                      <mat-datepicker-toggle [for]="startDate" matSuffix [disabled]="!editMode"></mat-datepicker-toggle>
                      <mat-datepicker #startDate [disabled]="!editMode"></mat-datepicker>
                    </mat-form-field>
                    <mat-form-field>
                      <mat-label>End Date</mat-label>
                      <input matInput [readonly]="!editMode" [matDatepicker]="endDate" [(ngModel)]="currentProject.endDate">
                      <mat-datepicker-toggle [for]="endDate" matSuffix [disabled]="!editMode"></mat-datepicker-toggle>
                      <mat-datepicker #endDate [disabled]="!editMode"></mat-datepicker>
                    </mat-form-field>
                    <mat-form-field>
                      <mat-label>Check-in Frequency</mat-label>
                      <mat-select [(ngModel)]="currentProject.cadence" [disabled]="!editMode">
                        <mat-option *ngFor="let id of cadenceTypes" [value]="id">{{cadenceTypeLabels[id]}}</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
              </mat-card-content>
              <mat-card-actions *ngIf="canEdit && !editMode">
                <div class="spacer"></div>
                <button class="panel-footer-button" (click)="toggleEditProject()" aria-label="Edit Project" mat-icon-button title="Edit Project">
                  <mat-icon>edit</mat-icon>
                </button>
              </mat-card-actions>
              <mat-card-actions *ngIf="editMode">
                <button mat-raised-button (click)="onSaveProjectEditClick()" color="primary" type="submit">Save</button>
                <button mat-button (click)="onCancelProjectEditClick()" type="button">Cancel</button>
              </mat-card-actions>
            </mat-card>
            <div class="notes-container">
              <inthraction-help-bubble [message]="PROJECT_NOTES_HELP" tip="Project Notes Help" title="Project Notes"></inthraction-help-bubble>
              <inthraction-notes [notes]="notes" [user]="employee" [options]="{title:'Project Notes', enableCreate:canEdit}" (createNoteEvent)="openAddNote()" (editNoteEvent)="openEditNote($event)"></inthraction-notes>
            </div>
          </div>
          <div class="panel-row">
            <mat-card appearance="outlined" class="team-inthraction full-card">
              <inthraction-help-bubble [message]="PROJECT_TEAM_INTHRACTION_HELP" tip="Project Team intHRactions Help" title="Project Team intHRactions Notes"></inthraction-help-bubble>
              <mat-card-header>
                <mat-card-title>Team int<span class="inthraction-highlight">HR</span>action</mat-card-title>
              </mat-card-header>
              <mat-card-content>
                <div class="team-inthraction-score-chart-holder">
                  <div></div>
                  <inthraction-project-score-chart [project]="currentProject" (selectEvent)="openProjectSurveyResults($event)" (scoreChangeEvent)="setCurrentProjectScore($event)"></inthraction-project-score-chart>
                  <div></div>
                </div>
              </mat-card-content>
            </mat-card>
          </div>
          <div class="panel-row">
            <mat-card appearance="outlined" class="team full-card">
              <inthraction-help-bubble [message]="PROJECT_TEAM_HELP" tip="Project Team Help" title="Project Team"></inthraction-help-bubble>
              <mat-card-header>
                <mat-card-title>Team</mat-card-title>
                <button *ngIf="canEdit" mat-icon-button class="panel-button" (click)="openAddTeamMember()" aria-label="Add Team Member" title="Add Team Member">
                  <mat-icon>add_box</mat-icon>
                </button>
              </mat-card-header>
              <mat-card-content>
                <div *ngIf="project.team && employeeMap && employeeMap.size > 0" class="project-team-container">
                  <ng-container *ngFor="let teamMemberId of project.team">
                    <inthraction-mini-employee-card [options]="{showRemoveButton:canEdit}" [employee]="employeeMap.get(teamMemberId)" (removeEvent)="openRemoveTeamMember($event)"></inthraction-mini-employee-card>
                  </ng-container>
                </div>
              </mat-card-content>
            </mat-card>
          </div>
        </div>
        <div class="milestone-panel">
          <div style="position: relative">
            <h2>Milestones</h2>
            <inthraction-help-bubble [message]="PROJECT_MILESTONES_HELP" tip="Project Milestones Help" title="Project Milestones"></inthraction-help-bubble>
          </div>
          <div class="milestone-actions" *ngIf="canEdit">
            <div>
              <button (click)="openAddMilestone()" class="" mat-raised-button>Assign Milestone</button>
            </div>
          </div>
          <div class="milestones-container">
            <inthraction-project-milestone *ngFor="let milestone of milestones" [milestone]="milestone" [managerAccess]="canEdit" (openEdit)="openEditMilestone($event)"></inthraction-project-milestone>
          </div>
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>
