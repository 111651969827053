<inthraction-page-header [pageTitle]="'Objective Response Distribution' + (employee ? ' ' + employee.firstName + ' ' + employee.lastName : '')"></inthraction-page-header>
<mat-dialog-content>
  <mat-tab-group mat-stretch-tabs="false">
    <mat-tab label="Distribution by Department">
      <ng-template matTabContent>
        <inthraction-objective-response-distribution-details [mode]="mode" [distributionBy]="ObjectiveResponseDistributionMode.DEPARTMENT" [employee]="employee"></inthraction-objective-response-distribution-details>
      </ng-template>
    </mat-tab>
    <mat-tab label="Distribution by {{employee ? 'Peer' : 'User'}}">
      <ng-template matTabContent>
        <inthraction-objective-response-distribution-details [mode]="mode" [distributionBy]="ObjectiveResponseDistributionMode.PEER" [employee]="employee"></inthraction-objective-response-distribution-details>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
</mat-dialog-content>
