import * as moment from "moment";
import { Employee } from "./employee";
import { DEPARTMENT_TYPE, READINESS_TYPE, RoleType } from "@inthraction/codes";

export class EmployeeImpl implements Employee {

  id: string;
  authId: string;
  email: string;
  firstName: string;
  lastName: string;
  title: string;
  orgId: string;
  disabled: boolean;
  disabledBy: string;
  disabledDate: string;
  department: DEPARTMENT_TYPE | string;
  hireDate?: string;
  lastAnnual?: string;
  lastOneOnOne?: string;
  positionDate?: string;
  previousPositionStartDate?: string;
  previousPositionTitle?: string;
  validEmailToken?: boolean;
  roles: RoleType[] | string[];
  relocate?: string;
  relocateDate?: string;
  readiness?: READINESS_TYPE | string;
  inviteSent?: string;
  updatedAt: string;
  groups?: string[];
  managerID?: string;
  criticalPosition?: boolean;

  constructor(employee: Employee) {
    if (!employee) {
      throw new Error("User must not be null/undefined");
    }
    this.id = employee.id;
    this.authId = employee.authId;
    this.email = employee.email;
    this.firstName = employee.firstName;
    this.lastName = employee.lastName;
    this.title = employee.title;
    this.orgId = employee.orgId;
    this.disabled = employee.disabled;
    this.disabledBy = employee.disabledBy;
    this.disabledDate = employee.disabledDate;
    this.department = employee.department;
    this.hireDate = employee.hireDate;
    this.lastAnnual = employee.lastAnnual;
    this.lastOneOnOne = employee.lastOneOnOne;
    this.positionDate = employee.positionDate;
    this.previousPositionStartDate = employee.previousPositionStartDate;
    this.previousPositionTitle = employee.previousPositionTitle;
    this.validEmailToken = employee.validEmailToken;
    this.roles = employee.roles;
    this.readiness = employee.readiness;
    this.updatedAt = employee.updatedAt;
    this.inviteSent = employee.inviteSent;
    this.groups = employee.groups;
    this.managerID = employee.managerID;
    this.criticalPosition = employee.criticalPosition;
  }

  public static getDaysIn(date: string): number {
    if (date) {
      return moment().diff(moment(date, "YYYY-MM-DD"), "days");
    }
    return null;
  }

  public getDaysSinceLastOneOnOne(): number {
    return EmployeeImpl.getDaysIn(this.lastOneOnOne);
  }

  public getDaysInPosition(): number {
    return EmployeeImpl.getDaysIn(this.positionDate);
  }

  public isAdmin(): boolean {
    return this.roles?.includes("ADMIN");
  }

  public isHR(): boolean {
    return this.roles?.includes("HR");
  }

  public isProjectManager() {
    return this.roles?.includes("PM");
  }

  public setAdmin(value: boolean) {
    this.setRole(value, "ADMIN");
  }

  public setHR(value: boolean) {
    this.setRole(value, "HR");
  }

  public setPM(value: boolean) {
    this.setRole(value, "PM");
  }

  private setRole(value: boolean, role: RoleType) {
    if (value && (!this.roles || !this.roles.includes(role))) {
      if (!this.roles) {
        this.roles = [];
      }
      this.roles.push(role);
    } else if (!value && this.roles && this.roles.includes(role)) {
      const index: number = this.roles.indexOf(role, 0);
      if (index > -1) {
        this.roles.splice(index, 1);
      }
    }
  }

}
