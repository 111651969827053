import { Component, OnInit } from "@angular/core";
import { ObjectiveDistributionDetailsModes } from "../../../components/objective-distribution/DistributionDetails";

@Component({
  selector: "inthraction-manager-objective-distribution",
  templateUrl: "./manager-objective-distribution.component.html",
  styleUrls: ["./manager-objective-distribution.component.scss"]
})
export class ManagerObjectiveDistributionComponent implements OnInit {

  readonly ObjectiveDistributionDetailsModes = ObjectiveDistributionDetailsModes;

  constructor() {
  }

  ngOnInit(): void {
  }

}
