import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from "@angular/router";
import { Employee } from "../../data-models/employee/employee";
import { EmployeeService } from "../../services/employee.service";
import { AuthService } from "../../services/auth.service";
import { EmployeeDataTypes } from "@inthraction/codes";
import * as moment from "moment";
import { EmployeeData, EmployeeImpl } from "@inthraction/data-models";
import { OrganizationService } from "@inthraction/services";

@Injectable({
  providedIn: "root"
})
export class ActiveUserGuard implements CanActivate {
  constructor(
    private router: Router,
    private authService: AuthService,
    private employeeService: EmployeeService,
    private organizationService: OrganizationService
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    return new Promise(async (resolve) => {
      let user: Employee;
      try {
        user = await this.employeeService.getCurrentEmployee();
      } catch (e) {
        console.error("ActiveUserGuard", e);
      }
      if (!user || user.disabled) {
        this.router.navigate(["/inactive"]);
        resolve(false);
      } else {
        if (user && (!user.authId || user.authId.trim().length <= 0)) {
          const credentials = await this.authService.currentCredentials();
          await this.employeeService.updateEmployee({
            id: user.id,
            authId: credentials.identityId
          });
        }
        if (user) {
          let lastActivity: EmployeeData = await this.employeeService.getEmployeeDataMemoize(user.orgId ,user.id, EmployeeDataTypes.LAST_ACTIVITY_DATE);
          if (!lastActivity || lastActivity.stringValue !== moment().format("YYYY-MM-DD")) {
            if (!lastActivity) {
              lastActivity = {
                employeeID: user.id,
                organizationID: user.orgId,
                dataCode: EmployeeDataTypes.LAST_ACTIVITY_DATE
              };
            }
            lastActivity.stringValue = moment().format("YYYY-MM-DD");
            await this.employeeService.putEmployeeData(lastActivity);
          }
          if (await this.isOrganizationInactive(user.orgId)) {
            const employee: EmployeeImpl = new EmployeeImpl(user);
            if (employee.isAdmin() && ("/admin/subscription" !== state.url)) {
              this.router.navigate(["/admin/subscription"]);
              resolve(true);
            } else if (employee.isAdmin() && "/admin/subscription" === state.url)  {
              resolve(true);
            } else {
              this.router.navigate(["/inactive"]);
              resolve(false);
            }
          } else {
            resolve(true);
          }
        } else {
          resolve(false);
        }
      }
    });
  }

  private async isOrganizationInactive(organizationId:string) : Promise<boolean> {
    const organization = await this.organizationService.getOrganizationByIDMemoize(organizationId);
    return organization?.disabled;
  }

}
