import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";
import { READINESS_TYPE_LABELS, SHORT_WORK_STATUS_TYPE_LABELS } from "@inthraction/labels";
import { EmployeeService, OrganizationService, SurveyService, SurveyType } from "@inthraction/services";
import { Employee, EmployeeImageInterface, EmployeeImpl } from "@inthraction/data-models";
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";
import { READINESS_HELP } from "@inthraction/labels/src/help/HRDashboardHelpLabels";
import { EmployeeDataTypes, OrganizationConfigurationCodes } from "@inthraction/codes";
import { MatSlideToggleChange } from "@angular/material/slide-toggle";

@Component({
  selector: "inthraction-employee-card-component",
  templateUrl: "./employee-card-component.component.html",
  styleUrls: ["./employee-card-component.component.scss"]
})
export class EmployeeCardComponentComponent implements OnInit, OnChanges {

  readonly SHORT_WORK_STATUS_TYPE_LABELS = SHORT_WORK_STATUS_TYPE_LABELS;
  readonly READINESS_HELP = READINESS_HELP;
  subordinates: Employee[];
  employeeImage: EmployeeImageInterface;
  relocate: string;
  workStatus: string;
  employeeReadiness: string;

  constructor(
    private employeeService: EmployeeService,
    private surveyService: SurveyService,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private organizationService: OrganizationService
  ) {
    this.matIconRegistry.addSvgIcon("star", this.domSanitizer.bypassSecurityTrustResourceUrl("assets/font-icons/star.svg"));
  }

  get color(): string {
    return this._color;
  }

  set color(color: string) {
    this._color = color;
    EmployeeCardComponentComponent.appendCss(color);
  }

  @Input() employee: Employee;
  @Input() options: EmployeeCardOptions = {};

  @Output() orgNavEvent = new EventEmitter();
  @Output() calendarToggleEvent = new EventEmitter();
  @Output() profileImageClickEvent = new EventEmitter<Employee>();
  @Output() editClickEvent = new EventEmitter<Employee>();
  @Output() showIntHRactionsChartClickEvent = new EventEmitter<Employee>();
  @Output() showRatesOthersChartClickEvent = new EventEmitter<Employee>();
  @Output() favoriteClickEvent = new EventEmitter<Employee>();

  daysSinceLastOneOnOne: number = null;
  daysInPosition: number = null;
  totalPerformanceScore = 0;
  ytdTeamScore = 0;
  openIntHRactionsCount = 0;
  readinessLabels = READINESS_TYPE_LABELS;

  private _color: string;

  // TODO This is kind of a hack, will override material theme for the toggle
  private static appendCss(customColor: string): void {
    const customID = `#toggle-${customColor.replace("#", "")}`;
    let style = `${customID}.mat-slide-toggle.mat-checked .mat-slide-toggle-bar { background-color: ${customColor};}`;
    style = style + ` ${customID}.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {background-color: ${customColor}; filter: brightness(75%);}`;
    const css = document.createElement("style");
    css.appendChild(document.createTextNode(style));
    document.getElementsByTagName("head")[0].appendChild(css);
  }

  async ngOnInit() {
    await this.initialize(this.employee);
  }

  private async initialize(employee: Employee) {
    this.employeeImage = await this.employeeService.getEmployeeImageMemoize(employee.id, employee.orgId);

    let employeeDataRecords = await this.employeeService.getEmployeeData(employee.orgId, employee.id, [EmployeeDataTypes.RELOCATABLE, EmployeeDataTypes.WORK_STATUS], true);
    for (let employeeDataRecord of employeeDataRecords) {
      if (employeeDataRecord.dataCode === EmployeeDataTypes.RELOCATABLE) {
        this.relocate = employeeDataRecord.booleanValue ? "yes" : "no";
      } else if (employeeDataRecord.dataCode === EmployeeDataTypes.WORK_STATUS) {
        this.workStatus = employeeDataRecord.stringValue;
      }
    }

    if (this.options.showReadiness && employee.readiness) {
      const readinessHistory = await this.employeeService.getEmployeeReadiness(employee.readiness);
      this.employeeReadiness = readinessHistory.readiness;
    }

    if (employee.lastOneOnOne) {
      this.daysSinceLastOneOnOne = EmployeeImpl.getDaysIn(employee.lastOneOnOne);
    }
    if (employee.positionDate) {
      this.daysInPosition = EmployeeImpl.getDaysIn(employee.positionDate);
    }
    const calculationTypeConfig = await this.organizationService.getOrganizationConfiguration(OrganizationConfigurationCodes.INTHRACTION_CALCULATION, employee.orgId);
    if (calculationTypeConfig && "ROLLING" === calculationTypeConfig.configStringValue) {
      this.totalPerformanceScore = await this.employeeService.getEmployeeMTDTotalScoreByEmployeeIDMemoize(employee.orgId, employee.id);
    } else {
      this.totalPerformanceScore = await this.employeeService.getEmployeeYTDTotalScoreByEmployeeIDMemoize(employee.orgId, employee.id);
    }
    this.subordinates = await this.employeeService.getSubordinatesByEmployeeIDForOrganization({managerID: employee.id, memoize:true});
    if (this.subordinates?.length > 0) {
      if (calculationTypeConfig && "ROLLING" === calculationTypeConfig.configStringValue) {
        this.ytdTeamScore = await this.employeeService.getEmployeeMTDTeamTotalScoreMemoize(employee.id);
      } else {
        this.ytdTeamScore = await this.employeeService.getEmployeeYTDTeamTotalScoreMemoize(employee.id);
      }
    }

    if (this.options.showCalendarToggle) {
      this.color = this.employeeService.getEmployeeColor(employee.email);
    }

    if (this.options.showOpenIntHRactionCount) {
      await this.getOpenIntHRactionCounts(this.employee);
    }

  }

  loadSubordinates(employee: Employee) {
    this.orgNavEvent.emit(employee);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.employee && changes.employee.currentValue && changes.employee.currentValue.id) {
      this.employeeImage = null;
      this.initialize(changes.employee.currentValue);
    }
    if (changes.options && changes.options.currentValue) {
      if (changes.options.currentValue.showCalendarToggle) {
        this.color = this.employeeService.getEmployeeColor(this.employee.email);
      }
      if (changes.options.currentValue.showOpenIntHRactionCount) {
        this.getOpenIntHRactionCounts(this.employee);
      }
    }

  }

  async getOpenIntHRactionCounts(employee: Employee) {
    const openSurveys = (await this.surveyService.getPendingResponseSurveysByRespondentEmailMemoize(employee.orgId, employee.email)).filter(s => s.surveyType != SurveyType.INSPHRATION);
    this.openIntHRactionsCount = openSurveys.length;
  }

  async setCriticalPosition(event: MatSlideToggleChange) {
    await this.employeeService.updateEmployee({ id: this.employee.id, criticalPosition: event.checked });
    if (this.employee.managerID) {
      const manager = await this.employeeService.getEmployeeByIDMemoize(this.employee.managerID);
      this.employeeService.clearMemoizedEmployee(manager);
    }
  }

  getEmployeeColor(employee: Employee) {
    return this.employeeService.getEmployeeColor(employee?.email);
  }
}

export interface EmployeeCardOptions {
  hideEmail?: boolean;
  showCalendarToggle?: boolean;
  showOpenIntHRactionCount?: boolean;
  showOrgNav?: boolean;
  disableOrgNav?: boolean;
  showReadiness?: boolean;
  showCriticalPosition?: boolean;
  showEdit?: boolean;
  showRatesOthersChart?: boolean;
  showIntHRactionsChart?: boolean;
  startingScore?: number;
  endingScore?: number;
  showFavoriteToggle?: boolean;
  isFavorite?: boolean;
  favorites?: string[];
}
