import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { EmployeeService, ProjectService, SurveyService } from "@inthraction/services";
import { MilestoneScoreChartSelection } from "../../milestone-score-chart/milestone-score-chart.component";
import * as moment from "moment";
import { ProjectMilestoneAssignment, Survey } from "@inthraction/data-models";
import { OBJECTIVE_SURVEY_RESPONSE_LABELS } from "@inthraction/labels";
import { OBJECTIVE_SURVEY_RESPONSE_TYPES } from "@inthraction/codes";

@Component({
  selector: "inthraction-milestone-survey-results",
  templateUrl: "./milestone-survey-results.component.html",
  styleUrls: ["./milestone-survey-results.component.scss"]
})
export class MilestoneSurveyResultsComponent implements OnInit {

  surveys: Survey[];
  totalScore = 0;
  milestone: ProjectMilestoneAssignment;
  responseOptions = Object.assign([], OBJECTIVE_SURVEY_RESPONSE_TYPES);
  displayOptions: string[];
  surveysWithComments: any[] = [];
  scoreMap = new Map<number, any>();
  isProjectManager: boolean;

  constructor(
    public dialogRef: MatDialogRef<MilestoneSurveyResultsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { selection: MilestoneScoreChartSelection, isProjectManager: boolean, organizationID: string },
    private surveyService: SurveyService,
    private employeeService: EmployeeService,
    private projectService: ProjectService
  ) {
    this.displayOptions = this.responseOptions.reverse().map(o => OBJECTIVE_SURVEY_RESPONSE_LABELS[o]);
  }

  async ngOnInit() {
    this.isProjectManager = this.data.isProjectManager;
    this.milestone = await this.projectService.getProjectMilestoneAssignmentByIDMemoized(this.data.selection.milestoneID);
    const surveys = await this.surveyService.getSurveysByObjectIDMemoize(this.data.organizationID, this.data.selection.milestoneID);
    this.surveys = surveys.filter(i => i.responseReceived && moment(i.surveyDate).format("YYYY-MM-DD") === this.data.selection.surveyDate);

    let count = 0;
    let sum = 0;
    for (const survey of this.surveys) {
      const score = survey.surveyResponse;
      if (!this.scoreMap.get(score)) {
        this.scoreMap.set(score, { count: 0, respondents: [] });
      }
      const scoreCountObj = this.scoreMap.get(score);
      scoreCountObj.count = scoreCountObj.count + 1;
      const respondent = await this.employeeService.getEmployeeByEmailMemoize(survey.respondentEmail);
      const respondentName = `${respondent.firstName} ${respondent.lastName}`;
      scoreCountObj.respondents.push(respondentName);
      if (survey.feedback && survey.feedback.length > 0) {
        this.surveysWithComments.push({ respondent: respondentName, event: survey });
      }
      this.scoreMap.set(score, scoreCountObj);

      const surveyScores = await this.projectService.getMilestoneScores(this.milestone.id, survey.id);
      if (surveyScores.length) {
        count++;
        sum = sum + surveyScores[0].score;
      }
    }

    this.totalScore = count ? (sum / count) : 0;

  }

}
