import { Injectable } from "@angular/core";
import {
  APIService,
  CreateOrganizationInput,
  UpdateOrganizationConfigurationInput,
  UpdateOrganizationInput
} from "./API.service";
import { Memoize, MEMOIZE_FN_MAP } from "@inthraction/utils";
import { Organization, OrganizationConfiguration, StripeCustomer } from "@inthraction/data-models";
import { BaseService } from "./base.service";
import { AuthService } from "./auth.service";
import { ObjectiveService } from './objective.service';

@Injectable({ providedIn: "root" })
export class OrganizationService extends BaseService {

  constructor(
    protected api: APIService,
    protected authService: AuthService
  ) {
    super(api, authService);
  }

  clearOrganizationConfiguration(organizationID: string, configCode: string) {
    if (MEMOIZE_FN_MAP.has("_getOrganizationConfiguration")) {
      MEMOIZE_FN_MAP.get("_getOrganizationConfiguration").delete(organizationID, configCode);
    }
    if (MEMOIZE_FN_MAP.has("getOrganizationObjectivesByOrganizationIDMemoize")) {
      MEMOIZE_FN_MAP.get("getOrganizationObjectivesByOrganizationIDMemoize").delete(organizationID);
      MEMOIZE_FN_MAP.get("getOrganizationObjectivesByOrganizationIDMemoize").delete(organizationID, false);
      MEMOIZE_FN_MAP.get("getOrganizationObjectivesByOrganizationIDMemoize").delete(organizationID, true);
    }
  }

  clearOrganization(organizationId: string) {
    if (MEMOIZE_FN_MAP.has("getOrganizationByIDMemoize")) {
      MEMOIZE_FN_MAP.get("getOrganizationByIDMemoize").delete(organizationId);
    }
  }

  /* Organization should never change, no need to expire the memoize */
  @Memoize()
  async getOrganizationByIDMemoize(orgId: string): Promise<Organization> {
    return this.api.GetOrganization(orgId);
  }


  /**
   * Super Admin Function
   * @param configCode
   * @param organizationID
   */
  async getConfigurationForAllOrganizations(configCode: string): Promise<OrganizationConfiguration[]> {
    const filter = {
      configCode: { eq: configCode }
    };
    return this.getAll<OrganizationConfiguration>(this.api.ListOrganizationConfigurations, filter);
  }

  async getOrganizationConfiguration(configCode: string, organizationID?: string): Promise<OrganizationConfiguration> {
    if (!organizationID) {
      organizationID = (await this.getCurrentUser()).orgId;
    }
    return this._getOrganizationConfiguration(organizationID, configCode);
  }

  @Memoize()
  private async _getOrganizationConfiguration(orgId: string, configCode: string): Promise<OrganizationConfiguration> {
    const filter = {
      organizationID: { eq: orgId },
      configCode: { eq: configCode }
    };
    return (await this.getAll<OrganizationConfiguration>(this.api.ListOrganizationConfigurations, filter))[0];
  }

  async getOrganizationStripeCustomer(): Promise<StripeCustomer> {
    const organizationID = (await this.getCurrentUser()).orgId;
    return this.api.GetStripe(organizationID);
  }

  async getOrganizationsByDomain(domain: string): Promise<Organization[]> {
    return this.getAll<Organization>(this.api.ListOrganizations, { domains: { contains: domain } });
  }

  async createOrganization(organization: Organization): Promise<Organization> {
    const input: CreateOrganizationInput = {
      orgName: organization.orgName,
      domains: organization.domains,
      site: organization.site,
      disabled: organization.disabled
    };
    return this.api.CreateOrganization(input);
  }

  async getOrganizationForCurrentUser(): Promise<Organization> {
    const organizationID = (await this.getCurrentUser()).orgId;
    return this.getOrganizationByIDMemoize(organizationID);
  }

  async updateOrganizationConfiguration(input: UpdateOrganizationConfigurationInput): Promise<OrganizationConfiguration> {
    const configuration = await this.api.UpdateOrganizationConfiguration(input);
    this.clearOrganizationConfiguration(configuration.organizationID, configuration.configCode);
    return configuration;
  }

  createOrganizationConfiguration(param: OrganizationConfiguration) {
    this.clearOrganizationConfiguration(param.organizationID, param.configCode);
    return this.api.CreateOrganizationConfiguration(param);
  }

  async getOrganizations(): Promise<Organization[]> {
    return this.getAll<Organization>(this.api.ListOrganizations, null);
  }

  async updateOrganization(organization: Organization): Promise<Organization> {
    const input: UpdateOrganizationInput = {
      id: organization.id,
      orgName: organization.orgName,
      domains: organization.domains,
      site: organization.site,
      disabled: organization.disabled
    };
    this.clearOrganization(organization.id);
    return this.api.UpdateOrganization(input);
  }
}
