<mat-card appearance="outlined" class="project-milestone-card">
  <mat-expansion-panel class="mat-elevation-z0">
    <mat-expansion-panel-header>
      <mat-card-header>
        <div class="title-wrapper">
          <div class="title">
            <mat-card-title>{{milestone.title}}</mat-card-title>
          </div>
          <div class="title-due-date-wrapper">
            <mat-card-subtitle *ngIf="milestone.endDate">
              End: {{milestone.endDate | amParse:'YYYY-MM-DD' | amDateFormat:'ll'}}</mat-card-subtitle>
          </div>
        </div>
      </mat-card-header>
    </mat-expansion-panel-header>
    <mat-card-content>
      <div class="half-width side-by-side">
        <mat-card-subtitle *ngIf="milestone.startDate">
          Start: {{milestone.startDate | amParse:'YYYY-MM-DD' | amDateFormat:'ll'}}</mat-card-subtitle>
      </div>
      <div class="half-width text-right side-by-side">
        <mat-card-subtitle>Frequency: {{cadenceTypeLabels[milestone.cadence]}}</mat-card-subtitle>
      </div>
      <div *ngIf="milestone.description" class="manager-objective-wrapper">
        <mat-label>Description</mat-label>
        <p>{{milestone.description}}</p>
      </div>
    </mat-card-content>
  </mat-expansion-panel>
  <mat-card-content>
    <div class="score-chart-holder">
      <inthraction-milestone-score-chart [milestone]="milestone" (selectEvent)="openSurveyResponse($event)"></inthraction-milestone-score-chart>
    </div>
    <div class="comments-container">
      <inthraction-milestone-comments *ngIf="milestone.comments && milestone.comments.length" [comments]="milestone.comments"></inthraction-milestone-comments>
    </div>
  </mat-card-content>
  <mat-card-actions>
    <button mat-icon-button class="panel-footer-button-right" aria-label="Add Comment" title="Add Comment" (click)="openAddComment(milestone)">
      <mat-icon>comment</mat-icon>
    </button>
    <button *ngIf="managerAccess" mat-icon-button class="panel-footer-button-left" (click)="openEditClick(milestone.id)" aria-label="Edit Milestone" title="Edit Milestone">
      <mat-icon>edit</mat-icon>
    </button>
  </mat-card-actions>
</mat-card>
