import { Component, Input, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { EmployeeService } from "@inthraction/services";

@Component({
  selector: "inthraction-score-card",
  templateUrl: "./score-card.component.html",
  styleUrls: ["./score-card.component.scss"]
})
export class ScoreCardComponent implements OnInit, OnChanges {

  @Input() additionalStyleClass: string;
  @Input() subTitle: string;
  @Input() scale: number;
  backgroundColor: string;

  constructor() {
  }

  private _score: number;

  get score(): number {
    return this._score;
  }

  @Input()
  set score(score: number) {
    this._score = score;
    this.backgroundColor = EmployeeService.getScoreColor(this._score, this.scale);
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.scale) {
      this.backgroundColor = EmployeeService.getScoreColor(this._score, changes.scale.currentValue);
    }
  }

}
