<form (ngSubmit)="saveComment(milestoneAssignment.id, milestoneAssignment.organizationID, commentText.value, $event);">
  <h1 mat-dialog-title>Add Comment</h1>
  <div mat-dialog-content>
    <mat-form-field class="full-width">
      <mat-label>Comment</mat-label>
      <textarea matInput #commentText cdkTextareaAutosize maxlength="2000"></textarea>
    </mat-form-field>
  </div>
  <div mat-dialog-actions>
    <button mat-raised-button type="submit" color="primary" [disabled]="commentText.value.length <= 0">Submit</button>
  </div>
</form>
