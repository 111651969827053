import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Employee, ObjectiveAssignment } from "@inthraction/data-models";
import { EmployeeService, ObjectiveService } from "@inthraction/services";

@Component({
  selector: "app-objective-dialog",
  templateUrl: "./objective-dialog.component.html",
  styleUrls: ["./objective-dialog.component.scss"]
})
export class ObjectiveDialogComponent implements OnInit {

  objectiveAssignment: ObjectiveAssignment;
  employee: Employee;
  managerAccess: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private objectiveService: ObjectiveService,
    private employeeService: EmployeeService
  ) {
  }

  async ngOnInit() {
    this.objectiveAssignment = await this.objectiveService.getEmployeeObjectiveAssignmentByID(this.data.objectiveAssignmentID);
    this.employee = await this.employeeService.getEmployeeByIDMemoize(this.objectiveAssignment.employeeId);
  }


}
