import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanLoad,
  Route,
  Router,
  RouterStateSnapshot,
  UrlSegment,
  UrlTree
} from "@angular/router";
import { Observable } from "rxjs";
import { EmployeeService } from "../../services/employee.service";
import { Employee } from "../../data-models/employee/employee";
import { OrganizationService } from "../../services/organization.service";
import { EmployeeImpl } from "../../data-models/employee/EmployeeImpl";
import { AuthService } from "../../services/auth.service";

@Injectable({
  providedIn: "root"
})
export class SuperAdminGuard implements CanLoad, CanActivate {

  constructor(
    private router: Router,
    private organizationService: OrganizationService,
    private employeeService: EmployeeService,
    private authService: AuthService
  ) {
  }

  canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
    return this.isSuperAdmin();
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.isSuperAdmin();
  }

  private isSuperAdmin(): Promise<boolean> {
    return new Promise(async (resolve) => {
      const securityUser = this.authService.currentUser;
      if (securityUser) {
        const user: Employee = await this.employeeService.getCurrentEmployee();
        const employee: EmployeeImpl = new EmployeeImpl(user);
        let isSuperAdmin = false;
        if (user && employee && employee.isAdmin()) {
          isSuperAdmin = await this.employeeService.isSuper();
        }
        if (isSuperAdmin) {
          resolve(true);
        } else {
          this.router.navigate(["/dashboard"]);
          resolve(false);
        }
      } else { // Not authenticated
        resolve(false);
      }
    });
  }
}
