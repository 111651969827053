import { Component, EventEmitter, Input, Output } from "@angular/core";
import { IEmployeeImpl } from "@inthraction/data-models";
import { EmployeeService } from "@inthraction/services";

@Component({
  selector: "inthraction-ng-org-chart",
  templateUrl: "./org-chart.component.html",
  styleUrls: ["./org-chart.component.css"]
})
export class OrgChartComponent {

  public isCollapsed: boolean;
  @Input() topEmployee: IEmployeeImpl;
  @Input() hasManager = false;
  @Input() levels = -1;
  @Input() showActions = true;
  @Output() chartChangeEvent = new EventEmitter();
  @Output() clickEvent = new EventEmitter();

  constructor(
    private employeeService: EmployeeService
  ) {
  }

  get subordinateEmployees(): IEmployeeImpl[] {
    return this.topEmployee?.subordinateEmployees.sort((a, b) => (a.department + a.lastName + a.firstName).localeCompare(b.department + b.lastName + b.firstName));
  }

  public collapseSubordinates(isCollapsed: boolean): void {
    this.isCollapsed = isCollapsed;
  }

  async editData(result: any) {
    if (result.disabled) {
      this.chartChangeEvent.emit();
    }
    this.topEmployee = await this.employeeService.buildEmployeeHierarchy(result);
  }

  async updateData(result: any) {
    this.topEmployee = await this.employeeService.buildEmployeeHierarchy(result.employee);
    if (result.addExisting) {
      this.chartChangeEvent.emit();
    }
  }

  chartChanged(topEmployee: IEmployeeImpl): void {
    this.chartChangeEvent.emit(topEmployee);
  }

  clickEmitter(): void {
    this.clickEvent.emit(this.topEmployee);
  }

  clickEmitterPassThru(event: any): void {
    this.clickEvent.emit(event);
  }
}
