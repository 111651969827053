import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Employee, ReadinessHistory } from "@inthraction/data-models";
import { READINESS_TYPE_LABELS } from "@inthraction/labels";
import { READINESS_TYPES } from "@inthraction/codes";
import { EmployeeService } from "@inthraction/services";

@Component({
  selector: "inthraction-employee-readiness",
  templateUrl: "./employee-readiness.component.html",
  styleUrls: ["./employee-readiness.component.scss"]
})
export class EmployeeReadinessComponent implements OnInit {

  readinessLabels = READINESS_TYPE_LABELS;
  readinessTypes = READINESS_TYPES;
  readinessValue: string;
  readinessComments: string;
  readinessOpportunity1: string;
  readinessOpportunity2: string;
  readinessOpportunity3: string;

  employee: Employee;

  constructor(
    public dialogRef: MatDialogRef<EmployeeReadinessComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private employeeService: EmployeeService
  ) {
    this.employee = data.employee;
  }

  async ngOnInit() {
    if (this.employee.readiness) {
      const readinessHistory: ReadinessHistory = await this.employeeService.getEmployeeReadiness(this.employee.readiness);
      this.readinessValue = readinessHistory.readiness;
    }
  }

  onCancelClick(): void {
    this.dialogRef.close(null);
  }

  async onSaveClick(): Promise<void> {
    const readinessResult: [Employee, ReadinessHistory] = await this.employeeService.setReadiness(this.employee, this.readinessValue, this.readinessComments, [this.readinessOpportunity1, this.readinessOpportunity2, this.readinessOpportunity3]);
    this.dialogRef.close({
      readiness: readinessResult[1]
    });
  }

}
