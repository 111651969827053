import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import {
  CadenceObjectiveAssignment,
  Employee,
  ExternalRespondent,
  ObjectiveAssignment,
  ObjectiveSurvey,
  QuantifiableObjectiveAssignment
} from "@inthraction/data-models";
import { EmployeeService, ExternalService, ObjectiveService, SurveyService } from "@inthraction/services";
import { OBJECTIVE_SURVEY_RESPONSE_TYPES, ObjectiveTypeCodes } from "@inthraction/codes";
import { OBJECTIVE_SURVEY_RESPONSE_LABELS } from "@inthraction/labels";

@Component({
  selector: "inthraction-objective-response",
  templateUrl: "./objectiveResponse.component.html",
  styleUrls: ["./objectiveResponse.component.scss"]
})
export class ObjectiveResponseComponent implements OnInit {

  objectiveResponse: ObjectiveSurvey;
  objectiveAssignment: ObjectiveAssignment | QuantifiableObjectiveAssignment | CadenceObjectiveAssignment;
  respondent: Employee | ExternalRespondent;
  objectiveResponseID: string;
  participantEmail: string;
  readonly objectiveSurveyResponseLabels = OBJECTIVE_SURVEY_RESPONSE_LABELS;
  readonly objectiveSurveyResponseTypes = OBJECTIVE_SURVEY_RESPONSE_TYPES;
  managerAccess = false;
  employee: Employee;
  totalScore: number;

  constructor(
    private externalService: ExternalService,
    private employeeService: EmployeeService,
    private objectiveService: ObjectiveService,
    private surveyService: SurveyService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.objectiveResponseID = data.objectiveResponseID;
    this.employee = data.employee;
    this.participantEmail = this.employee.email;
  }

  async ngOnInit(): Promise<void> {
    // TODO ensure participant email is the current user, subordinate of a manager, or user is HR
    const securityUser = await this.employeeService.getCurrentEmployee();
    this.managerAccess = this.participantEmail !== securityUser.email;

    this.objectiveResponse = await this.surveyService.getSurveyMemoize(this.objectiveResponseID);
    if (this.objectiveResponse) {
      this.totalScore = SurveyService.getFivePointResponseScore(this.objectiveResponse.surveyResponse);
      this.objectiveAssignment = await this.objectiveService.getObjectiveAssignmentMemoize(this.objectiveResponse.objectID);
      if (this.objectiveAssignment.objectiveType === ObjectiveTypeCodes.DEFINED_EXTERNAL) {
        this.respondent = await this.externalService.getExternalRespondentByEmail(this.objectiveResponse.respondentEmail);
      } else {
        this.respondent = await this.employeeService.getEmployeeByEmailMemoize(this.objectiveResponse.respondentEmail);
      }
    }

  }

}
