<mat-card appearance="outlined" class="detail-card">
  <mat-card-header>
    <div class="title-wrapper">
      <mat-card-title>{{orgObjective.objective.display}}</mat-card-title>
      <mat-card-title style="text-align: right" *ngIf="assignmentTotal">{{assignmentTotal}} of {{totalUserCount}} Users</mat-card-title>
      <button mat-icon-button class="csv-download-btn" (click)="downloadCSV()" [disabled]="assignmentTotal === undefined" aria-label="Download CSV" title="Download CSV">
        <mat-icon>download</mat-icon>
      </button>
    </div>
    <div class="objectiveSubtitleWrapper">
      <mat-card-subtitle>{{orgObjective.objective.definition}}</mat-card-subtitle>
    </div>
  </mat-card-header>
  <mat-card-content>
    <ul class="objectiveObservables">
      <li *ngFor="let observable of orgObjective.objective.observables">{{observable}}</li>
    </ul>
    <div class="businessObjective" *ngIf="orgObjective.businessObjective">
      <mat-card-subtitle>Business Objective: {{orgObjective.businessObjective}}</mat-card-subtitle>
    </div>
    <ng-container *ngIf="assignmentTotal !== undefined">
      <mat-grid-list cols="3" rowHeight="32px">
        <mat-grid-tile *ngFor="let distributionType of ['capacity','proficiency','delivery']" rowspan="4">
          <mat-card appearance="outlined" [class]="distributionType + (detailsMap?.get(distributionType|uppercase)?.tableData?.length ? ' clickable' : '') +' ' + detailsMap?.get(distributionType|uppercase)?.backgroundColor" (click)="showDistributionList(distributionType.toLocaleUpperCase())">
            <mat-card-header>
              <mat-card-title>{{distributionType | titlecase}}</mat-card-title>
            </mat-card-header>
            <mat-card-content>
              <mat-card-subtitle>{{detailsMap?.get(distributionType|uppercase)?.assigneeCount || 0}} Assignees</mat-card-subtitle>
              <mat-card-subtitle>{{getPercentage(detailsMap?.get(distributionType|uppercase)?.assigneeCount)}}% Users</mat-card-subtitle>
            </mat-card-content>
          </mat-card>
        </mat-grid-tile>
        <mat-grid-tile *ngFor="let distributionType of ['capacity','proficiency','delivery']" rowspan="6">
          <mat-card appearance="outlined">
            <mat-card-content *ngIf="_OBJECTIVE_SURVEY_RESPONSE_TYPES">
              <mat-card-subtitle *ngFor="let responseType of _OBJECTIVE_SURVEY_RESPONSE_TYPES">{{OBJECTIVE_SURVEY_RESPONSE_LABELS[responseType]}} - {{detailsMap?.get(distributionType|uppercase)?.rankings?.get(OBJECTIVE_SURVEY_RESPONSE_VALUES[responseType]) || 0}}</mat-card-subtitle>
            </mat-card-content>
          </mat-card>
        </mat-grid-tile>
      </mat-grid-list>
    </ng-container>
    <ng-container *ngIf="assignmentTotal === undefined">
      <inthraction-loading className="container" [initDone]="true"></inthraction-loading>
    </ng-container>
  </mat-card-content>
</mat-card>
