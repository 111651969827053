import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from "@angular/core";
import { CalendarEventService, EmployeeService, SurveyService, SurveyType } from "@inthraction/services";
import { CalEvent, Employee, EmployeeImageInterface, EventSurvey } from "@inthraction/data-models";
import { EventSurveyResponseOptions } from "@inthraction/codes";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import * as moment from "moment";
import { ToastrService } from "ngx-toastr";
import { Subscription } from "rxjs";
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";
import { MatButton } from "@angular/material/button";
import { SurveyCompleteEvent } from "../survey/survey.component";


@Component({
  selector: "inthraction-event-survey",
  templateUrl: "./eventSurvey.component.html",
  styleUrls: ["./eventSurvey.component.scss"]
})
export class EventSurveyComponent implements OnInit, OnDestroy, OnChanges {

  static surveyIDParameterName = "survey-id";
  static surveyResponseParameterName = "survey-response";
  @ViewChild("didNotAttendBtn") didNotAttendBtn: ElementRef<MatButton | HTMLButtonElement>;
  @ViewChild("submitBtn") submitBtn: ElementRef<MatButton | HTMLButtonElement>;
  surveyResponseOptionsDisplay: string[] = { ...[], ...EventSurvey.SURVEY_RESPONSE_OPTIONS_DISPLAY };
  surveyResponseOptionsTips = EventSurvey.SURVEY_RESPONSE_OPTIONS_TIPS;
  errorMessage = "Please try again.";
  attendee: Employee;
  attendeeImage: EmployeeImageInterface;
  surveyResponseOptionsValues = Object.values(EventSurveyResponseOptions).filter(e => typeof (e) === "number").reverse();
  event: CalEvent = new CalEvent();
  surveyForm: FormGroup;

  private feedback = new FormControl("", []);
  private surveyValue = new FormControl("", [Validators.required]);

  private surveyValueChangeSubscription: Subscription;

  private completedSurveyIDs: string[] = [];

  @Input() survey: EventSurvey;
  @Input() employee: Employee;
  @Input() remainingSurveysCount? = 0;
  @Input() surveyResponse?: number;
  @Output() surveyComplete: EventEmitter<SurveyCompleteEvent> = new EventEmitter<SurveyCompleteEvent>();

  constructor(
    protected surveyService: SurveyService,
    private employeeService: EmployeeService,
    private calendarEventService: CalendarEventService,
    protected toastr: ToastrService,
    protected iconRegistry: MatIconRegistry,
    protected sanitizer: DomSanitizer
  ) {
    this.surveyForm = new FormGroup(
      {
        surveyValue: this.surveyValue,
        feedback: this.feedback
      });
    this.surveyResponseOptionsDisplay[EventSurveyResponseOptions.PROBLEM] = `${EventSurvey.SURVEY_RESPONSE_OPTIONS_DISPLAY[EventSurveyResponseOptions.PROBLEM]} ${EventSurvey.feedbackRequired}`;
    this.surveyResponseOptionsDisplay[EventSurveyResponseOptions.LEAD] = `${EventSurvey.SURVEY_RESPONSE_OPTIONS_DISPLAY[EventSurveyResponseOptions.LEAD]} ${EventSurvey.feedbackRequired}`;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.survey && changes.survey.currentValue) {
      this.surveyForm.reset();
      this.attendee = null;
      this.attendeeImage = null;
      this.event = null;
      this.initialize(changes.survey.currentValue, this.surveyResponse);
    }
    if (changes.surveyResponse && changes.surveyResponse.currentValue) {
      this.initializeResponse(changes.surveyResponse.currentValue);
    }
  }

  ngOnDestroy(): void {
    if (this.surveyValueChangeSubscription) {
      this.surveyValueChangeSubscription.unsubscribe();
    }
  }

  async ngOnInit(): Promise<void> {
    this.surveyValueChangeSubscription = this.surveyForm.get("surveyValue").valueChanges.subscribe(surveyValue => {
      if ((surveyValue === EventSurveyResponseOptions.PROBLEM || surveyValue === EventSurveyResponseOptions.LEAD)) {
        this.surveyForm.get("feedback").setValidators([Validators.required]);
      } else {
        this.surveyForm.get("feedback").setValidators(null);
      }
      this.surveyForm.get("feedback").updateValueAndValidity();
    });

    await this.initialize(this.survey, this.surveyResponse);
  }

  async initialize(survey: EventSurvey, response: number) {
    if (survey) {
      this.event = await this.calendarEventService.getCalendarEventByIDMemoize(survey.objectID);
      this.attendee = await this.employeeService.getEmployeeByEmailMemoize(survey.participantEmail);
      this.attendeeImage = await this.employeeService.getEmployeeImageMemoize(this.attendee.id, this.attendee.orgId);
      this.surveyResponseOptionsDisplay[EventSurveyResponseOptions.DID_NOT_ATTEND] = `${this.attendee.firstName} ${EventSurvey.SURVEY_RESPONSE_OPTIONS_DISPLAY[EventSurveyResponseOptions.DID_NOT_ATTEND]}`;
    }
    await this.initializeResponse(response);
  }

  async initializeResponse(response: number) {
    if ((response || response === 0) && !Number.isNaN(response) && response <= EventSurveyResponseOptions.LEAD) {
      if (response < 0) {
        await this.submitDidNotAttend(this.didNotAttendBtn.nativeElement, this.submitBtn.nativeElement);
      } else {
        this.surveyForm.get("surveyValue").setValue(response);
      }
    } else {
      this.surveyResponse = null;
    }
  }

  public hasError = (controlName: string, errorName: string) =>
    this.surveyForm.controls[controlName].hasError(errorName);

  async submitSurvey(formValue: any, submitBtn: MatButton | HTMLButtonElement): Promise<void> {
    submitBtn.disabled = true;
    if (this.surveyForm.valid) {
      this.survey.surveyResponse = formValue.surveyValue;
      this.survey.feedback = formValue.feedback;
      this.survey.responseReceived = true;
      this.survey.responseReceivedDate = moment().milliseconds(0).toISOString().replace(".000Z", "Z");
      await this.surveyService.updateSurvey(
        {
          id: this.survey.id,
          objectID: this.survey.objectID,
          feedback: ((this.survey.feedback == null || this.survey.feedback.length <= 0) ? null : this.survey.feedback),
          surveyResponse: this.survey.surveyResponse,
          responseReceived: true,
          responseReceivedDate: this.survey.responseReceivedDate,
          surveyDate: this.survey.surveyDate,
          surveyType: SurveyType[this.survey.surveyType]
        });
      this.toastr.success("Thank you for your response", "intHRaction Received");
      this.surveyComplete.emit({ completedSurveyIDs: [this.survey.id] });
    } else {
      submitBtn.disabled = !this.surveyForm.valid;
      this.toastr.error(this.errorMessage);
    }
  }

  async submitDidNotAttend(didNotAttendBtn: MatButton | HTMLButtonElement, submitBtn: MatButton | HTMLButtonElement): Promise<void> {
    didNotAttendBtn.disabled = true;
    submitBtn.disabled = true;
    if (confirm("Are you sure you did not attend?")) {
      const eventSurveyList = await this.surveyService.getSurveysByObjectIDAndEmail(this.attendee.orgId, this.survey.objectID, this.survey.respondentEmail);
      const responseReceivedDate = moment().milliseconds(0).toISOString().replace(".000Z", "Z");
      for (const survey of eventSurveyList) {
        let response;
        if (survey.respondentEmail === this.survey.respondentEmail) {
          response = {
            id: survey.id,
            objectID: survey.objectID,
            feedback: null,
            surveyResponse: null,
            responseReceived: true,
            respondentAttended: false,
            responseReceivedDate,
            surveyDate: survey.surveyDate,
            surveyType: SurveyType[survey.surveyType]
          };
        } else {
          response = {
            id: survey.id,
            objectID: survey.objectID,
            surveyResponse: EventSurveyResponseOptions.DID_NOT_ATTEND,
            responseReceived: true,
            responseReceivedDate,
            surveyDate: survey.surveyDate,
            surveyType: SurveyType[survey.surveyType]
          };
        }
        await this.surveyService.updateSurvey(response);
        this.completedSurveyIDs.push(survey.id);
      }
      this.toastr.success("Thank you for your response", "intHRaction Received");
      this.surveyComplete.emit({ completedSurveyIDs: [...this.completedSurveyIDs] });
    } else {
      didNotAttendBtn.disabled = false;
      submitBtn.disabled = !this.surveyForm.valid;
    }
  }

}
