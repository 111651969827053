<h1 mat-dialog-title>{{addMode ? 'Add' : 'Edit'}} Organization</h1>
<form [formGroup]="organizationFG">
  <div mat-dialog-content>
    <mat-form-field class="half-width left">
      <mat-label>Name</mat-label>
      <input matInput formControlName="name">
      <mat-error *ngIf="hasError(organizationFG, 'required', 'name')">Organization Name is required</mat-error>
    </mat-form-field>

    <mat-form-field class="half-width left">
      <mat-label>Site</mat-label>
      <mat-select formControlName="site">
        <mat-option *ngFor="let site of SiteOptions" [value]="site">{{site}}</mat-option>
      </mat-select>
      <mat-error *ngIf="hasError(organizationFG, 'required', 'site')">Site is required</mat-error>
    </mat-form-field>

    <mat-form-field floatLabel="always" class="half-width left slide-toggle-form-field">
      <mat-label>Disabled</mat-label>
      <mat-slide-toggle formControlName="disabled"></mat-slide-toggle>
      <input matInput hidden height="0" style="height: 0" />
    </mat-form-field>

    <mat-card appearance="outlined">
      <mat-card-subtitle>Domains</mat-card-subtitle>
      <ng-container *ngFor="let domain of organization.domains; let i = index">
        <mat-form-field>
          <input matInput [(ngModel)]="organization.domains[i]" [ngModelOptions]="{standalone: true, updateOn: 'blur'}" placeholder="example.com" (blur)="updateDomains()">
        </mat-form-field>
      </ng-container>
      <mat-error *ngIf="hasError(organizationFG, 'required', 'domains')">An Organization Domain is required</mat-error>
      <mat-card-actions>
        <a (click)="organization.domains.push('')" aria-label="Add New Domain" mat-icon-button title="Add New Domain">
          <mat-icon>add</mat-icon>
        </a>
      </mat-card-actions>
    </mat-card>

  </div>
  <div mat-dialog-actions>
    <button (click)="onCancelClick()" mat-button>Cancel</button>
    <button #submitBtn type="submit" (click)="onSaveClick(organizationFG.value, submitBtn)" cdkFocusInitial mat-raised-button color="primary" [disabled]="organizationFG.invalid">Save</button>
  </div>
</form>
