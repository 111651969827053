<form (ngSubmit)="onSaveClick(shoutOutTypeForm.value, submitBtn)" [formGroup]="shoutOutTypeForm" autocomplete="off" novalidate>
  <h1 mat-dialog-title>{{addMode ? 'Add' : 'Edit'}} insp<span class="inthraction-highlight">HR</span>ation Type</h1>
  <div mat-dialog-content>
    <mat-form-field>
      <mat-label>Title</mat-label>
      <input matInput formControlName="title">
      <mat-error *ngIf="hasError('title', 'required')">Title is required</mat-error>
    </mat-form-field>

    <mat-form-field>
      <mat-select [formControl]="shoutOutTypeForm.get('icon')" matInput placeholder="Icon">
        <mat-select-trigger>
          <div *ngIf="shoutOutTypeForm.get('icon').value">
            <mat-icon [svgIcon]="shoutOutTypeForm.get('icon').value"></mat-icon>
            {{shoutOutIconLabels[shoutOutValueToKeyMap.get(shoutOutTypeForm.get('icon').value)]}}
          </div>
        </mat-select-trigger>
        <mat-option *ngFor="let key of shoutOutIconKeys" [value]="shoutOutIcons[key]">
          <mat-icon [svgIcon]="shoutOutIcons[key]"></mat-icon>
          {{shoutOutIconLabels[key]}}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="hasError('icon', 'required')">Icon is required</mat-error>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Formal Description</mat-label>
      <textarea matInput formControlName="formalDescription" maxlength="500" rows="3"></textarea>
      <mat-error *ngIf="hasError('formalDescription', 'required')">Formal Description is required</mat-error>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Informal Description</mat-label>
      <textarea matInput formControlName="informalDescription" maxlength="500" rows="3"></textarea>
      <mat-error *ngIf="hasError('informalDescription', 'required')">Informal Description is required</mat-error>
    </mat-form-field>

    <mat-card appearance="outlined">
      <mat-card-subtitle>Attributes</mat-card-subtitle>
      <ng-container *ngFor="let attribute of attributes; let i = index">
        <mat-form-field>
          <mat-label>Attribute {{i + 1}}</mat-label>
          <textarea matInput [(ngModel)]="attributes[i]" [ngModelOptions]="{standalone: true, updateOn: 'blur'}"></textarea>
        </mat-form-field>
      </ng-container>
      <mat-card-actions>
        <a (click)="attributes.push('')" aria-label="Add New Attribute" mat-icon-button title="Add New Attribute">
          <mat-icon>add</mat-icon>
        </a>
      </mat-card-actions>
    </mat-card>
  </div>
  <div mat-dialog-actions>
    <button (click)="onCancelClick()" mat-button type="button">Cancel</button>
    <button #submitBtn [disabled]="!shoutOutTypeForm.valid" color="primary" mat-raised-button type="submit">Save</button>
  </div>
</form>
