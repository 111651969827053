import { Injectable } from "@angular/core";
import { EmployeeScore, MilestoneComment, Note, Project, ProjectMilestoneAssignment } from "@inthraction/data-models";
import {
  APIService,
  CreateNoteMutation,
  CreateProjectInput,
  CreateProjectMilestoneAssignmentInput,
  CreateProjectMilestoneAssignmentMutation,
  GetNoteQuery,
  GetProjectMilestoneAssignmentQuery,
  ModelProjectFilterInput,
  UpdateProjectInput,
  UpdateProjectMilestoneAssignmentInput
} from "./API.service";
import { Memoize, MEMOIZE_FN_MAP } from "@inthraction/utils";
import { NoteTypes, ScoreTypes } from "@inthraction/codes";
import * as moment from "moment";
import { BaseService } from "./base.service";
import { AuthService } from "./auth.service";

export interface CreateProject extends CreateProjectInput {
}

export interface UpdateProject extends UpdateProjectInput {
}

export interface UpdateProjectMilestoneAssignment extends UpdateProjectMilestoneAssignmentInput {
}

@Injectable({
  providedIn: "root"
})
export class ProjectService extends BaseService {

  constructor(
    protected api: APIService,
    protected authService: AuthService
  ) {
    super(api, authService);
  }

  clearMemoizedProjectsForUser(id: string) {
    if (MEMOIZE_FN_MAP.has("getAllProjectsForUserMemoized")) {
      MEMOIZE_FN_MAP.get("getAllProjectsForUserMemoized").delete(id);
    }

    if (MEMOIZE_FN_MAP.has("getAllProjectsForConsultantUserMemoized")) {
      MEMOIZE_FN_MAP.get("getAllProjectsForConsultantUserMemoized").clear();
    }

    if (MEMOIZE_FN_MAP.has("getProjectByIDMemoized")) {
      MEMOIZE_FN_MAP.get("getProjectByIDMemoized").clear();
    }
  }

  clearMemoizedMilestones(projectID: string) {
    if (MEMOIZE_FN_MAP.has("getProjectMilestoneAssignmentsByProjectIDMemoized")) {
      MEMOIZE_FN_MAP.get("getProjectMilestoneAssignmentsByProjectIDMemoized").delete(projectID);
    }
    if (MEMOIZE_FN_MAP.has("getProjectMilestoneAssignmentByID")) {
      MEMOIZE_FN_MAP.get("getProjectMilestoneAssignmentByID").clear();
    }
  }

  clearMemoizedNotes(projectID: string) {
    if (MEMOIZE_FN_MAP.has("getProjectNotesByProjectIDMemoized")) {
      MEMOIZE_FN_MAP.get("getProjectNotesByProjectIDMemoized").delete(projectID, true);
      MEMOIZE_FN_MAP.get("getProjectNotesByProjectIDMemoized").delete(projectID, false);
    }
  }

  clearMemoizedProject(projectID: string) {
    if (MEMOIZE_FN_MAP.has("getProjectByIDMemoized")) {
      MEMOIZE_FN_MAP.get("getProjectByIDMemoized").delete(projectID);
    }
  }

  @Memoize({ maxAge: 600000, preFetch: true })
  async getAllProjectsForConsultantUserMemoized(employeeID: string, organizationID: string) {
    return this.getAllProjectsForUser(employeeID, organizationID);
  }

  @Memoize({ maxAge: 600000, preFetch: true })
  async getAllProjectsForUserMemoized(id: string): Promise<Project[]> {
    return this.getAllProjectsForUser(id);
  }

  async getAllProjectsForUser(id: string, organizationID?: string): Promise<Project[]> {
    if (!organizationID) {
      organizationID = (await this.getCurrentUser()).orgId;
    }
    const filter: ModelProjectFilterInput = {
      and: [
        { organizationID: { eq: organizationID } },
        { endDate: { gt: moment().subtract(32, "days").format("YYYY-MM-DD") } },
        {
          or: [
            { projectManagerID: { eq: id } },
            { team: { contains: id } }
          ]
        }
      ]
    };
    return this.getAll<Project>(this.api.ListProjects, filter);
  }

  async createProject(newProject: CreateProjectInput): Promise<Project> {
    return await this.api.CreateProject(newProject);
  }

  async updateProjectTeam(project: Project): Promise<Project> {
    const projectResult = await this.api.UpdateProject({ id: project.id, team: project.team });
    this.clearMemoizedProjectsByProject(projectResult);
    return projectResult;
  }

  @Memoize({ maxAge: 600000, preFetch: true })
  async getProjectMilestoneAssignmentsByProjectIDMemoized(id: string): Promise<ProjectMilestoneAssignment[]> {
    return this.getProjectMilestoneAssignmentsByProjectID(id);
  }

  async getProjectMilestoneAssignmentsByProjectID(id: string): Promise<ProjectMilestoneAssignment[]> {
    const filter = { projectID: { eq: id } };
    const results = await this.getAll<CreateProjectMilestoneAssignmentMutation | GetProjectMilestoneAssignmentQuery>(this.api.ListProjectMilestoneAssignments, filter);
    return results.map(ProjectMilestoneAssignment.constructFromCreateProjectMilestoneAssignmentMutation);
  }

  async createProjectMilestone(milestone: ProjectMilestoneAssignment): Promise<ProjectMilestoneAssignment> {
    const createMilestone: CreateProjectMilestoneAssignmentInput = {
      projectID: milestone.projectID,
      organizationID: milestone.organizationID,
      milestoneType: milestone.milestoneType,
      title: milestone.title,
      description: milestone.description,
      startDate: milestone.startDate,
      endDate: milestone.endDate,
      cadence: milestone.cadence,
      nextSurveyDate: milestone.nextSurveyDate,
      assignedBy: milestone.assignedBy,
      assignmentDate: milestone.assignmentDate
    };
    if (milestone.team && milestone.team.length > 0) {
      createMilestone.team = milestone.team;
    }
    return ProjectMilestoneAssignment.constructFromCreateProjectMilestoneAssignmentMutation(await this.api.CreateProjectMilestoneAssignment(createMilestone));
  }

  async getProjectNotesByProjectID(projectID: string, isPM: boolean): Promise<Note[]> {
    let filter;
    if (isPM) {
      filter = { objectID: { eq: projectID } };
    } else {
      filter = { and: [{ objectID: { eq: projectID } }, { noteType: { eq: NoteTypes.PROJECT } }] };
    }
    return (await this.getAll<CreateNoteMutation | GetNoteQuery>(this.api.ListNotes, filter)).map(i => Note.constructFromCreateNoteMutation(i));
  }

  @Memoize({ maxAge: 600000, preFetch: true })
  async getProjectNotesByProjectIDMemoized(projectID: string, isPM: boolean): Promise<Note[]> {
    return await this.getProjectNotesByProjectID(projectID, isPM);
  }

  async updateProject(project: UpdateProjectInput): Promise<Project> {
    const projectResult = await this.api.UpdateProject(project);
    this.clearMemoizedProjectsByProject(projectResult);
    return projectResult;
  }

  async updateMilestone(milestoneAssignment: UpdateProjectMilestoneAssignmentInput): Promise<ProjectMilestoneAssignment> {
    return ProjectMilestoneAssignment.constructFromCreateProjectMilestoneAssignmentMutation(await this.api.UpdateProjectMilestoneAssignment(milestoneAssignment));
  }

  async getProjectMilestoneAssignmentByID(milestoneAssignmentID: string): Promise<ProjectMilestoneAssignment> {
    return ProjectMilestoneAssignment.constructFromCreateProjectMilestoneAssignmentMutation(await this.api.GetProjectMilestoneAssignment(milestoneAssignmentID));
  }

  async getProjectByID(projectID: string): Promise<Project> {
    return await this.api.GetProject(projectID);
  }

  @Memoize({ maxAge: 600000, preFetch: true })
  async getProjectByIDMemoized(projectID: string) {
    return this.getProjectByID(projectID);
  }

  @Memoize({ maxAge: 600000, preFetch: true })
  async getProjectMilestoneAssignmentByIDMemoized(objectID: string) {
    return this.getProjectMilestoneAssignmentByID(objectID);
  }

  @Memoize({ maxAge: 600000, preFetch: true })
  async getEmployeeYTDProjectScoresForProjectMemoize(projectID: string, scoreStartMoment: string): Promise<EmployeeScore[]> {
    const filter = {
      and: [
        { scoreID: { eq: projectID } },
        { scoreType: { eq: ScoreTypes.YTDProjectScore } },
        { scoreStart: { ge: scoreStartMoment.replace(".000Z", "Z") } }
      ]
    };
    return this.getAll<EmployeeScore>(this.api.ListEmployeeScores, filter);
  }

  @Memoize({ maxAge: 600000, preFetch: true })
  async getEmployeeYTDMilestoneScoresForMilestoneMemoize(milestoneID: string, scoreStartMoment: string): Promise<EmployeeScore[]> {
    const filter = {
      and: [
        { scoreID: { eq: milestoneID } },
        { scoreType: { eq: ScoreTypes.YTDMilestoneScore } },
        { scoreStart: { ge: scoreStartMoment.replace(".000Z", "Z") } }
      ]
    };
    return this.getAll<EmployeeScore>(this.api.ListEmployeeScores, filter);
  }

  async getEmployeeProjectScores(employeeID: string, projectID: string, surveyDate: string): Promise<EmployeeScore[]> {
    const filter = {
      and: [
        { employeeID: { eq: employeeID } },
        { scoreType: { eq: ScoreTypes.ProjectScore } },
        { scoreID: { eq: projectID } },
        { specifier: { eq: surveyDate } }
      ]
    };
    return this.getAll<EmployeeScore>(this.api.ListEmployeeScores, filter);
  }

  async getMilestoneScores(scoreID: string, surveyID: string): Promise<EmployeeScore[]> {
    const filter = {
      and: [
        { scoreType: { eq: ScoreTypes.MilestoneScore } },
        { scoreID: { eq: scoreID } },
        { specifier: { eq: surveyID } }
      ]
    };
    return this.getAll<EmployeeScore>(this.api.ListEmployeeScores, filter);
  }

  private clearMemoizedProjectsByProject(projectResult: Project) {
    this.clearMemoizedProjectsForUser(projectResult.projectManagerID);
    if (projectResult.team) {
      for (const id of projectResult.team) {
        this.clearMemoizedProjectsForUser(id);
      }
    }
  }

  async createProjectMilestoneComment(id: string, organizationID: string, value: string): Promise<MilestoneComment> {
    const user = await this.getCurrentUser();
    return this.api.CreateMilestoneComment({
      milestoneID: id,
      organizationID: organizationID,
      comment: value,
      createdBy: user.id,
      createdAt: moment().toISOString()
    });
  }
}
