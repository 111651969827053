<div class="login-form-wrapper" *ngIf="authenticator.route !== 'authenticated'">
<!--  <img class="login-form-bg" src="/assets/Wheel%20Blank@4x.png" >-->
  <div class="image-left">
    <img class="info-image"src="/assets/Experience@4x.png">
    <img class="info-image"src="/assets/Culture@4x.png">
    <img class="info-image"src="/assets/Performance@4x.png">
    <img class="info-image"src="/assets/1-1@4x.png">
  </div>
  <div class="login-form-content">
  <amplify-authenticator hideSignUp="true">
    <ng-template amplifySlot="header">
      <div style="padding: var(--amplify-space-large); text-align: center">
        <img class="amplify-image" alt="site Logo" [src]="logo" />
      </div>
    </ng-template>
  </amplify-authenticator>
  </div>
  <div class="image-right">
    <img class="info-image"src="/assets/Analytics@4x.png">
    <img class="info-image"src="/assets/Voice@4x.png">
    <img class="info-image"src="/assets/Stand-Ups@4x.png">
    <img class="info-image"src="/assets/Succession@4x.png">
  </div>
</div>
