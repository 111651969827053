import { Component, Input, OnInit } from "@angular/core";
import { Employee, StandupSurveyConfiguration } from "@inthraction/data-models";
import { EmployeeService} from "@inthraction/services";

@Component({
  selector: 'inthraction-consultant-stand-up',
  templateUrl: './consultant-stand-up.component.html',
  styleUrls: ['./consultant-stand-up.component.scss']
})
export class ConsultantStandUpComponent implements OnInit {

  sharedStandupManagers: Employee[] = [];

  @Input() private standupConfigurations: StandupSurveyConfiguration[];

  constructor(
    private employeeService: EmployeeService,
  ) {}


  async ngOnInit() {
    await this.initializeData(this.standupConfigurations);
  }

  private async initializeData(standupConfigurations: StandupSurveyConfiguration[]) {

    // Get Shared Standup Configurations...
    for(let configuration of standupConfigurations) {
      const manager = await this.employeeService.getEmployeeByIDMemoize(configuration.employeeID);
      this.sharedStandupManagers.push(manager);
    }
  }

}
