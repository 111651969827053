import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { EmployeeService } from "@inthraction/services";
import { Employee, EmployeeImpl } from "@inthraction/data-models";
import { Subscription } from "rxjs";
import {
  ObjectiveResponseDistributionDialogComponent
} from "../objective-response-distribution/objective-response-distribution-dialog/objective-response-distribution-dialog.component";
import {
  ObjectiveResponseDistributionMode
} from "../objective-response-distribution/objective-response-distribution.component";
import { MatDialog } from "@angular/material/dialog";

@Component({
  selector: "inthraction-employee-settings",
  templateUrl: "./employee-settings.component.html",
  styleUrls: ["./employee-settings.component.scss"]
})
export class EmployeeSettingsComponent implements OnInit, OnDestroy {

  static EMPLOYEE_ID_PARAMETER = "employee-id";

  employeeID: string;
  employee: Employee;
  manager: Employee;
  private parameterMapSubscription: Subscription;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private activatedRoute: ActivatedRoute,
    private employeeService: EmployeeService,
    public dialog: MatDialog
  ) {
  }

  async ngOnInit(): Promise<void> {
    this.employeeID = this.route.snapshot.paramMap.get(EmployeeSettingsComponent.EMPLOYEE_ID_PARAMETER);
    this.manager = await this.employeeService.getCurrentEmployee();

    await this.loadEmployeeSettings(this.employeeID);

    this.parameterMapSubscription = this.route.paramMap.subscribe(async queryParams => {
      this.employeeID = queryParams.get(EmployeeSettingsComponent.EMPLOYEE_ID_PARAMETER);
      await this.loadEmployeeSettings(this.employeeID);
    });

  }

  async loadEmployeeSettings(employeeID: string): Promise<void> {
    const userResult = await this.employeeService.getEmployeeByIDMemoize(employeeID);
    if (employeeID && this.manager.id === userResult?.managerID) {
      this.employee = new EmployeeImpl(userResult);
      if (!this.employee) {
        this.router.navigate(["/manager"]);
      }
    } else {
      this.router.navigate(["/manager"]);
    }
  }

  ngOnDestroy(): void {
    if (this.parameterMapSubscription) {
      this.parameterMapSubscription.unsubscribe();
    }
  }

  showObjectiveResponseDistributions(employee: Employee) {
    this.dialog.open(ObjectiveResponseDistributionDialogComponent, {
      width: "100%",
      height: "90%",
      data: { employee, mode: ObjectiveResponseDistributionMode.MANAGER }
    });
  }

}
