<h1 *ngIf="!formEditMode" mat-dialog-title>Add Objectives</h1>
<div *ngIf="formEditMode" class="edit-title-wrapper">
  <h1 *ngIf="formEditMode" mat-dialog-title>Edit Objectives</h1>
  <mat-icon (click)="onDeleteClick()" *ngIf="formEditMode" title="Delete Objective Assignment">delete</mat-icon>
</div>
<ng-container *ngIf="objectiveType && 'DEFINED' == objectiveType">
  <form (ngSubmit)="onSaveClick(predefinedPeerRatedObjectiveForm.value, submitBtn)" [formGroup]="predefinedPeerRatedObjectiveForm" autocomplete="off" novalidate>
    <div mat-dialog-content>
      <mat-form-field class="full-width">
        <mat-label>Objective Type</mat-label>
        <inthraction-help-bubble [message]="OBJECTIVE_TYPE_HELP" title="Objective Type" tip="Objective Type Help"></inthraction-help-bubble>
        <mat-select [value]="objectiveType" (valueChange)="changeObjectiveType($event)" [disabled]="formEditMode">
          <mat-option *ngFor="let id of objectiveTypes" [value]="id">{{objectiveTypeLabels[id]}}</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field class="full-width">
        <mat-label>Objective Domain</mat-label>
        <inthraction-help-bubble [message]="OBJECTIVE_DOMAIN_HELP" title="Objective Domain" tip="Objective Domain Help"></inthraction-help-bubble>
        <mat-select formControlName="objectiveDomain">
          <mat-option *ngFor="let domain of organizationObjectiveDomains" [value]="domain.id" title="{{domain.display}} : {{getObjectiveTitles(domain.id)}}">
            {{domain.display}}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="hasError('objectiveDomain', 'required')">Objective Domain is required</mat-error>
      </mat-form-field>

      <mat-form-field class="full-width"
                      *ngIf="domainToOrganizationObjectivesMap && objectiveForm.get('objectiveDomain').value">
        <mat-label>Objective</mat-label>
        <inthraction-help-bubble [message]="OBJECTIVE_HELP" title="Objective" tip="Objective Help"></inthraction-help-bubble>
        <mat-select formControlName="organizationObjective">
          <mat-option
            *ngFor="let objective of domainToOrganizationObjectivesMap.get(objectiveForm.get('objectiveDomain').value)"
            [value]="objective.id" title="{{objective.objective.display}} : {{objective.objective.definition}}">
            {{objective.objective.display}}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="hasError('organizationObjective', 'required')">Objective is required</mat-error>
      </mat-form-field>

      <mat-form-field class="full-width">
        <mat-label>Focus</mat-label>
        <inthraction-help-bubble [message]="FOCUS_HELP" title="Focus" tip="Focus Help"></inthraction-help-bubble>
        <mat-select formControlName="objectiveFocus">
          <mat-option *ngFor="let focus of focuses" [value]="focus.id">
            {{focus.display}}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="hasError('objectiveFocus', 'required')">Focus is required</mat-error>
      </mat-form-field>

      <mat-form-field class="full-width">
        <mat-label>Time Frame</mat-label>
        <inthraction-help-bubble [message]="TIME_FRAME_HELP" title="Time Frame" tip="Time Frame Help"></inthraction-help-bubble>
        <mat-select formControlName="objectiveTimeFrame">
          <mat-option *ngFor="let priority of priorities" [value]="priority.id">
            {{priority.display}}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="hasError('objectiveTimeFrame', 'required')">Time Frame is required</mat-error>
      </mat-form-field>

      <mat-form-field class="full-width">
        <mat-label>Manager Objective</mat-label>
        <inthraction-help-bubble [message]="MANAGER_OBJECTIVE_HELP" title="Manager Objective" tip="Manager Objective Help"></inthraction-help-bubble>
        <textarea matInput formControlName="managerDescription" maxlength="256"></textarea>
        <mat-error *ngIf="hasError('managerDescription', 'required')">Manager Objective is required</mat-error>
      </mat-form-field>

      <mat-form-field class="half-width">
        <mat-label>Due Date</mat-label>
        <inthraction-help-bubble [message]="DUE_DATE_HELP" title="Due Date" tip="Due Date Help"></inthraction-help-bubble>
        <input [matDatepicker]="dueDate" formControlName="endDate" matInput>
        <mat-datepicker-toggle [for]="dueDate" matSuffix></mat-datepicker-toggle>
        <mat-datepicker #dueDate></mat-datepicker>
        <mat-error *ngIf="hasError('endDate', 'required')">Due Date is required</mat-error>
        <mat-error *ngIf="hasError('endDate', 'notCurrent')">Due Date must be greater than or equal to the current date</mat-error>
      </mat-form-field>
    </div>
    <div mat-dialog-actions class="form-actions">
      <button (click)="onCancelClick()" mat-button type="button">Cancel</button>
      <button #submitBtn [disabled]="!objectiveForm.valid" color="primary" mat-raised-button type="submit">Save</button>
    </div>
  </form>
</ng-container>
<ng-container *ngIf="objectiveType && 'QUANTIFIABLE' == objectiveType">
  <form (ngSubmit)="onSaveClick(quantifiableObjectiveForm.value, submitBtn)" [formGroup]="quantifiableObjectiveForm" autocomplete="off" novalidate>
    <div mat-dialog-content>
      <mat-form-field class="full-width">
        <mat-label>Objective Type</mat-label>
        <inthraction-help-bubble [message]="OBJECTIVE_TYPE_HELP" title="Objective Type" tip="Objective Type Help"></inthraction-help-bubble>
        <mat-select [value]="objectiveType" (valueChange)="changeObjectiveType($event)">
          <mat-option *ngFor="let id of objectiveTypes" [value]="id">{{objectiveTypeLabels[id]}}</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field class="full-width">
        <mat-label>Objective Title</mat-label>
        <inthraction-help-bubble [message]="OBJECTIVE_TITLE_HELP" title="Objective Type" tip="Objective Type Help"></inthraction-help-bubble>
        <textarea matInput maxlength="50" formControlName="objectiveTitle"></textarea>
        <mat-error *ngIf="hasError('objectiveTitle', 'required')">Objective Title is required</mat-error>
      </mat-form-field>

      <mat-form-field class="full-width">
        <mat-label>Manager Objective</mat-label>
        <inthraction-help-bubble [message]="MANAGER_DEFINED_QUANTIFIABLE_HELP" title="Manager Objective" tip="Manager Objective Help"></inthraction-help-bubble>
        <textarea matInput formControlName="managerDescription" maxlength="256"></textarea>
        <mat-error *ngIf="hasError('managerDescription', 'required')">Manager Objective is required</mat-error>
      </mat-form-field>

      <mat-form-field class="half-width">
        <mat-label>Start Date</mat-label>
        <inthraction-help-bubble [message]="START_DATE_HELP" title="Start Date" tip="Start Date Help"></inthraction-help-bubble>
        <input [matDatepicker]="startDate" formControlName="startDate" matInput>
        <mat-datepicker-toggle [for]="startDate" matSuffix></mat-datepicker-toggle>
        <mat-datepicker #startDate></mat-datepicker>
        <mat-error *ngIf="hasError('startDate', 'required')">Start Date is required</mat-error>
        <mat-error *ngIf="hasError('startDate', 'notCurrent')">Start Date must be greater than or equal to the current date</mat-error>
      </mat-form-field>

      <mat-form-field class="half-width">
        <mat-label>Status Frequency</mat-label>
        <inthraction-help-bubble [message]="FREQUENCY_HELP" title="Status Frequency" tip="Status Frequency Help"></inthraction-help-bubble>
        <mat-select formControlName="cadence">
          <mat-option *ngFor="let id of objectiveCadenceTypes"
                      [value]="id">{{objectiveCadenceTypeLabels[id]}}</mat-option>
        </mat-select>
        <mat-error *ngIf="hasError('cadence', 'required')">Status Frequency is required</mat-error>
      </mat-form-field>

      <mat-form-field class="half-width">
        <mat-label>End Date</mat-label>
        <inthraction-help-bubble [message]="END_DATE_HELP" title="End Date" tip="End Date Help"></inthraction-help-bubble>
        <input [matDatepicker]="dueDate" formControlName="endDate" matInput>
        <mat-datepicker-toggle [for]="dueDate" matSuffix></mat-datepicker-toggle>
        <mat-datepicker #dueDate></mat-datepicker>
        <mat-error *ngIf="hasError('endDate', 'required')">End Date is required</mat-error>
        <mat-error *ngIf="hasError('endDate', 'notCurrent')">End Date must be greater than or equal to the current date</mat-error>
      </mat-form-field>

      <mat-form-field class="half-width">
        <mat-label>Quantity</mat-label>
        <inthraction-help-bubble [message]="QUANTITY_HELP" title="Quantity" tip="Quantity Help"></inthraction-help-bubble>
        <input formControlName="quantity" type="number" matInput maxlength="50">
        <mat-error *ngIf="hasError('quantity', 'required')">Quantity is required</mat-error>
      </mat-form-field>
    </div>
    <div mat-dialog-actions class="form-actions">
      <button (click)="onCancelClick()" mat-button type="button">Cancel</button>
      <button #submitBtn [disabled]="!objectiveForm.valid" color="primary" mat-raised-button type="submit">Save</button>
      <mat-error *ngIf="hasError(null, 'range')">Start Date must be before End Date</mat-error>
    </div>
  </form>
</ng-container>
<ng-container *ngIf="objectiveType && ('DEFINED_SELF' == objectiveType || 'DEFINED_MANAGER' == objectiveType)">
  <form (ngSubmit)="onSaveClick(predefinedManagerOrSelfRatedObjectiveForm.value, submitBtn)" [formGroup]="predefinedManagerOrSelfRatedObjectiveForm" autocomplete="off" novalidate>
    <div mat-dialog-content>
      <mat-form-field class="full-width">
        <mat-label>Objective Type</mat-label>
        <mat-select [value]="objectiveType" (valueChange)="changeObjectiveType($event)" [disabled]="formEditMode">
          <mat-option *ngFor="let id of objectiveTypes" [value]="id">{{objectiveTypeLabels[id]}}</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field class="full-width">
        <mat-label>Objective Domain</mat-label>
        <mat-select formControlName="objectiveDomain">
          <mat-option *ngFor="let domain of organizationObjectiveDomains" [value]="domain.id">
            {{domain.display}}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="hasError('objectiveDomain', 'required')">Objective Domain is required</mat-error>
      </mat-form-field>

      <mat-form-field class="full-width"
                      *ngIf="domainToOrganizationObjectivesMap && objectiveForm.get('objectiveDomain').value">
        <mat-label>Objective</mat-label>
        <mat-select formControlName="organizationObjective">
          <mat-option
            *ngFor="let objective of domainToOrganizationObjectivesMap.get(objectiveForm.get('objectiveDomain').value)"
            [value]="objective.id">
            {{objective.objective.display}}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="hasError('organizationObjective', 'required')">Objective is required</mat-error>
      </mat-form-field>

      <mat-form-field class="full-width">
        <mat-label>Focus</mat-label>
        <mat-select formControlName="objectiveFocus">
          <mat-option *ngFor="let focus of focuses" [value]="focus.id">
            {{focus.display}}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="hasError('objectiveFocus', 'required')">Focus is required</mat-error>
      </mat-form-field>

      <mat-form-field class="full-width">
        <mat-label>Time Frame</mat-label>
        <mat-select formControlName="objectiveTimeFrame">
          <mat-option *ngFor="let priority of priorities" [value]="priority.id">
            {{priority.display}}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="hasError('objectiveTimeFrame', 'required')">Time Frame is required</mat-error>
      </mat-form-field>

      <mat-form-field class="full-width">
        <mat-label>Manager Objective</mat-label>
        <textarea matInput formControlName="managerDescription" maxlength="256"></textarea>
        <mat-error *ngIf="hasError('managerDescription', 'required')">Manager Objective is required</mat-error>
      </mat-form-field>

      <mat-form-field class="half-width">
        <mat-label>Start Date</mat-label>
        <input [matDatepicker]="startDate" formControlName="startDate" matInput>
        <mat-datepicker-toggle [for]="startDate" matSuffix></mat-datepicker-toggle>
        <mat-datepicker #startDate></mat-datepicker>
        <mat-error *ngIf="hasError('startDate', 'required')">Start Date is required</mat-error>
        <mat-error *ngIf="hasError('startDate', 'notCurrent')">Start Date must be greater than or equal to the current date</mat-error>
      </mat-form-field>

      <mat-form-field class="half-width">
        <mat-label>Status Frequency</mat-label>
        <mat-select formControlName="cadence">
          <mat-option *ngFor="let id of objectiveCadenceTypes"
                      [value]="id">{{objectiveCadenceTypeLabels[id]}}</mat-option>
        </mat-select>
        <mat-error *ngIf="hasError('cadence', 'required')">Status Frequency is required</mat-error>
      </mat-form-field>

      <mat-form-field class="half-width">
        <mat-label>End Date</mat-label>
        <input [matDatepicker]="dueDate" formControlName="endDate" matInput>
        <mat-datepicker-toggle [for]="dueDate" matSuffix></mat-datepicker-toggle>
        <mat-datepicker #dueDate></mat-datepicker>
        <mat-error *ngIf="hasError('endDate', 'required')">End Date is required</mat-error>
        <mat-error *ngIf="hasError('endDate', 'notCurrent')">End Date must be greater than or equal to the current date</mat-error>
      </mat-form-field>

    </div>
    <div mat-dialog-actions class="form-actions">
      <button (click)="onCancelClick()" mat-button type="button">Cancel</button>
      <button #submitBtn [disabled]="!objectiveForm.valid" color="primary" mat-raised-button type="submit">Save</button>
      <mat-error *ngIf="hasError(null, 'range')">Start Date must be before End Date</mat-error>
    </div>
  </form>
</ng-container>
<ng-container *ngIf="objectiveType && ('DEFINED_EXTERNAL' == objectiveType)">
  <form (ngSubmit)="onSaveClick(predefinedExternalRatedObjectiveForm.value, submitBtn)" [formGroup]="predefinedExternalRatedObjectiveForm" autocomplete="off" novalidate>
    <div mat-dialog-content>
      <mat-form-field class="full-width">
        <mat-label>Objective Type</mat-label>
        <mat-select [value]="objectiveType" (valueChange)="changeObjectiveType($event)" [disabled]="formEditMode">
          <mat-option *ngFor="let id of objectiveTypes" [value]="id">{{objectiveTypeLabels[id]}}</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field class="full-width">
        <mat-label>Objective Domain</mat-label>
        <mat-select formControlName="objectiveDomain">
          <mat-option *ngFor="let domain of organizationObjectiveDomains" [value]="domain.id">
            {{domain.display}}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="hasError('objectiveDomain', 'required')">Objective Domain is required</mat-error>
      </mat-form-field>

      <mat-form-field class="full-width"
                      *ngIf="domainToOrganizationObjectivesMap && objectiveForm.get('objectiveDomain').value">
        <mat-label>Objective</mat-label>
        <mat-select formControlName="organizationObjective">
          <mat-option
            *ngFor="let objective of domainToOrganizationObjectivesMap.get(objectiveForm.get('objectiveDomain').value)"
            [value]="objective.id">
            {{objective.objective.display}}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="hasError('organizationObjective', 'required')">Objective is required</mat-error>
      </mat-form-field>

      <mat-form-field class="full-width">
        <mat-label>Focus</mat-label>
        <mat-select formControlName="objectiveFocus">
          <mat-option *ngFor="let focus of focuses" [value]="focus.id">
            {{focus.display}}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="hasError('objectiveFocus', 'required')">Focus is required</mat-error>
      </mat-form-field>

      <mat-form-field class="full-width">
        <mat-label>Time Frame</mat-label>
        <mat-select formControlName="objectiveTimeFrame">
          <mat-option *ngFor="let priority of priorities" [value]="priority.id">
            {{priority.display}}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="hasError('objectiveTimeFrame', 'required')">Time Frame is required</mat-error>
      </mat-form-field>

      <mat-form-field class="full-width">
        <mat-label>Manager Objective</mat-label>
        <textarea matInput formControlName="managerDescription" maxlength="256"></textarea>
        <mat-error *ngIf="hasError('managerDescription', 'required')">Manager Objective is required</mat-error>
      </mat-form-field>

      <mat-form-field class="half-width">
        <mat-label>Start Date</mat-label>
        <input [matDatepicker]="startDate" formControlName="startDate" matInput>
        <mat-datepicker-toggle [for]="startDate" matSuffix></mat-datepicker-toggle>
        <mat-datepicker #startDate></mat-datepicker>
        <mat-error *ngIf="hasError('startDate', 'required')">Start Date is required</mat-error>
        <mat-error *ngIf="hasError('startDate', 'notCurrent')">Start Date must be greater than or equal to the current date</mat-error>
      </mat-form-field>

      <mat-form-field class="half-width">
        <mat-label>Status Frequency</mat-label>
        <mat-select formControlName="cadence">
          <mat-option *ngFor="let id of objectiveCadenceTypes"
                      [value]="id">{{objectiveCadenceTypeLabels[id]}}</mat-option>
        </mat-select>
        <mat-error *ngIf="hasError('cadence', 'required')">Status Frequency is required</mat-error>
      </mat-form-field>

      <mat-form-field class="half-width">
        <mat-label>End Date</mat-label>
        <input [matDatepicker]="dueDate" formControlName="endDate" matInput>
        <mat-datepicker-toggle [for]="dueDate" matSuffix></mat-datepicker-toggle>
        <mat-datepicker #dueDate></mat-datepicker>
        <mat-error *ngIf="hasError('endDate', 'required')">End Date is required</mat-error>
        <mat-error *ngIf="hasError('endDate', 'notCurrent')">End Date must be greater than or equal to the current date</mat-error>
      </mat-form-field>


      <mat-list>
        <mat-list-item *ngFor="let email of externalEmails">
          <mat-icon matListItemIcon>email</mat-icon>
          <h4 mat-line matListItemTitle>{{email}}</h4>
          <button mat-icon-button (click)="onRemoveExternalEmailClick(email)">
            <mat-icon>delete</mat-icon>
          </button>
        </mat-list-item>
      </mat-list>

      <form (ngSubmit)="onAddExternalEmailClick(externalEmailForm.value, submitBtn)" [formGroup]="externalEmailForm" autocomplete="off" novalidate>
        <div style="display: flex; flex-direction: row">
          <mat-form-field class="full-width">
            <mat-label>External Contact Email Address</mat-label>
            <input matInput formControlName="email" lowercase>
            <mat-error *ngIf="hasError('email', 'required', externalEmailForm)">External Contact Email Address is required</mat-error>
            <mat-error *ngIf="hasError('email', 'email', externalEmailForm) || hasError('email', 'pattern', externalEmailForm)">Please enter a valid email address</mat-error>
            <mat-error *ngIf="hasError('email', 'doNotDisturb', externalEmailForm)">This email has requested not to be contacted.</mat-error>
            <mat-error *ngIf="hasError('email', 'outsideDomain', externalEmailForm)">Please enter an email that is not in this organization's domain.</mat-error>
          </mat-form-field>
          <div>
            <button type="submit" mat-raised-button color="primary" mat-raised-button [disabled]="!externalEmailForm.valid">Add</button>
          </div>
        </div>
      </form>

    </div>
    <div mat-dialog-actions class="form-actions">
      <button (click)="onCancelClick()" mat-button type="button">Cancel</button>
      <button #submitBtn [disabled]="!objectiveForm.valid" color="primary" mat-raised-button type="submit">Save</button>
      <mat-error *ngIf="hasError(null, 'range')">Start Date must be before End Date</mat-error>
    </div>
  </form>
</ng-container>
