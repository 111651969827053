<div *ngIf="surveys" class="milestone-survey-results-container">
  <h1 mat-dialog-title>Milestone int<span style="color: #FE5305;">HR</span>action</h1>
  <div mat-dialog-content>
    <div class="inthraction-score-container">
      <inthraction-score-card [score]="totalScore"></inthraction-score-card>
    </div>
    <div>
      <mat-form-field class="three-qtr-width">
        <mat-label>Milestone</mat-label>
        <input matInput readonly value="{{milestone.title}}">
      </mat-form-field>
      <mat-form-field class="three-qtr-width">
        <mat-label>Survey Date</mat-label>
        <input matInput readonly value="{{data.selection.surveyDate | amParse: 'YYYY-MM-DD' | amDateFormat:'ll'}}">
      </mat-form-field>
      <div class="two-columns">
        <div class="score-list">
          <ng-container *ngFor="let option of displayOptions; let i = index">
            <p class="inthraction-response-type-wrapper color-{{(displayOptions.length) - i}}">
              <button *ngIf="isProjectManager" [matTooltipClass]="'multi-line-tooltip'"
                      mat-raised-button
                      matBadge="{{scoreMap.get((displayOptions.length) - i) ? scoreMap.get((displayOptions.length) - i).count : null}}" matBadgeColor="accent" matBadgePosition="before"
                      matTooltip="{{scoreMap.get((displayOptions.length) - i) && scoreMap.get((displayOptions.length) - i).respondents.length > 0 ?  scoreMap.get((displayOptions.length) - i).respondents.join('\n') : null}}">{{option}}</button>
              <button *ngIf="!isProjectManager" mat-raised-button
                      matBadge="{{scoreMap.get((displayOptions.length) - i) ? scoreMap.get((displayOptions.length) - i).count : null}}" matBadgeColor="accent" matBadgePosition="before">{{option}}</button>
            </p>
          </ng-container>
        </div>
        <div class="survey-comments">
          <div *ngFor="let commentObj of surveysWithComments">
            <mat-card appearance="outlined">
              <div class="two-columns">
                <mat-card-title *ngIf="isProjectManager" class="full-width">{{commentObj.respondent}}</mat-card-title>
                <mat-card-subtitle class="color-{{commentObj.event.surveyResponse}}">{{displayOptions[(displayOptions.length) - commentObj.event.surveyResponse]}}</mat-card-subtitle>
              </div>
              <mat-card-content>
                <textarea matInput readonly value="{{commentObj.event.feedback}}"></textarea>
              </mat-card-content>
            </mat-card>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<inthraction-loading className="milestone-survey-results-container" [initDone]="surveys ? true : false"></inthraction-loading>
