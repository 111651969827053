<inthraction-page-header [pageTitle]="pageTitle">
  <span class="toolbar-spacer"></span>
  <div class="date-range-wrapper no-subscript-wrapper" *ngIf="showDateRange">
    <mat-form-field class="half-width">
      <mat-label>From</mat-label>
      <input [matDatepicker]="startDateDP" matInput [(ngModel)]="startDate" [readonly]="formEditMode || viewOnly" (ngModelChange)="changeStartDate($event)">
      <mat-datepicker-toggle [for]="startDateDP" matSuffix></mat-datepicker-toggle>
      <mat-datepicker #startDateDP [disabled]="formEditMode || viewOnly"></mat-datepicker>
    </mat-form-field>
    <mat-form-field class="half-width">
      <mat-label>Through</mat-label>
      <input [matDatepicker]="endDateDP" matInput [(ngModel)]="endDate" [readonly]="formEditMode || viewOnly" (ngModelChange)="changeEndDate($event)">
      <mat-datepicker-toggle [for]="endDateDP" matSuffix></mat-datepicker-toggle>
      <mat-datepicker #endDateDP [disabled]="formEditMode || viewOnly"></mat-datepicker>
    </mat-form-field>
  </div>
</inthraction-page-header>

<div class="panel-wrapper" *ngIf="employee && startDate && endDate">
  <div class="left-panel">
    <div class="emp-info-container">
      <inthraction-employee-card-component [employee]="employee" [options]="{startingScore: startingScore, endingScore: endingScore}"></inthraction-employee-card-component>
    </div>
    <div class="trend-container" #trendContainer hidden="hidden">
      <mat-card appearance="outlined" class="trend">
        <div class="title">
          <mat-card-title>int<span class="inthraction-highlight">HR</span>action Trend</mat-card-title>
        </div>
        <div class="inthraction-score-chart-holder">
          <div></div>
          <inthraction-interaction-score-chart (openEventEmitter)="openRecentIntHRaction($event.scoreID, $event.employeeID)"
                                               (scoreEmitter)="updateScores($event.startingScore, $event.endingScore)"
                                               [employee]="employee"
                                               [orgID]="employee.orgId"
                                               [employees]="[employee.id]"
                                               [managerView]="true"
                                               [options]="{startDate: startDate.toISOString(), endDate: endDate.toISOString()}"
                                               (noDataEmitter)="$event.nodata ?  trendContainer.hidden = true : trendContainer.hidden = false"
          ></inthraction-interaction-score-chart>
          <div></div>
        </div>
      </mat-card>
    </div>
    <div *ngIf="lowResponse || highResponse" class="no-print">
      <mat-card appearance="outlined">
        <mat-card-title>Recent int<span class="inthraction-highlight">HR</span>action Comments</mat-card-title>
        <mat-card-content class="comments-container">
          <div>
            <ng-container *ngIf="highResponse">
              <mat-card appearance="outlined" class="full-height">
                <mat-card-subtitle>Highlight Feedback</mat-card-subtitle>
                <div class="two-columns">
                  <mat-card-title class="">{{highResponseName}}</mat-card-title>
                  <div>
                    <mat-card-subtitle class="color-{{highResponse.surveyResponse}}">{{surveyResponseOptionsDisplay[highResponse.surveyResponse]}}</mat-card-subtitle>
                    <mat-card-subtitle>{{highResponse.surveyDate | amParse:'YYYY-MM-DDTHH:mm:ssZ' | amDateFormat:'ll' }}</mat-card-subtitle>
                  </div>
                </div>
                <mat-card-content>
                  <textarea matInput readonly value="{{highResponse.feedback}}" cdkTextareaAutosize></textarea>
                </mat-card-content>
                <mat-card-actions>
                  <button mat-button *ngIf="highResponses.length > 1" (click)="showSurveyComments(highResponses)" class="show-more-btn">Show More</button>
                </mat-card-actions>
              </mat-card>
            </ng-container>
          </div>
          <div>
            <ng-container *ngIf="lowResponse">
              <mat-card appearance="outlined" class="full-height">
                <mat-card-subtitle>Challenge Feedback</mat-card-subtitle>
                <div class="two-columns">
                  <mat-card-title class="">{{lowResponseName}}</mat-card-title>
                  <div>
                    <mat-card-subtitle class="color-{{lowResponse.surveyResponse}}">{{surveyResponseOptionsDisplay[lowResponse.surveyResponse]}}</mat-card-subtitle>
                    <mat-card-subtitle>{{lowResponse.surveyDate | amParse:'YYYY-MM-DDTHH:mm:ssZ' | amDateFormat:'ll' }}</mat-card-subtitle>
                  </div>
                </div>
                <mat-card-content>
                  <textarea matInput readonly value="{{lowResponse.feedback}}" cdkTextareaAutosize></textarea>
                </mat-card-content>
                <mat-card-actions>
                  <button mat-button *ngIf="lowResponses.length > 1" (click)="showSurveyComments(lowResponses)" class="show-more-btn">Show More</button>
                </mat-card-actions>
              </mat-card>
            </ng-container>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
    <mat-card appearance="outlined">
      <inthraction-help-bubble [message]="MANAGER_NOTES_HELP" title="Notes" tip="Notes Help"></inthraction-help-bubble>
      <mat-form-field class="full-width">
        <mat-label>Notes</mat-label>
        <textarea matInput cdkTextareaAutosize cdkAutosizeMinRows="4" cdkAutosizeMaxRows="27" placeholder="" [(ngModel)]="notes" [readonly]="viewOnly"></textarea>
      </mat-form-field>

      <div *ngIf="formEditMode || viewOnly" class="comments-container notes-bubble-container">
        <ng-container *ngFor="let comment of oneOnOneNote.comments">
          <mat-card appearance="outlined" [class]="comment.createdBy === employee.id ? 'left-bubble mat-card' : 'right-bubble mat-card'">
            <mat-card-content>
              <span>{{comment.comment}}</span>
            </mat-card-content>
            <mat-card-footer>
              <span>{{employeeNameMap.get(comment.createdBy)}}</span>
              <span>{{comment.createdAt | amParse: 'YYYY-MM-DDTHH:mm:ssZ' | amDateFormat: 'lll'}}</span>
            </mat-card-footer>
          </mat-card>
        </ng-container>
      </div>
      <mat-dialog-actions class="button-wrapper">
        <div class="relative-btn-wrapper" *ngIf="showProgressCheckButton">
          <button *ngIf="!viewOnly" mat-raised-button (click)="openProgressCheckDialog()">Add/Edit Objective Progress Check</button>
          <inthraction-help-bubble [message]="OBJECTIVE_PROGRESS_HELP" title="Add/Edit Objective Progress Check" tip="Add/Edit Objective Progress Check Help"></inthraction-help-bubble>
        </div>
        <div class="flex-divider"></div>
        <button *ngIf="viewOnly" mat-button (click)="onCloseClick()">Close</button>
        <button *ngIf="!viewOnly" mat-button (click)="onCancelClick()">Cancel</button>
        <button *ngIf="!viewOnly" #submitBtn type="submit" mat-raised-button color="accent" (click)="onSaveClick(notes, submitBtn)">Save</button>
      </mat-dialog-actions>
    </mat-card>

  </div>
  <div class="right-panel" *ngIf="objectives && objectives.length">
    <div *ngFor="let objective of objectives">
      <inthraction-quantifiable-objective *ngIf="objective.objectiveType === 'QUANTIFIABLE'" [objectiveAssignment]="objective"
                                          [employee]="employee" [options]="{hideChart:false, showRecentResponses:true, objectiveAssignmentProgressCheck: objectiveProgressCheckMap.get(objective.id), startDate:startDate.toISOString(), endDate: endDate.toISOString()}"></inthraction-quantifiable-objective>
      <inthraction-objective *ngIf="objective.objectiveType === 'DEFINED'" [objectiveAssignment]="objective"
                             [employee]="employee" [options]="{hideChart:false, showRecentResponses:true, objectiveAssignmentProgressCheck: objectiveProgressCheckMap.get(objective.id), startDate:startDate.toISOString(), endDate: endDate.toISOString()}"></inthraction-objective>
      <inthraction-cadence-objective
        *ngIf="objective.objectiveType === 'DEFINED_MANAGER' || objective.objectiveType === 'DEFINED_SELF'"
        [objectiveAssignment]="objective" [employee]="employee" [options]="{hideChart:false, showRecentResponses:true, objectiveAssignmentProgressCheck: objectiveProgressCheckMap.get(objective.id), startDate:startDate.toISOString(), endDate: endDate.toISOString()}"></inthraction-cadence-objective>
    </div>
  </div>
</div>
