import { EmployeeService, SurveyService } from "@inthraction/services";
import { Employee, ObjectiveAssignment } from "@inthraction/data-models";
import { DEPARTMENT_TYPE_LABELS, OBJECTIVE_TYPE_LABELS } from "@inthraction/labels";
import * as moment from "moment";
import { TableData } from "./TableData";

export class DistributionDetails {
  key: string;
  orgObjectiveID: string;
  userCount: number = 0;
  assignee: Set<string> = new Set<string>();
  rankings: Map<number, number> = new Map<number, number>();
  scores: number[] = [];
  tableData: TableData[] = [];
  private assignments = new Set<string>();

  constructor(key: string, orgObjectiveID: string) {
    this.key = key;
    this.orgObjectiveID = orgObjectiveID;
  }

  get assigneeCount(): number {
    return this.assignee.size;
  }

  get averageScore(): number | null {
    if (this.assigneeCount) {
      return SurveyService.round(this.scores.reduce((accumulator, currentValue) => accumulator + currentValue) / this.assigneeCount, 2);
    }
    return null;
  }

  get backgroundColor(): string {
    if (this.averageScore) {
      return EmployeeService.getScoreColor(this.averageScore);
    }
    return "";
  }

  addAssignment(employee: Employee, assignment: ObjectiveAssignment, score: number, rank, manager?: Employee) {
    if (!this.assignments.has(assignment.id)) {
      this.assignments.add(assignment.id);
      if (score) {
        this.assignee.add(employee.id);
        this.rankings.get(rank) ? this.rankings.set(rank, this.rankings.get(rank) + 1) : this.rankings.set(rank, 1);
        this.scores.push(score);
      }
      this.tableData.push({
        id: assignment.id,
        score: score,
        rank: rank,
        name: `${employee.firstName} ${employee.lastName}`,
        manager: `${manager?.firstName || ""} ${manager?.lastName || ""}`,
        department: DEPARTMENT_TYPE_LABELS[employee.department],
        objectiveType: OBJECTIVE_TYPE_LABELS[assignment.objectiveType],
        objectiveTitle: assignment.title || assignment.orgObjective.objective.display,
        domain: assignment.orgObjective.objective.domain.display,
        focus: assignment.focus.display,
        timeframe: assignment.priority.display,
        startDate: assignment.startDate ? moment(assignment.startDate) : moment(assignment.createdAt),
        endDate: assignment.endDate ? moment(assignment.endDate) : null
      });
    }
  }
}

export enum ObjectiveDistributionDetailsModes {
  MANAGER,
  HR,
  CONSULTANT
}
