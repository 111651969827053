<h1 mat-dialog-title>Note History</h1>
<div mat-dialog-content class="overflow-container">
  <mat-card appearance="outlined">
    <mat-card-footer>
      <mat-card-subtitle>{{authors.get(note.createdBy)}}</mat-card-subtitle>
      <mat-card-subtitle>{{note.updatedAt | amParse: 'YYYY-MM-DDTHH:mm:ssZ' | amDateFormat: 'lll'}}</mat-card-subtitle>
    </mat-card-footer>
    <mat-card-content>
      <h3 *ngIf="note.options?.title">{{note.options.title}}</h3>
      <mat-card-subtitle *ngIf="note.noteType == NoteTypes.PROJECT_PRIVATE" class="private">Private</mat-card-subtitle>
      <mat-form-field class="full-width">
        <textarea readonly matInput [value]="note.text" cdkTextareaAutosize></textarea>
      </mat-form-field>
    </mat-card-content>
  </mat-card>
  <mat-card appearance="outlined" *ngFor="let history of note.historys">
    <mat-card-footer>
      <mat-card-subtitle>{{authors.get(history.createdBy)}}</mat-card-subtitle>
      <mat-card-subtitle>{{history.createdAt | amParse: 'YYYY-MM-DDTHH:mm:ssZ' | amDateFormat: 'lll'}}</mat-card-subtitle>
    </mat-card-footer>
    <mat-card-content>
      <mat-form-field class="full-width">
        <textarea readonly matInput [value]="history.text" cdkTextareaAutosize></textarea>
      </mat-form-field>
    </mat-card-content>
  </mat-card>
</div>
