import { Component, Inject, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { DateValidators } from "../../validators/date-validators";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { CADENCE_TYPES } from "@inthraction/codes";
import { CADENCE_TYPE_LABELS } from "@inthraction/labels";
import { CreateProject, ProjectService, SurveyService } from "@inthraction/services";
import * as moment from "moment";
import { Moment } from "moment";

@Component({
  selector: "inthraction-add-project",
  templateUrl: "./add-project.component.html",
  styleUrls: ["./add-project.component.scss"]
})
export class AddProjectComponent implements OnInit {

  readonly cadenceTypes = CADENCE_TYPES;
  readonly cadenceTypeLabels = CADENCE_TYPE_LABELS;

  form: FormGroup = new FormGroup({
      title: new FormControl("", [Validators.required]),
      description: new FormControl("", []),
      startDate: new FormControl("", [Validators.required]),
      endDate: new FormControl("", [DateValidators.dateGreaterThanEqualToCurrentDate({ notCurrent: true }), Validators.required]),
      cadence: new FormControl("", [Validators.required])
    },
    { validators: DateValidators.dateLessThan("startDate", "endDate", { range: true }) });

  constructor(
    public projectService: ProjectService,
    public dialogRef: MatDialogRef<AddProjectComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
  }

  ngOnInit() {
  }

  onCancelClick() {
    this.dialogRef.close();
  }

  async onSaveClick(value) {
    const newProject: CreateProject | any = {};
    if (value.endDate && (value.endDate as Moment).isValid()) {
      newProject.endDate = moment(value.endDate).format("YYYY-MM-DD");
    }
    newProject.projectManagerID = this.data.employee.id;

    if (value.startDate && (value.startDate as Moment).isValid()) {
      newProject.startDate = moment(value.startDate).format("YYYY-MM-DD");
    }
    newProject.cadence = value.cadence;
    newProject.title = value.title;
    newProject.description = value.description;
    newProject.organizationID = this.data.organizationID ? this.data.organizationID : this.data.employee.orgId;
    newProject.nextSurveyDate = SurveyService.calculateNextSurveyDate(newProject.startDate, newProject.cadence, newProject.endDate);

    const project = await this.projectService.createProject(newProject);
    this.dialogRef.close({ project });
  }

  public hasError = (controlName: string, errorName: string) => {
    if (controlName && this.form.controls[controlName]) {
      return this.form.controls[controlName].hasError(errorName);
    }
    return this.form.hasError(errorName);
  };

}
