import { Component, OnDestroy, OnInit } from "@angular/core";
import {
  CalendarEvent,
  CalendarEventTimesChangedEvent,
  CalendarMonthViewBeforeRenderEvent,
  CalendarView
} from "angular-calendar";
import { Subject, Subscription } from "rxjs";
import { Router } from "@angular/router";
import {
  Employee,
  EmployeeImageInterface,
  Note,
  StandupSurvey,
  StandupSurveyConfiguration
} from "@inthraction/data-models";
import { CalendarEventService, EmployeeService, NoteService, StandupService } from "@inthraction/services";
import { EventColor } from "calendar-utils";
import * as moment from "moment";
import { Moment } from "moment";
import {
  StandardDialogComponentComponent
} from "../../../components/shared/dialogs/standard-dialog-component/standard-dialog-component.component";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import {
  IthractionGraphDialogComponent
} from "../../../components/shared/dialogs/ithraction-graph-dialog/ithraction-graph-dialog.component";
import {
  StandupConfigurationComponent
} from "../../../components/standup-configuration/standup-configuration.component";
import { QuestionsMap } from "@inthraction/data-mappers";
import { NoteTypes } from "@inthraction/codes";

@Component({
  selector: "inthraction-manager-workforce",
  templateUrl: "./manager-workforce.component.html",
  styleUrls: ["./manager-workforce.component.scss"]
})
export class ManagerWorkforceComponent implements OnInit, OnDestroy {

  // https://mattlewis92.github.io/angular-calendar
  view: CalendarView = CalendarView.Month;
  viewDate: Date = new Date();
  userEvents: CalendarEvent[] = [];
  activeDayIsOpen = true;
  breakpoint: number;
  modalData: {
    action: string;
    event: CalendarEvent;
  };
  currentUser: Employee;
  subordinates: Employee[] = [];
  subordinateImageMap: Map<string, EmployeeImageInterface> = new Map<string, EmployeeImageInterface>();
  refresh: Subject<any> = new Subject();
  today: string;
  loadingStandupDetails: boolean = true;
  standupDetails: StandupDetails;
  private toggledEmployees: string[] = [];
  private _userEvents: Map<string, CalendarEvent[]> = new Map<string, CalendarEvent[]>();
  private infoDialogRef: MatDialogRef<StandardDialogComponentComponent>;
  private calStart: Date;
  private calEnd: Date;
  private subscriptions: Subscription[] = [];

  constructor(
    private router: Router,
    private employeeService: EmployeeService,
    private calendarEventService: CalendarEventService,
    private standupService: StandupService,
    private noteService: NoteService,
    public dialog: MatDialog
  ) {
    this.today = moment().toISOString();
  }

  private static calcBreakpoint(width: number): number {
    const bp = Math.floor(width / 440);
    return bp ? bp : 1;
  }

  ngOnDestroy(): void {
    if (this.subscriptions.length) {
      for (const sub of this.subscriptions) {
        sub.unsubscribe();
      }
    }
  }

  async ngOnInit(): Promise<void> {
    this.breakpoint = ManagerWorkforceComponent.calcBreakpoint(window.innerWidth);
    this.currentUser = await this.employeeService.getCurrentEmployee();
    this.subordinates = await this.employeeService.getSubordinatesByEmployeeIDForOrganization({managerID: this.currentUser.id, memoize:true});
    for (const subordinate of this.subordinates) {
      this.subordinateImageMap.set(subordinate.id, await this.employeeService.getEmployeeImageMemoize(subordinate.id, subordinate.orgId));
    }
    await this.getUserCalendarEvents(this.currentUser, this.subordinates);
    this.standupDetails = await this.initializeStandupDetails(this.currentUser, this.subordinates);
  }

  onResize(event) {
    this.breakpoint = ManagerWorkforceComponent.calcBreakpoint(event.target.innerWidth);
  }

  openEmployeeSettings(subordinate: Employee): void {
    this.router.navigate([`/manager/${subordinate.id}/workforce`]);
  }

  closeOpenMonthViewDay(): void {
    this.activeDayIsOpen = false;
  }

  dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
  }

  eventTimesChanged({ event, newStart, newEnd }: CalendarEventTimesChangedEvent): void {
  }

  handleEvent(action: string, event: CalendarEvent): void {
    this.modalData = { event, action };
  }

  getSubordinate(id: string): Employee {
    return this.subordinates.find(s => s.id == id);
  }

  getStandupQuestion(configuration: StandupSurveyConfiguration, questionMap: QuestionsMap): string {
    const question = configuration.questions.find(q => q.id == questionMap.id);
    return question.questionTextArray[questionMap.version];
  }


  async getUserCalendarEvents(currentUser: Employee, subordinates: Employee[]): Promise<void> {
    let employees: Employee[] = [];
    if (subordinates && subordinates.length > 0) {
      employees = Object.assign([], subordinates.filter(s => !this.toggledEmployees.includes(s?.email)));
    }
    employees.push(currentUser);
    if (employees && employees.length) {
      for (const user of employees) {
        const primaryColor = this.employeeService.getEmployeeColor(user?.email);
        const colour: EventColor = { primary: primaryColor, secondary: "#fff" };
        let start: Moment;
        let end: Moment;
        if (this.calStart) {
          start = moment(this.calStart);
        } else {
          start = moment().startOf("month");
        }
        if (this.calEnd) {
          end = moment(this.calEnd);
        } else {
          end = moment().endOf("month");
        }
        if (user && user.email) {
          const calendarEvents = await this.calendarEventService.listCalendarEventsByEmailMemoize(user?.email, start.toISOString(), end.toISOString());
          calendarEvents.sort((a, b) => {
            return moment(a.start).diff(moment(b.start));
          });
          for (const event of calendarEvents) {

            let endDate: Date;
            if (event.recurring) {
              // Cronofy seems to make the end date arbitrary for recurring events when they should start and end the actual event the same day.
              // This is a workaround to get the correct end date but will probably break if the event is a recurring multi-day event.
              endDate = new Date(event.start);
              const end = new Date(event.end);
              endDate.setHours(end.getHours());
              endDate.setMinutes(end.getMinutes());
              endDate.setSeconds(end.getSeconds());
            } else {
              endDate = new Date(event.end);
            }

            const calEvent: CalendarEvent = {
              id: event.id,
              start: new Date(event.start),
              end: endDate,
              title: event.summary,
              color: colour
            };

            if (this._userEvents.has(user.email)) {
              const events = this._userEvents.get(user.email);
              if (!events.some(e => e.id === calEvent.id)) {
                events.push(calEvent);
                this._userEvents.set(user.email, events);
              }
            } else {
              this._userEvents.set(user.email, [calEvent]);
            }
          }
        }
      }
    }
    this.userEvents = this.getUserEvents();
    this.refresh.next(true);
  }

  getUserEvents(): CalendarEvent[] {
    const events: CalendarEvent[] = [];
    for (const key of this._userEvents.keys()) {
      if (!this.toggledEmployees.includes(key)) {
        events.push(...this._userEvents.get(key));
      }
    }
    return events;
  }

  async toggleEmployee($event: any) {
    if (!$event.checked) {
      this.toggledEmployees.push($event.employee.email);
    } else {
      this.toggledEmployees = this.toggledEmployees.filter(s => s !== $event.employee.email);
    }
    await this.getUserCalendarEvents(this.currentUser, this.subordinates);
  }

  openInfoDialog(title: string, message: string): void {
    if (!this.infoDialogRef) {
      this.infoDialogRef = this.dialog.open(StandardDialogComponentComponent, {
        panelClass: "topRightCloseButton",
        width: "450px",
        autoFocus: false,
        data: { message, title }
      });
    }

    this.subscriptions.push(
      this.infoDialogRef.afterClosed().subscribe(() => this.infoDialogRef = null)
    );
  }

  async beforeCalRender($event: CalendarMonthViewBeforeRenderEvent) {
    if (this.calStart?.toISOString() !== $event.period.start.toISOString() || this.calEnd?.toISOString() !== $event.period.end.toISOString()) {
      this.calStart = $event.period.start;
      this.calEnd = $event.period.end;
      this.getUserCalendarEvents(this.currentUser, this.subordinates).catch();
    }
  }

  editEmployee(employee: Employee) {
    this.router.navigate([`/manager/${employee.id}/workforce`]);
  }

  showIntHRactionsChart(employee: Employee) {
    this.dialog.open(IthractionGraphDialogComponent, {
      width: "500px",
      data: { employee, ratesOthers: false }
    });
  }

  showRatesOthersChart(employee: Employee) {
    this.dialog.open(IthractionGraphDialogComponent, {
      width: "500px",
      data: { employee, ratesOthers: true }
    });
  }

  showStandupConfiguration() {
    this.dialog.open(StandupConfigurationComponent, {
      data: { manager: this.currentUser },
      minWidth: 520
    });
  }

  private async initializeStandupDetails(currentUser: Employee, subordinates: Employee[]): Promise<StandupDetails> {
    let standupDetails: StandupDetails;
    try {
      const configuration = await this.standupService.getStandupConfigurationByManager(currentUser);
      if (configuration) {
        let surveyDate;
        const surveys: StandupSurvey[] = await this.standupService.getLatestStandupSurveysByConfiguration(configuration.id, configuration.lastSurveyedDate);
        if (surveys.length) {
          surveyDate = configuration.lastSurveyedDate;
          surveys.filter(s => this.subordinates.map(s => s.id).includes(s.respondentID));
        }
        const notesMap = new Map<string, Note>();
        for (const id of surveys.map(s => s.id)) {
          const notes = await this.noteService.getOneOnOneNotesByObjectIDMemoize(id, NoteTypes.STANDUP);
          if (notes?.length) {
            notesMap.set(id, notes[0]);
          }
        }
        standupDetails = {
          surveyDate: surveyDate,
          configuration: configuration,
          surveys: surveys,
          notes: notesMap
        };
      }
    } finally {
      this.loadingStandupDetails = false;
      return standupDetails;
    }
  }

  showStandupHistory() {
    this.router.navigate(["/manager/stand-up-history"]);
  }

  openStandupComment(survey: StandupSurvey) {
    if (survey?.responseReceived && !this.standupDetails.notes.has(survey.id)) {
      this.router.navigate([`/manager/${survey.respondentID}/communication/${survey.id}`])
    }
  }
}

export interface StandupDetails {
  surveyDate: string;
  surveys: StandupSurvey[];
  notes: Map<string, Note>;
  configuration: StandupSurveyConfiguration;
}
