import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Note } from "@inthraction/data-models";
import { EmployeeService } from "@inthraction/services";
import { NoteTypes } from "@inthraction/codes";

@Component({
  selector: "inthraction-note-history-dialog",
  templateUrl: "./note-history-dialog.component.html",
  styleUrls: ["./note-history-dialog.component.scss"]
})
export class NoteHistoryDialogComponent implements OnInit {

  note: Note;

  authors: Map<string, string> = new Map<string, string>();

  NoteTypes = NoteTypes;

  constructor(
    public dialogRef: MatDialogRef<NoteHistoryDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private employeeService: EmployeeService
  ) {
    this.note = data.note;
  }

  async ngOnInit() {
    this.note.historys.sort((a, b) => a.createdAt > b.createdAt ? -1 : a.createdAt < b.createdAt ? 1 : 0);
    for (const history of this.note.historys) {
      if (!this.authors.has(history.createdBy)) {
        const author = await this.employeeService.getEmployeeByIDMemoize(history.createdBy);
        this.authors.set(history.createdBy, `${author.firstName} ${author.lastName}`);
      }
    }

    if (!this.authors.has(this.note.createdBy)) {
      const author = await this.employeeService.getEmployeeByIDMemoize(this.note.createdBy);
      this.authors.set(this.note.createdBy, `${author.firstName} ${author.lastName}`);
    }
  }

}
