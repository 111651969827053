<div *ngIf="hasManager" class="oc-h-bar oc-border"></div>
<div class="oc-box oc-border oc-background oc-emp-box">
  <inthraction-profile-image [employeeImage]="employeeImage"></inthraction-profile-image>
  <div class="oc-content">
    <div class="oc-name"
         title="{{employee?.firstName}} {{employee?.lastName}}">{{employee?.firstName}} {{employee?.lastName}}</div>
    <div class="oc-designation" title="{{employee?.title}}">{{employee?.title}}</div>
  </div>
  <div class="circle {{scoreColor}}"></div>
  <div *ngIf="showActions" class="oc-emp-footer">
    <button (click)="openEdit()" class="oc-emp-edit-btn" mat-icon-button title="Edit User">
      <mat-icon>edit</mat-icon>
    </button>
    <button (click)="collapseSubordinates()" *ngIf="employee?.subordinateEmployees?.length" class="" mat-icon-button
            title="Collapse Subordinates">
      <mat-icon *ngIf="!isCollapsed">keyboard_arrow_up</mat-icon>
      <mat-icon *ngIf="isCollapsed">keyboard_arrow_down</mat-icon>
    </button>
    <button (click)="openAdd()" class="oc-emp-add-btn" mat-icon-button title="Add Subordinate">
      <mat-icon>add_box</mat-icon>
    </button>
  </div>
</div>
<div *ngIf="employee?.subordinateEmployees?.length" class="oc-h-bar oc-border"></div>
