export enum NoteTypes {
  ONE_ON_ONE = "ONE_ON_ONE",
  HR_ONE_ON_ONE = "HR_ONE_ON_ONE",
  CONSULTANT_ONE_ON_ONE = "CONSULTANT_ONE_ON_ONE",
  PROJECT = "PROJECT",
  PROJECT_PRIVATE = "PROJECT_PRIVATE",
  MANAGER = "MANAGER",
  STANDUP = "STANDUP"
}

export enum NotificationTypes {
  COMMENT = "COMMENT",
  NOTE = "NOTE"
}
