<ng-container *ngIf="employee">
  <div class="hr-employee-edit-container flex-wrapper">
    <div class="flex-wrapper wide column">
      <inthraction-employee-card-component [employee]="employee" [options]="{showCriticalPosition:mode == PageMode.HR, showReadiness:true}"></inthraction-employee-card-component>
      <div>
        <mat-grid-list [style.display]="ratedGraph || ratesGraph ? 'block': 'none'" cols="2" rowHeight="500px" class="chart-container">
          <mat-grid-tile>
            <inthraction-inthraction-graph [employee]="employee" (graphRenderedEmitter)="ratedGraph = $event"></inthraction-inthraction-graph>
            <div class="center-break"></div>
          </mat-grid-tile>
          <mat-grid-tile>
            <div class="center-break"></div>
            <inthraction-inthraction-graph [employee]="employee" [configuration]="{ratesOthers:true}" (graphRenderedEmitter)="ratesGraph = $event"></inthraction-inthraction-graph>
          </mat-grid-tile>
        </mat-grid-list>
      </div>
      <div #trendContainer hidden="hidden">
        <mat-card appearance="outlined">
          <div class="inthraction-score-chart-holder">
            <div></div>
            <inthraction-interaction-score-chart
              (openEventEmitter)="openRecentIntHRaction($event.scoreID, $event.employeeID)"
              [employee]="employee"
              [orgID]="employee.orgId"
              [employees]="[employee.id]"
              [managerView]="notSelf"
              (noDataEmitter)="$event.nodata ?  trendContainer.hidden = true : trendContainer.hidden = false"
            ></inthraction-interaction-score-chart>
            <div></div>
          </div>
        </mat-card>
      </div>
      <inthraction-app-employee-personal-goals [managerAccess]="notSelf" [employeeID]="employee.id"></inthraction-app-employee-personal-goals>
      <div [style.position]="'relative'" class="notes-container" *ngIf="oneOnOneNotes?.length > 0 && mode == PageMode.CONSULTANT">
        <inthraction-notes
          [user]="(notSelf ? currentUser : employee)"
          [options]="{title:'Feedback & 1 on 1\'s', enableCreate:false, enableEdit: notSelf, enableReview: notSelf}"
          [notes]="oneOnOneNotes"
          (editNoteEvent)="editOneOnOneNote($event)"
          (reviewNoteEvent)="reviewOneOnOneNote($event)"></inthraction-notes>
      </div>
      <div class="notes-container" *ngIf="(oneOnOneNotes?.length || managerOneOnOneNotes?.length || consultantOneOnOneNotes?.length) && mode == PageMode.HR">
        <mat-card appearance="outlined">
          <mat-card-content>
            <mat-tab-group mat-stretch-tabs="false">
              <mat-tab label="HR Communication" *ngIf="oneOnOneNotes?.length">
                <inthraction-notes
                  [user]="(notSelf ? currentUser : employee)"
                  [options]="{title:'', enableCreate:false, enableEdit: notSelf, enableReview: notSelf}"
                  [notes]="oneOnOneNotes"
                  (editNoteEvent)="editOneOnOneNote($event)"
                  (reviewNoteEvent)="reviewOneOnOneNote($event)"></inthraction-notes>
              </mat-tab>
              <mat-tab label="Manager Feedback & 1 on 1's" *ngIf="managerOneOnOneNotes?.length">
                <inthraction-notes
                  [user]="(notSelf ? currentUser : employee)"
                  [options]="{title:'', enableCreate:false, enableEdit: false, enableReview: notSelf, disableComments: true}"
                  [notes]="managerOneOnOneNotes"
                  (editNoteEvent)="editOneOnOneNote($event)"
                  (reviewNoteEvent)="reviewOneOnOneNote($event)"></inthraction-notes>
              </mat-tab>
              <mat-tab label="Consultant Communication" *ngIf="consultantOneOnOneNotes?.length">
                <inthraction-notes
                  [user]="(notSelf ? currentUser : employee)"
                  [options]="{title:'', enableCreate:false, enableEdit: false, enableReview: notSelf, disableComments: true}"
                  [notes]="consultantOneOnOneNotes"
                  (editNoteEvent)="editOneOnOneNote($event)"
                  (reviewNoteEvent)="reviewOneOnOneNote($event)"></inthraction-notes>
              </mat-tab>
            </mat-tab-group>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
    <div class="flex-wrapper column narrow">
      <div class="actions-container flex-wrapper wrap">
        <div *ngIf="notSelf" class="action-wrapper">
          <inthraction-help-bubble [message]="ASSIGN_OBJECTIVES_HELP" title="Assign Objectives" tip="Assign Objectives Help"></inthraction-help-bubble>
          <button (click)="openAddObjective()" class="" mat-raised-button>Assign Objectives</button>
        </div>
        <div *ngIf="notSelf && mode == PageMode.HR" class="action-wrapper">
          <inthraction-help-bubble [message]="ASSIGN_READINESS_LEVEL_HELP" title="Assign Readiness Level" tip="Assign Readiness Level Help"></inthraction-help-bubble>
          <button (click)="openSetReadiness()" class="" mat-raised-button>Assign Readiness</button>
        </div>
        <div *ngIf="notSelf" class="action-wrapper">
          <inthraction-help-bubble [message]="COMMUNICATION_HELP" title="Communication" tip="Communication Help"></inthraction-help-bubble>
          <button (click)="openNotes()" class="" mat-raised-button>Communication</button>
        </div>
        <div *ngIf="notSelf && mode == PageMode.HR && employee.readiness" class="action-wrapper">
          <inthraction-help-bubble [message]="READINESS_HISTORY_HELP" title="Readiness History" tip="Readiness History Help"></inthraction-help-bubble>
          <button (click)="openReadinessHistory()" class="" mat-raised-button>Readiness History</button>
        </div>
        <div *ngIf="notSelf" class="action-wrapper wide-btn">
          <inthraction-help-bubble [message]="OBJECTIVE_RESPONSE_DISTRIBUTION_HELP" title="Objective Response Distributions" tip="Objective Response Distributions Help"></inthraction-help-bubble>
          <button (click)="showObjectiveResponseDistributions(employee)" class="" mat-raised-button>Objective Response Distributions</button>
        </div>
      </div>
      <div class="emp-objective-container">
        <ng-container *ngFor="let objectiveAssignment of employeeObjectives">
          <inthraction-quantifiable-objective *ngIf="objectiveAssignment.objectiveType === 'QUANTIFIABLE'" [objectiveAssignment]="objectiveAssignment" [options]="{managerAccess: notSelf && (mode === PageMode.HR || objectiveAssignment.consultantId === currentUser.id)}" (openEdit)="openEditObjective($event)" [employee]="employee"></inthraction-quantifiable-objective>
          <inthraction-objective *ngIf="!objectiveAssignment.objectiveType || objectiveAssignment.objectiveType === 'DEFINED'" [objectiveAssignment]="objectiveAssignment" [options]="{managerAccess: notSelf && (mode === PageMode.HR || objectiveAssignment.consultantId === currentUser.id)}" (openEdit)="openEditObjective($event)" [employee]="employee"></inthraction-objective>
          <inthraction-cadence-objective *ngIf="objectiveAssignment.objectiveType === 'DEFINED_MANAGER' || objectiveAssignment.objectiveType === 'DEFINED_SELF'" [objectiveAssignment]="objectiveAssignment" [options]="{managerAccess: notSelf && (mode === PageMode.HR || objectiveAssignment.consultantId === currentUser.id)}" (openEdit)="openEditObjective($event)"
                                         [employee]="employee"></inthraction-cadence-objective>
          <inthraction-cadence-objective *ngIf="objectiveAssignment.objectiveType === 'DEFINED_EXTERNAL'" [objectiveAssignment]="objectiveAssignment" [options]="{managerAccess: notSelf && (mode === PageMode.HR || objectiveAssignment.consultantId === currentUser.id)}" (openEdit)="openEditObjective($event)" [employee]="employee"></inthraction-cadence-objective>
        </ng-container>
      </div>
    </div>
  </div>
</ng-container>
