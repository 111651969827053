import { Component, OnDestroy, OnInit } from "@angular/core";
import { MatSlideToggleChange } from "@angular/material/slide-toggle";
import { Employee, Objective, ObjectiveDomain, Organization, OrganizationObjective } from "@inthraction/data-models";
import { EmployeeService, ObjectiveService, OrganizationService } from "@inthraction/services";
import { ConfirmationDialogComponent } from "../shared/dialogs/confirmation-dialog/confirmation-dialog.component";
import { EditObjectiveDialogComponent } from "../shared/add-edit-objective/edit-objective-dialog.component";
import { MatDialog } from "@angular/material/dialog";
import { MatTabChangeEvent } from "@angular/material/tabs";
import { Subscription } from "rxjs";
import { UserDefinedObjectiveDomainTypes } from "@inthraction/codes";
import { ToastrService } from "ngx-toastr";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "inthraction-objectiveconfiguration",
  templateUrl: "./objective-configuration.component.html",
  styleUrls: ["./objective-configuration.component.scss"]
})
export class ObjectiveConfigurationComponent implements OnInit, OnDestroy {

  static organizationIDParameterName = "organizationID";

  private subscriptions: Subscription[] = [];
  private currentUser: Employee;

  objectiveDomains: ObjectiveDomain[];
  organization: Organization;
  organizationObjectiveDomainsMap: Map<string, Map<string, OrganizationObjective>>;
  activeObjectiveIdSet: Set<string>;

  selectedObjectiveDomain: ObjectiveDomain;
  orgDefinedDomain: ObjectiveDomain;
  selectedIndex = 0;
  readonly UserDefinedObjectiveDomainTypes = UserDefinedObjectiveDomainTypes;

  constructor(
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private objectiveService: ObjectiveService,
    private organizationService: OrganizationService,
    private employeeService: EmployeeService,
    private toastr: ToastrService
  ) {
  }

  async ngOnInit(): Promise<void> {
    this.subscriptions.push( this.route.paramMap.subscribe(async queryParams => {
      if (queryParams.has(ObjectiveConfigurationComponent.organizationIDParameterName)) {
        this.organization = await this.organizationService.getOrganizationByIDMemoize(queryParams.get(ObjectiveConfigurationComponent.organizationIDParameterName));
      } else {
        this.organization = await this.organizationService.getOrganizationForCurrentUser();
      }

      await this.refreshOrganizationObjectivesList();
      this.selectedObjectiveDomain = this.objectiveDomains[0];

    }));

    this.currentUser = await this.employeeService.getCurrentEmployee();

    this.objectiveDomains = [];
    this.objectiveDomains = this.objectiveDomains.concat((await this.objectiveService.getObjectiveDomainsMemoize())
      .filter(d => {
        // Don't directly use the memoize array because OrgDefined type will be added below and we don't want that showing up other places.
        return d.key !== UserDefinedObjectiveDomainTypes.CONSULTANT_DEFINED
      })
    ).sort((a,b) => {
      if (a.key == UserDefinedObjectiveDomainTypes.ORG_DEFINED) {
        return 1;
      } else if (b.key == UserDefinedObjectiveDomainTypes.ORG_DEFINED) {
        return -1;
      }
      return a.display.localeCompare(b.display);
    });
  }

  tabChanged(event: MatTabChangeEvent) {
    this.selectedObjectiveDomain = this.objectiveDomains[event.index];
  }

  async updateOrgObjective(organizationObjective: OrganizationObjective, toggleEvent: MatSlideToggleChange): Promise<void> {
    organizationObjective.enabled = toggleEvent.checked;
    if (!organizationObjective.id) {
      await this.objectiveService.createNewOrganizationObjective(organizationObjective);
      await this.refreshOrganizationObjectivesList();
    } else {
      await this.objectiveService.updateOrganizationObjective({
        id: organizationObjective.id,
        organizationID: organizationObjective.organizationID,
        enabled: organizationObjective.enabled
      });
    }
  }

  async updateBusinessObjective(organizationObjective: OrganizationObjective, blurEvent: FocusEvent): Promise<void> {
    organizationObjective.businessObjective = (blurEvent.target as HTMLInputElement).value;
    if (organizationObjective.businessObjective && organizationObjective.businessObjective.length <= 0) {
      organizationObjective.businessObjective = null;
    }
    if (!organizationObjective.id) {
      await this.objectiveService.createNewOrganizationObjective(organizationObjective);
      await this.refreshOrganizationObjectivesList();
    } else {
      await this.objectiveService.updateOrganizationObjective({
        id: organizationObjective.id,
        organizationID: organizationObjective.organizationID,
        businessObjective: organizationObjective.businessObjective
      });
    }
  }

  deleteObjective(objectiveID: string): void {
    const deleteDialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: "350px",
      data: "Are you sure you want to Delete this objective?"
    });
    this.subscriptions.push(
      deleteDialogRef.afterClosed().subscribe(async result => {
        if (result) {
          const obj = this.organizationObjectiveDomainsMap.get(this.orgDefinedDomain.id).get(objectiveID);
          this.organizationObjectiveDomainsMap.get(this.orgDefinedDomain.id).delete(objectiveID);
          this.activeObjectiveIdSet = await this.objectiveService.getActiveObjectives(this.organization.id);
          if (!this.activeObjectiveIdSet.has(objectiveID)) {
            await this.objectiveService.deleteObjective(objectiveID, this.organization.id);
            this.toastr.success("Objective Deleted");
          } else {
            this.toastr.error("Unable to Delete this Objective, it is currently assigned to a user.");
            this.organizationObjectiveDomainsMap.get(this.orgDefinedDomain.id).set(objectiveID, obj);
          }
          await this.refreshOrganizationObjectivesList();
        }
      }));
  }

  editObjective(objective: Objective): void {
    const editModal = this.dialog.open(EditObjectiveDialogComponent, {
      width: "600px",
      data: { objective }
    });
    this.subscriptions.push(editModal.afterClosed().subscribe(
      result => {
        if (result) {
          this.objectiveService.clearMemoizedOrganizationObjectives(this.organization.id); // TODO Is this needed, update in the edit dialog should clear memoized
          this.organizationObjectiveDomainsMap.get(this.orgDefinedDomain.id).get(result.objective.id).objective = result.objective;
        }
      }
    ));
  }

  addNewObjective(): void {
    this.selectedIndex = this.objectiveDomains.length-1
    this.selectedObjectiveDomain = this.objectiveDomains[this.selectedIndex];


    const editModal = this.dialog.open(EditObjectiveDialogComponent, {
      width: "600px",
      data: { domain: this.orgDefinedDomain, addMode: true, organizationID: this.organization.id }
    });
    this.subscriptions.push(editModal.afterClosed().subscribe(
      async result => {
        if (result) {
          const orgObjective = await this.objectiveService.createNewOrganizationObjective({
            organizationID: this.organization.id,
            organizationObjectiveObjectiveId: result.objective.id
          });
          this.organizationObjectiveDomainsMap.get(this.orgDefinedDomain.id).set(result.objective.id, orgObjective);
        }
      }
    ));
  }

  private async refreshOrganizationObjectivesList(): Promise<void> {
    let allObjectives: Objective[] = [];
    const allCoreObjectives = (await this.objectiveService.getObjectivesMemoize()).filter(o => (!o.organizations?.length) || o.organizations.includes(this.organization.id));
    const allOrgDefinedObjectives = await this.objectiveService.getObjectivesMemoize(this.organization.id);
    if (allCoreObjectives) {
      allObjectives = allObjectives.concat(allCoreObjectives);
    }
    if (allOrgDefinedObjectives) {
      allObjectives = allObjectives.concat(allOrgDefinedObjectives);
      this.activeObjectiveIdSet = await this.objectiveService.getActiveObjectives(this.organization.id);
    }
    const organizationObjectives = new Array(...await this.objectiveService.getOrganizationObjectivesByOrganizationID(this.organization.id, false));

    // TODO Remove this part when management app can create new Objectives and automatically add them to organizations as disabled
    if (allObjectives.length !== organizationObjectives.length) {
      const organizationObjectivesIDs: string[] = [];
      for (const orgObjective of organizationObjectives) {
        organizationObjectivesIDs.push(orgObjective.objective.id);
      }
      for (const objective of allObjectives) {
        if (
          !organizationObjectivesIDs.includes(objective.id) // objective isn't already assigned ot the organization
          && objective.domain.sites.includes(this.organization.site) // objective domain registered sites matches the orgainzations site
          && ((!objective.organizations?.length) || objective.organizations?.includes(this.organization.id))) // the objective is assigned to all organizations or is specifically assigned to this organization
        {
          // Create a fake orgObjective an only insert if they enable it
          const orgObjective: OrganizationObjective = {
            id: undefined,
            __typename: "OrganizationObjective",
            objective: objective,
            organizationID: this.organization.id,
            organizationObjectiveObjectiveId: objective.id,
          };
          if (orgObjective) {
            organizationObjectives.push(orgObjective);
          }
        }
      }
    }

    const orgObjectiveMap = new Map<string, Map<string, OrganizationObjective>>();
    for (const objective of organizationObjectives) {
      if (!orgObjectiveMap.has(objective.objective.objectiveDomainId)) {
        orgObjectiveMap.set(objective.objective.objectiveDomainId, new Map<string, OrganizationObjective>());
      }
      orgObjectiveMap.get(objective.objective.objectiveDomainId).set(objective.objective.id, objective);
    }

    // TODO Add functionality for Custom Organization Specific Domains / Objectives
    for (const orgObjectiveDomain of this.objectiveDomains) {
      if (orgObjectiveDomain.key === UserDefinedObjectiveDomainTypes.ORG_DEFINED) {
        this.orgDefinedDomain = orgObjectiveDomain;
        break;
      }
    }
    if (!orgObjectiveMap.has(this.orgDefinedDomain.id)) {
      orgObjectiveMap.set(this.orgDefinedDomain.id, new Map<string, OrganizationObjective>());
    }
    this.organizationObjectiveDomainsMap = orgObjectiveMap;

    this.objectiveDomains = this.objectiveDomains
      .filter(domain => this.organizationObjectiveDomainsMap.has(domain.id) || domain.key == UserDefinedObjectiveDomainTypes.ORG_DEFINED)
      .sort((a,b) => {
        if (a.key == UserDefinedObjectiveDomainTypes.ORG_DEFINED) {
          return 1;
        } else if (b.key == UserDefinedObjectiveDomainTypes.ORG_DEFINED) {
          return -1;
        }
        return a.display.localeCompare(b.display);
      });
  }

  getObjectiveTitles(id: string): string {
    const objectiveDisplays: string[] = [];
    if (this.organizationObjectiveDomainsMap.get(id)) {
      for (const entry of this.organizationObjectiveDomainsMap.get(id).entries()) {
        objectiveDisplays.push(entry[1].objective.display);
      }
    }
    return objectiveDisplays.join(", ");
  }

  get isShowOrganizationName(): boolean {
    return this.organization && this.currentUser?.orgId !== this.organization?.id;
  }

  get organizationName(): string {
    return this.organization?.orgName;
  }

  ngOnDestroy(): void {
    if (this.subscriptions.length) {
      for (const sub of this.subscriptions) {
        sub.unsubscribe();
      }
    }
  }

}
