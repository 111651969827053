<inthraction-page-header pageTitle="Stand-Up Surveys"></inthraction-page-header>
<mat-accordion multi>
  <mat-expansion-panel *ngFor="let manager of sharedStandupManagers">
    <mat-expansion-panel-header>
      <mat-panel-title>{{manager.firstName}} {{manager.lastName}}</mat-panel-title>
    </mat-expansion-panel-header>
    <ng-template matExpansionPanelContent>
      <inthraction-standup-history [manager]="manager"></inthraction-standup-history>
    </ng-template>
  </mat-expansion-panel>
</mat-accordion>
