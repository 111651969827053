<h1 mat-dialog-title>Add Consultant Association</h1>
<mat-horizontal-stepper [linear]="true" #stepper>
  <mat-step [stepControl]="searchForConsultantFG">
    <form [formGroup]="searchForConsultantFG">
      <ng-template matStepLabel>Search</ng-template>
      <div mat-dialog-content>
        <mat-form-field class="full-width">
          <mat-label>Search for User by Email</mat-label>
          <mat-autocomplete #emailSearch="matAutocomplete">
            <mat-option *ngFor="let email of filteredEmails | async" [value]="email">{{email}}</mat-option>
          </mat-autocomplete>
          <input matInput [matAutocomplete]="emailSearch" placeholder="jane.doe@example.com" #emailSearchFC formControlName="email"
                 (input)="emailSearchFC.value = emailSearchFC.value.toLowerCase()"
                 (change)="searchForConsultantFG.get('isValidEmail').setValue(false)">
          <mat-error *ngIf="hasError(searchForConsultantFG, 'required', 'email')">Email is required</mat-error>
          <mat-error *ngIf="hasError(searchForConsultantFG, 'email', 'email')">Must be a valid email address</mat-error>
          <mat-error *ngIf="hasError(searchForConsultantFG, 'disabled', 'email')">A user matching this email exists but is disabled.</mat-error>
          <mat-error *ngIf="hasError(searchForConsultantFG, 'duplicate', 'email')">An association for this user already exists.</mat-error>
        </mat-form-field>
      </div>
      <div mat-dialog-actions>
        <button (click)="onSearchClick()" mat-raised-button [disabled]="hasError(searchForConsultantFG, 'null', 'email')">Search</button>
      </div>
    </form>
  </mat-step>
  <mat-step [stepControl]="addConsultantAssociationFG">
    <ng-template matStepLabel>Submit</ng-template>
    <div mat-dialog-content>
      <mat-form-field class="full-width" *ngIf="employee">
        <mat-label>Name</mat-label>
        <input readonly matInput value="{{employee.firstName}} {{employee.lastName}}">
      </mat-form-field>

      <mat-form-field class="full-width" *ngIf="organization">
        <mat-label>Consulting for</mat-label>
        <input readonly matInput value="{{organization.orgName}}">
      </mat-form-field>

      <form [formGroup]="addConsultantAssociationFG">
        <mat-slide-toggle formControlName="isIncentivized">Receive Incentives</mat-slide-toggle>
      </form>
    </div>
    <div mat-dialog-actions>
      <button (click)="onCancelClick()" mat-button>Cancel</button>
      <button #submitBtn type="submit" (click)="onSaveClick(addConsultantAssociationFG.value, submitBtn)" cdkFocusInitial mat-raised-button color="primary" [disabled]="addConsultantAssociationFG.invalid">Save</button>
    </div>
  </mat-step>
</mat-horizontal-stepper>
