import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Employee, EmployeeImpl, Project, ProjectMilestoneAssignment } from "@inthraction/data-models";
import {
  AuthService,
  EmployeeService,
  ProjectService,
  SurveyService,
  UpdateProjectMilestoneAssignment
} from "@inthraction/services";
import * as moment from "moment";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { DateValidators } from "../../validators/date-validators";
import { CADENCE_TYPE_LABELS } from "@inthraction/labels";
import { CADENCE_TYPES } from "@inthraction/codes";

@Component({
  selector: "inthraction-add-edit-project-milestone",
  templateUrl: "./add-edit-project-milestone.component.html",
  styleUrls: ["./add-edit-project-milestone.component.scss"]
})
export class AddEditProjectMilestoneComponent implements OnInit {

  readonly cadenceTypes = CADENCE_TYPES;
  readonly cadenceTypeLabels = CADENCE_TYPE_LABELS;

  private employee: Employee;
  private projectMilestoneAssignment: ProjectMilestoneAssignment;

  editMode = false;
  selectedCadence = "";

  milestoneFG = new FormGroup({
    title: new FormControl("", [Validators.required]),
    description: new FormControl("", []),
    startDate: new FormControl("", [Validators.required]),
    endDate: new FormControl("", [DateValidators.dateGreaterThanEqualToCurrentDate({ notCurrent: true }), Validators.required]),
    cadence: new FormControl("", [Validators.required]),
    team: new FormControl()
  }, { validators: DateValidators.dateLessThan("startDate", "endDate", { range: true }) });

  teamMembers: Employee[] = [];

  constructor(
    private projectService: ProjectService,
    private employeeService: EmployeeService,
    private dialogRef: MatDialogRef<AddEditProjectMilestoneComponent>,
    private authService: AuthService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
  }

  async ngOnInit() {
    const employee = await this.employeeService.getCurrentEmployee();
    this.employee = new EmployeeImpl(employee);
    await this.initializeTeamMembers(this.data.project.team);
    if (this.data.milestoneID) {
      this.editMode = true;
      this.projectMilestoneAssignment = await this.projectService.getProjectMilestoneAssignmentByID(this.data.milestoneID);
      this.milestoneFG.get("title").setValue(this.projectMilestoneAssignment.title);
      this.milestoneFG.get("description").setValue(this.projectMilestoneAssignment.description);
      this.milestoneFG.get("cadence").setValue(this.projectMilestoneAssignment.cadence);
      this.selectedCadence = this.projectMilestoneAssignment.cadence;
      if (this.projectMilestoneAssignment.team && this.projectMilestoneAssignment.team.length > 0) {
        this.milestoneFG.get("team").setValue(this.projectMilestoneAssignment.team);
      }
      // @ts-ignore
      this.milestoneFG.get("startDate").setValue(moment(this.projectMilestoneAssignment.startDate, "YYYY-MM-DD"));
      // @ts-ignore
      this.milestoneFG.get("endDate").setValue(moment(this.projectMilestoneAssignment.endDate, "YYYY-MM-DD"));
    }
  }

  onCancelClick() {
    this.dialogRef.close();
  }

  async onSaveClick(value) {
    const project: Project = this.data.project;
    if (this.editMode) {
      let needsUpdate = false;
      let recalculateNextSurveyDate = false;
      const updateProjectMilestoneAssignmentInput: UpdateProjectMilestoneAssignment = { id: this.projectMilestoneAssignment.id };

      if (value.title && this.projectMilestoneAssignment.title !== value.title) {
        updateProjectMilestoneAssignmentInput.title = value.title;
        needsUpdate = true;
      }

      if (value.description && this.projectMilestoneAssignment.description !== value.description) {
        updateProjectMilestoneAssignmentInput.description = value.description;
        needsUpdate = true;
      }

      if (value.startDate && this.projectMilestoneAssignment.startDate !== moment(value.startDate).format("YYYY-MM-DD")) {
        updateProjectMilestoneAssignmentInput.startDate = moment(value.startDate).format("YYYY-MM-DD");
        needsUpdate = true;
        recalculateNextSurveyDate = true;
      }

      if (value.endDate && this.projectMilestoneAssignment.endDate !== moment(value.endDate).format("YYYY-MM-DD")) {
        updateProjectMilestoneAssignmentInput.endDate = moment(value.endDate).format("YYYY-MM-DD");
        needsUpdate = true;
        recalculateNextSurveyDate = true;
      }

      if (value.cadence && this.projectMilestoneAssignment.cadence !== value.cadence) {
        updateProjectMilestoneAssignmentInput.cadence = value.cadence;
        needsUpdate = true;
        recalculateNextSurveyDate = true;
      }

      if (value.team !== this.projectMilestoneAssignment.team) {
        updateProjectMilestoneAssignmentInput.team = value.team;
        needsUpdate = true;
      }

      if (needsUpdate) {
        if (recalculateNextSurveyDate) {
          updateProjectMilestoneAssignmentInput.nextSurveyDate = SurveyService.calculateNextSurveyDate(moment(value.startDate).format("YYYY-MM-DD"), value.cadence, moment(value.endDate).format("YYYY-MM-DD"));
        }

        this.projectMilestoneAssignment = await this.projectService.updateMilestone(updateProjectMilestoneAssignmentInput);
      }
    } else {
      const milestone: ProjectMilestoneAssignment = {
        id: null,
        projectID: project.id,
        organizationID: project.organizationID,
        milestoneType: "SELF",
        title: value.title,
        description: value.description,
        startDate: moment(value.startDate).format("YYYY-MM-DD"),
        endDate: moment(value.endDate).format("YYYY-MM-DD"),
        cadence: value.cadence,
        nextSurveyDate: null,
        assignedBy: this.employee.id,
        assignmentDate: moment().format("YYYY-MM-DD"),
        team: null
      };
      if (value.team) {
        milestone.team = value.team;
      }
      milestone.nextSurveyDate = SurveyService.calculateNextSurveyDate(milestone.startDate, milestone.cadence, milestone.endDate);
      this.projectMilestoneAssignment = await this.projectService.createProjectMilestone(milestone);
    }
    this.projectService.clearMemoizedMilestones(this.projectMilestoneAssignment.projectID);
    this.dialogRef.close({ milestone: this.projectMilestoneAssignment });
  }

  public hasError = (controlName: string, errorName: string) => {
    if (controlName && this.milestoneFG.controls[controlName]) {
      return this.milestoneFG.controls[controlName].hasError(errorName);
    }
    return this.milestoneFG.hasError(errorName);
  };

  private async initializeTeamMembers(team: string[]) {
    if (team && team.length > 0) {
      for (const employeeId of team) {
        this.teamMembers.push(await this.employeeService.getEmployeeByIDMemoize(employeeId));
      }
    }
  }
}
