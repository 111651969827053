import { Component, OnDestroy, OnInit } from "@angular/core";
import { ExternalService, IPService } from "@inthraction/services";
import { Subscription } from "rxjs";
import { ActivatedRoute } from "@angular/router";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MatButton } from "@angular/material/button";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-unsubscribe",
  templateUrl: "./unsubscribe.component.html",
  styleUrls: ["./unsubscribe.component.scss"]
})
export class UnsubscribeComponent implements OnInit, OnDestroy {
  static externalRespondentIDParameterName = "externalRespondentID";
  static externalRespondentEmailParameterName = "externalRespondentEmail";
  private ip: string;
  private subscriptions: Subscription[] = [];
  private externalRespondentID: string;

  private errorMessage = "Please try again.";

  form = new FormGroup({
    email: new FormControl("", [Validators.required, Validators.email, Validators.pattern(/^\S+@\S+\.\S+$/)]
    ),
    doNotDisturb: new FormControl("", [Validators.required])
  });

  constructor(
    private ipService: IPService,
    private externalService: ExternalService,
    private route: ActivatedRoute,
    protected toastr: ToastrService
  ) {
  }

  async ngOnInit() {
    this.ip = await this.ipService.getIP();

    this.subscriptions.push(this.route.paramMap.subscribe(async queryParams => {
      const externalRespondentID = queryParams.get(UnsubscribeComponent.externalRespondentIDParameterName);
      if (externalRespondentID) {
        this.externalRespondentID = externalRespondentID;
      } else {
        this.externalRespondentID = null;
      }
    }));

    this.subscriptions.push(this.route.paramMap.subscribe(async queryParams => {
      const externalRespondentEmail = queryParams.get(UnsubscribeComponent.externalRespondentEmailParameterName);
      if (externalRespondentEmail) {
        this.form.get("email").setValue(externalRespondentEmail);
      } else {
        this.form.get("email").setValue(null);
      }
    }));

  }

  async submitSurvey(formValue: any, submitBtn: MatButton | HTMLButtonElement): Promise<void> {
    submitBtn.disabled = true;
    if (this.form.valid) {
      await this.externalService.createDoNotDisturbRequest(this.ip, this.externalRespondentID, formValue.email, formValue.doNotDisturb == 1 ? true : false);
      this.toastr.success("It may take up to 24 hours for your change to take affect. Thank you for your patience.", "Preferences Saved");
      submitBtn.disabled = !this.form.valid;
    } else {
      submitBtn.disabled = !this.form.valid;
      this.toastr.error(this.errorMessage);
    }
  }

  public hasError = (controlName: string, errorName: string) => {
    if (controlName && this.form.controls[controlName]) {
      return this.form.controls[controlName].hasError(errorName);
    }
    return this.form.hasError(errorName);
  };

  ngOnDestroy(): void {
    if (this.subscriptions) {
      for (const subscription of this.subscriptions) {
        subscription.unsubscribe();
      }
    }
  }

}
