import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Employee, EmployeeImageInterface } from "@inthraction/data-models";
import { FormControl } from "@angular/forms";
import { Observable } from "rxjs";
import { EmployeeService } from "@inthraction/services";
import { map, startWith } from "rxjs/operators";

@Component({
  selector: "inthraction-employee-search",
  templateUrl: "./employee-search.component.html",
  styleUrls: ["./employee-search.component.scss"]
})
export class EmployeeSearchComponent implements OnInit {

  control = new FormControl();
  filteredEmployees: Observable<Employee[]>;
  private employeeImagesMap: Map<string, EmployeeImageInterface> = new Map<string, EmployeeImageInterface>();
  private allOrgEmployees: Employee[] = [];

  @Input()
  options: EmployeeSearchOptions = {};

  @Output()
  employeeSelected: EventEmitter<Employee> = new EventEmitter<Employee>();

  constructor(
    private employeeService: EmployeeService
  ) {
  }

  async ngOnInit() {
    if (this.options.employeeSet?.length) {
      this.allOrgEmployees.push(...this.options.employeeSet);
    } else {
      const allOrgEmployees = await this.employeeService.getEmployeesForOrganizationByOrganization({includeDisabled:false, organizationID: this.options?.organizationID, onlyDisable:false, memoize:true});
      this.allOrgEmployees.push(...allOrgEmployees);
    }

    if(this.options.includeConsultants && this.options.organizationID) {
      const consultantAssociations = (await this.employeeService.getConsultantsForOrganizationByOrganization(this.options.organizationID)).filter(ca => ca.status != 'DISABLED');
      for (let consultantAssociation of consultantAssociations) {
        const consultant = await this.employeeService.getEmployeeByIDMemoize(consultantAssociation.employeeID);
        if(consultant.orgId != this.options.organizationID) {
          this.allOrgEmployees.push(consultant);
        }
      }
    }

    this.allOrgEmployees.sort((a, b) => a.lastName.localeCompare(b.lastName));
    this.loadEmployeeImages(this.allOrgEmployees);
    this.control.valueChanges.subscribe(async (value) => {
      if (value && !(value instanceof String || typeof value === "string")) {
        this.employeeSelected.emit(value);
        if (this.options.clearAfterSelect) {
          this.control.reset("", { emitEvent: false });
        }
      }
    });

    this.filteredEmployees = this.control.valueChanges.pipe(
      startWith(""),
      map(value => this._filter(value))
    );

  }

  getEmployeeImage(employeeID: string) {
    return this.employeeImagesMap.get(employeeID);
  }

  displayFn(employee: Employee): string {
    return employee ? `${employee.firstName} ${employee.lastName}` : "";
  }

  private _filter(value: string): Employee[] {
    const filterValue = this._normalizeValue(value);
    return this.allOrgEmployees.filter(employee => this._normalizeValue(`${employee.firstName}${employee.lastName}${employee.email}`).includes(filterValue));
  }

  private _normalizeValue(value: string): string {
    if (value && (value as any instanceof String || typeof value === "string")) {
      return value?.toLowerCase().replace(/\s/g, "");
    }
  }

  private async loadEmployeeImages(allOrgEmployees: Employee[]) {
    for (const employee of allOrgEmployees) {
      const image = await this.employeeService.getEmployeeImageMemoize(employee.id, employee.orgId);
      if (image) {
        this.employeeImagesMap.set(employee.id, image);
      }
    }
  }

}

export interface EmployeeSearchOptions {
  clearAfterSelect?: boolean;
  organizationID?: string;
  includeConsultants?: boolean;
  employeeSet?: Employee[];
}
