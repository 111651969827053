import { OrganizationConfigurationCodes } from './ConfigurationCodes';

export enum QRType {
  ROCKED = "ROCKED"
}

export const QRTypes: QRType[] = Object.keys(QRType) as QRType[];


export const QRTypeToIntegrationConfiguration: Map<QRType,OrganizationConfigurationCodes> = new Map<QRType,OrganizationConfigurationCodes>([[QRType.ROCKED, OrganizationConfigurationCodes.ROCKED_INTEGRATION]]);
