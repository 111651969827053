import { AfterViewInit, Component, OnInit, ViewChild } from "@angular/core";
import { EmployeeService, OrganizationService } from "@inthraction/services";
import { DEPARTMENT_TYPE_LABELS, READINESS_TYPE_LABELS } from "@inthraction/labels";
import { MatTableDataSource } from "@angular/material/table";
import { MatSort } from "@angular/material/sort";
import { saveAs } from "file-saver";
import * as moment from "moment";
import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import { EmployeeImpl } from "@inthraction/data-models";
import { animate, state, style, transition, trigger } from "@angular/animations";
import { OrganizationConfigurationCodes } from "@inthraction/codes";

@Component({
  selector: "app-readiness-report",
  templateUrl: "./readiness-report.component.html",
  styleUrls: ["./readiness-report.component.scss"],
  animations: [
    trigger("detailExpand", [
      state("collapsed", style({ height: "0px", minHeight: "0" })),
      state("expanded", style({ height: "*" })),
      transition("expanded <=> collapsed", animate("225ms cubic-bezier(0.4, 0.0, 0.2, 1)"))
    ])
  ]
})
export class ReadinessReportComponent implements OnInit, AfterViewInit {

  tableData: MatTableDataSource<TableData> = new MatTableDataSource<TableData>([]);
  displayedColumns: string[] = ["department", "manager", "name", "currentReadiness", "currentReadinessDate", "previousReadiness", "previousReadinessDate", "daysInPosition", "last1on1Date", "criticalPosition", "totalPerformanceScore", "inthractionScore", "objectiveScore"];
  filterExample: string;
  expandedElement: TableData | null;

  constructor(
    private employeeService: EmployeeService,
    private organizationService: OrganizationService
  ) {
  }

  @ViewChild(MatSort) sort: MatSort;

  ngAfterViewInit() {
    if (this.tableData) {
      this.tableData.sort = this.sort;
    }
  }

  async ngOnInit() {


    const tableData: TableData[] = [];
    let objectiveScores;
    let intHRactionScores;
    const calculationTypeConfig = await this.organizationService.getOrganizationConfiguration(OrganizationConfigurationCodes.INTHRACTION_CALCULATION);
    if (calculationTypeConfig && "ROLLING" === calculationTypeConfig.configStringValue) {
      objectiveScores = await this.employeeService.getMTDTotalObjectiveScoreForOrganization();
      intHRactionScores = await this.employeeService.getMTDTotalInthractionActionScoreForOrganization();
    } else {
      objectiveScores = await this.employeeService.getYTDTotalObjectiveScoreForOrganization();
      intHRactionScores = await this.employeeService.getYTDTotalInthractionActionScoreForOrganization();
    }

    const readinessHistories = await this.employeeService.getEmployeeReadinessHistories();
    const employees = await this.employeeService.getEmployeesForOrganizationByOrganization({memoize:true});
    for (const employee of employees) {
      const data: TableData = {
        name: `${employee.firstName} ${employee.lastName}`,
        department: DEPARTMENT_TYPE_LABELS[employee.department],
        criticalPosition: employee.criticalPosition
      };
      if (!this.filterExample) {
        this.filterExample = data.department;
      }
      if (employee.managerID) {
        const manager = employees.find(e => e.id === employee.managerID);
        data.manager = `${manager.firstName} ${manager.lastName}`;
      }
      if (employee.readiness) {
        const readinessHistory = readinessHistories.filter( r => r.employeeID == employee.id);
        const currentReadiness = readinessHistory.find(r => r.id === employee.readiness);
        data.currentReadiness = READINESS_TYPE_LABELS[currentReadiness.readiness];
        data.currentReadinessDate = currentReadiness.createdAt;
        data.currentReadinessComments = currentReadiness.comments;

        if (currentReadiness.opportunities && currentReadiness.opportunities.length > 0) {
          let index = 0;
          for (const opportunity of currentReadiness.opportunities) {
            switch (index) {
              case 0:
                data.currentReadinessOpportunity1 = opportunity;
                break;
              case 1:
                data.currentReadinessOpportunity2 = opportunity;
                break;
              case 2:
                data.currentReadinessOpportunity3 = opportunity;
                break;
              default:
                break;
            }
            index++;
          }
        }

        if (readinessHistory.length > 1) {
          readinessHistory.sort((a, b) => {
            return moment(a.createdAt).isBefore(b.createdAt) ? 1 : -1;
          });
          const previousReadiness = readinessHistory[1];
          data.previousReadiness = READINESS_TYPE_LABELS[previousReadiness.readiness];
          data.previousReadinessDate = previousReadiness.createdAt;
        }
      }
      if (employee.positionDate) {
        data.daysInPosition = EmployeeImpl.getDaysIn(employee.positionDate);
      }
      data.last1on1Date = employee.lastOneOnOne;

      objectiveScores.filter(o => o.employeeID === employee.id).forEach(o => {
        data.objectiveScore = o.score;
      });

      intHRactionScores.filter(o => o.employeeID === employee.id).forEach(o => {
        data.inthractionScore = o.score;
      });

      data.totalPerformanceScore = EmployeeService.calculateScore(data.inthractionScore, data.objectiveScore);

      tableData.push(data);
    }
    this.tableData.data = tableData;
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.displayedColumns, event.previousIndex, event.currentIndex);
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.tableData.filter = filterValue.trim().toLowerCase();

    if (this.tableData.paginator) {
      this.tableData.paginator.firstPage();
    }
  }

  downloadCSV() {
    const fileName = `Readiness Report ${moment().format("l")}`;
    const csvData = [];
    const csvHeaders = [
      "Department",
      "Manager",
      "Name",
      "Current Readiness",
      "Current Readiness Date",
      "Previous Readiness",
      "Previous Readiness Date",
      "Days in Position",
      "Last 1-on-1 Date",
      "Critical Position",
      "Total Performance Score",
      "intHRaction Score",
      "Objective Score",
      "Current Readiness Comments",
      "Current Readiness Opportunity 1",
      "Current Readiness Opportunity 2",
      "Current Readiness Opportunity 2"
    ];

    csvData.push(csvHeaders);
    for (const row of this.tableData.data) {
      const rowData = [
        row.department || "",
        row.manager || "",
        row.name || "",
        row.currentReadiness || "",
        row.currentReadinessDate ? moment(row.currentReadinessDate).format("l") : "",
        row.previousReadiness || "",
        row.previousReadinessDate ? moment(row.previousReadinessDate).format("l") : "",
        row.daysInPosition || "",
        row.last1on1Date ? moment(row.last1on1Date).format("l") : "",
        row.criticalPosition ? "Yes" : "No",
        row.totalPerformanceScore || "",
        row.inthractionScore || "",
        row.objectiveScore || "",
        row.currentReadinessComments ? `"${row.currentReadinessComments.replace("\"", "'")}"` : "",
        row.currentReadinessOpportunity1 ? `"${row.currentReadinessOpportunity1.replace("\"", "'")}"` : "",
        row.currentReadinessOpportunity2 ? `"${row.currentReadinessOpportunity2.replace("\"", "'")}"` : "",
        row.currentReadinessOpportunity3 ? `"${row.currentReadinessOpportunity3.replace("\"", "'")}"` : ""
      ];

      csvData.push(rowData);
    }
    let csvArray = csvData.join("\r\n");
    const blob = new Blob([csvArray], { type: "text/csv" });
    saveAs(blob, `${fileName}.csv`.replace(" ", "_"));
  }


}

interface TableData {
  name: string;
  manager?: string;
  department: string;
  currentReadiness?: string;
  currentReadinessComments?: string;
  currentReadinessOpportunity1?: string;
  currentReadinessOpportunity2?: string;
  currentReadinessOpportunity3?: string;
  currentReadinessDate?: string;
  previousReadiness?: string;
  previousReadinessDate?: string;
  daysInPosition?: number;
  last1on1Date?: string;
  totalPerformanceScore?: number;
  inthractionScore?: number;
  objectiveScore?: number;
  criticalPosition?: boolean;
}
