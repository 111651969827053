import { Component, OnInit } from "@angular/core";

@Component({
  selector: "inthraction-inactive-user",
  templateUrl: "./inactive-employee.component.html",
  styleUrls: ["./inactive-employee.component.scss"]
})
export class InactiveEmployeeComponent implements OnInit {

  constructor() {
  }

  ngOnInit(): void {
  }

}
