<mat-toolbar>
  <span *ngIf="pageTitle" class="page-title">{{pageTitle}}</span>
  <ng-content></ng-content>
  <span *ngIf="addBtnTitle || downloadBtn" class="toolbar-spacer"></span>
  <ng-container *ngIf="downloadBtn">
    <button (click)="downloadBtnClickEvent.emit()" [disabled]="downloadBtnDisabled" aria-label="Download CSV" mat-icon-button title="Download CSV">
      <mat-icon>download</mat-icon>
    </button>
  </ng-container>
  <ng-container *ngIf="addBtnTitle">
    <button (click)="addBtnClickEvent.emit()" [disabled]="addBtnDisabled" aria-label="{{addBtnTitle}}" mat-icon-button [title]="addBtnTitle">
      <mat-icon>add_box</mat-icon>
    </button>
  </ng-container>
</mat-toolbar>
