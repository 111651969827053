<h1 mat-dialog-title>{{ addMode ? 'Add' : 'Edit' }} Objective QR Code</h1>
  <form [formGroup]="qrForm" (ngSubmit)="onSaveClick(qrForm.value, submitQRBtn)" autocomplete="off" novalidate>
    <div mat-dialog-content>
      <mat-form-field>
        <mat-label>QR Type</mat-label>
        <mat-select formControlName="qrType">
          <mat-option *ngFor="let qrType of QRTypes" [value]="qrType">{{ qrType }}</mat-option>
        </mat-select>
        <mat-error *ngIf="hasError('qrType', 'required')">QR Type is required</mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-label>QR Title</mat-label>
        <input matInput formControlName="qrTitle">
        <mat-error *ngIf="hasError('qrTitle', 'required')">QR Title is required</mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-label>QR Aria Label</mat-label>
        <input matInput formControlName="qrLabel">
        <mat-error *ngIf="hasError('qrLabel', 'required')">QR Aria Label is required</mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-label>QR Image</mat-label>
        <input matInput formControlName="qrImageSrc" placeholder="/assets/rocked.png">
        <mat-error *ngIf="hasError('qrImageSrc', 'required')">QR Image is required</mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-label>QR Data</mat-label>
        <input matInput formControlName="qrData" placeholder="https://url.com">
        <mat-error *ngIf="hasError('qrData', 'required')">QR Data is required</mat-error>
      </mat-form-field>
    </div>
      <div mat-dialog-actions>
        <button (click)="onCancelClick()" mat-button type="button">Cancel</button>
        <button #submitQRBtn [disabled]="!qrForm.valid" color="primary" mat-raised-button type="submit">Add QR</button>
      </div>
  </form>
