<inthraction-page-header pageTitle="Manage Organizations" addBtnTitle="Add Organization" (addBtnClickEvent)="addOrganization()"></inthraction-page-header>
<table *ngIf="organizations" mat-table [dataSource]="organizations">
  <ng-container matColumnDef="orgName">
    <th mat-header-cell *matHeaderCellDef> Name</th>
    <td mat-cell *matCellDef="let element"> {{element.orgName}} </td>
  </ng-container>

  <ng-container matColumnDef="site">
    <th mat-header-cell *matHeaderCellDef> Site</th>
    <td mat-cell *matCellDef="let element"> {{element.site}} </td>
  </ng-container>

  <ng-container matColumnDef="domains">
    <th mat-header-cell *matHeaderCellDef> Domains</th>
    <td mat-cell *matCellDef="let element"> {{element.domains}} </td>
  </ng-container>

  <ng-container matColumnDef="disabled">
    <th mat-header-cell *matHeaderCellDef> Disabled</th>
    <td mat-cell *matCellDef="let element"> {{element.disabled ? 'Disabled' : ''}} </td>
  </ng-container>

  <ng-container matColumnDef="edit">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell *matCellDef="let element">
      <button mat-stroked-button (click)="editOrganization(element)">Edit</button>
    </td>
  </ng-container>

  <ng-container matColumnDef="configurations">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell *matCellDef="let element">
      <button mat-stroked-button (click)="editOrganizationConfigurations(element)">Configurations</button>
    </td>
  </ng-container>

  <ng-container matColumnDef="objectives">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell *matCellDef="let element">
      <button mat-stroked-button (click)="editOrganizationObjectives(element)">Objectives</button>
    </td>
  </ng-container>

  <ng-container matColumnDef="inspHRations">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell *matCellDef="let element">
      <button mat-stroked-button (click)="editOrganizationinspHRations(element)">inspHRations</button>
    </td>
  </ng-container>

  <ng-container matColumnDef="users">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell *matCellDef="let element">
      <button mat-stroked-button (click)="editOrganizationEmployees(element)">Users</button>
    </td>
  </ng-container>

  <ng-container matColumnDef="consultants">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell *matCellDef="let element">
      <button mat-stroked-button (click)="editOrganizationConsultants(element)">Consultants</button>
    </td>
  </ng-container>

  <ng-container matColumnDef="resend">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell *matCellDef="let element">
      <button mat-stroked-button class="no-wrap" (click)="resendInvites(element)">Send Invites</button>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
