import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { EmployeeService, OrganizationService } from "@inthraction/services";
import { ToastrService } from "ngx-toastr";
import { ConsultantAssociation, Employee, Organization } from "@inthraction/data-models";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Observable } from "rxjs";
import { MatStepper } from "@angular/material/stepper";
import { MatButton } from "@angular/material/button";

@Component({
  selector: "inthraction-add-consultant-assignment",
  templateUrl: "./add-consultant-assignment.component.html",
  styleUrls: ["./add-consultant-assignment.component.scss"]
})
export class AddConsultantAssignmentComponent implements OnInit {

  @ViewChild("stepper") private addFormStepper: MatStepper;
  private email = new FormControl("", [Validators.required, Validators.email]);

  organization: Organization;
  employee: Employee;
  filteredEmails: Observable<string[]>;
  searchForConsultantFG = new FormGroup({
    email: this.email,
    isValidEmail: new FormControl(false, [Validators.requiredTrue])
  });

  addConsultantAssociationFG = new FormGroup({
    employeeID: new FormControl("", [Validators.required]),
    isIncentivized: new FormControl(false)
  });

  constructor(
    public dialogRef: MatDialogRef<AddConsultantAssignmentComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private employeeService: EmployeeService,
    private organizationService: OrganizationService,
    private toastrService: ToastrService
  ) {
    this.organization = data.organization;
  }

  ngOnInit(): void {
  }

  onCancelClick(): void {
    this.dialogRef.close();
  }

  async onSaveClick(formValue: any, submitBtn: MatButton | HTMLButtonElement): Promise<void> {
    submitBtn.disabled = true;
    const newAssociation = new ConsultantAssociation();
    newAssociation.employeeID = formValue.employeeID;
    newAssociation.organizationID = this.organization.id;
    newAssociation.incentive = formValue.isIncentivized;

    try {
      const association: ConsultantAssociation = await this.employeeService.createConsultantAssociation(newAssociation);
      if (association) {

        if (!this.employee.consulting) {
          this.employee.consulting = [];
        }
        if (!this.employee.consulting.includes(association.organizationID)) {
          this.employee.consulting.push(association.organizationID);
          await this.employeeService.updateEmployee({
            id: association.employeeID,
            consulting: this.employee.consulting
          });
        }

        this.toastrService.success("New association created");
        this.dialogRef.close({ consultantAssociation: association });
      } else {
        this.toastrService.error("Failed to create new association");
        submitBtn.disabled = this.addConsultantAssociationFG.invalid;
      }
    } catch (err) {
      console.error(err);
      this.toastrService.error("Failed to create new association");
      submitBtn.disabled = this.addConsultantAssociationFG.invalid;
      throw err;
    }
  }

  async onSearchClick(): Promise<void> {
    const emailSearchFC = this.searchForConsultantFG.get("email");
    this.searchForConsultantFG.get("isValidEmail").setValue(true);
    const employee = await this.searchForEmployee(emailSearchFC.value);
    if (employee) {
      this.employee = employee;
      this.addConsultantAssociationFG.get("employeeID").setValue(employee.id);
      this.addFormStepper.next();
    } else {
      this.addConsultantAssociationFG.get("employeeID").setValue("");
      this.employee = null;
    }
  }

  private async searchForEmployee(email: string): Promise<Employee> {
    const searchResult = await this.employeeService.getEmployeeByEmailMemoize(email.toLowerCase());
    const consultantIDs: string[] = (await this.employeeService.getConsultantsForOrganizationByOrganization(this.organization.id)).map(assocation => assocation.employeeID);
    if (searchResult && searchResult.disabled) {
      this.searchForConsultantFG.get("email").setErrors({ disabled: true });
    } else if (searchResult && consultantIDs.includes(searchResult.id)) {
      this.searchForConsultantFG.get("email").setErrors({ duplicate: true });
    } else if (searchResult) {
      return searchResult;
    } else {
      this.searchForConsultantFG.get("email").setErrors({ email: true });
    }
  }

  public hasError = (form: FormGroup, errorName: string, controlName?: string) => {
    if (controlName) {
      if ("null" === errorName) {
        return !(!form.controls[controlName].errors);
      }
      return form.controls[controlName].hasError(errorName);
    }
    if ("null" === errorName) {
      return !(!form.errors);
    }
    return form.hasError(errorName);
  };

}
