import { AfterViewInit, Component, OnDestroy, OnInit, TemplateRef, ViewChild, ViewContainerRef } from "@angular/core";
import { ConsultantFavorites, Employee } from "@inthraction/data-models";
import { AuthService, EmployeeService } from "@inthraction/services";
import { ActivatedRoute, ParamMap, Router } from "@angular/router";
import { Subscription } from "rxjs";
import { Location } from "@angular/common";
import { MatDialog } from "@angular/material/dialog";
import { TemplatePortal } from "@angular/cdk/portal";
import { HrDashboardComponent } from "../../dashboard/hr-dashboard/hr-dashboard.component";

enum HRWorkforceContext {
  FAVORITES = "FAVORITES",
  WORKFORCE = "WORKFORCE",
}

@Component({
  selector: "inthraction-hr-workforce",
  templateUrl: "./hr-workforce.component.html",
  styleUrls: ["./hr-workforce.component.scss"]
})
export class HrWorkforceComponent implements OnInit, AfterViewInit, OnDestroy {

  private hrFavorites: ConsultantFavorites;
  private subscriptions: Subscription[] = [];
  currentUser: Employee;
  subordinates: Employee[] = [];
  topEmployees: Employee[] = [];
  favoriteEmployees: Employee[] = [];
  favorites: string[] = [];
  navToEmployeeID: string;
  _selectedPortal: TemplatePortal<any>;
  @ViewChild("favoritesContent") favoritesPortalContent: TemplateRef<unknown>;
  @ViewChild("workforceContent") workforcePortalContent: TemplateRef<unknown>;
  favoritesPortal: TemplatePortal<unknown>;
  workforcePortal: TemplatePortal<unknown>;


  constructor(
    private employeeService: EmployeeService,
    private route: ActivatedRoute,
    private router: Router,
    private location: Location,
    private authService: AuthService,
    public dialog: MatDialog,
    private _viewContainerRef: ViewContainerRef
  ) {
  }

  ngOnDestroy(): void {
    if (this.subscriptions.length) {
      for (const sub of this.subscriptions) {
        sub.unsubscribe();
      }
    }
  }

  ngAfterViewInit() {
    this.favoritesPortal = new TemplatePortal(this.favoritesPortalContent, this._viewContainerRef, HRWorkforceContext.FAVORITES);
    this.workforcePortal = new TemplatePortal(this.workforcePortalContent, this._viewContainerRef, HRWorkforceContext.WORKFORCE);
  }

  async ngOnInit() {
    await this.retrieveFavorites();
    await this.initializeCurrentUser();
    await this.retrieveFavoriteEmployees();
    await this.retrieveOrgChartForUsersDomain();
    await this.initializeEmployee(this.route.snapshot.paramMap);

    this.subscriptions.push(this.route.paramMap.subscribe(async queryParams => {
      await this.initializeEmployee(queryParams);
    }));

    if (this.route.routeConfig.path.includes("workforce")) {
      this.selectedPortal = this.workforcePortal;
    } else if (this.route.routeConfig.path.endsWith("favorites")) {
      if (this.favorites.length) {
        this.selectedPortal = this.favoritesPortal;
      } else {
        this.selectedPortal = this.workforcePortal;
      }
    }

    if (!this.selectedPortal) {
      if (this.favorites?.length) {
        this.selectedPortal = this.favoritesPortal;
      } else {
        this.selectedPortal = this.workforcePortal;
      }
    }

  }

  set selectedPortal(portal: TemplatePortal<any>) {
    this._selectedPortal = portal;
    let statePath: string;
    switch (portal.context as HRWorkforceContext) {
      case HRWorkforceContext.FAVORITES : {
        this.navToEmployeeID = null;
        statePath = `hr/favorites`;
        break;
      }
      case HRWorkforceContext.WORKFORCE : {
        statePath = `hr/workforce`;
        break;
      }
    }
    if (!this.location.path().includes(statePath)) {
      this.location.replaceState(statePath);
    }
  }

  get selectedPortal(): TemplatePortal<any> {
    return this._selectedPortal;
  }

  employeeChanged(event: string) {
    this.location.go(`/hr/workforce/${event}`);
  }

  favoriteEmployeeChanged(event: string) {
    // this.location.go(`/hr/workforce/${event}`);
  }

  hrEditEmployee(employee: Employee) {
    this.router.navigate([`/hr/workforce/${employee.id}/edit`]);
  }

  private async initializeEmployee(queryParams: ParamMap) {
    const routeEmployeeID = queryParams.get(HrDashboardComponent.EMPLOYEE_ID_PARAMETER);
    if (routeEmployeeID) {
      const routeEmployee = await this.employeeService.getEmployeeByIDMemoize(routeEmployeeID);
      if (routeEmployee) {
        this.navToEmployeeID = routeEmployee.id;
      }
    }
  }

  private async initializeCurrentUser(): Promise<void> {
    try {
      this.currentUser = await this.employeeService.getCurrentEmployee()
    } catch ( error ) {
      console.error("HrWorkforceComponent: Unable to get user credentials", error);
      this.currentUser = undefined;
      this.router.navigate(["/dashboard"]);
    }
  }

  private async retrieveOrgChartForUsersDomain(): Promise<void> {
    const topLevelEmployees = await this.employeeService.getSubordinatesByEmployeeIDForOrganization({memoize:true});
    //Get Employees where Manager is disabled
    const allOrgUsers = await this.employeeService.getEmployeesForOrganizationByOrganization({includeDisabled:true, onlyDisable:true, memoize:true});
    for (const disabledUser of allOrgUsers) {
      const subordinates = await this.employeeService.getSubordinatesByEmployeeIDForOrganization({managerID: disabledUser.id, memoize:true});
      if (subordinates?.length > 0) {
        topLevelEmployees.push(...subordinates);
      }
    }

    this.topEmployees.push(...topLevelEmployees);
  }

  private async retrieveFavorites() {
    this.favorites = [];
    this.hrFavorites = await this.employeeService.getHRFavorites();
    if (this.hrFavorites?.favorites) {
      this.favorites = this.hrFavorites.favorites;
    }
  }

  async favoriteToggle(employee: Employee) {
    if (this.favorites.includes(employee.id)) {
      this.favorites = this.favorites.filter(id => id !== employee.id);
    } else {
      this.favorites.push(employee.id);
    }
    if (this.hrFavorites) {
      this.hrFavorites.favorites = this.favorites;
      this.hrFavorites = await this.employeeService.updateHRFavorites(this.hrFavorites);
    } else {
      this.hrFavorites = await this.employeeService.createHRFavorites(this.favorites);
    }
  }

  private async retrieveFavoriteEmployees() {
    const top = [];
    for (const id of this.favorites) {
      const employee = await this.employeeService.getEmployeeByIDMemoize(id);
      if (!employee.disabled) {
        top.push(employee);
      }
    }
    this.favoriteEmployees = top;
  }

  searchEmployee(employee: Employee) {
    this.navToEmployeeID = employee.id;
  }

}
