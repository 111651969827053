import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { ProjectMilestoneAssignment } from "@inthraction/data-models";
import { ProjectService } from "@inthraction/services";

@Component({
  selector: "inthraction-add-comment-dialog",
  templateUrl: "./add-comment-dialog.component.html",
  styleUrls: ["./add-comment-dialog.component.scss"]
})
export class AddCommentDialogComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<AddCommentDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public milestoneAssignment: ProjectMilestoneAssignment,
    private projectService: ProjectService
  ) {
  }

  ngOnInit(): void {
  }

  async saveComment(id: string, organizationID: string, value: string, $event: any) {
    const comment = await this.projectService.createProjectMilestoneComment(id, organizationID, value);
    this.dialogRef.close(comment);
  }
}
