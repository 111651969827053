import { Component, HostListener, Inject, OnInit } from "@angular/core";
import { Objective, ObjectiveDomain, ObjectiveDomainImpl } from "@inthraction/data-models";
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { ObjectiveService } from "@inthraction/services";
import { ToastrService } from "ngx-toastr";
import { MatButton } from "@angular/material/button";
import { SiteCodes } from "@inthraction/codes";

@Component({
  selector: 'app-edit-objective-domain-dialog',
  templateUrl: './edit-objective-domain-dialog.component.html',
  styleUrls: ['./edit-objective-domain-dialog.component.scss']
})
export class EditObjectiveDomainDialogComponent {

  domain: ObjectiveDomain;
  addMode = false;
  readonly siteCodes = Object.keys(SiteCodes);

  form: FormGroup;
  sites: FormGroup;
  private domainTitleFC = new FormControl("", [Validators.required]);
  private domainKeyFC = new FormControl("", [Validators.required]);
  private excludeFromTPSFC = new FormControl(false);

  constructor(
    public dialogRef: MatDialogRef<EditObjectiveDomainDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private objectiveService: ObjectiveService,
    private toastr: ToastrService,
    private formBuilder: FormBuilder
  ) {
    dialogRef.disableClose = true;

    const siteControls = {};
    for (let site of this.siteCodes) {
      siteControls[site] = this.formBuilder.control(false);
    }

    this.sites = new FormGroup(siteControls, Validators.required);

    this.form = new FormGroup({
      domainTitle: this.domainTitleFC,
      excludeFromTPS: this.excludeFromTPSFC,
      sites: this.sites
    });
    if (data.addMode) {
      this.addMode = true;
      this.domain = new ObjectiveDomainImpl();
      this.form.addControl("domainKey", this.domainKeyFC);
    } else {
      this.domain = JSON.parse((JSON.stringify(data.domain)));
      this.domainTitleFC.setValue(this.domain.display);
      this.excludeFromTPSFC.setValue(this.domain.excludeFromTPS);
      for (let site of this.siteCodes) {
        this.sites.controls[site].setValue(this.domain.sites.includes(site));
      }
    }
  }

  @HostListener('window:keyup.esc') onKeyUp() {
    this.onCancelClick();
  }

  onCancelClick(): void {
    this.dialogRef.close(null);
  }

  public hasError = (controlName: string, errorName: string) =>
    this.form.controls[controlName].hasError(errorName);

  async onSaveClick(formValue: any, submitBtn: MatButton | HTMLButtonElement): Promise<void> {
    submitBtn.disabled = true;
    if(this.addMode) {
      this.domain.key = formValue.domainKey;
    }
    this.domain.display = formValue.domainTitle;
    this.domain.excludeFromTPS = formValue.excludeFromTPS;

    const domainSites = [];
    const sites = formValue.sites
    console.debug("sites", sites);
    for (let site of this.siteCodes) {
      if (sites[site]) {
        domainSites.push(site);
      }
    }

    if (domainSites.length) {
      this.domain.sites = domainSites;
    } else {
      submitBtn.disabled = !this.form.valid;
      this.toastr.error("A site is required.");
      return;
    }


    try {
      let domainResult: ObjectiveDomain;
      if (this.addMode) {
        domainResult = await this.objectiveService.createObjectiveDomain(this.domain);
        this.toastr.success("Objective Domain Created");
      } else {
        domainResult = await this.objectiveService.updateObjectiveDomain(this.domain);
        this.toastr.success("Objective Domain Updated");
      }
      this.dialogRef.close({ domain: domainResult });
    } catch (err) {
      submitBtn.disabled = !this.form.valid;
      console.error(err);
      this.toastr.error("Unable to create or update Objective Domain");
      throw err;
    }

  }

}
