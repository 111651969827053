import { Component, Input, OnInit } from "@angular/core";
import { OrganizationShoutOutType } from "@inthraction/data-models";

@Component({
  selector: "inthraction-employee-insphration",
  templateUrl: "./employee-insphration.component.html",
  styleUrls: ["./employee-insphration.component.scss"]
})
export class EmployeeInsphrationComponent implements OnInit {

  @Input()
  inspHRation: OrganizationShoutOutType;

  @Input()
  count = 0;

  constructor() {
  }

  ngOnInit(): void {
  }

}
