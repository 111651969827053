import { Component, Inject, OnInit } from "@angular/core";
import { Employee, Note, StandupSurvey, StandupSurveyConfiguration } from "@inthraction/data-models";
import { EmployeeService, NoteService, StandupService } from "@inthraction/services";
import { QuestionsMap } from "@inthraction/data-mappers";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { NoteTypes } from "@inthraction/codes";

@Component({
  selector: "inthraction-employee-standup-history",
  templateUrl: "./employee-standup-history.component.html",
  styleUrls: ["./employee-standup-history.component.scss"]
})
export class EmployeeStandupHistoryComponent implements OnInit {

  private employee: Employee;
  private configurationMap = new Map<string, StandupSurveyConfiguration>();

  surveysByDateMap = new Map<string, StandupSurvey[]>();
  notesMap = new Map<string, Note>();
  initialized = false;
  selectedSurveyDate: string;
  index = 0;

  constructor(
    private employeeService: EmployeeService,
    private standupService: StandupService,
    private noteService: NoteService,
    public dialogRef: MatDialogRef<EmployeeStandupHistoryComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
  }

  async ngOnInit() {
    this.employee = this.data.employee;
    const surveys: StandupSurvey[] = await this.standupService.getAllStandupSurveysForEmployee(this.employee.id);
    for (const survey of surveys) {

      if (!this.configurationMap.has(survey.standupSurveyConfigurationID)) {
        const configuration = await this.standupService.getStandupConfigurationByID(survey.standupSurveyConfigurationID);
        this.configurationMap.set(configuration.id, configuration);
      }

      if (!this.surveysByDateMap.has(survey.surveyDate)) {
        this.surveysByDateMap.set(survey.surveyDate, []);
      }
      this.surveysByDateMap.get(survey.surveyDate).push(survey);
    }
    this.selectedSurveyDate = this.surveyDates[0];
    await this.initializeNotesForSelectedSurveyDate(this.selectedSurveyDate);
    this.initialized = true;
  }

  get configuration(): StandupSurveyConfiguration {
    return this.configurationMap.get(
      this.surveysByDateMap.get(this.selectedSurveyDate)[0]
        .standupSurveyConfigurationID
    );
  }

  getStandupQuestion(configuration: StandupSurveyConfiguration, questionMap: QuestionsMap): string {
    const question = configuration.questions.find(q => q.id == questionMap.id);
    return question.questionTextArray[questionMap.version];
  }

  async initializeNotesForSelectedSurveyDate(surveyDate: string) {
    for (const survey of this.surveysByDateMap.get(surveyDate)) {
      if (!this.notesMap.has(survey.id)) {
        const notes = await this.noteService.getOneOnOneNotesByObjectIDMemoize(survey.id, NoteTypes.STANDUP);
        if (notes?.length) {
          this.notesMap.set(survey.id, notes[0]);
        } else {
          this.notesMap.set(survey.id, null);
        }
      }
    }
  }

  get surveyDates(): string[] {
    const keys = [];
    keys.push(...this.surveysByDateMap.keys());
    return keys;
  }

  async moveSurvey(direction: string, index: number) {

    if ((direction == "up" && index == 0) || (direction == "down" && index == this.surveyDates.length - 1)) {
      return;
    }
    if (direction == "up") {
      index--;
    } else {
      index++;
    }
    this.selectedSurveyDate = this.surveyDates[index];
    await this.initializeNotesForSelectedSurveyDate(this.selectedSurveyDate);
    this.index = index;
  }
}
