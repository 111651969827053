<h1 mat-dialog-title>Add User</h1>
<mat-horizontal-stepper [linear]="true" #stepper>
  <mat-step [stepControl]="searchForEmployeeFG">
    <form [formGroup]="searchForEmployeeFG">
      <ng-template matStepLabel>Search</ng-template>
      <div mat-dialog-content>
        <mat-form-field class="full-width">
          <mat-label>Search for User by Email</mat-label>
          <mat-autocomplete #emailSearch="matAutocomplete">
            <mat-option *ngFor="let email of filteredEmails | async" [value]="email">{{email}}</mat-option>
          </mat-autocomplete>
          <input matInput lowercase [matAutocomplete]="emailSearch" placeholder="jane.doe@example.com" #emailSearchFC formControlName="email"
                 (input)="emailSearchFC.value = emailSearchFC.value.toLowerCase()"
                 (change)="searchForEmployeeFG.get('isValidEmail').setValue(false)">
          <mat-error *ngIf="hasError(searchForEmployeeFG, 'required', 'email')">Email is required</mat-error>
          <mat-error *ngIf="hasError(searchForEmployeeFG, 'email', 'email')">Must be a valid email address</mat-error>
          <mat-error *ngIf="hasError(searchForEmployeeFG, 'manager', 'email')">Please enter an email address that is different than the selected Manager.</mat-error>
          <mat-error *ngIf="hasError(searchForEmployeeFG, 'domain', 'email')">Email address must match a domain that belongs to your organization.</mat-error>
          <mat-error *ngIf="hasError(searchForEmployeeFG, 'disabled', 'email')">An account matching this email already exists and is currently disabled.</mat-error>
          <mat-error *ngIf="hasError(searchForEmployeeFG, 'inUse', 'email')">An account matching this email already exists in a different organization.</mat-error>
        </mat-form-field>
      </div>
      <div mat-dialog-actions>
        <div style="flex-grow: 2"></div>
        <button (click)="onEmployeeSearchClick()" mat-raised-button [disabled]="hasError(searchForEmployeeFG, 'null', 'email')">Search</button>
      </div>
    </form>
  </mat-step>
  <mat-step [stepControl]="addEmployeeFG">
    <form [formGroup]="addEmployeeFG">
      <ng-template matStepLabel>Details</ng-template>
      <div mat-dialog-content>
        <mat-form-field class="half-width left-padding">
          <mat-label>First Name</mat-label>
          <input matInput formControlName="firstName">
          <mat-error *ngIf="hasError(addEmployeeFG, 'required', 'firstName')">First Name is required</mat-error>
        </mat-form-field>
        <mat-form-field class="half-width right-padding">
          <mat-label>Last Name</mat-label>
          <input matInput formControlName="lastName">
          <mat-error *ngIf="hasError(addEmployeeFG, 'required', 'lastName')">Last Name is required</mat-error>
        </mat-form-field>
        <mat-form-field class="full-width">
          <mat-label>Title</mat-label>
          <input matInput formControlName="title">
          <mat-error *ngIf="hasError(addEmployeeFG, 'required', 'title')">Title is required</mat-error>
        </mat-form-field>
        <mat-form-field class="half-width left-padding">
          <mat-label>Date Hired</mat-label>
          <input matInput [matDatepicker]="hireDate" formControlName="dateHired">
          <mat-datepicker-toggle [for]="hireDate" matSuffix></mat-datepicker-toggle>
          <mat-datepicker #hireDate></mat-datepicker>
        </mat-form-field>
        <mat-form-field class="half-width right-padding">
          <mat-label>Department</mat-label>
          <mat-select formControlName="department">
            <mat-option *ngFor="let departmentType of departmentTypes" [value]="departmentType">{{departmentTypeLabels[departmentType]}}</mat-option>
          </mat-select>
          <mat-error *ngIf="hasError(addEmployeeFG, 'required', 'department')">Department is required</mat-error>
        </mat-form-field>
      </div>
      <div mat-dialog-actions>
        <button mat-raised-button matStepperNext [disabled]="addEmployeeFG.invalid">Next</button>
      </div>
    </form>
  </mat-step>
  <mat-step *ngIf="!manager" [stepControl]="searchForManagerFG" [optional]="this.searchedManager == null">
    <form [formGroup]="searchForManagerFG">
      <ng-template matStepLabel>Manager</ng-template>
      <div mat-dialog-content *ngIf="!searchedManager">
        <mat-form-field class="full-width">
          <mat-label>Search for Manager by Email</mat-label>
          <mat-autocomplete #emailSearch="matAutocomplete">
            <mat-option *ngFor="let email of filteredManagerEmails | async" [value]="email">{{email}}</mat-option>
          </mat-autocomplete>
          <input matInput lowercase [matAutocomplete]="emailSearch" placeholder="jane.doe@example.com" #emailSearchFC formControlName="email"
                 (input)="emailSearchFC.value = emailSearchFC.value.toLowerCase()"
                 (change)="searchForManagerFG.get('isValidEmail').setValue(false)">
          <mat-error *ngIf="hasError(searchForManagerFG, 'required', 'email')">Email is required</mat-error>
          <mat-error *ngIf="hasError(searchForManagerFG, 'email', 'email')">Must be a valid email address</mat-error>
          <mat-error *ngIf="hasError(searchForManagerFG, 'manager', 'email')">Please enter an email address that is different than the selected Manager.</mat-error>
          <mat-error *ngIf="hasError(searchForManagerFG, 'domain', 'email')">Email address must match a domain that belongs to your organization.</mat-error>
          <mat-error *ngIf="hasError(searchForManagerFG, 'disabled', 'email')">An account matching this email already exists and is currently disabled.</mat-error>
        </mat-form-field>
      </div>
      <div mat-dialog-content *ngIf="searchedManager">
        <mat-form-field class="full-width">
          <mat-label>Manager</mat-label>
          <input readonly matInput value="{{searchedManager.firstName}} {{searchedManager.lastName}}">
        </mat-form-field>
        <mat-form-field class="full-width">
          <mat-label>Manager Email</mat-label>
          <input readonly matInput value="{{searchedManager.email}}">
        </mat-form-field>
      </div>
      <div mat-dialog-actions *ngIf="!searchedManager">
        <button mat-raised-button matStepperNext>Skip</button>
        <div style="flex-grow: 2"></div>
        <button class="btn-right" (click)="onManagerSearchClick()" mat-raised-button [disabled]="hasError(searchForEmployeeFG, 'null', 'email')">Search</button>
      </div>
      <div mat-dialog-actions *ngIf="searchedManager">
        <button mat-raised-button matStepperNext>Next</button>
        <div style="flex-grow: 2"></div>
        <button (click)="onClearManagerSearchClick()" mat-button class="btn-right">Clear Search</button>
      </div>
    </form>
  </mat-step>
  <mat-step [stepControl]="employeeOptionsFG">

    <div mat-dialog-content>
      <mat-form-field class="half-width left-padding">
        <mat-label>Name</mat-label>
        <input readonly matInput value="{{addEmployeeFG.get('firstName').value}} {{addEmployeeFG.get('lastName').value}}">
      </mat-form-field>

      <mat-form-field class="half-width right-padding" *ngIf="manager">
        <mat-label>{{addEmployeeFG.get('isExistingEmployee').value ? 'New ' : ''}}Manager</mat-label>
        <input readonly matInput value="{{manager.firstName}} {{manager.lastName}}">
      </mat-form-field>

      <mat-form-field class="full-width">
        <mat-label>Title</mat-label>
        <input readonly matInput value="{{addEmployeeFG.get('title').value}}">
      </mat-form-field>

      <mat-form-field class="full-width">
        <mat-label>Email</mat-label>
        <input readonly matInput value="{{addEmployeeFG.get('email').value}}">
      </mat-form-field>

      <form [formGroup]="employeeOptionsFG">
        <ng-template matStepLabel>Confirmation</ng-template>
        <mat-form-field floatLabel="always" appearance="outline" class="slide-toggle-form-field full-width">
          <mat-label>Administrator Access</mat-label>
          <mat-slide-toggle formControlName="admin"></mat-slide-toggle>
          <input matInput hidden height="0" style="height: 0" />
          <mat-hint>Designates an Administrator user, allowing abilities such as add / edit users and OrgCharts</mat-hint>
        </mat-form-field>
        <mat-form-field floatLabel="always" appearance="outline" class="slide-toggle-form-field full-width">
          <mat-label>Human Resources Access</mat-label>
          <mat-slide-toggle formControlName="hr"></mat-slide-toggle>
          <input matInput hidden height="0" style="height: 0" />
          <mat-hint>Designates HR user, allowing access to all User's Interaction Data</mat-hint>
        </mat-form-field>
        <mat-form-field floatLabel="always" appearance="outline" class="slide-toggle-form-field full-width">
          <mat-label>Project Manager Access</mat-label>
          <mat-slide-toggle formControlName="pm"></mat-slide-toggle>
          <input matInput hidden height="0" style="height: 0" />
          <mat-hint>Designates Project Manager user, allowing Project Creation abilities</mat-hint>
        </mat-form-field>
        <mat-form-field *ngIf="!addEmployeeFG.get('isExistingEmployee').value" floatLabel="always" appearance="outline" class="slide-toggle-form-field full-width">
          <mat-label>Send Account Invitation Email</mat-label>
          <mat-slide-toggle formControlName="sendInvite"></mat-slide-toggle>
          <input matInput hidden height="0" style="height: 0" />
        </mat-form-field>
      </form>
    </div>
    <div mat-dialog-actions>
      <button (click)="onCancelClick()" mat-button>Cancel</button>
      <button #saveBtn (click)="onSaveClick(saveBtn); saveBtn.disabled=true" cdkFocusInitial mat-raised-button color="primary" [disabled]="addEmployeeFG.invalid">Save</button>
    </div>
  </mat-step>
</mat-horizontal-stepper>
