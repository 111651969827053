import { Injectable } from "@angular/core";
import { BaseService } from "./base.service";
import {
  APIService,
  CreateOrganizationShoutOutTypeInput,
  CreateShoutOutTypeInput,
  InspHRationCounterType,
  ModelInspHRationsOrganizationResponseCounterFilterInput,
  UpdateOrganizationShoutOutTypeInput,
  UpdateShoutOutTypeInput
} from "./API.service";
import { EmployeeInspHRationCount, OrganizationShoutOutType, ShoutOutType } from "@inthraction/data-models";
import { ShoutOutTypeStatus } from "@inthraction/codes";
import { Memoize, MEMOIZE_FN_MAP } from "@inthraction/utils";
import * as moment from "moment";
import { AuthService } from "./auth.service";

@Injectable({
  providedIn: "root"
})
export class ShoutOutService extends BaseService {

  constructor(
    protected api: APIService,
    protected authService: AuthService
  ) {
    super(api, authService);
  }

  static getInspHRationGroup(type: InspHRationCounterType) {
    let group: string;
    switch (type) {
      case InspHRationCounterType.WEEK: {
        group = moment().format("YYYY-WW");
        break;
      }
      case InspHRationCounterType.MONTH: {
        group = moment().format("YYYY-MM");
        break;
      }
      case InspHRationCounterType.QTR: {
        group = moment().format("YYYY-Q");
        break;
      }
      case InspHRationCounterType.YEAR: {
        group = moment().format("YYYY");
        break;
      }
    }
    return group;
  }

  @Memoize()
  async getShoutOutTypes(): Promise<ShoutOutType[]> {
    return this.getAll<ShoutOutType>(this.api.ListShoutOutTypes, { status: { ne: ShoutOutTypeStatus.DELETED } });
  }

  clearMemoizedShoutOutTypes() {
    if (MEMOIZE_FN_MAP.has("getShoutOutTypes")) {
      MEMOIZE_FN_MAP.get("getShoutOutTypes").delete();
    }
  }

  clearMemoizedOrganizationShoutOutTypes(organizationID?: string) {
    if (MEMOIZE_FN_MAP.has("getOrganizationShoutOutTypesMemoize")) {
      MEMOIZE_FN_MAP.get("getOrganizationShoutOutTypesMemoize").delete(organizationID);
    }
    if (MEMOIZE_FN_MAP.has("getActiveOrganizationShoutOuts")) {
      MEMOIZE_FN_MAP.get("getActiveOrganizationShoutOuts").delete(null);
      MEMOIZE_FN_MAP.get("getActiveOrganizationShoutOuts").delete(organizationID);
    }
    if (MEMOIZE_FN_MAP.has("getInspHRationsResponseCountersForEmployee")) {
      MEMOIZE_FN_MAP.get("getInspHRationsResponseCountersForEmployee").clear();
    }
  }

  async createShoutOutType(shoutOutType: ShoutOutType): Promise<ShoutOutType> {
    let input: CreateShoutOutTypeInput = {
      title: shoutOutType.title,
      attributes: shoutOutType.attributes,
      formalDescription: shoutOutType.formalDescription,
      informalDescription: shoutOutType.informalDescription,
      icon: shoutOutType.icon
    };
    const result = this.api.CreateShoutOutType(input);
    this.clearMemoizedShoutOutTypes();
    this.clearMemoizedOrganizationShoutOutTypes();
    return result;
  }

  async deleteShoutOutType(id: string): Promise<ShoutOutType> {
    const shoutout = await this.api.UpdateShoutOutType({ id: id, status: ShoutOutTypeStatus.DELETED });
    this.clearMemoizedShoutOutTypes();
    this.clearMemoizedOrganizationShoutOutTypes();
    return shoutout;
  }

  async updateShoutOutType(updateShoutOutType: UpdateShoutOutTypeInput) {
    const shoutout = await this.api.UpdateShoutOutType(updateShoutOutType);
    this.clearMemoizedShoutOutTypes();
    this.clearMemoizedOrganizationShoutOutTypes();
    return shoutout;
  }

  @Memoize({ maxAge: 600000, preFetch: true })
  async getOrganizationShoutOutTypesMemoize(organizationID: string): Promise<OrganizationShoutOutType[]> {
    const results = await this.getAll<OrganizationShoutOutType>(this.api.ListOrganizationShoutOutTypes, { organizationID: { eq: organizationID } });
    return results.filter(s => s.shoutOutType.status != ShoutOutTypeStatus.DELETED);
  }

  async getOrganizationShoutOutTypes(organizationID?: string): Promise<OrganizationShoutOutType[]> {
    if(!organizationID) {
      const currentUser = await this.getCurrentUser();
      organizationID = currentUser.orgId;
    }
    return this.getOrganizationShoutOutTypesMemoize(organizationID)
  }

  async getMemoizedOrganizationShoutOutType(organizationID: string, id: string): Promise<OrganizationShoutOutType> {
   const results = await this.getOrganizationShoutOutTypesMemoize(organizationID);
   return results.find(s => s.id == id);
  }

  async createOrganizationShoutOutType(organizationShoutOutType: CreateOrganizationShoutOutTypeInput): Promise<OrganizationShoutOutType> {
    const currentUser = await this.getCurrentUser();
    if (!organizationShoutOutType.organizationID) {
      organizationShoutOutType.organizationID = currentUser.orgId;
    }
    const result = await this.api.CreateOrganizationShoutOutType(organizationShoutOutType);
    this.clearMemoizedOrganizationShoutOutTypes(result.organizationID);
    return result;
  }

  async updateOrganizationShoutOutType(input: UpdateOrganizationShoutOutTypeInput) {
    const osot = await this.api.GetOrganizationShoutOutType(input.id)
    if (osot.organizationID === input.organizationID || !input.organizationID) {
      input.organizationShoutOutTypeShoutOuTypeId = undefined; // Why?  Is this because when it gets edited it shouldn't link to the system one anymore?
      const result = await this.api.UpdateOrganizationShoutOutType(input);
      this.clearMemoizedOrganizationShoutOutTypes(result.organizationID);
      return result;
    } else {
      throw new Error("Invalid Organization ID for UpdateOrganizationShoutOutType");
    }
  }

  async getActiveOrganizationShoutOuts(): Promise<OrganizationShoutOutType[]> {
    const currentUser = await this.getCurrentUser();
    return this._getActiveOrganizationShoutOuts(currentUser.orgId);
  }

  @Memoize({ maxAge: 600000, preFetch: true })
  private async _getActiveOrganizationShoutOuts(organizationID: string): Promise<OrganizationShoutOutType[]> {
    const results = await this.getOrganizationShoutOutTypes(organizationID);
    return results.filter(s => s.shoutOutType.status != ShoutOutTypeStatus.DELETED && s.enabled);
  }

  async getInspHRationCountsForOrganization(): Promise<EmployeeInspHRationCount[]> {
    const currentUser = await this.getCurrentUser();
    return this.getInspHRationsResponseCountersForOrganization(currentUser.orgId);
  }

  @Memoize({ maxAge: 600000, preFetch: true })
  private async getInspHRationsResponseCountersForOrganization(organizationID: string): Promise<EmployeeInspHRationCount[]> {
    const filter: ModelInspHRationsOrganizationResponseCounterFilterInput = { or: [] };

    for (const typeKey of Object.keys(InspHRationCounterType)) {
      filter.or.push({group: {eq: ShoutOutService.getInspHRationGroup(InspHRationCounterType[typeKey]) }})
    }

    let results: EmployeeInspHRationCount[] = [];
    let response;
    do {
      response = await this.api.ListInspHRationsOrganizationResponseCounters(organizationID, null, filter, 1000, response?.nextToken);
      results.push(...response.items);
    } while (response.nextToken);
    results = results.filter((elm, pos, array) => {
      return array.indexOf(elm) == pos;
    });
    const orgShoutOutTypes = await this.getOrganizationShoutOutTypes(organizationID);
    const filterIDs: string[] = [];
    for (const item of results) {
      if (!filterIDs.includes(item.organizationShoutOutTypeID)) {
        const orgShoutOutType = orgShoutOutTypes.find(st => st.id == item.organizationShoutOutTypeID);
        if (!orgShoutOutType || orgShoutOutType.shoutOutType.status == ShoutOutTypeStatus.DELETED || !orgShoutOutType.enabled) {
          filterIDs.push(item.organizationShoutOutTypeID);
        }
      }
    }
    return results.filter(c => !filterIDs.includes(c.organizationShoutOutTypeID));
  }

  async getInspHRationCountsForEmployee(organizationID: string, employeeID: string, type: InspHRationCounterType = InspHRationCounterType.WEEK): Promise<EmployeeInspHRationCount[]> {
    let group = ShoutOutService.getInspHRationGroup(type);
    return this.getInspHRationsResponseCountersForEmployee(organizationID, employeeID, type.toString(), group);
  }

  @Memoize({ maxAge: 600000, preFetch: true })
  private async getInspHRationsResponseCountersForEmployee(organizationID: string, employeeID: string, type: string, group: string): Promise<EmployeeInspHRationCount[]> {
    const filter: ModelInspHRationsOrganizationResponseCounterFilterInput = {
      and: [
        {
          employeeID: {eq: employeeID},
          group: { eq: group },
          type: { eq: InspHRationCounterType[type] }
        }
      ]
    };
    let results: EmployeeInspHRationCount[] = [];
    let response;
    do {
      response = await this.api.ListInspHRationsOrganizationResponseCounters(organizationID, null, filter, 1000, response?.nextToken);
      results.push(...response.items);
    } while (response.nextToken);
    results = results.filter((elm, pos, array) => {
      return array.indexOf(elm) == pos;
    });

    const orgShoutOutTypes = await this.getOrganizationShoutOutTypes(organizationID);

    const filterIDs: string[] = [];
    for (const item of results) {
      if (!filterIDs.includes(item.organizationShoutOutTypeID)) {
        const orgShoutOutType = orgShoutOutTypes.find(st => st.id == item.organizationShoutOutTypeID);
        if (!orgShoutOutType || orgShoutOutType.shoutOutType.status == ShoutOutTypeStatus.DELETED || !orgShoutOutType.enabled) {
          filterIDs.push(item.organizationShoutOutTypeID);
        }
      }
    }
    return results.filter(c => !filterIDs.includes(c.organizationShoutOutTypeID));
  }

}
