/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.
import { Injectable } from "@angular/core";
import API, { graphqlOperation, GraphQLResult } from "@aws-amplify/api-graphql";
import { Observable } from "zen-observable-ts";

export interface SubscriptionResponse<T> {
  value: GraphQLResult<T>;
}

export type __SubscriptionContainer = {
  onCreateOrganization: OnCreateOrganizationSubscription;
  onUpdateOrganization: OnUpdateOrganizationSubscription;
  onDeleteOrganization: OnDeleteOrganizationSubscription;
  onCreateStripe: OnCreateStripeSubscription;
  onUpdateStripe: OnUpdateStripeSubscription;
  onDeleteStripe: OnDeleteStripeSubscription;
  onCreateProcessLog: OnCreateProcessLogSubscription;
  onUpdateProcessLog: OnUpdateProcessLogSubscription;
  onDeleteProcessLog: OnDeleteProcessLogSubscription;
  onCreateOrganizationConfiguration: OnCreateOrganizationConfigurationSubscription;
  onUpdateOrganizationConfiguration: OnUpdateOrganizationConfigurationSubscription;
  onDeleteOrganizationConfiguration: OnDeleteOrganizationConfigurationSubscription;
  onCreateEmployee: OnCreateEmployeeSubscription;
  onUpdateEmployee: OnUpdateEmployeeSubscription;
  onDeleteEmployee: OnDeleteEmployeeSubscription;
  onCreateEmployeeData: OnCreateEmployeeDataSubscription;
  onUpdateEmployeeData: OnUpdateEmployeeDataSubscription;
  onDeleteEmployeeData: OnDeleteEmployeeDataSubscription;
  onCreateReadinessHistory: OnCreateReadinessHistorySubscription;
  onUpdateReadinessHistory: OnUpdateReadinessHistorySubscription;
  onDeleteReadinessHistory: OnDeleteReadinessHistorySubscription;
  onCreateConsultantAssociation: OnCreateConsultantAssociationSubscription;
  onUpdateConsultantAssociation: OnUpdateConsultantAssociationSubscription;
  onDeleteConsultantAssociation: OnDeleteConsultantAssociationSubscription;
  onCreateConsultantFavorites: OnCreateConsultantFavoritesSubscription;
  onUpdateConsultantFavorites: OnUpdateConsultantFavoritesSubscription;
  onDeleteConsultantFavorites: OnDeleteConsultantFavoritesSubscription;
  onCreateUserAction: OnCreateUserActionSubscription;
  onUpdateUserAction: OnUpdateUserActionSubscription;
  onDeleteUserAction: OnDeleteUserActionSubscription;
  onCreateEmployeeCalendarCode: OnCreateEmployeeCalendarCodeSubscription;
  onUpdateEmployeeCalendarCode: OnUpdateEmployeeCalendarCodeSubscription;
  onDeleteEmployeeCalendarCode: OnDeleteEmployeeCalendarCodeSubscription;
  onCreateEmployeeScore: OnCreateEmployeeScoreSubscription;
  onUpdateEmployeeScore: OnUpdateEmployeeScoreSubscription;
  onDeleteEmployeeScore: OnDeleteEmployeeScoreSubscription;
  onCreateInspHRationsResponseCounter: OnCreateInspHRationsResponseCounterSubscription;
  onUpdateInspHRationsResponseCounter: OnUpdateInspHRationsResponseCounterSubscription;
  onDeleteInspHRationsResponseCounter: OnDeleteInspHRationsResponseCounterSubscription;
  onCreateInspHRationsOrganizationResponseCounter: OnCreateInspHRationsOrganizationResponseCounterSubscription;
  onUpdateInspHRationsOrganizationResponseCounter: OnUpdateInspHRationsOrganizationResponseCounterSubscription;
  onDeleteInspHRationsOrganizationResponseCounter: OnDeleteInspHRationsOrganizationResponseCounterSubscription;
  onCreateIntHRactionCounter: OnCreateIntHRactionCounterSubscription;
  onUpdateIntHRactionCounter: OnUpdateIntHRactionCounterSubscription;
  onDeleteIntHRactionCounter: OnDeleteIntHRactionCounterSubscription;
  onCreatePriority: OnCreatePrioritySubscription;
  onUpdatePriority: OnUpdatePrioritySubscription;
  onDeletePriority: OnDeletePrioritySubscription;
  onCreateFocus: OnCreateFocusSubscription;
  onUpdateFocus: OnUpdateFocusSubscription;
  onDeleteFocus: OnDeleteFocusSubscription;
  onCreateObjectiveDomain: OnCreateObjectiveDomainSubscription;
  onUpdateObjectiveDomain: OnUpdateObjectiveDomainSubscription;
  onDeleteObjectiveDomain: OnDeleteObjectiveDomainSubscription;
  onCreateObjective: OnCreateObjectiveSubscription;
  onUpdateObjective: OnUpdateObjectiveSubscription;
  onDeleteObjective: OnDeleteObjectiveSubscription;
  onCreateOrganizationObjective: OnCreateOrganizationObjectiveSubscription;
  onUpdateOrganizationObjective: OnUpdateOrganizationObjectiveSubscription;
  onDeleteOrganizationObjective: OnDeleteOrganizationObjectiveSubscription;
  onCreateObjectiveAssignment: OnCreateObjectiveAssignmentSubscription;
  onUpdateObjectiveAssignment: OnUpdateObjectiveAssignmentSubscription;
  onDeleteObjectiveAssignment: OnDeleteObjectiveAssignmentSubscription;
  onCreateCalendarEvent: OnCreateCalendarEventSubscription;
  onUpdateCalendarEvent: OnUpdateCalendarEventSubscription;
  onDeleteCalendarEvent: OnDeleteCalendarEventSubscription;
  onCreateSurvey: OnCreateSurveySubscription;
  onUpdateSurvey: OnUpdateSurveySubscription;
  onDeleteSurvey: OnDeleteSurveySubscription;
  onCreateExternalRespondent: OnCreateExternalRespondentSubscription;
  onUpdateExternalRespondent: OnUpdateExternalRespondentSubscription;
  onDeleteExternalRespondent: OnDeleteExternalRespondentSubscription;
  onCreateDoNotDisturbRequest: OnCreateDoNotDisturbRequestSubscription;
  onUpdateDoNotDisturbRequest: OnUpdateDoNotDisturbRequestSubscription;
  onDeleteDoNotDisturbRequest: OnDeleteDoNotDisturbRequestSubscription;
  onCreateProject: OnCreateProjectSubscription;
  onUpdateProject: OnUpdateProjectSubscription;
  onDeleteProject: OnDeleteProjectSubscription;
  onCreateMilestoneComment: OnCreateMilestoneCommentSubscription;
  onUpdateMilestoneComment: OnUpdateMilestoneCommentSubscription;
  onDeleteMilestoneComment: OnDeleteMilestoneCommentSubscription;
  onCreateProjectMilestoneAssignment: OnCreateProjectMilestoneAssignmentSubscription;
  onUpdateProjectMilestoneAssignment: OnUpdateProjectMilestoneAssignmentSubscription;
  onDeleteProjectMilestoneAssignment: OnDeleteProjectMilestoneAssignmentSubscription;
  onCreateNoteComment: OnCreateNoteCommentSubscription;
  onUpdateNoteComment: OnUpdateNoteCommentSubscription;
  onDeleteNoteComment: OnDeleteNoteCommentSubscription;
  onCreateNote: OnCreateNoteSubscription;
  onUpdateNote: OnUpdateNoteSubscription;
  onDeleteNote: OnDeleteNoteSubscription;
  onCreateNoteHistory: OnCreateNoteHistorySubscription;
  onUpdateNoteHistory: OnUpdateNoteHistorySubscription;
  onDeleteNoteHistory: OnDeleteNoteHistorySubscription;
  onCreateShoutOutType: OnCreateShoutOutTypeSubscription;
  onUpdateShoutOutType: OnUpdateShoutOutTypeSubscription;
  onDeleteShoutOutType: OnDeleteShoutOutTypeSubscription;
  onCreateOrganizationShoutOutType: OnCreateOrganizationShoutOutTypeSubscription;
  onUpdateOrganizationShoutOutType: OnUpdateOrganizationShoutOutTypeSubscription;
  onDeleteOrganizationShoutOutType: OnDeleteOrganizationShoutOutTypeSubscription;
  onCreateStandupSurveyConfiguration: OnCreateStandupSurveyConfigurationSubscription;
  onUpdateStandupSurveyConfiguration: OnUpdateStandupSurveyConfigurationSubscription;
  onDeleteStandupSurveyConfiguration: OnDeleteStandupSurveyConfigurationSubscription;
  onCreateStandupSurvey: OnCreateStandupSurveySubscription;
  onUpdateStandupSurvey: OnUpdateStandupSurveySubscription;
  onDeleteStandupSurvey: OnDeleteStandupSurveySubscription;
  onCreateEmployeeImage: OnCreateEmployeeImageSubscription;
  onUpdateEmployeeImage: OnUpdateEmployeeImageSubscription;
  onDeleteEmployeeImage: OnDeleteEmployeeImageSubscription;
  onCreateExternalSurvey: OnCreateExternalSurveySubscription;
  onUpdateExternalSurvey: OnUpdateExternalSurveySubscription;
  onDeleteExternalSurvey: OnDeleteExternalSurveySubscription;
};

export type CreateOrganizationInput = {
  id?: string | null;
  orgName: string;
  domains: Array<string>;
  site: string;
  disabled?: boolean | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type ModelOrganizationConditionInput = {
  orgName?: ModelStringInput | null;
  domains?: ModelStringInput | null;
  site?: ModelStringInput | null;
  disabled?: ModelBooleanInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelOrganizationConditionInput | null> | null;
  or?: Array<ModelOrganizationConditionInput | null> | null;
  not?: ModelOrganizationConditionInput | null;
};

export type ModelStringInput = {
  ne?: string | null;
  eq?: string | null;
  le?: string | null;
  lt?: string | null;
  ge?: string | null;
  gt?: string | null;
  contains?: string | null;
  notContains?: string | null;
  between?: Array<string | null> | null;
  beginsWith?: string | null;
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
  size?: ModelSizeInput | null;
};

export enum ModelAttributeTypes {
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
  _null = "_null"
}

export type ModelSizeInput = {
  ne?: number | null;
  eq?: number | null;
  le?: number | null;
  lt?: number | null;
  ge?: number | null;
  gt?: number | null;
  between?: Array<number | null> | null;
};

export type ModelBooleanInput = {
  ne?: boolean | null;
  eq?: boolean | null;
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
};

export type Organization = {
  __typename: "Organization";
  id: string;
  orgName: string;
  domains: Array<string>;
  site: string;
  disabled?: boolean | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type UpdateOrganizationInput = {
  id: string;
  orgName?: string | null;
  domains?: Array<string> | null;
  site?: string | null;
  disabled?: boolean | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type DeleteOrganizationInput = {
  id: string;
};

export type CreateStripeInput = {
  organizationID: string;
  customerID?: string | null;
  subscriptionID?: string | null;
  subscriptionItemID?: string | null;
  planID?: string | null;
  billingName?: string | null;
  billingEmail?: string | null;
  cardToken?: string | null;
  subscriptionStatus?: string | null;
  hmac?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type ModelStripeConditionInput = {
  customerID?: ModelStringInput | null;
  subscriptionID?: ModelStringInput | null;
  subscriptionItemID?: ModelStringInput | null;
  planID?: ModelStringInput | null;
  billingName?: ModelStringInput | null;
  billingEmail?: ModelStringInput | null;
  cardToken?: ModelStringInput | null;
  subscriptionStatus?: ModelStringInput | null;
  hmac?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelStripeConditionInput | null> | null;
  or?: Array<ModelStripeConditionInput | null> | null;
  not?: ModelStripeConditionInput | null;
};

export type Stripe = {
  __typename: "Stripe";
  organizationID: string;
  customerID?: string | null;
  subscriptionID?: string | null;
  subscriptionItemID?: string | null;
  planID?: string | null;
  billingName?: string | null;
  billingEmail?: string | null;
  cardToken?: string | null;
  subscriptionStatus?: string | null;
  hmac?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type UpdateStripeInput = {
  organizationID: string;
  customerID?: string | null;
  subscriptionID?: string | null;
  subscriptionItemID?: string | null;
  planID?: string | null;
  billingName?: string | null;
  billingEmail?: string | null;
  cardToken?: string | null;
  subscriptionStatus?: string | null;
  hmac?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type DeleteStripeInput = {
  organizationID: string;
};

export type CreateProcessLogInput = {
  process: string;
  lastRunTime: string;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type ModelProcessLogConditionInput = {
  lastRunTime?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelProcessLogConditionInput | null> | null;
  or?: Array<ModelProcessLogConditionInput | null> | null;
  not?: ModelProcessLogConditionInput | null;
};

export type ProcessLog = {
  __typename: "ProcessLog";
  process: string;
  lastRunTime: string;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type UpdateProcessLogInput = {
  process: string;
  lastRunTime?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type DeleteProcessLogInput = {
  process: string;
};

export type CreateOrganizationConfigurationInput = {
  id?: string | null;
  organizationID: string;
  configCode: string;
  configStringValue?: string | null;
  configIntValue?: number | null;
  configBooleanValue?: boolean | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type ModelOrganizationConfigurationConditionInput = {
  configCode?: ModelStringInput | null;
  configStringValue?: ModelStringInput | null;
  configIntValue?: ModelIntInput | null;
  configBooleanValue?: ModelBooleanInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelOrganizationConfigurationConditionInput | null> | null;
  or?: Array<ModelOrganizationConfigurationConditionInput | null> | null;
  not?: ModelOrganizationConfigurationConditionInput | null;
};

export type ModelIntInput = {
  ne?: number | null;
  eq?: number | null;
  le?: number | null;
  lt?: number | null;
  ge?: number | null;
  gt?: number | null;
  between?: Array<number | null> | null;
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
};

export type OrganizationConfiguration = {
  __typename: "OrganizationConfiguration";
  id: string;
  organizationID: string;
  configCode: string;
  configStringValue?: string | null;
  configIntValue?: number | null;
  configBooleanValue?: boolean | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type UpdateOrganizationConfigurationInput = {
  id: string;
  organizationID?: string | null;
  configCode?: string | null;
  configStringValue?: string | null;
  configIntValue?: number | null;
  configBooleanValue?: boolean | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type DeleteOrganizationConfigurationInput = {
  id: string;
};

export type CreateEmployeeInput = {
  id?: string | null;
  authId?: string | null;
  email: string;
  orgId: string;
  groups?: Array<string | null> | null;
  consulting?: Array<string | null> | null;
  managerID?: string | null;
  subordinates: Array<string | null>;
  roles?: Array<string | null> | null;
  firstName: string;
  lastName: string;
  title: string;
  department?: string | null;
  disabled?: boolean | null;
  disabledDate?: string | null;
  disabledBy?: string | null;
  hireDate?: string | null;
  managerChangeDate?: string | null;
  lastAnnual?: string | null;
  lastOneOnOne?: string | null;
  positionDate?: string | null;
  previousPositionStartDate?: string | null;
  previousPositionTitle?: string | null;
  validEmailToken?: boolean | null;
  inviteSent?: string | null;
  readiness?: string | null;
  criticalPosition?: boolean | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  readinessAssessmentDate?: string | null;
  image?: string | null;
  imageIdentityId?: string | null;
  bio?: string | null;
  careerGoals?: string | null;
  relocate?: string | null;
  relocateDate?: string | null;
  workStatus?: string | null;
  workStatusDate?: string | null;
};

export type ModelEmployeeConditionInput = {
  authId?: ModelStringInput | null;
  managerID?: ModelStringInput | null;
  subordinates?: ModelStringInput | null;
  roles?: ModelStringInput | null;
  firstName?: ModelStringInput | null;
  lastName?: ModelStringInput | null;
  title?: ModelStringInput | null;
  department?: ModelStringInput | null;
  disabled?: ModelBooleanInput | null;
  disabledDate?: ModelStringInput | null;
  disabledBy?: ModelStringInput | null;
  hireDate?: ModelStringInput | null;
  managerChangeDate?: ModelStringInput | null;
  lastAnnual?: ModelStringInput | null;
  lastOneOnOne?: ModelStringInput | null;
  positionDate?: ModelStringInput | null;
  previousPositionStartDate?: ModelStringInput | null;
  previousPositionTitle?: ModelStringInput | null;
  validEmailToken?: ModelBooleanInput | null;
  inviteSent?: ModelStringInput | null;
  readiness?: ModelStringInput | null;
  criticalPosition?: ModelBooleanInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  readinessAssessmentDate?: ModelStringInput | null;
  image?: ModelStringInput | null;
  imageIdentityId?: ModelStringInput | null;
  bio?: ModelStringInput | null;
  careerGoals?: ModelStringInput | null;
  relocate?: ModelStringInput | null;
  relocateDate?: ModelStringInput | null;
  workStatus?: ModelStringInput | null;
  workStatusDate?: ModelStringInput | null;
  and?: Array<ModelEmployeeConditionInput | null> | null;
  or?: Array<ModelEmployeeConditionInput | null> | null;
  not?: ModelEmployeeConditionInput | null;
};

export type Employee = {
  __typename: "Employee";
  id: string;
  authId?: string | null;
  email: string;
  orgId: string;
  groups?: Array<string | null> | null;
  consulting?: Array<string | null> | null;
  managerID?: string | null;
  subordinates: Array<string | null>;
  roles?: Array<string | null> | null;
  firstName: string;
  lastName: string;
  title: string;
  department?: string | null;
  disabled?: boolean | null;
  disabledDate?: string | null;
  disabledBy?: string | null;
  hireDate?: string | null;
  managerChangeDate?: string | null;
  lastAnnual?: string | null;
  lastOneOnOne?: string | null;
  positionDate?: string | null;
  previousPositionStartDate?: string | null;
  previousPositionTitle?: string | null;
  validEmailToken?: boolean | null;
  inviteSent?: string | null;
  readiness?: string | null;
  criticalPosition?: boolean | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  readinessAssessmentDate?: string | null;
  image?: string | null;
  imageIdentityId?: string | null;
  bio?: string | null;
  careerGoals?: string | null;
  relocate?: string | null;
  relocateDate?: string | null;
  workStatus?: string | null;
  workStatusDate?: string | null;
};

export type UpdateEmployeeInput = {
  id: string;
  authId?: string | null;
  email?: string | null;
  orgId?: string | null;
  groups?: Array<string | null> | null;
  consulting?: Array<string | null> | null;
  managerID?: string | null;
  subordinates?: Array<string | null> | null;
  roles?: Array<string | null> | null;
  firstName?: string | null;
  lastName?: string | null;
  title?: string | null;
  department?: string | null;
  disabled?: boolean | null;
  disabledDate?: string | null;
  disabledBy?: string | null;
  hireDate?: string | null;
  managerChangeDate?: string | null;
  lastAnnual?: string | null;
  lastOneOnOne?: string | null;
  positionDate?: string | null;
  previousPositionStartDate?: string | null;
  previousPositionTitle?: string | null;
  validEmailToken?: boolean | null;
  inviteSent?: string | null;
  readiness?: string | null;
  criticalPosition?: boolean | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  readinessAssessmentDate?: string | null;
  image?: string | null;
  imageIdentityId?: string | null;
  bio?: string | null;
  careerGoals?: string | null;
  relocate?: string | null;
  relocateDate?: string | null;
  workStatus?: string | null;
  workStatusDate?: string | null;
};

export type DeleteEmployeeInput = {
  id: string;
};

export type CreateEmployeeImageInput = {
  employeeID: string;
  organizationID: string;
  groups?: Array<string | null> | null;
  image?: string | null;
  imageIdentityId?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type ModelEmployeeImageConditionInput = {
  image?: ModelStringInput | null;
  imageIdentityId?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelEmployeeImageConditionInput | null> | null;
  or?: Array<ModelEmployeeImageConditionInput | null> | null;
  not?: ModelEmployeeImageConditionInput | null;
};

export type EmployeeImage = {
  __typename: "EmployeeImage";
  employeeID: string;
  organizationID: string;
  groups?: Array<string | null> | null;
  image?: string | null;
  imageIdentityId?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type UpdateEmployeeImageInput = {
  employeeID: string;
  organizationID?: string | null;
  groups?: Array<string | null> | null;
  image?: string | null;
  imageIdentityId?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type DeleteEmployeeImageInput = {
  employeeID: string;
};

export type CreateEmployeeDataInput = {
  employeeID: string;
  organizationID: string;
  dataCode: string;
  groups?: Array<string | null> | null;
  stringValue?: string | null;
  intValue?: number | null;
  booleanValue?: boolean | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type ModelEmployeeDataConditionInput = {
  stringValue?: ModelStringInput | null;
  intValue?: ModelIntInput | null;
  booleanValue?: ModelBooleanInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelEmployeeDataConditionInput | null> | null;
  or?: Array<ModelEmployeeDataConditionInput | null> | null;
  not?: ModelEmployeeDataConditionInput | null;
};

export type EmployeeData = {
  __typename: "EmployeeData";
  employeeID: string;
  organizationID: string;
  dataCode: string;
  groups?: Array<string | null> | null;
  stringValue?: string | null;
  intValue?: number | null;
  booleanValue?: boolean | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type UpdateEmployeeDataInput = {
  employeeID: string;
  organizationID?: string | null;
  dataCode: string;
  groups?: Array<string | null> | null;
  stringValue?: string | null;
  intValue?: number | null;
  booleanValue?: boolean | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type DeleteEmployeeDataInput = {
  employeeID: string;
  dataCode: string;
};

export type CreateReadinessHistoryInput = {
  id?: string | null;
  organizationID: string;
  groups: Array<string | null>;
  employeeID: string;
  readiness: string;
  comments?: string | null;
  opportunities?: Array<string | null> | null;
  createdAt?: string | null;
  createdBy?: string | null;
  updatedAt?: string | null;
};

export type ModelReadinessHistoryConditionInput = {
  employeeID?: ModelStringInput | null;
  readiness?: ModelStringInput | null;
  comments?: ModelStringInput | null;
  opportunities?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  createdBy?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelReadinessHistoryConditionInput | null> | null;
  or?: Array<ModelReadinessHistoryConditionInput | null> | null;
  not?: ModelReadinessHistoryConditionInput | null;
};

export type ReadinessHistory = {
  __typename: "ReadinessHistory";
  id: string;
  organizationID: string;
  groups: Array<string | null>;
  employeeID: string;
  readiness: string;
  comments?: string | null;
  opportunities?: Array<string | null> | null;
  createdAt: string;
  createdBy?: string | null;
  updatedAt?: string | null;
};

export type UpdateReadinessHistoryInput = {
  id: string;
  organizationID?: string | null;
  groups?: Array<string | null> | null;
  employeeID?: string | null;
  readiness?: string | null;
  comments?: string | null;
  opportunities?: Array<string | null> | null;
  createdAt?: string | null;
  createdBy?: string | null;
  updatedAt?: string | null;
};

export type DeleteReadinessHistoryInput = {
  id: string;
};

export type CreateConsultantAssociationInput = {
  employeeID: string;
  organizationID: string;
  incentive?: boolean | null;
  status?: string | null;
  options?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type ModelConsultantAssociationConditionInput = {
  incentive?: ModelBooleanInput | null;
  status?: ModelStringInput | null;
  options?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelConsultantAssociationConditionInput | null> | null;
  or?: Array<ModelConsultantAssociationConditionInput | null> | null;
  not?: ModelConsultantAssociationConditionInput | null;
};

export type ConsultantAssociation = {
  __typename: "ConsultantAssociation";
  employeeID: string;
  organizationID: string;
  incentive?: boolean | null;
  status?: string | null;
  options?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type UpdateConsultantAssociationInput = {
  employeeID: string;
  organizationID: string;
  incentive?: boolean | null;
  status?: string | null;
  options?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type DeleteConsultantAssociationInput = {
  organizationID: string;
  employeeID: string;
};

export type CreateConsultantFavoritesInput = {
  employeeID: string;
  organizationID: string;
  favorites?: Array<string | null> | null;
};

export type ModelConsultantFavoritesConditionInput = {
  favorites?: ModelStringInput | null;
  and?: Array<ModelConsultantFavoritesConditionInput | null> | null;
  or?: Array<ModelConsultantFavoritesConditionInput | null> | null;
  not?: ModelConsultantFavoritesConditionInput | null;
};

export type ConsultantFavorites = {
  __typename: "ConsultantFavorites";
  employeeID: string;
  organizationID: string;
  favorites?: Array<string | null> | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdateConsultantFavoritesInput = {
  employeeID: string;
  organizationID: string;
  favorites?: Array<string | null> | null;
};

export type DeleteConsultantFavoritesInput = {
  organizationID: string;
  employeeID: string;
};

export type CreateUserActionInput = {
  employeeID: string;
  organizationID: string;
  action: string;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type ModelUserActionConditionInput = {
  action?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelUserActionConditionInput | null> | null;
  or?: Array<ModelUserActionConditionInput | null> | null;
  not?: ModelUserActionConditionInput | null;
};

export type UserAction = {
  __typename: "UserAction";
  employeeID: string;
  organizationID: string;
  action: string;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type UpdateUserActionInput = {
  employeeID: string;
  organizationID?: string | null;
  action?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type DeleteUserActionInput = {
  employeeID: string;
};

export type CreateEmployeeCalendarCodeInput = {
  employeeID: string;
  code: string;
  redirectUrl: string;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type ModelEmployeeCalendarCodeConditionInput = {
  code?: ModelStringInput | null;
  redirectUrl?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelEmployeeCalendarCodeConditionInput | null> | null;
  or?: Array<ModelEmployeeCalendarCodeConditionInput | null> | null;
  not?: ModelEmployeeCalendarCodeConditionInput | null;
};

export type EmployeeCalendarCode = {
  __typename: "EmployeeCalendarCode";
  employeeID: string;
  code: string;
  redirectUrl: string;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type UpdateEmployeeCalendarCodeInput = {
  employeeID: string;
  code?: string | null;
  redirectUrl?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type DeleteEmployeeCalendarCodeInput = {
  employeeID: string;
};

export type CreateEmployeeScoreInput = {
  id?: string | null;
  employeeID: string;
  organizationID: string;
  score: number;
  scoreType: string;
  scoreID: string;
  scoreStart?: string | null;
  scoreEnd?: string | null;
  specifier?: string | null;
  details?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type ModelEmployeeScoreConditionInput = {
  employeeID?: ModelStringInput | null;
  score?: ModelFloatInput | null;
  scoreStart?: ModelStringInput | null;
  scoreEnd?: ModelStringInput | null;
  specifier?: ModelStringInput | null;
  details?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelEmployeeScoreConditionInput | null> | null;
  or?: Array<ModelEmployeeScoreConditionInput | null> | null;
  not?: ModelEmployeeScoreConditionInput | null;
};

export type ModelFloatInput = {
  ne?: number | null;
  eq?: number | null;
  le?: number | null;
  lt?: number | null;
  ge?: number | null;
  gt?: number | null;
  between?: Array<number | null> | null;
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
};

export type EmployeeScore = {
  __typename: "EmployeeScore";
  id: string;
  employeeID: string;
  organizationID: string;
  score: number;
  scoreType: string;
  scoreID: string;
  scoreStart?: string | null;
  scoreEnd?: string | null;
  specifier?: string | null;
  details?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type UpdateEmployeeScoreInput = {
  id: string;
  employeeID?: string | null;
  organizationID: string;
  score?: number | null;
  scoreType: string;
  scoreID: string;
  scoreStart?: string | null;
  scoreEnd?: string | null;
  specifier?: string | null;
  details?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type DeleteEmployeeScoreInput = {
  organizationID: string;
  scoreType: string;
  scoreID: string;
  id: string;
};

export type CreateInspHRationsResponseCounterInput = {
  organizationID: string;
  employeeID: string;
  organizationShoutOutTypeID: string;
  type: InspHRationCounterType;
  group: string;
  count: number;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export enum InspHRationCounterType {
  WEEK = "WEEK",
  MONTH = "MONTH",
  QTR = "QTR",
  YEAR = "YEAR"
}

export type ModelInspHRationsResponseCounterConditionInput = {
  organizationID?: ModelStringInput | null;
  count?: ModelIntInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelInspHRationsResponseCounterConditionInput | null> | null;
  or?: Array<ModelInspHRationsResponseCounterConditionInput | null> | null;
  not?: ModelInspHRationsResponseCounterConditionInput | null;
};

export type InspHRationsResponseCounter = {
  __typename: "InspHRationsResponseCounter";
  organizationID: string;
  employeeID: string;
  organizationShoutOutTypeID: string;
  type: InspHRationCounterType;
  group: string;
  count: number;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type UpdateInspHRationsResponseCounterInput = {
  organizationID?: string | null;
  employeeID: string;
  organizationShoutOutTypeID: string;
  type: InspHRationCounterType;
  group: string;
  count?: number | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type DeleteInspHRationsResponseCounterInput = {
  employeeID: string;
  organizationShoutOutTypeID: string;
  type: InspHRationCounterType;
  group: string;
};

export type CreateInspHRationsOrganizationResponseCounterInput = {
  organizationID: string;
  employeeID: string;
  organizationShoutOutTypeID: string;
  type: InspHRationCounterType;
  group: string;
  count: number;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type ModelInspHRationsOrganizationResponseCounterConditionInput = {
  count?: ModelIntInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelInspHRationsOrganizationResponseCounterConditionInput | null> | null;
  or?: Array<ModelInspHRationsOrganizationResponseCounterConditionInput | null> | null;
  not?: ModelInspHRationsOrganizationResponseCounterConditionInput | null;
};

export type InspHRationsOrganizationResponseCounter = {
  __typename: "InspHRationsOrganizationResponseCounter";
  organizationID: string;
  employeeID: string;
  organizationShoutOutTypeID: string;
  type: InspHRationCounterType;
  group: string;
  count: number;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type UpdateInspHRationsOrganizationResponseCounterInput = {
  organizationID: string;
  employeeID: string;
  organizationShoutOutTypeID: string;
  type: InspHRationCounterType;
  group: string;
  count?: number | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type DeleteInspHRationsOrganizationResponseCounterInput = {
  organizationID: string;
  employeeID: string;
  organizationShoutOutTypeID: string;
  type: InspHRationCounterType;
  group: string;
};

export type CreateIntHRactionCounterInput = {
  employeeID: string;
  participantID: string;
  organizationID: string;
  count: number;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type ModelIntHRactionCounterConditionInput = {
  organizationID?: ModelStringInput | null;
  count?: ModelIntInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelIntHRactionCounterConditionInput | null> | null;
  or?: Array<ModelIntHRactionCounterConditionInput | null> | null;
  not?: ModelIntHRactionCounterConditionInput | null;
};

export type IntHRactionCounter = {
  __typename: "IntHRactionCounter";
  employeeID: string;
  participantID: string;
  organizationID: string;
  count: number;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type UpdateIntHRactionCounterInput = {
  employeeID: string;
  participantID: string;
  organizationID?: string | null;
  count?: number | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type DeleteIntHRactionCounterInput = {
  employeeID: string;
  participantID: string;
};

export type CreatePriorityInput = {
  id?: string | null;
  key: string;
  display: string;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type ModelPriorityConditionInput = {
  key?: ModelStringInput | null;
  display?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelPriorityConditionInput | null> | null;
  or?: Array<ModelPriorityConditionInput | null> | null;
  not?: ModelPriorityConditionInput | null;
};

export type Priority = {
  __typename: "Priority";
  id: string;
  key: string;
  display: string;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type Code = {
  __typename: "Code";
  id: string;
  key: string;
  display: string;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type Focus = {
  __typename: "Focus";
  id: string;
  key: string;
  display: string;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type ObjectiveDomain = {
  __typename: "ObjectiveDomain";
  id: string;
  key: string;
  display: string;
  excludeFromTPS?: boolean | null;
  sites: Array<string>;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type UpdatePriorityInput = {
  id: string;
  key?: string | null;
  display?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type DeletePriorityInput = {
  id: string;
};

export type CreateFocusInput = {
  id?: string | null;
  key: string;
  display: string;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type ModelFocusConditionInput = {
  key?: ModelStringInput | null;
  display?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelFocusConditionInput | null> | null;
  or?: Array<ModelFocusConditionInput | null> | null;
  not?: ModelFocusConditionInput | null;
};

export type UpdateFocusInput = {
  id: string;
  key?: string | null;
  display?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type DeleteFocusInput = {
  id: string;
};

export type CreateObjectiveDomainInput = {
  id?: string | null;
  key: string;
  display: string;
  excludeFromTPS?: boolean | null;
  sites: Array<string>;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type ModelObjectiveDomainConditionInput = {
  key?: ModelStringInput | null;
  display?: ModelStringInput | null;
  excludeFromTPS?: ModelBooleanInput | null;
  sites?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelObjectiveDomainConditionInput | null> | null;
  or?: Array<ModelObjectiveDomainConditionInput | null> | null;
  not?: ModelObjectiveDomainConditionInput | null;
};

export type UpdateObjectiveDomainInput = {
  id: string;
  key?: string | null;
  display?: string | null;
  excludeFromTPS?: boolean | null;
  sites?: Array<string> | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type DeleteObjectiveDomainInput = {
  id: string;
};

export type CreateObjectiveInput = {
  id?: string | null;
  display: string;
  definition: string;
  observables: Array<string>;
  hints?: Array<string | null> | null;
  organizationID?: string | null;
  organizations?: Array<string | null> | null;
  status?: string | null;
  objectiveDomainId: string;
  qrCodes?: Array<QRInput | null> | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type QRInput = {
  type: string;
  imageSrc: string;
  title: string;
  label: string;
  qrdata: string;
};

export type ModelObjectiveConditionInput = {
  display?: ModelStringInput | null;
  definition?: ModelStringInput | null;
  observables?: ModelStringInput | null;
  hints?: ModelStringInput | null;
  organizations?: ModelStringInput | null;
  status?: ModelStringInput | null;
  objectiveDomainId?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelObjectiveConditionInput | null> | null;
  or?: Array<ModelObjectiveConditionInput | null> | null;
  not?: ModelObjectiveConditionInput | null;
};

export type Objective = {
  __typename: "Objective";
  id: string;
  display: string;
  definition: string;
  observables: Array<string>;
  hints?: Array<string | null> | null;
  organizationID?: string | null;
  organizations?: Array<string | null> | null;
  status?: string | null;
  domain: ObjectiveDomain;
  objectiveDomainId: string;
  qrCodes?: Array<QR | null> | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type QR = {
  __typename: "QR";
  type: string;
  imageSrc: string;
  title: string;
  label: string;
  qrdata: string;
};

export type UpdateObjectiveInput = {
  id: string;
  display?: string | null;
  definition?: string | null;
  observables?: Array<string> | null;
  hints?: Array<string | null> | null;
  organizationID?: string | null;
  organizations?: Array<string | null> | null;
  status?: string | null;
  objectiveDomainId?: string | null;
  qrCodes?: Array<QRInput | null> | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type DeleteObjectiveInput = {
  id: string;
};

export type CreateOrganizationObjectiveInput = {
  id?: string | null;
  organizationID: string;
  organizationObjectiveObjectiveId: string;
  enabled?: boolean | null;
  businessObjective?: string | null;
  status?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type ModelOrganizationObjectiveConditionInput = {
  organizationObjectiveObjectiveId?: ModelStringInput | null;
  enabled?: ModelBooleanInput | null;
  businessObjective?: ModelStringInput | null;
  status?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelOrganizationObjectiveConditionInput | null> | null;
  or?: Array<ModelOrganizationObjectiveConditionInput | null> | null;
  not?: ModelOrganizationObjectiveConditionInput | null;
};

export type OrganizationObjective = {
  __typename: "OrganizationObjective";
  id: string;
  organizationID: string;
  objective: Objective;
  organizationObjectiveObjectiveId: string;
  enabled?: boolean | null;
  businessObjective?: string | null;
  status?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type UpdateOrganizationObjectiveInput = {
  id: string;
  organizationID?: string | null;
  organizationObjectiveObjectiveId?: string | null;
  enabled?: boolean | null;
  businessObjective?: string | null;
  status?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type DeleteOrganizationObjectiveInput = {
  id: string;
};

export type CreateObjectiveAssignmentInput = {
  id?: string | null;
  employeeId: string;
  organizationID?: string | null;
  objectiveAssignmentAssignedById?: string | null;
  assignmentDate: string;
  startDate?: string | null;
  endDate?: string | null;
  cadence?: string | null;
  managerDescription?: string | null;
  objectiveType?: string | null;
  title?: string | null;
  quantity?: number | null;
  consultantId?: string | null;
  nextSurveyDate?: string | null;
  status?: string | null;
  externalEmail?: string | null;
  externalEmails?: Array<string | null> | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  objectiveAssignmentPriorityId?: string | null;
  objectiveAssignmentFocusId?: string | null;
  objectiveAssignmentOrgObjectiveId?: string | null;
};

export type ModelObjectiveAssignmentConditionInput = {
  employeeId?: ModelStringInput | null;
  objectiveAssignmentAssignedById?: ModelStringInput | null;
  assignmentDate?: ModelStringInput | null;
  startDate?: ModelStringInput | null;
  endDate?: ModelStringInput | null;
  cadence?: ModelStringInput | null;
  managerDescription?: ModelStringInput | null;
  objectiveType?: ModelStringInput | null;
  title?: ModelStringInput | null;
  quantity?: ModelIntInput | null;
  consultantId?: ModelStringInput | null;
  nextSurveyDate?: ModelStringInput | null;
  status?: ModelStringInput | null;
  externalEmail?: ModelStringInput | null;
  externalEmails?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelObjectiveAssignmentConditionInput | null> | null;
  or?: Array<ModelObjectiveAssignmentConditionInput | null> | null;
  not?: ModelObjectiveAssignmentConditionInput | null;
};

export type ObjectiveAssignment = {
  __typename: "ObjectiveAssignment";
  id: string;
  employeeId: string;
  organizationID?: string | null;
  objectiveAssignmentAssignedById?: string | null;
  assignmentDate: string;
  priority?: Priority | null;
  focus?: Focus | null;
  orgObjective?: OrganizationObjective | null;
  startDate?: string | null;
  endDate?: string | null;
  cadence?: string | null;
  managerDescription?: string | null;
  objectiveType?: string | null;
  title?: string | null;
  quantity?: number | null;
  consultantId?: string | null;
  nextSurveyDate?: string | null;
  status?: string | null;
  externalEmail?: string | null;
  externalEmails?: Array<string | null> | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type UpdateObjectiveAssignmentInput = {
  id: string;
  employeeId?: string | null;
  organizationID?: string | null;
  objectiveAssignmentAssignedById?: string | null;
  assignmentDate?: string | null;
  startDate?: string | null;
  endDate?: string | null;
  cadence?: string | null;
  managerDescription?: string | null;
  objectiveType?: string | null;
  title?: string | null;
  quantity?: number | null;
  consultantId?: string | null;
  nextSurveyDate?: string | null;
  status?: string | null;
  externalEmail?: string | null;
  externalEmails?: Array<string | null> | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  objectiveAssignmentPriorityId?: string | null;
  objectiveAssignmentFocusId?: string | null;
  objectiveAssignmentOrgObjectiveId?: string | null;
};

export type DeleteObjectiveAssignmentInput = {
  id: string;
};

export type CreateCalendarEventInput = {
  id?: string | null;
  employeeID: string;
  organizationID: string;
  calendarId: string;
  start: string;
  end: string;
  eventCreated: string;
  eventUpdated?: string | null;
  summary?: string | null;
  description?: string | null;
  status?: string | null;
  private?: boolean | null;
  deleted?: boolean | null;
  recurring?: boolean | null;
  locationDescription?: string | null;
  participationStatus?: string | null;
  eventStatus?: string | null;
  organizerEmail?: string | null;
  organizer?: EventAttendeeInput | null;
  attendees?: Array<EventAttendeeInput | null> | null;
  attendeeEmails?: Array<string | null> | null;
  meetingURL?: string | null;
  options?: EventOptionsInput | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type EventAttendeeInput = {
  email: string;
  displayName?: string | null;
  status?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type EventOptionsInput = {
  changeParticipationStatus?: boolean | null;
  delete?: boolean | null;
  update?: boolean | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type ModelCalendarEventConditionInput = {
  employeeID?: ModelStringInput | null;
  calendarId?: ModelStringInput | null;
  start?: ModelStringInput | null;
  end?: ModelStringInput | null;
  eventCreated?: ModelStringInput | null;
  eventUpdated?: ModelStringInput | null;
  summary?: ModelStringInput | null;
  description?: ModelStringInput | null;
  status?: ModelStringInput | null;
  private?: ModelBooleanInput | null;
  deleted?: ModelBooleanInput | null;
  recurring?: ModelBooleanInput | null;
  locationDescription?: ModelStringInput | null;
  participationStatus?: ModelStringInput | null;
  eventStatus?: ModelStringInput | null;
  organizerEmail?: ModelStringInput | null;
  attendeeEmails?: ModelStringInput | null;
  meetingURL?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelCalendarEventConditionInput | null> | null;
  or?: Array<ModelCalendarEventConditionInput | null> | null;
  not?: ModelCalendarEventConditionInput | null;
};

export type CalendarEvent = {
  __typename: "CalendarEvent";
  id: string;
  employeeID: string;
  organizationID: string;
  calendarId: string;
  start: string;
  end: string;
  eventCreated: string;
  eventUpdated?: string | null;
  summary?: string | null;
  description?: string | null;
  status?: string | null;
  private?: boolean | null;
  deleted?: boolean | null;
  recurring?: boolean | null;
  locationDescription?: string | null;
  participationStatus?: string | null;
  eventStatus?: string | null;
  organizerEmail?: string | null;
  organizer?: EventAttendee | null;
  attendees?: Array<EventAttendee | null> | null;
  attendeeEmails?: Array<string | null> | null;
  meetingURL?: string | null;
  options?: EventOptions | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type EventAttendee = {
  __typename: "EventAttendee";
  email: string;
  displayName?: string | null;
  status?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type EventOptions = {
  __typename: "EventOptions";
  changeParticipationStatus?: boolean | null;
  delete?: boolean | null;
  update?: boolean | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type UpdateCalendarEventInput = {
  id: string;
  employeeID?: string | null;
  organizationID?: string | null;
  calendarId?: string | null;
  start?: string | null;
  end?: string | null;
  eventCreated?: string | null;
  eventUpdated?: string | null;
  summary?: string | null;
  description?: string | null;
  status?: string | null;
  private?: boolean | null;
  deleted?: boolean | null;
  recurring?: boolean | null;
  locationDescription?: string | null;
  participationStatus?: string | null;
  eventStatus?: string | null;
  organizerEmail?: string | null;
  organizer?: EventAttendeeInput | null;
  attendees?: Array<EventAttendeeInput | null> | null;
  attendeeEmails?: Array<string | null> | null;
  meetingURL?: string | null;
  options?: EventOptionsInput | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type DeleteCalendarEventInput = {
  id: string;
};

export type CreateSurveyInput = {
  id?: string | null;
  organizationID: string;
  objectID: string;
  surveyType: SurveyType;
  surveyDate: string;
  respondentEmail: string;
  participantEmail: string;
  respondentAttended?: boolean | null;
  surveyResponse?: number | null;
  feedback?: string | null;
  responseReceived?: boolean | null;
  responseReceivedDate?: string | null;
  status?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export enum SurveyType {
  EVENT = "EVENT",
  OBJECTIVE = "OBJECTIVE",
  PROJECT = "PROJECT",
  MILESTONE = "MILESTONE",
  INSPHRATION = "INSPHRATION",
  EXTERNAL_EVENT = "EXTERNAL_EVENT",
  EXTERNAL_OBJECTIVE = "EXTERNAL_OBJECTIVE",
  NEW_HIRE_14_DAY = "NEW_HIRE_14_DAY",
  NEW_POSITION_60_DAY = "NEW_POSITION_60_DAY",
  NEW_MANAGER_30_DAY = "NEW_MANAGER_30_DAY",
  NEW_HIRE_90_DAY = "NEW_HIRE_90_DAY",
  NEW_MANAGER_90_DAY = "NEW_MANAGER_90_DAY"
}

export type ModelSurveyConditionInput = {
  organizationID?: ModelStringInput | null;
  objectID?: ModelStringInput | null;
  surveyType?: ModelSurveyTypeInput | null;
  surveyDate?: ModelStringInput | null;
  respondentAttended?: ModelBooleanInput | null;
  surveyResponse?: ModelIntInput | null;
  feedback?: ModelStringInput | null;
  responseReceived?: ModelBooleanInput | null;
  responseReceivedDate?: ModelStringInput | null;
  status?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelSurveyConditionInput | null> | null;
  or?: Array<ModelSurveyConditionInput | null> | null;
  not?: ModelSurveyConditionInput | null;
};

export type ModelSurveyTypeInput = {
  eq?: SurveyType | null;
  ne?: SurveyType | null;
};

export type Survey = {
  __typename: "Survey";
  id: string;
  organizationID: string;
  objectID: string;
  surveyType: SurveyType;
  surveyDate: string;
  respondentEmail: string;
  participantEmail: string;
  respondentAttended?: boolean | null;
  surveyResponse?: number | null;
  feedback?: string | null;
  responseReceived?: boolean | null;
  responseReceivedDate?: string | null;
  status?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type UpdateSurveyInput = {
  id: string;
  organizationID?: string | null;
  objectID?: string | null;
  surveyType?: SurveyType | null;
  surveyDate?: string | null;
  respondentEmail?: string | null;
  participantEmail?: string | null;
  respondentAttended?: boolean | null;
  surveyResponse?: number | null;
  feedback?: string | null;
  responseReceived?: boolean | null;
  responseReceivedDate?: string | null;
  status?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type DeleteSurveyInput = {
  id: string;
};

export type CreateExternalRespondentInput = {
  id?: string | null;
  externalRespondentEmail: string;
  externalRespondentName?: string | null;
  externalRespondentStatus?: string | null;
  statusUpdates?: Array<ExternalRespondentStatusChangeInput | null> | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type ExternalRespondentStatusChangeInput = {
  status: string;
  ipAddress: string;
  externalRespondentStatusDate: string;
};

export type ModelExternalRespondentConditionInput = {
  externalRespondentEmail?: ModelStringInput | null;
  externalRespondentName?: ModelStringInput | null;
  externalRespondentStatus?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelExternalRespondentConditionInput | null> | null;
  or?: Array<ModelExternalRespondentConditionInput | null> | null;
  not?: ModelExternalRespondentConditionInput | null;
};

export type ExternalRespondent = {
  __typename: "ExternalRespondent";
  id: string;
  externalRespondentEmail: string;
  externalRespondentName?: string | null;
  externalRespondentStatus?: string | null;
  statusUpdates?: Array<ExternalRespondentStatusChange | null> | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type ExternalRespondentStatusChange = {
  __typename: "ExternalRespondentStatusChange";
  status: string;
  ipAddress: string;
  externalRespondentStatusDate: string;
};

export type UpdateExternalRespondentInput = {
  id: string;
  externalRespondentEmail?: string | null;
  externalRespondentName?: string | null;
  externalRespondentStatus?: string | null;
  statusUpdates?: Array<ExternalRespondentStatusChangeInput | null> | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type DeleteExternalRespondentInput = {
  id: string;
};

export type UpdateDoNotDisturbRequestInput = {
  id: string;
  externalRespondentID?: string | null;
  externalRespondentEmail?: string | null;
  ipAddress?: string | null;
  status?: string | null;
};

export type ModelDoNotDisturbRequestConditionInput = {
  externalRespondentID?: ModelStringInput | null;
  externalRespondentEmail?: ModelStringInput | null;
  ipAddress?: ModelStringInput | null;
  status?: ModelStringInput | null;
  and?: Array<ModelDoNotDisturbRequestConditionInput | null> | null;
  or?: Array<ModelDoNotDisturbRequestConditionInput | null> | null;
  not?: ModelDoNotDisturbRequestConditionInput | null;
};

export type DoNotDisturbRequest = {
  __typename: "DoNotDisturbRequest";
  id: string;
  externalRespondentID?: string | null;
  externalRespondentEmail: string;
  ipAddress: string;
  status: string;
  createdAt: string;
  updatedAt: string;
};

export type DeleteDoNotDisturbRequestInput = {
  id: string;
};

export type CreateExternalSurveyInput = {
  id?: string | null;
  surveyType: SurveyType;
  surveyDate?: string | null;
  externalRespondentID: string;
  participant: ParticipantInput;
  objectiveDetails?: ObjectiveDetailsInput | null;
  respondentAttended?: boolean | null;
  surveyResponse?: number | null;
  feedback?: string | null;
  responseReceived?: boolean | null;
  responseReceivedDate?: string | null;
  status?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type ParticipantInput = {
  id: string;
  participantName: string;
};

export type ObjectiveDetailsInput = {
  id: string;
  objectiveDisplay?: string | null;
  objectiveDefinition?: string | null;
  businessObjective?: string | null;
  managerDescription?: string | null;
  objectiveObservables?: Array<string | null> | null;
};

export type ModelExternalSurveyConditionInput = {
  surveyType?: ModelSurveyTypeInput | null;
  surveyDate?: ModelStringInput | null;
  externalRespondentID?: ModelStringInput | null;
  respondentAttended?: ModelBooleanInput | null;
  surveyResponse?: ModelIntInput | null;
  feedback?: ModelStringInput | null;
  responseReceived?: ModelBooleanInput | null;
  responseReceivedDate?: ModelStringInput | null;
  status?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelExternalSurveyConditionInput | null> | null;
  or?: Array<ModelExternalSurveyConditionInput | null> | null;
  not?: ModelExternalSurveyConditionInput | null;
};

export type ExternalSurvey = {
  __typename: "ExternalSurvey";
  id: string;
  surveyType: SurveyType;
  surveyDate?: string | null;
  externalRespondentID: string;
  participant: Participant;
  objectiveDetails?: ObjectiveDetails | null;
  status?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  respondentAttended?: boolean | null;
  surveyResponse?: number | null;
  feedback?: string | null;
  responseReceived?: boolean | null;
  responseReceivedDate?: string | null;
};

export type Participant = {
  __typename: "Participant";
  id: string;
  participantName: string;
};

export type ObjectiveDetails = {
  __typename: "ObjectiveDetails";
  id: string;
  objectiveDisplay?: string | null;
  objectiveDefinition?: string | null;
  businessObjective?: string | null;
  managerDescription?: string | null;
  objectiveObservables?: Array<string | null> | null;
};

export type DeleteExternalSurveyInput = {
  id: string;
};

export type CreateProjectInput = {
  id?: string | null;
  organizationID: string;
  title: string;
  description?: string | null;
  startDate: string;
  endDate: string;
  cadence: string;
  projectManagerID: string;
  status?: string | null;
  team?: Array<string | null> | null;
  nextSurveyDate: string;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type ModelProjectConditionInput = {
  title?: ModelStringInput | null;
  description?: ModelStringInput | null;
  startDate?: ModelStringInput | null;
  endDate?: ModelStringInput | null;
  cadence?: ModelStringInput | null;
  projectManagerID?: ModelStringInput | null;
  status?: ModelStringInput | null;
  team?: ModelStringInput | null;
  nextSurveyDate?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelProjectConditionInput | null> | null;
  or?: Array<ModelProjectConditionInput | null> | null;
  not?: ModelProjectConditionInput | null;
};

export type Project = {
  __typename: "Project";
  id: string;
  organizationID: string;
  title: string;
  description?: string | null;
  startDate: string;
  endDate: string;
  cadence: string;
  projectManagerID: string;
  status?: string | null;
  team?: Array<string | null> | null;
  nextSurveyDate: string;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type UpdateProjectInput = {
  id: string;
  organizationID?: string | null;
  title?: string | null;
  description?: string | null;
  startDate?: string | null;
  endDate?: string | null;
  cadence?: string | null;
  projectManagerID?: string | null;
  status?: string | null;
  team?: Array<string | null> | null;
  nextSurveyDate?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type DeleteProjectInput = {
  id: string;
};

export type CreateMilestoneCommentInput = {
  id?: string | null;
  organizationID: string;
  milestoneID: string;
  comment: string;
  createdAt?: string | null;
  createdBy: string;
  updatedAt?: string | null;
};

export type ModelMilestoneCommentConditionInput = {
  milestoneID?: ModelStringInput | null;
  comment?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  createdBy?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelMilestoneCommentConditionInput | null> | null;
  or?: Array<ModelMilestoneCommentConditionInput | null> | null;
  not?: ModelMilestoneCommentConditionInput | null;
};

export type MilestoneComment = {
  __typename: "MilestoneComment";
  id: string;
  organizationID: string;
  milestoneID: string;
  projectMilestoneAssignment?: ProjectMilestoneAssignment | null;
  comment: string;
  createdAt: string;
  createdBy: string;
  updatedAt?: string | null;
};

export type ProjectMilestoneAssignment = {
  __typename: "ProjectMilestoneAssignment";
  id: string;
  projectID: string;
  organizationID: string;
  assignedBy: string;
  assignmentDate: string;
  startDate: string;
  endDate: string;
  cadence: string;
  milestoneType: string;
  title: string;
  nextSurveyDate: string;
  description?: string | null;
  team?: Array<string | null> | null;
  comments?: ModelMilestoneCommentConnection | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type ModelMilestoneCommentConnection = {
  __typename: "ModelMilestoneCommentConnection";
  items: Array<MilestoneComment | null>;
  nextToken?: string | null;
};

export type UpdateMilestoneCommentInput = {
  id: string;
  organizationID?: string | null;
  milestoneID?: string | null;
  comment?: string | null;
  createdAt?: string | null;
  createdBy?: string | null;
  updatedAt?: string | null;
};

export type DeleteMilestoneCommentInput = {
  id: string;
};

export type CreateProjectMilestoneAssignmentInput = {
  id?: string | null;
  projectID: string;
  organizationID: string;
  assignedBy: string;
  assignmentDate: string;
  startDate: string;
  endDate: string;
  cadence: string;
  milestoneType: string;
  title: string;
  nextSurveyDate: string;
  description?: string | null;
  team?: Array<string | null> | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type ModelProjectMilestoneAssignmentConditionInput = {
  projectID?: ModelStringInput | null;
  assignedBy?: ModelStringInput | null;
  assignmentDate?: ModelStringInput | null;
  startDate?: ModelStringInput | null;
  endDate?: ModelStringInput | null;
  cadence?: ModelStringInput | null;
  milestoneType?: ModelStringInput | null;
  title?: ModelStringInput | null;
  nextSurveyDate?: ModelStringInput | null;
  description?: ModelStringInput | null;
  team?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelProjectMilestoneAssignmentConditionInput | null> | null;
  or?: Array<ModelProjectMilestoneAssignmentConditionInput | null> | null;
  not?: ModelProjectMilestoneAssignmentConditionInput | null;
};

export type UpdateProjectMilestoneAssignmentInput = {
  id: string;
  projectID?: string | null;
  organizationID?: string | null;
  assignedBy?: string | null;
  assignmentDate?: string | null;
  startDate?: string | null;
  endDate?: string | null;
  cadence?: string | null;
  milestoneType?: string | null;
  title?: string | null;
  nextSurveyDate?: string | null;
  description?: string | null;
  team?: Array<string | null> | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type DeleteProjectMilestoneAssignmentInput = {
  id: string;
};

export type CreateNoteCommentInput = {
  id?: string | null;
  organizationID: string;
  noteID: string;
  comment: string;
  createdAt?: string | null;
  createdBy: string;
  updatedAt?: string | null;
};

export type ModelNoteCommentConditionInput = {
  noteID?: ModelStringInput | null;
  comment?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  createdBy?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelNoteCommentConditionInput | null> | null;
  or?: Array<ModelNoteCommentConditionInput | null> | null;
  not?: ModelNoteCommentConditionInput | null;
};

export type NoteComment = {
  __typename: "NoteComment";
  id: string;
  organizationID: string;
  noteID: string;
  note?: Note | null;
  comment: string;
  createdAt: string;
  createdBy: string;
  updatedAt?: string | null;
};

export type Note = {
  __typename: "Note";
  id: string;
  organizationID: string;
  objectID: string;
  createdAt: string;
  createdBy: string;
  updatedAt?: string | null;
  updatedBy?: string | null;
  text?: string | null;
  noteType: string;
  options?: string | null;
  comments?: ModelNoteCommentConnection | null;
  historys?: ModelNoteHistoryConnection | null;
};

export type ModelNoteCommentConnection = {
  __typename: "ModelNoteCommentConnection";
  items: Array<NoteComment | null>;
  nextToken?: string | null;
};

export type ModelNoteHistoryConnection = {
  __typename: "ModelNoteHistoryConnection";
  items: Array<NoteHistory | null>;
  nextToken?: string | null;
};

export type NoteHistory = {
  __typename: "NoteHistory";
  id: string;
  organizationID: string;
  noteID: string;
  note?: Note | null;
  createdAt: string;
  createdBy: string;
  text?: string | null;
  updatedAt?: string | null;
};

export type UpdateNoteCommentInput = {
  id: string;
  organizationID?: string | null;
  noteID?: string | null;
  comment?: string | null;
  createdAt?: string | null;
  createdBy?: string | null;
  updatedAt?: string | null;
};

export type DeleteNoteCommentInput = {
  id: string;
};

export type CreateNoteInput = {
  id?: string | null;
  organizationID: string;
  objectID: string;
  createdAt?: string | null;
  createdBy: string;
  updatedAt?: string | null;
  updatedBy?: string | null;
  text?: string | null;
  noteType: string;
  options?: string | null;
};

export type ModelNoteConditionInput = {
  objectID?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  createdBy?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  updatedBy?: ModelStringInput | null;
  text?: ModelStringInput | null;
  noteType?: ModelStringInput | null;
  options?: ModelStringInput | null;
  and?: Array<ModelNoteConditionInput | null> | null;
  or?: Array<ModelNoteConditionInput | null> | null;
  not?: ModelNoteConditionInput | null;
};

export type UpdateNoteInput = {
  id: string;
  organizationID?: string | null;
  objectID?: string | null;
  createdAt?: string | null;
  createdBy?: string | null;
  updatedAt?: string | null;
  updatedBy?: string | null;
  text?: string | null;
  noteType?: string | null;
  options?: string | null;
};

export type DeleteNoteInput = {
  id: string;
};

export type CreateNoteHistoryInput = {
  id?: string | null;
  organizationID: string;
  noteID: string;
  createdAt?: string | null;
  createdBy: string;
  text?: string | null;
  updatedAt?: string | null;
};

export type ModelNoteHistoryConditionInput = {
  noteID?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  createdBy?: ModelStringInput | null;
  text?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelNoteHistoryConditionInput | null> | null;
  or?: Array<ModelNoteHistoryConditionInput | null> | null;
  not?: ModelNoteHistoryConditionInput | null;
};

export type UpdateNoteHistoryInput = {
  id: string;
  organizationID?: string | null;
  noteID?: string | null;
  createdAt?: string | null;
  createdBy?: string | null;
  text?: string | null;
  updatedAt?: string | null;
};

export type DeleteNoteHistoryInput = {
  id: string;
};

export type CreateShoutOutTypeInput = {
  id?: string | null;
  title: string;
  formalDescription?: string | null;
  informalDescription?: string | null;
  icon?: string | null;
  attributes: Array<string | null>;
  status?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type ModelShoutOutTypeConditionInput = {
  title?: ModelStringInput | null;
  formalDescription?: ModelStringInput | null;
  informalDescription?: ModelStringInput | null;
  icon?: ModelStringInput | null;
  attributes?: ModelStringInput | null;
  status?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelShoutOutTypeConditionInput | null> | null;
  or?: Array<ModelShoutOutTypeConditionInput | null> | null;
  not?: ModelShoutOutTypeConditionInput | null;
};

export type ShoutOutType = {
  __typename: "ShoutOutType";
  id: string;
  title: string;
  formalDescription?: string | null;
  informalDescription?: string | null;
  icon?: string | null;
  attributes: Array<string | null>;
  status?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type UpdateShoutOutTypeInput = {
  id: string;
  title?: string | null;
  formalDescription?: string | null;
  informalDescription?: string | null;
  icon?: string | null;
  attributes?: Array<string | null> | null;
  status?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type DeleteShoutOutTypeInput = {
  id: string;
};

export type CreateOrganizationShoutOutTypeInput = {
  id?: string | null;
  organizationID: string;
  overrideTitle: string;
  organizationShoutOutTypeShoutOuTypeId: string;
  description: string;
  overrideIcon?: string | null;
  overrideIconIdentityId?: string | null;
  enabled?: boolean | null;
  organizationAttributes?: Array<string | null> | null;
  disabledAttributes?: Array<number | null> | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type ModelOrganizationShoutOutTypeConditionInput = {
  overrideTitle?: ModelStringInput | null;
  organizationShoutOutTypeShoutOuTypeId?: ModelStringInput | null;
  description?: ModelStringInput | null;
  overrideIcon?: ModelStringInput | null;
  overrideIconIdentityId?: ModelStringInput | null;
  enabled?: ModelBooleanInput | null;
  organizationAttributes?: ModelStringInput | null;
  disabledAttributes?: ModelIntInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelOrganizationShoutOutTypeConditionInput | null> | null;
  or?: Array<ModelOrganizationShoutOutTypeConditionInput | null> | null;
  not?: ModelOrganizationShoutOutTypeConditionInput | null;
};

export type OrganizationShoutOutType = {
  __typename: "OrganizationShoutOutType";
  id: string;
  organizationID: string;
  overrideTitle: string;
  organizationShoutOutTypeShoutOuTypeId: string;
  shoutOutType: ShoutOutType;
  description: string;
  overrideIcon?: string | null;
  overrideIconIdentityId?: string | null;
  enabled?: boolean | null;
  organizationAttributes?: Array<string | null> | null;
  disabledAttributes?: Array<number | null> | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type UpdateOrganizationShoutOutTypeInput = {
  id: string;
  organizationID?: string | null;
  overrideTitle?: string | null;
  organizationShoutOutTypeShoutOuTypeId?: string | null;
  description?: string | null;
  overrideIcon?: string | null;
  overrideIconIdentityId?: string | null;
  enabled?: boolean | null;
  organizationAttributes?: Array<string | null> | null;
  disabledAttributes?: Array<number | null> | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type DeleteOrganizationShoutOutTypeInput = {
  id: string;
};

export type CreateStandupSurveyConfigurationInput = {
  id?: string | null;
  organizationID: string;
  employeeID: string;
  questions: Array<VersionedQuestionInput | null>;
  shared?: Array<string | null> | null;
  excluded?: Array<string | null> | null;
  frequency: string;
  lastSurveyedDate?: string | null;
  isoWeekday?: number | null;
  status?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type VersionedQuestionInput = {
  id: string;
  questionTextArray: Array<string | null>;
  enabled?: boolean | null;
};

export type ModelStandupSurveyConfigurationConditionInput = {
  shared?: ModelStringInput | null;
  excluded?: ModelStringInput | null;
  frequency?: ModelStringInput | null;
  lastSurveyedDate?: ModelStringInput | null;
  isoWeekday?: ModelIntInput | null;
  status?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelStandupSurveyConfigurationConditionInput | null> | null;
  or?: Array<ModelStandupSurveyConfigurationConditionInput | null> | null;
  not?: ModelStandupSurveyConfigurationConditionInput | null;
};

export type StandupSurveyConfiguration = {
  __typename: "StandupSurveyConfiguration";
  id: string;
  organizationID: string;
  employeeID: string;
  questions: Array<VersionedQuestion | null>;
  shared?: Array<string | null> | null;
  excluded?: Array<string | null> | null;
  frequency: string;
  lastSurveyedDate?: string | null;
  isoWeekday?: number | null;
  status?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type VersionedQuestion = {
  __typename: "VersionedQuestion";
  id: string;
  questionTextArray: Array<string | null>;
  enabled?: boolean | null;
};

export type UpdateStandupSurveyConfigurationInput = {
  id: string;
  organizationID?: string | null;
  employeeID?: string | null;
  questions?: Array<VersionedQuestionInput | null> | null;
  shared?: Array<string | null> | null;
  excluded?: Array<string | null> | null;
  frequency?: string | null;
  lastSurveyedDate?: string | null;
  isoWeekday?: number | null;
  status?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type DeleteStandupSurveyConfigurationInput = {
  id: string;
};

export type CreateStandupSurveyInput = {
  id?: string | null;
  organizationID: string;
  standupSurveyConfigurationID: string;
  questionMaps: Array<QuestionMapInput | null>;
  respondentEmail: string;
  respondentID: string;
  surveyDate: string;
  feedbackArray?: Array<string | null> | null;
  chatRequest?: boolean | null;
  meetingRequest?: boolean | null;
  responseReceived?: boolean | null;
  responseReceivedDate?: string | null;
  status?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type QuestionMapInput = {
  id: string;
  version: number;
};

export type ModelStandupSurveyConditionInput = {
  standupSurveyConfigurationID?: ModelStringInput | null;
  respondentID?: ModelStringInput | null;
  surveyDate?: ModelStringInput | null;
  feedbackArray?: ModelStringInput | null;
  chatRequest?: ModelBooleanInput | null;
  meetingRequest?: ModelBooleanInput | null;
  responseReceived?: ModelBooleanInput | null;
  responseReceivedDate?: ModelStringInput | null;
  status?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelStandupSurveyConditionInput | null> | null;
  or?: Array<ModelStandupSurveyConditionInput | null> | null;
  not?: ModelStandupSurveyConditionInput | null;
};

export type StandupSurvey = {
  __typename: "StandupSurvey";
  id: string;
  organizationID: string;
  standupSurveyConfigurationID: string;
  questionMaps: Array<QuestionMap | null>;
  respondentEmail: string;
  respondentID: string;
  surveyDate: string;
  feedbackArray?: Array<string | null> | null;
  chatRequest?: boolean | null;
  meetingRequest?: boolean | null;
  responseReceived?: boolean | null;
  responseReceivedDate?: string | null;
  status?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type QuestionMap = {
  __typename: "QuestionMap";
  id: string;
  version: number;
};

export type UpdateStandupSurveyInput = {
  id: string;
  organizationID?: string | null;
  standupSurveyConfigurationID?: string | null;
  questionMaps?: Array<QuestionMapInput | null> | null;
  respondentEmail?: string | null;
  respondentID?: string | null;
  surveyDate?: string | null;
  feedbackArray?: Array<string | null> | null;
  chatRequest?: boolean | null;
  meetingRequest?: boolean | null;
  responseReceived?: boolean | null;
  responseReceivedDate?: string | null;
  status?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type DeleteStandupSurveyInput = {
  id: string;
};

export type CreateDoNotDisturbRequestInput = {
  id?: string | null;
  externalRespondentID?: string | null;
  externalRespondentEmail: string;
  ipAddress: string;
  status: string;
};

export type UpdateExternalSurveyInput = {
  id: string;
  surveyType?: SurveyType | null;
  surveyDate?: string | null;
  externalRespondentID?: string | null;
  participant?: ParticipantInput | null;
  objectiveDetails?: ObjectiveDetailsInput | null;
  respondentAttended?: boolean | null;
  surveyResponse?: number | null;
  feedback?: string | null;
  responseReceived?: boolean | null;
  responseReceivedDate?: string | null;
  status?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type ModelOrganizationFilterInput = {
  id?: ModelIDInput | null;
  orgName?: ModelStringInput | null;
  domains?: ModelStringInput | null;
  site?: ModelStringInput | null;
  disabled?: ModelBooleanInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelOrganizationFilterInput | null> | null;
  or?: Array<ModelOrganizationFilterInput | null> | null;
  not?: ModelOrganizationFilterInput | null;
};

export type ModelIDInput = {
  ne?: string | null;
  eq?: string | null;
  le?: string | null;
  lt?: string | null;
  ge?: string | null;
  gt?: string | null;
  contains?: string | null;
  notContains?: string | null;
  between?: Array<string | null> | null;
  beginsWith?: string | null;
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
  size?: ModelSizeInput | null;
};

export type ModelOrganizationConnection = {
  __typename: "ModelOrganizationConnection";
  items: Array<Organization | null>;
  nextToken?: string | null;
};

export type ModelStripeFilterInput = {
  organizationID?: ModelStringInput | null;
  customerID?: ModelStringInput | null;
  subscriptionID?: ModelStringInput | null;
  subscriptionItemID?: ModelStringInput | null;
  planID?: ModelStringInput | null;
  billingName?: ModelStringInput | null;
  billingEmail?: ModelStringInput | null;
  cardToken?: ModelStringInput | null;
  subscriptionStatus?: ModelStringInput | null;
  hmac?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelStripeFilterInput | null> | null;
  or?: Array<ModelStripeFilterInput | null> | null;
  not?: ModelStripeFilterInput | null;
};

export enum ModelSortDirection {
  ASC = "ASC",
  DESC = "DESC"
}

export type ModelStripeConnection = {
  __typename: "ModelStripeConnection";
  items: Array<Stripe | null>;
  nextToken?: string | null;
};

export type ModelProcessLogFilterInput = {
  process?: ModelStringInput | null;
  lastRunTime?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelProcessLogFilterInput | null> | null;
  or?: Array<ModelProcessLogFilterInput | null> | null;
  not?: ModelProcessLogFilterInput | null;
};

export type ModelProcessLogConnection = {
  __typename: "ModelProcessLogConnection";
  items: Array<ProcessLog | null>;
  nextToken?: string | null;
};

export type ModelOrganizationConfigurationFilterInput = {
  id?: ModelIDInput | null;
  organizationID?: ModelStringInput | null;
  configCode?: ModelStringInput | null;
  configStringValue?: ModelStringInput | null;
  configIntValue?: ModelIntInput | null;
  configBooleanValue?: ModelBooleanInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelOrganizationConfigurationFilterInput | null> | null;
  or?: Array<ModelOrganizationConfigurationFilterInput | null> | null;
  not?: ModelOrganizationConfigurationFilterInput | null;
};

export type ModelOrganizationConfigurationConnection = {
  __typename: "ModelOrganizationConfigurationConnection";
  items: Array<OrganizationConfiguration | null>;
  nextToken?: string | null;
};

export type ModelEmployeeFilterInput = {
  id?: ModelIDInput | null;
  authId?: ModelStringInput | null;
  email?: ModelStringInput | null;
  orgId?: ModelStringInput | null;
  groups?: ModelStringInput | null;
  consulting?: ModelStringInput | null;
  managerID?: ModelStringInput | null;
  subordinates?: ModelStringInput | null;
  roles?: ModelStringInput | null;
  firstName?: ModelStringInput | null;
  lastName?: ModelStringInput | null;
  title?: ModelStringInput | null;
  department?: ModelStringInput | null;
  disabled?: ModelBooleanInput | null;
  disabledDate?: ModelStringInput | null;
  disabledBy?: ModelStringInput | null;
  hireDate?: ModelStringInput | null;
  managerChangeDate?: ModelStringInput | null;
  lastAnnual?: ModelStringInput | null;
  lastOneOnOne?: ModelStringInput | null;
  positionDate?: ModelStringInput | null;
  previousPositionStartDate?: ModelStringInput | null;
  previousPositionTitle?: ModelStringInput | null;
  validEmailToken?: ModelBooleanInput | null;
  inviteSent?: ModelStringInput | null;
  readiness?: ModelStringInput | null;
  criticalPosition?: ModelBooleanInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  readinessAssessmentDate?: ModelStringInput | null;
  image?: ModelStringInput | null;
  imageIdentityId?: ModelStringInput | null;
  bio?: ModelStringInput | null;
  careerGoals?: ModelStringInput | null;
  relocate?: ModelStringInput | null;
  relocateDate?: ModelStringInput | null;
  workStatus?: ModelStringInput | null;
  workStatusDate?: ModelStringInput | null;
  and?: Array<ModelEmployeeFilterInput | null> | null;
  or?: Array<ModelEmployeeFilterInput | null> | null;
  not?: ModelEmployeeFilterInput | null;
};

export type ModelEmployeeConnection = {
  __typename: "ModelEmployeeConnection";
  items: Array<Employee | null>;
  nextToken?: string | null;
};

export type ModelStringKeyConditionInput = {
  eq?: string | null;
  le?: string | null;
  lt?: string | null;
  ge?: string | null;
  gt?: string | null;
  between?: Array<string | null> | null;
  beginsWith?: string | null;
};

export type ModelEmployeeDataFilterInput = {
  employeeID?: ModelStringInput | null;
  organizationID?: ModelStringInput | null;
  dataCode?: ModelStringInput | null;
  groups?: ModelStringInput | null;
  stringValue?: ModelStringInput | null;
  intValue?: ModelIntInput | null;
  booleanValue?: ModelBooleanInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelEmployeeDataFilterInput | null> | null;
  or?: Array<ModelEmployeeDataFilterInput | null> | null;
  not?: ModelEmployeeDataFilterInput | null;
};

export type ModelEmployeeDataConnection = {
  __typename: "ModelEmployeeDataConnection";
  items: Array<EmployeeData | null>;
  nextToken?: string | null;
};

export type ModelReadinessHistoryFilterInput = {
  id?: ModelIDInput | null;
  organizationID?: ModelStringInput | null;
  groups?: ModelStringInput | null;
  employeeID?: ModelStringInput | null;
  readiness?: ModelStringInput | null;
  comments?: ModelStringInput | null;
  opportunities?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  createdBy?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelReadinessHistoryFilterInput | null> | null;
  or?: Array<ModelReadinessHistoryFilterInput | null> | null;
  not?: ModelReadinessHistoryFilterInput | null;
};

export type ModelReadinessHistoryConnection = {
  __typename: "ModelReadinessHistoryConnection";
  items: Array<ReadinessHistory | null>;
  nextToken?: string | null;
};

export type ModelConsultantAssociationFilterInput = {
  employeeID?: ModelStringInput | null;
  organizationID?: ModelStringInput | null;
  incentive?: ModelBooleanInput | null;
  status?: ModelStringInput | null;
  options?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelConsultantAssociationFilterInput | null> | null;
  or?: Array<ModelConsultantAssociationFilterInput | null> | null;
  not?: ModelConsultantAssociationFilterInput | null;
};

export type ModelConsultantAssociationConnection = {
  __typename: "ModelConsultantAssociationConnection";
  items: Array<ConsultantAssociation | null>;
  nextToken?: string | null;
};

export type ModelConsultantFavoritesFilterInput = {
  employeeID?: ModelStringInput | null;
  organizationID?: ModelStringInput | null;
  favorites?: ModelStringInput | null;
  and?: Array<ModelConsultantFavoritesFilterInput | null> | null;
  or?: Array<ModelConsultantFavoritesFilterInput | null> | null;
  not?: ModelConsultantFavoritesFilterInput | null;
};

export type ModelConsultantFavoritesConnection = {
  __typename: "ModelConsultantFavoritesConnection";
  items: Array<ConsultantFavorites | null>;
  nextToken?: string | null;
};

export type ModelUserActionFilterInput = {
  employeeID?: ModelStringInput | null;
  organizationID?: ModelStringInput | null;
  action?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelUserActionFilterInput | null> | null;
  or?: Array<ModelUserActionFilterInput | null> | null;
  not?: ModelUserActionFilterInput | null;
};

export type ModelUserActionConnection = {
  __typename: "ModelUserActionConnection";
  items: Array<UserAction | null>;
  nextToken?: string | null;
};

export type ModelEmployeeCalendarCodeFilterInput = {
  employeeID?: ModelStringInput | null;
  code?: ModelStringInput | null;
  redirectUrl?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelEmployeeCalendarCodeFilterInput | null> | null;
  or?: Array<ModelEmployeeCalendarCodeFilterInput | null> | null;
  not?: ModelEmployeeCalendarCodeFilterInput | null;
};

export type ModelEmployeeCalendarCodeConnection = {
  __typename: "ModelEmployeeCalendarCodeConnection";
  items: Array<EmployeeCalendarCode | null>;
  nextToken?: string | null;
};

export type ModelEmployeeScorePrimaryCompositeKeyConditionInput = {
  eq?: ModelEmployeeScorePrimaryCompositeKeyInput | null;
  le?: ModelEmployeeScorePrimaryCompositeKeyInput | null;
  lt?: ModelEmployeeScorePrimaryCompositeKeyInput | null;
  ge?: ModelEmployeeScorePrimaryCompositeKeyInput | null;
  gt?: ModelEmployeeScorePrimaryCompositeKeyInput | null;
  between?: Array<ModelEmployeeScorePrimaryCompositeKeyInput | null> | null;
  beginsWith?: ModelEmployeeScorePrimaryCompositeKeyInput | null;
};

export type ModelEmployeeScorePrimaryCompositeKeyInput = {
  scoreType?: string | null;
  scoreID?: string | null;
  id?: string | null;
};

export type ModelEmployeeScoreFilterInput = {
  id?: ModelIDInput | null;
  employeeID?: ModelStringInput | null;
  organizationID?: ModelStringInput | null;
  score?: ModelFloatInput | null;
  scoreType?: ModelStringInput | null;
  scoreID?: ModelStringInput | null;
  scoreStart?: ModelStringInput | null;
  scoreEnd?: ModelStringInput | null;
  specifier?: ModelStringInput | null;
  details?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelEmployeeScoreFilterInput | null> | null;
  or?: Array<ModelEmployeeScoreFilterInput | null> | null;
  not?: ModelEmployeeScoreFilterInput | null;
};

export type ModelEmployeeScoreConnection = {
  __typename: "ModelEmployeeScoreConnection";
  items: Array<EmployeeScore | null>;
  nextToken?: string | null;
};

export type ModelInspHRationsResponseCounterPrimaryCompositeKeyConditionInput = {
  eq?: ModelInspHRationsResponseCounterPrimaryCompositeKeyInput | null;
  le?: ModelInspHRationsResponseCounterPrimaryCompositeKeyInput | null;
  lt?: ModelInspHRationsResponseCounterPrimaryCompositeKeyInput | null;
  ge?: ModelInspHRationsResponseCounterPrimaryCompositeKeyInput | null;
  gt?: ModelInspHRationsResponseCounterPrimaryCompositeKeyInput | null;
  between?: Array<ModelInspHRationsResponseCounterPrimaryCompositeKeyInput | null> | null;
  beginsWith?: ModelInspHRationsResponseCounterPrimaryCompositeKeyInput | null;
};

export type ModelInspHRationsResponseCounterPrimaryCompositeKeyInput = {
  organizationShoutOutTypeID?: string | null;
  type?: InspHRationCounterType | null;
  group?: string | null;
};

export type ModelInspHRationsResponseCounterFilterInput = {
  organizationID?: ModelStringInput | null;
  employeeID?: ModelStringInput | null;
  organizationShoutOutTypeID?: ModelStringInput | null;
  type?: ModelInspHRationCounterTypeInput | null;
  group?: ModelStringInput | null;
  count?: ModelIntInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelInspHRationsResponseCounterFilterInput | null> | null;
  or?: Array<ModelInspHRationsResponseCounterFilterInput | null> | null;
  not?: ModelInspHRationsResponseCounterFilterInput | null;
};

export type ModelInspHRationCounterTypeInput = {
  eq?: InspHRationCounterType | null;
  ne?: InspHRationCounterType | null;
};

export type ModelInspHRationsResponseCounterConnection = {
  __typename: "ModelInspHRationsResponseCounterConnection";
  items: Array<InspHRationsResponseCounter | null>;
  nextToken?: string | null;
};

export type ModelInspHRationsOrganizationResponseCounterPrimaryCompositeKeyConditionInput = {
  eq?: ModelInspHRationsOrganizationResponseCounterPrimaryCompositeKeyInput | null;
  le?: ModelInspHRationsOrganizationResponseCounterPrimaryCompositeKeyInput | null;
  lt?: ModelInspHRationsOrganizationResponseCounterPrimaryCompositeKeyInput | null;
  ge?: ModelInspHRationsOrganizationResponseCounterPrimaryCompositeKeyInput | null;
  gt?: ModelInspHRationsOrganizationResponseCounterPrimaryCompositeKeyInput | null;
  between?: Array<ModelInspHRationsOrganizationResponseCounterPrimaryCompositeKeyInput | null> | null;
  beginsWith?: ModelInspHRationsOrganizationResponseCounterPrimaryCompositeKeyInput | null;
};

export type ModelInspHRationsOrganizationResponseCounterPrimaryCompositeKeyInput = {
  employeeID?: string | null;
  organizationShoutOutTypeID?: string | null;
  type?: InspHRationCounterType | null;
  group?: string | null;
};

export type ModelInspHRationsOrganizationResponseCounterFilterInput = {
  organizationID?: ModelStringInput | null;
  employeeID?: ModelStringInput | null;
  organizationShoutOutTypeID?: ModelStringInput | null;
  type?: ModelInspHRationCounterTypeInput | null;
  group?: ModelStringInput | null;
  count?: ModelIntInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelInspHRationsOrganizationResponseCounterFilterInput | null> | null;
  or?: Array<ModelInspHRationsOrganizationResponseCounterFilterInput | null> | null;
  not?: ModelInspHRationsOrganizationResponseCounterFilterInput | null;
};

export type ModelInspHRationsOrganizationResponseCounterConnection = {
  __typename: "ModelInspHRationsOrganizationResponseCounterConnection";
  items: Array<InspHRationsOrganizationResponseCounter | null>;
  nextToken?: string | null;
};

export type ModelIntHRactionCounterFilterInput = {
  employeeID?: ModelStringInput | null;
  participantID?: ModelStringInput | null;
  organizationID?: ModelStringInput | null;
  count?: ModelIntInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelIntHRactionCounterFilterInput | null> | null;
  or?: Array<ModelIntHRactionCounterFilterInput | null> | null;
  not?: ModelIntHRactionCounterFilterInput | null;
};

export type ModelIntHRactionCounterConnection = {
  __typename: "ModelIntHRactionCounterConnection";
  items: Array<IntHRactionCounter | null>;
  nextToken?: string | null;
};

export type ModelPriorityFilterInput = {
  id?: ModelIDInput | null;
  key?: ModelStringInput | null;
  display?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelPriorityFilterInput | null> | null;
  or?: Array<ModelPriorityFilterInput | null> | null;
  not?: ModelPriorityFilterInput | null;
};

export type ModelPriorityConnection = {
  __typename: "ModelPriorityConnection";
  items: Array<Priority | null>;
  nextToken?: string | null;
};

export type ModelFocusFilterInput = {
  id?: ModelIDInput | null;
  key?: ModelStringInput | null;
  display?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelFocusFilterInput | null> | null;
  or?: Array<ModelFocusFilterInput | null> | null;
  not?: ModelFocusFilterInput | null;
};

export type ModelFocusConnection = {
  __typename: "ModelFocusConnection";
  items: Array<Focus | null>;
  nextToken?: string | null;
};

export type ModelObjectiveDomainFilterInput = {
  id?: ModelIDInput | null;
  key?: ModelStringInput | null;
  display?: ModelStringInput | null;
  excludeFromTPS?: ModelBooleanInput | null;
  sites?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelObjectiveDomainFilterInput | null> | null;
  or?: Array<ModelObjectiveDomainFilterInput | null> | null;
  not?: ModelObjectiveDomainFilterInput | null;
};

export type ModelObjectiveDomainConnection = {
  __typename: "ModelObjectiveDomainConnection";
  items: Array<ObjectiveDomain | null>;
  nextToken?: string | null;
};

export type ModelObjectiveFilterInput = {
  id?: ModelIDInput | null;
  display?: ModelStringInput | null;
  definition?: ModelStringInput | null;
  observables?: ModelStringInput | null;
  hints?: ModelStringInput | null;
  organizationID?: ModelStringInput | null;
  organizations?: ModelStringInput | null;
  status?: ModelStringInput | null;
  objectiveDomainId?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelObjectiveFilterInput | null> | null;
  or?: Array<ModelObjectiveFilterInput | null> | null;
  not?: ModelObjectiveFilterInput | null;
};

export type ModelObjectiveConnection = {
  __typename: "ModelObjectiveConnection";
  items: Array<Objective | null>;
  nextToken?: string | null;
};

export type ModelOrganizationObjectiveFilterInput = {
  id?: ModelIDInput | null;
  organizationID?: ModelStringInput | null;
  organizationObjectiveObjectiveId?: ModelStringInput | null;
  enabled?: ModelBooleanInput | null;
  businessObjective?: ModelStringInput | null;
  status?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelOrganizationObjectiveFilterInput | null> | null;
  or?: Array<ModelOrganizationObjectiveFilterInput | null> | null;
  not?: ModelOrganizationObjectiveFilterInput | null;
};

export type ModelOrganizationObjectiveConnection = {
  __typename: "ModelOrganizationObjectiveConnection";
  items: Array<OrganizationObjective | null>;
  nextToken?: string | null;
};

export type ModelObjectiveAssignmentFilterInput = {
  id?: ModelIDInput | null;
  employeeId?: ModelStringInput | null;
  organizationID?: ModelStringInput | null;
  objectiveAssignmentAssignedById?: ModelStringInput | null;
  assignmentDate?: ModelStringInput | null;
  startDate?: ModelStringInput | null;
  endDate?: ModelStringInput | null;
  cadence?: ModelStringInput | null;
  managerDescription?: ModelStringInput | null;
  objectiveType?: ModelStringInput | null;
  title?: ModelStringInput | null;
  quantity?: ModelIntInput | null;
  consultantId?: ModelStringInput | null;
  nextSurveyDate?: ModelStringInput | null;
  status?: ModelStringInput | null;
  externalEmail?: ModelStringInput | null;
  externalEmails?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelObjectiveAssignmentFilterInput | null> | null;
  or?: Array<ModelObjectiveAssignmentFilterInput | null> | null;
  not?: ModelObjectiveAssignmentFilterInput | null;
};

export type ModelObjectiveAssignmentConnection = {
  __typename: "ModelObjectiveAssignmentConnection";
  items: Array<ObjectiveAssignment | null>;
  nextToken?: string | null;
};

export type ModelCalendarEventFilterInput = {
  id?: ModelIDInput | null;
  employeeID?: ModelStringInput | null;
  organizationID?: ModelStringInput | null;
  calendarId?: ModelStringInput | null;
  start?: ModelStringInput | null;
  end?: ModelStringInput | null;
  eventCreated?: ModelStringInput | null;
  eventUpdated?: ModelStringInput | null;
  summary?: ModelStringInput | null;
  description?: ModelStringInput | null;
  status?: ModelStringInput | null;
  private?: ModelBooleanInput | null;
  deleted?: ModelBooleanInput | null;
  recurring?: ModelBooleanInput | null;
  locationDescription?: ModelStringInput | null;
  participationStatus?: ModelStringInput | null;
  eventStatus?: ModelStringInput | null;
  organizerEmail?: ModelStringInput | null;
  attendeeEmails?: ModelStringInput | null;
  meetingURL?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelCalendarEventFilterInput | null> | null;
  or?: Array<ModelCalendarEventFilterInput | null> | null;
  not?: ModelCalendarEventFilterInput | null;
};

export type ModelCalendarEventConnection = {
  __typename: "ModelCalendarEventConnection";
  items: Array<CalendarEvent | null>;
  nextToken?: string | null;
};

export type ModelSurveyFilterInput = {
  id?: ModelIDInput | null;
  organizationID?: ModelStringInput | null;
  objectID?: ModelStringInput | null;
  surveyType?: ModelSurveyTypeInput | null;
  surveyDate?: ModelStringInput | null;
  respondentEmail?: ModelStringInput | null;
  participantEmail?: ModelStringInput | null;
  respondentAttended?: ModelBooleanInput | null;
  surveyResponse?: ModelIntInput | null;
  feedback?: ModelStringInput | null;
  responseReceived?: ModelBooleanInput | null;
  responseReceivedDate?: ModelStringInput | null;
  status?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelSurveyFilterInput | null> | null;
  or?: Array<ModelSurveyFilterInput | null> | null;
  not?: ModelSurveyFilterInput | null;
};

export type ModelSurveyConnection = {
  __typename: "ModelSurveyConnection";
  items: Array<Survey | null>;
  nextToken?: string | null;
};

export type ModelExternalRespondentFilterInput = {
  id?: ModelIDInput | null;
  externalRespondentEmail?: ModelStringInput | null;
  externalRespondentName?: ModelStringInput | null;
  externalRespondentStatus?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelExternalRespondentFilterInput | null> | null;
  or?: Array<ModelExternalRespondentFilterInput | null> | null;
  not?: ModelExternalRespondentFilterInput | null;
};

export type ModelExternalRespondentConnection = {
  __typename: "ModelExternalRespondentConnection";
  items: Array<ExternalRespondent | null>;
  nextToken?: string | null;
};

export type ModelDoNotDisturbRequestFilterInput = {
  id?: ModelIDInput | null;
  externalRespondentID?: ModelStringInput | null;
  externalRespondentEmail?: ModelStringInput | null;
  ipAddress?: ModelStringInput | null;
  status?: ModelStringInput | null;
  and?: Array<ModelDoNotDisturbRequestFilterInput | null> | null;
  or?: Array<ModelDoNotDisturbRequestFilterInput | null> | null;
  not?: ModelDoNotDisturbRequestFilterInput | null;
};

export type ModelDoNotDisturbRequestConnection = {
  __typename: "ModelDoNotDisturbRequestConnection";
  items: Array<DoNotDisturbRequest | null>;
  nextToken?: string | null;
};

export type ModelProjectFilterInput = {
  id?: ModelIDInput | null;
  organizationID?: ModelStringInput | null;
  title?: ModelStringInput | null;
  description?: ModelStringInput | null;
  startDate?: ModelStringInput | null;
  endDate?: ModelStringInput | null;
  cadence?: ModelStringInput | null;
  projectManagerID?: ModelStringInput | null;
  status?: ModelStringInput | null;
  team?: ModelStringInput | null;
  nextSurveyDate?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelProjectFilterInput | null> | null;
  or?: Array<ModelProjectFilterInput | null> | null;
  not?: ModelProjectFilterInput | null;
};

export type ModelProjectConnection = {
  __typename: "ModelProjectConnection";
  items: Array<Project | null>;
  nextToken?: string | null;
};

export type ModelMilestoneCommentFilterInput = {
  id?: ModelIDInput | null;
  organizationID?: ModelStringInput | null;
  milestoneID?: ModelStringInput | null;
  comment?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  createdBy?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelMilestoneCommentFilterInput | null> | null;
  or?: Array<ModelMilestoneCommentFilterInput | null> | null;
  not?: ModelMilestoneCommentFilterInput | null;
};

export type ModelProjectMilestoneAssignmentFilterInput = {
  id?: ModelIDInput | null;
  projectID?: ModelStringInput | null;
  organizationID?: ModelStringInput | null;
  assignedBy?: ModelStringInput | null;
  assignmentDate?: ModelStringInput | null;
  startDate?: ModelStringInput | null;
  endDate?: ModelStringInput | null;
  cadence?: ModelStringInput | null;
  milestoneType?: ModelStringInput | null;
  title?: ModelStringInput | null;
  nextSurveyDate?: ModelStringInput | null;
  description?: ModelStringInput | null;
  team?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelProjectMilestoneAssignmentFilterInput | null> | null;
  or?: Array<ModelProjectMilestoneAssignmentFilterInput | null> | null;
  not?: ModelProjectMilestoneAssignmentFilterInput | null;
};

export type ModelProjectMilestoneAssignmentConnection = {
  __typename: "ModelProjectMilestoneAssignmentConnection";
  items: Array<ProjectMilestoneAssignment | null>;
  nextToken?: string | null;
};

export type ModelNoteCommentFilterInput = {
  id?: ModelIDInput | null;
  organizationID?: ModelStringInput | null;
  noteID?: ModelStringInput | null;
  comment?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  createdBy?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelNoteCommentFilterInput | null> | null;
  or?: Array<ModelNoteCommentFilterInput | null> | null;
  not?: ModelNoteCommentFilterInput | null;
};

export type ModelNoteFilterInput = {
  id?: ModelIDInput | null;
  organizationID?: ModelStringInput | null;
  objectID?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  createdBy?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  updatedBy?: ModelStringInput | null;
  text?: ModelStringInput | null;
  noteType?: ModelStringInput | null;
  options?: ModelStringInput | null;
  and?: Array<ModelNoteFilterInput | null> | null;
  or?: Array<ModelNoteFilterInput | null> | null;
  not?: ModelNoteFilterInput | null;
};

export type ModelNoteConnection = {
  __typename: "ModelNoteConnection";
  items: Array<Note | null>;
  nextToken?: string | null;
};

export type ModelNoteHistoryFilterInput = {
  id?: ModelIDInput | null;
  organizationID?: ModelStringInput | null;
  noteID?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  createdBy?: ModelStringInput | null;
  text?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelNoteHistoryFilterInput | null> | null;
  or?: Array<ModelNoteHistoryFilterInput | null> | null;
  not?: ModelNoteHistoryFilterInput | null;
};

export type ModelShoutOutTypeFilterInput = {
  id?: ModelIDInput | null;
  title?: ModelStringInput | null;
  formalDescription?: ModelStringInput | null;
  informalDescription?: ModelStringInput | null;
  icon?: ModelStringInput | null;
  attributes?: ModelStringInput | null;
  status?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelShoutOutTypeFilterInput | null> | null;
  or?: Array<ModelShoutOutTypeFilterInput | null> | null;
  not?: ModelShoutOutTypeFilterInput | null;
};

export type ModelShoutOutTypeConnection = {
  __typename: "ModelShoutOutTypeConnection";
  items: Array<ShoutOutType | null>;
  nextToken?: string | null;
};

export type ModelOrganizationShoutOutTypeFilterInput = {
  id?: ModelIDInput | null;
  organizationID?: ModelStringInput | null;
  overrideTitle?: ModelStringInput | null;
  organizationShoutOutTypeShoutOuTypeId?: ModelStringInput | null;
  description?: ModelStringInput | null;
  overrideIcon?: ModelStringInput | null;
  overrideIconIdentityId?: ModelStringInput | null;
  enabled?: ModelBooleanInput | null;
  organizationAttributes?: ModelStringInput | null;
  disabledAttributes?: ModelIntInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelOrganizationShoutOutTypeFilterInput | null> | null;
  or?: Array<ModelOrganizationShoutOutTypeFilterInput | null> | null;
  not?: ModelOrganizationShoutOutTypeFilterInput | null;
};

export type ModelOrganizationShoutOutTypeConnection = {
  __typename: "ModelOrganizationShoutOutTypeConnection";
  items: Array<OrganizationShoutOutType | null>;
  nextToken?: string | null;
};

export type ModelStandupSurveyConfigurationFilterInput = {
  id?: ModelIDInput | null;
  organizationID?: ModelStringInput | null;
  employeeID?: ModelStringInput | null;
  shared?: ModelStringInput | null;
  excluded?: ModelStringInput | null;
  frequency?: ModelStringInput | null;
  lastSurveyedDate?: ModelStringInput | null;
  isoWeekday?: ModelIntInput | null;
  status?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelStandupSurveyConfigurationFilterInput | null> | null;
  or?: Array<ModelStandupSurveyConfigurationFilterInput | null> | null;
  not?: ModelStandupSurveyConfigurationFilterInput | null;
};

export type ModelStandupSurveyConfigurationConnection = {
  __typename: "ModelStandupSurveyConfigurationConnection";
  items: Array<StandupSurveyConfiguration | null>;
  nextToken?: string | null;
};

export type ModelStandupSurveyFilterInput = {
  id?: ModelIDInput | null;
  organizationID?: ModelStringInput | null;
  standupSurveyConfigurationID?: ModelStringInput | null;
  respondentEmail?: ModelStringInput | null;
  respondentID?: ModelStringInput | null;
  surveyDate?: ModelStringInput | null;
  feedbackArray?: ModelStringInput | null;
  chatRequest?: ModelBooleanInput | null;
  meetingRequest?: ModelBooleanInput | null;
  responseReceived?: ModelBooleanInput | null;
  responseReceivedDate?: ModelStringInput | null;
  status?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelStandupSurveyFilterInput | null> | null;
  or?: Array<ModelStandupSurveyFilterInput | null> | null;
  not?: ModelStandupSurveyFilterInput | null;
};

export type ModelStandupSurveyConnection = {
  __typename: "ModelStandupSurveyConnection";
  items: Array<StandupSurvey | null>;
  nextToken?: string | null;
};

export type ModelSurveyByRespondentTypeDateCompositeKeyConditionInput = {
  eq?: ModelSurveyByRespondentTypeDateCompositeKeyInput | null;
  le?: ModelSurveyByRespondentTypeDateCompositeKeyInput | null;
  lt?: ModelSurveyByRespondentTypeDateCompositeKeyInput | null;
  ge?: ModelSurveyByRespondentTypeDateCompositeKeyInput | null;
  gt?: ModelSurveyByRespondentTypeDateCompositeKeyInput | null;
  between?: Array<ModelSurveyByRespondentTypeDateCompositeKeyInput | null> | null;
  beginsWith?: ModelSurveyByRespondentTypeDateCompositeKeyInput | null;
};

export type ModelSurveyByRespondentTypeDateCompositeKeyInput = {
  surveyType?: SurveyType | null;
  surveyDate?: string | null;
  id?: string | null;
};

export type ModelSurveyByParticipantTypeDateCompositeKeyConditionInput = {
  eq?: ModelSurveyByParticipantTypeDateCompositeKeyInput | null;
  le?: ModelSurveyByParticipantTypeDateCompositeKeyInput | null;
  lt?: ModelSurveyByParticipantTypeDateCompositeKeyInput | null;
  ge?: ModelSurveyByParticipantTypeDateCompositeKeyInput | null;
  gt?: ModelSurveyByParticipantTypeDateCompositeKeyInput | null;
  between?: Array<ModelSurveyByParticipantTypeDateCompositeKeyInput | null> | null;
  beginsWith?: ModelSurveyByParticipantTypeDateCompositeKeyInput | null;
};

export type ModelSurveyByParticipantTypeDateCompositeKeyInput = {
  surveyType?: SurveyType | null;
  surveyDate?: string | null;
  id?: string | null;
};

export type ModelSurveyByOrgObjectDateCompositeKeyConditionInput = {
  eq?: ModelSurveyByOrgObjectDateCompositeKeyInput | null;
  le?: ModelSurveyByOrgObjectDateCompositeKeyInput | null;
  lt?: ModelSurveyByOrgObjectDateCompositeKeyInput | null;
  ge?: ModelSurveyByOrgObjectDateCompositeKeyInput | null;
  gt?: ModelSurveyByOrgObjectDateCompositeKeyInput | null;
  between?: Array<ModelSurveyByOrgObjectDateCompositeKeyInput | null> | null;
  beginsWith?: ModelSurveyByOrgObjectDateCompositeKeyInput | null;
};

export type ModelSurveyByOrgObjectDateCompositeKeyInput = {
  objectID?: string | null;
  surveyDate?: string | null;
  id?: string | null;
};

export type ModelSurveyByOrgTypeDateCompositeKeyConditionInput = {
  eq?: ModelSurveyByOrgTypeDateCompositeKeyInput | null;
  le?: ModelSurveyByOrgTypeDateCompositeKeyInput | null;
  lt?: ModelSurveyByOrgTypeDateCompositeKeyInput | null;
  ge?: ModelSurveyByOrgTypeDateCompositeKeyInput | null;
  gt?: ModelSurveyByOrgTypeDateCompositeKeyInput | null;
  between?: Array<ModelSurveyByOrgTypeDateCompositeKeyInput | null> | null;
  beginsWith?: ModelSurveyByOrgTypeDateCompositeKeyInput | null;
};

export type ModelSurveyByOrgTypeDateCompositeKeyInput = {
  surveyType?: SurveyType | null;
  surveyDate?: string | null;
  id?: string | null;
};

export type ModelEmployeeImageFilterInput = {
  employeeID?: ModelStringInput | null;
  organizationID?: ModelStringInput | null;
  groups?: ModelStringInput | null;
  image?: ModelStringInput | null;
  imageIdentityId?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelEmployeeImageFilterInput | null> | null;
  or?: Array<ModelEmployeeImageFilterInput | null> | null;
  not?: ModelEmployeeImageFilterInput | null;
};

export type ModelEmployeeImageConnection = {
  __typename: "ModelEmployeeImageConnection";
  items: Array<EmployeeImage | null>;
  nextToken?: string | null;
};

export type ModelExternalSurveyFilterInput = {
  id?: ModelIDInput | null;
  surveyType?: ModelSurveyTypeInput | null;
  surveyDate?: ModelStringInput | null;
  externalRespondentID?: ModelStringInput | null;
  respondentAttended?: ModelBooleanInput | null;
  surveyResponse?: ModelIntInput | null;
  feedback?: ModelStringInput | null;
  responseReceived?: ModelBooleanInput | null;
  responseReceivedDate?: ModelStringInput | null;
  status?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelExternalSurveyFilterInput | null> | null;
  or?: Array<ModelExternalSurveyFilterInput | null> | null;
  not?: ModelExternalSurveyFilterInput | null;
};

export type ModelExternalSurveyConnection = {
  __typename: "ModelExternalSurveyConnection";
  items: Array<ExternalSurvey | null>;
  nextToken?: string | null;
};

export type CreateOrganizationMutation = {
  __typename: "Organization";
  id: string;
  orgName: string;
  domains: Array<string>;
  site: string;
  disabled?: boolean | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type UpdateOrganizationMutation = {
  __typename: "Organization";
  id: string;
  orgName: string;
  domains: Array<string>;
  site: string;
  disabled?: boolean | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type DeleteOrganizationMutation = {
  __typename: "Organization";
  id: string;
  orgName: string;
  domains: Array<string>;
  site: string;
  disabled?: boolean | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type CreateStripeMutation = {
  __typename: "Stripe";
  organizationID: string;
  customerID?: string | null;
  subscriptionID?: string | null;
  subscriptionItemID?: string | null;
  planID?: string | null;
  billingName?: string | null;
  billingEmail?: string | null;
  cardToken?: string | null;
  subscriptionStatus?: string | null;
  hmac?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type UpdateStripeMutation = {
  __typename: "Stripe";
  organizationID: string;
  customerID?: string | null;
  subscriptionID?: string | null;
  subscriptionItemID?: string | null;
  planID?: string | null;
  billingName?: string | null;
  billingEmail?: string | null;
  cardToken?: string | null;
  subscriptionStatus?: string | null;
  hmac?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type DeleteStripeMutation = {
  __typename: "Stripe";
  organizationID: string;
  customerID?: string | null;
  subscriptionID?: string | null;
  subscriptionItemID?: string | null;
  planID?: string | null;
  billingName?: string | null;
  billingEmail?: string | null;
  cardToken?: string | null;
  subscriptionStatus?: string | null;
  hmac?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type CreateProcessLogMutation = {
  __typename: "ProcessLog";
  process: string;
  lastRunTime: string;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type UpdateProcessLogMutation = {
  __typename: "ProcessLog";
  process: string;
  lastRunTime: string;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type DeleteProcessLogMutation = {
  __typename: "ProcessLog";
  process: string;
  lastRunTime: string;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type CreateOrganizationConfigurationMutation = {
  __typename: "OrganizationConfiguration";
  id: string;
  organizationID: string;
  configCode: string;
  configStringValue?: string | null;
  configIntValue?: number | null;
  configBooleanValue?: boolean | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type UpdateOrganizationConfigurationMutation = {
  __typename: "OrganizationConfiguration";
  id: string;
  organizationID: string;
  configCode: string;
  configStringValue?: string | null;
  configIntValue?: number | null;
  configBooleanValue?: boolean | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type DeleteOrganizationConfigurationMutation = {
  __typename: "OrganizationConfiguration";
  id: string;
  organizationID: string;
  configCode: string;
  configStringValue?: string | null;
  configIntValue?: number | null;
  configBooleanValue?: boolean | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type CreateEmployeeMutation = {
  __typename: "Employee";
  id: string;
  authId?: string | null;
  email: string;
  orgId: string;
  groups?: Array<string | null> | null;
  consulting?: Array<string | null> | null;
  managerID?: string | null;
  subordinates: Array<string | null>;
  roles?: Array<string | null> | null;
  firstName: string;
  lastName: string;
  title: string;
  department?: string | null;
  disabled?: boolean | null;
  disabledDate?: string | null;
  disabledBy?: string | null;
  hireDate?: string | null;
  managerChangeDate?: string | null;
  lastAnnual?: string | null;
  lastOneOnOne?: string | null;
  positionDate?: string | null;
  previousPositionStartDate?: string | null;
  previousPositionTitle?: string | null;
  validEmailToken?: boolean | null;
  inviteSent?: string | null;
  readiness?: string | null;
  criticalPosition?: boolean | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  readinessAssessmentDate?: string | null;
  image?: string | null;
  imageIdentityId?: string | null;
  bio?: string | null;
  careerGoals?: string | null;
  relocate?: string | null;
  relocateDate?: string | null;
  workStatus?: string | null;
  workStatusDate?: string | null;
};

export type UpdateEmployeeMutation = {
  __typename: "Employee";
  id: string;
  authId?: string | null;
  email: string;
  orgId: string;
  groups?: Array<string | null> | null;
  consulting?: Array<string | null> | null;
  managerID?: string | null;
  subordinates: Array<string | null>;
  roles?: Array<string | null> | null;
  firstName: string;
  lastName: string;
  title: string;
  department?: string | null;
  disabled?: boolean | null;
  disabledDate?: string | null;
  disabledBy?: string | null;
  hireDate?: string | null;
  managerChangeDate?: string | null;
  lastAnnual?: string | null;
  lastOneOnOne?: string | null;
  positionDate?: string | null;
  previousPositionStartDate?: string | null;
  previousPositionTitle?: string | null;
  validEmailToken?: boolean | null;
  inviteSent?: string | null;
  readiness?: string | null;
  criticalPosition?: boolean | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  readinessAssessmentDate?: string | null;
  image?: string | null;
  imageIdentityId?: string | null;
  bio?: string | null;
  careerGoals?: string | null;
  relocate?: string | null;
  relocateDate?: string | null;
  workStatus?: string | null;
  workStatusDate?: string | null;
};

export type DeleteEmployeeMutation = {
  __typename: "Employee";
  id: string;
  authId?: string | null;
  email: string;
  orgId: string;
  groups?: Array<string | null> | null;
  consulting?: Array<string | null> | null;
  managerID?: string | null;
  subordinates: Array<string | null>;
  roles?: Array<string | null> | null;
  firstName: string;
  lastName: string;
  title: string;
  department?: string | null;
  disabled?: boolean | null;
  disabledDate?: string | null;
  disabledBy?: string | null;
  hireDate?: string | null;
  managerChangeDate?: string | null;
  lastAnnual?: string | null;
  lastOneOnOne?: string | null;
  positionDate?: string | null;
  previousPositionStartDate?: string | null;
  previousPositionTitle?: string | null;
  validEmailToken?: boolean | null;
  inviteSent?: string | null;
  readiness?: string | null;
  criticalPosition?: boolean | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  readinessAssessmentDate?: string | null;
  image?: string | null;
  imageIdentityId?: string | null;
  bio?: string | null;
  careerGoals?: string | null;
  relocate?: string | null;
  relocateDate?: string | null;
  workStatus?: string | null;
  workStatusDate?: string | null;
};

export type CreateEmployeeImageMutation = {
  __typename: "EmployeeImage";
  employeeID: string;
  organizationID: string;
  groups?: Array<string | null> | null;
  image?: string | null;
  imageIdentityId?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type UpdateEmployeeImageMutation = {
  __typename: "EmployeeImage";
  employeeID: string;
  organizationID: string;
  groups?: Array<string | null> | null;
  image?: string | null;
  imageIdentityId?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type DeleteEmployeeImageMutation = {
  __typename: "EmployeeImage";
  employeeID: string;
  organizationID: string;
  groups?: Array<string | null> | null;
  image?: string | null;
  imageIdentityId?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type CreateEmployeeDataMutation = {
  __typename: "EmployeeData";
  employeeID: string;
  organizationID: string;
  dataCode: string;
  groups?: Array<string | null> | null;
  stringValue?: string | null;
  intValue?: number | null;
  booleanValue?: boolean | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type UpdateEmployeeDataMutation = {
  __typename: "EmployeeData";
  employeeID: string;
  organizationID: string;
  dataCode: string;
  groups?: Array<string | null> | null;
  stringValue?: string | null;
  intValue?: number | null;
  booleanValue?: boolean | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type DeleteEmployeeDataMutation = {
  __typename: "EmployeeData";
  employeeID: string;
  organizationID: string;
  dataCode: string;
  groups?: Array<string | null> | null;
  stringValue?: string | null;
  intValue?: number | null;
  booleanValue?: boolean | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type CreateReadinessHistoryMutation = {
  __typename: "ReadinessHistory";
  id: string;
  organizationID: string;
  groups: Array<string | null>;
  employeeID: string;
  readiness: string;
  comments?: string | null;
  opportunities?: Array<string | null> | null;
  createdAt: string;
  createdBy?: string | null;
  updatedAt?: string | null;
};

export type UpdateReadinessHistoryMutation = {
  __typename: "ReadinessHistory";
  id: string;
  organizationID: string;
  groups: Array<string | null>;
  employeeID: string;
  readiness: string;
  comments?: string | null;
  opportunities?: Array<string | null> | null;
  createdAt: string;
  createdBy?: string | null;
  updatedAt?: string | null;
};

export type DeleteReadinessHistoryMutation = {
  __typename: "ReadinessHistory";
  id: string;
  organizationID: string;
  groups: Array<string | null>;
  employeeID: string;
  readiness: string;
  comments?: string | null;
  opportunities?: Array<string | null> | null;
  createdAt: string;
  createdBy?: string | null;
  updatedAt?: string | null;
};

export type CreateConsultantAssociationMutation = {
  __typename: "ConsultantAssociation";
  employeeID: string;
  organizationID: string;
  incentive?: boolean | null;
  status?: string | null;
  options?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type UpdateConsultantAssociationMutation = {
  __typename: "ConsultantAssociation";
  employeeID: string;
  organizationID: string;
  incentive?: boolean | null;
  status?: string | null;
  options?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type DeleteConsultantAssociationMutation = {
  __typename: "ConsultantAssociation";
  employeeID: string;
  organizationID: string;
  incentive?: boolean | null;
  status?: string | null;
  options?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type CreateConsultantFavoritesMutation = {
  __typename: "ConsultantFavorites";
  employeeID: string;
  organizationID: string;
  favorites?: Array<string | null> | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdateConsultantFavoritesMutation = {
  __typename: "ConsultantFavorites";
  employeeID: string;
  organizationID: string;
  favorites?: Array<string | null> | null;
  createdAt: string;
  updatedAt: string;
};

export type DeleteConsultantFavoritesMutation = {
  __typename: "ConsultantFavorites";
  employeeID: string;
  organizationID: string;
  favorites?: Array<string | null> | null;
  createdAt: string;
  updatedAt: string;
};

export type CreateUserActionMutation = {
  __typename: "UserAction";
  employeeID: string;
  organizationID: string;
  action: string;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type UpdateUserActionMutation = {
  __typename: "UserAction";
  employeeID: string;
  organizationID: string;
  action: string;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type DeleteUserActionMutation = {
  __typename: "UserAction";
  employeeID: string;
  organizationID: string;
  action: string;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type CreateEmployeeCalendarCodeMutation = {
  __typename: "EmployeeCalendarCode";
  employeeID: string;
  code: string;
  redirectUrl: string;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type UpdateEmployeeCalendarCodeMutation = {
  __typename: "EmployeeCalendarCode";
  employeeID: string;
  code: string;
  redirectUrl: string;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type DeleteEmployeeCalendarCodeMutation = {
  __typename: "EmployeeCalendarCode";
  employeeID: string;
  code: string;
  redirectUrl: string;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type CreateEmployeeScoreMutation = {
  __typename: "EmployeeScore";
  id: string;
  employeeID: string;
  organizationID: string;
  score: number;
  scoreType: string;
  scoreID: string;
  scoreStart?: string | null;
  scoreEnd?: string | null;
  specifier?: string | null;
  details?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type UpdateEmployeeScoreMutation = {
  __typename: "EmployeeScore";
  id: string;
  employeeID: string;
  organizationID: string;
  score: number;
  scoreType: string;
  scoreID: string;
  scoreStart?: string | null;
  scoreEnd?: string | null;
  specifier?: string | null;
  details?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type DeleteEmployeeScoreMutation = {
  __typename: "EmployeeScore";
  id: string;
  employeeID: string;
  organizationID: string;
  score: number;
  scoreType: string;
  scoreID: string;
  scoreStart?: string | null;
  scoreEnd?: string | null;
  specifier?: string | null;
  details?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type CreateInspHRationsResponseCounterMutation = {
  __typename: "InspHRationsResponseCounter";
  organizationID: string;
  employeeID: string;
  organizationShoutOutTypeID: string;
  type: InspHRationCounterType;
  group: string;
  count: number;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type UpdateInspHRationsResponseCounterMutation = {
  __typename: "InspHRationsResponseCounter";
  organizationID: string;
  employeeID: string;
  organizationShoutOutTypeID: string;
  type: InspHRationCounterType;
  group: string;
  count: number;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type DeleteInspHRationsResponseCounterMutation = {
  __typename: "InspHRationsResponseCounter";
  organizationID: string;
  employeeID: string;
  organizationShoutOutTypeID: string;
  type: InspHRationCounterType;
  group: string;
  count: number;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type CreateInspHRationsOrganizationResponseCounterMutation = {
  __typename: "InspHRationsOrganizationResponseCounter";
  organizationID: string;
  employeeID: string;
  organizationShoutOutTypeID: string;
  type: InspHRationCounterType;
  group: string;
  count: number;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type UpdateInspHRationsOrganizationResponseCounterMutation = {
  __typename: "InspHRationsOrganizationResponseCounter";
  organizationID: string;
  employeeID: string;
  organizationShoutOutTypeID: string;
  type: InspHRationCounterType;
  group: string;
  count: number;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type DeleteInspHRationsOrganizationResponseCounterMutation = {
  __typename: "InspHRationsOrganizationResponseCounter";
  organizationID: string;
  employeeID: string;
  organizationShoutOutTypeID: string;
  type: InspHRationCounterType;
  group: string;
  count: number;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type CreateIntHRactionCounterMutation = {
  __typename: "IntHRactionCounter";
  employeeID: string;
  participantID: string;
  organizationID: string;
  count: number;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type UpdateIntHRactionCounterMutation = {
  __typename: "IntHRactionCounter";
  employeeID: string;
  participantID: string;
  organizationID: string;
  count: number;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type DeleteIntHRactionCounterMutation = {
  __typename: "IntHRactionCounter";
  employeeID: string;
  participantID: string;
  organizationID: string;
  count: number;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type CreatePriorityMutation = {
  __typename: "Priority";
  id: string;
  key: string;
  display: string;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type UpdatePriorityMutation = {
  __typename: "Priority";
  id: string;
  key: string;
  display: string;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type DeletePriorityMutation = {
  __typename: "Priority";
  id: string;
  key: string;
  display: string;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type CreateFocusMutation = {
  __typename: "Focus";
  id: string;
  key: string;
  display: string;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type UpdateFocusMutation = {
  __typename: "Focus";
  id: string;
  key: string;
  display: string;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type DeleteFocusMutation = {
  __typename: "Focus";
  id: string;
  key: string;
  display: string;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type CreateObjectiveDomainMutation = {
  __typename: "ObjectiveDomain";
  id: string;
  key: string;
  display: string;
  excludeFromTPS?: boolean | null;
  sites: Array<string>;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type UpdateObjectiveDomainMutation = {
  __typename: "ObjectiveDomain";
  id: string;
  key: string;
  display: string;
  excludeFromTPS?: boolean | null;
  sites: Array<string>;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type DeleteObjectiveDomainMutation = {
  __typename: "ObjectiveDomain";
  id: string;
  key: string;
  display: string;
  excludeFromTPS?: boolean | null;
  sites: Array<string>;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type CreateObjectiveMutation = {
  __typename: "Objective";
  id: string;
  display: string;
  definition: string;
  observables: Array<string>;
  hints?: Array<string | null> | null;
  organizationID?: string | null;
  organizations?: Array<string | null> | null;
  status?: string | null;
  domain: {
    __typename: "ObjectiveDomain";
    id: string;
    key: string;
    display: string;
    excludeFromTPS?: boolean | null;
    sites: Array<string>;
    createdAt?: string | null;
    updatedAt?: string | null;
  };
  objectiveDomainId: string;
  qrCodes?: Array<{
    __typename: "QR";
    type: string;
    imageSrc: string;
    title: string;
    label: string;
    qrdata: string;
  } | null> | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type UpdateObjectiveMutation = {
  __typename: "Objective";
  id: string;
  display: string;
  definition: string;
  observables: Array<string>;
  hints?: Array<string | null> | null;
  organizationID?: string | null;
  organizations?: Array<string | null> | null;
  status?: string | null;
  domain: {
    __typename: "ObjectiveDomain";
    id: string;
    key: string;
    display: string;
    excludeFromTPS?: boolean | null;
    sites: Array<string>;
    createdAt?: string | null;
    updatedAt?: string | null;
  };
  objectiveDomainId: string;
  qrCodes?: Array<{
    __typename: "QR";
    type: string;
    imageSrc: string;
    title: string;
    label: string;
    qrdata: string;
  } | null> | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type DeleteObjectiveMutation = {
  __typename: "Objective";
  id: string;
  display: string;
  definition: string;
  observables: Array<string>;
  hints?: Array<string | null> | null;
  organizationID?: string | null;
  organizations?: Array<string | null> | null;
  status?: string | null;
  domain: {
    __typename: "ObjectiveDomain";
    id: string;
    key: string;
    display: string;
    excludeFromTPS?: boolean | null;
    sites: Array<string>;
    createdAt?: string | null;
    updatedAt?: string | null;
  };
  objectiveDomainId: string;
  qrCodes?: Array<{
    __typename: "QR";
    type: string;
    imageSrc: string;
    title: string;
    label: string;
    qrdata: string;
  } | null> | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type CreateOrganizationObjectiveMutation = {
  __typename: "OrganizationObjective";
  id: string;
  organizationID: string;
  objective: {
    __typename: "Objective";
    id: string;
    display: string;
    definition: string;
    observables: Array<string>;
    hints?: Array<string | null> | null;
    organizationID?: string | null;
    organizations?: Array<string | null> | null;
    status?: string | null;
    domain: {
      __typename: "ObjectiveDomain";
      id: string;
      key: string;
      display: string;
      excludeFromTPS?: boolean | null;
      sites: Array<string>;
      createdAt?: string | null;
      updatedAt?: string | null;
    };
    objectiveDomainId: string;
    qrCodes?: Array<{
      __typename: "QR";
      type: string;
      imageSrc: string;
      title: string;
      label: string;
      qrdata: string;
    } | null> | null;
    createdAt?: string | null;
    updatedAt?: string | null;
  };
  organizationObjectiveObjectiveId: string;
  enabled?: boolean | null;
  businessObjective?: string | null;
  status?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type UpdateOrganizationObjectiveMutation = {
  __typename: "OrganizationObjective";
  id: string;
  organizationID: string;
  objective: {
    __typename: "Objective";
    id: string;
    display: string;
    definition: string;
    observables: Array<string>;
    hints?: Array<string | null> | null;
    organizationID?: string | null;
    organizations?: Array<string | null> | null;
    status?: string | null;
    domain: {
      __typename: "ObjectiveDomain";
      id: string;
      key: string;
      display: string;
      excludeFromTPS?: boolean | null;
      sites: Array<string>;
      createdAt?: string | null;
      updatedAt?: string | null;
    };
    objectiveDomainId: string;
    qrCodes?: Array<{
      __typename: "QR";
      type: string;
      imageSrc: string;
      title: string;
      label: string;
      qrdata: string;
    } | null> | null;
    createdAt?: string | null;
    updatedAt?: string | null;
  };
  organizationObjectiveObjectiveId: string;
  enabled?: boolean | null;
  businessObjective?: string | null;
  status?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type DeleteOrganizationObjectiveMutation = {
  __typename: "OrganizationObjective";
  id: string;
  organizationID: string;
  objective: {
    __typename: "Objective";
    id: string;
    display: string;
    definition: string;
    observables: Array<string>;
    hints?: Array<string | null> | null;
    organizationID?: string | null;
    organizations?: Array<string | null> | null;
    status?: string | null;
    domain: {
      __typename: "ObjectiveDomain";
      id: string;
      key: string;
      display: string;
      excludeFromTPS?: boolean | null;
      sites: Array<string>;
      createdAt?: string | null;
      updatedAt?: string | null;
    };
    objectiveDomainId: string;
    qrCodes?: Array<{
      __typename: "QR";
      type: string;
      imageSrc: string;
      title: string;
      label: string;
      qrdata: string;
    } | null> | null;
    createdAt?: string | null;
    updatedAt?: string | null;
  };
  organizationObjectiveObjectiveId: string;
  enabled?: boolean | null;
  businessObjective?: string | null;
  status?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type CreateObjectiveAssignmentMutation = {
  __typename: "ObjectiveAssignment";
  id: string;
  employeeId: string;
  organizationID?: string | null;
  objectiveAssignmentAssignedById?: string | null;
  assignmentDate: string;
  priority?: {
    __typename: "Priority";
    id: string;
    key: string;
    display: string;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null;
  focus?: {
    __typename: "Focus";
    id: string;
    key: string;
    display: string;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null;
  orgObjective?: {
    __typename: "OrganizationObjective";
    id: string;
    organizationID: string;
    objective: {
      __typename: "Objective";
      id: string;
      display: string;
      definition: string;
      observables: Array<string>;
      hints?: Array<string | null> | null;
      organizationID?: string | null;
      organizations?: Array<string | null> | null;
      status?: string | null;
      domain: {
        __typename: "ObjectiveDomain";
        id: string;
        key: string;
        display: string;
        excludeFromTPS?: boolean | null;
        sites: Array<string>;
        createdAt?: string | null;
        updatedAt?: string | null;
      };
      objectiveDomainId: string;
      qrCodes?: Array<{
        __typename: "QR";
        type: string;
        imageSrc: string;
        title: string;
        label: string;
        qrdata: string;
      } | null> | null;
      createdAt?: string | null;
      updatedAt?: string | null;
    };
    organizationObjectiveObjectiveId: string;
    enabled?: boolean | null;
    businessObjective?: string | null;
    status?: string | null;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null;
  startDate?: string | null;
  endDate?: string | null;
  cadence?: string | null;
  managerDescription?: string | null;
  objectiveType?: string | null;
  title?: string | null;
  quantity?: number | null;
  consultantId?: string | null;
  nextSurveyDate?: string | null;
  status?: string | null;
  externalEmail?: string | null;
  externalEmails?: Array<string | null> | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type UpdateObjectiveAssignmentMutation = {
  __typename: "ObjectiveAssignment";
  id: string;
  employeeId: string;
  organizationID?: string | null;
  objectiveAssignmentAssignedById?: string | null;
  assignmentDate: string;
  priority?: {
    __typename: "Priority";
    id: string;
    key: string;
    display: string;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null;
  focus?: {
    __typename: "Focus";
    id: string;
    key: string;
    display: string;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null;
  orgObjective?: {
    __typename: "OrganizationObjective";
    id: string;
    organizationID: string;
    objective: {
      __typename: "Objective";
      id: string;
      display: string;
      definition: string;
      observables: Array<string>;
      hints?: Array<string | null> | null;
      organizationID?: string | null;
      organizations?: Array<string | null> | null;
      status?: string | null;
      domain: {
        __typename: "ObjectiveDomain";
        id: string;
        key: string;
        display: string;
        excludeFromTPS?: boolean | null;
        sites: Array<string>;
        createdAt?: string | null;
        updatedAt?: string | null;
      };
      objectiveDomainId: string;
      qrCodes?: Array<{
        __typename: "QR";
        type: string;
        imageSrc: string;
        title: string;
        label: string;
        qrdata: string;
      } | null> | null;
      createdAt?: string | null;
      updatedAt?: string | null;
    };
    organizationObjectiveObjectiveId: string;
    enabled?: boolean | null;
    businessObjective?: string | null;
    status?: string | null;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null;
  startDate?: string | null;
  endDate?: string | null;
  cadence?: string | null;
  managerDescription?: string | null;
  objectiveType?: string | null;
  title?: string | null;
  quantity?: number | null;
  consultantId?: string | null;
  nextSurveyDate?: string | null;
  status?: string | null;
  externalEmail?: string | null;
  externalEmails?: Array<string | null> | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type DeleteObjectiveAssignmentMutation = {
  __typename: "ObjectiveAssignment";
  id: string;
  employeeId: string;
  organizationID?: string | null;
  objectiveAssignmentAssignedById?: string | null;
  assignmentDate: string;
  priority?: {
    __typename: "Priority";
    id: string;
    key: string;
    display: string;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null;
  focus?: {
    __typename: "Focus";
    id: string;
    key: string;
    display: string;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null;
  orgObjective?: {
    __typename: "OrganizationObjective";
    id: string;
    organizationID: string;
    objective: {
      __typename: "Objective";
      id: string;
      display: string;
      definition: string;
      observables: Array<string>;
      hints?: Array<string | null> | null;
      organizationID?: string | null;
      organizations?: Array<string | null> | null;
      status?: string | null;
      domain: {
        __typename: "ObjectiveDomain";
        id: string;
        key: string;
        display: string;
        excludeFromTPS?: boolean | null;
        sites: Array<string>;
        createdAt?: string | null;
        updatedAt?: string | null;
      };
      objectiveDomainId: string;
      qrCodes?: Array<{
        __typename: "QR";
        type: string;
        imageSrc: string;
        title: string;
        label: string;
        qrdata: string;
      } | null> | null;
      createdAt?: string | null;
      updatedAt?: string | null;
    };
    organizationObjectiveObjectiveId: string;
    enabled?: boolean | null;
    businessObjective?: string | null;
    status?: string | null;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null;
  startDate?: string | null;
  endDate?: string | null;
  cadence?: string | null;
  managerDescription?: string | null;
  objectiveType?: string | null;
  title?: string | null;
  quantity?: number | null;
  consultantId?: string | null;
  nextSurveyDate?: string | null;
  status?: string | null;
  externalEmail?: string | null;
  externalEmails?: Array<string | null> | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type CreateCalendarEventMutation = {
  __typename: "CalendarEvent";
  id: string;
  employeeID: string;
  organizationID: string;
  calendarId: string;
  start: string;
  end: string;
  eventCreated: string;
  eventUpdated?: string | null;
  summary?: string | null;
  description?: string | null;
  status?: string | null;
  private?: boolean | null;
  deleted?: boolean | null;
  recurring?: boolean | null;
  locationDescription?: string | null;
  participationStatus?: string | null;
  eventStatus?: string | null;
  organizerEmail?: string | null;
  organizer?: {
    __typename: "EventAttendee";
    email: string;
    displayName?: string | null;
    status?: string | null;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null;
  attendees?: Array<{
    __typename: "EventAttendee";
    email: string;
    displayName?: string | null;
    status?: string | null;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null> | null;
  attendeeEmails?: Array<string | null> | null;
  meetingURL?: string | null;
  options?: {
    __typename: "EventOptions";
    changeParticipationStatus?: boolean | null;
    delete?: boolean | null;
    update?: boolean | null;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type UpdateCalendarEventMutation = {
  __typename: "CalendarEvent";
  id: string;
  employeeID: string;
  organizationID: string;
  calendarId: string;
  start: string;
  end: string;
  eventCreated: string;
  eventUpdated?: string | null;
  summary?: string | null;
  description?: string | null;
  status?: string | null;
  private?: boolean | null;
  deleted?: boolean | null;
  recurring?: boolean | null;
  locationDescription?: string | null;
  participationStatus?: string | null;
  eventStatus?: string | null;
  organizerEmail?: string | null;
  organizer?: {
    __typename: "EventAttendee";
    email: string;
    displayName?: string | null;
    status?: string | null;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null;
  attendees?: Array<{
    __typename: "EventAttendee";
    email: string;
    displayName?: string | null;
    status?: string | null;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null> | null;
  attendeeEmails?: Array<string | null> | null;
  meetingURL?: string | null;
  options?: {
    __typename: "EventOptions";
    changeParticipationStatus?: boolean | null;
    delete?: boolean | null;
    update?: boolean | null;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type DeleteCalendarEventMutation = {
  __typename: "CalendarEvent";
  id: string;
  employeeID: string;
  organizationID: string;
  calendarId: string;
  start: string;
  end: string;
  eventCreated: string;
  eventUpdated?: string | null;
  summary?: string | null;
  description?: string | null;
  status?: string | null;
  private?: boolean | null;
  deleted?: boolean | null;
  recurring?: boolean | null;
  locationDescription?: string | null;
  participationStatus?: string | null;
  eventStatus?: string | null;
  organizerEmail?: string | null;
  organizer?: {
    __typename: "EventAttendee";
    email: string;
    displayName?: string | null;
    status?: string | null;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null;
  attendees?: Array<{
    __typename: "EventAttendee";
    email: string;
    displayName?: string | null;
    status?: string | null;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null> | null;
  attendeeEmails?: Array<string | null> | null;
  meetingURL?: string | null;
  options?: {
    __typename: "EventOptions";
    changeParticipationStatus?: boolean | null;
    delete?: boolean | null;
    update?: boolean | null;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type CreateSurveyMutation = {
  __typename: "Survey";
  id: string;
  organizationID: string;
  objectID: string;
  surveyType: SurveyType;
  surveyDate: string;
  respondentEmail: string;
  participantEmail: string;
  respondentAttended?: boolean | null;
  surveyResponse?: number | null;
  feedback?: string | null;
  responseReceived?: boolean | null;
  responseReceivedDate?: string | null;
  status?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type UpdateSurveyMutation = {
  __typename: "Survey";
  id: string;
  organizationID: string;
  objectID: string;
  surveyType: SurveyType;
  surveyDate: string;
  respondentEmail: string;
  participantEmail: string;
  respondentAttended?: boolean | null;
  surveyResponse?: number | null;
  feedback?: string | null;
  responseReceived?: boolean | null;
  responseReceivedDate?: string | null;
  status?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type DeleteSurveyMutation = {
  __typename: "Survey";
  id: string;
  organizationID: string;
  objectID: string;
  surveyType: SurveyType;
  surveyDate: string;
  respondentEmail: string;
  participantEmail: string;
  respondentAttended?: boolean | null;
  surveyResponse?: number | null;
  feedback?: string | null;
  responseReceived?: boolean | null;
  responseReceivedDate?: string | null;
  status?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type CreateExternalRespondentMutation = {
  __typename: "ExternalRespondent";
  id: string;
  externalRespondentEmail: string;
  externalRespondentName?: string | null;
  externalRespondentStatus?: string | null;
  statusUpdates?: Array<{
    __typename: "ExternalRespondentStatusChange";
    status: string;
    ipAddress: string;
    externalRespondentStatusDate: string;
  } | null> | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type UpdateExternalRespondentMutation = {
  __typename: "ExternalRespondent";
  id: string;
  externalRespondentEmail: string;
  externalRespondentName?: string | null;
  externalRespondentStatus?: string | null;
  statusUpdates?: Array<{
    __typename: "ExternalRespondentStatusChange";
    status: string;
    ipAddress: string;
    externalRespondentStatusDate: string;
  } | null> | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type DeleteExternalRespondentMutation = {
  __typename: "ExternalRespondent";
  id: string;
  externalRespondentEmail: string;
  externalRespondentName?: string | null;
  externalRespondentStatus?: string | null;
  statusUpdates?: Array<{
    __typename: "ExternalRespondentStatusChange";
    status: string;
    ipAddress: string;
    externalRespondentStatusDate: string;
  } | null> | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type UpdateDoNotDisturbRequestMutation = {
  __typename: "DoNotDisturbRequest";
  id: string;
  externalRespondentID?: string | null;
  externalRespondentEmail: string;
  ipAddress: string;
  status: string;
  createdAt: string;
  updatedAt: string;
};

export type DeleteDoNotDisturbRequestMutation = {
  __typename: "DoNotDisturbRequest";
  id: string;
  externalRespondentID?: string | null;
  externalRespondentEmail: string;
  ipAddress: string;
  status: string;
  createdAt: string;
  updatedAt: string;
};

export type CreateExternalSurveyMutation = {
  __typename: "ExternalSurvey";
  id: string;
  surveyType: SurveyType;
  surveyDate?: string | null;
  externalRespondentID: string;
  participant: {
    __typename: "Participant";
    id: string;
    participantName: string;
  };
  objectiveDetails?: {
    __typename: "ObjectiveDetails";
    id: string;
    objectiveDisplay?: string | null;
    objectiveDefinition?: string | null;
    businessObjective?: string | null;
    managerDescription?: string | null;
    objectiveObservables?: Array<string | null> | null;
  } | null;
  status?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  respondentAttended?: boolean | null;
  surveyResponse?: number | null;
  feedback?: string | null;
  responseReceived?: boolean | null;
  responseReceivedDate?: string | null;
};

export type DeleteExternalSurveyMutation = {
  __typename: "ExternalSurvey";
  id: string;
  surveyType: SurveyType;
  surveyDate?: string | null;
  externalRespondentID: string;
  participant: {
    __typename: "Participant";
    id: string;
    participantName: string;
  };
  objectiveDetails?: {
    __typename: "ObjectiveDetails";
    id: string;
    objectiveDisplay?: string | null;
    objectiveDefinition?: string | null;
    businessObjective?: string | null;
    managerDescription?: string | null;
    objectiveObservables?: Array<string | null> | null;
  } | null;
  status?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  respondentAttended?: boolean | null;
  surveyResponse?: number | null;
  feedback?: string | null;
  responseReceived?: boolean | null;
  responseReceivedDate?: string | null;
};

export type CreateProjectMutation = {
  __typename: "Project";
  id: string;
  organizationID: string;
  title: string;
  description?: string | null;
  startDate: string;
  endDate: string;
  cadence: string;
  projectManagerID: string;
  status?: string | null;
  team?: Array<string | null> | null;
  nextSurveyDate: string;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type UpdateProjectMutation = {
  __typename: "Project";
  id: string;
  organizationID: string;
  title: string;
  description?: string | null;
  startDate: string;
  endDate: string;
  cadence: string;
  projectManagerID: string;
  status?: string | null;
  team?: Array<string | null> | null;
  nextSurveyDate: string;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type DeleteProjectMutation = {
  __typename: "Project";
  id: string;
  organizationID: string;
  title: string;
  description?: string | null;
  startDate: string;
  endDate: string;
  cadence: string;
  projectManagerID: string;
  status?: string | null;
  team?: Array<string | null> | null;
  nextSurveyDate: string;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type CreateMilestoneCommentMutation = {
  __typename: "MilestoneComment";
  id: string;
  organizationID: string;
  milestoneID: string;
  projectMilestoneAssignment?: {
    __typename: "ProjectMilestoneAssignment";
    id: string;
    projectID: string;
    organizationID: string;
    assignedBy: string;
    assignmentDate: string;
    startDate: string;
    endDate: string;
    cadence: string;
    milestoneType: string;
    title: string;
    nextSurveyDate: string;
    description?: string | null;
    team?: Array<string | null> | null;
    comments?: {
      __typename: "ModelMilestoneCommentConnection";
      items: Array<{
        __typename: "MilestoneComment";
        id: string;
        organizationID: string;
        milestoneID: string;
        projectMilestoneAssignment?: {
          __typename: "ProjectMilestoneAssignment";
          id: string;
          projectID: string;
          organizationID: string;
          assignedBy: string;
          assignmentDate: string;
          startDate: string;
          endDate: string;
          cadence: string;
          milestoneType: string;
          title: string;
          nextSurveyDate: string;
          description?: string | null;
          team?: Array<string | null> | null;
          comments?: {
            __typename: "ModelMilestoneCommentConnection";
            items: Array<{
              __typename: "MilestoneComment";
              id: string;
              organizationID: string;
              milestoneID: string;
              projectMilestoneAssignment?: {
                __typename: "ProjectMilestoneAssignment";
                id: string;
                projectID: string;
                organizationID: string;
                assignedBy: string;
                assignmentDate: string;
                startDate: string;
                endDate: string;
                cadence: string;
                milestoneType: string;
                title: string;
                nextSurveyDate: string;
                description?: string | null;
                team?: Array<string | null> | null;
                comments?: {
                  __typename: "ModelMilestoneCommentConnection";
                  items: Array<{
                    __typename: "MilestoneComment";
                    id: string;
                    organizationID: string;
                    milestoneID: string;
                    comment: string;
                    createdAt: string;
                    createdBy: string;
                    updatedAt?: string | null;
                  } | null>;
                  nextToken?: string | null;
                } | null;
                createdAt?: string | null;
                updatedAt?: string | null;
              } | null;
              comment: string;
              createdAt: string;
              createdBy: string;
              updatedAt?: string | null;
            } | null>;
            nextToken?: string | null;
          } | null;
          createdAt?: string | null;
          updatedAt?: string | null;
        } | null;
        comment: string;
        createdAt: string;
        createdBy: string;
        updatedAt?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null;
  comment: string;
  createdAt: string;
  createdBy: string;
  updatedAt?: string | null;
};

export type UpdateMilestoneCommentMutation = {
  __typename: "MilestoneComment";
  id: string;
  organizationID: string;
  milestoneID: string;
  projectMilestoneAssignment?: {
    __typename: "ProjectMilestoneAssignment";
    id: string;
    projectID: string;
    organizationID: string;
    assignedBy: string;
    assignmentDate: string;
    startDate: string;
    endDate: string;
    cadence: string;
    milestoneType: string;
    title: string;
    nextSurveyDate: string;
    description?: string | null;
    team?: Array<string | null> | null;
    comments?: {
      __typename: "ModelMilestoneCommentConnection";
      items: Array<{
        __typename: "MilestoneComment";
        id: string;
        organizationID: string;
        milestoneID: string;
        projectMilestoneAssignment?: {
          __typename: "ProjectMilestoneAssignment";
          id: string;
          projectID: string;
          organizationID: string;
          assignedBy: string;
          assignmentDate: string;
          startDate: string;
          endDate: string;
          cadence: string;
          milestoneType: string;
          title: string;
          nextSurveyDate: string;
          description?: string | null;
          team?: Array<string | null> | null;
          comments?: {
            __typename: "ModelMilestoneCommentConnection";
            items: Array<{
              __typename: "MilestoneComment";
              id: string;
              organizationID: string;
              milestoneID: string;
              projectMilestoneAssignment?: {
                __typename: "ProjectMilestoneAssignment";
                id: string;
                projectID: string;
                organizationID: string;
                assignedBy: string;
                assignmentDate: string;
                startDate: string;
                endDate: string;
                cadence: string;
                milestoneType: string;
                title: string;
                nextSurveyDate: string;
                description?: string | null;
                team?: Array<string | null> | null;
                comments?: {
                  __typename: "ModelMilestoneCommentConnection";
                  items: Array<{
                    __typename: "MilestoneComment";
                    id: string;
                    organizationID: string;
                    milestoneID: string;
                    comment: string;
                    createdAt: string;
                    createdBy: string;
                    updatedAt?: string | null;
                  } | null>;
                  nextToken?: string | null;
                } | null;
                createdAt?: string | null;
                updatedAt?: string | null;
              } | null;
              comment: string;
              createdAt: string;
              createdBy: string;
              updatedAt?: string | null;
            } | null>;
            nextToken?: string | null;
          } | null;
          createdAt?: string | null;
          updatedAt?: string | null;
        } | null;
        comment: string;
        createdAt: string;
        createdBy: string;
        updatedAt?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null;
  comment: string;
  createdAt: string;
  createdBy: string;
  updatedAt?: string | null;
};

export type DeleteMilestoneCommentMutation = {
  __typename: "MilestoneComment";
  id: string;
  organizationID: string;
  milestoneID: string;
  projectMilestoneAssignment?: {
    __typename: "ProjectMilestoneAssignment";
    id: string;
    projectID: string;
    organizationID: string;
    assignedBy: string;
    assignmentDate: string;
    startDate: string;
    endDate: string;
    cadence: string;
    milestoneType: string;
    title: string;
    nextSurveyDate: string;
    description?: string | null;
    team?: Array<string | null> | null;
    comments?: {
      __typename: "ModelMilestoneCommentConnection";
      items: Array<{
        __typename: "MilestoneComment";
        id: string;
        organizationID: string;
        milestoneID: string;
        projectMilestoneAssignment?: {
          __typename: "ProjectMilestoneAssignment";
          id: string;
          projectID: string;
          organizationID: string;
          assignedBy: string;
          assignmentDate: string;
          startDate: string;
          endDate: string;
          cadence: string;
          milestoneType: string;
          title: string;
          nextSurveyDate: string;
          description?: string | null;
          team?: Array<string | null> | null;
          comments?: {
            __typename: "ModelMilestoneCommentConnection";
            items: Array<{
              __typename: "MilestoneComment";
              id: string;
              organizationID: string;
              milestoneID: string;
              projectMilestoneAssignment?: {
                __typename: "ProjectMilestoneAssignment";
                id: string;
                projectID: string;
                organizationID: string;
                assignedBy: string;
                assignmentDate: string;
                startDate: string;
                endDate: string;
                cadence: string;
                milestoneType: string;
                title: string;
                nextSurveyDate: string;
                description?: string | null;
                team?: Array<string | null> | null;
                comments?: {
                  __typename: "ModelMilestoneCommentConnection";
                  items: Array<{
                    __typename: "MilestoneComment";
                    id: string;
                    organizationID: string;
                    milestoneID: string;
                    comment: string;
                    createdAt: string;
                    createdBy: string;
                    updatedAt?: string | null;
                  } | null>;
                  nextToken?: string | null;
                } | null;
                createdAt?: string | null;
                updatedAt?: string | null;
              } | null;
              comment: string;
              createdAt: string;
              createdBy: string;
              updatedAt?: string | null;
            } | null>;
            nextToken?: string | null;
          } | null;
          createdAt?: string | null;
          updatedAt?: string | null;
        } | null;
        comment: string;
        createdAt: string;
        createdBy: string;
        updatedAt?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null;
  comment: string;
  createdAt: string;
  createdBy: string;
  updatedAt?: string | null;
};

export type CreateProjectMilestoneAssignmentMutation = {
  __typename: "ProjectMilestoneAssignment";
  id: string;
  projectID: string;
  organizationID: string;
  assignedBy: string;
  assignmentDate: string;
  startDate: string;
  endDate: string;
  cadence: string;
  milestoneType: string;
  title: string;
  nextSurveyDate: string;
  description?: string | null;
  team?: Array<string | null> | null;
  comments?: {
    __typename: "ModelMilestoneCommentConnection";
    items: Array<{
      __typename: "MilestoneComment";
      id: string;
      organizationID: string;
      milestoneID: string;
      projectMilestoneAssignment?: {
        __typename: "ProjectMilestoneAssignment";
        id: string;
        projectID: string;
        organizationID: string;
        assignedBy: string;
        assignmentDate: string;
        startDate: string;
        endDate: string;
        cadence: string;
        milestoneType: string;
        title: string;
        nextSurveyDate: string;
        description?: string | null;
        team?: Array<string | null> | null;
        comments?: {
          __typename: "ModelMilestoneCommentConnection";
          items: Array<{
            __typename: "MilestoneComment";
            id: string;
            organizationID: string;
            milestoneID: string;
            projectMilestoneAssignment?: {
              __typename: "ProjectMilestoneAssignment";
              id: string;
              projectID: string;
              organizationID: string;
              assignedBy: string;
              assignmentDate: string;
              startDate: string;
              endDate: string;
              cadence: string;
              milestoneType: string;
              title: string;
              nextSurveyDate: string;
              description?: string | null;
              team?: Array<string | null> | null;
              comments?: {
                __typename: "ModelMilestoneCommentConnection";
                items: Array<{
                  __typename: "MilestoneComment";
                  id: string;
                  organizationID: string;
                  milestoneID: string;
                  projectMilestoneAssignment?: {
                    __typename: "ProjectMilestoneAssignment";
                    id: string;
                    projectID: string;
                    organizationID: string;
                    assignedBy: string;
                    assignmentDate: string;
                    startDate: string;
                    endDate: string;
                    cadence: string;
                    milestoneType: string;
                    title: string;
                    nextSurveyDate: string;
                    description?: string | null;
                    team?: Array<string | null> | null;
                    createdAt?: string | null;
                    updatedAt?: string | null;
                  } | null;
                  comment: string;
                  createdAt: string;
                  createdBy: string;
                  updatedAt?: string | null;
                } | null>;
                nextToken?: string | null;
              } | null;
              createdAt?: string | null;
              updatedAt?: string | null;
            } | null;
            comment: string;
            createdAt: string;
            createdBy: string;
            updatedAt?: string | null;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt?: string | null;
        updatedAt?: string | null;
      } | null;
      comment: string;
      createdAt: string;
      createdBy: string;
      updatedAt?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type UpdateProjectMilestoneAssignmentMutation = {
  __typename: "ProjectMilestoneAssignment";
  id: string;
  projectID: string;
  organizationID: string;
  assignedBy: string;
  assignmentDate: string;
  startDate: string;
  endDate: string;
  cadence: string;
  milestoneType: string;
  title: string;
  nextSurveyDate: string;
  description?: string | null;
  team?: Array<string | null> | null;
  comments?: {
    __typename: "ModelMilestoneCommentConnection";
    items: Array<{
      __typename: "MilestoneComment";
      id: string;
      organizationID: string;
      milestoneID: string;
      projectMilestoneAssignment?: {
        __typename: "ProjectMilestoneAssignment";
        id: string;
        projectID: string;
        organizationID: string;
        assignedBy: string;
        assignmentDate: string;
        startDate: string;
        endDate: string;
        cadence: string;
        milestoneType: string;
        title: string;
        nextSurveyDate: string;
        description?: string | null;
        team?: Array<string | null> | null;
        comments?: {
          __typename: "ModelMilestoneCommentConnection";
          items: Array<{
            __typename: "MilestoneComment";
            id: string;
            organizationID: string;
            milestoneID: string;
            projectMilestoneAssignment?: {
              __typename: "ProjectMilestoneAssignment";
              id: string;
              projectID: string;
              organizationID: string;
              assignedBy: string;
              assignmentDate: string;
              startDate: string;
              endDate: string;
              cadence: string;
              milestoneType: string;
              title: string;
              nextSurveyDate: string;
              description?: string | null;
              team?: Array<string | null> | null;
              comments?: {
                __typename: "ModelMilestoneCommentConnection";
                items: Array<{
                  __typename: "MilestoneComment";
                  id: string;
                  organizationID: string;
                  milestoneID: string;
                  projectMilestoneAssignment?: {
                    __typename: "ProjectMilestoneAssignment";
                    id: string;
                    projectID: string;
                    organizationID: string;
                    assignedBy: string;
                    assignmentDate: string;
                    startDate: string;
                    endDate: string;
                    cadence: string;
                    milestoneType: string;
                    title: string;
                    nextSurveyDate: string;
                    description?: string | null;
                    team?: Array<string | null> | null;
                    createdAt?: string | null;
                    updatedAt?: string | null;
                  } | null;
                  comment: string;
                  createdAt: string;
                  createdBy: string;
                  updatedAt?: string | null;
                } | null>;
                nextToken?: string | null;
              } | null;
              createdAt?: string | null;
              updatedAt?: string | null;
            } | null;
            comment: string;
            createdAt: string;
            createdBy: string;
            updatedAt?: string | null;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt?: string | null;
        updatedAt?: string | null;
      } | null;
      comment: string;
      createdAt: string;
      createdBy: string;
      updatedAt?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type DeleteProjectMilestoneAssignmentMutation = {
  __typename: "ProjectMilestoneAssignment";
  id: string;
  projectID: string;
  organizationID: string;
  assignedBy: string;
  assignmentDate: string;
  startDate: string;
  endDate: string;
  cadence: string;
  milestoneType: string;
  title: string;
  nextSurveyDate: string;
  description?: string | null;
  team?: Array<string | null> | null;
  comments?: {
    __typename: "ModelMilestoneCommentConnection";
    items: Array<{
      __typename: "MilestoneComment";
      id: string;
      organizationID: string;
      milestoneID: string;
      projectMilestoneAssignment?: {
        __typename: "ProjectMilestoneAssignment";
        id: string;
        projectID: string;
        organizationID: string;
        assignedBy: string;
        assignmentDate: string;
        startDate: string;
        endDate: string;
        cadence: string;
        milestoneType: string;
        title: string;
        nextSurveyDate: string;
        description?: string | null;
        team?: Array<string | null> | null;
        comments?: {
          __typename: "ModelMilestoneCommentConnection";
          items: Array<{
            __typename: "MilestoneComment";
            id: string;
            organizationID: string;
            milestoneID: string;
            projectMilestoneAssignment?: {
              __typename: "ProjectMilestoneAssignment";
              id: string;
              projectID: string;
              organizationID: string;
              assignedBy: string;
              assignmentDate: string;
              startDate: string;
              endDate: string;
              cadence: string;
              milestoneType: string;
              title: string;
              nextSurveyDate: string;
              description?: string | null;
              team?: Array<string | null> | null;
              comments?: {
                __typename: "ModelMilestoneCommentConnection";
                items: Array<{
                  __typename: "MilestoneComment";
                  id: string;
                  organizationID: string;
                  milestoneID: string;
                  projectMilestoneAssignment?: {
                    __typename: "ProjectMilestoneAssignment";
                    id: string;
                    projectID: string;
                    organizationID: string;
                    assignedBy: string;
                    assignmentDate: string;
                    startDate: string;
                    endDate: string;
                    cadence: string;
                    milestoneType: string;
                    title: string;
                    nextSurveyDate: string;
                    description?: string | null;
                    team?: Array<string | null> | null;
                    createdAt?: string | null;
                    updatedAt?: string | null;
                  } | null;
                  comment: string;
                  createdAt: string;
                  createdBy: string;
                  updatedAt?: string | null;
                } | null>;
                nextToken?: string | null;
              } | null;
              createdAt?: string | null;
              updatedAt?: string | null;
            } | null;
            comment: string;
            createdAt: string;
            createdBy: string;
            updatedAt?: string | null;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt?: string | null;
        updatedAt?: string | null;
      } | null;
      comment: string;
      createdAt: string;
      createdBy: string;
      updatedAt?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type CreateNoteCommentMutation = {
  __typename: "NoteComment";
  id: string;
  organizationID: string;
  noteID: string;
  note?: {
    __typename: "Note";
    id: string;
    organizationID: string;
    objectID: string;
    createdAt: string;
    createdBy: string;
    updatedAt?: string | null;
    updatedBy?: string | null;
    text?: string | null;
    noteType: string;
    options?: string | null;
    comments?: {
      __typename: "ModelNoteCommentConnection";
      items: Array<{
        __typename: "NoteComment";
        id: string;
        organizationID: string;
        noteID: string;
        note?: {
          __typename: "Note";
          id: string;
          organizationID: string;
          objectID: string;
          createdAt: string;
          createdBy: string;
          updatedAt?: string | null;
          updatedBy?: string | null;
          text?: string | null;
          noteType: string;
          options?: string | null;
          comments?: {
            __typename: "ModelNoteCommentConnection";
            items: Array<{
              __typename: "NoteComment";
              id: string;
              organizationID: string;
              noteID: string;
              note?: {
                __typename: "Note";
                id: string;
                organizationID: string;
                objectID: string;
                createdAt: string;
                createdBy: string;
                updatedAt?: string | null;
                updatedBy?: string | null;
                text?: string | null;
                noteType: string;
                options?: string | null;
                comments?: {
                  __typename: "ModelNoteCommentConnection";
                  items: Array<{
                    __typename: "NoteComment";
                    id: string;
                    organizationID: string;
                    noteID: string;
                    comment: string;
                    createdAt: string;
                    createdBy: string;
                    updatedAt?: string | null;
                  } | null>;
                  nextToken?: string | null;
                } | null;
                historys?: {
                  __typename: "ModelNoteHistoryConnection";
                  items: Array<{
                    __typename: "NoteHistory";
                    id: string;
                    organizationID: string;
                    noteID: string;
                    createdAt: string;
                    createdBy: string;
                    text?: string | null;
                    updatedAt?: string | null;
                  } | null>;
                  nextToken?: string | null;
                } | null;
              } | null;
              comment: string;
              createdAt: string;
              createdBy: string;
              updatedAt?: string | null;
            } | null>;
            nextToken?: string | null;
          } | null;
          historys?: {
            __typename: "ModelNoteHistoryConnection";
            items: Array<{
              __typename: "NoteHistory";
              id: string;
              organizationID: string;
              noteID: string;
              note?: {
                __typename: "Note";
                id: string;
                organizationID: string;
                objectID: string;
                createdAt: string;
                createdBy: string;
                updatedAt?: string | null;
                updatedBy?: string | null;
                text?: string | null;
                noteType: string;
                options?: string | null;
                comments?: {
                  __typename: "ModelNoteCommentConnection";
                  items: Array<{
                    __typename: "NoteComment";
                    id: string;
                    organizationID: string;
                    noteID: string;
                    comment: string;
                    createdAt: string;
                    createdBy: string;
                    updatedAt?: string | null;
                  } | null>;
                  nextToken?: string | null;
                } | null;
                historys?: {
                  __typename: "ModelNoteHistoryConnection";
                  items: Array<{
                    __typename: "NoteHistory";
                    id: string;
                    organizationID: string;
                    noteID: string;
                    createdAt: string;
                    createdBy: string;
                    text?: string | null;
                    updatedAt?: string | null;
                  } | null>;
                  nextToken?: string | null;
                } | null;
              } | null;
              createdAt: string;
              createdBy: string;
              text?: string | null;
              updatedAt?: string | null;
            } | null>;
            nextToken?: string | null;
          } | null;
        } | null;
        comment: string;
        createdAt: string;
        createdBy: string;
        updatedAt?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    historys?: {
      __typename: "ModelNoteHistoryConnection";
      items: Array<{
        __typename: "NoteHistory";
        id: string;
        organizationID: string;
        noteID: string;
        note?: {
          __typename: "Note";
          id: string;
          organizationID: string;
          objectID: string;
          createdAt: string;
          createdBy: string;
          updatedAt?: string | null;
          updatedBy?: string | null;
          text?: string | null;
          noteType: string;
          options?: string | null;
          comments?: {
            __typename: "ModelNoteCommentConnection";
            items: Array<{
              __typename: "NoteComment";
              id: string;
              organizationID: string;
              noteID: string;
              note?: {
                __typename: "Note";
                id: string;
                organizationID: string;
                objectID: string;
                createdAt: string;
                createdBy: string;
                updatedAt?: string | null;
                updatedBy?: string | null;
                text?: string | null;
                noteType: string;
                options?: string | null;
                comments?: {
                  __typename: "ModelNoteCommentConnection";
                  items: Array<{
                    __typename: "NoteComment";
                    id: string;
                    organizationID: string;
                    noteID: string;
                    comment: string;
                    createdAt: string;
                    createdBy: string;
                    updatedAt?: string | null;
                  } | null>;
                  nextToken?: string | null;
                } | null;
                historys?: {
                  __typename: "ModelNoteHistoryConnection";
                  items: Array<{
                    __typename: "NoteHistory";
                    id: string;
                    organizationID: string;
                    noteID: string;
                    createdAt: string;
                    createdBy: string;
                    text?: string | null;
                    updatedAt?: string | null;
                  } | null>;
                  nextToken?: string | null;
                } | null;
              } | null;
              comment: string;
              createdAt: string;
              createdBy: string;
              updatedAt?: string | null;
            } | null>;
            nextToken?: string | null;
          } | null;
          historys?: {
            __typename: "ModelNoteHistoryConnection";
            items: Array<{
              __typename: "NoteHistory";
              id: string;
              organizationID: string;
              noteID: string;
              note?: {
                __typename: "Note";
                id: string;
                organizationID: string;
                objectID: string;
                createdAt: string;
                createdBy: string;
                updatedAt?: string | null;
                updatedBy?: string | null;
                text?: string | null;
                noteType: string;
                options?: string | null;
                comments?: {
                  __typename: "ModelNoteCommentConnection";
                  items: Array<{
                    __typename: "NoteComment";
                    id: string;
                    organizationID: string;
                    noteID: string;
                    comment: string;
                    createdAt: string;
                    createdBy: string;
                    updatedAt?: string | null;
                  } | null>;
                  nextToken?: string | null;
                } | null;
                historys?: {
                  __typename: "ModelNoteHistoryConnection";
                  items: Array<{
                    __typename: "NoteHistory";
                    id: string;
                    organizationID: string;
                    noteID: string;
                    createdAt: string;
                    createdBy: string;
                    text?: string | null;
                    updatedAt?: string | null;
                  } | null>;
                  nextToken?: string | null;
                } | null;
              } | null;
              createdAt: string;
              createdBy: string;
              text?: string | null;
              updatedAt?: string | null;
            } | null>;
            nextToken?: string | null;
          } | null;
        } | null;
        createdAt: string;
        createdBy: string;
        text?: string | null;
        updatedAt?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null;
  comment: string;
  createdAt: string;
  createdBy: string;
  updatedAt?: string | null;
};

export type UpdateNoteCommentMutation = {
  __typename: "NoteComment";
  id: string;
  organizationID: string;
  noteID: string;
  note?: {
    __typename: "Note";
    id: string;
    organizationID: string;
    objectID: string;
    createdAt: string;
    createdBy: string;
    updatedAt?: string | null;
    updatedBy?: string | null;
    text?: string | null;
    noteType: string;
    options?: string | null;
    comments?: {
      __typename: "ModelNoteCommentConnection";
      items: Array<{
        __typename: "NoteComment";
        id: string;
        organizationID: string;
        noteID: string;
        note?: {
          __typename: "Note";
          id: string;
          organizationID: string;
          objectID: string;
          createdAt: string;
          createdBy: string;
          updatedAt?: string | null;
          updatedBy?: string | null;
          text?: string | null;
          noteType: string;
          options?: string | null;
          comments?: {
            __typename: "ModelNoteCommentConnection";
            items: Array<{
              __typename: "NoteComment";
              id: string;
              organizationID: string;
              noteID: string;
              note?: {
                __typename: "Note";
                id: string;
                organizationID: string;
                objectID: string;
                createdAt: string;
                createdBy: string;
                updatedAt?: string | null;
                updatedBy?: string | null;
                text?: string | null;
                noteType: string;
                options?: string | null;
                comments?: {
                  __typename: "ModelNoteCommentConnection";
                  items: Array<{
                    __typename: "NoteComment";
                    id: string;
                    organizationID: string;
                    noteID: string;
                    comment: string;
                    createdAt: string;
                    createdBy: string;
                    updatedAt?: string | null;
                  } | null>;
                  nextToken?: string | null;
                } | null;
                historys?: {
                  __typename: "ModelNoteHistoryConnection";
                  items: Array<{
                    __typename: "NoteHistory";
                    id: string;
                    organizationID: string;
                    noteID: string;
                    createdAt: string;
                    createdBy: string;
                    text?: string | null;
                    updatedAt?: string | null;
                  } | null>;
                  nextToken?: string | null;
                } | null;
              } | null;
              comment: string;
              createdAt: string;
              createdBy: string;
              updatedAt?: string | null;
            } | null>;
            nextToken?: string | null;
          } | null;
          historys?: {
            __typename: "ModelNoteHistoryConnection";
            items: Array<{
              __typename: "NoteHistory";
              id: string;
              organizationID: string;
              noteID: string;
              note?: {
                __typename: "Note";
                id: string;
                organizationID: string;
                objectID: string;
                createdAt: string;
                createdBy: string;
                updatedAt?: string | null;
                updatedBy?: string | null;
                text?: string | null;
                noteType: string;
                options?: string | null;
                comments?: {
                  __typename: "ModelNoteCommentConnection";
                  items: Array<{
                    __typename: "NoteComment";
                    id: string;
                    organizationID: string;
                    noteID: string;
                    comment: string;
                    createdAt: string;
                    createdBy: string;
                    updatedAt?: string | null;
                  } | null>;
                  nextToken?: string | null;
                } | null;
                historys?: {
                  __typename: "ModelNoteHistoryConnection";
                  items: Array<{
                    __typename: "NoteHistory";
                    id: string;
                    organizationID: string;
                    noteID: string;
                    createdAt: string;
                    createdBy: string;
                    text?: string | null;
                    updatedAt?: string | null;
                  } | null>;
                  nextToken?: string | null;
                } | null;
              } | null;
              createdAt: string;
              createdBy: string;
              text?: string | null;
              updatedAt?: string | null;
            } | null>;
            nextToken?: string | null;
          } | null;
        } | null;
        comment: string;
        createdAt: string;
        createdBy: string;
        updatedAt?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    historys?: {
      __typename: "ModelNoteHistoryConnection";
      items: Array<{
        __typename: "NoteHistory";
        id: string;
        organizationID: string;
        noteID: string;
        note?: {
          __typename: "Note";
          id: string;
          organizationID: string;
          objectID: string;
          createdAt: string;
          createdBy: string;
          updatedAt?: string | null;
          updatedBy?: string | null;
          text?: string | null;
          noteType: string;
          options?: string | null;
          comments?: {
            __typename: "ModelNoteCommentConnection";
            items: Array<{
              __typename: "NoteComment";
              id: string;
              organizationID: string;
              noteID: string;
              note?: {
                __typename: "Note";
                id: string;
                organizationID: string;
                objectID: string;
                createdAt: string;
                createdBy: string;
                updatedAt?: string | null;
                updatedBy?: string | null;
                text?: string | null;
                noteType: string;
                options?: string | null;
                comments?: {
                  __typename: "ModelNoteCommentConnection";
                  items: Array<{
                    __typename: "NoteComment";
                    id: string;
                    organizationID: string;
                    noteID: string;
                    comment: string;
                    createdAt: string;
                    createdBy: string;
                    updatedAt?: string | null;
                  } | null>;
                  nextToken?: string | null;
                } | null;
                historys?: {
                  __typename: "ModelNoteHistoryConnection";
                  items: Array<{
                    __typename: "NoteHistory";
                    id: string;
                    organizationID: string;
                    noteID: string;
                    createdAt: string;
                    createdBy: string;
                    text?: string | null;
                    updatedAt?: string | null;
                  } | null>;
                  nextToken?: string | null;
                } | null;
              } | null;
              comment: string;
              createdAt: string;
              createdBy: string;
              updatedAt?: string | null;
            } | null>;
            nextToken?: string | null;
          } | null;
          historys?: {
            __typename: "ModelNoteHistoryConnection";
            items: Array<{
              __typename: "NoteHistory";
              id: string;
              organizationID: string;
              noteID: string;
              note?: {
                __typename: "Note";
                id: string;
                organizationID: string;
                objectID: string;
                createdAt: string;
                createdBy: string;
                updatedAt?: string | null;
                updatedBy?: string | null;
                text?: string | null;
                noteType: string;
                options?: string | null;
                comments?: {
                  __typename: "ModelNoteCommentConnection";
                  items: Array<{
                    __typename: "NoteComment";
                    id: string;
                    organizationID: string;
                    noteID: string;
                    comment: string;
                    createdAt: string;
                    createdBy: string;
                    updatedAt?: string | null;
                  } | null>;
                  nextToken?: string | null;
                } | null;
                historys?: {
                  __typename: "ModelNoteHistoryConnection";
                  items: Array<{
                    __typename: "NoteHistory";
                    id: string;
                    organizationID: string;
                    noteID: string;
                    createdAt: string;
                    createdBy: string;
                    text?: string | null;
                    updatedAt?: string | null;
                  } | null>;
                  nextToken?: string | null;
                } | null;
              } | null;
              createdAt: string;
              createdBy: string;
              text?: string | null;
              updatedAt?: string | null;
            } | null>;
            nextToken?: string | null;
          } | null;
        } | null;
        createdAt: string;
        createdBy: string;
        text?: string | null;
        updatedAt?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null;
  comment: string;
  createdAt: string;
  createdBy: string;
  updatedAt?: string | null;
};

export type DeleteNoteCommentMutation = {
  __typename: "NoteComment";
  id: string;
  organizationID: string;
  noteID: string;
  note?: {
    __typename: "Note";
    id: string;
    organizationID: string;
    objectID: string;
    createdAt: string;
    createdBy: string;
    updatedAt?: string | null;
    updatedBy?: string | null;
    text?: string | null;
    noteType: string;
    options?: string | null;
    comments?: {
      __typename: "ModelNoteCommentConnection";
      items: Array<{
        __typename: "NoteComment";
        id: string;
        organizationID: string;
        noteID: string;
        note?: {
          __typename: "Note";
          id: string;
          organizationID: string;
          objectID: string;
          createdAt: string;
          createdBy: string;
          updatedAt?: string | null;
          updatedBy?: string | null;
          text?: string | null;
          noteType: string;
          options?: string | null;
          comments?: {
            __typename: "ModelNoteCommentConnection";
            items: Array<{
              __typename: "NoteComment";
              id: string;
              organizationID: string;
              noteID: string;
              note?: {
                __typename: "Note";
                id: string;
                organizationID: string;
                objectID: string;
                createdAt: string;
                createdBy: string;
                updatedAt?: string | null;
                updatedBy?: string | null;
                text?: string | null;
                noteType: string;
                options?: string | null;
                comments?: {
                  __typename: "ModelNoteCommentConnection";
                  items: Array<{
                    __typename: "NoteComment";
                    id: string;
                    organizationID: string;
                    noteID: string;
                    comment: string;
                    createdAt: string;
                    createdBy: string;
                    updatedAt?: string | null;
                  } | null>;
                  nextToken?: string | null;
                } | null;
                historys?: {
                  __typename: "ModelNoteHistoryConnection";
                  items: Array<{
                    __typename: "NoteHistory";
                    id: string;
                    organizationID: string;
                    noteID: string;
                    createdAt: string;
                    createdBy: string;
                    text?: string | null;
                    updatedAt?: string | null;
                  } | null>;
                  nextToken?: string | null;
                } | null;
              } | null;
              comment: string;
              createdAt: string;
              createdBy: string;
              updatedAt?: string | null;
            } | null>;
            nextToken?: string | null;
          } | null;
          historys?: {
            __typename: "ModelNoteHistoryConnection";
            items: Array<{
              __typename: "NoteHistory";
              id: string;
              organizationID: string;
              noteID: string;
              note?: {
                __typename: "Note";
                id: string;
                organizationID: string;
                objectID: string;
                createdAt: string;
                createdBy: string;
                updatedAt?: string | null;
                updatedBy?: string | null;
                text?: string | null;
                noteType: string;
                options?: string | null;
                comments?: {
                  __typename: "ModelNoteCommentConnection";
                  items: Array<{
                    __typename: "NoteComment";
                    id: string;
                    organizationID: string;
                    noteID: string;
                    comment: string;
                    createdAt: string;
                    createdBy: string;
                    updatedAt?: string | null;
                  } | null>;
                  nextToken?: string | null;
                } | null;
                historys?: {
                  __typename: "ModelNoteHistoryConnection";
                  items: Array<{
                    __typename: "NoteHistory";
                    id: string;
                    organizationID: string;
                    noteID: string;
                    createdAt: string;
                    createdBy: string;
                    text?: string | null;
                    updatedAt?: string | null;
                  } | null>;
                  nextToken?: string | null;
                } | null;
              } | null;
              createdAt: string;
              createdBy: string;
              text?: string | null;
              updatedAt?: string | null;
            } | null>;
            nextToken?: string | null;
          } | null;
        } | null;
        comment: string;
        createdAt: string;
        createdBy: string;
        updatedAt?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    historys?: {
      __typename: "ModelNoteHistoryConnection";
      items: Array<{
        __typename: "NoteHistory";
        id: string;
        organizationID: string;
        noteID: string;
        note?: {
          __typename: "Note";
          id: string;
          organizationID: string;
          objectID: string;
          createdAt: string;
          createdBy: string;
          updatedAt?: string | null;
          updatedBy?: string | null;
          text?: string | null;
          noteType: string;
          options?: string | null;
          comments?: {
            __typename: "ModelNoteCommentConnection";
            items: Array<{
              __typename: "NoteComment";
              id: string;
              organizationID: string;
              noteID: string;
              note?: {
                __typename: "Note";
                id: string;
                organizationID: string;
                objectID: string;
                createdAt: string;
                createdBy: string;
                updatedAt?: string | null;
                updatedBy?: string | null;
                text?: string | null;
                noteType: string;
                options?: string | null;
                comments?: {
                  __typename: "ModelNoteCommentConnection";
                  items: Array<{
                    __typename: "NoteComment";
                    id: string;
                    organizationID: string;
                    noteID: string;
                    comment: string;
                    createdAt: string;
                    createdBy: string;
                    updatedAt?: string | null;
                  } | null>;
                  nextToken?: string | null;
                } | null;
                historys?: {
                  __typename: "ModelNoteHistoryConnection";
                  items: Array<{
                    __typename: "NoteHistory";
                    id: string;
                    organizationID: string;
                    noteID: string;
                    createdAt: string;
                    createdBy: string;
                    text?: string | null;
                    updatedAt?: string | null;
                  } | null>;
                  nextToken?: string | null;
                } | null;
              } | null;
              comment: string;
              createdAt: string;
              createdBy: string;
              updatedAt?: string | null;
            } | null>;
            nextToken?: string | null;
          } | null;
          historys?: {
            __typename: "ModelNoteHistoryConnection";
            items: Array<{
              __typename: "NoteHistory";
              id: string;
              organizationID: string;
              noteID: string;
              note?: {
                __typename: "Note";
                id: string;
                organizationID: string;
                objectID: string;
                createdAt: string;
                createdBy: string;
                updatedAt?: string | null;
                updatedBy?: string | null;
                text?: string | null;
                noteType: string;
                options?: string | null;
                comments?: {
                  __typename: "ModelNoteCommentConnection";
                  items: Array<{
                    __typename: "NoteComment";
                    id: string;
                    organizationID: string;
                    noteID: string;
                    comment: string;
                    createdAt: string;
                    createdBy: string;
                    updatedAt?: string | null;
                  } | null>;
                  nextToken?: string | null;
                } | null;
                historys?: {
                  __typename: "ModelNoteHistoryConnection";
                  items: Array<{
                    __typename: "NoteHistory";
                    id: string;
                    organizationID: string;
                    noteID: string;
                    createdAt: string;
                    createdBy: string;
                    text?: string | null;
                    updatedAt?: string | null;
                  } | null>;
                  nextToken?: string | null;
                } | null;
              } | null;
              createdAt: string;
              createdBy: string;
              text?: string | null;
              updatedAt?: string | null;
            } | null>;
            nextToken?: string | null;
          } | null;
        } | null;
        createdAt: string;
        createdBy: string;
        text?: string | null;
        updatedAt?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null;
  comment: string;
  createdAt: string;
  createdBy: string;
  updatedAt?: string | null;
};

export type CreateNoteMutation = {
  __typename: "Note";
  id: string;
  organizationID: string;
  objectID: string;
  createdAt: string;
  createdBy: string;
  updatedAt?: string | null;
  updatedBy?: string | null;
  text?: string | null;
  noteType: string;
  options?: string | null;
  comments?: {
    __typename: "ModelNoteCommentConnection";
    items: Array<{
      __typename: "NoteComment";
      id: string;
      organizationID: string;
      noteID: string;
      note?: {
        __typename: "Note";
        id: string;
        organizationID: string;
        objectID: string;
        createdAt: string;
        createdBy: string;
        updatedAt?: string | null;
        updatedBy?: string | null;
        text?: string | null;
        noteType: string;
        options?: string | null;
        comments?: {
          __typename: "ModelNoteCommentConnection";
          items: Array<{
            __typename: "NoteComment";
            id: string;
            organizationID: string;
            noteID: string;
            note?: {
              __typename: "Note";
              id: string;
              organizationID: string;
              objectID: string;
              createdAt: string;
              createdBy: string;
              updatedAt?: string | null;
              updatedBy?: string | null;
              text?: string | null;
              noteType: string;
              options?: string | null;
              comments?: {
                __typename: "ModelNoteCommentConnection";
                items: Array<{
                  __typename: "NoteComment";
                  id: string;
                  organizationID: string;
                  noteID: string;
                  note?: {
                    __typename: "Note";
                    id: string;
                    organizationID: string;
                    objectID: string;
                    createdAt: string;
                    createdBy: string;
                    updatedAt?: string | null;
                    updatedBy?: string | null;
                    text?: string | null;
                    noteType: string;
                    options?: string | null;
                  } | null;
                  comment: string;
                  createdAt: string;
                  createdBy: string;
                  updatedAt?: string | null;
                } | null>;
                nextToken?: string | null;
              } | null;
              historys?: {
                __typename: "ModelNoteHistoryConnection";
                items: Array<{
                  __typename: "NoteHistory";
                  id: string;
                  organizationID: string;
                  noteID: string;
                  note?: {
                    __typename: "Note";
                    id: string;
                    organizationID: string;
                    objectID: string;
                    createdAt: string;
                    createdBy: string;
                    updatedAt?: string | null;
                    updatedBy?: string | null;
                    text?: string | null;
                    noteType: string;
                    options?: string | null;
                  } | null;
                  createdAt: string;
                  createdBy: string;
                  text?: string | null;
                  updatedAt?: string | null;
                } | null>;
                nextToken?: string | null;
              } | null;
            } | null;
            comment: string;
            createdAt: string;
            createdBy: string;
            updatedAt?: string | null;
          } | null>;
          nextToken?: string | null;
        } | null;
        historys?: {
          __typename: "ModelNoteHistoryConnection";
          items: Array<{
            __typename: "NoteHistory";
            id: string;
            organizationID: string;
            noteID: string;
            note?: {
              __typename: "Note";
              id: string;
              organizationID: string;
              objectID: string;
              createdAt: string;
              createdBy: string;
              updatedAt?: string | null;
              updatedBy?: string | null;
              text?: string | null;
              noteType: string;
              options?: string | null;
              comments?: {
                __typename: "ModelNoteCommentConnection";
                items: Array<{
                  __typename: "NoteComment";
                  id: string;
                  organizationID: string;
                  noteID: string;
                  note?: {
                    __typename: "Note";
                    id: string;
                    organizationID: string;
                    objectID: string;
                    createdAt: string;
                    createdBy: string;
                    updatedAt?: string | null;
                    updatedBy?: string | null;
                    text?: string | null;
                    noteType: string;
                    options?: string | null;
                  } | null;
                  comment: string;
                  createdAt: string;
                  createdBy: string;
                  updatedAt?: string | null;
                } | null>;
                nextToken?: string | null;
              } | null;
              historys?: {
                __typename: "ModelNoteHistoryConnection";
                items: Array<{
                  __typename: "NoteHistory";
                  id: string;
                  organizationID: string;
                  noteID: string;
                  note?: {
                    __typename: "Note";
                    id: string;
                    organizationID: string;
                    objectID: string;
                    createdAt: string;
                    createdBy: string;
                    updatedAt?: string | null;
                    updatedBy?: string | null;
                    text?: string | null;
                    noteType: string;
                    options?: string | null;
                  } | null;
                  createdAt: string;
                  createdBy: string;
                  text?: string | null;
                  updatedAt?: string | null;
                } | null>;
                nextToken?: string | null;
              } | null;
            } | null;
            createdAt: string;
            createdBy: string;
            text?: string | null;
            updatedAt?: string | null;
          } | null>;
          nextToken?: string | null;
        } | null;
      } | null;
      comment: string;
      createdAt: string;
      createdBy: string;
      updatedAt?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  historys?: {
    __typename: "ModelNoteHistoryConnection";
    items: Array<{
      __typename: "NoteHistory";
      id: string;
      organizationID: string;
      noteID: string;
      note?: {
        __typename: "Note";
        id: string;
        organizationID: string;
        objectID: string;
        createdAt: string;
        createdBy: string;
        updatedAt?: string | null;
        updatedBy?: string | null;
        text?: string | null;
        noteType: string;
        options?: string | null;
        comments?: {
          __typename: "ModelNoteCommentConnection";
          items: Array<{
            __typename: "NoteComment";
            id: string;
            organizationID: string;
            noteID: string;
            note?: {
              __typename: "Note";
              id: string;
              organizationID: string;
              objectID: string;
              createdAt: string;
              createdBy: string;
              updatedAt?: string | null;
              updatedBy?: string | null;
              text?: string | null;
              noteType: string;
              options?: string | null;
              comments?: {
                __typename: "ModelNoteCommentConnection";
                items: Array<{
                  __typename: "NoteComment";
                  id: string;
                  organizationID: string;
                  noteID: string;
                  note?: {
                    __typename: "Note";
                    id: string;
                    organizationID: string;
                    objectID: string;
                    createdAt: string;
                    createdBy: string;
                    updatedAt?: string | null;
                    updatedBy?: string | null;
                    text?: string | null;
                    noteType: string;
                    options?: string | null;
                  } | null;
                  comment: string;
                  createdAt: string;
                  createdBy: string;
                  updatedAt?: string | null;
                } | null>;
                nextToken?: string | null;
              } | null;
              historys?: {
                __typename: "ModelNoteHistoryConnection";
                items: Array<{
                  __typename: "NoteHistory";
                  id: string;
                  organizationID: string;
                  noteID: string;
                  note?: {
                    __typename: "Note";
                    id: string;
                    organizationID: string;
                    objectID: string;
                    createdAt: string;
                    createdBy: string;
                    updatedAt?: string | null;
                    updatedBy?: string | null;
                    text?: string | null;
                    noteType: string;
                    options?: string | null;
                  } | null;
                  createdAt: string;
                  createdBy: string;
                  text?: string | null;
                  updatedAt?: string | null;
                } | null>;
                nextToken?: string | null;
              } | null;
            } | null;
            comment: string;
            createdAt: string;
            createdBy: string;
            updatedAt?: string | null;
          } | null>;
          nextToken?: string | null;
        } | null;
        historys?: {
          __typename: "ModelNoteHistoryConnection";
          items: Array<{
            __typename: "NoteHistory";
            id: string;
            organizationID: string;
            noteID: string;
            note?: {
              __typename: "Note";
              id: string;
              organizationID: string;
              objectID: string;
              createdAt: string;
              createdBy: string;
              updatedAt?: string | null;
              updatedBy?: string | null;
              text?: string | null;
              noteType: string;
              options?: string | null;
              comments?: {
                __typename: "ModelNoteCommentConnection";
                items: Array<{
                  __typename: "NoteComment";
                  id: string;
                  organizationID: string;
                  noteID: string;
                  note?: {
                    __typename: "Note";
                    id: string;
                    organizationID: string;
                    objectID: string;
                    createdAt: string;
                    createdBy: string;
                    updatedAt?: string | null;
                    updatedBy?: string | null;
                    text?: string | null;
                    noteType: string;
                    options?: string | null;
                  } | null;
                  comment: string;
                  createdAt: string;
                  createdBy: string;
                  updatedAt?: string | null;
                } | null>;
                nextToken?: string | null;
              } | null;
              historys?: {
                __typename: "ModelNoteHistoryConnection";
                items: Array<{
                  __typename: "NoteHistory";
                  id: string;
                  organizationID: string;
                  noteID: string;
                  note?: {
                    __typename: "Note";
                    id: string;
                    organizationID: string;
                    objectID: string;
                    createdAt: string;
                    createdBy: string;
                    updatedAt?: string | null;
                    updatedBy?: string | null;
                    text?: string | null;
                    noteType: string;
                    options?: string | null;
                  } | null;
                  createdAt: string;
                  createdBy: string;
                  text?: string | null;
                  updatedAt?: string | null;
                } | null>;
                nextToken?: string | null;
              } | null;
            } | null;
            createdAt: string;
            createdBy: string;
            text?: string | null;
            updatedAt?: string | null;
          } | null>;
          nextToken?: string | null;
        } | null;
      } | null;
      createdAt: string;
      createdBy: string;
      text?: string | null;
      updatedAt?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type UpdateNoteMutation = {
  __typename: "Note";
  id: string;
  organizationID: string;
  objectID: string;
  createdAt: string;
  createdBy: string;
  updatedAt?: string | null;
  updatedBy?: string | null;
  text?: string | null;
  noteType: string;
  options?: string | null;
  comments?: {
    __typename: "ModelNoteCommentConnection";
    items: Array<{
      __typename: "NoteComment";
      id: string;
      organizationID: string;
      noteID: string;
      note?: {
        __typename: "Note";
        id: string;
        organizationID: string;
        objectID: string;
        createdAt: string;
        createdBy: string;
        updatedAt?: string | null;
        updatedBy?: string | null;
        text?: string | null;
        noteType: string;
        options?: string | null;
        comments?: {
          __typename: "ModelNoteCommentConnection";
          items: Array<{
            __typename: "NoteComment";
            id: string;
            organizationID: string;
            noteID: string;
            note?: {
              __typename: "Note";
              id: string;
              organizationID: string;
              objectID: string;
              createdAt: string;
              createdBy: string;
              updatedAt?: string | null;
              updatedBy?: string | null;
              text?: string | null;
              noteType: string;
              options?: string | null;
              comments?: {
                __typename: "ModelNoteCommentConnection";
                items: Array<{
                  __typename: "NoteComment";
                  id: string;
                  organizationID: string;
                  noteID: string;
                  note?: {
                    __typename: "Note";
                    id: string;
                    organizationID: string;
                    objectID: string;
                    createdAt: string;
                    createdBy: string;
                    updatedAt?: string | null;
                    updatedBy?: string | null;
                    text?: string | null;
                    noteType: string;
                    options?: string | null;
                  } | null;
                  comment: string;
                  createdAt: string;
                  createdBy: string;
                  updatedAt?: string | null;
                } | null>;
                nextToken?: string | null;
              } | null;
              historys?: {
                __typename: "ModelNoteHistoryConnection";
                items: Array<{
                  __typename: "NoteHistory";
                  id: string;
                  organizationID: string;
                  noteID: string;
                  note?: {
                    __typename: "Note";
                    id: string;
                    organizationID: string;
                    objectID: string;
                    createdAt: string;
                    createdBy: string;
                    updatedAt?: string | null;
                    updatedBy?: string | null;
                    text?: string | null;
                    noteType: string;
                    options?: string | null;
                  } | null;
                  createdAt: string;
                  createdBy: string;
                  text?: string | null;
                  updatedAt?: string | null;
                } | null>;
                nextToken?: string | null;
              } | null;
            } | null;
            comment: string;
            createdAt: string;
            createdBy: string;
            updatedAt?: string | null;
          } | null>;
          nextToken?: string | null;
        } | null;
        historys?: {
          __typename: "ModelNoteHistoryConnection";
          items: Array<{
            __typename: "NoteHistory";
            id: string;
            organizationID: string;
            noteID: string;
            note?: {
              __typename: "Note";
              id: string;
              organizationID: string;
              objectID: string;
              createdAt: string;
              createdBy: string;
              updatedAt?: string | null;
              updatedBy?: string | null;
              text?: string | null;
              noteType: string;
              options?: string | null;
              comments?: {
                __typename: "ModelNoteCommentConnection";
                items: Array<{
                  __typename: "NoteComment";
                  id: string;
                  organizationID: string;
                  noteID: string;
                  note?: {
                    __typename: "Note";
                    id: string;
                    organizationID: string;
                    objectID: string;
                    createdAt: string;
                    createdBy: string;
                    updatedAt?: string | null;
                    updatedBy?: string | null;
                    text?: string | null;
                    noteType: string;
                    options?: string | null;
                  } | null;
                  comment: string;
                  createdAt: string;
                  createdBy: string;
                  updatedAt?: string | null;
                } | null>;
                nextToken?: string | null;
              } | null;
              historys?: {
                __typename: "ModelNoteHistoryConnection";
                items: Array<{
                  __typename: "NoteHistory";
                  id: string;
                  organizationID: string;
                  noteID: string;
                  note?: {
                    __typename: "Note";
                    id: string;
                    organizationID: string;
                    objectID: string;
                    createdAt: string;
                    createdBy: string;
                    updatedAt?: string | null;
                    updatedBy?: string | null;
                    text?: string | null;
                    noteType: string;
                    options?: string | null;
                  } | null;
                  createdAt: string;
                  createdBy: string;
                  text?: string | null;
                  updatedAt?: string | null;
                } | null>;
                nextToken?: string | null;
              } | null;
            } | null;
            createdAt: string;
            createdBy: string;
            text?: string | null;
            updatedAt?: string | null;
          } | null>;
          nextToken?: string | null;
        } | null;
      } | null;
      comment: string;
      createdAt: string;
      createdBy: string;
      updatedAt?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  historys?: {
    __typename: "ModelNoteHistoryConnection";
    items: Array<{
      __typename: "NoteHistory";
      id: string;
      organizationID: string;
      noteID: string;
      note?: {
        __typename: "Note";
        id: string;
        organizationID: string;
        objectID: string;
        createdAt: string;
        createdBy: string;
        updatedAt?: string | null;
        updatedBy?: string | null;
        text?: string | null;
        noteType: string;
        options?: string | null;
        comments?: {
          __typename: "ModelNoteCommentConnection";
          items: Array<{
            __typename: "NoteComment";
            id: string;
            organizationID: string;
            noteID: string;
            note?: {
              __typename: "Note";
              id: string;
              organizationID: string;
              objectID: string;
              createdAt: string;
              createdBy: string;
              updatedAt?: string | null;
              updatedBy?: string | null;
              text?: string | null;
              noteType: string;
              options?: string | null;
              comments?: {
                __typename: "ModelNoteCommentConnection";
                items: Array<{
                  __typename: "NoteComment";
                  id: string;
                  organizationID: string;
                  noteID: string;
                  note?: {
                    __typename: "Note";
                    id: string;
                    organizationID: string;
                    objectID: string;
                    createdAt: string;
                    createdBy: string;
                    updatedAt?: string | null;
                    updatedBy?: string | null;
                    text?: string | null;
                    noteType: string;
                    options?: string | null;
                  } | null;
                  comment: string;
                  createdAt: string;
                  createdBy: string;
                  updatedAt?: string | null;
                } | null>;
                nextToken?: string | null;
              } | null;
              historys?: {
                __typename: "ModelNoteHistoryConnection";
                items: Array<{
                  __typename: "NoteHistory";
                  id: string;
                  organizationID: string;
                  noteID: string;
                  note?: {
                    __typename: "Note";
                    id: string;
                    organizationID: string;
                    objectID: string;
                    createdAt: string;
                    createdBy: string;
                    updatedAt?: string | null;
                    updatedBy?: string | null;
                    text?: string | null;
                    noteType: string;
                    options?: string | null;
                  } | null;
                  createdAt: string;
                  createdBy: string;
                  text?: string | null;
                  updatedAt?: string | null;
                } | null>;
                nextToken?: string | null;
              } | null;
            } | null;
            comment: string;
            createdAt: string;
            createdBy: string;
            updatedAt?: string | null;
          } | null>;
          nextToken?: string | null;
        } | null;
        historys?: {
          __typename: "ModelNoteHistoryConnection";
          items: Array<{
            __typename: "NoteHistory";
            id: string;
            organizationID: string;
            noteID: string;
            note?: {
              __typename: "Note";
              id: string;
              organizationID: string;
              objectID: string;
              createdAt: string;
              createdBy: string;
              updatedAt?: string | null;
              updatedBy?: string | null;
              text?: string | null;
              noteType: string;
              options?: string | null;
              comments?: {
                __typename: "ModelNoteCommentConnection";
                items: Array<{
                  __typename: "NoteComment";
                  id: string;
                  organizationID: string;
                  noteID: string;
                  note?: {
                    __typename: "Note";
                    id: string;
                    organizationID: string;
                    objectID: string;
                    createdAt: string;
                    createdBy: string;
                    updatedAt?: string | null;
                    updatedBy?: string | null;
                    text?: string | null;
                    noteType: string;
                    options?: string | null;
                  } | null;
                  comment: string;
                  createdAt: string;
                  createdBy: string;
                  updatedAt?: string | null;
                } | null>;
                nextToken?: string | null;
              } | null;
              historys?: {
                __typename: "ModelNoteHistoryConnection";
                items: Array<{
                  __typename: "NoteHistory";
                  id: string;
                  organizationID: string;
                  noteID: string;
                  note?: {
                    __typename: "Note";
                    id: string;
                    organizationID: string;
                    objectID: string;
                    createdAt: string;
                    createdBy: string;
                    updatedAt?: string | null;
                    updatedBy?: string | null;
                    text?: string | null;
                    noteType: string;
                    options?: string | null;
                  } | null;
                  createdAt: string;
                  createdBy: string;
                  text?: string | null;
                  updatedAt?: string | null;
                } | null>;
                nextToken?: string | null;
              } | null;
            } | null;
            createdAt: string;
            createdBy: string;
            text?: string | null;
            updatedAt?: string | null;
          } | null>;
          nextToken?: string | null;
        } | null;
      } | null;
      createdAt: string;
      createdBy: string;
      text?: string | null;
      updatedAt?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type DeleteNoteMutation = {
  __typename: "Note";
  id: string;
  organizationID: string;
  objectID: string;
  createdAt: string;
  createdBy: string;
  updatedAt?: string | null;
  updatedBy?: string | null;
  text?: string | null;
  noteType: string;
  options?: string | null;
  comments?: {
    __typename: "ModelNoteCommentConnection";
    items: Array<{
      __typename: "NoteComment";
      id: string;
      organizationID: string;
      noteID: string;
      note?: {
        __typename: "Note";
        id: string;
        organizationID: string;
        objectID: string;
        createdAt: string;
        createdBy: string;
        updatedAt?: string | null;
        updatedBy?: string | null;
        text?: string | null;
        noteType: string;
        options?: string | null;
        comments?: {
          __typename: "ModelNoteCommentConnection";
          items: Array<{
            __typename: "NoteComment";
            id: string;
            organizationID: string;
            noteID: string;
            note?: {
              __typename: "Note";
              id: string;
              organizationID: string;
              objectID: string;
              createdAt: string;
              createdBy: string;
              updatedAt?: string | null;
              updatedBy?: string | null;
              text?: string | null;
              noteType: string;
              options?: string | null;
              comments?: {
                __typename: "ModelNoteCommentConnection";
                items: Array<{
                  __typename: "NoteComment";
                  id: string;
                  organizationID: string;
                  noteID: string;
                  note?: {
                    __typename: "Note";
                    id: string;
                    organizationID: string;
                    objectID: string;
                    createdAt: string;
                    createdBy: string;
                    updatedAt?: string | null;
                    updatedBy?: string | null;
                    text?: string | null;
                    noteType: string;
                    options?: string | null;
                  } | null;
                  comment: string;
                  createdAt: string;
                  createdBy: string;
                  updatedAt?: string | null;
                } | null>;
                nextToken?: string | null;
              } | null;
              historys?: {
                __typename: "ModelNoteHistoryConnection";
                items: Array<{
                  __typename: "NoteHistory";
                  id: string;
                  organizationID: string;
                  noteID: string;
                  note?: {
                    __typename: "Note";
                    id: string;
                    organizationID: string;
                    objectID: string;
                    createdAt: string;
                    createdBy: string;
                    updatedAt?: string | null;
                    updatedBy?: string | null;
                    text?: string | null;
                    noteType: string;
                    options?: string | null;
                  } | null;
                  createdAt: string;
                  createdBy: string;
                  text?: string | null;
                  updatedAt?: string | null;
                } | null>;
                nextToken?: string | null;
              } | null;
            } | null;
            comment: string;
            createdAt: string;
            createdBy: string;
            updatedAt?: string | null;
          } | null>;
          nextToken?: string | null;
        } | null;
        historys?: {
          __typename: "ModelNoteHistoryConnection";
          items: Array<{
            __typename: "NoteHistory";
            id: string;
            organizationID: string;
            noteID: string;
            note?: {
              __typename: "Note";
              id: string;
              organizationID: string;
              objectID: string;
              createdAt: string;
              createdBy: string;
              updatedAt?: string | null;
              updatedBy?: string | null;
              text?: string | null;
              noteType: string;
              options?: string | null;
              comments?: {
                __typename: "ModelNoteCommentConnection";
                items: Array<{
                  __typename: "NoteComment";
                  id: string;
                  organizationID: string;
                  noteID: string;
                  note?: {
                    __typename: "Note";
                    id: string;
                    organizationID: string;
                    objectID: string;
                    createdAt: string;
                    createdBy: string;
                    updatedAt?: string | null;
                    updatedBy?: string | null;
                    text?: string | null;
                    noteType: string;
                    options?: string | null;
                  } | null;
                  comment: string;
                  createdAt: string;
                  createdBy: string;
                  updatedAt?: string | null;
                } | null>;
                nextToken?: string | null;
              } | null;
              historys?: {
                __typename: "ModelNoteHistoryConnection";
                items: Array<{
                  __typename: "NoteHistory";
                  id: string;
                  organizationID: string;
                  noteID: string;
                  note?: {
                    __typename: "Note";
                    id: string;
                    organizationID: string;
                    objectID: string;
                    createdAt: string;
                    createdBy: string;
                    updatedAt?: string | null;
                    updatedBy?: string | null;
                    text?: string | null;
                    noteType: string;
                    options?: string | null;
                  } | null;
                  createdAt: string;
                  createdBy: string;
                  text?: string | null;
                  updatedAt?: string | null;
                } | null>;
                nextToken?: string | null;
              } | null;
            } | null;
            createdAt: string;
            createdBy: string;
            text?: string | null;
            updatedAt?: string | null;
          } | null>;
          nextToken?: string | null;
        } | null;
      } | null;
      comment: string;
      createdAt: string;
      createdBy: string;
      updatedAt?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  historys?: {
    __typename: "ModelNoteHistoryConnection";
    items: Array<{
      __typename: "NoteHistory";
      id: string;
      organizationID: string;
      noteID: string;
      note?: {
        __typename: "Note";
        id: string;
        organizationID: string;
        objectID: string;
        createdAt: string;
        createdBy: string;
        updatedAt?: string | null;
        updatedBy?: string | null;
        text?: string | null;
        noteType: string;
        options?: string | null;
        comments?: {
          __typename: "ModelNoteCommentConnection";
          items: Array<{
            __typename: "NoteComment";
            id: string;
            organizationID: string;
            noteID: string;
            note?: {
              __typename: "Note";
              id: string;
              organizationID: string;
              objectID: string;
              createdAt: string;
              createdBy: string;
              updatedAt?: string | null;
              updatedBy?: string | null;
              text?: string | null;
              noteType: string;
              options?: string | null;
              comments?: {
                __typename: "ModelNoteCommentConnection";
                items: Array<{
                  __typename: "NoteComment";
                  id: string;
                  organizationID: string;
                  noteID: string;
                  note?: {
                    __typename: "Note";
                    id: string;
                    organizationID: string;
                    objectID: string;
                    createdAt: string;
                    createdBy: string;
                    updatedAt?: string | null;
                    updatedBy?: string | null;
                    text?: string | null;
                    noteType: string;
                    options?: string | null;
                  } | null;
                  comment: string;
                  createdAt: string;
                  createdBy: string;
                  updatedAt?: string | null;
                } | null>;
                nextToken?: string | null;
              } | null;
              historys?: {
                __typename: "ModelNoteHistoryConnection";
                items: Array<{
                  __typename: "NoteHistory";
                  id: string;
                  organizationID: string;
                  noteID: string;
                  note?: {
                    __typename: "Note";
                    id: string;
                    organizationID: string;
                    objectID: string;
                    createdAt: string;
                    createdBy: string;
                    updatedAt?: string | null;
                    updatedBy?: string | null;
                    text?: string | null;
                    noteType: string;
                    options?: string | null;
                  } | null;
                  createdAt: string;
                  createdBy: string;
                  text?: string | null;
                  updatedAt?: string | null;
                } | null>;
                nextToken?: string | null;
              } | null;
            } | null;
            comment: string;
            createdAt: string;
            createdBy: string;
            updatedAt?: string | null;
          } | null>;
          nextToken?: string | null;
        } | null;
        historys?: {
          __typename: "ModelNoteHistoryConnection";
          items: Array<{
            __typename: "NoteHistory";
            id: string;
            organizationID: string;
            noteID: string;
            note?: {
              __typename: "Note";
              id: string;
              organizationID: string;
              objectID: string;
              createdAt: string;
              createdBy: string;
              updatedAt?: string | null;
              updatedBy?: string | null;
              text?: string | null;
              noteType: string;
              options?: string | null;
              comments?: {
                __typename: "ModelNoteCommentConnection";
                items: Array<{
                  __typename: "NoteComment";
                  id: string;
                  organizationID: string;
                  noteID: string;
                  note?: {
                    __typename: "Note";
                    id: string;
                    organizationID: string;
                    objectID: string;
                    createdAt: string;
                    createdBy: string;
                    updatedAt?: string | null;
                    updatedBy?: string | null;
                    text?: string | null;
                    noteType: string;
                    options?: string | null;
                  } | null;
                  comment: string;
                  createdAt: string;
                  createdBy: string;
                  updatedAt?: string | null;
                } | null>;
                nextToken?: string | null;
              } | null;
              historys?: {
                __typename: "ModelNoteHistoryConnection";
                items: Array<{
                  __typename: "NoteHistory";
                  id: string;
                  organizationID: string;
                  noteID: string;
                  note?: {
                    __typename: "Note";
                    id: string;
                    organizationID: string;
                    objectID: string;
                    createdAt: string;
                    createdBy: string;
                    updatedAt?: string | null;
                    updatedBy?: string | null;
                    text?: string | null;
                    noteType: string;
                    options?: string | null;
                  } | null;
                  createdAt: string;
                  createdBy: string;
                  text?: string | null;
                  updatedAt?: string | null;
                } | null>;
                nextToken?: string | null;
              } | null;
            } | null;
            createdAt: string;
            createdBy: string;
            text?: string | null;
            updatedAt?: string | null;
          } | null>;
          nextToken?: string | null;
        } | null;
      } | null;
      createdAt: string;
      createdBy: string;
      text?: string | null;
      updatedAt?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type CreateNoteHistoryMutation = {
  __typename: "NoteHistory";
  id: string;
  organizationID: string;
  noteID: string;
  note?: {
    __typename: "Note";
    id: string;
    organizationID: string;
    objectID: string;
    createdAt: string;
    createdBy: string;
    updatedAt?: string | null;
    updatedBy?: string | null;
    text?: string | null;
    noteType: string;
    options?: string | null;
    comments?: {
      __typename: "ModelNoteCommentConnection";
      items: Array<{
        __typename: "NoteComment";
        id: string;
        organizationID: string;
        noteID: string;
        note?: {
          __typename: "Note";
          id: string;
          organizationID: string;
          objectID: string;
          createdAt: string;
          createdBy: string;
          updatedAt?: string | null;
          updatedBy?: string | null;
          text?: string | null;
          noteType: string;
          options?: string | null;
          comments?: {
            __typename: "ModelNoteCommentConnection";
            items: Array<{
              __typename: "NoteComment";
              id: string;
              organizationID: string;
              noteID: string;
              note?: {
                __typename: "Note";
                id: string;
                organizationID: string;
                objectID: string;
                createdAt: string;
                createdBy: string;
                updatedAt?: string | null;
                updatedBy?: string | null;
                text?: string | null;
                noteType: string;
                options?: string | null;
                comments?: {
                  __typename: "ModelNoteCommentConnection";
                  items: Array<{
                    __typename: "NoteComment";
                    id: string;
                    organizationID: string;
                    noteID: string;
                    comment: string;
                    createdAt: string;
                    createdBy: string;
                    updatedAt?: string | null;
                  } | null>;
                  nextToken?: string | null;
                } | null;
                historys?: {
                  __typename: "ModelNoteHistoryConnection";
                  items: Array<{
                    __typename: "NoteHistory";
                    id: string;
                    organizationID: string;
                    noteID: string;
                    createdAt: string;
                    createdBy: string;
                    text?: string | null;
                    updatedAt?: string | null;
                  } | null>;
                  nextToken?: string | null;
                } | null;
              } | null;
              comment: string;
              createdAt: string;
              createdBy: string;
              updatedAt?: string | null;
            } | null>;
            nextToken?: string | null;
          } | null;
          historys?: {
            __typename: "ModelNoteHistoryConnection";
            items: Array<{
              __typename: "NoteHistory";
              id: string;
              organizationID: string;
              noteID: string;
              note?: {
                __typename: "Note";
                id: string;
                organizationID: string;
                objectID: string;
                createdAt: string;
                createdBy: string;
                updatedAt?: string | null;
                updatedBy?: string | null;
                text?: string | null;
                noteType: string;
                options?: string | null;
                comments?: {
                  __typename: "ModelNoteCommentConnection";
                  items: Array<{
                    __typename: "NoteComment";
                    id: string;
                    organizationID: string;
                    noteID: string;
                    comment: string;
                    createdAt: string;
                    createdBy: string;
                    updatedAt?: string | null;
                  } | null>;
                  nextToken?: string | null;
                } | null;
                historys?: {
                  __typename: "ModelNoteHistoryConnection";
                  items: Array<{
                    __typename: "NoteHistory";
                    id: string;
                    organizationID: string;
                    noteID: string;
                    createdAt: string;
                    createdBy: string;
                    text?: string | null;
                    updatedAt?: string | null;
                  } | null>;
                  nextToken?: string | null;
                } | null;
              } | null;
              createdAt: string;
              createdBy: string;
              text?: string | null;
              updatedAt?: string | null;
            } | null>;
            nextToken?: string | null;
          } | null;
        } | null;
        comment: string;
        createdAt: string;
        createdBy: string;
        updatedAt?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    historys?: {
      __typename: "ModelNoteHistoryConnection";
      items: Array<{
        __typename: "NoteHistory";
        id: string;
        organizationID: string;
        noteID: string;
        note?: {
          __typename: "Note";
          id: string;
          organizationID: string;
          objectID: string;
          createdAt: string;
          createdBy: string;
          updatedAt?: string | null;
          updatedBy?: string | null;
          text?: string | null;
          noteType: string;
          options?: string | null;
          comments?: {
            __typename: "ModelNoteCommentConnection";
            items: Array<{
              __typename: "NoteComment";
              id: string;
              organizationID: string;
              noteID: string;
              note?: {
                __typename: "Note";
                id: string;
                organizationID: string;
                objectID: string;
                createdAt: string;
                createdBy: string;
                updatedAt?: string | null;
                updatedBy?: string | null;
                text?: string | null;
                noteType: string;
                options?: string | null;
                comments?: {
                  __typename: "ModelNoteCommentConnection";
                  items: Array<{
                    __typename: "NoteComment";
                    id: string;
                    organizationID: string;
                    noteID: string;
                    comment: string;
                    createdAt: string;
                    createdBy: string;
                    updatedAt?: string | null;
                  } | null>;
                  nextToken?: string | null;
                } | null;
                historys?: {
                  __typename: "ModelNoteHistoryConnection";
                  items: Array<{
                    __typename: "NoteHistory";
                    id: string;
                    organizationID: string;
                    noteID: string;
                    createdAt: string;
                    createdBy: string;
                    text?: string | null;
                    updatedAt?: string | null;
                  } | null>;
                  nextToken?: string | null;
                } | null;
              } | null;
              comment: string;
              createdAt: string;
              createdBy: string;
              updatedAt?: string | null;
            } | null>;
            nextToken?: string | null;
          } | null;
          historys?: {
            __typename: "ModelNoteHistoryConnection";
            items: Array<{
              __typename: "NoteHistory";
              id: string;
              organizationID: string;
              noteID: string;
              note?: {
                __typename: "Note";
                id: string;
                organizationID: string;
                objectID: string;
                createdAt: string;
                createdBy: string;
                updatedAt?: string | null;
                updatedBy?: string | null;
                text?: string | null;
                noteType: string;
                options?: string | null;
                comments?: {
                  __typename: "ModelNoteCommentConnection";
                  items: Array<{
                    __typename: "NoteComment";
                    id: string;
                    organizationID: string;
                    noteID: string;
                    comment: string;
                    createdAt: string;
                    createdBy: string;
                    updatedAt?: string | null;
                  } | null>;
                  nextToken?: string | null;
                } | null;
                historys?: {
                  __typename: "ModelNoteHistoryConnection";
                  items: Array<{
                    __typename: "NoteHistory";
                    id: string;
                    organizationID: string;
                    noteID: string;
                    createdAt: string;
                    createdBy: string;
                    text?: string | null;
                    updatedAt?: string | null;
                  } | null>;
                  nextToken?: string | null;
                } | null;
              } | null;
              createdAt: string;
              createdBy: string;
              text?: string | null;
              updatedAt?: string | null;
            } | null>;
            nextToken?: string | null;
          } | null;
        } | null;
        createdAt: string;
        createdBy: string;
        text?: string | null;
        updatedAt?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null;
  createdAt: string;
  createdBy: string;
  text?: string | null;
  updatedAt?: string | null;
};

export type UpdateNoteHistoryMutation = {
  __typename: "NoteHistory";
  id: string;
  organizationID: string;
  noteID: string;
  note?: {
    __typename: "Note";
    id: string;
    organizationID: string;
    objectID: string;
    createdAt: string;
    createdBy: string;
    updatedAt?: string | null;
    updatedBy?: string | null;
    text?: string | null;
    noteType: string;
    options?: string | null;
    comments?: {
      __typename: "ModelNoteCommentConnection";
      items: Array<{
        __typename: "NoteComment";
        id: string;
        organizationID: string;
        noteID: string;
        note?: {
          __typename: "Note";
          id: string;
          organizationID: string;
          objectID: string;
          createdAt: string;
          createdBy: string;
          updatedAt?: string | null;
          updatedBy?: string | null;
          text?: string | null;
          noteType: string;
          options?: string | null;
          comments?: {
            __typename: "ModelNoteCommentConnection";
            items: Array<{
              __typename: "NoteComment";
              id: string;
              organizationID: string;
              noteID: string;
              note?: {
                __typename: "Note";
                id: string;
                organizationID: string;
                objectID: string;
                createdAt: string;
                createdBy: string;
                updatedAt?: string | null;
                updatedBy?: string | null;
                text?: string | null;
                noteType: string;
                options?: string | null;
                comments?: {
                  __typename: "ModelNoteCommentConnection";
                  items: Array<{
                    __typename: "NoteComment";
                    id: string;
                    organizationID: string;
                    noteID: string;
                    comment: string;
                    createdAt: string;
                    createdBy: string;
                    updatedAt?: string | null;
                  } | null>;
                  nextToken?: string | null;
                } | null;
                historys?: {
                  __typename: "ModelNoteHistoryConnection";
                  items: Array<{
                    __typename: "NoteHistory";
                    id: string;
                    organizationID: string;
                    noteID: string;
                    createdAt: string;
                    createdBy: string;
                    text?: string | null;
                    updatedAt?: string | null;
                  } | null>;
                  nextToken?: string | null;
                } | null;
              } | null;
              comment: string;
              createdAt: string;
              createdBy: string;
              updatedAt?: string | null;
            } | null>;
            nextToken?: string | null;
          } | null;
          historys?: {
            __typename: "ModelNoteHistoryConnection";
            items: Array<{
              __typename: "NoteHistory";
              id: string;
              organizationID: string;
              noteID: string;
              note?: {
                __typename: "Note";
                id: string;
                organizationID: string;
                objectID: string;
                createdAt: string;
                createdBy: string;
                updatedAt?: string | null;
                updatedBy?: string | null;
                text?: string | null;
                noteType: string;
                options?: string | null;
                comments?: {
                  __typename: "ModelNoteCommentConnection";
                  items: Array<{
                    __typename: "NoteComment";
                    id: string;
                    organizationID: string;
                    noteID: string;
                    comment: string;
                    createdAt: string;
                    createdBy: string;
                    updatedAt?: string | null;
                  } | null>;
                  nextToken?: string | null;
                } | null;
                historys?: {
                  __typename: "ModelNoteHistoryConnection";
                  items: Array<{
                    __typename: "NoteHistory";
                    id: string;
                    organizationID: string;
                    noteID: string;
                    createdAt: string;
                    createdBy: string;
                    text?: string | null;
                    updatedAt?: string | null;
                  } | null>;
                  nextToken?: string | null;
                } | null;
              } | null;
              createdAt: string;
              createdBy: string;
              text?: string | null;
              updatedAt?: string | null;
            } | null>;
            nextToken?: string | null;
          } | null;
        } | null;
        comment: string;
        createdAt: string;
        createdBy: string;
        updatedAt?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    historys?: {
      __typename: "ModelNoteHistoryConnection";
      items: Array<{
        __typename: "NoteHistory";
        id: string;
        organizationID: string;
        noteID: string;
        note?: {
          __typename: "Note";
          id: string;
          organizationID: string;
          objectID: string;
          createdAt: string;
          createdBy: string;
          updatedAt?: string | null;
          updatedBy?: string | null;
          text?: string | null;
          noteType: string;
          options?: string | null;
          comments?: {
            __typename: "ModelNoteCommentConnection";
            items: Array<{
              __typename: "NoteComment";
              id: string;
              organizationID: string;
              noteID: string;
              note?: {
                __typename: "Note";
                id: string;
                organizationID: string;
                objectID: string;
                createdAt: string;
                createdBy: string;
                updatedAt?: string | null;
                updatedBy?: string | null;
                text?: string | null;
                noteType: string;
                options?: string | null;
                comments?: {
                  __typename: "ModelNoteCommentConnection";
                  items: Array<{
                    __typename: "NoteComment";
                    id: string;
                    organizationID: string;
                    noteID: string;
                    comment: string;
                    createdAt: string;
                    createdBy: string;
                    updatedAt?: string | null;
                  } | null>;
                  nextToken?: string | null;
                } | null;
                historys?: {
                  __typename: "ModelNoteHistoryConnection";
                  items: Array<{
                    __typename: "NoteHistory";
                    id: string;
                    organizationID: string;
                    noteID: string;
                    createdAt: string;
                    createdBy: string;
                    text?: string | null;
                    updatedAt?: string | null;
                  } | null>;
                  nextToken?: string | null;
                } | null;
              } | null;
              comment: string;
              createdAt: string;
              createdBy: string;
              updatedAt?: string | null;
            } | null>;
            nextToken?: string | null;
          } | null;
          historys?: {
            __typename: "ModelNoteHistoryConnection";
            items: Array<{
              __typename: "NoteHistory";
              id: string;
              organizationID: string;
              noteID: string;
              note?: {
                __typename: "Note";
                id: string;
                organizationID: string;
                objectID: string;
                createdAt: string;
                createdBy: string;
                updatedAt?: string | null;
                updatedBy?: string | null;
                text?: string | null;
                noteType: string;
                options?: string | null;
                comments?: {
                  __typename: "ModelNoteCommentConnection";
                  items: Array<{
                    __typename: "NoteComment";
                    id: string;
                    organizationID: string;
                    noteID: string;
                    comment: string;
                    createdAt: string;
                    createdBy: string;
                    updatedAt?: string | null;
                  } | null>;
                  nextToken?: string | null;
                } | null;
                historys?: {
                  __typename: "ModelNoteHistoryConnection";
                  items: Array<{
                    __typename: "NoteHistory";
                    id: string;
                    organizationID: string;
                    noteID: string;
                    createdAt: string;
                    createdBy: string;
                    text?: string | null;
                    updatedAt?: string | null;
                  } | null>;
                  nextToken?: string | null;
                } | null;
              } | null;
              createdAt: string;
              createdBy: string;
              text?: string | null;
              updatedAt?: string | null;
            } | null>;
            nextToken?: string | null;
          } | null;
        } | null;
        createdAt: string;
        createdBy: string;
        text?: string | null;
        updatedAt?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null;
  createdAt: string;
  createdBy: string;
  text?: string | null;
  updatedAt?: string | null;
};

export type DeleteNoteHistoryMutation = {
  __typename: "NoteHistory";
  id: string;
  organizationID: string;
  noteID: string;
  note?: {
    __typename: "Note";
    id: string;
    organizationID: string;
    objectID: string;
    createdAt: string;
    createdBy: string;
    updatedAt?: string | null;
    updatedBy?: string | null;
    text?: string | null;
    noteType: string;
    options?: string | null;
    comments?: {
      __typename: "ModelNoteCommentConnection";
      items: Array<{
        __typename: "NoteComment";
        id: string;
        organizationID: string;
        noteID: string;
        note?: {
          __typename: "Note";
          id: string;
          organizationID: string;
          objectID: string;
          createdAt: string;
          createdBy: string;
          updatedAt?: string | null;
          updatedBy?: string | null;
          text?: string | null;
          noteType: string;
          options?: string | null;
          comments?: {
            __typename: "ModelNoteCommentConnection";
            items: Array<{
              __typename: "NoteComment";
              id: string;
              organizationID: string;
              noteID: string;
              note?: {
                __typename: "Note";
                id: string;
                organizationID: string;
                objectID: string;
                createdAt: string;
                createdBy: string;
                updatedAt?: string | null;
                updatedBy?: string | null;
                text?: string | null;
                noteType: string;
                options?: string | null;
                comments?: {
                  __typename: "ModelNoteCommentConnection";
                  items: Array<{
                    __typename: "NoteComment";
                    id: string;
                    organizationID: string;
                    noteID: string;
                    comment: string;
                    createdAt: string;
                    createdBy: string;
                    updatedAt?: string | null;
                  } | null>;
                  nextToken?: string | null;
                } | null;
                historys?: {
                  __typename: "ModelNoteHistoryConnection";
                  items: Array<{
                    __typename: "NoteHistory";
                    id: string;
                    organizationID: string;
                    noteID: string;
                    createdAt: string;
                    createdBy: string;
                    text?: string | null;
                    updatedAt?: string | null;
                  } | null>;
                  nextToken?: string | null;
                } | null;
              } | null;
              comment: string;
              createdAt: string;
              createdBy: string;
              updatedAt?: string | null;
            } | null>;
            nextToken?: string | null;
          } | null;
          historys?: {
            __typename: "ModelNoteHistoryConnection";
            items: Array<{
              __typename: "NoteHistory";
              id: string;
              organizationID: string;
              noteID: string;
              note?: {
                __typename: "Note";
                id: string;
                organizationID: string;
                objectID: string;
                createdAt: string;
                createdBy: string;
                updatedAt?: string | null;
                updatedBy?: string | null;
                text?: string | null;
                noteType: string;
                options?: string | null;
                comments?: {
                  __typename: "ModelNoteCommentConnection";
                  items: Array<{
                    __typename: "NoteComment";
                    id: string;
                    organizationID: string;
                    noteID: string;
                    comment: string;
                    createdAt: string;
                    createdBy: string;
                    updatedAt?: string | null;
                  } | null>;
                  nextToken?: string | null;
                } | null;
                historys?: {
                  __typename: "ModelNoteHistoryConnection";
                  items: Array<{
                    __typename: "NoteHistory";
                    id: string;
                    organizationID: string;
                    noteID: string;
                    createdAt: string;
                    createdBy: string;
                    text?: string | null;
                    updatedAt?: string | null;
                  } | null>;
                  nextToken?: string | null;
                } | null;
              } | null;
              createdAt: string;
              createdBy: string;
              text?: string | null;
              updatedAt?: string | null;
            } | null>;
            nextToken?: string | null;
          } | null;
        } | null;
        comment: string;
        createdAt: string;
        createdBy: string;
        updatedAt?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    historys?: {
      __typename: "ModelNoteHistoryConnection";
      items: Array<{
        __typename: "NoteHistory";
        id: string;
        organizationID: string;
        noteID: string;
        note?: {
          __typename: "Note";
          id: string;
          organizationID: string;
          objectID: string;
          createdAt: string;
          createdBy: string;
          updatedAt?: string | null;
          updatedBy?: string | null;
          text?: string | null;
          noteType: string;
          options?: string | null;
          comments?: {
            __typename: "ModelNoteCommentConnection";
            items: Array<{
              __typename: "NoteComment";
              id: string;
              organizationID: string;
              noteID: string;
              note?: {
                __typename: "Note";
                id: string;
                organizationID: string;
                objectID: string;
                createdAt: string;
                createdBy: string;
                updatedAt?: string | null;
                updatedBy?: string | null;
                text?: string | null;
                noteType: string;
                options?: string | null;
                comments?: {
                  __typename: "ModelNoteCommentConnection";
                  items: Array<{
                    __typename: "NoteComment";
                    id: string;
                    organizationID: string;
                    noteID: string;
                    comment: string;
                    createdAt: string;
                    createdBy: string;
                    updatedAt?: string | null;
                  } | null>;
                  nextToken?: string | null;
                } | null;
                historys?: {
                  __typename: "ModelNoteHistoryConnection";
                  items: Array<{
                    __typename: "NoteHistory";
                    id: string;
                    organizationID: string;
                    noteID: string;
                    createdAt: string;
                    createdBy: string;
                    text?: string | null;
                    updatedAt?: string | null;
                  } | null>;
                  nextToken?: string | null;
                } | null;
              } | null;
              comment: string;
              createdAt: string;
              createdBy: string;
              updatedAt?: string | null;
            } | null>;
            nextToken?: string | null;
          } | null;
          historys?: {
            __typename: "ModelNoteHistoryConnection";
            items: Array<{
              __typename: "NoteHistory";
              id: string;
              organizationID: string;
              noteID: string;
              note?: {
                __typename: "Note";
                id: string;
                organizationID: string;
                objectID: string;
                createdAt: string;
                createdBy: string;
                updatedAt?: string | null;
                updatedBy?: string | null;
                text?: string | null;
                noteType: string;
                options?: string | null;
                comments?: {
                  __typename: "ModelNoteCommentConnection";
                  items: Array<{
                    __typename: "NoteComment";
                    id: string;
                    organizationID: string;
                    noteID: string;
                    comment: string;
                    createdAt: string;
                    createdBy: string;
                    updatedAt?: string | null;
                  } | null>;
                  nextToken?: string | null;
                } | null;
                historys?: {
                  __typename: "ModelNoteHistoryConnection";
                  items: Array<{
                    __typename: "NoteHistory";
                    id: string;
                    organizationID: string;
                    noteID: string;
                    createdAt: string;
                    createdBy: string;
                    text?: string | null;
                    updatedAt?: string | null;
                  } | null>;
                  nextToken?: string | null;
                } | null;
              } | null;
              createdAt: string;
              createdBy: string;
              text?: string | null;
              updatedAt?: string | null;
            } | null>;
            nextToken?: string | null;
          } | null;
        } | null;
        createdAt: string;
        createdBy: string;
        text?: string | null;
        updatedAt?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null;
  createdAt: string;
  createdBy: string;
  text?: string | null;
  updatedAt?: string | null;
};

export type CreateShoutOutTypeMutation = {
  __typename: "ShoutOutType";
  id: string;
  title: string;
  formalDescription?: string | null;
  informalDescription?: string | null;
  icon?: string | null;
  attributes: Array<string | null>;
  status?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type UpdateShoutOutTypeMutation = {
  __typename: "ShoutOutType";
  id: string;
  title: string;
  formalDescription?: string | null;
  informalDescription?: string | null;
  icon?: string | null;
  attributes: Array<string | null>;
  status?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type DeleteShoutOutTypeMutation = {
  __typename: "ShoutOutType";
  id: string;
  title: string;
  formalDescription?: string | null;
  informalDescription?: string | null;
  icon?: string | null;
  attributes: Array<string | null>;
  status?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type CreateOrganizationShoutOutTypeMutation = {
  __typename: "OrganizationShoutOutType";
  id: string;
  organizationID: string;
  overrideTitle: string;
  organizationShoutOutTypeShoutOuTypeId: string;
  shoutOutType: {
    __typename: "ShoutOutType";
    id: string;
    title: string;
    formalDescription?: string | null;
    informalDescription?: string | null;
    icon?: string | null;
    attributes: Array<string | null>;
    status?: string | null;
    createdAt?: string | null;
    updatedAt?: string | null;
  };
  description: string;
  overrideIcon?: string | null;
  overrideIconIdentityId?: string | null;
  enabled?: boolean | null;
  organizationAttributes?: Array<string | null> | null;
  disabledAttributes?: Array<number | null> | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type UpdateOrganizationShoutOutTypeMutation = {
  __typename: "OrganizationShoutOutType";
  id: string;
  organizationID: string;
  overrideTitle: string;
  organizationShoutOutTypeShoutOuTypeId: string;
  shoutOutType: {
    __typename: "ShoutOutType";
    id: string;
    title: string;
    formalDescription?: string | null;
    informalDescription?: string | null;
    icon?: string | null;
    attributes: Array<string | null>;
    status?: string | null;
    createdAt?: string | null;
    updatedAt?: string | null;
  };
  description: string;
  overrideIcon?: string | null;
  overrideIconIdentityId?: string | null;
  enabled?: boolean | null;
  organizationAttributes?: Array<string | null> | null;
  disabledAttributes?: Array<number | null> | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type DeleteOrganizationShoutOutTypeMutation = {
  __typename: "OrganizationShoutOutType";
  id: string;
  organizationID: string;
  overrideTitle: string;
  organizationShoutOutTypeShoutOuTypeId: string;
  shoutOutType: {
    __typename: "ShoutOutType";
    id: string;
    title: string;
    formalDescription?: string | null;
    informalDescription?: string | null;
    icon?: string | null;
    attributes: Array<string | null>;
    status?: string | null;
    createdAt?: string | null;
    updatedAt?: string | null;
  };
  description: string;
  overrideIcon?: string | null;
  overrideIconIdentityId?: string | null;
  enabled?: boolean | null;
  organizationAttributes?: Array<string | null> | null;
  disabledAttributes?: Array<number | null> | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type CreateStandupSurveyConfigurationMutation = {
  __typename: "StandupSurveyConfiguration";
  id: string;
  organizationID: string;
  employeeID: string;
  questions: Array<{
    __typename: "VersionedQuestion";
    id: string;
    questionTextArray: Array<string | null>;
    enabled?: boolean | null;
  } | null>;
  shared?: Array<string | null> | null;
  excluded?: Array<string | null> | null;
  frequency: string;
  lastSurveyedDate?: string | null;
  isoWeekday?: number | null;
  status?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type UpdateStandupSurveyConfigurationMutation = {
  __typename: "StandupSurveyConfiguration";
  id: string;
  organizationID: string;
  employeeID: string;
  questions: Array<{
    __typename: "VersionedQuestion";
    id: string;
    questionTextArray: Array<string | null>;
    enabled?: boolean | null;
  } | null>;
  shared?: Array<string | null> | null;
  excluded?: Array<string | null> | null;
  frequency: string;
  lastSurveyedDate?: string | null;
  isoWeekday?: number | null;
  status?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type DeleteStandupSurveyConfigurationMutation = {
  __typename: "StandupSurveyConfiguration";
  id: string;
  organizationID: string;
  employeeID: string;
  questions: Array<{
    __typename: "VersionedQuestion";
    id: string;
    questionTextArray: Array<string | null>;
    enabled?: boolean | null;
  } | null>;
  shared?: Array<string | null> | null;
  excluded?: Array<string | null> | null;
  frequency: string;
  lastSurveyedDate?: string | null;
  isoWeekday?: number | null;
  status?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type CreateStandupSurveyMutation = {
  __typename: "StandupSurvey";
  id: string;
  organizationID: string;
  standupSurveyConfigurationID: string;
  questionMaps: Array<{
    __typename: "QuestionMap";
    id: string;
    version: number;
  } | null>;
  respondentEmail: string;
  respondentID: string;
  surveyDate: string;
  feedbackArray?: Array<string | null> | null;
  chatRequest?: boolean | null;
  meetingRequest?: boolean | null;
  responseReceived?: boolean | null;
  responseReceivedDate?: string | null;
  status?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type UpdateStandupSurveyMutation = {
  __typename: "StandupSurvey";
  id: string;
  organizationID: string;
  standupSurveyConfigurationID: string;
  questionMaps: Array<{
    __typename: "QuestionMap";
    id: string;
    version: number;
  } | null>;
  respondentEmail: string;
  respondentID: string;
  surveyDate: string;
  feedbackArray?: Array<string | null> | null;
  chatRequest?: boolean | null;
  meetingRequest?: boolean | null;
  responseReceived?: boolean | null;
  responseReceivedDate?: string | null;
  status?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type DeleteStandupSurveyMutation = {
  __typename: "StandupSurvey";
  id: string;
  organizationID: string;
  standupSurveyConfigurationID: string;
  questionMaps: Array<{
    __typename: "QuestionMap";
    id: string;
    version: number;
  } | null>;
  respondentEmail: string;
  respondentID: string;
  surveyDate: string;
  feedbackArray?: Array<string | null> | null;
  chatRequest?: boolean | null;
  meetingRequest?: boolean | null;
  responseReceived?: boolean | null;
  responseReceivedDate?: string | null;
  status?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type CreateDoNotDisturbRequestMutation = {
  __typename: "DoNotDisturbRequest";
  id: string;
  externalRespondentID?: string | null;
  externalRespondentEmail: string;
  ipAddress: string;
  status: string;
  createdAt: string;
  updatedAt: string;
};

export type UpdateExternalSurveyMutation = {
  __typename: "ExternalSurvey";
  id: string;
  surveyType: SurveyType;
  surveyDate?: string | null;
  externalRespondentID: string;
  participant: {
    __typename: "Participant";
    id: string;
    participantName: string;
  };
  objectiveDetails?: {
    __typename: "ObjectiveDetails";
    id: string;
    objectiveDisplay?: string | null;
    objectiveDefinition?: string | null;
    businessObjective?: string | null;
    managerDescription?: string | null;
    objectiveObservables?: Array<string | null> | null;
  } | null;
  status?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  respondentAttended?: boolean | null;
  surveyResponse?: number | null;
  feedback?: string | null;
  responseReceived?: boolean | null;
  responseReceivedDate?: string | null;
};

export type GetOrganizationQuery = {
  __typename: "Organization";
  id: string;
  orgName: string;
  domains: Array<string>;
  site: string;
  disabled?: boolean | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type ListOrganizationsQuery = {
  __typename: "ModelOrganizationConnection";
  items: Array<{
    __typename: "Organization";
    id: string;
    orgName: string;
    domains: Array<string>;
    site: string;
    disabled?: boolean | null;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null>;
  nextToken?: string | null;
};

export type GetStripeQuery = {
  __typename: "Stripe";
  organizationID: string;
  customerID?: string | null;
  subscriptionID?: string | null;
  subscriptionItemID?: string | null;
  planID?: string | null;
  billingName?: string | null;
  billingEmail?: string | null;
  cardToken?: string | null;
  subscriptionStatus?: string | null;
  hmac?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type ListStripesQuery = {
  __typename: "ModelStripeConnection";
  items: Array<{
    __typename: "Stripe";
    organizationID: string;
    customerID?: string | null;
    subscriptionID?: string | null;
    subscriptionItemID?: string | null;
    planID?: string | null;
    billingName?: string | null;
    billingEmail?: string | null;
    cardToken?: string | null;
    subscriptionStatus?: string | null;
    hmac?: string | null;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null>;
  nextToken?: string | null;
};

export type GetProcessLogQuery = {
  __typename: "ProcessLog";
  process: string;
  lastRunTime: string;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type ListProcessLogsQuery = {
  __typename: "ModelProcessLogConnection";
  items: Array<{
    __typename: "ProcessLog";
    process: string;
    lastRunTime: string;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null>;
  nextToken?: string | null;
};

export type GetOrganizationConfigurationQuery = {
  __typename: "OrganizationConfiguration";
  id: string;
  organizationID: string;
  configCode: string;
  configStringValue?: string | null;
  configIntValue?: number | null;
  configBooleanValue?: boolean | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type ListOrganizationConfigurationsQuery = {
  __typename: "ModelOrganizationConfigurationConnection";
  items: Array<{
    __typename: "OrganizationConfiguration";
    id: string;
    organizationID: string;
    configCode: string;
    configStringValue?: string | null;
    configIntValue?: number | null;
    configBooleanValue?: boolean | null;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null>;
  nextToken?: string | null;
};

export type GetEmployeeQuery = {
  __typename: "Employee";
  id: string;
  authId?: string | null;
  email: string;
  orgId: string;
  groups?: Array<string | null> | null;
  consulting?: Array<string | null> | null;
  managerID?: string | null;
  subordinates: Array<string | null>;
  roles?: Array<string | null> | null;
  firstName: string;
  lastName: string;
  title: string;
  department?: string | null;
  disabled?: boolean | null;
  disabledDate?: string | null;
  disabledBy?: string | null;
  hireDate?: string | null;
  managerChangeDate?: string | null;
  lastAnnual?: string | null;
  lastOneOnOne?: string | null;
  positionDate?: string | null;
  previousPositionStartDate?: string | null;
  previousPositionTitle?: string | null;
  validEmailToken?: boolean | null;
  inviteSent?: string | null;
  readiness?: string | null;
  criticalPosition?: boolean | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  readinessAssessmentDate?: string | null;
  image?: string | null;
  imageIdentityId?: string | null;
  bio?: string | null;
  careerGoals?: string | null;
  relocate?: string | null;
  relocateDate?: string | null;
  workStatus?: string | null;
  workStatusDate?: string | null;
};

export type ListEmployeesQuery = {
  __typename: "ModelEmployeeConnection";
  items: Array<{
    __typename: "Employee";
    id: string;
    authId?: string | null;
    email: string;
    orgId: string;
    groups?: Array<string | null> | null;
    consulting?: Array<string | null> | null;
    managerID?: string | null;
    subordinates: Array<string | null>;
    roles?: Array<string | null> | null;
    firstName: string;
    lastName: string;
    title: string;
    department?: string | null;
    disabled?: boolean | null;
    disabledDate?: string | null;
    disabledBy?: string | null;
    hireDate?: string | null;
    managerChangeDate?: string | null;
    lastAnnual?: string | null;
    lastOneOnOne?: string | null;
    positionDate?: string | null;
    previousPositionStartDate?: string | null;
    previousPositionTitle?: string | null;
    validEmailToken?: boolean | null;
    inviteSent?: string | null;
    readiness?: string | null;
    criticalPosition?: boolean | null;
    createdAt?: string | null;
    updatedAt?: string | null;
    readinessAssessmentDate?: string | null;
    image?: string | null;
    imageIdentityId?: string | null;
    bio?: string | null;
    careerGoals?: string | null;
    relocate?: string | null;
    relocateDate?: string | null;
    workStatus?: string | null;
    workStatusDate?: string | null;
  } | null>;
  nextToken?: string | null;
};

export type GetEmployeeDataQuery = {
  __typename: "EmployeeData";
  employeeID: string;
  organizationID: string;
  dataCode: string;
  groups?: Array<string | null> | null;
  stringValue?: string | null;
  intValue?: number | null;
  booleanValue?: boolean | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type ListEmployeeDatasQuery = {
  __typename: "ModelEmployeeDataConnection";
  items: Array<{
    __typename: "EmployeeData";
    employeeID: string;
    organizationID: string;
    dataCode: string;
    groups?: Array<string | null> | null;
    stringValue?: string | null;
    intValue?: number | null;
    booleanValue?: boolean | null;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null>;
  nextToken?: string | null;
};

export type GetReadinessHistoryQuery = {
  __typename: "ReadinessHistory";
  id: string;
  organizationID: string;
  groups: Array<string | null>;
  employeeID: string;
  readiness: string;
  comments?: string | null;
  opportunities?: Array<string | null> | null;
  createdAt: string;
  createdBy?: string | null;
  updatedAt?: string | null;
};

export type ListReadinessHistorysQuery = {
  __typename: "ModelReadinessHistoryConnection";
  items: Array<{
    __typename: "ReadinessHistory";
    id: string;
    organizationID: string;
    groups: Array<string | null>;
    employeeID: string;
    readiness: string;
    comments?: string | null;
    opportunities?: Array<string | null> | null;
    createdAt: string;
    createdBy?: string | null;
    updatedAt?: string | null;
  } | null>;
  nextToken?: string | null;
};

export type GetConsultantAssociationQuery = {
  __typename: "ConsultantAssociation";
  employeeID: string;
  organizationID: string;
  incentive?: boolean | null;
  status?: string | null;
  options?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type ListConsultantAssociationsQuery = {
  __typename: "ModelConsultantAssociationConnection";
  items: Array<{
    __typename: "ConsultantAssociation";
    employeeID: string;
    organizationID: string;
    incentive?: boolean | null;
    status?: string | null;
    options?: string | null;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null>;
  nextToken?: string | null;
};

export type GetConsultantFavoritesQuery = {
  __typename: "ConsultantFavorites";
  employeeID: string;
  organizationID: string;
  favorites?: Array<string | null> | null;
  createdAt: string;
  updatedAt: string;
};

export type ListConsultantFavoritessQuery = {
  __typename: "ModelConsultantFavoritesConnection";
  items: Array<{
    __typename: "ConsultantFavorites";
    employeeID: string;
    organizationID: string;
    favorites?: Array<string | null> | null;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type GetUserActionQuery = {
  __typename: "UserAction";
  employeeID: string;
  organizationID: string;
  action: string;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type ListUserActionsQuery = {
  __typename: "ModelUserActionConnection";
  items: Array<{
    __typename: "UserAction";
    employeeID: string;
    organizationID: string;
    action: string;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null>;
  nextToken?: string | null;
};

export type GetEmployeeCalendarCodeQuery = {
  __typename: "EmployeeCalendarCode";
  employeeID: string;
  code: string;
  redirectUrl: string;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type ListEmployeeCalendarCodesQuery = {
  __typename: "ModelEmployeeCalendarCodeConnection";
  items: Array<{
    __typename: "EmployeeCalendarCode";
    employeeID: string;
    code: string;
    redirectUrl: string;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null>;
  nextToken?: string | null;
};

export type GetEmployeeScoreQuery = {
  __typename: "EmployeeScore";
  id: string;
  employeeID: string;
  organizationID: string;
  score: number;
  scoreType: string;
  scoreID: string;
  scoreStart?: string | null;
  scoreEnd?: string | null;
  specifier?: string | null;
  details?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type ListEmployeeScoresQuery = {
  __typename: "ModelEmployeeScoreConnection";
  items: Array<{
    __typename: "EmployeeScore";
    id: string;
    employeeID: string;
    organizationID: string;
    score: number;
    scoreType: string;
    scoreID: string;
    scoreStart?: string | null;
    scoreEnd?: string | null;
    specifier?: string | null;
    details?: string | null;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null>;
  nextToken?: string | null;
};

export type GetInspHRationsResponseCounterQuery = {
  __typename: "InspHRationsResponseCounter";
  organizationID: string;
  employeeID: string;
  organizationShoutOutTypeID: string;
  type: InspHRationCounterType;
  group: string;
  count: number;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type ListInspHRationsResponseCountersQuery = {
  __typename: "ModelInspHRationsResponseCounterConnection";
  items: Array<{
    __typename: "InspHRationsResponseCounter";
    organizationID: string;
    employeeID: string;
    organizationShoutOutTypeID: string;
    type: InspHRationCounterType;
    group: string;
    count: number;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null>;
  nextToken?: string | null;
};

export type GetInspHRationsOrganizationResponseCounterQuery = {
  __typename: "InspHRationsOrganizationResponseCounter";
  organizationID: string;
  employeeID: string;
  organizationShoutOutTypeID: string;
  type: InspHRationCounterType;
  group: string;
  count: number;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type ListInspHRationsOrganizationResponseCountersQuery = {
  __typename: "ModelInspHRationsOrganizationResponseCounterConnection";
  items: Array<{
    __typename: "InspHRationsOrganizationResponseCounter";
    organizationID: string;
    employeeID: string;
    organizationShoutOutTypeID: string;
    type: InspHRationCounterType;
    group: string;
    count: number;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null>;
  nextToken?: string | null;
};

export type GetIntHRactionCounterQuery = {
  __typename: "IntHRactionCounter";
  employeeID: string;
  participantID: string;
  organizationID: string;
  count: number;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type ListIntHRactionCountersQuery = {
  __typename: "ModelIntHRactionCounterConnection";
  items: Array<{
    __typename: "IntHRactionCounter";
    employeeID: string;
    participantID: string;
    organizationID: string;
    count: number;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null>;
  nextToken?: string | null;
};

export type GetPriorityQuery = {
  __typename: "Priority";
  id: string;
  key: string;
  display: string;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type ListPrioritysQuery = {
  __typename: "ModelPriorityConnection";
  items: Array<{
    __typename: "Priority";
    id: string;
    key: string;
    display: string;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null>;
  nextToken?: string | null;
};

export type GetFocusQuery = {
  __typename: "Focus";
  id: string;
  key: string;
  display: string;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type ListFocussQuery = {
  __typename: "ModelFocusConnection";
  items: Array<{
    __typename: "Focus";
    id: string;
    key: string;
    display: string;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null>;
  nextToken?: string | null;
};

export type GetObjectiveDomainQuery = {
  __typename: "ObjectiveDomain";
  id: string;
  key: string;
  display: string;
  excludeFromTPS?: boolean | null;
  sites: Array<string>;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type ListObjectiveDomainsQuery = {
  __typename: "ModelObjectiveDomainConnection";
  items: Array<{
    __typename: "ObjectiveDomain";
    id: string;
    key: string;
    display: string;
    excludeFromTPS?: boolean | null;
    sites: Array<string>;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null>;
  nextToken?: string | null;
};

export type GetObjectiveQuery = {
  __typename: "Objective";
  id: string;
  display: string;
  definition: string;
  observables: Array<string>;
  hints?: Array<string | null> | null;
  organizationID?: string | null;
  organizations?: Array<string | null> | null;
  status?: string | null;
  domain: {
    __typename: "ObjectiveDomain";
    id: string;
    key: string;
    display: string;
    excludeFromTPS?: boolean | null;
    sites: Array<string>;
    createdAt?: string | null;
    updatedAt?: string | null;
  };
  objectiveDomainId: string;
  qrCodes?: Array<{
    __typename: "QR";
    type: string;
    imageSrc: string;
    title: string;
    label: string;
    qrdata: string;
  } | null> | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type ListObjectivesQuery = {
  __typename: "ModelObjectiveConnection";
  items: Array<{
    __typename: "Objective";
    id: string;
    display: string;
    definition: string;
    observables: Array<string>;
    hints?: Array<string | null> | null;
    organizationID?: string | null;
    organizations?: Array<string | null> | null;
    status?: string | null;
    domain: {
      __typename: "ObjectiveDomain";
      id: string;
      key: string;
      display: string;
      excludeFromTPS?: boolean | null;
      sites: Array<string>;
      createdAt?: string | null;
      updatedAt?: string | null;
    };
    objectiveDomainId: string;
    qrCodes?: Array<{
      __typename: "QR";
      type: string;
      imageSrc: string;
      title: string;
      label: string;
      qrdata: string;
    } | null> | null;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null>;
  nextToken?: string | null;
};

export type GetOrganizationObjectiveQuery = {
  __typename: "OrganizationObjective";
  id: string;
  organizationID: string;
  objective: {
    __typename: "Objective";
    id: string;
    display: string;
    definition: string;
    observables: Array<string>;
    hints?: Array<string | null> | null;
    organizationID?: string | null;
    organizations?: Array<string | null> | null;
    status?: string | null;
    domain: {
      __typename: "ObjectiveDomain";
      id: string;
      key: string;
      display: string;
      excludeFromTPS?: boolean | null;
      sites: Array<string>;
      createdAt?: string | null;
      updatedAt?: string | null;
    };
    objectiveDomainId: string;
    qrCodes?: Array<{
      __typename: "QR";
      type: string;
      imageSrc: string;
      title: string;
      label: string;
      qrdata: string;
    } | null> | null;
    createdAt?: string | null;
    updatedAt?: string | null;
  };
  organizationObjectiveObjectiveId: string;
  enabled?: boolean | null;
  businessObjective?: string | null;
  status?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type ListOrganizationObjectivesQuery = {
  __typename: "ModelOrganizationObjectiveConnection";
  items: Array<{
    __typename: "OrganizationObjective";
    id: string;
    organizationID: string;
    objective: {
      __typename: "Objective";
      id: string;
      display: string;
      definition: string;
      observables: Array<string>;
      hints?: Array<string | null> | null;
      organizationID?: string | null;
      organizations?: Array<string | null> | null;
      status?: string | null;
      domain: {
        __typename: "ObjectiveDomain";
        id: string;
        key: string;
        display: string;
        excludeFromTPS?: boolean | null;
        sites: Array<string>;
        createdAt?: string | null;
        updatedAt?: string | null;
      };
      objectiveDomainId: string;
      qrCodes?: Array<{
        __typename: "QR";
        type: string;
        imageSrc: string;
        title: string;
        label: string;
        qrdata: string;
      } | null> | null;
      createdAt?: string | null;
      updatedAt?: string | null;
    };
    organizationObjectiveObjectiveId: string;
    enabled?: boolean | null;
    businessObjective?: string | null;
    status?: string | null;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null>;
  nextToken?: string | null;
};

export type GetObjectiveAssignmentQuery = {
  __typename: "ObjectiveAssignment";
  id: string;
  employeeId: string;
  organizationID?: string | null;
  objectiveAssignmentAssignedById?: string | null;
  assignmentDate: string;
  priority?: {
    __typename: "Priority";
    id: string;
    key: string;
    display: string;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null;
  focus?: {
    __typename: "Focus";
    id: string;
    key: string;
    display: string;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null;
  orgObjective?: {
    __typename: "OrganizationObjective";
    id: string;
    organizationID: string;
    objective: {
      __typename: "Objective";
      id: string;
      display: string;
      definition: string;
      observables: Array<string>;
      hints?: Array<string | null> | null;
      organizationID?: string | null;
      organizations?: Array<string | null> | null;
      status?: string | null;
      domain: {
        __typename: "ObjectiveDomain";
        id: string;
        key: string;
        display: string;
        excludeFromTPS?: boolean | null;
        sites: Array<string>;
        createdAt?: string | null;
        updatedAt?: string | null;
      };
      objectiveDomainId: string;
      qrCodes?: Array<{
        __typename: "QR";
        type: string;
        imageSrc: string;
        title: string;
        label: string;
        qrdata: string;
      } | null> | null;
      createdAt?: string | null;
      updatedAt?: string | null;
    };
    organizationObjectiveObjectiveId: string;
    enabled?: boolean | null;
    businessObjective?: string | null;
    status?: string | null;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null;
  startDate?: string | null;
  endDate?: string | null;
  cadence?: string | null;
  managerDescription?: string | null;
  objectiveType?: string | null;
  title?: string | null;
  quantity?: number | null;
  consultantId?: string | null;
  nextSurveyDate?: string | null;
  status?: string | null;
  externalEmail?: string | null;
  externalEmails?: Array<string | null> | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type ListObjectiveAssignmentsQuery = {
  __typename: "ModelObjectiveAssignmentConnection";
  items: Array<{
    __typename: "ObjectiveAssignment";
    id: string;
    employeeId: string;
    organizationID?: string | null;
    objectiveAssignmentAssignedById?: string | null;
    assignmentDate: string;
    priority?: {
      __typename: "Priority";
      id: string;
      key: string;
      display: string;
      createdAt?: string | null;
      updatedAt?: string | null;
    } | null;
    focus?: {
      __typename: "Focus";
      id: string;
      key: string;
      display: string;
      createdAt?: string | null;
      updatedAt?: string | null;
    } | null;
    orgObjective?: {
      __typename: "OrganizationObjective";
      id: string;
      organizationID: string;
      objective: {
        __typename: "Objective";
        id: string;
        display: string;
        definition: string;
        observables: Array<string>;
        hints?: Array<string | null> | null;
        organizationID?: string | null;
        organizations?: Array<string | null> | null;
        status?: string | null;
        domain: {
          __typename: "ObjectiveDomain";
          id: string;
          key: string;
          display: string;
          excludeFromTPS?: boolean | null;
          sites: Array<string>;
          createdAt?: string | null;
          updatedAt?: string | null;
        };
        objectiveDomainId: string;
        qrCodes?: Array<{
          __typename: "QR";
          type: string;
          imageSrc: string;
          title: string;
          label: string;
          qrdata: string;
        } | null> | null;
        createdAt?: string | null;
        updatedAt?: string | null;
      };
      organizationObjectiveObjectiveId: string;
      enabled?: boolean | null;
      businessObjective?: string | null;
      status?: string | null;
      createdAt?: string | null;
      updatedAt?: string | null;
    } | null;
    startDate?: string | null;
    endDate?: string | null;
    cadence?: string | null;
    managerDescription?: string | null;
    objectiveType?: string | null;
    title?: string | null;
    quantity?: number | null;
    consultantId?: string | null;
    nextSurveyDate?: string | null;
    status?: string | null;
    externalEmail?: string | null;
    externalEmails?: Array<string | null> | null;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null>;
  nextToken?: string | null;
};

export type GetCalendarEventQuery = {
  __typename: "CalendarEvent";
  id: string;
  employeeID: string;
  organizationID: string;
  calendarId: string;
  start: string;
  end: string;
  eventCreated: string;
  eventUpdated?: string | null;
  summary?: string | null;
  description?: string | null;
  status?: string | null;
  private?: boolean | null;
  deleted?: boolean | null;
  recurring?: boolean | null;
  locationDescription?: string | null;
  participationStatus?: string | null;
  eventStatus?: string | null;
  organizerEmail?: string | null;
  organizer?: {
    __typename: "EventAttendee";
    email: string;
    displayName?: string | null;
    status?: string | null;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null;
  attendees?: Array<{
    __typename: "EventAttendee";
    email: string;
    displayName?: string | null;
    status?: string | null;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null> | null;
  attendeeEmails?: Array<string | null> | null;
  meetingURL?: string | null;
  options?: {
    __typename: "EventOptions";
    changeParticipationStatus?: boolean | null;
    delete?: boolean | null;
    update?: boolean | null;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type ListCalendarEventsQuery = {
  __typename: "ModelCalendarEventConnection";
  items: Array<{
    __typename: "CalendarEvent";
    id: string;
    employeeID: string;
    organizationID: string;
    calendarId: string;
    start: string;
    end: string;
    eventCreated: string;
    eventUpdated?: string | null;
    summary?: string | null;
    description?: string | null;
    status?: string | null;
    private?: boolean | null;
    deleted?: boolean | null;
    recurring?: boolean | null;
    locationDescription?: string | null;
    participationStatus?: string | null;
    eventStatus?: string | null;
    organizerEmail?: string | null;
    organizer?: {
      __typename: "EventAttendee";
      email: string;
      displayName?: string | null;
      status?: string | null;
      createdAt?: string | null;
      updatedAt?: string | null;
    } | null;
    attendees?: Array<{
      __typename: "EventAttendee";
      email: string;
      displayName?: string | null;
      status?: string | null;
      createdAt?: string | null;
      updatedAt?: string | null;
    } | null> | null;
    attendeeEmails?: Array<string | null> | null;
    meetingURL?: string | null;
    options?: {
      __typename: "EventOptions";
      changeParticipationStatus?: boolean | null;
      delete?: boolean | null;
      update?: boolean | null;
      createdAt?: string | null;
      updatedAt?: string | null;
    } | null;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null>;
  nextToken?: string | null;
};

export type GetSurveyQuery = {
  __typename: "Survey";
  id: string;
  organizationID: string;
  objectID: string;
  surveyType: SurveyType;
  surveyDate: string;
  respondentEmail: string;
  participantEmail: string;
  respondentAttended?: boolean | null;
  surveyResponse?: number | null;
  feedback?: string | null;
  responseReceived?: boolean | null;
  responseReceivedDate?: string | null;
  status?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type ListSurveysQuery = {
  __typename: "ModelSurveyConnection";
  items: Array<{
    __typename: "Survey";
    id: string;
    organizationID: string;
    objectID: string;
    surveyType: SurveyType;
    surveyDate: string;
    respondentEmail: string;
    participantEmail: string;
    respondentAttended?: boolean | null;
    surveyResponse?: number | null;
    feedback?: string | null;
    responseReceived?: boolean | null;
    responseReceivedDate?: string | null;
    status?: string | null;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null>;
  nextToken?: string | null;
};

export type GetExternalRespondentQuery = {
  __typename: "ExternalRespondent";
  id: string;
  externalRespondentEmail: string;
  externalRespondentName?: string | null;
  externalRespondentStatus?: string | null;
  statusUpdates?: Array<{
    __typename: "ExternalRespondentStatusChange";
    status: string;
    ipAddress: string;
    externalRespondentStatusDate: string;
  } | null> | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type ListExternalRespondentsQuery = {
  __typename: "ModelExternalRespondentConnection";
  items: Array<{
    __typename: "ExternalRespondent";
    id: string;
    externalRespondentEmail: string;
    externalRespondentName?: string | null;
    externalRespondentStatus?: string | null;
    statusUpdates?: Array<{
      __typename: "ExternalRespondentStatusChange";
      status: string;
      ipAddress: string;
      externalRespondentStatusDate: string;
    } | null> | null;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null>;
  nextToken?: string | null;
};

export type GetDoNotDisturbRequestQuery = {
  __typename: "DoNotDisturbRequest";
  id: string;
  externalRespondentID?: string | null;
  externalRespondentEmail: string;
  ipAddress: string;
  status: string;
  createdAt: string;
  updatedAt: string;
};

export type ListDoNotDisturbRequestsQuery = {
  __typename: "ModelDoNotDisturbRequestConnection";
  items: Array<{
    __typename: "DoNotDisturbRequest";
    id: string;
    externalRespondentID?: string | null;
    externalRespondentEmail: string;
    ipAddress: string;
    status: string;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type GetProjectQuery = {
  __typename: "Project";
  id: string;
  organizationID: string;
  title: string;
  description?: string | null;
  startDate: string;
  endDate: string;
  cadence: string;
  projectManagerID: string;
  status?: string | null;
  team?: Array<string | null> | null;
  nextSurveyDate: string;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type ListProjectsQuery = {
  __typename: "ModelProjectConnection";
  items: Array<{
    __typename: "Project";
    id: string;
    organizationID: string;
    title: string;
    description?: string | null;
    startDate: string;
    endDate: string;
    cadence: string;
    projectManagerID: string;
    status?: string | null;
    team?: Array<string | null> | null;
    nextSurveyDate: string;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null>;
  nextToken?: string | null;
};

export type GetMilestoneCommentQuery = {
  __typename: "MilestoneComment";
  id: string;
  organizationID: string;
  milestoneID: string;
  projectMilestoneAssignment?: {
    __typename: "ProjectMilestoneAssignment";
    id: string;
    projectID: string;
    organizationID: string;
    assignedBy: string;
    assignmentDate: string;
    startDate: string;
    endDate: string;
    cadence: string;
    milestoneType: string;
    title: string;
    nextSurveyDate: string;
    description?: string | null;
    team?: Array<string | null> | null;
    comments?: {
      __typename: "ModelMilestoneCommentConnection";
      items: Array<{
        __typename: "MilestoneComment";
        id: string;
        organizationID: string;
        milestoneID: string;
        projectMilestoneAssignment?: {
          __typename: "ProjectMilestoneAssignment";
          id: string;
          projectID: string;
          organizationID: string;
          assignedBy: string;
          assignmentDate: string;
          startDate: string;
          endDate: string;
          cadence: string;
          milestoneType: string;
          title: string;
          nextSurveyDate: string;
          description?: string | null;
          team?: Array<string | null> | null;
          comments?: {
            __typename: "ModelMilestoneCommentConnection";
            items: Array<{
              __typename: "MilestoneComment";
              id: string;
              organizationID: string;
              milestoneID: string;
              projectMilestoneAssignment?: {
                __typename: "ProjectMilestoneAssignment";
                id: string;
                projectID: string;
                organizationID: string;
                assignedBy: string;
                assignmentDate: string;
                startDate: string;
                endDate: string;
                cadence: string;
                milestoneType: string;
                title: string;
                nextSurveyDate: string;
                description?: string | null;
                team?: Array<string | null> | null;
                comments?: {
                  __typename: "ModelMilestoneCommentConnection";
                  items: Array<{
                    __typename: "MilestoneComment";
                    id: string;
                    organizationID: string;
                    milestoneID: string;
                    comment: string;
                    createdAt: string;
                    createdBy: string;
                    updatedAt?: string | null;
                  } | null>;
                  nextToken?: string | null;
                } | null;
                createdAt?: string | null;
                updatedAt?: string | null;
              } | null;
              comment: string;
              createdAt: string;
              createdBy: string;
              updatedAt?: string | null;
            } | null>;
            nextToken?: string | null;
          } | null;
          createdAt?: string | null;
          updatedAt?: string | null;
        } | null;
        comment: string;
        createdAt: string;
        createdBy: string;
        updatedAt?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null;
  comment: string;
  createdAt: string;
  createdBy: string;
  updatedAt?: string | null;
};

export type ListMilestoneCommentsQuery = {
  __typename: "ModelMilestoneCommentConnection";
  items: Array<{
    __typename: "MilestoneComment";
    id: string;
    organizationID: string;
    milestoneID: string;
    projectMilestoneAssignment?: {
      __typename: "ProjectMilestoneAssignment";
      id: string;
      projectID: string;
      organizationID: string;
      assignedBy: string;
      assignmentDate: string;
      startDate: string;
      endDate: string;
      cadence: string;
      milestoneType: string;
      title: string;
      nextSurveyDate: string;
      description?: string | null;
      team?: Array<string | null> | null;
      comments?: {
        __typename: "ModelMilestoneCommentConnection";
        items: Array<{
          __typename: "MilestoneComment";
          id: string;
          organizationID: string;
          milestoneID: string;
          projectMilestoneAssignment?: {
            __typename: "ProjectMilestoneAssignment";
            id: string;
            projectID: string;
            organizationID: string;
            assignedBy: string;
            assignmentDate: string;
            startDate: string;
            endDate: string;
            cadence: string;
            milestoneType: string;
            title: string;
            nextSurveyDate: string;
            description?: string | null;
            team?: Array<string | null> | null;
            comments?: {
              __typename: "ModelMilestoneCommentConnection";
              items: Array<{
                __typename: "MilestoneComment";
                id: string;
                organizationID: string;
                milestoneID: string;
                projectMilestoneAssignment?: {
                  __typename: "ProjectMilestoneAssignment";
                  id: string;
                  projectID: string;
                  organizationID: string;
                  assignedBy: string;
                  assignmentDate: string;
                  startDate: string;
                  endDate: string;
                  cadence: string;
                  milestoneType: string;
                  title: string;
                  nextSurveyDate: string;
                  description?: string | null;
                  team?: Array<string | null> | null;
                  comments?: {
                    __typename: "ModelMilestoneCommentConnection";
                    nextToken?: string | null;
                  } | null;
                  createdAt?: string | null;
                  updatedAt?: string | null;
                } | null;
                comment: string;
                createdAt: string;
                createdBy: string;
                updatedAt?: string | null;
              } | null>;
              nextToken?: string | null;
            } | null;
            createdAt?: string | null;
            updatedAt?: string | null;
          } | null;
          comment: string;
          createdAt: string;
          createdBy: string;
          updatedAt?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt?: string | null;
      updatedAt?: string | null;
    } | null;
    comment: string;
    createdAt: string;
    createdBy: string;
    updatedAt?: string | null;
  } | null>;
  nextToken?: string | null;
};

export type GetProjectMilestoneAssignmentQuery = {
  __typename: "ProjectMilestoneAssignment";
  id: string;
  projectID: string;
  organizationID: string;
  assignedBy: string;
  assignmentDate: string;
  startDate: string;
  endDate: string;
  cadence: string;
  milestoneType: string;
  title: string;
  nextSurveyDate: string;
  description?: string | null;
  team?: Array<string | null> | null;
  comments?: {
    __typename: "ModelMilestoneCommentConnection";
    items: Array<{
      __typename: "MilestoneComment";
      id: string;
      organizationID: string;
      milestoneID: string;
      projectMilestoneAssignment?: {
        __typename: "ProjectMilestoneAssignment";
        id: string;
        projectID: string;
        organizationID: string;
        assignedBy: string;
        assignmentDate: string;
        startDate: string;
        endDate: string;
        cadence: string;
        milestoneType: string;
        title: string;
        nextSurveyDate: string;
        description?: string | null;
        team?: Array<string | null> | null;
        comments?: {
          __typename: "ModelMilestoneCommentConnection";
          items: Array<{
            __typename: "MilestoneComment";
            id: string;
            organizationID: string;
            milestoneID: string;
            projectMilestoneAssignment?: {
              __typename: "ProjectMilestoneAssignment";
              id: string;
              projectID: string;
              organizationID: string;
              assignedBy: string;
              assignmentDate: string;
              startDate: string;
              endDate: string;
              cadence: string;
              milestoneType: string;
              title: string;
              nextSurveyDate: string;
              description?: string | null;
              team?: Array<string | null> | null;
              comments?: {
                __typename: "ModelMilestoneCommentConnection";
                items: Array<{
                  __typename: "MilestoneComment";
                  id: string;
                  organizationID: string;
                  milestoneID: string;
                  projectMilestoneAssignment?: {
                    __typename: "ProjectMilestoneAssignment";
                    id: string;
                    projectID: string;
                    organizationID: string;
                    assignedBy: string;
                    assignmentDate: string;
                    startDate: string;
                    endDate: string;
                    cadence: string;
                    milestoneType: string;
                    title: string;
                    nextSurveyDate: string;
                    description?: string | null;
                    team?: Array<string | null> | null;
                    createdAt?: string | null;
                    updatedAt?: string | null;
                  } | null;
                  comment: string;
                  createdAt: string;
                  createdBy: string;
                  updatedAt?: string | null;
                } | null>;
                nextToken?: string | null;
              } | null;
              createdAt?: string | null;
              updatedAt?: string | null;
            } | null;
            comment: string;
            createdAt: string;
            createdBy: string;
            updatedAt?: string | null;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt?: string | null;
        updatedAt?: string | null;
      } | null;
      comment: string;
      createdAt: string;
      createdBy: string;
      updatedAt?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type ListProjectMilestoneAssignmentsQuery = {
  __typename: "ModelProjectMilestoneAssignmentConnection";
  items: Array<{
    __typename: "ProjectMilestoneAssignment";
    id: string;
    projectID: string;
    organizationID: string;
    assignedBy: string;
    assignmentDate: string;
    startDate: string;
    endDate: string;
    cadence: string;
    milestoneType: string;
    title: string;
    nextSurveyDate: string;
    description?: string | null;
    team?: Array<string | null> | null;
    comments?: {
      __typename: "ModelMilestoneCommentConnection";
      items: Array<{
        __typename: "MilestoneComment";
        id: string;
        organizationID: string;
        milestoneID: string;
        projectMilestoneAssignment?: {
          __typename: "ProjectMilestoneAssignment";
          id: string;
          projectID: string;
          organizationID: string;
          assignedBy: string;
          assignmentDate: string;
          startDate: string;
          endDate: string;
          cadence: string;
          milestoneType: string;
          title: string;
          nextSurveyDate: string;
          description?: string | null;
          team?: Array<string | null> | null;
          comments?: {
            __typename: "ModelMilestoneCommentConnection";
            items: Array<{
              __typename: "MilestoneComment";
              id: string;
              organizationID: string;
              milestoneID: string;
              projectMilestoneAssignment?: {
                __typename: "ProjectMilestoneAssignment";
                id: string;
                projectID: string;
                organizationID: string;
                assignedBy: string;
                assignmentDate: string;
                startDate: string;
                endDate: string;
                cadence: string;
                milestoneType: string;
                title: string;
                nextSurveyDate: string;
                description?: string | null;
                team?: Array<string | null> | null;
                comments?: {
                  __typename: "ModelMilestoneCommentConnection";
                  items: Array<{
                    __typename: "MilestoneComment";
                    id: string;
                    organizationID: string;
                    milestoneID: string;
                    comment: string;
                    createdAt: string;
                    createdBy: string;
                    updatedAt?: string | null;
                  } | null>;
                  nextToken?: string | null;
                } | null;
                createdAt?: string | null;
                updatedAt?: string | null;
              } | null;
              comment: string;
              createdAt: string;
              createdBy: string;
              updatedAt?: string | null;
            } | null>;
            nextToken?: string | null;
          } | null;
          createdAt?: string | null;
          updatedAt?: string | null;
        } | null;
        comment: string;
        createdAt: string;
        createdBy: string;
        updatedAt?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null>;
  nextToken?: string | null;
};

export type GetNoteCommentQuery = {
  __typename: "NoteComment";
  id: string;
  organizationID: string;
  noteID: string;
  note?: {
    __typename: "Note";
    id: string;
    organizationID: string;
    objectID: string;
    createdAt: string;
    createdBy: string;
    updatedAt?: string | null;
    updatedBy?: string | null;
    text?: string | null;
    noteType: string;
    options?: string | null;
    comments?: {
      __typename: "ModelNoteCommentConnection";
      items: Array<{
        __typename: "NoteComment";
        id: string;
        organizationID: string;
        noteID: string;
        note?: {
          __typename: "Note";
          id: string;
          organizationID: string;
          objectID: string;
          createdAt: string;
          createdBy: string;
          updatedAt?: string | null;
          updatedBy?: string | null;
          text?: string | null;
          noteType: string;
          options?: string | null;
          comments?: {
            __typename: "ModelNoteCommentConnection";
            items: Array<{
              __typename: "NoteComment";
              id: string;
              organizationID: string;
              noteID: string;
              note?: {
                __typename: "Note";
                id: string;
                organizationID: string;
                objectID: string;
                createdAt: string;
                createdBy: string;
                updatedAt?: string | null;
                updatedBy?: string | null;
                text?: string | null;
                noteType: string;
                options?: string | null;
                comments?: {
                  __typename: "ModelNoteCommentConnection";
                  items: Array<{
                    __typename: "NoteComment";
                    id: string;
                    organizationID: string;
                    noteID: string;
                    comment: string;
                    createdAt: string;
                    createdBy: string;
                    updatedAt?: string | null;
                  } | null>;
                  nextToken?: string | null;
                } | null;
                historys?: {
                  __typename: "ModelNoteHistoryConnection";
                  items: Array<{
                    __typename: "NoteHistory";
                    id: string;
                    organizationID: string;
                    noteID: string;
                    createdAt: string;
                    createdBy: string;
                    text?: string | null;
                    updatedAt?: string | null;
                  } | null>;
                  nextToken?: string | null;
                } | null;
              } | null;
              comment: string;
              createdAt: string;
              createdBy: string;
              updatedAt?: string | null;
            } | null>;
            nextToken?: string | null;
          } | null;
          historys?: {
            __typename: "ModelNoteHistoryConnection";
            items: Array<{
              __typename: "NoteHistory";
              id: string;
              organizationID: string;
              noteID: string;
              note?: {
                __typename: "Note";
                id: string;
                organizationID: string;
                objectID: string;
                createdAt: string;
                createdBy: string;
                updatedAt?: string | null;
                updatedBy?: string | null;
                text?: string | null;
                noteType: string;
                options?: string | null;
                comments?: {
                  __typename: "ModelNoteCommentConnection";
                  items: Array<{
                    __typename: "NoteComment";
                    id: string;
                    organizationID: string;
                    noteID: string;
                    comment: string;
                    createdAt: string;
                    createdBy: string;
                    updatedAt?: string | null;
                  } | null>;
                  nextToken?: string | null;
                } | null;
                historys?: {
                  __typename: "ModelNoteHistoryConnection";
                  items: Array<{
                    __typename: "NoteHistory";
                    id: string;
                    organizationID: string;
                    noteID: string;
                    createdAt: string;
                    createdBy: string;
                    text?: string | null;
                    updatedAt?: string | null;
                  } | null>;
                  nextToken?: string | null;
                } | null;
              } | null;
              createdAt: string;
              createdBy: string;
              text?: string | null;
              updatedAt?: string | null;
            } | null>;
            nextToken?: string | null;
          } | null;
        } | null;
        comment: string;
        createdAt: string;
        createdBy: string;
        updatedAt?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    historys?: {
      __typename: "ModelNoteHistoryConnection";
      items: Array<{
        __typename: "NoteHistory";
        id: string;
        organizationID: string;
        noteID: string;
        note?: {
          __typename: "Note";
          id: string;
          organizationID: string;
          objectID: string;
          createdAt: string;
          createdBy: string;
          updatedAt?: string | null;
          updatedBy?: string | null;
          text?: string | null;
          noteType: string;
          options?: string | null;
          comments?: {
            __typename: "ModelNoteCommentConnection";
            items: Array<{
              __typename: "NoteComment";
              id: string;
              organizationID: string;
              noteID: string;
              note?: {
                __typename: "Note";
                id: string;
                organizationID: string;
                objectID: string;
                createdAt: string;
                createdBy: string;
                updatedAt?: string | null;
                updatedBy?: string | null;
                text?: string | null;
                noteType: string;
                options?: string | null;
                comments?: {
                  __typename: "ModelNoteCommentConnection";
                  items: Array<{
                    __typename: "NoteComment";
                    id: string;
                    organizationID: string;
                    noteID: string;
                    comment: string;
                    createdAt: string;
                    createdBy: string;
                    updatedAt?: string | null;
                  } | null>;
                  nextToken?: string | null;
                } | null;
                historys?: {
                  __typename: "ModelNoteHistoryConnection";
                  items: Array<{
                    __typename: "NoteHistory";
                    id: string;
                    organizationID: string;
                    noteID: string;
                    createdAt: string;
                    createdBy: string;
                    text?: string | null;
                    updatedAt?: string | null;
                  } | null>;
                  nextToken?: string | null;
                } | null;
              } | null;
              comment: string;
              createdAt: string;
              createdBy: string;
              updatedAt?: string | null;
            } | null>;
            nextToken?: string | null;
          } | null;
          historys?: {
            __typename: "ModelNoteHistoryConnection";
            items: Array<{
              __typename: "NoteHistory";
              id: string;
              organizationID: string;
              noteID: string;
              note?: {
                __typename: "Note";
                id: string;
                organizationID: string;
                objectID: string;
                createdAt: string;
                createdBy: string;
                updatedAt?: string | null;
                updatedBy?: string | null;
                text?: string | null;
                noteType: string;
                options?: string | null;
                comments?: {
                  __typename: "ModelNoteCommentConnection";
                  items: Array<{
                    __typename: "NoteComment";
                    id: string;
                    organizationID: string;
                    noteID: string;
                    comment: string;
                    createdAt: string;
                    createdBy: string;
                    updatedAt?: string | null;
                  } | null>;
                  nextToken?: string | null;
                } | null;
                historys?: {
                  __typename: "ModelNoteHistoryConnection";
                  items: Array<{
                    __typename: "NoteHistory";
                    id: string;
                    organizationID: string;
                    noteID: string;
                    createdAt: string;
                    createdBy: string;
                    text?: string | null;
                    updatedAt?: string | null;
                  } | null>;
                  nextToken?: string | null;
                } | null;
              } | null;
              createdAt: string;
              createdBy: string;
              text?: string | null;
              updatedAt?: string | null;
            } | null>;
            nextToken?: string | null;
          } | null;
        } | null;
        createdAt: string;
        createdBy: string;
        text?: string | null;
        updatedAt?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null;
  comment: string;
  createdAt: string;
  createdBy: string;
  updatedAt?: string | null;
};

export type ListNoteCommentsQuery = {
  __typename: "ModelNoteCommentConnection";
  items: Array<{
    __typename: "NoteComment";
    id: string;
    organizationID: string;
    noteID: string;
    note?: {
      __typename: "Note";
      id: string;
      organizationID: string;
      objectID: string;
      createdAt: string;
      createdBy: string;
      updatedAt?: string | null;
      updatedBy?: string | null;
      text?: string | null;
      noteType: string;
      options?: string | null;
      comments?: {
        __typename: "ModelNoteCommentConnection";
        items: Array<{
          __typename: "NoteComment";
          id: string;
          organizationID: string;
          noteID: string;
          note?: {
            __typename: "Note";
            id: string;
            organizationID: string;
            objectID: string;
            createdAt: string;
            createdBy: string;
            updatedAt?: string | null;
            updatedBy?: string | null;
            text?: string | null;
            noteType: string;
            options?: string | null;
            comments?: {
              __typename: "ModelNoteCommentConnection";
              items: Array<{
                __typename: "NoteComment";
                id: string;
                organizationID: string;
                noteID: string;
                note?: {
                  __typename: "Note";
                  id: string;
                  organizationID: string;
                  objectID: string;
                  createdAt: string;
                  createdBy: string;
                  updatedAt?: string | null;
                  updatedBy?: string | null;
                  text?: string | null;
                  noteType: string;
                  options?: string | null;
                  comments?: {
                    __typename: "ModelNoteCommentConnection";
                    nextToken?: string | null;
                  } | null;
                  historys?: {
                    __typename: "ModelNoteHistoryConnection";
                    nextToken?: string | null;
                  } | null;
                } | null;
                comment: string;
                createdAt: string;
                createdBy: string;
                updatedAt?: string | null;
              } | null>;
              nextToken?: string | null;
            } | null;
            historys?: {
              __typename: "ModelNoteHistoryConnection";
              items: Array<{
                __typename: "NoteHistory";
                id: string;
                organizationID: string;
                noteID: string;
                note?: {
                  __typename: "Note";
                  id: string;
                  organizationID: string;
                  objectID: string;
                  createdAt: string;
                  createdBy: string;
                  updatedAt?: string | null;
                  updatedBy?: string | null;
                  text?: string | null;
                  noteType: string;
                  options?: string | null;
                  comments?: {
                    __typename: "ModelNoteCommentConnection";
                    nextToken?: string | null;
                  } | null;
                  historys?: {
                    __typename: "ModelNoteHistoryConnection";
                    nextToken?: string | null;
                  } | null;
                } | null;
                createdAt: string;
                createdBy: string;
                text?: string | null;
                updatedAt?: string | null;
              } | null>;
              nextToken?: string | null;
            } | null;
          } | null;
          comment: string;
          createdAt: string;
          createdBy: string;
          updatedAt?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      historys?: {
        __typename: "ModelNoteHistoryConnection";
        items: Array<{
          __typename: "NoteHistory";
          id: string;
          organizationID: string;
          noteID: string;
          note?: {
            __typename: "Note";
            id: string;
            organizationID: string;
            objectID: string;
            createdAt: string;
            createdBy: string;
            updatedAt?: string | null;
            updatedBy?: string | null;
            text?: string | null;
            noteType: string;
            options?: string | null;
            comments?: {
              __typename: "ModelNoteCommentConnection";
              items: Array<{
                __typename: "NoteComment";
                id: string;
                organizationID: string;
                noteID: string;
                note?: {
                  __typename: "Note";
                  id: string;
                  organizationID: string;
                  objectID: string;
                  createdAt: string;
                  createdBy: string;
                  updatedAt?: string | null;
                  updatedBy?: string | null;
                  text?: string | null;
                  noteType: string;
                  options?: string | null;
                  comments?: {
                    __typename: "ModelNoteCommentConnection";
                    nextToken?: string | null;
                  } | null;
                  historys?: {
                    __typename: "ModelNoteHistoryConnection";
                    nextToken?: string | null;
                  } | null;
                } | null;
                comment: string;
                createdAt: string;
                createdBy: string;
                updatedAt?: string | null;
              } | null>;
              nextToken?: string | null;
            } | null;
            historys?: {
              __typename: "ModelNoteHistoryConnection";
              items: Array<{
                __typename: "NoteHistory";
                id: string;
                organizationID: string;
                noteID: string;
                note?: {
                  __typename: "Note";
                  id: string;
                  organizationID: string;
                  objectID: string;
                  createdAt: string;
                  createdBy: string;
                  updatedAt?: string | null;
                  updatedBy?: string | null;
                  text?: string | null;
                  noteType: string;
                  options?: string | null;
                  comments?: {
                    __typename: "ModelNoteCommentConnection";
                    nextToken?: string | null;
                  } | null;
                  historys?: {
                    __typename: "ModelNoteHistoryConnection";
                    nextToken?: string | null;
                  } | null;
                } | null;
                createdAt: string;
                createdBy: string;
                text?: string | null;
                updatedAt?: string | null;
              } | null>;
              nextToken?: string | null;
            } | null;
          } | null;
          createdAt: string;
          createdBy: string;
          text?: string | null;
          updatedAt?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null;
    comment: string;
    createdAt: string;
    createdBy: string;
    updatedAt?: string | null;
  } | null>;
  nextToken?: string | null;
};

export type GetNoteQuery = {
  __typename: "Note";
  id: string;
  organizationID: string;
  objectID: string;
  createdAt: string;
  createdBy: string;
  updatedAt?: string | null;
  updatedBy?: string | null;
  text?: string | null;
  noteType: string;
  options?: string | null;
  comments?: {
    __typename: "ModelNoteCommentConnection";
    items: Array<{
      __typename: "NoteComment";
      id: string;
      organizationID: string;
      noteID: string;
      note?: {
        __typename: "Note";
        id: string;
        organizationID: string;
        objectID: string;
        createdAt: string;
        createdBy: string;
        updatedAt?: string | null;
        updatedBy?: string | null;
        text?: string | null;
        noteType: string;
        options?: string | null;
        comments?: {
          __typename: "ModelNoteCommentConnection";
          items: Array<{
            __typename: "NoteComment";
            id: string;
            organizationID: string;
            noteID: string;
            note?: {
              __typename: "Note";
              id: string;
              organizationID: string;
              objectID: string;
              createdAt: string;
              createdBy: string;
              updatedAt?: string | null;
              updatedBy?: string | null;
              text?: string | null;
              noteType: string;
              options?: string | null;
              comments?: {
                __typename: "ModelNoteCommentConnection";
                items: Array<{
                  __typename: "NoteComment";
                  id: string;
                  organizationID: string;
                  noteID: string;
                  note?: {
                    __typename: "Note";
                    id: string;
                    organizationID: string;
                    objectID: string;
                    createdAt: string;
                    createdBy: string;
                    updatedAt?: string | null;
                    updatedBy?: string | null;
                    text?: string | null;
                    noteType: string;
                    options?: string | null;
                  } | null;
                  comment: string;
                  createdAt: string;
                  createdBy: string;
                  updatedAt?: string | null;
                } | null>;
                nextToken?: string | null;
              } | null;
              historys?: {
                __typename: "ModelNoteHistoryConnection";
                items: Array<{
                  __typename: "NoteHistory";
                  id: string;
                  organizationID: string;
                  noteID: string;
                  note?: {
                    __typename: "Note";
                    id: string;
                    organizationID: string;
                    objectID: string;
                    createdAt: string;
                    createdBy: string;
                    updatedAt?: string | null;
                    updatedBy?: string | null;
                    text?: string | null;
                    noteType: string;
                    options?: string | null;
                  } | null;
                  createdAt: string;
                  createdBy: string;
                  text?: string | null;
                  updatedAt?: string | null;
                } | null>;
                nextToken?: string | null;
              } | null;
            } | null;
            comment: string;
            createdAt: string;
            createdBy: string;
            updatedAt?: string | null;
          } | null>;
          nextToken?: string | null;
        } | null;
        historys?: {
          __typename: "ModelNoteHistoryConnection";
          items: Array<{
            __typename: "NoteHistory";
            id: string;
            organizationID: string;
            noteID: string;
            note?: {
              __typename: "Note";
              id: string;
              organizationID: string;
              objectID: string;
              createdAt: string;
              createdBy: string;
              updatedAt?: string | null;
              updatedBy?: string | null;
              text?: string | null;
              noteType: string;
              options?: string | null;
              comments?: {
                __typename: "ModelNoteCommentConnection";
                items: Array<{
                  __typename: "NoteComment";
                  id: string;
                  organizationID: string;
                  noteID: string;
                  note?: {
                    __typename: "Note";
                    id: string;
                    organizationID: string;
                    objectID: string;
                    createdAt: string;
                    createdBy: string;
                    updatedAt?: string | null;
                    updatedBy?: string | null;
                    text?: string | null;
                    noteType: string;
                    options?: string | null;
                  } | null;
                  comment: string;
                  createdAt: string;
                  createdBy: string;
                  updatedAt?: string | null;
                } | null>;
                nextToken?: string | null;
              } | null;
              historys?: {
                __typename: "ModelNoteHistoryConnection";
                items: Array<{
                  __typename: "NoteHistory";
                  id: string;
                  organizationID: string;
                  noteID: string;
                  note?: {
                    __typename: "Note";
                    id: string;
                    organizationID: string;
                    objectID: string;
                    createdAt: string;
                    createdBy: string;
                    updatedAt?: string | null;
                    updatedBy?: string | null;
                    text?: string | null;
                    noteType: string;
                    options?: string | null;
                  } | null;
                  createdAt: string;
                  createdBy: string;
                  text?: string | null;
                  updatedAt?: string | null;
                } | null>;
                nextToken?: string | null;
              } | null;
            } | null;
            createdAt: string;
            createdBy: string;
            text?: string | null;
            updatedAt?: string | null;
          } | null>;
          nextToken?: string | null;
        } | null;
      } | null;
      comment: string;
      createdAt: string;
      createdBy: string;
      updatedAt?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  historys?: {
    __typename: "ModelNoteHistoryConnection";
    items: Array<{
      __typename: "NoteHistory";
      id: string;
      organizationID: string;
      noteID: string;
      note?: {
        __typename: "Note";
        id: string;
        organizationID: string;
        objectID: string;
        createdAt: string;
        createdBy: string;
        updatedAt?: string | null;
        updatedBy?: string | null;
        text?: string | null;
        noteType: string;
        options?: string | null;
        comments?: {
          __typename: "ModelNoteCommentConnection";
          items: Array<{
            __typename: "NoteComment";
            id: string;
            organizationID: string;
            noteID: string;
            note?: {
              __typename: "Note";
              id: string;
              organizationID: string;
              objectID: string;
              createdAt: string;
              createdBy: string;
              updatedAt?: string | null;
              updatedBy?: string | null;
              text?: string | null;
              noteType: string;
              options?: string | null;
              comments?: {
                __typename: "ModelNoteCommentConnection";
                items: Array<{
                  __typename: "NoteComment";
                  id: string;
                  organizationID: string;
                  noteID: string;
                  note?: {
                    __typename: "Note";
                    id: string;
                    organizationID: string;
                    objectID: string;
                    createdAt: string;
                    createdBy: string;
                    updatedAt?: string | null;
                    updatedBy?: string | null;
                    text?: string | null;
                    noteType: string;
                    options?: string | null;
                  } | null;
                  comment: string;
                  createdAt: string;
                  createdBy: string;
                  updatedAt?: string | null;
                } | null>;
                nextToken?: string | null;
              } | null;
              historys?: {
                __typename: "ModelNoteHistoryConnection";
                items: Array<{
                  __typename: "NoteHistory";
                  id: string;
                  organizationID: string;
                  noteID: string;
                  note?: {
                    __typename: "Note";
                    id: string;
                    organizationID: string;
                    objectID: string;
                    createdAt: string;
                    createdBy: string;
                    updatedAt?: string | null;
                    updatedBy?: string | null;
                    text?: string | null;
                    noteType: string;
                    options?: string | null;
                  } | null;
                  createdAt: string;
                  createdBy: string;
                  text?: string | null;
                  updatedAt?: string | null;
                } | null>;
                nextToken?: string | null;
              } | null;
            } | null;
            comment: string;
            createdAt: string;
            createdBy: string;
            updatedAt?: string | null;
          } | null>;
          nextToken?: string | null;
        } | null;
        historys?: {
          __typename: "ModelNoteHistoryConnection";
          items: Array<{
            __typename: "NoteHistory";
            id: string;
            organizationID: string;
            noteID: string;
            note?: {
              __typename: "Note";
              id: string;
              organizationID: string;
              objectID: string;
              createdAt: string;
              createdBy: string;
              updatedAt?: string | null;
              updatedBy?: string | null;
              text?: string | null;
              noteType: string;
              options?: string | null;
              comments?: {
                __typename: "ModelNoteCommentConnection";
                items: Array<{
                  __typename: "NoteComment";
                  id: string;
                  organizationID: string;
                  noteID: string;
                  note?: {
                    __typename: "Note";
                    id: string;
                    organizationID: string;
                    objectID: string;
                    createdAt: string;
                    createdBy: string;
                    updatedAt?: string | null;
                    updatedBy?: string | null;
                    text?: string | null;
                    noteType: string;
                    options?: string | null;
                  } | null;
                  comment: string;
                  createdAt: string;
                  createdBy: string;
                  updatedAt?: string | null;
                } | null>;
                nextToken?: string | null;
              } | null;
              historys?: {
                __typename: "ModelNoteHistoryConnection";
                items: Array<{
                  __typename: "NoteHistory";
                  id: string;
                  organizationID: string;
                  noteID: string;
                  note?: {
                    __typename: "Note";
                    id: string;
                    organizationID: string;
                    objectID: string;
                    createdAt: string;
                    createdBy: string;
                    updatedAt?: string | null;
                    updatedBy?: string | null;
                    text?: string | null;
                    noteType: string;
                    options?: string | null;
                  } | null;
                  createdAt: string;
                  createdBy: string;
                  text?: string | null;
                  updatedAt?: string | null;
                } | null>;
                nextToken?: string | null;
              } | null;
            } | null;
            createdAt: string;
            createdBy: string;
            text?: string | null;
            updatedAt?: string | null;
          } | null>;
          nextToken?: string | null;
        } | null;
      } | null;
      createdAt: string;
      createdBy: string;
      text?: string | null;
      updatedAt?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type ListNotesQuery = {
  __typename: "ModelNoteConnection";
  items: Array<{
    __typename: "Note";
    id: string;
    organizationID: string;
    objectID: string;
    createdAt: string;
    createdBy: string;
    updatedAt?: string | null;
    updatedBy?: string | null;
    text?: string | null;
    noteType: string;
    options?: string | null;
    comments?: {
      __typename: "ModelNoteCommentConnection";
      items: Array<{
        __typename: "NoteComment";
        id: string;
        organizationID: string;
        noteID: string;
        note?: {
          __typename: "Note";
          id: string;
          organizationID: string;
          objectID: string;
          createdAt: string;
          createdBy: string;
          updatedAt?: string | null;
          updatedBy?: string | null;
          text?: string | null;
          noteType: string;
          options?: string | null;
          comments?: {
            __typename: "ModelNoteCommentConnection";
            items: Array<{
              __typename: "NoteComment";
              id: string;
              organizationID: string;
              noteID: string;
              note?: {
                __typename: "Note";
                id: string;
                organizationID: string;
                objectID: string;
                createdAt: string;
                createdBy: string;
                updatedAt?: string | null;
                updatedBy?: string | null;
                text?: string | null;
                noteType: string;
                options?: string | null;
                comments?: {
                  __typename: "ModelNoteCommentConnection";
                  items: Array<{
                    __typename: "NoteComment";
                    id: string;
                    organizationID: string;
                    noteID: string;
                    comment: string;
                    createdAt: string;
                    createdBy: string;
                    updatedAt?: string | null;
                  } | null>;
                  nextToken?: string | null;
                } | null;
                historys?: {
                  __typename: "ModelNoteHistoryConnection";
                  items: Array<{
                    __typename: "NoteHistory";
                    id: string;
                    organizationID: string;
                    noteID: string;
                    createdAt: string;
                    createdBy: string;
                    text?: string | null;
                    updatedAt?: string | null;
                  } | null>;
                  nextToken?: string | null;
                } | null;
              } | null;
              comment: string;
              createdAt: string;
              createdBy: string;
              updatedAt?: string | null;
            } | null>;
            nextToken?: string | null;
          } | null;
          historys?: {
            __typename: "ModelNoteHistoryConnection";
            items: Array<{
              __typename: "NoteHistory";
              id: string;
              organizationID: string;
              noteID: string;
              note?: {
                __typename: "Note";
                id: string;
                organizationID: string;
                objectID: string;
                createdAt: string;
                createdBy: string;
                updatedAt?: string | null;
                updatedBy?: string | null;
                text?: string | null;
                noteType: string;
                options?: string | null;
                comments?: {
                  __typename: "ModelNoteCommentConnection";
                  items: Array<{
                    __typename: "NoteComment";
                    id: string;
                    organizationID: string;
                    noteID: string;
                    comment: string;
                    createdAt: string;
                    createdBy: string;
                    updatedAt?: string | null;
                  } | null>;
                  nextToken?: string | null;
                } | null;
                historys?: {
                  __typename: "ModelNoteHistoryConnection";
                  items: Array<{
                    __typename: "NoteHistory";
                    id: string;
                    organizationID: string;
                    noteID: string;
                    createdAt: string;
                    createdBy: string;
                    text?: string | null;
                    updatedAt?: string | null;
                  } | null>;
                  nextToken?: string | null;
                } | null;
              } | null;
              createdAt: string;
              createdBy: string;
              text?: string | null;
              updatedAt?: string | null;
            } | null>;
            nextToken?: string | null;
          } | null;
        } | null;
        comment: string;
        createdAt: string;
        createdBy: string;
        updatedAt?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    historys?: {
      __typename: "ModelNoteHistoryConnection";
      items: Array<{
        __typename: "NoteHistory";
        id: string;
        organizationID: string;
        noteID: string;
        note?: {
          __typename: "Note";
          id: string;
          organizationID: string;
          objectID: string;
          createdAt: string;
          createdBy: string;
          updatedAt?: string | null;
          updatedBy?: string | null;
          text?: string | null;
          noteType: string;
          options?: string | null;
          comments?: {
            __typename: "ModelNoteCommentConnection";
            items: Array<{
              __typename: "NoteComment";
              id: string;
              organizationID: string;
              noteID: string;
              note?: {
                __typename: "Note";
                id: string;
                organizationID: string;
                objectID: string;
                createdAt: string;
                createdBy: string;
                updatedAt?: string | null;
                updatedBy?: string | null;
                text?: string | null;
                noteType: string;
                options?: string | null;
                comments?: {
                  __typename: "ModelNoteCommentConnection";
                  items: Array<{
                    __typename: "NoteComment";
                    id: string;
                    organizationID: string;
                    noteID: string;
                    comment: string;
                    createdAt: string;
                    createdBy: string;
                    updatedAt?: string | null;
                  } | null>;
                  nextToken?: string | null;
                } | null;
                historys?: {
                  __typename: "ModelNoteHistoryConnection";
                  items: Array<{
                    __typename: "NoteHistory";
                    id: string;
                    organizationID: string;
                    noteID: string;
                    createdAt: string;
                    createdBy: string;
                    text?: string | null;
                    updatedAt?: string | null;
                  } | null>;
                  nextToken?: string | null;
                } | null;
              } | null;
              comment: string;
              createdAt: string;
              createdBy: string;
              updatedAt?: string | null;
            } | null>;
            nextToken?: string | null;
          } | null;
          historys?: {
            __typename: "ModelNoteHistoryConnection";
            items: Array<{
              __typename: "NoteHistory";
              id: string;
              organizationID: string;
              noteID: string;
              note?: {
                __typename: "Note";
                id: string;
                organizationID: string;
                objectID: string;
                createdAt: string;
                createdBy: string;
                updatedAt?: string | null;
                updatedBy?: string | null;
                text?: string | null;
                noteType: string;
                options?: string | null;
                comments?: {
                  __typename: "ModelNoteCommentConnection";
                  items: Array<{
                    __typename: "NoteComment";
                    id: string;
                    organizationID: string;
                    noteID: string;
                    comment: string;
                    createdAt: string;
                    createdBy: string;
                    updatedAt?: string | null;
                  } | null>;
                  nextToken?: string | null;
                } | null;
                historys?: {
                  __typename: "ModelNoteHistoryConnection";
                  items: Array<{
                    __typename: "NoteHistory";
                    id: string;
                    organizationID: string;
                    noteID: string;
                    createdAt: string;
                    createdBy: string;
                    text?: string | null;
                    updatedAt?: string | null;
                  } | null>;
                  nextToken?: string | null;
                } | null;
              } | null;
              createdAt: string;
              createdBy: string;
              text?: string | null;
              updatedAt?: string | null;
            } | null>;
            nextToken?: string | null;
          } | null;
        } | null;
        createdAt: string;
        createdBy: string;
        text?: string | null;
        updatedAt?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null>;
  nextToken?: string | null;
};

export type GetNoteHistoryQuery = {
  __typename: "NoteHistory";
  id: string;
  organizationID: string;
  noteID: string;
  note?: {
    __typename: "Note";
    id: string;
    organizationID: string;
    objectID: string;
    createdAt: string;
    createdBy: string;
    updatedAt?: string | null;
    updatedBy?: string | null;
    text?: string | null;
    noteType: string;
    options?: string | null;
    comments?: {
      __typename: "ModelNoteCommentConnection";
      items: Array<{
        __typename: "NoteComment";
        id: string;
        organizationID: string;
        noteID: string;
        note?: {
          __typename: "Note";
          id: string;
          organizationID: string;
          objectID: string;
          createdAt: string;
          createdBy: string;
          updatedAt?: string | null;
          updatedBy?: string | null;
          text?: string | null;
          noteType: string;
          options?: string | null;
          comments?: {
            __typename: "ModelNoteCommentConnection";
            items: Array<{
              __typename: "NoteComment";
              id: string;
              organizationID: string;
              noteID: string;
              note?: {
                __typename: "Note";
                id: string;
                organizationID: string;
                objectID: string;
                createdAt: string;
                createdBy: string;
                updatedAt?: string | null;
                updatedBy?: string | null;
                text?: string | null;
                noteType: string;
                options?: string | null;
                comments?: {
                  __typename: "ModelNoteCommentConnection";
                  items: Array<{
                    __typename: "NoteComment";
                    id: string;
                    organizationID: string;
                    noteID: string;
                    comment: string;
                    createdAt: string;
                    createdBy: string;
                    updatedAt?: string | null;
                  } | null>;
                  nextToken?: string | null;
                } | null;
                historys?: {
                  __typename: "ModelNoteHistoryConnection";
                  items: Array<{
                    __typename: "NoteHistory";
                    id: string;
                    organizationID: string;
                    noteID: string;
                    createdAt: string;
                    createdBy: string;
                    text?: string | null;
                    updatedAt?: string | null;
                  } | null>;
                  nextToken?: string | null;
                } | null;
              } | null;
              comment: string;
              createdAt: string;
              createdBy: string;
              updatedAt?: string | null;
            } | null>;
            nextToken?: string | null;
          } | null;
          historys?: {
            __typename: "ModelNoteHistoryConnection";
            items: Array<{
              __typename: "NoteHistory";
              id: string;
              organizationID: string;
              noteID: string;
              note?: {
                __typename: "Note";
                id: string;
                organizationID: string;
                objectID: string;
                createdAt: string;
                createdBy: string;
                updatedAt?: string | null;
                updatedBy?: string | null;
                text?: string | null;
                noteType: string;
                options?: string | null;
                comments?: {
                  __typename: "ModelNoteCommentConnection";
                  items: Array<{
                    __typename: "NoteComment";
                    id: string;
                    organizationID: string;
                    noteID: string;
                    comment: string;
                    createdAt: string;
                    createdBy: string;
                    updatedAt?: string | null;
                  } | null>;
                  nextToken?: string | null;
                } | null;
                historys?: {
                  __typename: "ModelNoteHistoryConnection";
                  items: Array<{
                    __typename: "NoteHistory";
                    id: string;
                    organizationID: string;
                    noteID: string;
                    createdAt: string;
                    createdBy: string;
                    text?: string | null;
                    updatedAt?: string | null;
                  } | null>;
                  nextToken?: string | null;
                } | null;
              } | null;
              createdAt: string;
              createdBy: string;
              text?: string | null;
              updatedAt?: string | null;
            } | null>;
            nextToken?: string | null;
          } | null;
        } | null;
        comment: string;
        createdAt: string;
        createdBy: string;
        updatedAt?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    historys?: {
      __typename: "ModelNoteHistoryConnection";
      items: Array<{
        __typename: "NoteHistory";
        id: string;
        organizationID: string;
        noteID: string;
        note?: {
          __typename: "Note";
          id: string;
          organizationID: string;
          objectID: string;
          createdAt: string;
          createdBy: string;
          updatedAt?: string | null;
          updatedBy?: string | null;
          text?: string | null;
          noteType: string;
          options?: string | null;
          comments?: {
            __typename: "ModelNoteCommentConnection";
            items: Array<{
              __typename: "NoteComment";
              id: string;
              organizationID: string;
              noteID: string;
              note?: {
                __typename: "Note";
                id: string;
                organizationID: string;
                objectID: string;
                createdAt: string;
                createdBy: string;
                updatedAt?: string | null;
                updatedBy?: string | null;
                text?: string | null;
                noteType: string;
                options?: string | null;
                comments?: {
                  __typename: "ModelNoteCommentConnection";
                  items: Array<{
                    __typename: "NoteComment";
                    id: string;
                    organizationID: string;
                    noteID: string;
                    comment: string;
                    createdAt: string;
                    createdBy: string;
                    updatedAt?: string | null;
                  } | null>;
                  nextToken?: string | null;
                } | null;
                historys?: {
                  __typename: "ModelNoteHistoryConnection";
                  items: Array<{
                    __typename: "NoteHistory";
                    id: string;
                    organizationID: string;
                    noteID: string;
                    createdAt: string;
                    createdBy: string;
                    text?: string | null;
                    updatedAt?: string | null;
                  } | null>;
                  nextToken?: string | null;
                } | null;
              } | null;
              comment: string;
              createdAt: string;
              createdBy: string;
              updatedAt?: string | null;
            } | null>;
            nextToken?: string | null;
          } | null;
          historys?: {
            __typename: "ModelNoteHistoryConnection";
            items: Array<{
              __typename: "NoteHistory";
              id: string;
              organizationID: string;
              noteID: string;
              note?: {
                __typename: "Note";
                id: string;
                organizationID: string;
                objectID: string;
                createdAt: string;
                createdBy: string;
                updatedAt?: string | null;
                updatedBy?: string | null;
                text?: string | null;
                noteType: string;
                options?: string | null;
                comments?: {
                  __typename: "ModelNoteCommentConnection";
                  items: Array<{
                    __typename: "NoteComment";
                    id: string;
                    organizationID: string;
                    noteID: string;
                    comment: string;
                    createdAt: string;
                    createdBy: string;
                    updatedAt?: string | null;
                  } | null>;
                  nextToken?: string | null;
                } | null;
                historys?: {
                  __typename: "ModelNoteHistoryConnection";
                  items: Array<{
                    __typename: "NoteHistory";
                    id: string;
                    organizationID: string;
                    noteID: string;
                    createdAt: string;
                    createdBy: string;
                    text?: string | null;
                    updatedAt?: string | null;
                  } | null>;
                  nextToken?: string | null;
                } | null;
              } | null;
              createdAt: string;
              createdBy: string;
              text?: string | null;
              updatedAt?: string | null;
            } | null>;
            nextToken?: string | null;
          } | null;
        } | null;
        createdAt: string;
        createdBy: string;
        text?: string | null;
        updatedAt?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null;
  createdAt: string;
  createdBy: string;
  text?: string | null;
  updatedAt?: string | null;
};

export type ListNoteHistorysQuery = {
  __typename: "ModelNoteHistoryConnection";
  items: Array<{
    __typename: "NoteHistory";
    id: string;
    organizationID: string;
    noteID: string;
    note?: {
      __typename: "Note";
      id: string;
      organizationID: string;
      objectID: string;
      createdAt: string;
      createdBy: string;
      updatedAt?: string | null;
      updatedBy?: string | null;
      text?: string | null;
      noteType: string;
      options?: string | null;
      comments?: {
        __typename: "ModelNoteCommentConnection";
        items: Array<{
          __typename: "NoteComment";
          id: string;
          organizationID: string;
          noteID: string;
          note?: {
            __typename: "Note";
            id: string;
            organizationID: string;
            objectID: string;
            createdAt: string;
            createdBy: string;
            updatedAt?: string | null;
            updatedBy?: string | null;
            text?: string | null;
            noteType: string;
            options?: string | null;
            comments?: {
              __typename: "ModelNoteCommentConnection";
              items: Array<{
                __typename: "NoteComment";
                id: string;
                organizationID: string;
                noteID: string;
                note?: {
                  __typename: "Note";
                  id: string;
                  organizationID: string;
                  objectID: string;
                  createdAt: string;
                  createdBy: string;
                  updatedAt?: string | null;
                  updatedBy?: string | null;
                  text?: string | null;
                  noteType: string;
                  options?: string | null;
                  comments?: {
                    __typename: "ModelNoteCommentConnection";
                    nextToken?: string | null;
                  } | null;
                  historys?: {
                    __typename: "ModelNoteHistoryConnection";
                    nextToken?: string | null;
                  } | null;
                } | null;
                comment: string;
                createdAt: string;
                createdBy: string;
                updatedAt?: string | null;
              } | null>;
              nextToken?: string | null;
            } | null;
            historys?: {
              __typename: "ModelNoteHistoryConnection";
              items: Array<{
                __typename: "NoteHistory";
                id: string;
                organizationID: string;
                noteID: string;
                note?: {
                  __typename: "Note";
                  id: string;
                  organizationID: string;
                  objectID: string;
                  createdAt: string;
                  createdBy: string;
                  updatedAt?: string | null;
                  updatedBy?: string | null;
                  text?: string | null;
                  noteType: string;
                  options?: string | null;
                  comments?: {
                    __typename: "ModelNoteCommentConnection";
                    nextToken?: string | null;
                  } | null;
                  historys?: {
                    __typename: "ModelNoteHistoryConnection";
                    nextToken?: string | null;
                  } | null;
                } | null;
                createdAt: string;
                createdBy: string;
                text?: string | null;
                updatedAt?: string | null;
              } | null>;
              nextToken?: string | null;
            } | null;
          } | null;
          comment: string;
          createdAt: string;
          createdBy: string;
          updatedAt?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      historys?: {
        __typename: "ModelNoteHistoryConnection";
        items: Array<{
          __typename: "NoteHistory";
          id: string;
          organizationID: string;
          noteID: string;
          note?: {
            __typename: "Note";
            id: string;
            organizationID: string;
            objectID: string;
            createdAt: string;
            createdBy: string;
            updatedAt?: string | null;
            updatedBy?: string | null;
            text?: string | null;
            noteType: string;
            options?: string | null;
            comments?: {
              __typename: "ModelNoteCommentConnection";
              items: Array<{
                __typename: "NoteComment";
                id: string;
                organizationID: string;
                noteID: string;
                note?: {
                  __typename: "Note";
                  id: string;
                  organizationID: string;
                  objectID: string;
                  createdAt: string;
                  createdBy: string;
                  updatedAt?: string | null;
                  updatedBy?: string | null;
                  text?: string | null;
                  noteType: string;
                  options?: string | null;
                  comments?: {
                    __typename: "ModelNoteCommentConnection";
                    nextToken?: string | null;
                  } | null;
                  historys?: {
                    __typename: "ModelNoteHistoryConnection";
                    nextToken?: string | null;
                  } | null;
                } | null;
                comment: string;
                createdAt: string;
                createdBy: string;
                updatedAt?: string | null;
              } | null>;
              nextToken?: string | null;
            } | null;
            historys?: {
              __typename: "ModelNoteHistoryConnection";
              items: Array<{
                __typename: "NoteHistory";
                id: string;
                organizationID: string;
                noteID: string;
                note?: {
                  __typename: "Note";
                  id: string;
                  organizationID: string;
                  objectID: string;
                  createdAt: string;
                  createdBy: string;
                  updatedAt?: string | null;
                  updatedBy?: string | null;
                  text?: string | null;
                  noteType: string;
                  options?: string | null;
                  comments?: {
                    __typename: "ModelNoteCommentConnection";
                    nextToken?: string | null;
                  } | null;
                  historys?: {
                    __typename: "ModelNoteHistoryConnection";
                    nextToken?: string | null;
                  } | null;
                } | null;
                createdAt: string;
                createdBy: string;
                text?: string | null;
                updatedAt?: string | null;
              } | null>;
              nextToken?: string | null;
            } | null;
          } | null;
          createdAt: string;
          createdBy: string;
          text?: string | null;
          updatedAt?: string | null;
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null;
    createdAt: string;
    createdBy: string;
    text?: string | null;
    updatedAt?: string | null;
  } | null>;
  nextToken?: string | null;
};

export type GetShoutOutTypeQuery = {
  __typename: "ShoutOutType";
  id: string;
  title: string;
  formalDescription?: string | null;
  informalDescription?: string | null;
  icon?: string | null;
  attributes: Array<string | null>;
  status?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type ListShoutOutTypesQuery = {
  __typename: "ModelShoutOutTypeConnection";
  items: Array<{
    __typename: "ShoutOutType";
    id: string;
    title: string;
    formalDescription?: string | null;
    informalDescription?: string | null;
    icon?: string | null;
    attributes: Array<string | null>;
    status?: string | null;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null>;
  nextToken?: string | null;
};

export type GetOrganizationShoutOutTypeQuery = {
  __typename: "OrganizationShoutOutType";
  id: string;
  organizationID: string;
  overrideTitle: string;
  organizationShoutOutTypeShoutOuTypeId: string;
  shoutOutType: {
    __typename: "ShoutOutType";
    id: string;
    title: string;
    formalDescription?: string | null;
    informalDescription?: string | null;
    icon?: string | null;
    attributes: Array<string | null>;
    status?: string | null;
    createdAt?: string | null;
    updatedAt?: string | null;
  };
  description: string;
  overrideIcon?: string | null;
  overrideIconIdentityId?: string | null;
  enabled?: boolean | null;
  organizationAttributes?: Array<string | null> | null;
  disabledAttributes?: Array<number | null> | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type ListOrganizationShoutOutTypesQuery = {
  __typename: "ModelOrganizationShoutOutTypeConnection";
  items: Array<{
    __typename: "OrganizationShoutOutType";
    id: string;
    organizationID: string;
    overrideTitle: string;
    organizationShoutOutTypeShoutOuTypeId: string;
    shoutOutType: {
      __typename: "ShoutOutType";
      id: string;
      title: string;
      formalDescription?: string | null;
      informalDescription?: string | null;
      icon?: string | null;
      attributes: Array<string | null>;
      status?: string | null;
      createdAt?: string | null;
      updatedAt?: string | null;
    };
    description: string;
    overrideIcon?: string | null;
    overrideIconIdentityId?: string | null;
    enabled?: boolean | null;
    organizationAttributes?: Array<string | null> | null;
    disabledAttributes?: Array<number | null> | null;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null>;
  nextToken?: string | null;
};

export type GetStandupSurveyConfigurationQuery = {
  __typename: "StandupSurveyConfiguration";
  id: string;
  organizationID: string;
  employeeID: string;
  questions: Array<{
    __typename: "VersionedQuestion";
    id: string;
    questionTextArray: Array<string | null>;
    enabled?: boolean | null;
  } | null>;
  shared?: Array<string | null> | null;
  excluded?: Array<string | null> | null;
  frequency: string;
  lastSurveyedDate?: string | null;
  isoWeekday?: number | null;
  status?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type ListStandupSurveyConfigurationsQuery = {
  __typename: "ModelStandupSurveyConfigurationConnection";
  items: Array<{
    __typename: "StandupSurveyConfiguration";
    id: string;
    organizationID: string;
    employeeID: string;
    questions: Array<{
      __typename: "VersionedQuestion";
      id: string;
      questionTextArray: Array<string | null>;
      enabled?: boolean | null;
    } | null>;
    shared?: Array<string | null> | null;
    excluded?: Array<string | null> | null;
    frequency: string;
    lastSurveyedDate?: string | null;
    isoWeekday?: number | null;
    status?: string | null;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null>;
  nextToken?: string | null;
};

export type GetStandupSurveyQuery = {
  __typename: "StandupSurvey";
  id: string;
  organizationID: string;
  standupSurveyConfigurationID: string;
  questionMaps: Array<{
    __typename: "QuestionMap";
    id: string;
    version: number;
  } | null>;
  respondentEmail: string;
  respondentID: string;
  surveyDate: string;
  feedbackArray?: Array<string | null> | null;
  chatRequest?: boolean | null;
  meetingRequest?: boolean | null;
  responseReceived?: boolean | null;
  responseReceivedDate?: string | null;
  status?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type ListStandupSurveysQuery = {
  __typename: "ModelStandupSurveyConnection";
  items: Array<{
    __typename: "StandupSurvey";
    id: string;
    organizationID: string;
    standupSurveyConfigurationID: string;
    questionMaps: Array<{
      __typename: "QuestionMap";
      id: string;
      version: number;
    } | null>;
    respondentEmail: string;
    respondentID: string;
    surveyDate: string;
    feedbackArray?: Array<string | null> | null;
    chatRequest?: boolean | null;
    meetingRequest?: boolean | null;
    responseReceived?: boolean | null;
    responseReceivedDate?: string | null;
    status?: string | null;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null>;
  nextToken?: string | null;
};

export type EmployeeByOrgQuery = {
  __typename: "ModelEmployeeConnection";
  items: Array<{
    __typename: "Employee";
    id: string;
    authId?: string | null;
    email: string;
    orgId: string;
    groups?: Array<string | null> | null;
    consulting?: Array<string | null> | null;
    managerID?: string | null;
    subordinates: Array<string | null>;
    roles?: Array<string | null> | null;
    firstName: string;
    lastName: string;
    title: string;
    department?: string | null;
    disabled?: boolean | null;
    disabledDate?: string | null;
    disabledBy?: string | null;
    hireDate?: string | null;
    managerChangeDate?: string | null;
    lastAnnual?: string | null;
    lastOneOnOne?: string | null;
    positionDate?: string | null;
    previousPositionStartDate?: string | null;
    previousPositionTitle?: string | null;
    validEmailToken?: boolean | null;
    inviteSent?: string | null;
    readiness?: string | null;
    criticalPosition?: boolean | null;
    createdAt?: string | null;
    updatedAt?: string | null;
    readinessAssessmentDate?: string | null;
    image?: string | null;
    imageIdentityId?: string | null;
    bio?: string | null;
    careerGoals?: string | null;
    relocate?: string | null;
    relocateDate?: string | null;
    workStatus?: string | null;
    workStatusDate?: string | null;
  } | null>;
  nextToken?: string | null;
};

export type ItemsByEmployeeQuery = {
  __typename: "ModelConsultantAssociationConnection";
  items: Array<{
    __typename: "ConsultantAssociation";
    employeeID: string;
    organizationID: string;
    incentive?: boolean | null;
    status?: string | null;
    options?: string | null;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null>;
  nextToken?: string | null;
};

export type ObjectiveByOrgQuery = {
  __typename: "ModelObjectiveConnection";
  items: Array<{
    __typename: "Objective";
    id: string;
    display: string;
    definition: string;
    observables: Array<string>;
    hints?: Array<string | null> | null;
    organizationID?: string | null;
    organizations?: Array<string | null> | null;
    status?: string | null;
    domain: {
      __typename: "ObjectiveDomain";
      id: string;
      key: string;
      display: string;
      excludeFromTPS?: boolean | null;
      sites: Array<string>;
      createdAt?: string | null;
      updatedAt?: string | null;
    };
    objectiveDomainId: string;
    qrCodes?: Array<{
      __typename: "QR";
      type: string;
      imageSrc: string;
      title: string;
      label: string;
      qrdata: string;
    } | null> | null;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null>;
  nextToken?: string | null;
};

export type SurveysByRespondentTypeDateQuery = {
  __typename: "ModelSurveyConnection";
  items: Array<{
    __typename: "Survey";
    id: string;
    organizationID: string;
    objectID: string;
    surveyType: SurveyType;
    surveyDate: string;
    respondentEmail: string;
    participantEmail: string;
    respondentAttended?: boolean | null;
    surveyResponse?: number | null;
    feedback?: string | null;
    responseReceived?: boolean | null;
    responseReceivedDate?: string | null;
    status?: string | null;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null>;
  nextToken?: string | null;
};

export type SurveysByParticipantTypeDateQuery = {
  __typename: "ModelSurveyConnection";
  items: Array<{
    __typename: "Survey";
    id: string;
    organizationID: string;
    objectID: string;
    surveyType: SurveyType;
    surveyDate: string;
    respondentEmail: string;
    participantEmail: string;
    respondentAttended?: boolean | null;
    surveyResponse?: number | null;
    feedback?: string | null;
    responseReceived?: boolean | null;
    responseReceivedDate?: string | null;
    status?: string | null;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null>;
  nextToken?: string | null;
};

export type SurveysByOrgObjectDateQuery = {
  __typename: "ModelSurveyConnection";
  items: Array<{
    __typename: "Survey";
    id: string;
    organizationID: string;
    objectID: string;
    surveyType: SurveyType;
    surveyDate: string;
    respondentEmail: string;
    participantEmail: string;
    respondentAttended?: boolean | null;
    surveyResponse?: number | null;
    feedback?: string | null;
    responseReceived?: boolean | null;
    responseReceivedDate?: string | null;
    status?: string | null;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null>;
  nextToken?: string | null;
};

export type SurveysByOrgTypeDateQuery = {
  __typename: "ModelSurveyConnection";
  items: Array<{
    __typename: "Survey";
    id: string;
    organizationID: string;
    objectID: string;
    surveyType: SurveyType;
    surveyDate: string;
    respondentEmail: string;
    participantEmail: string;
    respondentAttended?: boolean | null;
    surveyResponse?: number | null;
    feedback?: string | null;
    responseReceived?: boolean | null;
    responseReceivedDate?: string | null;
    status?: string | null;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null>;
  nextToken?: string | null;
};

export type GetEmployeeImageQuery = {
  __typename: "EmployeeImage";
  employeeID: string;
  organizationID: string;
  groups?: Array<string | null> | null;
  image?: string | null;
  imageIdentityId?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type ListEmployeeImagesQuery = {
  __typename: "ModelEmployeeImageConnection";
  items: Array<{
    __typename: "EmployeeImage";
    employeeID: string;
    organizationID: string;
    groups?: Array<string | null> | null;
    image?: string | null;
    imageIdentityId?: string | null;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null>;
  nextToken?: string | null;
};

export type GetExternalSurveyQuery = {
  __typename: "ExternalSurvey";
  id: string;
  surveyType: SurveyType;
  surveyDate?: string | null;
  externalRespondentID: string;
  participant: {
    __typename: "Participant";
    id: string;
    participantName: string;
  };
  objectiveDetails?: {
    __typename: "ObjectiveDetails";
    id: string;
    objectiveDisplay?: string | null;
    objectiveDefinition?: string | null;
    businessObjective?: string | null;
    managerDescription?: string | null;
    objectiveObservables?: Array<string | null> | null;
  } | null;
  status?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  respondentAttended?: boolean | null;
  surveyResponse?: number | null;
  feedback?: string | null;
  responseReceived?: boolean | null;
  responseReceivedDate?: string | null;
};

export type ListExternalSurveysQuery = {
  __typename: "ModelExternalSurveyConnection";
  items: Array<{
    __typename: "ExternalSurvey";
    id: string;
    surveyType: SurveyType;
    surveyDate?: string | null;
    externalRespondentID: string;
    participant: {
      __typename: "Participant";
      id: string;
      participantName: string;
    };
    objectiveDetails?: {
      __typename: "ObjectiveDetails";
      id: string;
      objectiveDisplay?: string | null;
      objectiveDefinition?: string | null;
      businessObjective?: string | null;
      managerDescription?: string | null;
      objectiveObservables?: Array<string | null> | null;
    } | null;
    status?: string | null;
    createdAt?: string | null;
    updatedAt?: string | null;
    respondentAttended?: boolean | null;
    surveyResponse?: number | null;
    feedback?: string | null;
    responseReceived?: boolean | null;
    responseReceivedDate?: string | null;
  } | null>;
  nextToken?: string | null;
};

export type OnCreateOrganizationSubscription = {
  __typename: "Organization";
  id: string;
  orgName: string;
  domains: Array<string>;
  site: string;
  disabled?: boolean | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type OnUpdateOrganizationSubscription = {
  __typename: "Organization";
  id: string;
  orgName: string;
  domains: Array<string>;
  site: string;
  disabled?: boolean | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type OnDeleteOrganizationSubscription = {
  __typename: "Organization";
  id: string;
  orgName: string;
  domains: Array<string>;
  site: string;
  disabled?: boolean | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type OnCreateStripeSubscription = {
  __typename: "Stripe";
  organizationID: string;
  customerID?: string | null;
  subscriptionID?: string | null;
  subscriptionItemID?: string | null;
  planID?: string | null;
  billingName?: string | null;
  billingEmail?: string | null;
  cardToken?: string | null;
  subscriptionStatus?: string | null;
  hmac?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type OnUpdateStripeSubscription = {
  __typename: "Stripe";
  organizationID: string;
  customerID?: string | null;
  subscriptionID?: string | null;
  subscriptionItemID?: string | null;
  planID?: string | null;
  billingName?: string | null;
  billingEmail?: string | null;
  cardToken?: string | null;
  subscriptionStatus?: string | null;
  hmac?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type OnDeleteStripeSubscription = {
  __typename: "Stripe";
  organizationID: string;
  customerID?: string | null;
  subscriptionID?: string | null;
  subscriptionItemID?: string | null;
  planID?: string | null;
  billingName?: string | null;
  billingEmail?: string | null;
  cardToken?: string | null;
  subscriptionStatus?: string | null;
  hmac?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type OnCreateProcessLogSubscription = {
  __typename: "ProcessLog";
  process: string;
  lastRunTime: string;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type OnUpdateProcessLogSubscription = {
  __typename: "ProcessLog";
  process: string;
  lastRunTime: string;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type OnDeleteProcessLogSubscription = {
  __typename: "ProcessLog";
  process: string;
  lastRunTime: string;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type OnCreateOrganizationConfigurationSubscription = {
  __typename: "OrganizationConfiguration";
  id: string;
  organizationID: string;
  configCode: string;
  configStringValue?: string | null;
  configIntValue?: number | null;
  configBooleanValue?: boolean | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type OnUpdateOrganizationConfigurationSubscription = {
  __typename: "OrganizationConfiguration";
  id: string;
  organizationID: string;
  configCode: string;
  configStringValue?: string | null;
  configIntValue?: number | null;
  configBooleanValue?: boolean | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type OnDeleteOrganizationConfigurationSubscription = {
  __typename: "OrganizationConfiguration";
  id: string;
  organizationID: string;
  configCode: string;
  configStringValue?: string | null;
  configIntValue?: number | null;
  configBooleanValue?: boolean | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type OnCreateEmployeeSubscription = {
  __typename: "Employee";
  id: string;
  authId?: string | null;
  email: string;
  orgId: string;
  groups?: Array<string | null> | null;
  consulting?: Array<string | null> | null;
  managerID?: string | null;
  subordinates: Array<string | null>;
  roles?: Array<string | null> | null;
  firstName: string;
  lastName: string;
  title: string;
  department?: string | null;
  disabled?: boolean | null;
  disabledDate?: string | null;
  disabledBy?: string | null;
  hireDate?: string | null;
  managerChangeDate?: string | null;
  lastAnnual?: string | null;
  lastOneOnOne?: string | null;
  positionDate?: string | null;
  previousPositionStartDate?: string | null;
  previousPositionTitle?: string | null;
  validEmailToken?: boolean | null;
  inviteSent?: string | null;
  readiness?: string | null;
  criticalPosition?: boolean | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  readinessAssessmentDate?: string | null;
  image?: string | null;
  imageIdentityId?: string | null;
  bio?: string | null;
  careerGoals?: string | null;
  relocate?: string | null;
  relocateDate?: string | null;
  workStatus?: string | null;
  workStatusDate?: string | null;
};

export type OnUpdateEmployeeSubscription = {
  __typename: "Employee";
  id: string;
  authId?: string | null;
  email: string;
  orgId: string;
  groups?: Array<string | null> | null;
  consulting?: Array<string | null> | null;
  managerID?: string | null;
  subordinates: Array<string | null>;
  roles?: Array<string | null> | null;
  firstName: string;
  lastName: string;
  title: string;
  department?: string | null;
  disabled?: boolean | null;
  disabledDate?: string | null;
  disabledBy?: string | null;
  hireDate?: string | null;
  managerChangeDate?: string | null;
  lastAnnual?: string | null;
  lastOneOnOne?: string | null;
  positionDate?: string | null;
  previousPositionStartDate?: string | null;
  previousPositionTitle?: string | null;
  validEmailToken?: boolean | null;
  inviteSent?: string | null;
  readiness?: string | null;
  criticalPosition?: boolean | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  readinessAssessmentDate?: string | null;
  image?: string | null;
  imageIdentityId?: string | null;
  bio?: string | null;
  careerGoals?: string | null;
  relocate?: string | null;
  relocateDate?: string | null;
  workStatus?: string | null;
  workStatusDate?: string | null;
};

export type OnDeleteEmployeeSubscription = {
  __typename: "Employee";
  id: string;
  authId?: string | null;
  email: string;
  orgId: string;
  groups?: Array<string | null> | null;
  consulting?: Array<string | null> | null;
  managerID?: string | null;
  subordinates: Array<string | null>;
  roles?: Array<string | null> | null;
  firstName: string;
  lastName: string;
  title: string;
  department?: string | null;
  disabled?: boolean | null;
  disabledDate?: string | null;
  disabledBy?: string | null;
  hireDate?: string | null;
  managerChangeDate?: string | null;
  lastAnnual?: string | null;
  lastOneOnOne?: string | null;
  positionDate?: string | null;
  previousPositionStartDate?: string | null;
  previousPositionTitle?: string | null;
  validEmailToken?: boolean | null;
  inviteSent?: string | null;
  readiness?: string | null;
  criticalPosition?: boolean | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  readinessAssessmentDate?: string | null;
  image?: string | null;
  imageIdentityId?: string | null;
  bio?: string | null;
  careerGoals?: string | null;
  relocate?: string | null;
  relocateDate?: string | null;
  workStatus?: string | null;
  workStatusDate?: string | null;
};

export type OnCreateEmployeeDataSubscription = {
  __typename: "EmployeeData";
  employeeID: string;
  organizationID: string;
  dataCode: string;
  groups?: Array<string | null> | null;
  stringValue?: string | null;
  intValue?: number | null;
  booleanValue?: boolean | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type OnUpdateEmployeeDataSubscription = {
  __typename: "EmployeeData";
  employeeID: string;
  organizationID: string;
  dataCode: string;
  groups?: Array<string | null> | null;
  stringValue?: string | null;
  intValue?: number | null;
  booleanValue?: boolean | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type OnDeleteEmployeeDataSubscription = {
  __typename: "EmployeeData";
  employeeID: string;
  organizationID: string;
  dataCode: string;
  groups?: Array<string | null> | null;
  stringValue?: string | null;
  intValue?: number | null;
  booleanValue?: boolean | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type OnCreateReadinessHistorySubscription = {
  __typename: "ReadinessHistory";
  id: string;
  organizationID: string;
  groups: Array<string | null>;
  employeeID: string;
  readiness: string;
  comments?: string | null;
  opportunities?: Array<string | null> | null;
  createdAt: string;
  createdBy?: string | null;
  updatedAt?: string | null;
};

export type OnUpdateReadinessHistorySubscription = {
  __typename: "ReadinessHistory";
  id: string;
  organizationID: string;
  groups: Array<string | null>;
  employeeID: string;
  readiness: string;
  comments?: string | null;
  opportunities?: Array<string | null> | null;
  createdAt: string;
  createdBy?: string | null;
  updatedAt?: string | null;
};

export type OnDeleteReadinessHistorySubscription = {
  __typename: "ReadinessHistory";
  id: string;
  organizationID: string;
  groups: Array<string | null>;
  employeeID: string;
  readiness: string;
  comments?: string | null;
  opportunities?: Array<string | null> | null;
  createdAt: string;
  createdBy?: string | null;
  updatedAt?: string | null;
};

export type OnCreateConsultantAssociationSubscription = {
  __typename: "ConsultantAssociation";
  employeeID: string;
  organizationID: string;
  incentive?: boolean | null;
  status?: string | null;
  options?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type OnUpdateConsultantAssociationSubscription = {
  __typename: "ConsultantAssociation";
  employeeID: string;
  organizationID: string;
  incentive?: boolean | null;
  status?: string | null;
  options?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type OnDeleteConsultantAssociationSubscription = {
  __typename: "ConsultantAssociation";
  employeeID: string;
  organizationID: string;
  incentive?: boolean | null;
  status?: string | null;
  options?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type OnCreateConsultantFavoritesSubscription = {
  __typename: "ConsultantFavorites";
  employeeID: string;
  organizationID: string;
  favorites?: Array<string | null> | null;
  createdAt: string;
  updatedAt: string;
};

export type OnUpdateConsultantFavoritesSubscription = {
  __typename: "ConsultantFavorites";
  employeeID: string;
  organizationID: string;
  favorites?: Array<string | null> | null;
  createdAt: string;
  updatedAt: string;
};

export type OnDeleteConsultantFavoritesSubscription = {
  __typename: "ConsultantFavorites";
  employeeID: string;
  organizationID: string;
  favorites?: Array<string | null> | null;
  createdAt: string;
  updatedAt: string;
};

export type OnCreateUserActionSubscription = {
  __typename: "UserAction";
  employeeID: string;
  organizationID: string;
  action: string;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type OnUpdateUserActionSubscription = {
  __typename: "UserAction";
  employeeID: string;
  organizationID: string;
  action: string;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type OnDeleteUserActionSubscription = {
  __typename: "UserAction";
  employeeID: string;
  organizationID: string;
  action: string;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type OnCreateEmployeeCalendarCodeSubscription = {
  __typename: "EmployeeCalendarCode";
  employeeID: string;
  code: string;
  redirectUrl: string;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type OnUpdateEmployeeCalendarCodeSubscription = {
  __typename: "EmployeeCalendarCode";
  employeeID: string;
  code: string;
  redirectUrl: string;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type OnDeleteEmployeeCalendarCodeSubscription = {
  __typename: "EmployeeCalendarCode";
  employeeID: string;
  code: string;
  redirectUrl: string;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type OnCreateEmployeeScoreSubscription = {
  __typename: "EmployeeScore";
  id: string;
  employeeID: string;
  organizationID: string;
  score: number;
  scoreType: string;
  scoreID: string;
  scoreStart?: string | null;
  scoreEnd?: string | null;
  specifier?: string | null;
  details?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type OnUpdateEmployeeScoreSubscription = {
  __typename: "EmployeeScore";
  id: string;
  employeeID: string;
  organizationID: string;
  score: number;
  scoreType: string;
  scoreID: string;
  scoreStart?: string | null;
  scoreEnd?: string | null;
  specifier?: string | null;
  details?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type OnDeleteEmployeeScoreSubscription = {
  __typename: "EmployeeScore";
  id: string;
  employeeID: string;
  organizationID: string;
  score: number;
  scoreType: string;
  scoreID: string;
  scoreStart?: string | null;
  scoreEnd?: string | null;
  specifier?: string | null;
  details?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type OnCreateInspHRationsResponseCounterSubscription = {
  __typename: "InspHRationsResponseCounter";
  organizationID: string;
  employeeID: string;
  organizationShoutOutTypeID: string;
  type: InspHRationCounterType;
  group: string;
  count: number;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type OnUpdateInspHRationsResponseCounterSubscription = {
  __typename: "InspHRationsResponseCounter";
  organizationID: string;
  employeeID: string;
  organizationShoutOutTypeID: string;
  type: InspHRationCounterType;
  group: string;
  count: number;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type OnDeleteInspHRationsResponseCounterSubscription = {
  __typename: "InspHRationsResponseCounter";
  organizationID: string;
  employeeID: string;
  organizationShoutOutTypeID: string;
  type: InspHRationCounterType;
  group: string;
  count: number;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type OnCreateInspHRationsOrganizationResponseCounterSubscription = {
  __typename: "InspHRationsOrganizationResponseCounter";
  organizationID: string;
  employeeID: string;
  organizationShoutOutTypeID: string;
  type: InspHRationCounterType;
  group: string;
  count: number;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type OnUpdateInspHRationsOrganizationResponseCounterSubscription = {
  __typename: "InspHRationsOrganizationResponseCounter";
  organizationID: string;
  employeeID: string;
  organizationShoutOutTypeID: string;
  type: InspHRationCounterType;
  group: string;
  count: number;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type OnDeleteInspHRationsOrganizationResponseCounterSubscription = {
  __typename: "InspHRationsOrganizationResponseCounter";
  organizationID: string;
  employeeID: string;
  organizationShoutOutTypeID: string;
  type: InspHRationCounterType;
  group: string;
  count: number;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type OnCreateIntHRactionCounterSubscription = {
  __typename: "IntHRactionCounter";
  employeeID: string;
  participantID: string;
  organizationID: string;
  count: number;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type OnUpdateIntHRactionCounterSubscription = {
  __typename: "IntHRactionCounter";
  employeeID: string;
  participantID: string;
  organizationID: string;
  count: number;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type OnDeleteIntHRactionCounterSubscription = {
  __typename: "IntHRactionCounter";
  employeeID: string;
  participantID: string;
  organizationID: string;
  count: number;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type OnCreatePrioritySubscription = {
  __typename: "Priority";
  id: string;
  key: string;
  display: string;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type OnUpdatePrioritySubscription = {
  __typename: "Priority";
  id: string;
  key: string;
  display: string;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type OnDeletePrioritySubscription = {
  __typename: "Priority";
  id: string;
  key: string;
  display: string;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type OnCreateFocusSubscription = {
  __typename: "Focus";
  id: string;
  key: string;
  display: string;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type OnUpdateFocusSubscription = {
  __typename: "Focus";
  id: string;
  key: string;
  display: string;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type OnDeleteFocusSubscription = {
  __typename: "Focus";
  id: string;
  key: string;
  display: string;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type OnCreateObjectiveDomainSubscription = {
  __typename: "ObjectiveDomain";
  id: string;
  key: string;
  display: string;
  excludeFromTPS?: boolean | null;
  sites: Array<string>;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type OnUpdateObjectiveDomainSubscription = {
  __typename: "ObjectiveDomain";
  id: string;
  key: string;
  display: string;
  excludeFromTPS?: boolean | null;
  sites: Array<string>;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type OnDeleteObjectiveDomainSubscription = {
  __typename: "ObjectiveDomain";
  id: string;
  key: string;
  display: string;
  excludeFromTPS?: boolean | null;
  sites: Array<string>;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type OnCreateObjectiveSubscription = {
  __typename: "Objective";
  id: string;
  display: string;
  definition: string;
  observables: Array<string>;
  hints?: Array<string | null> | null;
  organizationID?: string | null;
  organizations?: Array<string | null> | null;
  status?: string | null;
  domain: {
    __typename: "ObjectiveDomain";
    id: string;
    key: string;
    display: string;
    excludeFromTPS?: boolean | null;
    sites: Array<string>;
    createdAt?: string | null;
    updatedAt?: string | null;
  };
  objectiveDomainId: string;
  qrCodes?: Array<{
    __typename: "QR";
    type: string;
    imageSrc: string;
    title: string;
    label: string;
    qrdata: string;
  } | null> | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type OnUpdateObjectiveSubscription = {
  __typename: "Objective";
  id: string;
  display: string;
  definition: string;
  observables: Array<string>;
  hints?: Array<string | null> | null;
  organizationID?: string | null;
  organizations?: Array<string | null> | null;
  status?: string | null;
  domain: {
    __typename: "ObjectiveDomain";
    id: string;
    key: string;
    display: string;
    excludeFromTPS?: boolean | null;
    sites: Array<string>;
    createdAt?: string | null;
    updatedAt?: string | null;
  };
  objectiveDomainId: string;
  qrCodes?: Array<{
    __typename: "QR";
    type: string;
    imageSrc: string;
    title: string;
    label: string;
    qrdata: string;
  } | null> | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type OnDeleteObjectiveSubscription = {
  __typename: "Objective";
  id: string;
  display: string;
  definition: string;
  observables: Array<string>;
  hints?: Array<string | null> | null;
  organizationID?: string | null;
  organizations?: Array<string | null> | null;
  status?: string | null;
  domain: {
    __typename: "ObjectiveDomain";
    id: string;
    key: string;
    display: string;
    excludeFromTPS?: boolean | null;
    sites: Array<string>;
    createdAt?: string | null;
    updatedAt?: string | null;
  };
  objectiveDomainId: string;
  qrCodes?: Array<{
    __typename: "QR";
    type: string;
    imageSrc: string;
    title: string;
    label: string;
    qrdata: string;
  } | null> | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type OnCreateOrganizationObjectiveSubscription = {
  __typename: "OrganizationObjective";
  id: string;
  organizationID: string;
  objective: {
    __typename: "Objective";
    id: string;
    display: string;
    definition: string;
    observables: Array<string>;
    hints?: Array<string | null> | null;
    organizationID?: string | null;
    organizations?: Array<string | null> | null;
    status?: string | null;
    domain: {
      __typename: "ObjectiveDomain";
      id: string;
      key: string;
      display: string;
      excludeFromTPS?: boolean | null;
      sites: Array<string>;
      createdAt?: string | null;
      updatedAt?: string | null;
    };
    objectiveDomainId: string;
    qrCodes?: Array<{
      __typename: "QR";
      type: string;
      imageSrc: string;
      title: string;
      label: string;
      qrdata: string;
    } | null> | null;
    createdAt?: string | null;
    updatedAt?: string | null;
  };
  organizationObjectiveObjectiveId: string;
  enabled?: boolean | null;
  businessObjective?: string | null;
  status?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type OnUpdateOrganizationObjectiveSubscription = {
  __typename: "OrganizationObjective";
  id: string;
  organizationID: string;
  objective: {
    __typename: "Objective";
    id: string;
    display: string;
    definition: string;
    observables: Array<string>;
    hints?: Array<string | null> | null;
    organizationID?: string | null;
    organizations?: Array<string | null> | null;
    status?: string | null;
    domain: {
      __typename: "ObjectiveDomain";
      id: string;
      key: string;
      display: string;
      excludeFromTPS?: boolean | null;
      sites: Array<string>;
      createdAt?: string | null;
      updatedAt?: string | null;
    };
    objectiveDomainId: string;
    qrCodes?: Array<{
      __typename: "QR";
      type: string;
      imageSrc: string;
      title: string;
      label: string;
      qrdata: string;
    } | null> | null;
    createdAt?: string | null;
    updatedAt?: string | null;
  };
  organizationObjectiveObjectiveId: string;
  enabled?: boolean | null;
  businessObjective?: string | null;
  status?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type OnDeleteOrganizationObjectiveSubscription = {
  __typename: "OrganizationObjective";
  id: string;
  organizationID: string;
  objective: {
    __typename: "Objective";
    id: string;
    display: string;
    definition: string;
    observables: Array<string>;
    hints?: Array<string | null> | null;
    organizationID?: string | null;
    organizations?: Array<string | null> | null;
    status?: string | null;
    domain: {
      __typename: "ObjectiveDomain";
      id: string;
      key: string;
      display: string;
      excludeFromTPS?: boolean | null;
      sites: Array<string>;
      createdAt?: string | null;
      updatedAt?: string | null;
    };
    objectiveDomainId: string;
    qrCodes?: Array<{
      __typename: "QR";
      type: string;
      imageSrc: string;
      title: string;
      label: string;
      qrdata: string;
    } | null> | null;
    createdAt?: string | null;
    updatedAt?: string | null;
  };
  organizationObjectiveObjectiveId: string;
  enabled?: boolean | null;
  businessObjective?: string | null;
  status?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type OnCreateObjectiveAssignmentSubscription = {
  __typename: "ObjectiveAssignment";
  id: string;
  employeeId: string;
  organizationID?: string | null;
  objectiveAssignmentAssignedById?: string | null;
  assignmentDate: string;
  priority?: {
    __typename: "Priority";
    id: string;
    key: string;
    display: string;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null;
  focus?: {
    __typename: "Focus";
    id: string;
    key: string;
    display: string;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null;
  orgObjective?: {
    __typename: "OrganizationObjective";
    id: string;
    organizationID: string;
    objective: {
      __typename: "Objective";
      id: string;
      display: string;
      definition: string;
      observables: Array<string>;
      hints?: Array<string | null> | null;
      organizationID?: string | null;
      organizations?: Array<string | null> | null;
      status?: string | null;
      domain: {
        __typename: "ObjectiveDomain";
        id: string;
        key: string;
        display: string;
        excludeFromTPS?: boolean | null;
        sites: Array<string>;
        createdAt?: string | null;
        updatedAt?: string | null;
      };
      objectiveDomainId: string;
      qrCodes?: Array<{
        __typename: "QR";
        type: string;
        imageSrc: string;
        title: string;
        label: string;
        qrdata: string;
      } | null> | null;
      createdAt?: string | null;
      updatedAt?: string | null;
    };
    organizationObjectiveObjectiveId: string;
    enabled?: boolean | null;
    businessObjective?: string | null;
    status?: string | null;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null;
  startDate?: string | null;
  endDate?: string | null;
  cadence?: string | null;
  managerDescription?: string | null;
  objectiveType?: string | null;
  title?: string | null;
  quantity?: number | null;
  consultantId?: string | null;
  nextSurveyDate?: string | null;
  status?: string | null;
  externalEmail?: string | null;
  externalEmails?: Array<string | null> | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type OnUpdateObjectiveAssignmentSubscription = {
  __typename: "ObjectiveAssignment";
  id: string;
  employeeId: string;
  organizationID?: string | null;
  objectiveAssignmentAssignedById?: string | null;
  assignmentDate: string;
  priority?: {
    __typename: "Priority";
    id: string;
    key: string;
    display: string;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null;
  focus?: {
    __typename: "Focus";
    id: string;
    key: string;
    display: string;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null;
  orgObjective?: {
    __typename: "OrganizationObjective";
    id: string;
    organizationID: string;
    objective: {
      __typename: "Objective";
      id: string;
      display: string;
      definition: string;
      observables: Array<string>;
      hints?: Array<string | null> | null;
      organizationID?: string | null;
      organizations?: Array<string | null> | null;
      status?: string | null;
      domain: {
        __typename: "ObjectiveDomain";
        id: string;
        key: string;
        display: string;
        excludeFromTPS?: boolean | null;
        sites: Array<string>;
        createdAt?: string | null;
        updatedAt?: string | null;
      };
      objectiveDomainId: string;
      qrCodes?: Array<{
        __typename: "QR";
        type: string;
        imageSrc: string;
        title: string;
        label: string;
        qrdata: string;
      } | null> | null;
      createdAt?: string | null;
      updatedAt?: string | null;
    };
    organizationObjectiveObjectiveId: string;
    enabled?: boolean | null;
    businessObjective?: string | null;
    status?: string | null;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null;
  startDate?: string | null;
  endDate?: string | null;
  cadence?: string | null;
  managerDescription?: string | null;
  objectiveType?: string | null;
  title?: string | null;
  quantity?: number | null;
  consultantId?: string | null;
  nextSurveyDate?: string | null;
  status?: string | null;
  externalEmail?: string | null;
  externalEmails?: Array<string | null> | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type OnDeleteObjectiveAssignmentSubscription = {
  __typename: "ObjectiveAssignment";
  id: string;
  employeeId: string;
  organizationID?: string | null;
  objectiveAssignmentAssignedById?: string | null;
  assignmentDate: string;
  priority?: {
    __typename: "Priority";
    id: string;
    key: string;
    display: string;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null;
  focus?: {
    __typename: "Focus";
    id: string;
    key: string;
    display: string;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null;
  orgObjective?: {
    __typename: "OrganizationObjective";
    id: string;
    organizationID: string;
    objective: {
      __typename: "Objective";
      id: string;
      display: string;
      definition: string;
      observables: Array<string>;
      hints?: Array<string | null> | null;
      organizationID?: string | null;
      organizations?: Array<string | null> | null;
      status?: string | null;
      domain: {
        __typename: "ObjectiveDomain";
        id: string;
        key: string;
        display: string;
        excludeFromTPS?: boolean | null;
        sites: Array<string>;
        createdAt?: string | null;
        updatedAt?: string | null;
      };
      objectiveDomainId: string;
      qrCodes?: Array<{
        __typename: "QR";
        type: string;
        imageSrc: string;
        title: string;
        label: string;
        qrdata: string;
      } | null> | null;
      createdAt?: string | null;
      updatedAt?: string | null;
    };
    organizationObjectiveObjectiveId: string;
    enabled?: boolean | null;
    businessObjective?: string | null;
    status?: string | null;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null;
  startDate?: string | null;
  endDate?: string | null;
  cadence?: string | null;
  managerDescription?: string | null;
  objectiveType?: string | null;
  title?: string | null;
  quantity?: number | null;
  consultantId?: string | null;
  nextSurveyDate?: string | null;
  status?: string | null;
  externalEmail?: string | null;
  externalEmails?: Array<string | null> | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type OnCreateCalendarEventSubscription = {
  __typename: "CalendarEvent";
  id: string;
  employeeID: string;
  organizationID: string;
  calendarId: string;
  start: string;
  end: string;
  eventCreated: string;
  eventUpdated?: string | null;
  summary?: string | null;
  description?: string | null;
  status?: string | null;
  private?: boolean | null;
  deleted?: boolean | null;
  recurring?: boolean | null;
  locationDescription?: string | null;
  participationStatus?: string | null;
  eventStatus?: string | null;
  organizerEmail?: string | null;
  organizer?: {
    __typename: "EventAttendee";
    email: string;
    displayName?: string | null;
    status?: string | null;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null;
  attendees?: Array<{
    __typename: "EventAttendee";
    email: string;
    displayName?: string | null;
    status?: string | null;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null> | null;
  attendeeEmails?: Array<string | null> | null;
  meetingURL?: string | null;
  options?: {
    __typename: "EventOptions";
    changeParticipationStatus?: boolean | null;
    delete?: boolean | null;
    update?: boolean | null;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type OnUpdateCalendarEventSubscription = {
  __typename: "CalendarEvent";
  id: string;
  employeeID: string;
  organizationID: string;
  calendarId: string;
  start: string;
  end: string;
  eventCreated: string;
  eventUpdated?: string | null;
  summary?: string | null;
  description?: string | null;
  status?: string | null;
  private?: boolean | null;
  deleted?: boolean | null;
  recurring?: boolean | null;
  locationDescription?: string | null;
  participationStatus?: string | null;
  eventStatus?: string | null;
  organizerEmail?: string | null;
  organizer?: {
    __typename: "EventAttendee";
    email: string;
    displayName?: string | null;
    status?: string | null;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null;
  attendees?: Array<{
    __typename: "EventAttendee";
    email: string;
    displayName?: string | null;
    status?: string | null;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null> | null;
  attendeeEmails?: Array<string | null> | null;
  meetingURL?: string | null;
  options?: {
    __typename: "EventOptions";
    changeParticipationStatus?: boolean | null;
    delete?: boolean | null;
    update?: boolean | null;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type OnDeleteCalendarEventSubscription = {
  __typename: "CalendarEvent";
  id: string;
  employeeID: string;
  organizationID: string;
  calendarId: string;
  start: string;
  end: string;
  eventCreated: string;
  eventUpdated?: string | null;
  summary?: string | null;
  description?: string | null;
  status?: string | null;
  private?: boolean | null;
  deleted?: boolean | null;
  recurring?: boolean | null;
  locationDescription?: string | null;
  participationStatus?: string | null;
  eventStatus?: string | null;
  organizerEmail?: string | null;
  organizer?: {
    __typename: "EventAttendee";
    email: string;
    displayName?: string | null;
    status?: string | null;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null;
  attendees?: Array<{
    __typename: "EventAttendee";
    email: string;
    displayName?: string | null;
    status?: string | null;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null> | null;
  attendeeEmails?: Array<string | null> | null;
  meetingURL?: string | null;
  options?: {
    __typename: "EventOptions";
    changeParticipationStatus?: boolean | null;
    delete?: boolean | null;
    update?: boolean | null;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type OnCreateSurveySubscription = {
  __typename: "Survey";
  id: string;
  organizationID: string;
  objectID: string;
  surveyType: SurveyType;
  surveyDate: string;
  respondentEmail: string;
  participantEmail: string;
  respondentAttended?: boolean | null;
  surveyResponse?: number | null;
  feedback?: string | null;
  responseReceived?: boolean | null;
  responseReceivedDate?: string | null;
  status?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type OnUpdateSurveySubscription = {
  __typename: "Survey";
  id: string;
  organizationID: string;
  objectID: string;
  surveyType: SurveyType;
  surveyDate: string;
  respondentEmail: string;
  participantEmail: string;
  respondentAttended?: boolean | null;
  surveyResponse?: number | null;
  feedback?: string | null;
  responseReceived?: boolean | null;
  responseReceivedDate?: string | null;
  status?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type OnDeleteSurveySubscription = {
  __typename: "Survey";
  id: string;
  organizationID: string;
  objectID: string;
  surveyType: SurveyType;
  surveyDate: string;
  respondentEmail: string;
  participantEmail: string;
  respondentAttended?: boolean | null;
  surveyResponse?: number | null;
  feedback?: string | null;
  responseReceived?: boolean | null;
  responseReceivedDate?: string | null;
  status?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type OnCreateExternalRespondentSubscription = {
  __typename: "ExternalRespondent";
  id: string;
  externalRespondentEmail: string;
  externalRespondentName?: string | null;
  externalRespondentStatus?: string | null;
  statusUpdates?: Array<{
    __typename: "ExternalRespondentStatusChange";
    status: string;
    ipAddress: string;
    externalRespondentStatusDate: string;
  } | null> | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type OnUpdateExternalRespondentSubscription = {
  __typename: "ExternalRespondent";
  id: string;
  externalRespondentEmail: string;
  externalRespondentName?: string | null;
  externalRespondentStatus?: string | null;
  statusUpdates?: Array<{
    __typename: "ExternalRespondentStatusChange";
    status: string;
    ipAddress: string;
    externalRespondentStatusDate: string;
  } | null> | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type OnDeleteExternalRespondentSubscription = {
  __typename: "ExternalRespondent";
  id: string;
  externalRespondentEmail: string;
  externalRespondentName?: string | null;
  externalRespondentStatus?: string | null;
  statusUpdates?: Array<{
    __typename: "ExternalRespondentStatusChange";
    status: string;
    ipAddress: string;
    externalRespondentStatusDate: string;
  } | null> | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type OnCreateDoNotDisturbRequestSubscription = {
  __typename: "DoNotDisturbRequest";
  id: string;
  externalRespondentID?: string | null;
  externalRespondentEmail: string;
  ipAddress: string;
  status: string;
  createdAt: string;
  updatedAt: string;
};

export type OnUpdateDoNotDisturbRequestSubscription = {
  __typename: "DoNotDisturbRequest";
  id: string;
  externalRespondentID?: string | null;
  externalRespondentEmail: string;
  ipAddress: string;
  status: string;
  createdAt: string;
  updatedAt: string;
};

export type OnDeleteDoNotDisturbRequestSubscription = {
  __typename: "DoNotDisturbRequest";
  id: string;
  externalRespondentID?: string | null;
  externalRespondentEmail: string;
  ipAddress: string;
  status: string;
  createdAt: string;
  updatedAt: string;
};

export type OnCreateProjectSubscription = {
  __typename: "Project";
  id: string;
  organizationID: string;
  title: string;
  description?: string | null;
  startDate: string;
  endDate: string;
  cadence: string;
  projectManagerID: string;
  status?: string | null;
  team?: Array<string | null> | null;
  nextSurveyDate: string;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type OnUpdateProjectSubscription = {
  __typename: "Project";
  id: string;
  organizationID: string;
  title: string;
  description?: string | null;
  startDate: string;
  endDate: string;
  cadence: string;
  projectManagerID: string;
  status?: string | null;
  team?: Array<string | null> | null;
  nextSurveyDate: string;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type OnDeleteProjectSubscription = {
  __typename: "Project";
  id: string;
  organizationID: string;
  title: string;
  description?: string | null;
  startDate: string;
  endDate: string;
  cadence: string;
  projectManagerID: string;
  status?: string | null;
  team?: Array<string | null> | null;
  nextSurveyDate: string;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type OnCreateMilestoneCommentSubscription = {
  __typename: "MilestoneComment";
  id: string;
  organizationID: string;
  milestoneID: string;
  projectMilestoneAssignment?: {
    __typename: "ProjectMilestoneAssignment";
    id: string;
    projectID: string;
    organizationID: string;
    assignedBy: string;
    assignmentDate: string;
    startDate: string;
    endDate: string;
    cadence: string;
    milestoneType: string;
    title: string;
    nextSurveyDate: string;
    description?: string | null;
    team?: Array<string | null> | null;
    comments?: {
      __typename: "ModelMilestoneCommentConnection";
      items: Array<{
        __typename: "MilestoneComment";
        id: string;
        organizationID: string;
        milestoneID: string;
        projectMilestoneAssignment?: {
          __typename: "ProjectMilestoneAssignment";
          id: string;
          projectID: string;
          organizationID: string;
          assignedBy: string;
          assignmentDate: string;
          startDate: string;
          endDate: string;
          cadence: string;
          milestoneType: string;
          title: string;
          nextSurveyDate: string;
          description?: string | null;
          team?: Array<string | null> | null;
          comments?: {
            __typename: "ModelMilestoneCommentConnection";
            items: Array<{
              __typename: "MilestoneComment";
              id: string;
              organizationID: string;
              milestoneID: string;
              projectMilestoneAssignment?: {
                __typename: "ProjectMilestoneAssignment";
                id: string;
                projectID: string;
                organizationID: string;
                assignedBy: string;
                assignmentDate: string;
                startDate: string;
                endDate: string;
                cadence: string;
                milestoneType: string;
                title: string;
                nextSurveyDate: string;
                description?: string | null;
                team?: Array<string | null> | null;
                comments?: {
                  __typename: "ModelMilestoneCommentConnection";
                  items: Array<{
                    __typename: "MilestoneComment";
                    id: string;
                    organizationID: string;
                    milestoneID: string;
                    comment: string;
                    createdAt: string;
                    createdBy: string;
                    updatedAt?: string | null;
                  } | null>;
                  nextToken?: string | null;
                } | null;
                createdAt?: string | null;
                updatedAt?: string | null;
              } | null;
              comment: string;
              createdAt: string;
              createdBy: string;
              updatedAt?: string | null;
            } | null>;
            nextToken?: string | null;
          } | null;
          createdAt?: string | null;
          updatedAt?: string | null;
        } | null;
        comment: string;
        createdAt: string;
        createdBy: string;
        updatedAt?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null;
  comment: string;
  createdAt: string;
  createdBy: string;
  updatedAt?: string | null;
};

export type OnUpdateMilestoneCommentSubscription = {
  __typename: "MilestoneComment";
  id: string;
  organizationID: string;
  milestoneID: string;
  projectMilestoneAssignment?: {
    __typename: "ProjectMilestoneAssignment";
    id: string;
    projectID: string;
    organizationID: string;
    assignedBy: string;
    assignmentDate: string;
    startDate: string;
    endDate: string;
    cadence: string;
    milestoneType: string;
    title: string;
    nextSurveyDate: string;
    description?: string | null;
    team?: Array<string | null> | null;
    comments?: {
      __typename: "ModelMilestoneCommentConnection";
      items: Array<{
        __typename: "MilestoneComment";
        id: string;
        organizationID: string;
        milestoneID: string;
        projectMilestoneAssignment?: {
          __typename: "ProjectMilestoneAssignment";
          id: string;
          projectID: string;
          organizationID: string;
          assignedBy: string;
          assignmentDate: string;
          startDate: string;
          endDate: string;
          cadence: string;
          milestoneType: string;
          title: string;
          nextSurveyDate: string;
          description?: string | null;
          team?: Array<string | null> | null;
          comments?: {
            __typename: "ModelMilestoneCommentConnection";
            items: Array<{
              __typename: "MilestoneComment";
              id: string;
              organizationID: string;
              milestoneID: string;
              projectMilestoneAssignment?: {
                __typename: "ProjectMilestoneAssignment";
                id: string;
                projectID: string;
                organizationID: string;
                assignedBy: string;
                assignmentDate: string;
                startDate: string;
                endDate: string;
                cadence: string;
                milestoneType: string;
                title: string;
                nextSurveyDate: string;
                description?: string | null;
                team?: Array<string | null> | null;
                comments?: {
                  __typename: "ModelMilestoneCommentConnection";
                  items: Array<{
                    __typename: "MilestoneComment";
                    id: string;
                    organizationID: string;
                    milestoneID: string;
                    comment: string;
                    createdAt: string;
                    createdBy: string;
                    updatedAt?: string | null;
                  } | null>;
                  nextToken?: string | null;
                } | null;
                createdAt?: string | null;
                updatedAt?: string | null;
              } | null;
              comment: string;
              createdAt: string;
              createdBy: string;
              updatedAt?: string | null;
            } | null>;
            nextToken?: string | null;
          } | null;
          createdAt?: string | null;
          updatedAt?: string | null;
        } | null;
        comment: string;
        createdAt: string;
        createdBy: string;
        updatedAt?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null;
  comment: string;
  createdAt: string;
  createdBy: string;
  updatedAt?: string | null;
};

export type OnDeleteMilestoneCommentSubscription = {
  __typename: "MilestoneComment";
  id: string;
  organizationID: string;
  milestoneID: string;
  projectMilestoneAssignment?: {
    __typename: "ProjectMilestoneAssignment";
    id: string;
    projectID: string;
    organizationID: string;
    assignedBy: string;
    assignmentDate: string;
    startDate: string;
    endDate: string;
    cadence: string;
    milestoneType: string;
    title: string;
    nextSurveyDate: string;
    description?: string | null;
    team?: Array<string | null> | null;
    comments?: {
      __typename: "ModelMilestoneCommentConnection";
      items: Array<{
        __typename: "MilestoneComment";
        id: string;
        organizationID: string;
        milestoneID: string;
        projectMilestoneAssignment?: {
          __typename: "ProjectMilestoneAssignment";
          id: string;
          projectID: string;
          organizationID: string;
          assignedBy: string;
          assignmentDate: string;
          startDate: string;
          endDate: string;
          cadence: string;
          milestoneType: string;
          title: string;
          nextSurveyDate: string;
          description?: string | null;
          team?: Array<string | null> | null;
          comments?: {
            __typename: "ModelMilestoneCommentConnection";
            items: Array<{
              __typename: "MilestoneComment";
              id: string;
              organizationID: string;
              milestoneID: string;
              projectMilestoneAssignment?: {
                __typename: "ProjectMilestoneAssignment";
                id: string;
                projectID: string;
                organizationID: string;
                assignedBy: string;
                assignmentDate: string;
                startDate: string;
                endDate: string;
                cadence: string;
                milestoneType: string;
                title: string;
                nextSurveyDate: string;
                description?: string | null;
                team?: Array<string | null> | null;
                comments?: {
                  __typename: "ModelMilestoneCommentConnection";
                  items: Array<{
                    __typename: "MilestoneComment";
                    id: string;
                    organizationID: string;
                    milestoneID: string;
                    comment: string;
                    createdAt: string;
                    createdBy: string;
                    updatedAt?: string | null;
                  } | null>;
                  nextToken?: string | null;
                } | null;
                createdAt?: string | null;
                updatedAt?: string | null;
              } | null;
              comment: string;
              createdAt: string;
              createdBy: string;
              updatedAt?: string | null;
            } | null>;
            nextToken?: string | null;
          } | null;
          createdAt?: string | null;
          updatedAt?: string | null;
        } | null;
        comment: string;
        createdAt: string;
        createdBy: string;
        updatedAt?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null;
  comment: string;
  createdAt: string;
  createdBy: string;
  updatedAt?: string | null;
};

export type OnCreateProjectMilestoneAssignmentSubscription = {
  __typename: "ProjectMilestoneAssignment";
  id: string;
  projectID: string;
  organizationID: string;
  assignedBy: string;
  assignmentDate: string;
  startDate: string;
  endDate: string;
  cadence: string;
  milestoneType: string;
  title: string;
  nextSurveyDate: string;
  description?: string | null;
  team?: Array<string | null> | null;
  comments?: {
    __typename: "ModelMilestoneCommentConnection";
    items: Array<{
      __typename: "MilestoneComment";
      id: string;
      organizationID: string;
      milestoneID: string;
      projectMilestoneAssignment?: {
        __typename: "ProjectMilestoneAssignment";
        id: string;
        projectID: string;
        organizationID: string;
        assignedBy: string;
        assignmentDate: string;
        startDate: string;
        endDate: string;
        cadence: string;
        milestoneType: string;
        title: string;
        nextSurveyDate: string;
        description?: string | null;
        team?: Array<string | null> | null;
        comments?: {
          __typename: "ModelMilestoneCommentConnection";
          items: Array<{
            __typename: "MilestoneComment";
            id: string;
            organizationID: string;
            milestoneID: string;
            projectMilestoneAssignment?: {
              __typename: "ProjectMilestoneAssignment";
              id: string;
              projectID: string;
              organizationID: string;
              assignedBy: string;
              assignmentDate: string;
              startDate: string;
              endDate: string;
              cadence: string;
              milestoneType: string;
              title: string;
              nextSurveyDate: string;
              description?: string | null;
              team?: Array<string | null> | null;
              comments?: {
                __typename: "ModelMilestoneCommentConnection";
                items: Array<{
                  __typename: "MilestoneComment";
                  id: string;
                  organizationID: string;
                  milestoneID: string;
                  projectMilestoneAssignment?: {
                    __typename: "ProjectMilestoneAssignment";
                    id: string;
                    projectID: string;
                    organizationID: string;
                    assignedBy: string;
                    assignmentDate: string;
                    startDate: string;
                    endDate: string;
                    cadence: string;
                    milestoneType: string;
                    title: string;
                    nextSurveyDate: string;
                    description?: string | null;
                    team?: Array<string | null> | null;
                    createdAt?: string | null;
                    updatedAt?: string | null;
                  } | null;
                  comment: string;
                  createdAt: string;
                  createdBy: string;
                  updatedAt?: string | null;
                } | null>;
                nextToken?: string | null;
              } | null;
              createdAt?: string | null;
              updatedAt?: string | null;
            } | null;
            comment: string;
            createdAt: string;
            createdBy: string;
            updatedAt?: string | null;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt?: string | null;
        updatedAt?: string | null;
      } | null;
      comment: string;
      createdAt: string;
      createdBy: string;
      updatedAt?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type OnUpdateProjectMilestoneAssignmentSubscription = {
  __typename: "ProjectMilestoneAssignment";
  id: string;
  projectID: string;
  organizationID: string;
  assignedBy: string;
  assignmentDate: string;
  startDate: string;
  endDate: string;
  cadence: string;
  milestoneType: string;
  title: string;
  nextSurveyDate: string;
  description?: string | null;
  team?: Array<string | null> | null;
  comments?: {
    __typename: "ModelMilestoneCommentConnection";
    items: Array<{
      __typename: "MilestoneComment";
      id: string;
      organizationID: string;
      milestoneID: string;
      projectMilestoneAssignment?: {
        __typename: "ProjectMilestoneAssignment";
        id: string;
        projectID: string;
        organizationID: string;
        assignedBy: string;
        assignmentDate: string;
        startDate: string;
        endDate: string;
        cadence: string;
        milestoneType: string;
        title: string;
        nextSurveyDate: string;
        description?: string | null;
        team?: Array<string | null> | null;
        comments?: {
          __typename: "ModelMilestoneCommentConnection";
          items: Array<{
            __typename: "MilestoneComment";
            id: string;
            organizationID: string;
            milestoneID: string;
            projectMilestoneAssignment?: {
              __typename: "ProjectMilestoneAssignment";
              id: string;
              projectID: string;
              organizationID: string;
              assignedBy: string;
              assignmentDate: string;
              startDate: string;
              endDate: string;
              cadence: string;
              milestoneType: string;
              title: string;
              nextSurveyDate: string;
              description?: string | null;
              team?: Array<string | null> | null;
              comments?: {
                __typename: "ModelMilestoneCommentConnection";
                items: Array<{
                  __typename: "MilestoneComment";
                  id: string;
                  organizationID: string;
                  milestoneID: string;
                  projectMilestoneAssignment?: {
                    __typename: "ProjectMilestoneAssignment";
                    id: string;
                    projectID: string;
                    organizationID: string;
                    assignedBy: string;
                    assignmentDate: string;
                    startDate: string;
                    endDate: string;
                    cadence: string;
                    milestoneType: string;
                    title: string;
                    nextSurveyDate: string;
                    description?: string | null;
                    team?: Array<string | null> | null;
                    createdAt?: string | null;
                    updatedAt?: string | null;
                  } | null;
                  comment: string;
                  createdAt: string;
                  createdBy: string;
                  updatedAt?: string | null;
                } | null>;
                nextToken?: string | null;
              } | null;
              createdAt?: string | null;
              updatedAt?: string | null;
            } | null;
            comment: string;
            createdAt: string;
            createdBy: string;
            updatedAt?: string | null;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt?: string | null;
        updatedAt?: string | null;
      } | null;
      comment: string;
      createdAt: string;
      createdBy: string;
      updatedAt?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type OnDeleteProjectMilestoneAssignmentSubscription = {
  __typename: "ProjectMilestoneAssignment";
  id: string;
  projectID: string;
  organizationID: string;
  assignedBy: string;
  assignmentDate: string;
  startDate: string;
  endDate: string;
  cadence: string;
  milestoneType: string;
  title: string;
  nextSurveyDate: string;
  description?: string | null;
  team?: Array<string | null> | null;
  comments?: {
    __typename: "ModelMilestoneCommentConnection";
    items: Array<{
      __typename: "MilestoneComment";
      id: string;
      organizationID: string;
      milestoneID: string;
      projectMilestoneAssignment?: {
        __typename: "ProjectMilestoneAssignment";
        id: string;
        projectID: string;
        organizationID: string;
        assignedBy: string;
        assignmentDate: string;
        startDate: string;
        endDate: string;
        cadence: string;
        milestoneType: string;
        title: string;
        nextSurveyDate: string;
        description?: string | null;
        team?: Array<string | null> | null;
        comments?: {
          __typename: "ModelMilestoneCommentConnection";
          items: Array<{
            __typename: "MilestoneComment";
            id: string;
            organizationID: string;
            milestoneID: string;
            projectMilestoneAssignment?: {
              __typename: "ProjectMilestoneAssignment";
              id: string;
              projectID: string;
              organizationID: string;
              assignedBy: string;
              assignmentDate: string;
              startDate: string;
              endDate: string;
              cadence: string;
              milestoneType: string;
              title: string;
              nextSurveyDate: string;
              description?: string | null;
              team?: Array<string | null> | null;
              comments?: {
                __typename: "ModelMilestoneCommentConnection";
                items: Array<{
                  __typename: "MilestoneComment";
                  id: string;
                  organizationID: string;
                  milestoneID: string;
                  projectMilestoneAssignment?: {
                    __typename: "ProjectMilestoneAssignment";
                    id: string;
                    projectID: string;
                    organizationID: string;
                    assignedBy: string;
                    assignmentDate: string;
                    startDate: string;
                    endDate: string;
                    cadence: string;
                    milestoneType: string;
                    title: string;
                    nextSurveyDate: string;
                    description?: string | null;
                    team?: Array<string | null> | null;
                    createdAt?: string | null;
                    updatedAt?: string | null;
                  } | null;
                  comment: string;
                  createdAt: string;
                  createdBy: string;
                  updatedAt?: string | null;
                } | null>;
                nextToken?: string | null;
              } | null;
              createdAt?: string | null;
              updatedAt?: string | null;
            } | null;
            comment: string;
            createdAt: string;
            createdBy: string;
            updatedAt?: string | null;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt?: string | null;
        updatedAt?: string | null;
      } | null;
      comment: string;
      createdAt: string;
      createdBy: string;
      updatedAt?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type OnCreateNoteCommentSubscription = {
  __typename: "NoteComment";
  id: string;
  organizationID: string;
  noteID: string;
  note?: {
    __typename: "Note";
    id: string;
    organizationID: string;
    objectID: string;
    createdAt: string;
    createdBy: string;
    updatedAt?: string | null;
    updatedBy?: string | null;
    text?: string | null;
    noteType: string;
    options?: string | null;
    comments?: {
      __typename: "ModelNoteCommentConnection";
      items: Array<{
        __typename: "NoteComment";
        id: string;
        organizationID: string;
        noteID: string;
        note?: {
          __typename: "Note";
          id: string;
          organizationID: string;
          objectID: string;
          createdAt: string;
          createdBy: string;
          updatedAt?: string | null;
          updatedBy?: string | null;
          text?: string | null;
          noteType: string;
          options?: string | null;
          comments?: {
            __typename: "ModelNoteCommentConnection";
            items: Array<{
              __typename: "NoteComment";
              id: string;
              organizationID: string;
              noteID: string;
              note?: {
                __typename: "Note";
                id: string;
                organizationID: string;
                objectID: string;
                createdAt: string;
                createdBy: string;
                updatedAt?: string | null;
                updatedBy?: string | null;
                text?: string | null;
                noteType: string;
                options?: string | null;
                comments?: {
                  __typename: "ModelNoteCommentConnection";
                  items: Array<{
                    __typename: "NoteComment";
                    id: string;
                    organizationID: string;
                    noteID: string;
                    comment: string;
                    createdAt: string;
                    createdBy: string;
                    updatedAt?: string | null;
                  } | null>;
                  nextToken?: string | null;
                } | null;
                historys?: {
                  __typename: "ModelNoteHistoryConnection";
                  items: Array<{
                    __typename: "NoteHistory";
                    id: string;
                    organizationID: string;
                    noteID: string;
                    createdAt: string;
                    createdBy: string;
                    text?: string | null;
                    updatedAt?: string | null;
                  } | null>;
                  nextToken?: string | null;
                } | null;
              } | null;
              comment: string;
              createdAt: string;
              createdBy: string;
              updatedAt?: string | null;
            } | null>;
            nextToken?: string | null;
          } | null;
          historys?: {
            __typename: "ModelNoteHistoryConnection";
            items: Array<{
              __typename: "NoteHistory";
              id: string;
              organizationID: string;
              noteID: string;
              note?: {
                __typename: "Note";
                id: string;
                organizationID: string;
                objectID: string;
                createdAt: string;
                createdBy: string;
                updatedAt?: string | null;
                updatedBy?: string | null;
                text?: string | null;
                noteType: string;
                options?: string | null;
                comments?: {
                  __typename: "ModelNoteCommentConnection";
                  items: Array<{
                    __typename: "NoteComment";
                    id: string;
                    organizationID: string;
                    noteID: string;
                    comment: string;
                    createdAt: string;
                    createdBy: string;
                    updatedAt?: string | null;
                  } | null>;
                  nextToken?: string | null;
                } | null;
                historys?: {
                  __typename: "ModelNoteHistoryConnection";
                  items: Array<{
                    __typename: "NoteHistory";
                    id: string;
                    organizationID: string;
                    noteID: string;
                    createdAt: string;
                    createdBy: string;
                    text?: string | null;
                    updatedAt?: string | null;
                  } | null>;
                  nextToken?: string | null;
                } | null;
              } | null;
              createdAt: string;
              createdBy: string;
              text?: string | null;
              updatedAt?: string | null;
            } | null>;
            nextToken?: string | null;
          } | null;
        } | null;
        comment: string;
        createdAt: string;
        createdBy: string;
        updatedAt?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    historys?: {
      __typename: "ModelNoteHistoryConnection";
      items: Array<{
        __typename: "NoteHistory";
        id: string;
        organizationID: string;
        noteID: string;
        note?: {
          __typename: "Note";
          id: string;
          organizationID: string;
          objectID: string;
          createdAt: string;
          createdBy: string;
          updatedAt?: string | null;
          updatedBy?: string | null;
          text?: string | null;
          noteType: string;
          options?: string | null;
          comments?: {
            __typename: "ModelNoteCommentConnection";
            items: Array<{
              __typename: "NoteComment";
              id: string;
              organizationID: string;
              noteID: string;
              note?: {
                __typename: "Note";
                id: string;
                organizationID: string;
                objectID: string;
                createdAt: string;
                createdBy: string;
                updatedAt?: string | null;
                updatedBy?: string | null;
                text?: string | null;
                noteType: string;
                options?: string | null;
                comments?: {
                  __typename: "ModelNoteCommentConnection";
                  items: Array<{
                    __typename: "NoteComment";
                    id: string;
                    organizationID: string;
                    noteID: string;
                    comment: string;
                    createdAt: string;
                    createdBy: string;
                    updatedAt?: string | null;
                  } | null>;
                  nextToken?: string | null;
                } | null;
                historys?: {
                  __typename: "ModelNoteHistoryConnection";
                  items: Array<{
                    __typename: "NoteHistory";
                    id: string;
                    organizationID: string;
                    noteID: string;
                    createdAt: string;
                    createdBy: string;
                    text?: string | null;
                    updatedAt?: string | null;
                  } | null>;
                  nextToken?: string | null;
                } | null;
              } | null;
              comment: string;
              createdAt: string;
              createdBy: string;
              updatedAt?: string | null;
            } | null>;
            nextToken?: string | null;
          } | null;
          historys?: {
            __typename: "ModelNoteHistoryConnection";
            items: Array<{
              __typename: "NoteHistory";
              id: string;
              organizationID: string;
              noteID: string;
              note?: {
                __typename: "Note";
                id: string;
                organizationID: string;
                objectID: string;
                createdAt: string;
                createdBy: string;
                updatedAt?: string | null;
                updatedBy?: string | null;
                text?: string | null;
                noteType: string;
                options?: string | null;
                comments?: {
                  __typename: "ModelNoteCommentConnection";
                  items: Array<{
                    __typename: "NoteComment";
                    id: string;
                    organizationID: string;
                    noteID: string;
                    comment: string;
                    createdAt: string;
                    createdBy: string;
                    updatedAt?: string | null;
                  } | null>;
                  nextToken?: string | null;
                } | null;
                historys?: {
                  __typename: "ModelNoteHistoryConnection";
                  items: Array<{
                    __typename: "NoteHistory";
                    id: string;
                    organizationID: string;
                    noteID: string;
                    createdAt: string;
                    createdBy: string;
                    text?: string | null;
                    updatedAt?: string | null;
                  } | null>;
                  nextToken?: string | null;
                } | null;
              } | null;
              createdAt: string;
              createdBy: string;
              text?: string | null;
              updatedAt?: string | null;
            } | null>;
            nextToken?: string | null;
          } | null;
        } | null;
        createdAt: string;
        createdBy: string;
        text?: string | null;
        updatedAt?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null;
  comment: string;
  createdAt: string;
  createdBy: string;
  updatedAt?: string | null;
};

export type OnUpdateNoteCommentSubscription = {
  __typename: "NoteComment";
  id: string;
  organizationID: string;
  noteID: string;
  note?: {
    __typename: "Note";
    id: string;
    organizationID: string;
    objectID: string;
    createdAt: string;
    createdBy: string;
    updatedAt?: string | null;
    updatedBy?: string | null;
    text?: string | null;
    noteType: string;
    options?: string | null;
    comments?: {
      __typename: "ModelNoteCommentConnection";
      items: Array<{
        __typename: "NoteComment";
        id: string;
        organizationID: string;
        noteID: string;
        note?: {
          __typename: "Note";
          id: string;
          organizationID: string;
          objectID: string;
          createdAt: string;
          createdBy: string;
          updatedAt?: string | null;
          updatedBy?: string | null;
          text?: string | null;
          noteType: string;
          options?: string | null;
          comments?: {
            __typename: "ModelNoteCommentConnection";
            items: Array<{
              __typename: "NoteComment";
              id: string;
              organizationID: string;
              noteID: string;
              note?: {
                __typename: "Note";
                id: string;
                organizationID: string;
                objectID: string;
                createdAt: string;
                createdBy: string;
                updatedAt?: string | null;
                updatedBy?: string | null;
                text?: string | null;
                noteType: string;
                options?: string | null;
                comments?: {
                  __typename: "ModelNoteCommentConnection";
                  items: Array<{
                    __typename: "NoteComment";
                    id: string;
                    organizationID: string;
                    noteID: string;
                    comment: string;
                    createdAt: string;
                    createdBy: string;
                    updatedAt?: string | null;
                  } | null>;
                  nextToken?: string | null;
                } | null;
                historys?: {
                  __typename: "ModelNoteHistoryConnection";
                  items: Array<{
                    __typename: "NoteHistory";
                    id: string;
                    organizationID: string;
                    noteID: string;
                    createdAt: string;
                    createdBy: string;
                    text?: string | null;
                    updatedAt?: string | null;
                  } | null>;
                  nextToken?: string | null;
                } | null;
              } | null;
              comment: string;
              createdAt: string;
              createdBy: string;
              updatedAt?: string | null;
            } | null>;
            nextToken?: string | null;
          } | null;
          historys?: {
            __typename: "ModelNoteHistoryConnection";
            items: Array<{
              __typename: "NoteHistory";
              id: string;
              organizationID: string;
              noteID: string;
              note?: {
                __typename: "Note";
                id: string;
                organizationID: string;
                objectID: string;
                createdAt: string;
                createdBy: string;
                updatedAt?: string | null;
                updatedBy?: string | null;
                text?: string | null;
                noteType: string;
                options?: string | null;
                comments?: {
                  __typename: "ModelNoteCommentConnection";
                  items: Array<{
                    __typename: "NoteComment";
                    id: string;
                    organizationID: string;
                    noteID: string;
                    comment: string;
                    createdAt: string;
                    createdBy: string;
                    updatedAt?: string | null;
                  } | null>;
                  nextToken?: string | null;
                } | null;
                historys?: {
                  __typename: "ModelNoteHistoryConnection";
                  items: Array<{
                    __typename: "NoteHistory";
                    id: string;
                    organizationID: string;
                    noteID: string;
                    createdAt: string;
                    createdBy: string;
                    text?: string | null;
                    updatedAt?: string | null;
                  } | null>;
                  nextToken?: string | null;
                } | null;
              } | null;
              createdAt: string;
              createdBy: string;
              text?: string | null;
              updatedAt?: string | null;
            } | null>;
            nextToken?: string | null;
          } | null;
        } | null;
        comment: string;
        createdAt: string;
        createdBy: string;
        updatedAt?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    historys?: {
      __typename: "ModelNoteHistoryConnection";
      items: Array<{
        __typename: "NoteHistory";
        id: string;
        organizationID: string;
        noteID: string;
        note?: {
          __typename: "Note";
          id: string;
          organizationID: string;
          objectID: string;
          createdAt: string;
          createdBy: string;
          updatedAt?: string | null;
          updatedBy?: string | null;
          text?: string | null;
          noteType: string;
          options?: string | null;
          comments?: {
            __typename: "ModelNoteCommentConnection";
            items: Array<{
              __typename: "NoteComment";
              id: string;
              organizationID: string;
              noteID: string;
              note?: {
                __typename: "Note";
                id: string;
                organizationID: string;
                objectID: string;
                createdAt: string;
                createdBy: string;
                updatedAt?: string | null;
                updatedBy?: string | null;
                text?: string | null;
                noteType: string;
                options?: string | null;
                comments?: {
                  __typename: "ModelNoteCommentConnection";
                  items: Array<{
                    __typename: "NoteComment";
                    id: string;
                    organizationID: string;
                    noteID: string;
                    comment: string;
                    createdAt: string;
                    createdBy: string;
                    updatedAt?: string | null;
                  } | null>;
                  nextToken?: string | null;
                } | null;
                historys?: {
                  __typename: "ModelNoteHistoryConnection";
                  items: Array<{
                    __typename: "NoteHistory";
                    id: string;
                    organizationID: string;
                    noteID: string;
                    createdAt: string;
                    createdBy: string;
                    text?: string | null;
                    updatedAt?: string | null;
                  } | null>;
                  nextToken?: string | null;
                } | null;
              } | null;
              comment: string;
              createdAt: string;
              createdBy: string;
              updatedAt?: string | null;
            } | null>;
            nextToken?: string | null;
          } | null;
          historys?: {
            __typename: "ModelNoteHistoryConnection";
            items: Array<{
              __typename: "NoteHistory";
              id: string;
              organizationID: string;
              noteID: string;
              note?: {
                __typename: "Note";
                id: string;
                organizationID: string;
                objectID: string;
                createdAt: string;
                createdBy: string;
                updatedAt?: string | null;
                updatedBy?: string | null;
                text?: string | null;
                noteType: string;
                options?: string | null;
                comments?: {
                  __typename: "ModelNoteCommentConnection";
                  items: Array<{
                    __typename: "NoteComment";
                    id: string;
                    organizationID: string;
                    noteID: string;
                    comment: string;
                    createdAt: string;
                    createdBy: string;
                    updatedAt?: string | null;
                  } | null>;
                  nextToken?: string | null;
                } | null;
                historys?: {
                  __typename: "ModelNoteHistoryConnection";
                  items: Array<{
                    __typename: "NoteHistory";
                    id: string;
                    organizationID: string;
                    noteID: string;
                    createdAt: string;
                    createdBy: string;
                    text?: string | null;
                    updatedAt?: string | null;
                  } | null>;
                  nextToken?: string | null;
                } | null;
              } | null;
              createdAt: string;
              createdBy: string;
              text?: string | null;
              updatedAt?: string | null;
            } | null>;
            nextToken?: string | null;
          } | null;
        } | null;
        createdAt: string;
        createdBy: string;
        text?: string | null;
        updatedAt?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null;
  comment: string;
  createdAt: string;
  createdBy: string;
  updatedAt?: string | null;
};

export type OnDeleteNoteCommentSubscription = {
  __typename: "NoteComment";
  id: string;
  organizationID: string;
  noteID: string;
  note?: {
    __typename: "Note";
    id: string;
    organizationID: string;
    objectID: string;
    createdAt: string;
    createdBy: string;
    updatedAt?: string | null;
    updatedBy?: string | null;
    text?: string | null;
    noteType: string;
    options?: string | null;
    comments?: {
      __typename: "ModelNoteCommentConnection";
      items: Array<{
        __typename: "NoteComment";
        id: string;
        organizationID: string;
        noteID: string;
        note?: {
          __typename: "Note";
          id: string;
          organizationID: string;
          objectID: string;
          createdAt: string;
          createdBy: string;
          updatedAt?: string | null;
          updatedBy?: string | null;
          text?: string | null;
          noteType: string;
          options?: string | null;
          comments?: {
            __typename: "ModelNoteCommentConnection";
            items: Array<{
              __typename: "NoteComment";
              id: string;
              organizationID: string;
              noteID: string;
              note?: {
                __typename: "Note";
                id: string;
                organizationID: string;
                objectID: string;
                createdAt: string;
                createdBy: string;
                updatedAt?: string | null;
                updatedBy?: string | null;
                text?: string | null;
                noteType: string;
                options?: string | null;
                comments?: {
                  __typename: "ModelNoteCommentConnection";
                  items: Array<{
                    __typename: "NoteComment";
                    id: string;
                    organizationID: string;
                    noteID: string;
                    comment: string;
                    createdAt: string;
                    createdBy: string;
                    updatedAt?: string | null;
                  } | null>;
                  nextToken?: string | null;
                } | null;
                historys?: {
                  __typename: "ModelNoteHistoryConnection";
                  items: Array<{
                    __typename: "NoteHistory";
                    id: string;
                    organizationID: string;
                    noteID: string;
                    createdAt: string;
                    createdBy: string;
                    text?: string | null;
                    updatedAt?: string | null;
                  } | null>;
                  nextToken?: string | null;
                } | null;
              } | null;
              comment: string;
              createdAt: string;
              createdBy: string;
              updatedAt?: string | null;
            } | null>;
            nextToken?: string | null;
          } | null;
          historys?: {
            __typename: "ModelNoteHistoryConnection";
            items: Array<{
              __typename: "NoteHistory";
              id: string;
              organizationID: string;
              noteID: string;
              note?: {
                __typename: "Note";
                id: string;
                organizationID: string;
                objectID: string;
                createdAt: string;
                createdBy: string;
                updatedAt?: string | null;
                updatedBy?: string | null;
                text?: string | null;
                noteType: string;
                options?: string | null;
                comments?: {
                  __typename: "ModelNoteCommentConnection";
                  items: Array<{
                    __typename: "NoteComment";
                    id: string;
                    organizationID: string;
                    noteID: string;
                    comment: string;
                    createdAt: string;
                    createdBy: string;
                    updatedAt?: string | null;
                  } | null>;
                  nextToken?: string | null;
                } | null;
                historys?: {
                  __typename: "ModelNoteHistoryConnection";
                  items: Array<{
                    __typename: "NoteHistory";
                    id: string;
                    organizationID: string;
                    noteID: string;
                    createdAt: string;
                    createdBy: string;
                    text?: string | null;
                    updatedAt?: string | null;
                  } | null>;
                  nextToken?: string | null;
                } | null;
              } | null;
              createdAt: string;
              createdBy: string;
              text?: string | null;
              updatedAt?: string | null;
            } | null>;
            nextToken?: string | null;
          } | null;
        } | null;
        comment: string;
        createdAt: string;
        createdBy: string;
        updatedAt?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    historys?: {
      __typename: "ModelNoteHistoryConnection";
      items: Array<{
        __typename: "NoteHistory";
        id: string;
        organizationID: string;
        noteID: string;
        note?: {
          __typename: "Note";
          id: string;
          organizationID: string;
          objectID: string;
          createdAt: string;
          createdBy: string;
          updatedAt?: string | null;
          updatedBy?: string | null;
          text?: string | null;
          noteType: string;
          options?: string | null;
          comments?: {
            __typename: "ModelNoteCommentConnection";
            items: Array<{
              __typename: "NoteComment";
              id: string;
              organizationID: string;
              noteID: string;
              note?: {
                __typename: "Note";
                id: string;
                organizationID: string;
                objectID: string;
                createdAt: string;
                createdBy: string;
                updatedAt?: string | null;
                updatedBy?: string | null;
                text?: string | null;
                noteType: string;
                options?: string | null;
                comments?: {
                  __typename: "ModelNoteCommentConnection";
                  items: Array<{
                    __typename: "NoteComment";
                    id: string;
                    organizationID: string;
                    noteID: string;
                    comment: string;
                    createdAt: string;
                    createdBy: string;
                    updatedAt?: string | null;
                  } | null>;
                  nextToken?: string | null;
                } | null;
                historys?: {
                  __typename: "ModelNoteHistoryConnection";
                  items: Array<{
                    __typename: "NoteHistory";
                    id: string;
                    organizationID: string;
                    noteID: string;
                    createdAt: string;
                    createdBy: string;
                    text?: string | null;
                    updatedAt?: string | null;
                  } | null>;
                  nextToken?: string | null;
                } | null;
              } | null;
              comment: string;
              createdAt: string;
              createdBy: string;
              updatedAt?: string | null;
            } | null>;
            nextToken?: string | null;
          } | null;
          historys?: {
            __typename: "ModelNoteHistoryConnection";
            items: Array<{
              __typename: "NoteHistory";
              id: string;
              organizationID: string;
              noteID: string;
              note?: {
                __typename: "Note";
                id: string;
                organizationID: string;
                objectID: string;
                createdAt: string;
                createdBy: string;
                updatedAt?: string | null;
                updatedBy?: string | null;
                text?: string | null;
                noteType: string;
                options?: string | null;
                comments?: {
                  __typename: "ModelNoteCommentConnection";
                  items: Array<{
                    __typename: "NoteComment";
                    id: string;
                    organizationID: string;
                    noteID: string;
                    comment: string;
                    createdAt: string;
                    createdBy: string;
                    updatedAt?: string | null;
                  } | null>;
                  nextToken?: string | null;
                } | null;
                historys?: {
                  __typename: "ModelNoteHistoryConnection";
                  items: Array<{
                    __typename: "NoteHistory";
                    id: string;
                    organizationID: string;
                    noteID: string;
                    createdAt: string;
                    createdBy: string;
                    text?: string | null;
                    updatedAt?: string | null;
                  } | null>;
                  nextToken?: string | null;
                } | null;
              } | null;
              createdAt: string;
              createdBy: string;
              text?: string | null;
              updatedAt?: string | null;
            } | null>;
            nextToken?: string | null;
          } | null;
        } | null;
        createdAt: string;
        createdBy: string;
        text?: string | null;
        updatedAt?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null;
  comment: string;
  createdAt: string;
  createdBy: string;
  updatedAt?: string | null;
};

export type OnCreateNoteSubscription = {
  __typename: "Note";
  id: string;
  organizationID: string;
  objectID: string;
  createdAt: string;
  createdBy: string;
  updatedAt?: string | null;
  updatedBy?: string | null;
  text?: string | null;
  noteType: string;
  options?: string | null;
  comments?: {
    __typename: "ModelNoteCommentConnection";
    items: Array<{
      __typename: "NoteComment";
      id: string;
      organizationID: string;
      noteID: string;
      note?: {
        __typename: "Note";
        id: string;
        organizationID: string;
        objectID: string;
        createdAt: string;
        createdBy: string;
        updatedAt?: string | null;
        updatedBy?: string | null;
        text?: string | null;
        noteType: string;
        options?: string | null;
        comments?: {
          __typename: "ModelNoteCommentConnection";
          items: Array<{
            __typename: "NoteComment";
            id: string;
            organizationID: string;
            noteID: string;
            note?: {
              __typename: "Note";
              id: string;
              organizationID: string;
              objectID: string;
              createdAt: string;
              createdBy: string;
              updatedAt?: string | null;
              updatedBy?: string | null;
              text?: string | null;
              noteType: string;
              options?: string | null;
              comments?: {
                __typename: "ModelNoteCommentConnection";
                items: Array<{
                  __typename: "NoteComment";
                  id: string;
                  organizationID: string;
                  noteID: string;
                  note?: {
                    __typename: "Note";
                    id: string;
                    organizationID: string;
                    objectID: string;
                    createdAt: string;
                    createdBy: string;
                    updatedAt?: string | null;
                    updatedBy?: string | null;
                    text?: string | null;
                    noteType: string;
                    options?: string | null;
                  } | null;
                  comment: string;
                  createdAt: string;
                  createdBy: string;
                  updatedAt?: string | null;
                } | null>;
                nextToken?: string | null;
              } | null;
              historys?: {
                __typename: "ModelNoteHistoryConnection";
                items: Array<{
                  __typename: "NoteHistory";
                  id: string;
                  organizationID: string;
                  noteID: string;
                  note?: {
                    __typename: "Note";
                    id: string;
                    organizationID: string;
                    objectID: string;
                    createdAt: string;
                    createdBy: string;
                    updatedAt?: string | null;
                    updatedBy?: string | null;
                    text?: string | null;
                    noteType: string;
                    options?: string | null;
                  } | null;
                  createdAt: string;
                  createdBy: string;
                  text?: string | null;
                  updatedAt?: string | null;
                } | null>;
                nextToken?: string | null;
              } | null;
            } | null;
            comment: string;
            createdAt: string;
            createdBy: string;
            updatedAt?: string | null;
          } | null>;
          nextToken?: string | null;
        } | null;
        historys?: {
          __typename: "ModelNoteHistoryConnection";
          items: Array<{
            __typename: "NoteHistory";
            id: string;
            organizationID: string;
            noteID: string;
            note?: {
              __typename: "Note";
              id: string;
              organizationID: string;
              objectID: string;
              createdAt: string;
              createdBy: string;
              updatedAt?: string | null;
              updatedBy?: string | null;
              text?: string | null;
              noteType: string;
              options?: string | null;
              comments?: {
                __typename: "ModelNoteCommentConnection";
                items: Array<{
                  __typename: "NoteComment";
                  id: string;
                  organizationID: string;
                  noteID: string;
                  note?: {
                    __typename: "Note";
                    id: string;
                    organizationID: string;
                    objectID: string;
                    createdAt: string;
                    createdBy: string;
                    updatedAt?: string | null;
                    updatedBy?: string | null;
                    text?: string | null;
                    noteType: string;
                    options?: string | null;
                  } | null;
                  comment: string;
                  createdAt: string;
                  createdBy: string;
                  updatedAt?: string | null;
                } | null>;
                nextToken?: string | null;
              } | null;
              historys?: {
                __typename: "ModelNoteHistoryConnection";
                items: Array<{
                  __typename: "NoteHistory";
                  id: string;
                  organizationID: string;
                  noteID: string;
                  note?: {
                    __typename: "Note";
                    id: string;
                    organizationID: string;
                    objectID: string;
                    createdAt: string;
                    createdBy: string;
                    updatedAt?: string | null;
                    updatedBy?: string | null;
                    text?: string | null;
                    noteType: string;
                    options?: string | null;
                  } | null;
                  createdAt: string;
                  createdBy: string;
                  text?: string | null;
                  updatedAt?: string | null;
                } | null>;
                nextToken?: string | null;
              } | null;
            } | null;
            createdAt: string;
            createdBy: string;
            text?: string | null;
            updatedAt?: string | null;
          } | null>;
          nextToken?: string | null;
        } | null;
      } | null;
      comment: string;
      createdAt: string;
      createdBy: string;
      updatedAt?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  historys?: {
    __typename: "ModelNoteHistoryConnection";
    items: Array<{
      __typename: "NoteHistory";
      id: string;
      organizationID: string;
      noteID: string;
      note?: {
        __typename: "Note";
        id: string;
        organizationID: string;
        objectID: string;
        createdAt: string;
        createdBy: string;
        updatedAt?: string | null;
        updatedBy?: string | null;
        text?: string | null;
        noteType: string;
        options?: string | null;
        comments?: {
          __typename: "ModelNoteCommentConnection";
          items: Array<{
            __typename: "NoteComment";
            id: string;
            organizationID: string;
            noteID: string;
            note?: {
              __typename: "Note";
              id: string;
              organizationID: string;
              objectID: string;
              createdAt: string;
              createdBy: string;
              updatedAt?: string | null;
              updatedBy?: string | null;
              text?: string | null;
              noteType: string;
              options?: string | null;
              comments?: {
                __typename: "ModelNoteCommentConnection";
                items: Array<{
                  __typename: "NoteComment";
                  id: string;
                  organizationID: string;
                  noteID: string;
                  note?: {
                    __typename: "Note";
                    id: string;
                    organizationID: string;
                    objectID: string;
                    createdAt: string;
                    createdBy: string;
                    updatedAt?: string | null;
                    updatedBy?: string | null;
                    text?: string | null;
                    noteType: string;
                    options?: string | null;
                  } | null;
                  comment: string;
                  createdAt: string;
                  createdBy: string;
                  updatedAt?: string | null;
                } | null>;
                nextToken?: string | null;
              } | null;
              historys?: {
                __typename: "ModelNoteHistoryConnection";
                items: Array<{
                  __typename: "NoteHistory";
                  id: string;
                  organizationID: string;
                  noteID: string;
                  note?: {
                    __typename: "Note";
                    id: string;
                    organizationID: string;
                    objectID: string;
                    createdAt: string;
                    createdBy: string;
                    updatedAt?: string | null;
                    updatedBy?: string | null;
                    text?: string | null;
                    noteType: string;
                    options?: string | null;
                  } | null;
                  createdAt: string;
                  createdBy: string;
                  text?: string | null;
                  updatedAt?: string | null;
                } | null>;
                nextToken?: string | null;
              } | null;
            } | null;
            comment: string;
            createdAt: string;
            createdBy: string;
            updatedAt?: string | null;
          } | null>;
          nextToken?: string | null;
        } | null;
        historys?: {
          __typename: "ModelNoteHistoryConnection";
          items: Array<{
            __typename: "NoteHistory";
            id: string;
            organizationID: string;
            noteID: string;
            note?: {
              __typename: "Note";
              id: string;
              organizationID: string;
              objectID: string;
              createdAt: string;
              createdBy: string;
              updatedAt?: string | null;
              updatedBy?: string | null;
              text?: string | null;
              noteType: string;
              options?: string | null;
              comments?: {
                __typename: "ModelNoteCommentConnection";
                items: Array<{
                  __typename: "NoteComment";
                  id: string;
                  organizationID: string;
                  noteID: string;
                  note?: {
                    __typename: "Note";
                    id: string;
                    organizationID: string;
                    objectID: string;
                    createdAt: string;
                    createdBy: string;
                    updatedAt?: string | null;
                    updatedBy?: string | null;
                    text?: string | null;
                    noteType: string;
                    options?: string | null;
                  } | null;
                  comment: string;
                  createdAt: string;
                  createdBy: string;
                  updatedAt?: string | null;
                } | null>;
                nextToken?: string | null;
              } | null;
              historys?: {
                __typename: "ModelNoteHistoryConnection";
                items: Array<{
                  __typename: "NoteHistory";
                  id: string;
                  organizationID: string;
                  noteID: string;
                  note?: {
                    __typename: "Note";
                    id: string;
                    organizationID: string;
                    objectID: string;
                    createdAt: string;
                    createdBy: string;
                    updatedAt?: string | null;
                    updatedBy?: string | null;
                    text?: string | null;
                    noteType: string;
                    options?: string | null;
                  } | null;
                  createdAt: string;
                  createdBy: string;
                  text?: string | null;
                  updatedAt?: string | null;
                } | null>;
                nextToken?: string | null;
              } | null;
            } | null;
            createdAt: string;
            createdBy: string;
            text?: string | null;
            updatedAt?: string | null;
          } | null>;
          nextToken?: string | null;
        } | null;
      } | null;
      createdAt: string;
      createdBy: string;
      text?: string | null;
      updatedAt?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type OnUpdateNoteSubscription = {
  __typename: "Note";
  id: string;
  organizationID: string;
  objectID: string;
  createdAt: string;
  createdBy: string;
  updatedAt?: string | null;
  updatedBy?: string | null;
  text?: string | null;
  noteType: string;
  options?: string | null;
  comments?: {
    __typename: "ModelNoteCommentConnection";
    items: Array<{
      __typename: "NoteComment";
      id: string;
      organizationID: string;
      noteID: string;
      note?: {
        __typename: "Note";
        id: string;
        organizationID: string;
        objectID: string;
        createdAt: string;
        createdBy: string;
        updatedAt?: string | null;
        updatedBy?: string | null;
        text?: string | null;
        noteType: string;
        options?: string | null;
        comments?: {
          __typename: "ModelNoteCommentConnection";
          items: Array<{
            __typename: "NoteComment";
            id: string;
            organizationID: string;
            noteID: string;
            note?: {
              __typename: "Note";
              id: string;
              organizationID: string;
              objectID: string;
              createdAt: string;
              createdBy: string;
              updatedAt?: string | null;
              updatedBy?: string | null;
              text?: string | null;
              noteType: string;
              options?: string | null;
              comments?: {
                __typename: "ModelNoteCommentConnection";
                items: Array<{
                  __typename: "NoteComment";
                  id: string;
                  organizationID: string;
                  noteID: string;
                  note?: {
                    __typename: "Note";
                    id: string;
                    organizationID: string;
                    objectID: string;
                    createdAt: string;
                    createdBy: string;
                    updatedAt?: string | null;
                    updatedBy?: string | null;
                    text?: string | null;
                    noteType: string;
                    options?: string | null;
                  } | null;
                  comment: string;
                  createdAt: string;
                  createdBy: string;
                  updatedAt?: string | null;
                } | null>;
                nextToken?: string | null;
              } | null;
              historys?: {
                __typename: "ModelNoteHistoryConnection";
                items: Array<{
                  __typename: "NoteHistory";
                  id: string;
                  organizationID: string;
                  noteID: string;
                  note?: {
                    __typename: "Note";
                    id: string;
                    organizationID: string;
                    objectID: string;
                    createdAt: string;
                    createdBy: string;
                    updatedAt?: string | null;
                    updatedBy?: string | null;
                    text?: string | null;
                    noteType: string;
                    options?: string | null;
                  } | null;
                  createdAt: string;
                  createdBy: string;
                  text?: string | null;
                  updatedAt?: string | null;
                } | null>;
                nextToken?: string | null;
              } | null;
            } | null;
            comment: string;
            createdAt: string;
            createdBy: string;
            updatedAt?: string | null;
          } | null>;
          nextToken?: string | null;
        } | null;
        historys?: {
          __typename: "ModelNoteHistoryConnection";
          items: Array<{
            __typename: "NoteHistory";
            id: string;
            organizationID: string;
            noteID: string;
            note?: {
              __typename: "Note";
              id: string;
              organizationID: string;
              objectID: string;
              createdAt: string;
              createdBy: string;
              updatedAt?: string | null;
              updatedBy?: string | null;
              text?: string | null;
              noteType: string;
              options?: string | null;
              comments?: {
                __typename: "ModelNoteCommentConnection";
                items: Array<{
                  __typename: "NoteComment";
                  id: string;
                  organizationID: string;
                  noteID: string;
                  note?: {
                    __typename: "Note";
                    id: string;
                    organizationID: string;
                    objectID: string;
                    createdAt: string;
                    createdBy: string;
                    updatedAt?: string | null;
                    updatedBy?: string | null;
                    text?: string | null;
                    noteType: string;
                    options?: string | null;
                  } | null;
                  comment: string;
                  createdAt: string;
                  createdBy: string;
                  updatedAt?: string | null;
                } | null>;
                nextToken?: string | null;
              } | null;
              historys?: {
                __typename: "ModelNoteHistoryConnection";
                items: Array<{
                  __typename: "NoteHistory";
                  id: string;
                  organizationID: string;
                  noteID: string;
                  note?: {
                    __typename: "Note";
                    id: string;
                    organizationID: string;
                    objectID: string;
                    createdAt: string;
                    createdBy: string;
                    updatedAt?: string | null;
                    updatedBy?: string | null;
                    text?: string | null;
                    noteType: string;
                    options?: string | null;
                  } | null;
                  createdAt: string;
                  createdBy: string;
                  text?: string | null;
                  updatedAt?: string | null;
                } | null>;
                nextToken?: string | null;
              } | null;
            } | null;
            createdAt: string;
            createdBy: string;
            text?: string | null;
            updatedAt?: string | null;
          } | null>;
          nextToken?: string | null;
        } | null;
      } | null;
      comment: string;
      createdAt: string;
      createdBy: string;
      updatedAt?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  historys?: {
    __typename: "ModelNoteHistoryConnection";
    items: Array<{
      __typename: "NoteHistory";
      id: string;
      organizationID: string;
      noteID: string;
      note?: {
        __typename: "Note";
        id: string;
        organizationID: string;
        objectID: string;
        createdAt: string;
        createdBy: string;
        updatedAt?: string | null;
        updatedBy?: string | null;
        text?: string | null;
        noteType: string;
        options?: string | null;
        comments?: {
          __typename: "ModelNoteCommentConnection";
          items: Array<{
            __typename: "NoteComment";
            id: string;
            organizationID: string;
            noteID: string;
            note?: {
              __typename: "Note";
              id: string;
              organizationID: string;
              objectID: string;
              createdAt: string;
              createdBy: string;
              updatedAt?: string | null;
              updatedBy?: string | null;
              text?: string | null;
              noteType: string;
              options?: string | null;
              comments?: {
                __typename: "ModelNoteCommentConnection";
                items: Array<{
                  __typename: "NoteComment";
                  id: string;
                  organizationID: string;
                  noteID: string;
                  note?: {
                    __typename: "Note";
                    id: string;
                    organizationID: string;
                    objectID: string;
                    createdAt: string;
                    createdBy: string;
                    updatedAt?: string | null;
                    updatedBy?: string | null;
                    text?: string | null;
                    noteType: string;
                    options?: string | null;
                  } | null;
                  comment: string;
                  createdAt: string;
                  createdBy: string;
                  updatedAt?: string | null;
                } | null>;
                nextToken?: string | null;
              } | null;
              historys?: {
                __typename: "ModelNoteHistoryConnection";
                items: Array<{
                  __typename: "NoteHistory";
                  id: string;
                  organizationID: string;
                  noteID: string;
                  note?: {
                    __typename: "Note";
                    id: string;
                    organizationID: string;
                    objectID: string;
                    createdAt: string;
                    createdBy: string;
                    updatedAt?: string | null;
                    updatedBy?: string | null;
                    text?: string | null;
                    noteType: string;
                    options?: string | null;
                  } | null;
                  createdAt: string;
                  createdBy: string;
                  text?: string | null;
                  updatedAt?: string | null;
                } | null>;
                nextToken?: string | null;
              } | null;
            } | null;
            comment: string;
            createdAt: string;
            createdBy: string;
            updatedAt?: string | null;
          } | null>;
          nextToken?: string | null;
        } | null;
        historys?: {
          __typename: "ModelNoteHistoryConnection";
          items: Array<{
            __typename: "NoteHistory";
            id: string;
            organizationID: string;
            noteID: string;
            note?: {
              __typename: "Note";
              id: string;
              organizationID: string;
              objectID: string;
              createdAt: string;
              createdBy: string;
              updatedAt?: string | null;
              updatedBy?: string | null;
              text?: string | null;
              noteType: string;
              options?: string | null;
              comments?: {
                __typename: "ModelNoteCommentConnection";
                items: Array<{
                  __typename: "NoteComment";
                  id: string;
                  organizationID: string;
                  noteID: string;
                  note?: {
                    __typename: "Note";
                    id: string;
                    organizationID: string;
                    objectID: string;
                    createdAt: string;
                    createdBy: string;
                    updatedAt?: string | null;
                    updatedBy?: string | null;
                    text?: string | null;
                    noteType: string;
                    options?: string | null;
                  } | null;
                  comment: string;
                  createdAt: string;
                  createdBy: string;
                  updatedAt?: string | null;
                } | null>;
                nextToken?: string | null;
              } | null;
              historys?: {
                __typename: "ModelNoteHistoryConnection";
                items: Array<{
                  __typename: "NoteHistory";
                  id: string;
                  organizationID: string;
                  noteID: string;
                  note?: {
                    __typename: "Note";
                    id: string;
                    organizationID: string;
                    objectID: string;
                    createdAt: string;
                    createdBy: string;
                    updatedAt?: string | null;
                    updatedBy?: string | null;
                    text?: string | null;
                    noteType: string;
                    options?: string | null;
                  } | null;
                  createdAt: string;
                  createdBy: string;
                  text?: string | null;
                  updatedAt?: string | null;
                } | null>;
                nextToken?: string | null;
              } | null;
            } | null;
            createdAt: string;
            createdBy: string;
            text?: string | null;
            updatedAt?: string | null;
          } | null>;
          nextToken?: string | null;
        } | null;
      } | null;
      createdAt: string;
      createdBy: string;
      text?: string | null;
      updatedAt?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type OnDeleteNoteSubscription = {
  __typename: "Note";
  id: string;
  organizationID: string;
  objectID: string;
  createdAt: string;
  createdBy: string;
  updatedAt?: string | null;
  updatedBy?: string | null;
  text?: string | null;
  noteType: string;
  options?: string | null;
  comments?: {
    __typename: "ModelNoteCommentConnection";
    items: Array<{
      __typename: "NoteComment";
      id: string;
      organizationID: string;
      noteID: string;
      note?: {
        __typename: "Note";
        id: string;
        organizationID: string;
        objectID: string;
        createdAt: string;
        createdBy: string;
        updatedAt?: string | null;
        updatedBy?: string | null;
        text?: string | null;
        noteType: string;
        options?: string | null;
        comments?: {
          __typename: "ModelNoteCommentConnection";
          items: Array<{
            __typename: "NoteComment";
            id: string;
            organizationID: string;
            noteID: string;
            note?: {
              __typename: "Note";
              id: string;
              organizationID: string;
              objectID: string;
              createdAt: string;
              createdBy: string;
              updatedAt?: string | null;
              updatedBy?: string | null;
              text?: string | null;
              noteType: string;
              options?: string | null;
              comments?: {
                __typename: "ModelNoteCommentConnection";
                items: Array<{
                  __typename: "NoteComment";
                  id: string;
                  organizationID: string;
                  noteID: string;
                  note?: {
                    __typename: "Note";
                    id: string;
                    organizationID: string;
                    objectID: string;
                    createdAt: string;
                    createdBy: string;
                    updatedAt?: string | null;
                    updatedBy?: string | null;
                    text?: string | null;
                    noteType: string;
                    options?: string | null;
                  } | null;
                  comment: string;
                  createdAt: string;
                  createdBy: string;
                  updatedAt?: string | null;
                } | null>;
                nextToken?: string | null;
              } | null;
              historys?: {
                __typename: "ModelNoteHistoryConnection";
                items: Array<{
                  __typename: "NoteHistory";
                  id: string;
                  organizationID: string;
                  noteID: string;
                  note?: {
                    __typename: "Note";
                    id: string;
                    organizationID: string;
                    objectID: string;
                    createdAt: string;
                    createdBy: string;
                    updatedAt?: string | null;
                    updatedBy?: string | null;
                    text?: string | null;
                    noteType: string;
                    options?: string | null;
                  } | null;
                  createdAt: string;
                  createdBy: string;
                  text?: string | null;
                  updatedAt?: string | null;
                } | null>;
                nextToken?: string | null;
              } | null;
            } | null;
            comment: string;
            createdAt: string;
            createdBy: string;
            updatedAt?: string | null;
          } | null>;
          nextToken?: string | null;
        } | null;
        historys?: {
          __typename: "ModelNoteHistoryConnection";
          items: Array<{
            __typename: "NoteHistory";
            id: string;
            organizationID: string;
            noteID: string;
            note?: {
              __typename: "Note";
              id: string;
              organizationID: string;
              objectID: string;
              createdAt: string;
              createdBy: string;
              updatedAt?: string | null;
              updatedBy?: string | null;
              text?: string | null;
              noteType: string;
              options?: string | null;
              comments?: {
                __typename: "ModelNoteCommentConnection";
                items: Array<{
                  __typename: "NoteComment";
                  id: string;
                  organizationID: string;
                  noteID: string;
                  note?: {
                    __typename: "Note";
                    id: string;
                    organizationID: string;
                    objectID: string;
                    createdAt: string;
                    createdBy: string;
                    updatedAt?: string | null;
                    updatedBy?: string | null;
                    text?: string | null;
                    noteType: string;
                    options?: string | null;
                  } | null;
                  comment: string;
                  createdAt: string;
                  createdBy: string;
                  updatedAt?: string | null;
                } | null>;
                nextToken?: string | null;
              } | null;
              historys?: {
                __typename: "ModelNoteHistoryConnection";
                items: Array<{
                  __typename: "NoteHistory";
                  id: string;
                  organizationID: string;
                  noteID: string;
                  note?: {
                    __typename: "Note";
                    id: string;
                    organizationID: string;
                    objectID: string;
                    createdAt: string;
                    createdBy: string;
                    updatedAt?: string | null;
                    updatedBy?: string | null;
                    text?: string | null;
                    noteType: string;
                    options?: string | null;
                  } | null;
                  createdAt: string;
                  createdBy: string;
                  text?: string | null;
                  updatedAt?: string | null;
                } | null>;
                nextToken?: string | null;
              } | null;
            } | null;
            createdAt: string;
            createdBy: string;
            text?: string | null;
            updatedAt?: string | null;
          } | null>;
          nextToken?: string | null;
        } | null;
      } | null;
      comment: string;
      createdAt: string;
      createdBy: string;
      updatedAt?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  historys?: {
    __typename: "ModelNoteHistoryConnection";
    items: Array<{
      __typename: "NoteHistory";
      id: string;
      organizationID: string;
      noteID: string;
      note?: {
        __typename: "Note";
        id: string;
        organizationID: string;
        objectID: string;
        createdAt: string;
        createdBy: string;
        updatedAt?: string | null;
        updatedBy?: string | null;
        text?: string | null;
        noteType: string;
        options?: string | null;
        comments?: {
          __typename: "ModelNoteCommentConnection";
          items: Array<{
            __typename: "NoteComment";
            id: string;
            organizationID: string;
            noteID: string;
            note?: {
              __typename: "Note";
              id: string;
              organizationID: string;
              objectID: string;
              createdAt: string;
              createdBy: string;
              updatedAt?: string | null;
              updatedBy?: string | null;
              text?: string | null;
              noteType: string;
              options?: string | null;
              comments?: {
                __typename: "ModelNoteCommentConnection";
                items: Array<{
                  __typename: "NoteComment";
                  id: string;
                  organizationID: string;
                  noteID: string;
                  note?: {
                    __typename: "Note";
                    id: string;
                    organizationID: string;
                    objectID: string;
                    createdAt: string;
                    createdBy: string;
                    updatedAt?: string | null;
                    updatedBy?: string | null;
                    text?: string | null;
                    noteType: string;
                    options?: string | null;
                  } | null;
                  comment: string;
                  createdAt: string;
                  createdBy: string;
                  updatedAt?: string | null;
                } | null>;
                nextToken?: string | null;
              } | null;
              historys?: {
                __typename: "ModelNoteHistoryConnection";
                items: Array<{
                  __typename: "NoteHistory";
                  id: string;
                  organizationID: string;
                  noteID: string;
                  note?: {
                    __typename: "Note";
                    id: string;
                    organizationID: string;
                    objectID: string;
                    createdAt: string;
                    createdBy: string;
                    updatedAt?: string | null;
                    updatedBy?: string | null;
                    text?: string | null;
                    noteType: string;
                    options?: string | null;
                  } | null;
                  createdAt: string;
                  createdBy: string;
                  text?: string | null;
                  updatedAt?: string | null;
                } | null>;
                nextToken?: string | null;
              } | null;
            } | null;
            comment: string;
            createdAt: string;
            createdBy: string;
            updatedAt?: string | null;
          } | null>;
          nextToken?: string | null;
        } | null;
        historys?: {
          __typename: "ModelNoteHistoryConnection";
          items: Array<{
            __typename: "NoteHistory";
            id: string;
            organizationID: string;
            noteID: string;
            note?: {
              __typename: "Note";
              id: string;
              organizationID: string;
              objectID: string;
              createdAt: string;
              createdBy: string;
              updatedAt?: string | null;
              updatedBy?: string | null;
              text?: string | null;
              noteType: string;
              options?: string | null;
              comments?: {
                __typename: "ModelNoteCommentConnection";
                items: Array<{
                  __typename: "NoteComment";
                  id: string;
                  organizationID: string;
                  noteID: string;
                  note?: {
                    __typename: "Note";
                    id: string;
                    organizationID: string;
                    objectID: string;
                    createdAt: string;
                    createdBy: string;
                    updatedAt?: string | null;
                    updatedBy?: string | null;
                    text?: string | null;
                    noteType: string;
                    options?: string | null;
                  } | null;
                  comment: string;
                  createdAt: string;
                  createdBy: string;
                  updatedAt?: string | null;
                } | null>;
                nextToken?: string | null;
              } | null;
              historys?: {
                __typename: "ModelNoteHistoryConnection";
                items: Array<{
                  __typename: "NoteHistory";
                  id: string;
                  organizationID: string;
                  noteID: string;
                  note?: {
                    __typename: "Note";
                    id: string;
                    organizationID: string;
                    objectID: string;
                    createdAt: string;
                    createdBy: string;
                    updatedAt?: string | null;
                    updatedBy?: string | null;
                    text?: string | null;
                    noteType: string;
                    options?: string | null;
                  } | null;
                  createdAt: string;
                  createdBy: string;
                  text?: string | null;
                  updatedAt?: string | null;
                } | null>;
                nextToken?: string | null;
              } | null;
            } | null;
            createdAt: string;
            createdBy: string;
            text?: string | null;
            updatedAt?: string | null;
          } | null>;
          nextToken?: string | null;
        } | null;
      } | null;
      createdAt: string;
      createdBy: string;
      text?: string | null;
      updatedAt?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type OnCreateNoteHistorySubscription = {
  __typename: "NoteHistory";
  id: string;
  organizationID: string;
  noteID: string;
  note?: {
    __typename: "Note";
    id: string;
    organizationID: string;
    objectID: string;
    createdAt: string;
    createdBy: string;
    updatedAt?: string | null;
    updatedBy?: string | null;
    text?: string | null;
    noteType: string;
    options?: string | null;
    comments?: {
      __typename: "ModelNoteCommentConnection";
      items: Array<{
        __typename: "NoteComment";
        id: string;
        organizationID: string;
        noteID: string;
        note?: {
          __typename: "Note";
          id: string;
          organizationID: string;
          objectID: string;
          createdAt: string;
          createdBy: string;
          updatedAt?: string | null;
          updatedBy?: string | null;
          text?: string | null;
          noteType: string;
          options?: string | null;
          comments?: {
            __typename: "ModelNoteCommentConnection";
            items: Array<{
              __typename: "NoteComment";
              id: string;
              organizationID: string;
              noteID: string;
              note?: {
                __typename: "Note";
                id: string;
                organizationID: string;
                objectID: string;
                createdAt: string;
                createdBy: string;
                updatedAt?: string | null;
                updatedBy?: string | null;
                text?: string | null;
                noteType: string;
                options?: string | null;
                comments?: {
                  __typename: "ModelNoteCommentConnection";
                  items: Array<{
                    __typename: "NoteComment";
                    id: string;
                    organizationID: string;
                    noteID: string;
                    comment: string;
                    createdAt: string;
                    createdBy: string;
                    updatedAt?: string | null;
                  } | null>;
                  nextToken?: string | null;
                } | null;
                historys?: {
                  __typename: "ModelNoteHistoryConnection";
                  items: Array<{
                    __typename: "NoteHistory";
                    id: string;
                    organizationID: string;
                    noteID: string;
                    createdAt: string;
                    createdBy: string;
                    text?: string | null;
                    updatedAt?: string | null;
                  } | null>;
                  nextToken?: string | null;
                } | null;
              } | null;
              comment: string;
              createdAt: string;
              createdBy: string;
              updatedAt?: string | null;
            } | null>;
            nextToken?: string | null;
          } | null;
          historys?: {
            __typename: "ModelNoteHistoryConnection";
            items: Array<{
              __typename: "NoteHistory";
              id: string;
              organizationID: string;
              noteID: string;
              note?: {
                __typename: "Note";
                id: string;
                organizationID: string;
                objectID: string;
                createdAt: string;
                createdBy: string;
                updatedAt?: string | null;
                updatedBy?: string | null;
                text?: string | null;
                noteType: string;
                options?: string | null;
                comments?: {
                  __typename: "ModelNoteCommentConnection";
                  items: Array<{
                    __typename: "NoteComment";
                    id: string;
                    organizationID: string;
                    noteID: string;
                    comment: string;
                    createdAt: string;
                    createdBy: string;
                    updatedAt?: string | null;
                  } | null>;
                  nextToken?: string | null;
                } | null;
                historys?: {
                  __typename: "ModelNoteHistoryConnection";
                  items: Array<{
                    __typename: "NoteHistory";
                    id: string;
                    organizationID: string;
                    noteID: string;
                    createdAt: string;
                    createdBy: string;
                    text?: string | null;
                    updatedAt?: string | null;
                  } | null>;
                  nextToken?: string | null;
                } | null;
              } | null;
              createdAt: string;
              createdBy: string;
              text?: string | null;
              updatedAt?: string | null;
            } | null>;
            nextToken?: string | null;
          } | null;
        } | null;
        comment: string;
        createdAt: string;
        createdBy: string;
        updatedAt?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    historys?: {
      __typename: "ModelNoteHistoryConnection";
      items: Array<{
        __typename: "NoteHistory";
        id: string;
        organizationID: string;
        noteID: string;
        note?: {
          __typename: "Note";
          id: string;
          organizationID: string;
          objectID: string;
          createdAt: string;
          createdBy: string;
          updatedAt?: string | null;
          updatedBy?: string | null;
          text?: string | null;
          noteType: string;
          options?: string | null;
          comments?: {
            __typename: "ModelNoteCommentConnection";
            items: Array<{
              __typename: "NoteComment";
              id: string;
              organizationID: string;
              noteID: string;
              note?: {
                __typename: "Note";
                id: string;
                organizationID: string;
                objectID: string;
                createdAt: string;
                createdBy: string;
                updatedAt?: string | null;
                updatedBy?: string | null;
                text?: string | null;
                noteType: string;
                options?: string | null;
                comments?: {
                  __typename: "ModelNoteCommentConnection";
                  items: Array<{
                    __typename: "NoteComment";
                    id: string;
                    organizationID: string;
                    noteID: string;
                    comment: string;
                    createdAt: string;
                    createdBy: string;
                    updatedAt?: string | null;
                  } | null>;
                  nextToken?: string | null;
                } | null;
                historys?: {
                  __typename: "ModelNoteHistoryConnection";
                  items: Array<{
                    __typename: "NoteHistory";
                    id: string;
                    organizationID: string;
                    noteID: string;
                    createdAt: string;
                    createdBy: string;
                    text?: string | null;
                    updatedAt?: string | null;
                  } | null>;
                  nextToken?: string | null;
                } | null;
              } | null;
              comment: string;
              createdAt: string;
              createdBy: string;
              updatedAt?: string | null;
            } | null>;
            nextToken?: string | null;
          } | null;
          historys?: {
            __typename: "ModelNoteHistoryConnection";
            items: Array<{
              __typename: "NoteHistory";
              id: string;
              organizationID: string;
              noteID: string;
              note?: {
                __typename: "Note";
                id: string;
                organizationID: string;
                objectID: string;
                createdAt: string;
                createdBy: string;
                updatedAt?: string | null;
                updatedBy?: string | null;
                text?: string | null;
                noteType: string;
                options?: string | null;
                comments?: {
                  __typename: "ModelNoteCommentConnection";
                  items: Array<{
                    __typename: "NoteComment";
                    id: string;
                    organizationID: string;
                    noteID: string;
                    comment: string;
                    createdAt: string;
                    createdBy: string;
                    updatedAt?: string | null;
                  } | null>;
                  nextToken?: string | null;
                } | null;
                historys?: {
                  __typename: "ModelNoteHistoryConnection";
                  items: Array<{
                    __typename: "NoteHistory";
                    id: string;
                    organizationID: string;
                    noteID: string;
                    createdAt: string;
                    createdBy: string;
                    text?: string | null;
                    updatedAt?: string | null;
                  } | null>;
                  nextToken?: string | null;
                } | null;
              } | null;
              createdAt: string;
              createdBy: string;
              text?: string | null;
              updatedAt?: string | null;
            } | null>;
            nextToken?: string | null;
          } | null;
        } | null;
        createdAt: string;
        createdBy: string;
        text?: string | null;
        updatedAt?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null;
  createdAt: string;
  createdBy: string;
  text?: string | null;
  updatedAt?: string | null;
};

export type OnUpdateNoteHistorySubscription = {
  __typename: "NoteHistory";
  id: string;
  organizationID: string;
  noteID: string;
  note?: {
    __typename: "Note";
    id: string;
    organizationID: string;
    objectID: string;
    createdAt: string;
    createdBy: string;
    updatedAt?: string | null;
    updatedBy?: string | null;
    text?: string | null;
    noteType: string;
    options?: string | null;
    comments?: {
      __typename: "ModelNoteCommentConnection";
      items: Array<{
        __typename: "NoteComment";
        id: string;
        organizationID: string;
        noteID: string;
        note?: {
          __typename: "Note";
          id: string;
          organizationID: string;
          objectID: string;
          createdAt: string;
          createdBy: string;
          updatedAt?: string | null;
          updatedBy?: string | null;
          text?: string | null;
          noteType: string;
          options?: string | null;
          comments?: {
            __typename: "ModelNoteCommentConnection";
            items: Array<{
              __typename: "NoteComment";
              id: string;
              organizationID: string;
              noteID: string;
              note?: {
                __typename: "Note";
                id: string;
                organizationID: string;
                objectID: string;
                createdAt: string;
                createdBy: string;
                updatedAt?: string | null;
                updatedBy?: string | null;
                text?: string | null;
                noteType: string;
                options?: string | null;
                comments?: {
                  __typename: "ModelNoteCommentConnection";
                  items: Array<{
                    __typename: "NoteComment";
                    id: string;
                    organizationID: string;
                    noteID: string;
                    comment: string;
                    createdAt: string;
                    createdBy: string;
                    updatedAt?: string | null;
                  } | null>;
                  nextToken?: string | null;
                } | null;
                historys?: {
                  __typename: "ModelNoteHistoryConnection";
                  items: Array<{
                    __typename: "NoteHistory";
                    id: string;
                    organizationID: string;
                    noteID: string;
                    createdAt: string;
                    createdBy: string;
                    text?: string | null;
                    updatedAt?: string | null;
                  } | null>;
                  nextToken?: string | null;
                } | null;
              } | null;
              comment: string;
              createdAt: string;
              createdBy: string;
              updatedAt?: string | null;
            } | null>;
            nextToken?: string | null;
          } | null;
          historys?: {
            __typename: "ModelNoteHistoryConnection";
            items: Array<{
              __typename: "NoteHistory";
              id: string;
              organizationID: string;
              noteID: string;
              note?: {
                __typename: "Note";
                id: string;
                organizationID: string;
                objectID: string;
                createdAt: string;
                createdBy: string;
                updatedAt?: string | null;
                updatedBy?: string | null;
                text?: string | null;
                noteType: string;
                options?: string | null;
                comments?: {
                  __typename: "ModelNoteCommentConnection";
                  items: Array<{
                    __typename: "NoteComment";
                    id: string;
                    organizationID: string;
                    noteID: string;
                    comment: string;
                    createdAt: string;
                    createdBy: string;
                    updatedAt?: string | null;
                  } | null>;
                  nextToken?: string | null;
                } | null;
                historys?: {
                  __typename: "ModelNoteHistoryConnection";
                  items: Array<{
                    __typename: "NoteHistory";
                    id: string;
                    organizationID: string;
                    noteID: string;
                    createdAt: string;
                    createdBy: string;
                    text?: string | null;
                    updatedAt?: string | null;
                  } | null>;
                  nextToken?: string | null;
                } | null;
              } | null;
              createdAt: string;
              createdBy: string;
              text?: string | null;
              updatedAt?: string | null;
            } | null>;
            nextToken?: string | null;
          } | null;
        } | null;
        comment: string;
        createdAt: string;
        createdBy: string;
        updatedAt?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    historys?: {
      __typename: "ModelNoteHistoryConnection";
      items: Array<{
        __typename: "NoteHistory";
        id: string;
        organizationID: string;
        noteID: string;
        note?: {
          __typename: "Note";
          id: string;
          organizationID: string;
          objectID: string;
          createdAt: string;
          createdBy: string;
          updatedAt?: string | null;
          updatedBy?: string | null;
          text?: string | null;
          noteType: string;
          options?: string | null;
          comments?: {
            __typename: "ModelNoteCommentConnection";
            items: Array<{
              __typename: "NoteComment";
              id: string;
              organizationID: string;
              noteID: string;
              note?: {
                __typename: "Note";
                id: string;
                organizationID: string;
                objectID: string;
                createdAt: string;
                createdBy: string;
                updatedAt?: string | null;
                updatedBy?: string | null;
                text?: string | null;
                noteType: string;
                options?: string | null;
                comments?: {
                  __typename: "ModelNoteCommentConnection";
                  items: Array<{
                    __typename: "NoteComment";
                    id: string;
                    organizationID: string;
                    noteID: string;
                    comment: string;
                    createdAt: string;
                    createdBy: string;
                    updatedAt?: string | null;
                  } | null>;
                  nextToken?: string | null;
                } | null;
                historys?: {
                  __typename: "ModelNoteHistoryConnection";
                  items: Array<{
                    __typename: "NoteHistory";
                    id: string;
                    organizationID: string;
                    noteID: string;
                    createdAt: string;
                    createdBy: string;
                    text?: string | null;
                    updatedAt?: string | null;
                  } | null>;
                  nextToken?: string | null;
                } | null;
              } | null;
              comment: string;
              createdAt: string;
              createdBy: string;
              updatedAt?: string | null;
            } | null>;
            nextToken?: string | null;
          } | null;
          historys?: {
            __typename: "ModelNoteHistoryConnection";
            items: Array<{
              __typename: "NoteHistory";
              id: string;
              organizationID: string;
              noteID: string;
              note?: {
                __typename: "Note";
                id: string;
                organizationID: string;
                objectID: string;
                createdAt: string;
                createdBy: string;
                updatedAt?: string | null;
                updatedBy?: string | null;
                text?: string | null;
                noteType: string;
                options?: string | null;
                comments?: {
                  __typename: "ModelNoteCommentConnection";
                  items: Array<{
                    __typename: "NoteComment";
                    id: string;
                    organizationID: string;
                    noteID: string;
                    comment: string;
                    createdAt: string;
                    createdBy: string;
                    updatedAt?: string | null;
                  } | null>;
                  nextToken?: string | null;
                } | null;
                historys?: {
                  __typename: "ModelNoteHistoryConnection";
                  items: Array<{
                    __typename: "NoteHistory";
                    id: string;
                    organizationID: string;
                    noteID: string;
                    createdAt: string;
                    createdBy: string;
                    text?: string | null;
                    updatedAt?: string | null;
                  } | null>;
                  nextToken?: string | null;
                } | null;
              } | null;
              createdAt: string;
              createdBy: string;
              text?: string | null;
              updatedAt?: string | null;
            } | null>;
            nextToken?: string | null;
          } | null;
        } | null;
        createdAt: string;
        createdBy: string;
        text?: string | null;
        updatedAt?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null;
  createdAt: string;
  createdBy: string;
  text?: string | null;
  updatedAt?: string | null;
};

export type OnDeleteNoteHistorySubscription = {
  __typename: "NoteHistory";
  id: string;
  organizationID: string;
  noteID: string;
  note?: {
    __typename: "Note";
    id: string;
    organizationID: string;
    objectID: string;
    createdAt: string;
    createdBy: string;
    updatedAt?: string | null;
    updatedBy?: string | null;
    text?: string | null;
    noteType: string;
    options?: string | null;
    comments?: {
      __typename: "ModelNoteCommentConnection";
      items: Array<{
        __typename: "NoteComment";
        id: string;
        organizationID: string;
        noteID: string;
        note?: {
          __typename: "Note";
          id: string;
          organizationID: string;
          objectID: string;
          createdAt: string;
          createdBy: string;
          updatedAt?: string | null;
          updatedBy?: string | null;
          text?: string | null;
          noteType: string;
          options?: string | null;
          comments?: {
            __typename: "ModelNoteCommentConnection";
            items: Array<{
              __typename: "NoteComment";
              id: string;
              organizationID: string;
              noteID: string;
              note?: {
                __typename: "Note";
                id: string;
                organizationID: string;
                objectID: string;
                createdAt: string;
                createdBy: string;
                updatedAt?: string | null;
                updatedBy?: string | null;
                text?: string | null;
                noteType: string;
                options?: string | null;
                comments?: {
                  __typename: "ModelNoteCommentConnection";
                  items: Array<{
                    __typename: "NoteComment";
                    id: string;
                    organizationID: string;
                    noteID: string;
                    comment: string;
                    createdAt: string;
                    createdBy: string;
                    updatedAt?: string | null;
                  } | null>;
                  nextToken?: string | null;
                } | null;
                historys?: {
                  __typename: "ModelNoteHistoryConnection";
                  items: Array<{
                    __typename: "NoteHistory";
                    id: string;
                    organizationID: string;
                    noteID: string;
                    createdAt: string;
                    createdBy: string;
                    text?: string | null;
                    updatedAt?: string | null;
                  } | null>;
                  nextToken?: string | null;
                } | null;
              } | null;
              comment: string;
              createdAt: string;
              createdBy: string;
              updatedAt?: string | null;
            } | null>;
            nextToken?: string | null;
          } | null;
          historys?: {
            __typename: "ModelNoteHistoryConnection";
            items: Array<{
              __typename: "NoteHistory";
              id: string;
              organizationID: string;
              noteID: string;
              note?: {
                __typename: "Note";
                id: string;
                organizationID: string;
                objectID: string;
                createdAt: string;
                createdBy: string;
                updatedAt?: string | null;
                updatedBy?: string | null;
                text?: string | null;
                noteType: string;
                options?: string | null;
                comments?: {
                  __typename: "ModelNoteCommentConnection";
                  items: Array<{
                    __typename: "NoteComment";
                    id: string;
                    organizationID: string;
                    noteID: string;
                    comment: string;
                    createdAt: string;
                    createdBy: string;
                    updatedAt?: string | null;
                  } | null>;
                  nextToken?: string | null;
                } | null;
                historys?: {
                  __typename: "ModelNoteHistoryConnection";
                  items: Array<{
                    __typename: "NoteHistory";
                    id: string;
                    organizationID: string;
                    noteID: string;
                    createdAt: string;
                    createdBy: string;
                    text?: string | null;
                    updatedAt?: string | null;
                  } | null>;
                  nextToken?: string | null;
                } | null;
              } | null;
              createdAt: string;
              createdBy: string;
              text?: string | null;
              updatedAt?: string | null;
            } | null>;
            nextToken?: string | null;
          } | null;
        } | null;
        comment: string;
        createdAt: string;
        createdBy: string;
        updatedAt?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    historys?: {
      __typename: "ModelNoteHistoryConnection";
      items: Array<{
        __typename: "NoteHistory";
        id: string;
        organizationID: string;
        noteID: string;
        note?: {
          __typename: "Note";
          id: string;
          organizationID: string;
          objectID: string;
          createdAt: string;
          createdBy: string;
          updatedAt?: string | null;
          updatedBy?: string | null;
          text?: string | null;
          noteType: string;
          options?: string | null;
          comments?: {
            __typename: "ModelNoteCommentConnection";
            items: Array<{
              __typename: "NoteComment";
              id: string;
              organizationID: string;
              noteID: string;
              note?: {
                __typename: "Note";
                id: string;
                organizationID: string;
                objectID: string;
                createdAt: string;
                createdBy: string;
                updatedAt?: string | null;
                updatedBy?: string | null;
                text?: string | null;
                noteType: string;
                options?: string | null;
                comments?: {
                  __typename: "ModelNoteCommentConnection";
                  items: Array<{
                    __typename: "NoteComment";
                    id: string;
                    organizationID: string;
                    noteID: string;
                    comment: string;
                    createdAt: string;
                    createdBy: string;
                    updatedAt?: string | null;
                  } | null>;
                  nextToken?: string | null;
                } | null;
                historys?: {
                  __typename: "ModelNoteHistoryConnection";
                  items: Array<{
                    __typename: "NoteHistory";
                    id: string;
                    organizationID: string;
                    noteID: string;
                    createdAt: string;
                    createdBy: string;
                    text?: string | null;
                    updatedAt?: string | null;
                  } | null>;
                  nextToken?: string | null;
                } | null;
              } | null;
              comment: string;
              createdAt: string;
              createdBy: string;
              updatedAt?: string | null;
            } | null>;
            nextToken?: string | null;
          } | null;
          historys?: {
            __typename: "ModelNoteHistoryConnection";
            items: Array<{
              __typename: "NoteHistory";
              id: string;
              organizationID: string;
              noteID: string;
              note?: {
                __typename: "Note";
                id: string;
                organizationID: string;
                objectID: string;
                createdAt: string;
                createdBy: string;
                updatedAt?: string | null;
                updatedBy?: string | null;
                text?: string | null;
                noteType: string;
                options?: string | null;
                comments?: {
                  __typename: "ModelNoteCommentConnection";
                  items: Array<{
                    __typename: "NoteComment";
                    id: string;
                    organizationID: string;
                    noteID: string;
                    comment: string;
                    createdAt: string;
                    createdBy: string;
                    updatedAt?: string | null;
                  } | null>;
                  nextToken?: string | null;
                } | null;
                historys?: {
                  __typename: "ModelNoteHistoryConnection";
                  items: Array<{
                    __typename: "NoteHistory";
                    id: string;
                    organizationID: string;
                    noteID: string;
                    createdAt: string;
                    createdBy: string;
                    text?: string | null;
                    updatedAt?: string | null;
                  } | null>;
                  nextToken?: string | null;
                } | null;
              } | null;
              createdAt: string;
              createdBy: string;
              text?: string | null;
              updatedAt?: string | null;
            } | null>;
            nextToken?: string | null;
          } | null;
        } | null;
        createdAt: string;
        createdBy: string;
        text?: string | null;
        updatedAt?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null;
  createdAt: string;
  createdBy: string;
  text?: string | null;
  updatedAt?: string | null;
};

export type OnCreateShoutOutTypeSubscription = {
  __typename: "ShoutOutType";
  id: string;
  title: string;
  formalDescription?: string | null;
  informalDescription?: string | null;
  icon?: string | null;
  attributes: Array<string | null>;
  status?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type OnUpdateShoutOutTypeSubscription = {
  __typename: "ShoutOutType";
  id: string;
  title: string;
  formalDescription?: string | null;
  informalDescription?: string | null;
  icon?: string | null;
  attributes: Array<string | null>;
  status?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type OnDeleteShoutOutTypeSubscription = {
  __typename: "ShoutOutType";
  id: string;
  title: string;
  formalDescription?: string | null;
  informalDescription?: string | null;
  icon?: string | null;
  attributes: Array<string | null>;
  status?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type OnCreateOrganizationShoutOutTypeSubscription = {
  __typename: "OrganizationShoutOutType";
  id: string;
  organizationID: string;
  overrideTitle: string;
  organizationShoutOutTypeShoutOuTypeId: string;
  shoutOutType: {
    __typename: "ShoutOutType";
    id: string;
    title: string;
    formalDescription?: string | null;
    informalDescription?: string | null;
    icon?: string | null;
    attributes: Array<string | null>;
    status?: string | null;
    createdAt?: string | null;
    updatedAt?: string | null;
  };
  description: string;
  overrideIcon?: string | null;
  overrideIconIdentityId?: string | null;
  enabled?: boolean | null;
  organizationAttributes?: Array<string | null> | null;
  disabledAttributes?: Array<number | null> | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type OnUpdateOrganizationShoutOutTypeSubscription = {
  __typename: "OrganizationShoutOutType";
  id: string;
  organizationID: string;
  overrideTitle: string;
  organizationShoutOutTypeShoutOuTypeId: string;
  shoutOutType: {
    __typename: "ShoutOutType";
    id: string;
    title: string;
    formalDescription?: string | null;
    informalDescription?: string | null;
    icon?: string | null;
    attributes: Array<string | null>;
    status?: string | null;
    createdAt?: string | null;
    updatedAt?: string | null;
  };
  description: string;
  overrideIcon?: string | null;
  overrideIconIdentityId?: string | null;
  enabled?: boolean | null;
  organizationAttributes?: Array<string | null> | null;
  disabledAttributes?: Array<number | null> | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type OnDeleteOrganizationShoutOutTypeSubscription = {
  __typename: "OrganizationShoutOutType";
  id: string;
  organizationID: string;
  overrideTitle: string;
  organizationShoutOutTypeShoutOuTypeId: string;
  shoutOutType: {
    __typename: "ShoutOutType";
    id: string;
    title: string;
    formalDescription?: string | null;
    informalDescription?: string | null;
    icon?: string | null;
    attributes: Array<string | null>;
    status?: string | null;
    createdAt?: string | null;
    updatedAt?: string | null;
  };
  description: string;
  overrideIcon?: string | null;
  overrideIconIdentityId?: string | null;
  enabled?: boolean | null;
  organizationAttributes?: Array<string | null> | null;
  disabledAttributes?: Array<number | null> | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type OnCreateStandupSurveyConfigurationSubscription = {
  __typename: "StandupSurveyConfiguration";
  id: string;
  organizationID: string;
  employeeID: string;
  questions: Array<{
    __typename: "VersionedQuestion";
    id: string;
    questionTextArray: Array<string | null>;
    enabled?: boolean | null;
  } | null>;
  shared?: Array<string | null> | null;
  excluded?: Array<string | null> | null;
  frequency: string;
  lastSurveyedDate?: string | null;
  isoWeekday?: number | null;
  status?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type OnUpdateStandupSurveyConfigurationSubscription = {
  __typename: "StandupSurveyConfiguration";
  id: string;
  organizationID: string;
  employeeID: string;
  questions: Array<{
    __typename: "VersionedQuestion";
    id: string;
    questionTextArray: Array<string | null>;
    enabled?: boolean | null;
  } | null>;
  shared?: Array<string | null> | null;
  excluded?: Array<string | null> | null;
  frequency: string;
  lastSurveyedDate?: string | null;
  isoWeekday?: number | null;
  status?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type OnDeleteStandupSurveyConfigurationSubscription = {
  __typename: "StandupSurveyConfiguration";
  id: string;
  organizationID: string;
  employeeID: string;
  questions: Array<{
    __typename: "VersionedQuestion";
    id: string;
    questionTextArray: Array<string | null>;
    enabled?: boolean | null;
  } | null>;
  shared?: Array<string | null> | null;
  excluded?: Array<string | null> | null;
  frequency: string;
  lastSurveyedDate?: string | null;
  isoWeekday?: number | null;
  status?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type OnCreateStandupSurveySubscription = {
  __typename: "StandupSurvey";
  id: string;
  organizationID: string;
  standupSurveyConfigurationID: string;
  questionMaps: Array<{
    __typename: "QuestionMap";
    id: string;
    version: number;
  } | null>;
  respondentEmail: string;
  respondentID: string;
  surveyDate: string;
  feedbackArray?: Array<string | null> | null;
  chatRequest?: boolean | null;
  meetingRequest?: boolean | null;
  responseReceived?: boolean | null;
  responseReceivedDate?: string | null;
  status?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type OnUpdateStandupSurveySubscription = {
  __typename: "StandupSurvey";
  id: string;
  organizationID: string;
  standupSurveyConfigurationID: string;
  questionMaps: Array<{
    __typename: "QuestionMap";
    id: string;
    version: number;
  } | null>;
  respondentEmail: string;
  respondentID: string;
  surveyDate: string;
  feedbackArray?: Array<string | null> | null;
  chatRequest?: boolean | null;
  meetingRequest?: boolean | null;
  responseReceived?: boolean | null;
  responseReceivedDate?: string | null;
  status?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type OnDeleteStandupSurveySubscription = {
  __typename: "StandupSurvey";
  id: string;
  organizationID: string;
  standupSurveyConfigurationID: string;
  questionMaps: Array<{
    __typename: "QuestionMap";
    id: string;
    version: number;
  } | null>;
  respondentEmail: string;
  respondentID: string;
  surveyDate: string;
  feedbackArray?: Array<string | null> | null;
  chatRequest?: boolean | null;
  meetingRequest?: boolean | null;
  responseReceived?: boolean | null;
  responseReceivedDate?: string | null;
  status?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type OnCreateEmployeeImageSubscription = {
  __typename: "EmployeeImage";
  employeeID: string;
  organizationID: string;
  groups?: Array<string | null> | null;
  image?: string | null;
  imageIdentityId?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type OnUpdateEmployeeImageSubscription = {
  __typename: "EmployeeImage";
  employeeID: string;
  organizationID: string;
  groups?: Array<string | null> | null;
  image?: string | null;
  imageIdentityId?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type OnDeleteEmployeeImageSubscription = {
  __typename: "EmployeeImage";
  employeeID: string;
  organizationID: string;
  groups?: Array<string | null> | null;
  image?: string | null;
  imageIdentityId?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type OnCreateExternalSurveySubscription = {
  __typename: "ExternalSurvey";
  id: string;
  surveyType: SurveyType;
  surveyDate?: string | null;
  externalRespondentID: string;
  participant: {
    __typename: "Participant";
    id: string;
    participantName: string;
  };
  objectiveDetails?: {
    __typename: "ObjectiveDetails";
    id: string;
    objectiveDisplay?: string | null;
    objectiveDefinition?: string | null;
    businessObjective?: string | null;
    managerDescription?: string | null;
    objectiveObservables?: Array<string | null> | null;
  } | null;
  status?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  respondentAttended?: boolean | null;
  surveyResponse?: number | null;
  feedback?: string | null;
  responseReceived?: boolean | null;
  responseReceivedDate?: string | null;
};

export type OnUpdateExternalSurveySubscription = {
  __typename: "ExternalSurvey";
  id: string;
  surveyType: SurveyType;
  surveyDate?: string | null;
  externalRespondentID: string;
  participant: {
    __typename: "Participant";
    id: string;
    participantName: string;
  };
  objectiveDetails?: {
    __typename: "ObjectiveDetails";
    id: string;
    objectiveDisplay?: string | null;
    objectiveDefinition?: string | null;
    businessObjective?: string | null;
    managerDescription?: string | null;
    objectiveObservables?: Array<string | null> | null;
  } | null;
  status?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  respondentAttended?: boolean | null;
  surveyResponse?: number | null;
  feedback?: string | null;
  responseReceived?: boolean | null;
  responseReceivedDate?: string | null;
};

export type OnDeleteExternalSurveySubscription = {
  __typename: "ExternalSurvey";
  id: string;
  surveyType: SurveyType;
  surveyDate?: string | null;
  externalRespondentID: string;
  participant: {
    __typename: "Participant";
    id: string;
    participantName: string;
  };
  objectiveDetails?: {
    __typename: "ObjectiveDetails";
    id: string;
    objectiveDisplay?: string | null;
    objectiveDefinition?: string | null;
    businessObjective?: string | null;
    managerDescription?: string | null;
    objectiveObservables?: Array<string | null> | null;
  } | null;
  status?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  respondentAttended?: boolean | null;
  surveyResponse?: number | null;
  feedback?: string | null;
  responseReceived?: boolean | null;
  responseReceivedDate?: string | null;
};

@Injectable({
  providedIn: "root"
})
export class APIService {
  async CreateOrganization(
    input: CreateOrganizationInput,
    condition?: ModelOrganizationConditionInput
  ): Promise<CreateOrganizationMutation> {
    const statement = `mutation CreateOrganization($input: CreateOrganizationInput!, $condition: ModelOrganizationConditionInput) {
        createOrganization(input: $input, condition: $condition) {
          __typename
          id
          orgName
          domains
          site
          disabled
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateOrganizationMutation>response.data.createOrganization;
  }
  async UpdateOrganization(
    input: UpdateOrganizationInput,
    condition?: ModelOrganizationConditionInput
  ): Promise<UpdateOrganizationMutation> {
    const statement = `mutation UpdateOrganization($input: UpdateOrganizationInput!, $condition: ModelOrganizationConditionInput) {
        updateOrganization(input: $input, condition: $condition) {
          __typename
          id
          orgName
          domains
          site
          disabled
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateOrganizationMutation>response.data.updateOrganization;
  }
  async DeleteOrganization(
    input: DeleteOrganizationInput,
    condition?: ModelOrganizationConditionInput
  ): Promise<DeleteOrganizationMutation> {
    const statement = `mutation DeleteOrganization($input: DeleteOrganizationInput!, $condition: ModelOrganizationConditionInput) {
        deleteOrganization(input: $input, condition: $condition) {
          __typename
          id
          orgName
          domains
          site
          disabled
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteOrganizationMutation>response.data.deleteOrganization;
  }
  async CreateStripe(
    input: CreateStripeInput,
    condition?: ModelStripeConditionInput
  ): Promise<CreateStripeMutation> {
    const statement = `mutation CreateStripe($input: CreateStripeInput!, $condition: ModelStripeConditionInput) {
        createStripe(input: $input, condition: $condition) {
          __typename
          organizationID
          customerID
          subscriptionID
          subscriptionItemID
          planID
          billingName
          billingEmail
          cardToken
          subscriptionStatus
          hmac
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateStripeMutation>response.data.createStripe;
  }
  async UpdateStripe(
    input: UpdateStripeInput,
    condition?: ModelStripeConditionInput
  ): Promise<UpdateStripeMutation> {
    const statement = `mutation UpdateStripe($input: UpdateStripeInput!, $condition: ModelStripeConditionInput) {
        updateStripe(input: $input, condition: $condition) {
          __typename
          organizationID
          customerID
          subscriptionID
          subscriptionItemID
          planID
          billingName
          billingEmail
          cardToken
          subscriptionStatus
          hmac
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateStripeMutation>response.data.updateStripe;
  }
  async DeleteStripe(
    input: DeleteStripeInput,
    condition?: ModelStripeConditionInput
  ): Promise<DeleteStripeMutation> {
    const statement = `mutation DeleteStripe($input: DeleteStripeInput!, $condition: ModelStripeConditionInput) {
        deleteStripe(input: $input, condition: $condition) {
          __typename
          organizationID
          customerID
          subscriptionID
          subscriptionItemID
          planID
          billingName
          billingEmail
          cardToken
          subscriptionStatus
          hmac
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteStripeMutation>response.data.deleteStripe;
  }
  async CreateProcessLog(
    input: CreateProcessLogInput,
    condition?: ModelProcessLogConditionInput
  ): Promise<CreateProcessLogMutation> {
    const statement = `mutation CreateProcessLog($input: CreateProcessLogInput!, $condition: ModelProcessLogConditionInput) {
        createProcessLog(input: $input, condition: $condition) {
          __typename
          process
          lastRunTime
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateProcessLogMutation>response.data.createProcessLog;
  }
  async UpdateProcessLog(
    input: UpdateProcessLogInput,
    condition?: ModelProcessLogConditionInput
  ): Promise<UpdateProcessLogMutation> {
    const statement = `mutation UpdateProcessLog($input: UpdateProcessLogInput!, $condition: ModelProcessLogConditionInput) {
        updateProcessLog(input: $input, condition: $condition) {
          __typename
          process
          lastRunTime
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateProcessLogMutation>response.data.updateProcessLog;
  }
  async DeleteProcessLog(
    input: DeleteProcessLogInput,
    condition?: ModelProcessLogConditionInput
  ): Promise<DeleteProcessLogMutation> {
    const statement = `mutation DeleteProcessLog($input: DeleteProcessLogInput!, $condition: ModelProcessLogConditionInput) {
        deleteProcessLog(input: $input, condition: $condition) {
          __typename
          process
          lastRunTime
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteProcessLogMutation>response.data.deleteProcessLog;
  }
  async CreateOrganizationConfiguration(
    input: CreateOrganizationConfigurationInput,
    condition?: ModelOrganizationConfigurationConditionInput
  ): Promise<CreateOrganizationConfigurationMutation> {
    const statement = `mutation CreateOrganizationConfiguration($input: CreateOrganizationConfigurationInput!, $condition: ModelOrganizationConfigurationConditionInput) {
        createOrganizationConfiguration(input: $input, condition: $condition) {
          __typename
          id
          organizationID
          configCode
          configStringValue
          configIntValue
          configBooleanValue
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateOrganizationConfigurationMutation>(
      response.data.createOrganizationConfiguration
    );
  }
  async UpdateOrganizationConfiguration(
    input: UpdateOrganizationConfigurationInput,
    condition?: ModelOrganizationConfigurationConditionInput
  ): Promise<UpdateOrganizationConfigurationMutation> {
    const statement = `mutation UpdateOrganizationConfiguration($input: UpdateOrganizationConfigurationInput!, $condition: ModelOrganizationConfigurationConditionInput) {
        updateOrganizationConfiguration(input: $input, condition: $condition) {
          __typename
          id
          organizationID
          configCode
          configStringValue
          configIntValue
          configBooleanValue
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateOrganizationConfigurationMutation>(
      response.data.updateOrganizationConfiguration
    );
  }
  async DeleteOrganizationConfiguration(
    input: DeleteOrganizationConfigurationInput,
    condition?: ModelOrganizationConfigurationConditionInput
  ): Promise<DeleteOrganizationConfigurationMutation> {
    const statement = `mutation DeleteOrganizationConfiguration($input: DeleteOrganizationConfigurationInput!, $condition: ModelOrganizationConfigurationConditionInput) {
        deleteOrganizationConfiguration(input: $input, condition: $condition) {
          __typename
          id
          organizationID
          configCode
          configStringValue
          configIntValue
          configBooleanValue
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteOrganizationConfigurationMutation>(
      response.data.deleteOrganizationConfiguration
    );
  }
  async CreateEmployee(
    input: CreateEmployeeInput,
    condition?: ModelEmployeeConditionInput
  ): Promise<CreateEmployeeMutation> {
    const statement = `mutation CreateEmployee($input: CreateEmployeeInput!, $condition: ModelEmployeeConditionInput) {
        createEmployee(input: $input, condition: $condition) {
          __typename
          id
          authId
          email
          orgId
          groups
          consulting
          managerID
          subordinates
          roles
          firstName
          lastName
          title
          department
          disabled
          disabledDate
          disabledBy
          hireDate
          managerChangeDate
          lastAnnual
          lastOneOnOne
          positionDate
          previousPositionStartDate
          previousPositionTitle
          validEmailToken
          inviteSent
          readiness
          criticalPosition
          createdAt
          updatedAt
          readinessAssessmentDate
          image
          imageIdentityId
          bio
          careerGoals
          relocate
          relocateDate
          workStatus
          workStatusDate
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateEmployeeMutation>response.data.createEmployee;
  }
  async UpdateEmployee(
    input: UpdateEmployeeInput,
    condition?: ModelEmployeeConditionInput
  ): Promise<UpdateEmployeeMutation> {
    const statement = `mutation UpdateEmployee($input: UpdateEmployeeInput!, $condition: ModelEmployeeConditionInput) {
        updateEmployee(input: $input, condition: $condition) {
          __typename
          id
          authId
          email
          orgId
          groups
          consulting
          managerID
          subordinates
          roles
          firstName
          lastName
          title
          department
          disabled
          disabledDate
          disabledBy
          hireDate
          managerChangeDate
          lastAnnual
          lastOneOnOne
          positionDate
          previousPositionStartDate
          previousPositionTitle
          validEmailToken
          inviteSent
          readiness
          criticalPosition
          createdAt
          updatedAt
          readinessAssessmentDate
          image
          imageIdentityId
          bio
          careerGoals
          relocate
          relocateDate
          workStatus
          workStatusDate
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateEmployeeMutation>response.data.updateEmployee;
  }
  async DeleteEmployee(
    input: DeleteEmployeeInput,
    condition?: ModelEmployeeConditionInput
  ): Promise<DeleteEmployeeMutation> {
    const statement = `mutation DeleteEmployee($input: DeleteEmployeeInput!, $condition: ModelEmployeeConditionInput) {
        deleteEmployee(input: $input, condition: $condition) {
          __typename
          id
          authId
          email
          orgId
          groups
          consulting
          managerID
          subordinates
          roles
          firstName
          lastName
          title
          department
          disabled
          disabledDate
          disabledBy
          hireDate
          managerChangeDate
          lastAnnual
          lastOneOnOne
          positionDate
          previousPositionStartDate
          previousPositionTitle
          validEmailToken
          inviteSent
          readiness
          criticalPosition
          createdAt
          updatedAt
          readinessAssessmentDate
          image
          imageIdentityId
          bio
          careerGoals
          relocate
          relocateDate
          workStatus
          workStatusDate
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteEmployeeMutation>response.data.deleteEmployee;
  }
  async CreateEmployeeImage(
    input: CreateEmployeeImageInput,
    condition?: ModelEmployeeImageConditionInput
  ): Promise<CreateEmployeeImageMutation> {
    const statement = `mutation CreateEmployeeImage($input: CreateEmployeeImageInput!, $condition: ModelEmployeeImageConditionInput) {
        createEmployeeImage(input: $input, condition: $condition) {
          __typename
          employeeID
          organizationID
          groups
          image
          imageIdentityId
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateEmployeeImageMutation>response.data.createEmployeeImage;
  }
  async UpdateEmployeeImage(
    input: UpdateEmployeeImageInput,
    condition?: ModelEmployeeImageConditionInput
  ): Promise<UpdateEmployeeImageMutation> {
    const statement = `mutation UpdateEmployeeImage($input: UpdateEmployeeImageInput!, $condition: ModelEmployeeImageConditionInput) {
        updateEmployeeImage(input: $input, condition: $condition) {
          __typename
          employeeID
          organizationID
          groups
          image
          imageIdentityId
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateEmployeeImageMutation>response.data.updateEmployeeImage;
  }
  async DeleteEmployeeImage(
    input: DeleteEmployeeImageInput,
    condition?: ModelEmployeeImageConditionInput
  ): Promise<DeleteEmployeeImageMutation> {
    const statement = `mutation DeleteEmployeeImage($input: DeleteEmployeeImageInput!, $condition: ModelEmployeeImageConditionInput) {
        deleteEmployeeImage(input: $input, condition: $condition) {
          __typename
          employeeID
          organizationID
          groups
          image
          imageIdentityId
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteEmployeeImageMutation>response.data.deleteEmployeeImage;
  }
  async CreateEmployeeData(
    input: CreateEmployeeDataInput,
    condition?: ModelEmployeeDataConditionInput
  ): Promise<CreateEmployeeDataMutation> {
    const statement = `mutation CreateEmployeeData($input: CreateEmployeeDataInput!, $condition: ModelEmployeeDataConditionInput) {
        createEmployeeData(input: $input, condition: $condition) {
          __typename
          employeeID
          organizationID
          dataCode
          groups
          stringValue
          intValue
          booleanValue
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateEmployeeDataMutation>response.data.createEmployeeData;
  }
  async UpdateEmployeeData(
    input: UpdateEmployeeDataInput,
    condition?: ModelEmployeeDataConditionInput
  ): Promise<UpdateEmployeeDataMutation> {
    const statement = `mutation UpdateEmployeeData($input: UpdateEmployeeDataInput!, $condition: ModelEmployeeDataConditionInput) {
        updateEmployeeData(input: $input, condition: $condition) {
          __typename
          employeeID
          organizationID
          dataCode
          groups
          stringValue
          intValue
          booleanValue
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateEmployeeDataMutation>response.data.updateEmployeeData;
  }
  async DeleteEmployeeData(
    input: DeleteEmployeeDataInput,
    condition?: ModelEmployeeDataConditionInput
  ): Promise<DeleteEmployeeDataMutation> {
    const statement = `mutation DeleteEmployeeData($input: DeleteEmployeeDataInput!, $condition: ModelEmployeeDataConditionInput) {
        deleteEmployeeData(input: $input, condition: $condition) {
          __typename
          employeeID
          organizationID
          dataCode
          groups
          stringValue
          intValue
          booleanValue
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteEmployeeDataMutation>response.data.deleteEmployeeData;
  }
  async CreateReadinessHistory(
    input: CreateReadinessHistoryInput,
    condition?: ModelReadinessHistoryConditionInput
  ): Promise<CreateReadinessHistoryMutation> {
    const statement = `mutation CreateReadinessHistory($input: CreateReadinessHistoryInput!, $condition: ModelReadinessHistoryConditionInput) {
        createReadinessHistory(input: $input, condition: $condition) {
          __typename
          id
          organizationID
          groups
          employeeID
          readiness
          comments
          opportunities
          createdAt
          createdBy
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateReadinessHistoryMutation>response.data.createReadinessHistory;
  }
  async UpdateReadinessHistory(
    input: UpdateReadinessHistoryInput,
    condition?: ModelReadinessHistoryConditionInput
  ): Promise<UpdateReadinessHistoryMutation> {
    const statement = `mutation UpdateReadinessHistory($input: UpdateReadinessHistoryInput!, $condition: ModelReadinessHistoryConditionInput) {
        updateReadinessHistory(input: $input, condition: $condition) {
          __typename
          id
          organizationID
          groups
          employeeID
          readiness
          comments
          opportunities
          createdAt
          createdBy
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateReadinessHistoryMutation>response.data.updateReadinessHistory;
  }
  async DeleteReadinessHistory(
    input: DeleteReadinessHistoryInput,
    condition?: ModelReadinessHistoryConditionInput
  ): Promise<DeleteReadinessHistoryMutation> {
    const statement = `mutation DeleteReadinessHistory($input: DeleteReadinessHistoryInput!, $condition: ModelReadinessHistoryConditionInput) {
        deleteReadinessHistory(input: $input, condition: $condition) {
          __typename
          id
          organizationID
          groups
          employeeID
          readiness
          comments
          opportunities
          createdAt
          createdBy
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteReadinessHistoryMutation>response.data.deleteReadinessHistory;
  }
  async CreateConsultantAssociation(
    input: CreateConsultantAssociationInput,
    condition?: ModelConsultantAssociationConditionInput
  ): Promise<CreateConsultantAssociationMutation> {
    const statement = `mutation CreateConsultantAssociation($input: CreateConsultantAssociationInput!, $condition: ModelConsultantAssociationConditionInput) {
        createConsultantAssociation(input: $input, condition: $condition) {
          __typename
          employeeID
          organizationID
          incentive
          status
          options
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateConsultantAssociationMutation>(
      response.data.createConsultantAssociation
    );
  }
  async UpdateConsultantAssociation(
    input: UpdateConsultantAssociationInput,
    condition?: ModelConsultantAssociationConditionInput
  ): Promise<UpdateConsultantAssociationMutation> {
    const statement = `mutation UpdateConsultantAssociation($input: UpdateConsultantAssociationInput!, $condition: ModelConsultantAssociationConditionInput) {
        updateConsultantAssociation(input: $input, condition: $condition) {
          __typename
          employeeID
          organizationID
          incentive
          status
          options
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateConsultantAssociationMutation>(
      response.data.updateConsultantAssociation
    );
  }
  async DeleteConsultantAssociation(
    input: DeleteConsultantAssociationInput,
    condition?: ModelConsultantAssociationConditionInput
  ): Promise<DeleteConsultantAssociationMutation> {
    const statement = `mutation DeleteConsultantAssociation($input: DeleteConsultantAssociationInput!, $condition: ModelConsultantAssociationConditionInput) {
        deleteConsultantAssociation(input: $input, condition: $condition) {
          __typename
          employeeID
          organizationID
          incentive
          status
          options
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteConsultantAssociationMutation>(
      response.data.deleteConsultantAssociation
    );
  }
  async CreateConsultantFavorites(
    input: CreateConsultantFavoritesInput,
    condition?: ModelConsultantFavoritesConditionInput
  ): Promise<CreateConsultantFavoritesMutation> {
    const statement = `mutation CreateConsultantFavorites($input: CreateConsultantFavoritesInput!, $condition: ModelConsultantFavoritesConditionInput) {
        createConsultantFavorites(input: $input, condition: $condition) {
          __typename
          employeeID
          organizationID
          favorites
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateConsultantFavoritesMutation>(
      response.data.createConsultantFavorites
    );
  }
  async UpdateConsultantFavorites(
    input: UpdateConsultantFavoritesInput,
    condition?: ModelConsultantFavoritesConditionInput
  ): Promise<UpdateConsultantFavoritesMutation> {
    const statement = `mutation UpdateConsultantFavorites($input: UpdateConsultantFavoritesInput!, $condition: ModelConsultantFavoritesConditionInput) {
        updateConsultantFavorites(input: $input, condition: $condition) {
          __typename
          employeeID
          organizationID
          favorites
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateConsultantFavoritesMutation>(
      response.data.updateConsultantFavorites
    );
  }
  async DeleteConsultantFavorites(
    input: DeleteConsultantFavoritesInput,
    condition?: ModelConsultantFavoritesConditionInput
  ): Promise<DeleteConsultantFavoritesMutation> {
    const statement = `mutation DeleteConsultantFavorites($input: DeleteConsultantFavoritesInput!, $condition: ModelConsultantFavoritesConditionInput) {
        deleteConsultantFavorites(input: $input, condition: $condition) {
          __typename
          employeeID
          organizationID
          favorites
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteConsultantFavoritesMutation>(
      response.data.deleteConsultantFavorites
    );
  }
  async CreateUserAction(
    input: CreateUserActionInput,
    condition?: ModelUserActionConditionInput
  ): Promise<CreateUserActionMutation> {
    const statement = `mutation CreateUserAction($input: CreateUserActionInput!, $condition: ModelUserActionConditionInput) {
        createUserAction(input: $input, condition: $condition) {
          __typename
          employeeID
          organizationID
          action
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateUserActionMutation>response.data.createUserAction;
  }
  async UpdateUserAction(
    input: UpdateUserActionInput,
    condition?: ModelUserActionConditionInput
  ): Promise<UpdateUserActionMutation> {
    const statement = `mutation UpdateUserAction($input: UpdateUserActionInput!, $condition: ModelUserActionConditionInput) {
        updateUserAction(input: $input, condition: $condition) {
          __typename
          employeeID
          organizationID
          action
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateUserActionMutation>response.data.updateUserAction;
  }
  async DeleteUserAction(
    input: DeleteUserActionInput,
    condition?: ModelUserActionConditionInput
  ): Promise<DeleteUserActionMutation> {
    const statement = `mutation DeleteUserAction($input: DeleteUserActionInput!, $condition: ModelUserActionConditionInput) {
        deleteUserAction(input: $input, condition: $condition) {
          __typename
          employeeID
          organizationID
          action
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteUserActionMutation>response.data.deleteUserAction;
  }
  async CreateEmployeeCalendarCode(
    input: CreateEmployeeCalendarCodeInput,
    condition?: ModelEmployeeCalendarCodeConditionInput
  ): Promise<CreateEmployeeCalendarCodeMutation> {
    const statement = `mutation CreateEmployeeCalendarCode($input: CreateEmployeeCalendarCodeInput!, $condition: ModelEmployeeCalendarCodeConditionInput) {
        createEmployeeCalendarCode(input: $input, condition: $condition) {
          __typename
          employeeID
          code
          redirectUrl
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateEmployeeCalendarCodeMutation>(
      response.data.createEmployeeCalendarCode
    );
  }
  async UpdateEmployeeCalendarCode(
    input: UpdateEmployeeCalendarCodeInput,
    condition?: ModelEmployeeCalendarCodeConditionInput
  ): Promise<UpdateEmployeeCalendarCodeMutation> {
    const statement = `mutation UpdateEmployeeCalendarCode($input: UpdateEmployeeCalendarCodeInput!, $condition: ModelEmployeeCalendarCodeConditionInput) {
        updateEmployeeCalendarCode(input: $input, condition: $condition) {
          __typename
          employeeID
          code
          redirectUrl
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateEmployeeCalendarCodeMutation>(
      response.data.updateEmployeeCalendarCode
    );
  }
  async DeleteEmployeeCalendarCode(
    input: DeleteEmployeeCalendarCodeInput,
    condition?: ModelEmployeeCalendarCodeConditionInput
  ): Promise<DeleteEmployeeCalendarCodeMutation> {
    const statement = `mutation DeleteEmployeeCalendarCode($input: DeleteEmployeeCalendarCodeInput!, $condition: ModelEmployeeCalendarCodeConditionInput) {
        deleteEmployeeCalendarCode(input: $input, condition: $condition) {
          __typename
          employeeID
          code
          redirectUrl
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteEmployeeCalendarCodeMutation>(
      response.data.deleteEmployeeCalendarCode
    );
  }
  async CreateEmployeeScore(
    input: CreateEmployeeScoreInput,
    condition?: ModelEmployeeScoreConditionInput
  ): Promise<CreateEmployeeScoreMutation> {
    const statement = `mutation CreateEmployeeScore($input: CreateEmployeeScoreInput!, $condition: ModelEmployeeScoreConditionInput) {
        createEmployeeScore(input: $input, condition: $condition) {
          __typename
          id
          employeeID
          organizationID
          score
          scoreType
          scoreID
          scoreStart
          scoreEnd
          specifier
          details
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateEmployeeScoreMutation>response.data.createEmployeeScore;
  }
  async UpdateEmployeeScore(
    input: UpdateEmployeeScoreInput,
    condition?: ModelEmployeeScoreConditionInput
  ): Promise<UpdateEmployeeScoreMutation> {
    const statement = `mutation UpdateEmployeeScore($input: UpdateEmployeeScoreInput!, $condition: ModelEmployeeScoreConditionInput) {
        updateEmployeeScore(input: $input, condition: $condition) {
          __typename
          id
          employeeID
          organizationID
          score
          scoreType
          scoreID
          scoreStart
          scoreEnd
          specifier
          details
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateEmployeeScoreMutation>response.data.updateEmployeeScore;
  }
  async DeleteEmployeeScore(
    input: DeleteEmployeeScoreInput,
    condition?: ModelEmployeeScoreConditionInput
  ): Promise<DeleteEmployeeScoreMutation> {
    const statement = `mutation DeleteEmployeeScore($input: DeleteEmployeeScoreInput!, $condition: ModelEmployeeScoreConditionInput) {
        deleteEmployeeScore(input: $input, condition: $condition) {
          __typename
          id
          employeeID
          organizationID
          score
          scoreType
          scoreID
          scoreStart
          scoreEnd
          specifier
          details
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteEmployeeScoreMutation>response.data.deleteEmployeeScore;
  }
  async CreateInspHRationsResponseCounter(
    input: CreateInspHRationsResponseCounterInput,
    condition?: ModelInspHRationsResponseCounterConditionInput
  ): Promise<CreateInspHRationsResponseCounterMutation> {
    const statement = `mutation CreateInspHRationsResponseCounter($input: CreateInspHRationsResponseCounterInput!, $condition: ModelInspHRationsResponseCounterConditionInput) {
        createInspHRationsResponseCounter(input: $input, condition: $condition) {
          __typename
          organizationID
          employeeID
          organizationShoutOutTypeID
          type
          group
          count
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateInspHRationsResponseCounterMutation>(
      response.data.createInspHRationsResponseCounter
    );
  }
  async UpdateInspHRationsResponseCounter(
    input: UpdateInspHRationsResponseCounterInput,
    condition?: ModelInspHRationsResponseCounterConditionInput
  ): Promise<UpdateInspHRationsResponseCounterMutation> {
    const statement = `mutation UpdateInspHRationsResponseCounter($input: UpdateInspHRationsResponseCounterInput!, $condition: ModelInspHRationsResponseCounterConditionInput) {
        updateInspHRationsResponseCounter(input: $input, condition: $condition) {
          __typename
          organizationID
          employeeID
          organizationShoutOutTypeID
          type
          group
          count
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateInspHRationsResponseCounterMutation>(
      response.data.updateInspHRationsResponseCounter
    );
  }
  async DeleteInspHRationsResponseCounter(
    input: DeleteInspHRationsResponseCounterInput,
    condition?: ModelInspHRationsResponseCounterConditionInput
  ): Promise<DeleteInspHRationsResponseCounterMutation> {
    const statement = `mutation DeleteInspHRationsResponseCounter($input: DeleteInspHRationsResponseCounterInput!, $condition: ModelInspHRationsResponseCounterConditionInput) {
        deleteInspHRationsResponseCounter(input: $input, condition: $condition) {
          __typename
          organizationID
          employeeID
          organizationShoutOutTypeID
          type
          group
          count
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteInspHRationsResponseCounterMutation>(
      response.data.deleteInspHRationsResponseCounter
    );
  }
  async CreateInspHRationsOrganizationResponseCounter(
    input: CreateInspHRationsOrganizationResponseCounterInput,
    condition?: ModelInspHRationsOrganizationResponseCounterConditionInput
  ): Promise<CreateInspHRationsOrganizationResponseCounterMutation> {
    const statement = `mutation CreateInspHRationsOrganizationResponseCounter($input: CreateInspHRationsOrganizationResponseCounterInput!, $condition: ModelInspHRationsOrganizationResponseCounterConditionInput) {
        createInspHRationsOrganizationResponseCounter(
          input: $input
          condition: $condition
        ) {
          __typename
          organizationID
          employeeID
          organizationShoutOutTypeID
          type
          group
          count
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateInspHRationsOrganizationResponseCounterMutation>(
      response.data.createInspHRationsOrganizationResponseCounter
    );
  }
  async UpdateInspHRationsOrganizationResponseCounter(
    input: UpdateInspHRationsOrganizationResponseCounterInput,
    condition?: ModelInspHRationsOrganizationResponseCounterConditionInput
  ): Promise<UpdateInspHRationsOrganizationResponseCounterMutation> {
    const statement = `mutation UpdateInspHRationsOrganizationResponseCounter($input: UpdateInspHRationsOrganizationResponseCounterInput!, $condition: ModelInspHRationsOrganizationResponseCounterConditionInput) {
        updateInspHRationsOrganizationResponseCounter(
          input: $input
          condition: $condition
        ) {
          __typename
          organizationID
          employeeID
          organizationShoutOutTypeID
          type
          group
          count
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateInspHRationsOrganizationResponseCounterMutation>(
      response.data.updateInspHRationsOrganizationResponseCounter
    );
  }
  async DeleteInspHRationsOrganizationResponseCounter(
    input: DeleteInspHRationsOrganizationResponseCounterInput,
    condition?: ModelInspHRationsOrganizationResponseCounterConditionInput
  ): Promise<DeleteInspHRationsOrganizationResponseCounterMutation> {
    const statement = `mutation DeleteInspHRationsOrganizationResponseCounter($input: DeleteInspHRationsOrganizationResponseCounterInput!, $condition: ModelInspHRationsOrganizationResponseCounterConditionInput) {
        deleteInspHRationsOrganizationResponseCounter(
          input: $input
          condition: $condition
        ) {
          __typename
          organizationID
          employeeID
          organizationShoutOutTypeID
          type
          group
          count
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteInspHRationsOrganizationResponseCounterMutation>(
      response.data.deleteInspHRationsOrganizationResponseCounter
    );
  }
  async CreateIntHRactionCounter(
    input: CreateIntHRactionCounterInput,
    condition?: ModelIntHRactionCounterConditionInput
  ): Promise<CreateIntHRactionCounterMutation> {
    const statement = `mutation CreateIntHRactionCounter($input: CreateIntHRactionCounterInput!, $condition: ModelIntHRactionCounterConditionInput) {
        createIntHRactionCounter(input: $input, condition: $condition) {
          __typename
          employeeID
          participantID
          organizationID
          count
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateIntHRactionCounterMutation>(
      response.data.createIntHRactionCounter
    );
  }
  async UpdateIntHRactionCounter(
    input: UpdateIntHRactionCounterInput,
    condition?: ModelIntHRactionCounterConditionInput
  ): Promise<UpdateIntHRactionCounterMutation> {
    const statement = `mutation UpdateIntHRactionCounter($input: UpdateIntHRactionCounterInput!, $condition: ModelIntHRactionCounterConditionInput) {
        updateIntHRactionCounter(input: $input, condition: $condition) {
          __typename
          employeeID
          participantID
          organizationID
          count
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateIntHRactionCounterMutation>(
      response.data.updateIntHRactionCounter
    );
  }
  async DeleteIntHRactionCounter(
    input: DeleteIntHRactionCounterInput,
    condition?: ModelIntHRactionCounterConditionInput
  ): Promise<DeleteIntHRactionCounterMutation> {
    const statement = `mutation DeleteIntHRactionCounter($input: DeleteIntHRactionCounterInput!, $condition: ModelIntHRactionCounterConditionInput) {
        deleteIntHRactionCounter(input: $input, condition: $condition) {
          __typename
          employeeID
          participantID
          organizationID
          count
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteIntHRactionCounterMutation>(
      response.data.deleteIntHRactionCounter
    );
  }
  async CreatePriority(
    input: CreatePriorityInput,
    condition?: ModelPriorityConditionInput
  ): Promise<CreatePriorityMutation> {
    const statement = `mutation CreatePriority($input: CreatePriorityInput!, $condition: ModelPriorityConditionInput) {
        createPriority(input: $input, condition: $condition) {
          __typename
          id
          key
          display
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreatePriorityMutation>response.data.createPriority;
  }
  async UpdatePriority(
    input: UpdatePriorityInput,
    condition?: ModelPriorityConditionInput
  ): Promise<UpdatePriorityMutation> {
    const statement = `mutation UpdatePriority($input: UpdatePriorityInput!, $condition: ModelPriorityConditionInput) {
        updatePriority(input: $input, condition: $condition) {
          __typename
          id
          key
          display
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdatePriorityMutation>response.data.updatePriority;
  }
  async DeletePriority(
    input: DeletePriorityInput,
    condition?: ModelPriorityConditionInput
  ): Promise<DeletePriorityMutation> {
    const statement = `mutation DeletePriority($input: DeletePriorityInput!, $condition: ModelPriorityConditionInput) {
        deletePriority(input: $input, condition: $condition) {
          __typename
          id
          key
          display
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeletePriorityMutation>response.data.deletePriority;
  }
  async CreateFocus(
    input: CreateFocusInput,
    condition?: ModelFocusConditionInput
  ): Promise<CreateFocusMutation> {
    const statement = `mutation CreateFocus($input: CreateFocusInput!, $condition: ModelFocusConditionInput) {
        createFocus(input: $input, condition: $condition) {
          __typename
          id
          key
          display
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateFocusMutation>response.data.createFocus;
  }
  async UpdateFocus(
    input: UpdateFocusInput,
    condition?: ModelFocusConditionInput
  ): Promise<UpdateFocusMutation> {
    const statement = `mutation UpdateFocus($input: UpdateFocusInput!, $condition: ModelFocusConditionInput) {
        updateFocus(input: $input, condition: $condition) {
          __typename
          id
          key
          display
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateFocusMutation>response.data.updateFocus;
  }
  async DeleteFocus(
    input: DeleteFocusInput,
    condition?: ModelFocusConditionInput
  ): Promise<DeleteFocusMutation> {
    const statement = `mutation DeleteFocus($input: DeleteFocusInput!, $condition: ModelFocusConditionInput) {
        deleteFocus(input: $input, condition: $condition) {
          __typename
          id
          key
          display
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteFocusMutation>response.data.deleteFocus;
  }
  async CreateObjectiveDomain(
    input: CreateObjectiveDomainInput,
    condition?: ModelObjectiveDomainConditionInput
  ): Promise<CreateObjectiveDomainMutation> {
    const statement = `mutation CreateObjectiveDomain($input: CreateObjectiveDomainInput!, $condition: ModelObjectiveDomainConditionInput) {
        createObjectiveDomain(input: $input, condition: $condition) {
          __typename
          id
          key
          display
          excludeFromTPS
          sites
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateObjectiveDomainMutation>response.data.createObjectiveDomain;
  }
  async UpdateObjectiveDomain(
    input: UpdateObjectiveDomainInput,
    condition?: ModelObjectiveDomainConditionInput
  ): Promise<UpdateObjectiveDomainMutation> {
    const statement = `mutation UpdateObjectiveDomain($input: UpdateObjectiveDomainInput!, $condition: ModelObjectiveDomainConditionInput) {
        updateObjectiveDomain(input: $input, condition: $condition) {
          __typename
          id
          key
          display
          excludeFromTPS
          sites
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateObjectiveDomainMutation>response.data.updateObjectiveDomain;
  }
  async DeleteObjectiveDomain(
    input: DeleteObjectiveDomainInput,
    condition?: ModelObjectiveDomainConditionInput
  ): Promise<DeleteObjectiveDomainMutation> {
    const statement = `mutation DeleteObjectiveDomain($input: DeleteObjectiveDomainInput!, $condition: ModelObjectiveDomainConditionInput) {
        deleteObjectiveDomain(input: $input, condition: $condition) {
          __typename
          id
          key
          display
          excludeFromTPS
          sites
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteObjectiveDomainMutation>response.data.deleteObjectiveDomain;
  }
  async CreateObjective(
    input: CreateObjectiveInput,
    condition?: ModelObjectiveConditionInput
  ): Promise<CreateObjectiveMutation> {
    const statement = `mutation CreateObjective($input: CreateObjectiveInput!, $condition: ModelObjectiveConditionInput) {
        createObjective(input: $input, condition: $condition) {
          __typename
          id
          display
          definition
          observables
          hints
          organizationID
          organizations
          status
          domain {
            __typename
            id
            key
            display
            excludeFromTPS
            sites
            createdAt
            updatedAt
          }
          objectiveDomainId
          qrCodes {
            __typename
            type
            imageSrc
            title
            label
            qrdata
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateObjectiveMutation>response.data.createObjective;
  }
  async UpdateObjective(
    input: UpdateObjectiveInput,
    condition?: ModelObjectiveConditionInput
  ): Promise<UpdateObjectiveMutation> {
    const statement = `mutation UpdateObjective($input: UpdateObjectiveInput!, $condition: ModelObjectiveConditionInput) {
        updateObjective(input: $input, condition: $condition) {
          __typename
          id
          display
          definition
          observables
          hints
          organizationID
          organizations
          status
          domain {
            __typename
            id
            key
            display
            excludeFromTPS
            sites
            createdAt
            updatedAt
          }
          objectiveDomainId
          qrCodes {
            __typename
            type
            imageSrc
            title
            label
            qrdata
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateObjectiveMutation>response.data.updateObjective;
  }
  async DeleteObjective(
    input: DeleteObjectiveInput,
    condition?: ModelObjectiveConditionInput
  ): Promise<DeleteObjectiveMutation> {
    const statement = `mutation DeleteObjective($input: DeleteObjectiveInput!, $condition: ModelObjectiveConditionInput) {
        deleteObjective(input: $input, condition: $condition) {
          __typename
          id
          display
          definition
          observables
          hints
          organizationID
          organizations
          status
          domain {
            __typename
            id
            key
            display
            excludeFromTPS
            sites
            createdAt
            updatedAt
          }
          objectiveDomainId
          qrCodes {
            __typename
            type
            imageSrc
            title
            label
            qrdata
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteObjectiveMutation>response.data.deleteObjective;
  }
  async CreateOrganizationObjective(
    input: CreateOrganizationObjectiveInput,
    condition?: ModelOrganizationObjectiveConditionInput
  ): Promise<CreateOrganizationObjectiveMutation> {
    const statement = `mutation CreateOrganizationObjective($input: CreateOrganizationObjectiveInput!, $condition: ModelOrganizationObjectiveConditionInput) {
        createOrganizationObjective(input: $input, condition: $condition) {
          __typename
          id
          organizationID
          objective {
            __typename
            id
            display
            definition
            observables
            hints
            organizationID
            organizations
            status
            domain {
              __typename
              id
              key
              display
              excludeFromTPS
              sites
              createdAt
              updatedAt
            }
            objectiveDomainId
            qrCodes {
              __typename
              type
              imageSrc
              title
              label
              qrdata
            }
            createdAt
            updatedAt
          }
          organizationObjectiveObjectiveId
          enabled
          businessObjective
          status
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateOrganizationObjectiveMutation>(
      response.data.createOrganizationObjective
    );
  }
  async UpdateOrganizationObjective(
    input: UpdateOrganizationObjectiveInput,
    condition?: ModelOrganizationObjectiveConditionInput
  ): Promise<UpdateOrganizationObjectiveMutation> {
    const statement = `mutation UpdateOrganizationObjective($input: UpdateOrganizationObjectiveInput!, $condition: ModelOrganizationObjectiveConditionInput) {
        updateOrganizationObjective(input: $input, condition: $condition) {
          __typename
          id
          organizationID
          objective {
            __typename
            id
            display
            definition
            observables
            hints
            organizationID
            organizations
            status
            domain {
              __typename
              id
              key
              display
              excludeFromTPS
              sites
              createdAt
              updatedAt
            }
            objectiveDomainId
            qrCodes {
              __typename
              type
              imageSrc
              title
              label
              qrdata
            }
            createdAt
            updatedAt
          }
          organizationObjectiveObjectiveId
          enabled
          businessObjective
          status
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateOrganizationObjectiveMutation>(
      response.data.updateOrganizationObjective
    );
  }
  async DeleteOrganizationObjective(
    input: DeleteOrganizationObjectiveInput,
    condition?: ModelOrganizationObjectiveConditionInput
  ): Promise<DeleteOrganizationObjectiveMutation> {
    const statement = `mutation DeleteOrganizationObjective($input: DeleteOrganizationObjectiveInput!, $condition: ModelOrganizationObjectiveConditionInput) {
        deleteOrganizationObjective(input: $input, condition: $condition) {
          __typename
          id
          organizationID
          objective {
            __typename
            id
            display
            definition
            observables
            hints
            organizationID
            organizations
            status
            domain {
              __typename
              id
              key
              display
              excludeFromTPS
              sites
              createdAt
              updatedAt
            }
            objectiveDomainId
            qrCodes {
              __typename
              type
              imageSrc
              title
              label
              qrdata
            }
            createdAt
            updatedAt
          }
          organizationObjectiveObjectiveId
          enabled
          businessObjective
          status
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteOrganizationObjectiveMutation>(
      response.data.deleteOrganizationObjective
    );
  }
  async CreateObjectiveAssignment(
    input: CreateObjectiveAssignmentInput,
    condition?: ModelObjectiveAssignmentConditionInput
  ): Promise<CreateObjectiveAssignmentMutation> {
    const statement = `mutation CreateObjectiveAssignment($input: CreateObjectiveAssignmentInput!, $condition: ModelObjectiveAssignmentConditionInput) {
        createObjectiveAssignment(input: $input, condition: $condition) {
          __typename
          id
          employeeId
          organizationID
          objectiveAssignmentAssignedById
          assignmentDate
          priority {
            __typename
            id
            key
            display
            createdAt
            updatedAt
          }
          focus {
            __typename
            id
            key
            display
            createdAt
            updatedAt
          }
          orgObjective {
            __typename
            id
            organizationID
            objective {
              __typename
              id
              display
              definition
              observables
              hints
              organizationID
              organizations
              status
              domain {
                __typename
                id
                key
                display
                excludeFromTPS
                sites
                createdAt
                updatedAt
              }
              objectiveDomainId
              qrCodes {
                __typename
                type
                imageSrc
                title
                label
                qrdata
              }
              createdAt
              updatedAt
            }
            organizationObjectiveObjectiveId
            enabled
            businessObjective
            status
            createdAt
            updatedAt
          }
          startDate
          endDate
          cadence
          managerDescription
          objectiveType
          title
          quantity
          consultantId
          nextSurveyDate
          status
          externalEmail
          externalEmails
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateObjectiveAssignmentMutation>(
      response.data.createObjectiveAssignment
    );
  }
  async UpdateObjectiveAssignment(
    input: UpdateObjectiveAssignmentInput,
    condition?: ModelObjectiveAssignmentConditionInput
  ): Promise<UpdateObjectiveAssignmentMutation> {
    const statement = `mutation UpdateObjectiveAssignment($input: UpdateObjectiveAssignmentInput!, $condition: ModelObjectiveAssignmentConditionInput) {
        updateObjectiveAssignment(input: $input, condition: $condition) {
          __typename
          id
          employeeId
          organizationID
          objectiveAssignmentAssignedById
          assignmentDate
          priority {
            __typename
            id
            key
            display
            createdAt
            updatedAt
          }
          focus {
            __typename
            id
            key
            display
            createdAt
            updatedAt
          }
          orgObjective {
            __typename
            id
            organizationID
            objective {
              __typename
              id
              display
              definition
              observables
              hints
              organizationID
              organizations
              status
              domain {
                __typename
                id
                key
                display
                excludeFromTPS
                sites
                createdAt
                updatedAt
              }
              objectiveDomainId
              qrCodes {
                __typename
                type
                imageSrc
                title
                label
                qrdata
              }
              createdAt
              updatedAt
            }
            organizationObjectiveObjectiveId
            enabled
            businessObjective
            status
            createdAt
            updatedAt
          }
          startDate
          endDate
          cadence
          managerDescription
          objectiveType
          title
          quantity
          consultantId
          nextSurveyDate
          status
          externalEmail
          externalEmails
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateObjectiveAssignmentMutation>(
      response.data.updateObjectiveAssignment
    );
  }
  async DeleteObjectiveAssignment(
    input: DeleteObjectiveAssignmentInput,
    condition?: ModelObjectiveAssignmentConditionInput
  ): Promise<DeleteObjectiveAssignmentMutation> {
    const statement = `mutation DeleteObjectiveAssignment($input: DeleteObjectiveAssignmentInput!, $condition: ModelObjectiveAssignmentConditionInput) {
        deleteObjectiveAssignment(input: $input, condition: $condition) {
          __typename
          id
          employeeId
          organizationID
          objectiveAssignmentAssignedById
          assignmentDate
          priority {
            __typename
            id
            key
            display
            createdAt
            updatedAt
          }
          focus {
            __typename
            id
            key
            display
            createdAt
            updatedAt
          }
          orgObjective {
            __typename
            id
            organizationID
            objective {
              __typename
              id
              display
              definition
              observables
              hints
              organizationID
              organizations
              status
              domain {
                __typename
                id
                key
                display
                excludeFromTPS
                sites
                createdAt
                updatedAt
              }
              objectiveDomainId
              qrCodes {
                __typename
                type
                imageSrc
                title
                label
                qrdata
              }
              createdAt
              updatedAt
            }
            organizationObjectiveObjectiveId
            enabled
            businessObjective
            status
            createdAt
            updatedAt
          }
          startDate
          endDate
          cadence
          managerDescription
          objectiveType
          title
          quantity
          consultantId
          nextSurveyDate
          status
          externalEmail
          externalEmails
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteObjectiveAssignmentMutation>(
      response.data.deleteObjectiveAssignment
    );
  }
  async CreateCalendarEvent(
    input: CreateCalendarEventInput,
    condition?: ModelCalendarEventConditionInput
  ): Promise<CreateCalendarEventMutation> {
    const statement = `mutation CreateCalendarEvent($input: CreateCalendarEventInput!, $condition: ModelCalendarEventConditionInput) {
        createCalendarEvent(input: $input, condition: $condition) {
          __typename
          id
          employeeID
          organizationID
          calendarId
          start
          end
          eventCreated
          eventUpdated
          summary
          description
          status
          private
          deleted
          recurring
          locationDescription
          participationStatus
          eventStatus
          organizerEmail
          organizer {
            __typename
            email
            displayName
            status
            createdAt
            updatedAt
          }
          attendees {
            __typename
            email
            displayName
            status
            createdAt
            updatedAt
          }
          attendeeEmails
          meetingURL
          options {
            __typename
            changeParticipationStatus
            delete
            update
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateCalendarEventMutation>response.data.createCalendarEvent;
  }
  async UpdateCalendarEvent(
    input: UpdateCalendarEventInput,
    condition?: ModelCalendarEventConditionInput
  ): Promise<UpdateCalendarEventMutation> {
    const statement = `mutation UpdateCalendarEvent($input: UpdateCalendarEventInput!, $condition: ModelCalendarEventConditionInput) {
        updateCalendarEvent(input: $input, condition: $condition) {
          __typename
          id
          employeeID
          organizationID
          calendarId
          start
          end
          eventCreated
          eventUpdated
          summary
          description
          status
          private
          deleted
          recurring
          locationDescription
          participationStatus
          eventStatus
          organizerEmail
          organizer {
            __typename
            email
            displayName
            status
            createdAt
            updatedAt
          }
          attendees {
            __typename
            email
            displayName
            status
            createdAt
            updatedAt
          }
          attendeeEmails
          meetingURL
          options {
            __typename
            changeParticipationStatus
            delete
            update
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateCalendarEventMutation>response.data.updateCalendarEvent;
  }
  async DeleteCalendarEvent(
    input: DeleteCalendarEventInput,
    condition?: ModelCalendarEventConditionInput
  ): Promise<DeleteCalendarEventMutation> {
    const statement = `mutation DeleteCalendarEvent($input: DeleteCalendarEventInput!, $condition: ModelCalendarEventConditionInput) {
        deleteCalendarEvent(input: $input, condition: $condition) {
          __typename
          id
          employeeID
          organizationID
          calendarId
          start
          end
          eventCreated
          eventUpdated
          summary
          description
          status
          private
          deleted
          recurring
          locationDescription
          participationStatus
          eventStatus
          organizerEmail
          organizer {
            __typename
            email
            displayName
            status
            createdAt
            updatedAt
          }
          attendees {
            __typename
            email
            displayName
            status
            createdAt
            updatedAt
          }
          attendeeEmails
          meetingURL
          options {
            __typename
            changeParticipationStatus
            delete
            update
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteCalendarEventMutation>response.data.deleteCalendarEvent;
  }
  async CreateSurvey(
    input: CreateSurveyInput,
    condition?: ModelSurveyConditionInput
  ): Promise<CreateSurveyMutation> {
    const statement = `mutation CreateSurvey($input: CreateSurveyInput!, $condition: ModelSurveyConditionInput) {
        createSurvey(input: $input, condition: $condition) {
          __typename
          id
          organizationID
          objectID
          surveyType
          surveyDate
          respondentEmail
          participantEmail
          respondentAttended
          surveyResponse
          feedback
          responseReceived
          responseReceivedDate
          status
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateSurveyMutation>response.data.createSurvey;
  }
  async UpdateSurvey(
    input: UpdateSurveyInput,
    condition?: ModelSurveyConditionInput
  ): Promise<UpdateSurveyMutation> {
    const statement = `mutation UpdateSurvey($input: UpdateSurveyInput!, $condition: ModelSurveyConditionInput) {
        updateSurvey(input: $input, condition: $condition) {
          __typename
          id
          organizationID
          objectID
          surveyType
          surveyDate
          respondentEmail
          participantEmail
          respondentAttended
          surveyResponse
          feedback
          responseReceived
          responseReceivedDate
          status
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateSurveyMutation>response.data.updateSurvey;
  }
  async DeleteSurvey(
    input: DeleteSurveyInput,
    condition?: ModelSurveyConditionInput
  ): Promise<DeleteSurveyMutation> {
    const statement = `mutation DeleteSurvey($input: DeleteSurveyInput!, $condition: ModelSurveyConditionInput) {
        deleteSurvey(input: $input, condition: $condition) {
          __typename
          id
          organizationID
          objectID
          surveyType
          surveyDate
          respondentEmail
          participantEmail
          respondentAttended
          surveyResponse
          feedback
          responseReceived
          responseReceivedDate
          status
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteSurveyMutation>response.data.deleteSurvey;
  }
  async CreateExternalRespondent(
    input: CreateExternalRespondentInput,
    condition?: ModelExternalRespondentConditionInput
  ): Promise<CreateExternalRespondentMutation> {
    const statement = `mutation CreateExternalRespondent($input: CreateExternalRespondentInput!, $condition: ModelExternalRespondentConditionInput) {
        createExternalRespondent(input: $input, condition: $condition) {
          __typename
          id
          externalRespondentEmail
          externalRespondentName
          externalRespondentStatus
          statusUpdates {
            __typename
            status
            ipAddress
            externalRespondentStatusDate
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateExternalRespondentMutation>(
      response.data.createExternalRespondent
    );
  }
  async UpdateExternalRespondent(
    input: UpdateExternalRespondentInput,
    condition?: ModelExternalRespondentConditionInput
  ): Promise<UpdateExternalRespondentMutation> {
    const statement = `mutation UpdateExternalRespondent($input: UpdateExternalRespondentInput!, $condition: ModelExternalRespondentConditionInput) {
        updateExternalRespondent(input: $input, condition: $condition) {
          __typename
          id
          externalRespondentEmail
          externalRespondentName
          externalRespondentStatus
          statusUpdates {
            __typename
            status
            ipAddress
            externalRespondentStatusDate
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateExternalRespondentMutation>(
      response.data.updateExternalRespondent
    );
  }
  async DeleteExternalRespondent(
    input: DeleteExternalRespondentInput,
    condition?: ModelExternalRespondentConditionInput
  ): Promise<DeleteExternalRespondentMutation> {
    const statement = `mutation DeleteExternalRespondent($input: DeleteExternalRespondentInput!, $condition: ModelExternalRespondentConditionInput) {
        deleteExternalRespondent(input: $input, condition: $condition) {
          __typename
          id
          externalRespondentEmail
          externalRespondentName
          externalRespondentStatus
          statusUpdates {
            __typename
            status
            ipAddress
            externalRespondentStatusDate
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteExternalRespondentMutation>(
      response.data.deleteExternalRespondent
    );
  }
  async UpdateDoNotDisturbRequest(
    input: UpdateDoNotDisturbRequestInput,
    condition?: ModelDoNotDisturbRequestConditionInput
  ): Promise<UpdateDoNotDisturbRequestMutation> {
    const statement = `mutation UpdateDoNotDisturbRequest($input: UpdateDoNotDisturbRequestInput!, $condition: ModelDoNotDisturbRequestConditionInput) {
        updateDoNotDisturbRequest(input: $input, condition: $condition) {
          __typename
          id
          externalRespondentID
          externalRespondentEmail
          ipAddress
          status
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateDoNotDisturbRequestMutation>(
      response.data.updateDoNotDisturbRequest
    );
  }
  async DeleteDoNotDisturbRequest(
    input: DeleteDoNotDisturbRequestInput,
    condition?: ModelDoNotDisturbRequestConditionInput
  ): Promise<DeleteDoNotDisturbRequestMutation> {
    const statement = `mutation DeleteDoNotDisturbRequest($input: DeleteDoNotDisturbRequestInput!, $condition: ModelDoNotDisturbRequestConditionInput) {
        deleteDoNotDisturbRequest(input: $input, condition: $condition) {
          __typename
          id
          externalRespondentID
          externalRespondentEmail
          ipAddress
          status
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteDoNotDisturbRequestMutation>(
      response.data.deleteDoNotDisturbRequest
    );
  }
  async CreateExternalSurvey(
    input: CreateExternalSurveyInput,
    condition?: ModelExternalSurveyConditionInput
  ): Promise<CreateExternalSurveyMutation> {
    const statement = `mutation CreateExternalSurvey($input: CreateExternalSurveyInput!, $condition: ModelExternalSurveyConditionInput) {
        createExternalSurvey(input: $input, condition: $condition) {
          __typename
          id
          surveyType
          surveyDate
          externalRespondentID
          participant {
            __typename
            id
            participantName
          }
          objectiveDetails {
            __typename
            id
            objectiveDisplay
            objectiveDefinition
            businessObjective
            managerDescription
            objectiveObservables
          }
          status
          createdAt
          updatedAt
          respondentAttended
          surveyResponse
          feedback
          responseReceived
          responseReceivedDate
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateExternalSurveyMutation>response.data.createExternalSurvey;
  }
  async DeleteExternalSurvey(
    input: DeleteExternalSurveyInput,
    condition?: ModelExternalSurveyConditionInput
  ): Promise<DeleteExternalSurveyMutation> {
    const statement = `mutation DeleteExternalSurvey($input: DeleteExternalSurveyInput!, $condition: ModelExternalSurveyConditionInput) {
        deleteExternalSurvey(input: $input, condition: $condition) {
          __typename
          id
          surveyType
          surveyDate
          externalRespondentID
          participant {
            __typename
            id
            participantName
          }
          objectiveDetails {
            __typename
            id
            objectiveDisplay
            objectiveDefinition
            businessObjective
            managerDescription
            objectiveObservables
          }
          status
          createdAt
          updatedAt
          respondentAttended
          surveyResponse
          feedback
          responseReceived
          responseReceivedDate
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteExternalSurveyMutation>response.data.deleteExternalSurvey;
  }
  async CreateProject(
    input: CreateProjectInput,
    condition?: ModelProjectConditionInput
  ): Promise<CreateProjectMutation> {
    const statement = `mutation CreateProject($input: CreateProjectInput!, $condition: ModelProjectConditionInput) {
        createProject(input: $input, condition: $condition) {
          __typename
          id
          organizationID
          title
          description
          startDate
          endDate
          cadence
          projectManagerID
          status
          team
          nextSurveyDate
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateProjectMutation>response.data.createProject;
  }
  async UpdateProject(
    input: UpdateProjectInput,
    condition?: ModelProjectConditionInput
  ): Promise<UpdateProjectMutation> {
    const statement = `mutation UpdateProject($input: UpdateProjectInput!, $condition: ModelProjectConditionInput) {
        updateProject(input: $input, condition: $condition) {
          __typename
          id
          organizationID
          title
          description
          startDate
          endDate
          cadence
          projectManagerID
          status
          team
          nextSurveyDate
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateProjectMutation>response.data.updateProject;
  }
  async DeleteProject(
    input: DeleteProjectInput,
    condition?: ModelProjectConditionInput
  ): Promise<DeleteProjectMutation> {
    const statement = `mutation DeleteProject($input: DeleteProjectInput!, $condition: ModelProjectConditionInput) {
        deleteProject(input: $input, condition: $condition) {
          __typename
          id
          organizationID
          title
          description
          startDate
          endDate
          cadence
          projectManagerID
          status
          team
          nextSurveyDate
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteProjectMutation>response.data.deleteProject;
  }
  async CreateMilestoneComment(
    input: CreateMilestoneCommentInput,
    condition?: ModelMilestoneCommentConditionInput
  ): Promise<CreateMilestoneCommentMutation> {
    const statement = `mutation CreateMilestoneComment($input: CreateMilestoneCommentInput!, $condition: ModelMilestoneCommentConditionInput) {
        createMilestoneComment(input: $input, condition: $condition) {
          __typename
          id
          organizationID
          milestoneID
          projectMilestoneAssignment {
            __typename
            id
            projectID
            organizationID
            assignedBy
            assignmentDate
            startDate
            endDate
            cadence
            milestoneType
            title
            nextSurveyDate
            description
            team
            comments {
              __typename
              items {
                __typename
                id
                organizationID
                milestoneID
                projectMilestoneAssignment {
                  __typename
                  id
                  projectID
                  organizationID
                  assignedBy
                  assignmentDate
                  startDate
                  endDate
                  cadence
                  milestoneType
                  title
                  nextSurveyDate
                  description
                  team
                  comments {
                    __typename
                    items {
                      __typename
                      id
                      organizationID
                      milestoneID
                      projectMilestoneAssignment {
                        __typename
                        id
                        projectID
                        organizationID
                        assignedBy
                        assignmentDate
                        startDate
                        endDate
                        cadence
                        milestoneType
                        title
                        nextSurveyDate
                        description
                        team
                        comments {
                          __typename
                          items {
                            __typename
                            id
                            organizationID
                            milestoneID
                            comment
                            createdAt
                            createdBy
                            updatedAt
                          }
                          nextToken
                        }
                        createdAt
                        updatedAt
                      }
                      comment
                      createdAt
                      createdBy
                      updatedAt
                    }
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                comment
                createdAt
                createdBy
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          comment
          createdAt
          createdBy
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateMilestoneCommentMutation>response.data.createMilestoneComment;
  }
  async UpdateMilestoneComment(
    input: UpdateMilestoneCommentInput,
    condition?: ModelMilestoneCommentConditionInput
  ): Promise<UpdateMilestoneCommentMutation> {
    const statement = `mutation UpdateMilestoneComment($input: UpdateMilestoneCommentInput!, $condition: ModelMilestoneCommentConditionInput) {
        updateMilestoneComment(input: $input, condition: $condition) {
          __typename
          id
          organizationID
          milestoneID
          projectMilestoneAssignment {
            __typename
            id
            projectID
            organizationID
            assignedBy
            assignmentDate
            startDate
            endDate
            cadence
            milestoneType
            title
            nextSurveyDate
            description
            team
            comments {
              __typename
              items {
                __typename
                id
                organizationID
                milestoneID
                projectMilestoneAssignment {
                  __typename
                  id
                  projectID
                  organizationID
                  assignedBy
                  assignmentDate
                  startDate
                  endDate
                  cadence
                  milestoneType
                  title
                  nextSurveyDate
                  description
                  team
                  comments {
                    __typename
                    items {
                      __typename
                      id
                      organizationID
                      milestoneID
                      projectMilestoneAssignment {
                        __typename
                        id
                        projectID
                        organizationID
                        assignedBy
                        assignmentDate
                        startDate
                        endDate
                        cadence
                        milestoneType
                        title
                        nextSurveyDate
                        description
                        team
                        comments {
                          __typename
                          items {
                            __typename
                            id
                            organizationID
                            milestoneID
                            comment
                            createdAt
                            createdBy
                            updatedAt
                          }
                          nextToken
                        }
                        createdAt
                        updatedAt
                      }
                      comment
                      createdAt
                      createdBy
                      updatedAt
                    }
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                comment
                createdAt
                createdBy
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          comment
          createdAt
          createdBy
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateMilestoneCommentMutation>response.data.updateMilestoneComment;
  }
  async DeleteMilestoneComment(
    input: DeleteMilestoneCommentInput,
    condition?: ModelMilestoneCommentConditionInput
  ): Promise<DeleteMilestoneCommentMutation> {
    const statement = `mutation DeleteMilestoneComment($input: DeleteMilestoneCommentInput!, $condition: ModelMilestoneCommentConditionInput) {
        deleteMilestoneComment(input: $input, condition: $condition) {
          __typename
          id
          organizationID
          milestoneID
          projectMilestoneAssignment {
            __typename
            id
            projectID
            organizationID
            assignedBy
            assignmentDate
            startDate
            endDate
            cadence
            milestoneType
            title
            nextSurveyDate
            description
            team
            comments {
              __typename
              items {
                __typename
                id
                organizationID
                milestoneID
                projectMilestoneAssignment {
                  __typename
                  id
                  projectID
                  organizationID
                  assignedBy
                  assignmentDate
                  startDate
                  endDate
                  cadence
                  milestoneType
                  title
                  nextSurveyDate
                  description
                  team
                  comments {
                    __typename
                    items {
                      __typename
                      id
                      organizationID
                      milestoneID
                      projectMilestoneAssignment {
                        __typename
                        id
                        projectID
                        organizationID
                        assignedBy
                        assignmentDate
                        startDate
                        endDate
                        cadence
                        milestoneType
                        title
                        nextSurveyDate
                        description
                        team
                        comments {
                          __typename
                          items {
                            __typename
                            id
                            organizationID
                            milestoneID
                            comment
                            createdAt
                            createdBy
                            updatedAt
                          }
                          nextToken
                        }
                        createdAt
                        updatedAt
                      }
                      comment
                      createdAt
                      createdBy
                      updatedAt
                    }
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                comment
                createdAt
                createdBy
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          comment
          createdAt
          createdBy
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteMilestoneCommentMutation>response.data.deleteMilestoneComment;
  }
  async CreateProjectMilestoneAssignment(
    input: CreateProjectMilestoneAssignmentInput,
    condition?: ModelProjectMilestoneAssignmentConditionInput
  ): Promise<CreateProjectMilestoneAssignmentMutation> {
    const statement = `mutation CreateProjectMilestoneAssignment($input: CreateProjectMilestoneAssignmentInput!, $condition: ModelProjectMilestoneAssignmentConditionInput) {
        createProjectMilestoneAssignment(input: $input, condition: $condition) {
          __typename
          id
          projectID
          organizationID
          assignedBy
          assignmentDate
          startDate
          endDate
          cadence
          milestoneType
          title
          nextSurveyDate
          description
          team
          comments {
            __typename
            items {
              __typename
              id
              organizationID
              milestoneID
              projectMilestoneAssignment {
                __typename
                id
                projectID
                organizationID
                assignedBy
                assignmentDate
                startDate
                endDate
                cadence
                milestoneType
                title
                nextSurveyDate
                description
                team
                comments {
                  __typename
                  items {
                    __typename
                    id
                    organizationID
                    milestoneID
                    projectMilestoneAssignment {
                      __typename
                      id
                      projectID
                      organizationID
                      assignedBy
                      assignmentDate
                      startDate
                      endDate
                      cadence
                      milestoneType
                      title
                      nextSurveyDate
                      description
                      team
                      comments {
                        __typename
                        items {
                          __typename
                          id
                          organizationID
                          milestoneID
                          projectMilestoneAssignment {
                            __typename
                            id
                            projectID
                            organizationID
                            assignedBy
                            assignmentDate
                            startDate
                            endDate
                            cadence
                            milestoneType
                            title
                            nextSurveyDate
                            description
                            team
                            createdAt
                            updatedAt
                          }
                          comment
                          createdAt
                          createdBy
                          updatedAt
                        }
                        nextToken
                      }
                      createdAt
                      updatedAt
                    }
                    comment
                    createdAt
                    createdBy
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              comment
              createdAt
              createdBy
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateProjectMilestoneAssignmentMutation>(
      response.data.createProjectMilestoneAssignment
    );
  }
  async UpdateProjectMilestoneAssignment(
    input: UpdateProjectMilestoneAssignmentInput,
    condition?: ModelProjectMilestoneAssignmentConditionInput
  ): Promise<UpdateProjectMilestoneAssignmentMutation> {
    const statement = `mutation UpdateProjectMilestoneAssignment($input: UpdateProjectMilestoneAssignmentInput!, $condition: ModelProjectMilestoneAssignmentConditionInput) {
        updateProjectMilestoneAssignment(input: $input, condition: $condition) {
          __typename
          id
          projectID
          organizationID
          assignedBy
          assignmentDate
          startDate
          endDate
          cadence
          milestoneType
          title
          nextSurveyDate
          description
          team
          comments {
            __typename
            items {
              __typename
              id
              organizationID
              milestoneID
              projectMilestoneAssignment {
                __typename
                id
                projectID
                organizationID
                assignedBy
                assignmentDate
                startDate
                endDate
                cadence
                milestoneType
                title
                nextSurveyDate
                description
                team
                comments {
                  __typename
                  items {
                    __typename
                    id
                    organizationID
                    milestoneID
                    projectMilestoneAssignment {
                      __typename
                      id
                      projectID
                      organizationID
                      assignedBy
                      assignmentDate
                      startDate
                      endDate
                      cadence
                      milestoneType
                      title
                      nextSurveyDate
                      description
                      team
                      comments {
                        __typename
                        items {
                          __typename
                          id
                          organizationID
                          milestoneID
                          projectMilestoneAssignment {
                            __typename
                            id
                            projectID
                            organizationID
                            assignedBy
                            assignmentDate
                            startDate
                            endDate
                            cadence
                            milestoneType
                            title
                            nextSurveyDate
                            description
                            team
                            createdAt
                            updatedAt
                          }
                          comment
                          createdAt
                          createdBy
                          updatedAt
                        }
                        nextToken
                      }
                      createdAt
                      updatedAt
                    }
                    comment
                    createdAt
                    createdBy
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              comment
              createdAt
              createdBy
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateProjectMilestoneAssignmentMutation>(
      response.data.updateProjectMilestoneAssignment
    );
  }
  async DeleteProjectMilestoneAssignment(
    input: DeleteProjectMilestoneAssignmentInput,
    condition?: ModelProjectMilestoneAssignmentConditionInput
  ): Promise<DeleteProjectMilestoneAssignmentMutation> {
    const statement = `mutation DeleteProjectMilestoneAssignment($input: DeleteProjectMilestoneAssignmentInput!, $condition: ModelProjectMilestoneAssignmentConditionInput) {
        deleteProjectMilestoneAssignment(input: $input, condition: $condition) {
          __typename
          id
          projectID
          organizationID
          assignedBy
          assignmentDate
          startDate
          endDate
          cadence
          milestoneType
          title
          nextSurveyDate
          description
          team
          comments {
            __typename
            items {
              __typename
              id
              organizationID
              milestoneID
              projectMilestoneAssignment {
                __typename
                id
                projectID
                organizationID
                assignedBy
                assignmentDate
                startDate
                endDate
                cadence
                milestoneType
                title
                nextSurveyDate
                description
                team
                comments {
                  __typename
                  items {
                    __typename
                    id
                    organizationID
                    milestoneID
                    projectMilestoneAssignment {
                      __typename
                      id
                      projectID
                      organizationID
                      assignedBy
                      assignmentDate
                      startDate
                      endDate
                      cadence
                      milestoneType
                      title
                      nextSurveyDate
                      description
                      team
                      comments {
                        __typename
                        items {
                          __typename
                          id
                          organizationID
                          milestoneID
                          projectMilestoneAssignment {
                            __typename
                            id
                            projectID
                            organizationID
                            assignedBy
                            assignmentDate
                            startDate
                            endDate
                            cadence
                            milestoneType
                            title
                            nextSurveyDate
                            description
                            team
                            createdAt
                            updatedAt
                          }
                          comment
                          createdAt
                          createdBy
                          updatedAt
                        }
                        nextToken
                      }
                      createdAt
                      updatedAt
                    }
                    comment
                    createdAt
                    createdBy
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              comment
              createdAt
              createdBy
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteProjectMilestoneAssignmentMutation>(
      response.data.deleteProjectMilestoneAssignment
    );
  }
  async CreateNoteComment(
    input: CreateNoteCommentInput,
    condition?: ModelNoteCommentConditionInput
  ): Promise<CreateNoteCommentMutation> {
    const statement = `mutation CreateNoteComment($input: CreateNoteCommentInput!, $condition: ModelNoteCommentConditionInput) {
        createNoteComment(input: $input, condition: $condition) {
          __typename
          id
          organizationID
          noteID
          note {
            __typename
            id
            organizationID
            objectID
            createdAt
            createdBy
            updatedAt
            updatedBy
            text
            noteType
            options
            comments {
              __typename
              items {
                __typename
                id
                organizationID
                noteID
                note {
                  __typename
                  id
                  organizationID
                  objectID
                  createdAt
                  createdBy
                  updatedAt
                  updatedBy
                  text
                  noteType
                  options
                  comments {
                    __typename
                    items {
                      __typename
                      id
                      organizationID
                      noteID
                      note {
                        __typename
                        id
                        organizationID
                        objectID
                        createdAt
                        createdBy
                        updatedAt
                        updatedBy
                        text
                        noteType
                        options
                        comments {
                          __typename
                          items {
                            __typename
                            id
                            organizationID
                            noteID
                            comment
                            createdAt
                            createdBy
                            updatedAt
                          }
                          nextToken
                        }
                        historys {
                          __typename
                          items {
                            __typename
                            id
                            organizationID
                            noteID
                            createdAt
                            createdBy
                            text
                            updatedAt
                          }
                          nextToken
                        }
                      }
                      comment
                      createdAt
                      createdBy
                      updatedAt
                    }
                    nextToken
                  }
                  historys {
                    __typename
                    items {
                      __typename
                      id
                      organizationID
                      noteID
                      note {
                        __typename
                        id
                        organizationID
                        objectID
                        createdAt
                        createdBy
                        updatedAt
                        updatedBy
                        text
                        noteType
                        options
                        comments {
                          __typename
                          items {
                            __typename
                            id
                            organizationID
                            noteID
                            comment
                            createdAt
                            createdBy
                            updatedAt
                          }
                          nextToken
                        }
                        historys {
                          __typename
                          items {
                            __typename
                            id
                            organizationID
                            noteID
                            createdAt
                            createdBy
                            text
                            updatedAt
                          }
                          nextToken
                        }
                      }
                      createdAt
                      createdBy
                      text
                      updatedAt
                    }
                    nextToken
                  }
                }
                comment
                createdAt
                createdBy
                updatedAt
              }
              nextToken
            }
            historys {
              __typename
              items {
                __typename
                id
                organizationID
                noteID
                note {
                  __typename
                  id
                  organizationID
                  objectID
                  createdAt
                  createdBy
                  updatedAt
                  updatedBy
                  text
                  noteType
                  options
                  comments {
                    __typename
                    items {
                      __typename
                      id
                      organizationID
                      noteID
                      note {
                        __typename
                        id
                        organizationID
                        objectID
                        createdAt
                        createdBy
                        updatedAt
                        updatedBy
                        text
                        noteType
                        options
                        comments {
                          __typename
                          items {
                            __typename
                            id
                            organizationID
                            noteID
                            comment
                            createdAt
                            createdBy
                            updatedAt
                          }
                          nextToken
                        }
                        historys {
                          __typename
                          items {
                            __typename
                            id
                            organizationID
                            noteID
                            createdAt
                            createdBy
                            text
                            updatedAt
                          }
                          nextToken
                        }
                      }
                      comment
                      createdAt
                      createdBy
                      updatedAt
                    }
                    nextToken
                  }
                  historys {
                    __typename
                    items {
                      __typename
                      id
                      organizationID
                      noteID
                      note {
                        __typename
                        id
                        organizationID
                        objectID
                        createdAt
                        createdBy
                        updatedAt
                        updatedBy
                        text
                        noteType
                        options
                        comments {
                          __typename
                          items {
                            __typename
                            id
                            organizationID
                            noteID
                            comment
                            createdAt
                            createdBy
                            updatedAt
                          }
                          nextToken
                        }
                        historys {
                          __typename
                          items {
                            __typename
                            id
                            organizationID
                            noteID
                            createdAt
                            createdBy
                            text
                            updatedAt
                          }
                          nextToken
                        }
                      }
                      createdAt
                      createdBy
                      text
                      updatedAt
                    }
                    nextToken
                  }
                }
                createdAt
                createdBy
                text
                updatedAt
              }
              nextToken
            }
          }
          comment
          createdAt
          createdBy
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateNoteCommentMutation>response.data.createNoteComment;
  }
  async UpdateNoteComment(
    input: UpdateNoteCommentInput,
    condition?: ModelNoteCommentConditionInput
  ): Promise<UpdateNoteCommentMutation> {
    const statement = `mutation UpdateNoteComment($input: UpdateNoteCommentInput!, $condition: ModelNoteCommentConditionInput) {
        updateNoteComment(input: $input, condition: $condition) {
          __typename
          id
          organizationID
          noteID
          note {
            __typename
            id
            organizationID
            objectID
            createdAt
            createdBy
            updatedAt
            updatedBy
            text
            noteType
            options
            comments {
              __typename
              items {
                __typename
                id
                organizationID
                noteID
                note {
                  __typename
                  id
                  organizationID
                  objectID
                  createdAt
                  createdBy
                  updatedAt
                  updatedBy
                  text
                  noteType
                  options
                  comments {
                    __typename
                    items {
                      __typename
                      id
                      organizationID
                      noteID
                      note {
                        __typename
                        id
                        organizationID
                        objectID
                        createdAt
                        createdBy
                        updatedAt
                        updatedBy
                        text
                        noteType
                        options
                        comments {
                          __typename
                          items {
                            __typename
                            id
                            organizationID
                            noteID
                            comment
                            createdAt
                            createdBy
                            updatedAt
                          }
                          nextToken
                        }
                        historys {
                          __typename
                          items {
                            __typename
                            id
                            organizationID
                            noteID
                            createdAt
                            createdBy
                            text
                            updatedAt
                          }
                          nextToken
                        }
                      }
                      comment
                      createdAt
                      createdBy
                      updatedAt
                    }
                    nextToken
                  }
                  historys {
                    __typename
                    items {
                      __typename
                      id
                      organizationID
                      noteID
                      note {
                        __typename
                        id
                        organizationID
                        objectID
                        createdAt
                        createdBy
                        updatedAt
                        updatedBy
                        text
                        noteType
                        options
                        comments {
                          __typename
                          items {
                            __typename
                            id
                            organizationID
                            noteID
                            comment
                            createdAt
                            createdBy
                            updatedAt
                          }
                          nextToken
                        }
                        historys {
                          __typename
                          items {
                            __typename
                            id
                            organizationID
                            noteID
                            createdAt
                            createdBy
                            text
                            updatedAt
                          }
                          nextToken
                        }
                      }
                      createdAt
                      createdBy
                      text
                      updatedAt
                    }
                    nextToken
                  }
                }
                comment
                createdAt
                createdBy
                updatedAt
              }
              nextToken
            }
            historys {
              __typename
              items {
                __typename
                id
                organizationID
                noteID
                note {
                  __typename
                  id
                  organizationID
                  objectID
                  createdAt
                  createdBy
                  updatedAt
                  updatedBy
                  text
                  noteType
                  options
                  comments {
                    __typename
                    items {
                      __typename
                      id
                      organizationID
                      noteID
                      note {
                        __typename
                        id
                        organizationID
                        objectID
                        createdAt
                        createdBy
                        updatedAt
                        updatedBy
                        text
                        noteType
                        options
                        comments {
                          __typename
                          items {
                            __typename
                            id
                            organizationID
                            noteID
                            comment
                            createdAt
                            createdBy
                            updatedAt
                          }
                          nextToken
                        }
                        historys {
                          __typename
                          items {
                            __typename
                            id
                            organizationID
                            noteID
                            createdAt
                            createdBy
                            text
                            updatedAt
                          }
                          nextToken
                        }
                      }
                      comment
                      createdAt
                      createdBy
                      updatedAt
                    }
                    nextToken
                  }
                  historys {
                    __typename
                    items {
                      __typename
                      id
                      organizationID
                      noteID
                      note {
                        __typename
                        id
                        organizationID
                        objectID
                        createdAt
                        createdBy
                        updatedAt
                        updatedBy
                        text
                        noteType
                        options
                        comments {
                          __typename
                          items {
                            __typename
                            id
                            organizationID
                            noteID
                            comment
                            createdAt
                            createdBy
                            updatedAt
                          }
                          nextToken
                        }
                        historys {
                          __typename
                          items {
                            __typename
                            id
                            organizationID
                            noteID
                            createdAt
                            createdBy
                            text
                            updatedAt
                          }
                          nextToken
                        }
                      }
                      createdAt
                      createdBy
                      text
                      updatedAt
                    }
                    nextToken
                  }
                }
                createdAt
                createdBy
                text
                updatedAt
              }
              nextToken
            }
          }
          comment
          createdAt
          createdBy
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateNoteCommentMutation>response.data.updateNoteComment;
  }
  async DeleteNoteComment(
    input: DeleteNoteCommentInput,
    condition?: ModelNoteCommentConditionInput
  ): Promise<DeleteNoteCommentMutation> {
    const statement = `mutation DeleteNoteComment($input: DeleteNoteCommentInput!, $condition: ModelNoteCommentConditionInput) {
        deleteNoteComment(input: $input, condition: $condition) {
          __typename
          id
          organizationID
          noteID
          note {
            __typename
            id
            organizationID
            objectID
            createdAt
            createdBy
            updatedAt
            updatedBy
            text
            noteType
            options
            comments {
              __typename
              items {
                __typename
                id
                organizationID
                noteID
                note {
                  __typename
                  id
                  organizationID
                  objectID
                  createdAt
                  createdBy
                  updatedAt
                  updatedBy
                  text
                  noteType
                  options
                  comments {
                    __typename
                    items {
                      __typename
                      id
                      organizationID
                      noteID
                      note {
                        __typename
                        id
                        organizationID
                        objectID
                        createdAt
                        createdBy
                        updatedAt
                        updatedBy
                        text
                        noteType
                        options
                        comments {
                          __typename
                          items {
                            __typename
                            id
                            organizationID
                            noteID
                            comment
                            createdAt
                            createdBy
                            updatedAt
                          }
                          nextToken
                        }
                        historys {
                          __typename
                          items {
                            __typename
                            id
                            organizationID
                            noteID
                            createdAt
                            createdBy
                            text
                            updatedAt
                          }
                          nextToken
                        }
                      }
                      comment
                      createdAt
                      createdBy
                      updatedAt
                    }
                    nextToken
                  }
                  historys {
                    __typename
                    items {
                      __typename
                      id
                      organizationID
                      noteID
                      note {
                        __typename
                        id
                        organizationID
                        objectID
                        createdAt
                        createdBy
                        updatedAt
                        updatedBy
                        text
                        noteType
                        options
                        comments {
                          __typename
                          items {
                            __typename
                            id
                            organizationID
                            noteID
                            comment
                            createdAt
                            createdBy
                            updatedAt
                          }
                          nextToken
                        }
                        historys {
                          __typename
                          items {
                            __typename
                            id
                            organizationID
                            noteID
                            createdAt
                            createdBy
                            text
                            updatedAt
                          }
                          nextToken
                        }
                      }
                      createdAt
                      createdBy
                      text
                      updatedAt
                    }
                    nextToken
                  }
                }
                comment
                createdAt
                createdBy
                updatedAt
              }
              nextToken
            }
            historys {
              __typename
              items {
                __typename
                id
                organizationID
                noteID
                note {
                  __typename
                  id
                  organizationID
                  objectID
                  createdAt
                  createdBy
                  updatedAt
                  updatedBy
                  text
                  noteType
                  options
                  comments {
                    __typename
                    items {
                      __typename
                      id
                      organizationID
                      noteID
                      note {
                        __typename
                        id
                        organizationID
                        objectID
                        createdAt
                        createdBy
                        updatedAt
                        updatedBy
                        text
                        noteType
                        options
                        comments {
                          __typename
                          items {
                            __typename
                            id
                            organizationID
                            noteID
                            comment
                            createdAt
                            createdBy
                            updatedAt
                          }
                          nextToken
                        }
                        historys {
                          __typename
                          items {
                            __typename
                            id
                            organizationID
                            noteID
                            createdAt
                            createdBy
                            text
                            updatedAt
                          }
                          nextToken
                        }
                      }
                      comment
                      createdAt
                      createdBy
                      updatedAt
                    }
                    nextToken
                  }
                  historys {
                    __typename
                    items {
                      __typename
                      id
                      organizationID
                      noteID
                      note {
                        __typename
                        id
                        organizationID
                        objectID
                        createdAt
                        createdBy
                        updatedAt
                        updatedBy
                        text
                        noteType
                        options
                        comments {
                          __typename
                          items {
                            __typename
                            id
                            organizationID
                            noteID
                            comment
                            createdAt
                            createdBy
                            updatedAt
                          }
                          nextToken
                        }
                        historys {
                          __typename
                          items {
                            __typename
                            id
                            organizationID
                            noteID
                            createdAt
                            createdBy
                            text
                            updatedAt
                          }
                          nextToken
                        }
                      }
                      createdAt
                      createdBy
                      text
                      updatedAt
                    }
                    nextToken
                  }
                }
                createdAt
                createdBy
                text
                updatedAt
              }
              nextToken
            }
          }
          comment
          createdAt
          createdBy
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteNoteCommentMutation>response.data.deleteNoteComment;
  }
  async CreateNote(
    input: CreateNoteInput,
    condition?: ModelNoteConditionInput
  ): Promise<CreateNoteMutation> {
    const statement = `mutation CreateNote($input: CreateNoteInput!, $condition: ModelNoteConditionInput) {
        createNote(input: $input, condition: $condition) {
          __typename
          id
          organizationID
          objectID
          createdAt
          createdBy
          updatedAt
          updatedBy
          text
          noteType
          options
          comments {
            __typename
            items {
              __typename
              id
              organizationID
              noteID
              note {
                __typename
                id
                organizationID
                objectID
                createdAt
                createdBy
                updatedAt
                updatedBy
                text
                noteType
                options
                comments {
                  __typename
                  items {
                    __typename
                    id
                    organizationID
                    noteID
                    note {
                      __typename
                      id
                      organizationID
                      objectID
                      createdAt
                      createdBy
                      updatedAt
                      updatedBy
                      text
                      noteType
                      options
                      comments {
                        __typename
                        items {
                          __typename
                          id
                          organizationID
                          noteID
                          note {
                            __typename
                            id
                            organizationID
                            objectID
                            createdAt
                            createdBy
                            updatedAt
                            updatedBy
                            text
                            noteType
                            options
                          }
                          comment
                          createdAt
                          createdBy
                          updatedAt
                        }
                        nextToken
                      }
                      historys {
                        __typename
                        items {
                          __typename
                          id
                          organizationID
                          noteID
                          note {
                            __typename
                            id
                            organizationID
                            objectID
                            createdAt
                            createdBy
                            updatedAt
                            updatedBy
                            text
                            noteType
                            options
                          }
                          createdAt
                          createdBy
                          text
                          updatedAt
                        }
                        nextToken
                      }
                    }
                    comment
                    createdAt
                    createdBy
                    updatedAt
                  }
                  nextToken
                }
                historys {
                  __typename
                  items {
                    __typename
                    id
                    organizationID
                    noteID
                    note {
                      __typename
                      id
                      organizationID
                      objectID
                      createdAt
                      createdBy
                      updatedAt
                      updatedBy
                      text
                      noteType
                      options
                      comments {
                        __typename
                        items {
                          __typename
                          id
                          organizationID
                          noteID
                          note {
                            __typename
                            id
                            organizationID
                            objectID
                            createdAt
                            createdBy
                            updatedAt
                            updatedBy
                            text
                            noteType
                            options
                          }
                          comment
                          createdAt
                          createdBy
                          updatedAt
                        }
                        nextToken
                      }
                      historys {
                        __typename
                        items {
                          __typename
                          id
                          organizationID
                          noteID
                          note {
                            __typename
                            id
                            organizationID
                            objectID
                            createdAt
                            createdBy
                            updatedAt
                            updatedBy
                            text
                            noteType
                            options
                          }
                          createdAt
                          createdBy
                          text
                          updatedAt
                        }
                        nextToken
                      }
                    }
                    createdAt
                    createdBy
                    text
                    updatedAt
                  }
                  nextToken
                }
              }
              comment
              createdAt
              createdBy
              updatedAt
            }
            nextToken
          }
          historys {
            __typename
            items {
              __typename
              id
              organizationID
              noteID
              note {
                __typename
                id
                organizationID
                objectID
                createdAt
                createdBy
                updatedAt
                updatedBy
                text
                noteType
                options
                comments {
                  __typename
                  items {
                    __typename
                    id
                    organizationID
                    noteID
                    note {
                      __typename
                      id
                      organizationID
                      objectID
                      createdAt
                      createdBy
                      updatedAt
                      updatedBy
                      text
                      noteType
                      options
                      comments {
                        __typename
                        items {
                          __typename
                          id
                          organizationID
                          noteID
                          note {
                            __typename
                            id
                            organizationID
                            objectID
                            createdAt
                            createdBy
                            updatedAt
                            updatedBy
                            text
                            noteType
                            options
                          }
                          comment
                          createdAt
                          createdBy
                          updatedAt
                        }
                        nextToken
                      }
                      historys {
                        __typename
                        items {
                          __typename
                          id
                          organizationID
                          noteID
                          note {
                            __typename
                            id
                            organizationID
                            objectID
                            createdAt
                            createdBy
                            updatedAt
                            updatedBy
                            text
                            noteType
                            options
                          }
                          createdAt
                          createdBy
                          text
                          updatedAt
                        }
                        nextToken
                      }
                    }
                    comment
                    createdAt
                    createdBy
                    updatedAt
                  }
                  nextToken
                }
                historys {
                  __typename
                  items {
                    __typename
                    id
                    organizationID
                    noteID
                    note {
                      __typename
                      id
                      organizationID
                      objectID
                      createdAt
                      createdBy
                      updatedAt
                      updatedBy
                      text
                      noteType
                      options
                      comments {
                        __typename
                        items {
                          __typename
                          id
                          organizationID
                          noteID
                          note {
                            __typename
                            id
                            organizationID
                            objectID
                            createdAt
                            createdBy
                            updatedAt
                            updatedBy
                            text
                            noteType
                            options
                          }
                          comment
                          createdAt
                          createdBy
                          updatedAt
                        }
                        nextToken
                      }
                      historys {
                        __typename
                        items {
                          __typename
                          id
                          organizationID
                          noteID
                          note {
                            __typename
                            id
                            organizationID
                            objectID
                            createdAt
                            createdBy
                            updatedAt
                            updatedBy
                            text
                            noteType
                            options
                          }
                          createdAt
                          createdBy
                          text
                          updatedAt
                        }
                        nextToken
                      }
                    }
                    createdAt
                    createdBy
                    text
                    updatedAt
                  }
                  nextToken
                }
              }
              createdAt
              createdBy
              text
              updatedAt
            }
            nextToken
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateNoteMutation>response.data.createNote;
  }
  async UpdateNote(
    input: UpdateNoteInput,
    condition?: ModelNoteConditionInput
  ): Promise<UpdateNoteMutation> {
    const statement = `mutation UpdateNote($input: UpdateNoteInput!, $condition: ModelNoteConditionInput) {
        updateNote(input: $input, condition: $condition) {
          __typename
          id
          organizationID
          objectID
          createdAt
          createdBy
          updatedAt
          updatedBy
          text
          noteType
          options
          comments {
            __typename
            items {
              __typename
              id
              organizationID
              noteID
              note {
                __typename
                id
                organizationID
                objectID
                createdAt
                createdBy
                updatedAt
                updatedBy
                text
                noteType
                options
                comments {
                  __typename
                  items {
                    __typename
                    id
                    organizationID
                    noteID
                    note {
                      __typename
                      id
                      organizationID
                      objectID
                      createdAt
                      createdBy
                      updatedAt
                      updatedBy
                      text
                      noteType
                      options
                      comments {
                        __typename
                        items {
                          __typename
                          id
                          organizationID
                          noteID
                          note {
                            __typename
                            id
                            organizationID
                            objectID
                            createdAt
                            createdBy
                            updatedAt
                            updatedBy
                            text
                            noteType
                            options
                          }
                          comment
                          createdAt
                          createdBy
                          updatedAt
                        }
                        nextToken
                      }
                      historys {
                        __typename
                        items {
                          __typename
                          id
                          organizationID
                          noteID
                          note {
                            __typename
                            id
                            organizationID
                            objectID
                            createdAt
                            createdBy
                            updatedAt
                            updatedBy
                            text
                            noteType
                            options
                          }
                          createdAt
                          createdBy
                          text
                          updatedAt
                        }
                        nextToken
                      }
                    }
                    comment
                    createdAt
                    createdBy
                    updatedAt
                  }
                  nextToken
                }
                historys {
                  __typename
                  items {
                    __typename
                    id
                    organizationID
                    noteID
                    note {
                      __typename
                      id
                      organizationID
                      objectID
                      createdAt
                      createdBy
                      updatedAt
                      updatedBy
                      text
                      noteType
                      options
                      comments {
                        __typename
                        items {
                          __typename
                          id
                          organizationID
                          noteID
                          note {
                            __typename
                            id
                            organizationID
                            objectID
                            createdAt
                            createdBy
                            updatedAt
                            updatedBy
                            text
                            noteType
                            options
                          }
                          comment
                          createdAt
                          createdBy
                          updatedAt
                        }
                        nextToken
                      }
                      historys {
                        __typename
                        items {
                          __typename
                          id
                          organizationID
                          noteID
                          note {
                            __typename
                            id
                            organizationID
                            objectID
                            createdAt
                            createdBy
                            updatedAt
                            updatedBy
                            text
                            noteType
                            options
                          }
                          createdAt
                          createdBy
                          text
                          updatedAt
                        }
                        nextToken
                      }
                    }
                    createdAt
                    createdBy
                    text
                    updatedAt
                  }
                  nextToken
                }
              }
              comment
              createdAt
              createdBy
              updatedAt
            }
            nextToken
          }
          historys {
            __typename
            items {
              __typename
              id
              organizationID
              noteID
              note {
                __typename
                id
                organizationID
                objectID
                createdAt
                createdBy
                updatedAt
                updatedBy
                text
                noteType
                options
                comments {
                  __typename
                  items {
                    __typename
                    id
                    organizationID
                    noteID
                    note {
                      __typename
                      id
                      organizationID
                      objectID
                      createdAt
                      createdBy
                      updatedAt
                      updatedBy
                      text
                      noteType
                      options
                      comments {
                        __typename
                        items {
                          __typename
                          id
                          organizationID
                          noteID
                          note {
                            __typename
                            id
                            organizationID
                            objectID
                            createdAt
                            createdBy
                            updatedAt
                            updatedBy
                            text
                            noteType
                            options
                          }
                          comment
                          createdAt
                          createdBy
                          updatedAt
                        }
                        nextToken
                      }
                      historys {
                        __typename
                        items {
                          __typename
                          id
                          organizationID
                          noteID
                          note {
                            __typename
                            id
                            organizationID
                            objectID
                            createdAt
                            createdBy
                            updatedAt
                            updatedBy
                            text
                            noteType
                            options
                          }
                          createdAt
                          createdBy
                          text
                          updatedAt
                        }
                        nextToken
                      }
                    }
                    comment
                    createdAt
                    createdBy
                    updatedAt
                  }
                  nextToken
                }
                historys {
                  __typename
                  items {
                    __typename
                    id
                    organizationID
                    noteID
                    note {
                      __typename
                      id
                      organizationID
                      objectID
                      createdAt
                      createdBy
                      updatedAt
                      updatedBy
                      text
                      noteType
                      options
                      comments {
                        __typename
                        items {
                          __typename
                          id
                          organizationID
                          noteID
                          note {
                            __typename
                            id
                            organizationID
                            objectID
                            createdAt
                            createdBy
                            updatedAt
                            updatedBy
                            text
                            noteType
                            options
                          }
                          comment
                          createdAt
                          createdBy
                          updatedAt
                        }
                        nextToken
                      }
                      historys {
                        __typename
                        items {
                          __typename
                          id
                          organizationID
                          noteID
                          note {
                            __typename
                            id
                            organizationID
                            objectID
                            createdAt
                            createdBy
                            updatedAt
                            updatedBy
                            text
                            noteType
                            options
                          }
                          createdAt
                          createdBy
                          text
                          updatedAt
                        }
                        nextToken
                      }
                    }
                    createdAt
                    createdBy
                    text
                    updatedAt
                  }
                  nextToken
                }
              }
              createdAt
              createdBy
              text
              updatedAt
            }
            nextToken
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateNoteMutation>response.data.updateNote;
  }
  async DeleteNote(
    input: DeleteNoteInput,
    condition?: ModelNoteConditionInput
  ): Promise<DeleteNoteMutation> {
    const statement = `mutation DeleteNote($input: DeleteNoteInput!, $condition: ModelNoteConditionInput) {
        deleteNote(input: $input, condition: $condition) {
          __typename
          id
          organizationID
          objectID
          createdAt
          createdBy
          updatedAt
          updatedBy
          text
          noteType
          options
          comments {
            __typename
            items {
              __typename
              id
              organizationID
              noteID
              note {
                __typename
                id
                organizationID
                objectID
                createdAt
                createdBy
                updatedAt
                updatedBy
                text
                noteType
                options
                comments {
                  __typename
                  items {
                    __typename
                    id
                    organizationID
                    noteID
                    note {
                      __typename
                      id
                      organizationID
                      objectID
                      createdAt
                      createdBy
                      updatedAt
                      updatedBy
                      text
                      noteType
                      options
                      comments {
                        __typename
                        items {
                          __typename
                          id
                          organizationID
                          noteID
                          note {
                            __typename
                            id
                            organizationID
                            objectID
                            createdAt
                            createdBy
                            updatedAt
                            updatedBy
                            text
                            noteType
                            options
                          }
                          comment
                          createdAt
                          createdBy
                          updatedAt
                        }
                        nextToken
                      }
                      historys {
                        __typename
                        items {
                          __typename
                          id
                          organizationID
                          noteID
                          note {
                            __typename
                            id
                            organizationID
                            objectID
                            createdAt
                            createdBy
                            updatedAt
                            updatedBy
                            text
                            noteType
                            options
                          }
                          createdAt
                          createdBy
                          text
                          updatedAt
                        }
                        nextToken
                      }
                    }
                    comment
                    createdAt
                    createdBy
                    updatedAt
                  }
                  nextToken
                }
                historys {
                  __typename
                  items {
                    __typename
                    id
                    organizationID
                    noteID
                    note {
                      __typename
                      id
                      organizationID
                      objectID
                      createdAt
                      createdBy
                      updatedAt
                      updatedBy
                      text
                      noteType
                      options
                      comments {
                        __typename
                        items {
                          __typename
                          id
                          organizationID
                          noteID
                          note {
                            __typename
                            id
                            organizationID
                            objectID
                            createdAt
                            createdBy
                            updatedAt
                            updatedBy
                            text
                            noteType
                            options
                          }
                          comment
                          createdAt
                          createdBy
                          updatedAt
                        }
                        nextToken
                      }
                      historys {
                        __typename
                        items {
                          __typename
                          id
                          organizationID
                          noteID
                          note {
                            __typename
                            id
                            organizationID
                            objectID
                            createdAt
                            createdBy
                            updatedAt
                            updatedBy
                            text
                            noteType
                            options
                          }
                          createdAt
                          createdBy
                          text
                          updatedAt
                        }
                        nextToken
                      }
                    }
                    createdAt
                    createdBy
                    text
                    updatedAt
                  }
                  nextToken
                }
              }
              comment
              createdAt
              createdBy
              updatedAt
            }
            nextToken
          }
          historys {
            __typename
            items {
              __typename
              id
              organizationID
              noteID
              note {
                __typename
                id
                organizationID
                objectID
                createdAt
                createdBy
                updatedAt
                updatedBy
                text
                noteType
                options
                comments {
                  __typename
                  items {
                    __typename
                    id
                    organizationID
                    noteID
                    note {
                      __typename
                      id
                      organizationID
                      objectID
                      createdAt
                      createdBy
                      updatedAt
                      updatedBy
                      text
                      noteType
                      options
                      comments {
                        __typename
                        items {
                          __typename
                          id
                          organizationID
                          noteID
                          note {
                            __typename
                            id
                            organizationID
                            objectID
                            createdAt
                            createdBy
                            updatedAt
                            updatedBy
                            text
                            noteType
                            options
                          }
                          comment
                          createdAt
                          createdBy
                          updatedAt
                        }
                        nextToken
                      }
                      historys {
                        __typename
                        items {
                          __typename
                          id
                          organizationID
                          noteID
                          note {
                            __typename
                            id
                            organizationID
                            objectID
                            createdAt
                            createdBy
                            updatedAt
                            updatedBy
                            text
                            noteType
                            options
                          }
                          createdAt
                          createdBy
                          text
                          updatedAt
                        }
                        nextToken
                      }
                    }
                    comment
                    createdAt
                    createdBy
                    updatedAt
                  }
                  nextToken
                }
                historys {
                  __typename
                  items {
                    __typename
                    id
                    organizationID
                    noteID
                    note {
                      __typename
                      id
                      organizationID
                      objectID
                      createdAt
                      createdBy
                      updatedAt
                      updatedBy
                      text
                      noteType
                      options
                      comments {
                        __typename
                        items {
                          __typename
                          id
                          organizationID
                          noteID
                          note {
                            __typename
                            id
                            organizationID
                            objectID
                            createdAt
                            createdBy
                            updatedAt
                            updatedBy
                            text
                            noteType
                            options
                          }
                          comment
                          createdAt
                          createdBy
                          updatedAt
                        }
                        nextToken
                      }
                      historys {
                        __typename
                        items {
                          __typename
                          id
                          organizationID
                          noteID
                          note {
                            __typename
                            id
                            organizationID
                            objectID
                            createdAt
                            createdBy
                            updatedAt
                            updatedBy
                            text
                            noteType
                            options
                          }
                          createdAt
                          createdBy
                          text
                          updatedAt
                        }
                        nextToken
                      }
                    }
                    createdAt
                    createdBy
                    text
                    updatedAt
                  }
                  nextToken
                }
              }
              createdAt
              createdBy
              text
              updatedAt
            }
            nextToken
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteNoteMutation>response.data.deleteNote;
  }
  async CreateNoteHistory(
    input: CreateNoteHistoryInput,
    condition?: ModelNoteHistoryConditionInput
  ): Promise<CreateNoteHistoryMutation> {
    const statement = `mutation CreateNoteHistory($input: CreateNoteHistoryInput!, $condition: ModelNoteHistoryConditionInput) {
        createNoteHistory(input: $input, condition: $condition) {
          __typename
          id
          organizationID
          noteID
          note {
            __typename
            id
            organizationID
            objectID
            createdAt
            createdBy
            updatedAt
            updatedBy
            text
            noteType
            options
            comments {
              __typename
              items {
                __typename
                id
                organizationID
                noteID
                note {
                  __typename
                  id
                  organizationID
                  objectID
                  createdAt
                  createdBy
                  updatedAt
                  updatedBy
                  text
                  noteType
                  options
                  comments {
                    __typename
                    items {
                      __typename
                      id
                      organizationID
                      noteID
                      note {
                        __typename
                        id
                        organizationID
                        objectID
                        createdAt
                        createdBy
                        updatedAt
                        updatedBy
                        text
                        noteType
                        options
                        comments {
                          __typename
                          items {
                            __typename
                            id
                            organizationID
                            noteID
                            comment
                            createdAt
                            createdBy
                            updatedAt
                          }
                          nextToken
                        }
                        historys {
                          __typename
                          items {
                            __typename
                            id
                            organizationID
                            noteID
                            createdAt
                            createdBy
                            text
                            updatedAt
                          }
                          nextToken
                        }
                      }
                      comment
                      createdAt
                      createdBy
                      updatedAt
                    }
                    nextToken
                  }
                  historys {
                    __typename
                    items {
                      __typename
                      id
                      organizationID
                      noteID
                      note {
                        __typename
                        id
                        organizationID
                        objectID
                        createdAt
                        createdBy
                        updatedAt
                        updatedBy
                        text
                        noteType
                        options
                        comments {
                          __typename
                          items {
                            __typename
                            id
                            organizationID
                            noteID
                            comment
                            createdAt
                            createdBy
                            updatedAt
                          }
                          nextToken
                        }
                        historys {
                          __typename
                          items {
                            __typename
                            id
                            organizationID
                            noteID
                            createdAt
                            createdBy
                            text
                            updatedAt
                          }
                          nextToken
                        }
                      }
                      createdAt
                      createdBy
                      text
                      updatedAt
                    }
                    nextToken
                  }
                }
                comment
                createdAt
                createdBy
                updatedAt
              }
              nextToken
            }
            historys {
              __typename
              items {
                __typename
                id
                organizationID
                noteID
                note {
                  __typename
                  id
                  organizationID
                  objectID
                  createdAt
                  createdBy
                  updatedAt
                  updatedBy
                  text
                  noteType
                  options
                  comments {
                    __typename
                    items {
                      __typename
                      id
                      organizationID
                      noteID
                      note {
                        __typename
                        id
                        organizationID
                        objectID
                        createdAt
                        createdBy
                        updatedAt
                        updatedBy
                        text
                        noteType
                        options
                        comments {
                          __typename
                          items {
                            __typename
                            id
                            organizationID
                            noteID
                            comment
                            createdAt
                            createdBy
                            updatedAt
                          }
                          nextToken
                        }
                        historys {
                          __typename
                          items {
                            __typename
                            id
                            organizationID
                            noteID
                            createdAt
                            createdBy
                            text
                            updatedAt
                          }
                          nextToken
                        }
                      }
                      comment
                      createdAt
                      createdBy
                      updatedAt
                    }
                    nextToken
                  }
                  historys {
                    __typename
                    items {
                      __typename
                      id
                      organizationID
                      noteID
                      note {
                        __typename
                        id
                        organizationID
                        objectID
                        createdAt
                        createdBy
                        updatedAt
                        updatedBy
                        text
                        noteType
                        options
                        comments {
                          __typename
                          items {
                            __typename
                            id
                            organizationID
                            noteID
                            comment
                            createdAt
                            createdBy
                            updatedAt
                          }
                          nextToken
                        }
                        historys {
                          __typename
                          items {
                            __typename
                            id
                            organizationID
                            noteID
                            createdAt
                            createdBy
                            text
                            updatedAt
                          }
                          nextToken
                        }
                      }
                      createdAt
                      createdBy
                      text
                      updatedAt
                    }
                    nextToken
                  }
                }
                createdAt
                createdBy
                text
                updatedAt
              }
              nextToken
            }
          }
          createdAt
          createdBy
          text
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateNoteHistoryMutation>response.data.createNoteHistory;
  }
  async UpdateNoteHistory(
    input: UpdateNoteHistoryInput,
    condition?: ModelNoteHistoryConditionInput
  ): Promise<UpdateNoteHistoryMutation> {
    const statement = `mutation UpdateNoteHistory($input: UpdateNoteHistoryInput!, $condition: ModelNoteHistoryConditionInput) {
        updateNoteHistory(input: $input, condition: $condition) {
          __typename
          id
          organizationID
          noteID
          note {
            __typename
            id
            organizationID
            objectID
            createdAt
            createdBy
            updatedAt
            updatedBy
            text
            noteType
            options
            comments {
              __typename
              items {
                __typename
                id
                organizationID
                noteID
                note {
                  __typename
                  id
                  organizationID
                  objectID
                  createdAt
                  createdBy
                  updatedAt
                  updatedBy
                  text
                  noteType
                  options
                  comments {
                    __typename
                    items {
                      __typename
                      id
                      organizationID
                      noteID
                      note {
                        __typename
                        id
                        organizationID
                        objectID
                        createdAt
                        createdBy
                        updatedAt
                        updatedBy
                        text
                        noteType
                        options
                        comments {
                          __typename
                          items {
                            __typename
                            id
                            organizationID
                            noteID
                            comment
                            createdAt
                            createdBy
                            updatedAt
                          }
                          nextToken
                        }
                        historys {
                          __typename
                          items {
                            __typename
                            id
                            organizationID
                            noteID
                            createdAt
                            createdBy
                            text
                            updatedAt
                          }
                          nextToken
                        }
                      }
                      comment
                      createdAt
                      createdBy
                      updatedAt
                    }
                    nextToken
                  }
                  historys {
                    __typename
                    items {
                      __typename
                      id
                      organizationID
                      noteID
                      note {
                        __typename
                        id
                        organizationID
                        objectID
                        createdAt
                        createdBy
                        updatedAt
                        updatedBy
                        text
                        noteType
                        options
                        comments {
                          __typename
                          items {
                            __typename
                            id
                            organizationID
                            noteID
                            comment
                            createdAt
                            createdBy
                            updatedAt
                          }
                          nextToken
                        }
                        historys {
                          __typename
                          items {
                            __typename
                            id
                            organizationID
                            noteID
                            createdAt
                            createdBy
                            text
                            updatedAt
                          }
                          nextToken
                        }
                      }
                      createdAt
                      createdBy
                      text
                      updatedAt
                    }
                    nextToken
                  }
                }
                comment
                createdAt
                createdBy
                updatedAt
              }
              nextToken
            }
            historys {
              __typename
              items {
                __typename
                id
                organizationID
                noteID
                note {
                  __typename
                  id
                  organizationID
                  objectID
                  createdAt
                  createdBy
                  updatedAt
                  updatedBy
                  text
                  noteType
                  options
                  comments {
                    __typename
                    items {
                      __typename
                      id
                      organizationID
                      noteID
                      note {
                        __typename
                        id
                        organizationID
                        objectID
                        createdAt
                        createdBy
                        updatedAt
                        updatedBy
                        text
                        noteType
                        options
                        comments {
                          __typename
                          items {
                            __typename
                            id
                            organizationID
                            noteID
                            comment
                            createdAt
                            createdBy
                            updatedAt
                          }
                          nextToken
                        }
                        historys {
                          __typename
                          items {
                            __typename
                            id
                            organizationID
                            noteID
                            createdAt
                            createdBy
                            text
                            updatedAt
                          }
                          nextToken
                        }
                      }
                      comment
                      createdAt
                      createdBy
                      updatedAt
                    }
                    nextToken
                  }
                  historys {
                    __typename
                    items {
                      __typename
                      id
                      organizationID
                      noteID
                      note {
                        __typename
                        id
                        organizationID
                        objectID
                        createdAt
                        createdBy
                        updatedAt
                        updatedBy
                        text
                        noteType
                        options
                        comments {
                          __typename
                          items {
                            __typename
                            id
                            organizationID
                            noteID
                            comment
                            createdAt
                            createdBy
                            updatedAt
                          }
                          nextToken
                        }
                        historys {
                          __typename
                          items {
                            __typename
                            id
                            organizationID
                            noteID
                            createdAt
                            createdBy
                            text
                            updatedAt
                          }
                          nextToken
                        }
                      }
                      createdAt
                      createdBy
                      text
                      updatedAt
                    }
                    nextToken
                  }
                }
                createdAt
                createdBy
                text
                updatedAt
              }
              nextToken
            }
          }
          createdAt
          createdBy
          text
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateNoteHistoryMutation>response.data.updateNoteHistory;
  }
  async DeleteNoteHistory(
    input: DeleteNoteHistoryInput,
    condition?: ModelNoteHistoryConditionInput
  ): Promise<DeleteNoteHistoryMutation> {
    const statement = `mutation DeleteNoteHistory($input: DeleteNoteHistoryInput!, $condition: ModelNoteHistoryConditionInput) {
        deleteNoteHistory(input: $input, condition: $condition) {
          __typename
          id
          organizationID
          noteID
          note {
            __typename
            id
            organizationID
            objectID
            createdAt
            createdBy
            updatedAt
            updatedBy
            text
            noteType
            options
            comments {
              __typename
              items {
                __typename
                id
                organizationID
                noteID
                note {
                  __typename
                  id
                  organizationID
                  objectID
                  createdAt
                  createdBy
                  updatedAt
                  updatedBy
                  text
                  noteType
                  options
                  comments {
                    __typename
                    items {
                      __typename
                      id
                      organizationID
                      noteID
                      note {
                        __typename
                        id
                        organizationID
                        objectID
                        createdAt
                        createdBy
                        updatedAt
                        updatedBy
                        text
                        noteType
                        options
                        comments {
                          __typename
                          items {
                            __typename
                            id
                            organizationID
                            noteID
                            comment
                            createdAt
                            createdBy
                            updatedAt
                          }
                          nextToken
                        }
                        historys {
                          __typename
                          items {
                            __typename
                            id
                            organizationID
                            noteID
                            createdAt
                            createdBy
                            text
                            updatedAt
                          }
                          nextToken
                        }
                      }
                      comment
                      createdAt
                      createdBy
                      updatedAt
                    }
                    nextToken
                  }
                  historys {
                    __typename
                    items {
                      __typename
                      id
                      organizationID
                      noteID
                      note {
                        __typename
                        id
                        organizationID
                        objectID
                        createdAt
                        createdBy
                        updatedAt
                        updatedBy
                        text
                        noteType
                        options
                        comments {
                          __typename
                          items {
                            __typename
                            id
                            organizationID
                            noteID
                            comment
                            createdAt
                            createdBy
                            updatedAt
                          }
                          nextToken
                        }
                        historys {
                          __typename
                          items {
                            __typename
                            id
                            organizationID
                            noteID
                            createdAt
                            createdBy
                            text
                            updatedAt
                          }
                          nextToken
                        }
                      }
                      createdAt
                      createdBy
                      text
                      updatedAt
                    }
                    nextToken
                  }
                }
                comment
                createdAt
                createdBy
                updatedAt
              }
              nextToken
            }
            historys {
              __typename
              items {
                __typename
                id
                organizationID
                noteID
                note {
                  __typename
                  id
                  organizationID
                  objectID
                  createdAt
                  createdBy
                  updatedAt
                  updatedBy
                  text
                  noteType
                  options
                  comments {
                    __typename
                    items {
                      __typename
                      id
                      organizationID
                      noteID
                      note {
                        __typename
                        id
                        organizationID
                        objectID
                        createdAt
                        createdBy
                        updatedAt
                        updatedBy
                        text
                        noteType
                        options
                        comments {
                          __typename
                          items {
                            __typename
                            id
                            organizationID
                            noteID
                            comment
                            createdAt
                            createdBy
                            updatedAt
                          }
                          nextToken
                        }
                        historys {
                          __typename
                          items {
                            __typename
                            id
                            organizationID
                            noteID
                            createdAt
                            createdBy
                            text
                            updatedAt
                          }
                          nextToken
                        }
                      }
                      comment
                      createdAt
                      createdBy
                      updatedAt
                    }
                    nextToken
                  }
                  historys {
                    __typename
                    items {
                      __typename
                      id
                      organizationID
                      noteID
                      note {
                        __typename
                        id
                        organizationID
                        objectID
                        createdAt
                        createdBy
                        updatedAt
                        updatedBy
                        text
                        noteType
                        options
                        comments {
                          __typename
                          items {
                            __typename
                            id
                            organizationID
                            noteID
                            comment
                            createdAt
                            createdBy
                            updatedAt
                          }
                          nextToken
                        }
                        historys {
                          __typename
                          items {
                            __typename
                            id
                            organizationID
                            noteID
                            createdAt
                            createdBy
                            text
                            updatedAt
                          }
                          nextToken
                        }
                      }
                      createdAt
                      createdBy
                      text
                      updatedAt
                    }
                    nextToken
                  }
                }
                createdAt
                createdBy
                text
                updatedAt
              }
              nextToken
            }
          }
          createdAt
          createdBy
          text
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteNoteHistoryMutation>response.data.deleteNoteHistory;
  }
  async CreateShoutOutType(
    input: CreateShoutOutTypeInput,
    condition?: ModelShoutOutTypeConditionInput
  ): Promise<CreateShoutOutTypeMutation> {
    const statement = `mutation CreateShoutOutType($input: CreateShoutOutTypeInput!, $condition: ModelShoutOutTypeConditionInput) {
        createShoutOutType(input: $input, condition: $condition) {
          __typename
          id
          title
          formalDescription
          informalDescription
          icon
          attributes
          status
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateShoutOutTypeMutation>response.data.createShoutOutType;
  }
  async UpdateShoutOutType(
    input: UpdateShoutOutTypeInput,
    condition?: ModelShoutOutTypeConditionInput
  ): Promise<UpdateShoutOutTypeMutation> {
    const statement = `mutation UpdateShoutOutType($input: UpdateShoutOutTypeInput!, $condition: ModelShoutOutTypeConditionInput) {
        updateShoutOutType(input: $input, condition: $condition) {
          __typename
          id
          title
          formalDescription
          informalDescription
          icon
          attributes
          status
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateShoutOutTypeMutation>response.data.updateShoutOutType;
  }
  async DeleteShoutOutType(
    input: DeleteShoutOutTypeInput,
    condition?: ModelShoutOutTypeConditionInput
  ): Promise<DeleteShoutOutTypeMutation> {
    const statement = `mutation DeleteShoutOutType($input: DeleteShoutOutTypeInput!, $condition: ModelShoutOutTypeConditionInput) {
        deleteShoutOutType(input: $input, condition: $condition) {
          __typename
          id
          title
          formalDescription
          informalDescription
          icon
          attributes
          status
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteShoutOutTypeMutation>response.data.deleteShoutOutType;
  }
  async CreateOrganizationShoutOutType(
    input: CreateOrganizationShoutOutTypeInput,
    condition?: ModelOrganizationShoutOutTypeConditionInput
  ): Promise<CreateOrganizationShoutOutTypeMutation> {
    const statement = `mutation CreateOrganizationShoutOutType($input: CreateOrganizationShoutOutTypeInput!, $condition: ModelOrganizationShoutOutTypeConditionInput) {
        createOrganizationShoutOutType(input: $input, condition: $condition) {
          __typename
          id
          organizationID
          overrideTitle
          organizationShoutOutTypeShoutOuTypeId
          shoutOutType {
            __typename
            id
            title
            formalDescription
            informalDescription
            icon
            attributes
            status
            createdAt
            updatedAt
          }
          description
          overrideIcon
          overrideIconIdentityId
          enabled
          organizationAttributes
          disabledAttributes
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateOrganizationShoutOutTypeMutation>(
      response.data.createOrganizationShoutOutType
    );
  }
  async UpdateOrganizationShoutOutType(
    input: UpdateOrganizationShoutOutTypeInput,
    condition?: ModelOrganizationShoutOutTypeConditionInput
  ): Promise<UpdateOrganizationShoutOutTypeMutation> {
    const statement = `mutation UpdateOrganizationShoutOutType($input: UpdateOrganizationShoutOutTypeInput!, $condition: ModelOrganizationShoutOutTypeConditionInput) {
        updateOrganizationShoutOutType(input: $input, condition: $condition) {
          __typename
          id
          organizationID
          overrideTitle
          organizationShoutOutTypeShoutOuTypeId
          shoutOutType {
            __typename
            id
            title
            formalDescription
            informalDescription
            icon
            attributes
            status
            createdAt
            updatedAt
          }
          description
          overrideIcon
          overrideIconIdentityId
          enabled
          organizationAttributes
          disabledAttributes
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateOrganizationShoutOutTypeMutation>(
      response.data.updateOrganizationShoutOutType
    );
  }
  async DeleteOrganizationShoutOutType(
    input: DeleteOrganizationShoutOutTypeInput,
    condition?: ModelOrganizationShoutOutTypeConditionInput
  ): Promise<DeleteOrganizationShoutOutTypeMutation> {
    const statement = `mutation DeleteOrganizationShoutOutType($input: DeleteOrganizationShoutOutTypeInput!, $condition: ModelOrganizationShoutOutTypeConditionInput) {
        deleteOrganizationShoutOutType(input: $input, condition: $condition) {
          __typename
          id
          organizationID
          overrideTitle
          organizationShoutOutTypeShoutOuTypeId
          shoutOutType {
            __typename
            id
            title
            formalDescription
            informalDescription
            icon
            attributes
            status
            createdAt
            updatedAt
          }
          description
          overrideIcon
          overrideIconIdentityId
          enabled
          organizationAttributes
          disabledAttributes
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteOrganizationShoutOutTypeMutation>(
      response.data.deleteOrganizationShoutOutType
    );
  }
  async CreateStandupSurveyConfiguration(
    input: CreateStandupSurveyConfigurationInput,
    condition?: ModelStandupSurveyConfigurationConditionInput
  ): Promise<CreateStandupSurveyConfigurationMutation> {
    const statement = `mutation CreateStandupSurveyConfiguration($input: CreateStandupSurveyConfigurationInput!, $condition: ModelStandupSurveyConfigurationConditionInput) {
        createStandupSurveyConfiguration(input: $input, condition: $condition) {
          __typename
          id
          organizationID
          employeeID
          questions {
            __typename
            id
            questionTextArray
            enabled
          }
          shared
          excluded
          frequency
          lastSurveyedDate
          isoWeekday
          status
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateStandupSurveyConfigurationMutation>(
      response.data.createStandupSurveyConfiguration
    );
  }
  async UpdateStandupSurveyConfiguration(
    input: UpdateStandupSurveyConfigurationInput,
    condition?: ModelStandupSurveyConfigurationConditionInput
  ): Promise<UpdateStandupSurveyConfigurationMutation> {
    const statement = `mutation UpdateStandupSurveyConfiguration($input: UpdateStandupSurveyConfigurationInput!, $condition: ModelStandupSurveyConfigurationConditionInput) {
        updateStandupSurveyConfiguration(input: $input, condition: $condition) {
          __typename
          id
          organizationID
          employeeID
          questions {
            __typename
            id
            questionTextArray
            enabled
          }
          shared
          excluded
          frequency
          lastSurveyedDate
          isoWeekday
          status
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateStandupSurveyConfigurationMutation>(
      response.data.updateStandupSurveyConfiguration
    );
  }
  async DeleteStandupSurveyConfiguration(
    input: DeleteStandupSurveyConfigurationInput,
    condition?: ModelStandupSurveyConfigurationConditionInput
  ): Promise<DeleteStandupSurveyConfigurationMutation> {
    const statement = `mutation DeleteStandupSurveyConfiguration($input: DeleteStandupSurveyConfigurationInput!, $condition: ModelStandupSurveyConfigurationConditionInput) {
        deleteStandupSurveyConfiguration(input: $input, condition: $condition) {
          __typename
          id
          organizationID
          employeeID
          questions {
            __typename
            id
            questionTextArray
            enabled
          }
          shared
          excluded
          frequency
          lastSurveyedDate
          isoWeekday
          status
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteStandupSurveyConfigurationMutation>(
      response.data.deleteStandupSurveyConfiguration
    );
  }
  async CreateStandupSurvey(
    input: CreateStandupSurveyInput,
    condition?: ModelStandupSurveyConditionInput
  ): Promise<CreateStandupSurveyMutation> {
    const statement = `mutation CreateStandupSurvey($input: CreateStandupSurveyInput!, $condition: ModelStandupSurveyConditionInput) {
        createStandupSurvey(input: $input, condition: $condition) {
          __typename
          id
          organizationID
          standupSurveyConfigurationID
          questionMaps {
            __typename
            id
            version
          }
          respondentEmail
          respondentID
          surveyDate
          feedbackArray
          chatRequest
          meetingRequest
          responseReceived
          responseReceivedDate
          status
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateStandupSurveyMutation>response.data.createStandupSurvey;
  }
  async UpdateStandupSurvey(
    input: UpdateStandupSurveyInput,
    condition?: ModelStandupSurveyConditionInput
  ): Promise<UpdateStandupSurveyMutation> {
    const statement = `mutation UpdateStandupSurvey($input: UpdateStandupSurveyInput!, $condition: ModelStandupSurveyConditionInput) {
        updateStandupSurvey(input: $input, condition: $condition) {
          __typename
          id
          organizationID
          standupSurveyConfigurationID
          questionMaps {
            __typename
            id
            version
          }
          respondentEmail
          respondentID
          surveyDate
          feedbackArray
          chatRequest
          meetingRequest
          responseReceived
          responseReceivedDate
          status
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateStandupSurveyMutation>response.data.updateStandupSurvey;
  }
  async DeleteStandupSurvey(
    input: DeleteStandupSurveyInput,
    condition?: ModelStandupSurveyConditionInput
  ): Promise<DeleteStandupSurveyMutation> {
    const statement = `mutation DeleteStandupSurvey($input: DeleteStandupSurveyInput!, $condition: ModelStandupSurveyConditionInput) {
        deleteStandupSurvey(input: $input, condition: $condition) {
          __typename
          id
          organizationID
          standupSurveyConfigurationID
          questionMaps {
            __typename
            id
            version
          }
          respondentEmail
          respondentID
          surveyDate
          feedbackArray
          chatRequest
          meetingRequest
          responseReceived
          responseReceivedDate
          status
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteStandupSurveyMutation>response.data.deleteStandupSurvey;
  }
  async CreateDoNotDisturbRequest(
    input: CreateDoNotDisturbRequestInput,
    condition?: ModelDoNotDisturbRequestConditionInput
  ): Promise<CreateDoNotDisturbRequestMutation> {
    const statement = `mutation CreateDoNotDisturbRequest($input: CreateDoNotDisturbRequestInput!, $condition: ModelDoNotDisturbRequestConditionInput) {
        createDoNotDisturbRequest(input: $input, condition: $condition) {
          __typename
          id
          externalRespondentID
          externalRespondentEmail
          ipAddress
          status
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateDoNotDisturbRequestMutation>(
      response.data.createDoNotDisturbRequest
    );
  }
  async UpdateExternalSurvey(
    input: UpdateExternalSurveyInput,
    condition?: ModelExternalSurveyConditionInput
  ): Promise<UpdateExternalSurveyMutation> {
    const statement = `mutation UpdateExternalSurvey($input: UpdateExternalSurveyInput!, $condition: ModelExternalSurveyConditionInput) {
        updateExternalSurvey(input: $input, condition: $condition) {
          __typename
          id
          surveyType
          surveyDate
          externalRespondentID
          participant {
            __typename
            id
            participantName
          }
          objectiveDetails {
            __typename
            id
            objectiveDisplay
            objectiveDefinition
            businessObjective
            managerDescription
            objectiveObservables
          }
          status
          createdAt
          updatedAt
          respondentAttended
          surveyResponse
          feedback
          responseReceived
          responseReceivedDate
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateExternalSurveyMutation>response.data.updateExternalSurvey;
  }
  async GetOrganization(id: string): Promise<GetOrganizationQuery> {
    const statement = `query GetOrganization($id: ID!) {
        getOrganization(id: $id) {
          __typename
          id
          orgName
          domains
          site
          disabled
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetOrganizationQuery>response.data.getOrganization;
  }
  async ListOrganizations(
    filter?: ModelOrganizationFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListOrganizationsQuery> {
    const statement = `query ListOrganizations($filter: ModelOrganizationFilterInput, $limit: Int, $nextToken: String) {
        listOrganizations(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            orgName
            domains
            site
            disabled
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListOrganizationsQuery>response.data.listOrganizations;
  }
  async GetStripe(organizationID: string): Promise<GetStripeQuery> {
    const statement = `query GetStripe($organizationID: String!) {
        getStripe(organizationID: $organizationID) {
          __typename
          organizationID
          customerID
          subscriptionID
          subscriptionItemID
          planID
          billingName
          billingEmail
          cardToken
          subscriptionStatus
          hmac
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      organizationID
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetStripeQuery>response.data.getStripe;
  }
  async ListStripes(
    organizationID?: string,
    filter?: ModelStripeFilterInput,
    limit?: number,
    nextToken?: string,
    sortDirection?: ModelSortDirection
  ): Promise<ListStripesQuery> {
    const statement = `query ListStripes($organizationID: String, $filter: ModelStripeFilterInput, $limit: Int, $nextToken: String, $sortDirection: ModelSortDirection) {
        listStripes(
          organizationID: $organizationID
          filter: $filter
          limit: $limit
          nextToken: $nextToken
          sortDirection: $sortDirection
        ) {
          __typename
          items {
            __typename
            organizationID
            customerID
            subscriptionID
            subscriptionItemID
            planID
            billingName
            billingEmail
            cardToken
            subscriptionStatus
            hmac
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (organizationID) {
      gqlAPIServiceArguments.organizationID = organizationID;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListStripesQuery>response.data.listStripes;
  }
  async GetProcessLog(process: string): Promise<GetProcessLogQuery> {
    const statement = `query GetProcessLog($process: String!) {
        getProcessLog(process: $process) {
          __typename
          process
          lastRunTime
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      process
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetProcessLogQuery>response.data.getProcessLog;
  }
  async ListProcessLogs(
    process?: string,
    filter?: ModelProcessLogFilterInput,
    limit?: number,
    nextToken?: string,
    sortDirection?: ModelSortDirection
  ): Promise<ListProcessLogsQuery> {
    const statement = `query ListProcessLogs($process: String, $filter: ModelProcessLogFilterInput, $limit: Int, $nextToken: String, $sortDirection: ModelSortDirection) {
        listProcessLogs(
          process: $process
          filter: $filter
          limit: $limit
          nextToken: $nextToken
          sortDirection: $sortDirection
        ) {
          __typename
          items {
            __typename
            process
            lastRunTime
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (process) {
      gqlAPIServiceArguments.process = process;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListProcessLogsQuery>response.data.listProcessLogs;
  }
  async GetOrganizationConfiguration(
    id: string
  ): Promise<GetOrganizationConfigurationQuery> {
    const statement = `query GetOrganizationConfiguration($id: ID!) {
        getOrganizationConfiguration(id: $id) {
          __typename
          id
          organizationID
          configCode
          configStringValue
          configIntValue
          configBooleanValue
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetOrganizationConfigurationQuery>(
      response.data.getOrganizationConfiguration
    );
  }
  async ListOrganizationConfigurations(
    filter?: ModelOrganizationConfigurationFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListOrganizationConfigurationsQuery> {
    const statement = `query ListOrganizationConfigurations($filter: ModelOrganizationConfigurationFilterInput, $limit: Int, $nextToken: String) {
        listOrganizationConfigurations(
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            organizationID
            configCode
            configStringValue
            configIntValue
            configBooleanValue
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListOrganizationConfigurationsQuery>(
      response.data.listOrganizationConfigurations
    );
  }
  async GetEmployee(id: string): Promise<GetEmployeeQuery> {
    const statement = `query GetEmployee($id: ID!) {
        getEmployee(id: $id) {
          __typename
          id
          authId
          email
          orgId
          groups
          consulting
          managerID
          subordinates
          roles
          firstName
          lastName
          title
          department
          disabled
          disabledDate
          disabledBy
          hireDate
          managerChangeDate
          lastAnnual
          lastOneOnOne
          positionDate
          previousPositionStartDate
          previousPositionTitle
          validEmailToken
          inviteSent
          readiness
          criticalPosition
          createdAt
          updatedAt
          readinessAssessmentDate
          image
          imageIdentityId
          bio
          careerGoals
          relocate
          relocateDate
          workStatus
          workStatusDate
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetEmployeeQuery>response.data.getEmployee;
  }
  async ListEmployees(
    filter?: ModelEmployeeFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListEmployeesQuery> {
    const statement = `query ListEmployees($filter: ModelEmployeeFilterInput, $limit: Int, $nextToken: String) {
        listEmployees(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            authId
            email
            orgId
            groups
            consulting
            managerID
            subordinates
            roles
            firstName
            lastName
            title
            department
            disabled
            disabledDate
            disabledBy
            hireDate
            managerChangeDate
            lastAnnual
            lastOneOnOne
            positionDate
            previousPositionStartDate
            previousPositionTitle
            validEmailToken
            inviteSent
            readiness
            criticalPosition
            createdAt
            updatedAt
            readinessAssessmentDate
            image
            imageIdentityId
            bio
            careerGoals
            relocate
            relocateDate
            workStatus
            workStatusDate
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListEmployeesQuery>response.data.listEmployees;
  }
  async GetEmployeeData(
    employeeID: string,
    dataCode: string
  ): Promise<GetEmployeeDataQuery> {
    const statement = `query GetEmployeeData($employeeID: String!, $dataCode: String!) {
        getEmployeeData(employeeID: $employeeID, dataCode: $dataCode) {
          __typename
          employeeID
          organizationID
          dataCode
          groups
          stringValue
          intValue
          booleanValue
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      employeeID,
      dataCode
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetEmployeeDataQuery>response.data.getEmployeeData;
  }
  async ListEmployeeDatas(
    employeeID?: string,
    dataCode?: ModelStringKeyConditionInput,
    filter?: ModelEmployeeDataFilterInput,
    limit?: number,
    nextToken?: string,
    sortDirection?: ModelSortDirection
  ): Promise<ListEmployeeDatasQuery> {
    const statement = `query ListEmployeeDatas($employeeID: String, $dataCode: ModelStringKeyConditionInput, $filter: ModelEmployeeDataFilterInput, $limit: Int, $nextToken: String, $sortDirection: ModelSortDirection) {
        listEmployeeDatas(
          employeeID: $employeeID
          dataCode: $dataCode
          filter: $filter
          limit: $limit
          nextToken: $nextToken
          sortDirection: $sortDirection
        ) {
          __typename
          items {
            __typename
            employeeID
            organizationID
            dataCode
            groups
            stringValue
            intValue
            booleanValue
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (employeeID) {
      gqlAPIServiceArguments.employeeID = employeeID;
    }
    if (dataCode) {
      gqlAPIServiceArguments.dataCode = dataCode;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListEmployeeDatasQuery>response.data.listEmployeeDatas;
  }
  async GetReadinessHistory(id: string): Promise<GetReadinessHistoryQuery> {
    const statement = `query GetReadinessHistory($id: ID!) {
        getReadinessHistory(id: $id) {
          __typename
          id
          organizationID
          groups
          employeeID
          readiness
          comments
          opportunities
          createdAt
          createdBy
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetReadinessHistoryQuery>response.data.getReadinessHistory;
  }
  async ListReadinessHistorys(
    filter?: ModelReadinessHistoryFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListReadinessHistorysQuery> {
    const statement = `query ListReadinessHistorys($filter: ModelReadinessHistoryFilterInput, $limit: Int, $nextToken: String) {
        listReadinessHistorys(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            organizationID
            groups
            employeeID
            readiness
            comments
            opportunities
            createdAt
            createdBy
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListReadinessHistorysQuery>response.data.listReadinessHistorys;
  }
  async GetConsultantAssociation(
    organizationID: string,
    employeeID: string
  ): Promise<GetConsultantAssociationQuery> {
    const statement = `query GetConsultantAssociation($organizationID: String!, $employeeID: String!) {
        getConsultantAssociation(
          organizationID: $organizationID
          employeeID: $employeeID
        ) {
          __typename
          employeeID
          organizationID
          incentive
          status
          options
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      organizationID,
      employeeID
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetConsultantAssociationQuery>(
      response.data.getConsultantAssociation
    );
  }
  async ListConsultantAssociations(
    organizationID?: string,
    employeeID?: ModelStringKeyConditionInput,
    filter?: ModelConsultantAssociationFilterInput,
    limit?: number,
    nextToken?: string,
    sortDirection?: ModelSortDirection
  ): Promise<ListConsultantAssociationsQuery> {
    const statement = `query ListConsultantAssociations($organizationID: String, $employeeID: ModelStringKeyConditionInput, $filter: ModelConsultantAssociationFilterInput, $limit: Int, $nextToken: String, $sortDirection: ModelSortDirection) {
        listConsultantAssociations(
          organizationID: $organizationID
          employeeID: $employeeID
          filter: $filter
          limit: $limit
          nextToken: $nextToken
          sortDirection: $sortDirection
        ) {
          __typename
          items {
            __typename
            employeeID
            organizationID
            incentive
            status
            options
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (organizationID) {
      gqlAPIServiceArguments.organizationID = organizationID;
    }
    if (employeeID) {
      gqlAPIServiceArguments.employeeID = employeeID;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListConsultantAssociationsQuery>(
      response.data.listConsultantAssociations
    );
  }
  async GetConsultantFavorites(
    organizationID: string,
    employeeID: string
  ): Promise<GetConsultantFavoritesQuery> {
    const statement = `query GetConsultantFavorites($organizationID: String!, $employeeID: String!) {
        getConsultantFavorites(organizationID: $organizationID, employeeID: $employeeID) {
          __typename
          employeeID
          organizationID
          favorites
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      organizationID,
      employeeID
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetConsultantFavoritesQuery>response.data.getConsultantFavorites;
  }
  async ListConsultantFavoritess(
    organizationID?: string,
    employeeID?: ModelStringKeyConditionInput,
    filter?: ModelConsultantFavoritesFilterInput,
    limit?: number,
    nextToken?: string,
    sortDirection?: ModelSortDirection
  ): Promise<ListConsultantFavoritessQuery> {
    const statement = `query ListConsultantFavoritess($organizationID: String, $employeeID: ModelStringKeyConditionInput, $filter: ModelConsultantFavoritesFilterInput, $limit: Int, $nextToken: String, $sortDirection: ModelSortDirection) {
        listConsultantFavoritess(
          organizationID: $organizationID
          employeeID: $employeeID
          filter: $filter
          limit: $limit
          nextToken: $nextToken
          sortDirection: $sortDirection
        ) {
          __typename
          items {
            __typename
            employeeID
            organizationID
            favorites
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (organizationID) {
      gqlAPIServiceArguments.organizationID = organizationID;
    }
    if (employeeID) {
      gqlAPIServiceArguments.employeeID = employeeID;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListConsultantFavoritessQuery>(
      response.data.listConsultantFavoritess
    );
  }
  async GetUserAction(employeeID: string): Promise<GetUserActionQuery> {
    const statement = `query GetUserAction($employeeID: String!) {
        getUserAction(employeeID: $employeeID) {
          __typename
          employeeID
          organizationID
          action
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      employeeID
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetUserActionQuery>response.data.getUserAction;
  }
  async ListUserActions(
    employeeID?: string,
    filter?: ModelUserActionFilterInput,
    limit?: number,
    nextToken?: string,
    sortDirection?: ModelSortDirection
  ): Promise<ListUserActionsQuery> {
    const statement = `query ListUserActions($employeeID: String, $filter: ModelUserActionFilterInput, $limit: Int, $nextToken: String, $sortDirection: ModelSortDirection) {
        listUserActions(
          employeeID: $employeeID
          filter: $filter
          limit: $limit
          nextToken: $nextToken
          sortDirection: $sortDirection
        ) {
          __typename
          items {
            __typename
            employeeID
            organizationID
            action
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (employeeID) {
      gqlAPIServiceArguments.employeeID = employeeID;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListUserActionsQuery>response.data.listUserActions;
  }
  async GetEmployeeCalendarCode(
    employeeID: string
  ): Promise<GetEmployeeCalendarCodeQuery> {
    const statement = `query GetEmployeeCalendarCode($employeeID: String!) {
        getEmployeeCalendarCode(employeeID: $employeeID) {
          __typename
          employeeID
          code
          redirectUrl
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      employeeID
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetEmployeeCalendarCodeQuery>response.data.getEmployeeCalendarCode;
  }
  async ListEmployeeCalendarCodes(
    employeeID?: string,
    filter?: ModelEmployeeCalendarCodeFilterInput,
    limit?: number,
    nextToken?: string,
    sortDirection?: ModelSortDirection
  ): Promise<ListEmployeeCalendarCodesQuery> {
    const statement = `query ListEmployeeCalendarCodes($employeeID: String, $filter: ModelEmployeeCalendarCodeFilterInput, $limit: Int, $nextToken: String, $sortDirection: ModelSortDirection) {
        listEmployeeCalendarCodes(
          employeeID: $employeeID
          filter: $filter
          limit: $limit
          nextToken: $nextToken
          sortDirection: $sortDirection
        ) {
          __typename
          items {
            __typename
            employeeID
            code
            redirectUrl
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (employeeID) {
      gqlAPIServiceArguments.employeeID = employeeID;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListEmployeeCalendarCodesQuery>(
      response.data.listEmployeeCalendarCodes
    );
  }
  async GetEmployeeScore(
    organizationID: string,
    scoreType: string,
    scoreID: string,
    id: string
  ): Promise<GetEmployeeScoreQuery> {
    const statement = `query GetEmployeeScore($organizationID: String!, $scoreType: String!, $scoreID: String!, $id: ID!) {
        getEmployeeScore(
          organizationID: $organizationID
          scoreType: $scoreType
          scoreID: $scoreID
          id: $id
        ) {
          __typename
          id
          employeeID
          organizationID
          score
          scoreType
          scoreID
          scoreStart
          scoreEnd
          specifier
          details
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      organizationID,
      scoreType,
      scoreID,
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetEmployeeScoreQuery>response.data.getEmployeeScore;
  }
  async ListEmployeeScores(
    organizationID?: string,
    scoreTypeScoreIDId?: ModelEmployeeScorePrimaryCompositeKeyConditionInput,
    filter?: ModelEmployeeScoreFilterInput,
    limit?: number,
    nextToken?: string,
    sortDirection?: ModelSortDirection
  ): Promise<ListEmployeeScoresQuery> {
    const statement = `query ListEmployeeScores($organizationID: String, $scoreTypeScoreIDId: ModelEmployeeScorePrimaryCompositeKeyConditionInput, $filter: ModelEmployeeScoreFilterInput, $limit: Int, $nextToken: String, $sortDirection: ModelSortDirection) {
        listEmployeeScores(
          organizationID: $organizationID
          scoreTypeScoreIDId: $scoreTypeScoreIDId
          filter: $filter
          limit: $limit
          nextToken: $nextToken
          sortDirection: $sortDirection
        ) {
          __typename
          items {
            __typename
            id
            employeeID
            organizationID
            score
            scoreType
            scoreID
            scoreStart
            scoreEnd
            specifier
            details
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (organizationID) {
      gqlAPIServiceArguments.organizationID = organizationID;
    }
    if (scoreTypeScoreIDId) {
      gqlAPIServiceArguments.scoreTypeScoreIDId = scoreTypeScoreIDId;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListEmployeeScoresQuery>response.data.listEmployeeScores;
  }
  async GetInspHRationsResponseCounter(
    employeeID: string,
    organizationShoutOutTypeID: string,
    type: InspHRationCounterType,
    group: string
  ): Promise<GetInspHRationsResponseCounterQuery> {
    const statement = `query GetInspHRationsResponseCounter($employeeID: String!, $organizationShoutOutTypeID: String!, $type: InspHRationCounterType!, $group: String!) {
        getInspHRationsResponseCounter(
          employeeID: $employeeID
          organizationShoutOutTypeID: $organizationShoutOutTypeID
          type: $type
          group: $group
        ) {
          __typename
          organizationID
          employeeID
          organizationShoutOutTypeID
          type
          group
          count
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      employeeID,
      organizationShoutOutTypeID,
      type,
      group
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetInspHRationsResponseCounterQuery>(
      response.data.getInspHRationsResponseCounter
    );
  }
  async ListInspHRationsResponseCounters(
    employeeID?: string,
    organizationShoutOutTypeIDTypeGroup?: ModelInspHRationsResponseCounterPrimaryCompositeKeyConditionInput,
    filter?: ModelInspHRationsResponseCounterFilterInput,
    limit?: number,
    nextToken?: string,
    sortDirection?: ModelSortDirection
  ): Promise<ListInspHRationsResponseCountersQuery> {
    const statement = `query ListInspHRationsResponseCounters($employeeID: String, $organizationShoutOutTypeIDTypeGroup: ModelInspHRationsResponseCounterPrimaryCompositeKeyConditionInput, $filter: ModelInspHRationsResponseCounterFilterInput, $limit: Int, $nextToken: String, $sortDirection: ModelSortDirection) {
        listInspHRationsResponseCounters(
          employeeID: $employeeID
          organizationShoutOutTypeIDTypeGroup: $organizationShoutOutTypeIDTypeGroup
          filter: $filter
          limit: $limit
          nextToken: $nextToken
          sortDirection: $sortDirection
        ) {
          __typename
          items {
            __typename
            organizationID
            employeeID
            organizationShoutOutTypeID
            type
            group
            count
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (employeeID) {
      gqlAPIServiceArguments.employeeID = employeeID;
    }
    if (organizationShoutOutTypeIDTypeGroup) {
      gqlAPIServiceArguments.organizationShoutOutTypeIDTypeGroup = organizationShoutOutTypeIDTypeGroup;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListInspHRationsResponseCountersQuery>(
      response.data.listInspHRationsResponseCounters
    );
  }
  async GetInspHRationsOrganizationResponseCounter(
    organizationID: string,
    employeeID: string,
    organizationShoutOutTypeID: string,
    type: InspHRationCounterType,
    group: string
  ): Promise<GetInspHRationsOrganizationResponseCounterQuery> {
    const statement = `query GetInspHRationsOrganizationResponseCounter($organizationID: String!, $employeeID: String!, $organizationShoutOutTypeID: String!, $type: InspHRationCounterType!, $group: String!) {
        getInspHRationsOrganizationResponseCounter(
          organizationID: $organizationID
          employeeID: $employeeID
          organizationShoutOutTypeID: $organizationShoutOutTypeID
          type: $type
          group: $group
        ) {
          __typename
          organizationID
          employeeID
          organizationShoutOutTypeID
          type
          group
          count
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      organizationID,
      employeeID,
      organizationShoutOutTypeID,
      type,
      group
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetInspHRationsOrganizationResponseCounterQuery>(
      response.data.getInspHRationsOrganizationResponseCounter
    );
  }
  async ListInspHRationsOrganizationResponseCounters(
    organizationID?: string,
    employeeIDOrganizationShoutOutTypeIDTypeGroup?: ModelInspHRationsOrganizationResponseCounterPrimaryCompositeKeyConditionInput,
    filter?: ModelInspHRationsOrganizationResponseCounterFilterInput,
    limit?: number,
    nextToken?: string,
    sortDirection?: ModelSortDirection
  ): Promise<ListInspHRationsOrganizationResponseCountersQuery> {
    const statement = `query ListInspHRationsOrganizationResponseCounters($organizationID: String, $employeeIDOrganizationShoutOutTypeIDTypeGroup: ModelInspHRationsOrganizationResponseCounterPrimaryCompositeKeyConditionInput, $filter: ModelInspHRationsOrganizationResponseCounterFilterInput, $limit: Int, $nextToken: String, $sortDirection: ModelSortDirection) {
        listInspHRationsOrganizationResponseCounters(
          organizationID: $organizationID
          employeeIDOrganizationShoutOutTypeIDTypeGroup: $employeeIDOrganizationShoutOutTypeIDTypeGroup
          filter: $filter
          limit: $limit
          nextToken: $nextToken
          sortDirection: $sortDirection
        ) {
          __typename
          items {
            __typename
            organizationID
            employeeID
            organizationShoutOutTypeID
            type
            group
            count
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (organizationID) {
      gqlAPIServiceArguments.organizationID = organizationID;
    }
    if (employeeIDOrganizationShoutOutTypeIDTypeGroup) {
      gqlAPIServiceArguments.employeeIDOrganizationShoutOutTypeIDTypeGroup = employeeIDOrganizationShoutOutTypeIDTypeGroup;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListInspHRationsOrganizationResponseCountersQuery>(
      response.data.listInspHRationsOrganizationResponseCounters
    );
  }
  async GetIntHRactionCounter(
    employeeID: string,
    participantID: string
  ): Promise<GetIntHRactionCounterQuery> {
    const statement = `query GetIntHRactionCounter($employeeID: String!, $participantID: String!) {
        getIntHRactionCounter(employeeID: $employeeID, participantID: $participantID) {
          __typename
          employeeID
          participantID
          organizationID
          count
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      employeeID,
      participantID
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetIntHRactionCounterQuery>response.data.getIntHRactionCounter;
  }
  async ListIntHRactionCounters(
    employeeID?: string,
    participantID?: ModelStringKeyConditionInput,
    filter?: ModelIntHRactionCounterFilterInput,
    limit?: number,
    nextToken?: string,
    sortDirection?: ModelSortDirection
  ): Promise<ListIntHRactionCountersQuery> {
    const statement = `query ListIntHRactionCounters($employeeID: String, $participantID: ModelStringKeyConditionInput, $filter: ModelIntHRactionCounterFilterInput, $limit: Int, $nextToken: String, $sortDirection: ModelSortDirection) {
        listIntHRactionCounters(
          employeeID: $employeeID
          participantID: $participantID
          filter: $filter
          limit: $limit
          nextToken: $nextToken
          sortDirection: $sortDirection
        ) {
          __typename
          items {
            __typename
            employeeID
            participantID
            organizationID
            count
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (employeeID) {
      gqlAPIServiceArguments.employeeID = employeeID;
    }
    if (participantID) {
      gqlAPIServiceArguments.participantID = participantID;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListIntHRactionCountersQuery>response.data.listIntHRactionCounters;
  }
  async GetPriority(id: string): Promise<GetPriorityQuery> {
    const statement = `query GetPriority($id: ID!) {
        getPriority(id: $id) {
          __typename
          id
          key
          display
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetPriorityQuery>response.data.getPriority;
  }
  async ListPrioritys(
    filter?: ModelPriorityFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListPrioritysQuery> {
    const statement = `query ListPrioritys($filter: ModelPriorityFilterInput, $limit: Int, $nextToken: String) {
        listPrioritys(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            key
            display
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListPrioritysQuery>response.data.listPrioritys;
  }
  async GetFocus(id: string): Promise<GetFocusQuery> {
    const statement = `query GetFocus($id: ID!) {
        getFocus(id: $id) {
          __typename
          id
          key
          display
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetFocusQuery>response.data.getFocus;
  }
  async ListFocuss(
    filter?: ModelFocusFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListFocussQuery> {
    const statement = `query ListFocuss($filter: ModelFocusFilterInput, $limit: Int, $nextToken: String) {
        listFocuss(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            key
            display
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListFocussQuery>response.data.listFocuss;
  }
  async GetObjectiveDomain(id: string): Promise<GetObjectiveDomainQuery> {
    const statement = `query GetObjectiveDomain($id: ID!) {
        getObjectiveDomain(id: $id) {
          __typename
          id
          key
          display
          excludeFromTPS
          sites
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetObjectiveDomainQuery>response.data.getObjectiveDomain;
  }
  async ListObjectiveDomains(
    filter?: ModelObjectiveDomainFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListObjectiveDomainsQuery> {
    const statement = `query ListObjectiveDomains($filter: ModelObjectiveDomainFilterInput, $limit: Int, $nextToken: String) {
        listObjectiveDomains(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            key
            display
            excludeFromTPS
            sites
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListObjectiveDomainsQuery>response.data.listObjectiveDomains;
  }
  async GetObjective(id: string): Promise<GetObjectiveQuery> {
    const statement = `query GetObjective($id: ID!) {
        getObjective(id: $id) {
          __typename
          id
          display
          definition
          observables
          hints
          organizationID
          organizations
          status
          domain {
            __typename
            id
            key
            display
            excludeFromTPS
            sites
            createdAt
            updatedAt
          }
          objectiveDomainId
          qrCodes {
            __typename
            type
            imageSrc
            title
            label
            qrdata
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetObjectiveQuery>response.data.getObjective;
  }
  async ListObjectives(
    filter?: ModelObjectiveFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListObjectivesQuery> {
    const statement = `query ListObjectives($filter: ModelObjectiveFilterInput, $limit: Int, $nextToken: String) {
        listObjectives(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            display
            definition
            observables
            hints
            organizationID
            organizations
            status
            domain {
              __typename
              id
              key
              display
              excludeFromTPS
              sites
              createdAt
              updatedAt
            }
            objectiveDomainId
            qrCodes {
              __typename
              type
              imageSrc
              title
              label
              qrdata
            }
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListObjectivesQuery>response.data.listObjectives;
  }
  async GetOrganizationObjective(
    id: string
  ): Promise<GetOrganizationObjectiveQuery> {
    const statement = `query GetOrganizationObjective($id: ID!) {
        getOrganizationObjective(id: $id) {
          __typename
          id
          organizationID
          objective {
            __typename
            id
            display
            definition
            observables
            hints
            organizationID
            organizations
            status
            domain {
              __typename
              id
              key
              display
              excludeFromTPS
              sites
              createdAt
              updatedAt
            }
            objectiveDomainId
            qrCodes {
              __typename
              type
              imageSrc
              title
              label
              qrdata
            }
            createdAt
            updatedAt
          }
          organizationObjectiveObjectiveId
          enabled
          businessObjective
          status
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetOrganizationObjectiveQuery>(
      response.data.getOrganizationObjective
    );
  }
  async ListOrganizationObjectives(
    filter?: ModelOrganizationObjectiveFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListOrganizationObjectivesQuery> {
    const statement = `query ListOrganizationObjectives($filter: ModelOrganizationObjectiveFilterInput, $limit: Int, $nextToken: String) {
        listOrganizationObjectives(
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            organizationID
            objective {
              __typename
              id
              display
              definition
              observables
              hints
              organizationID
              organizations
              status
              domain {
                __typename
                id
                key
                display
                excludeFromTPS
                sites
                createdAt
                updatedAt
              }
              objectiveDomainId
              qrCodes {
                __typename
                type
                imageSrc
                title
                label
                qrdata
              }
              createdAt
              updatedAt
            }
            organizationObjectiveObjectiveId
            enabled
            businessObjective
            status
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListOrganizationObjectivesQuery>(
      response.data.listOrganizationObjectives
    );
  }
  async GetObjectiveAssignment(
    id: string
  ): Promise<GetObjectiveAssignmentQuery> {
    const statement = `query GetObjectiveAssignment($id: ID!) {
        getObjectiveAssignment(id: $id) {
          __typename
          id
          employeeId
          organizationID
          objectiveAssignmentAssignedById
          assignmentDate
          priority {
            __typename
            id
            key
            display
            createdAt
            updatedAt
          }
          focus {
            __typename
            id
            key
            display
            createdAt
            updatedAt
          }
          orgObjective {
            __typename
            id
            organizationID
            objective {
              __typename
              id
              display
              definition
              observables
              hints
              organizationID
              organizations
              status
              domain {
                __typename
                id
                key
                display
                excludeFromTPS
                sites
                createdAt
                updatedAt
              }
              objectiveDomainId
              qrCodes {
                __typename
                type
                imageSrc
                title
                label
                qrdata
              }
              createdAt
              updatedAt
            }
            organizationObjectiveObjectiveId
            enabled
            businessObjective
            status
            createdAt
            updatedAt
          }
          startDate
          endDate
          cadence
          managerDescription
          objectiveType
          title
          quantity
          consultantId
          nextSurveyDate
          status
          externalEmail
          externalEmails
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetObjectiveAssignmentQuery>response.data.getObjectiveAssignment;
  }
  async ListObjectiveAssignments(
    filter?: ModelObjectiveAssignmentFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListObjectiveAssignmentsQuery> {
    const statement = `query ListObjectiveAssignments($filter: ModelObjectiveAssignmentFilterInput, $limit: Int, $nextToken: String) {
        listObjectiveAssignments(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            employeeId
            organizationID
            objectiveAssignmentAssignedById
            assignmentDate
            priority {
              __typename
              id
              key
              display
              createdAt
              updatedAt
            }
            focus {
              __typename
              id
              key
              display
              createdAt
              updatedAt
            }
            orgObjective {
              __typename
              id
              organizationID
              objective {
                __typename
                id
                display
                definition
                observables
                hints
                organizationID
                organizations
                status
                domain {
                  __typename
                  id
                  key
                  display
                  excludeFromTPS
                  sites
                  createdAt
                  updatedAt
                }
                objectiveDomainId
                qrCodes {
                  __typename
                  type
                  imageSrc
                  title
                  label
                  qrdata
                }
                createdAt
                updatedAt
              }
              organizationObjectiveObjectiveId
              enabled
              businessObjective
              status
              createdAt
              updatedAt
            }
            startDate
            endDate
            cadence
            managerDescription
            objectiveType
            title
            quantity
            consultantId
            nextSurveyDate
            status
            externalEmail
            externalEmails
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListObjectiveAssignmentsQuery>(
      response.data.listObjectiveAssignments
    );
  }
  async GetCalendarEvent(id: string): Promise<GetCalendarEventQuery> {
    const statement = `query GetCalendarEvent($id: ID!) {
        getCalendarEvent(id: $id) {
          __typename
          id
          employeeID
          organizationID
          calendarId
          start
          end
          eventCreated
          eventUpdated
          summary
          description
          status
          private
          deleted
          recurring
          locationDescription
          participationStatus
          eventStatus
          organizerEmail
          organizer {
            __typename
            email
            displayName
            status
            createdAt
            updatedAt
          }
          attendees {
            __typename
            email
            displayName
            status
            createdAt
            updatedAt
          }
          attendeeEmails
          meetingURL
          options {
            __typename
            changeParticipationStatus
            delete
            update
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetCalendarEventQuery>response.data.getCalendarEvent;
  }
  async ListCalendarEvents(
    filter?: ModelCalendarEventFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListCalendarEventsQuery> {
    const statement = `query ListCalendarEvents($filter: ModelCalendarEventFilterInput, $limit: Int, $nextToken: String) {
        listCalendarEvents(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            employeeID
            organizationID
            calendarId
            start
            end
            eventCreated
            eventUpdated
            summary
            description
            status
            private
            deleted
            recurring
            locationDescription
            participationStatus
            eventStatus
            organizerEmail
            organizer {
              __typename
              email
              displayName
              status
              createdAt
              updatedAt
            }
            attendees {
              __typename
              email
              displayName
              status
              createdAt
              updatedAt
            }
            attendeeEmails
            meetingURL
            options {
              __typename
              changeParticipationStatus
              delete
              update
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListCalendarEventsQuery>response.data.listCalendarEvents;
  }
  async GetSurvey(id: string): Promise<GetSurveyQuery> {
    const statement = `query GetSurvey($id: ID!) {
        getSurvey(id: $id) {
          __typename
          id
          organizationID
          objectID
          surveyType
          surveyDate
          respondentEmail
          participantEmail
          respondentAttended
          surveyResponse
          feedback
          responseReceived
          responseReceivedDate
          status
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetSurveyQuery>response.data.getSurvey;
  }
  async ListSurveys(
    filter?: ModelSurveyFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListSurveysQuery> {
    const statement = `query ListSurveys($filter: ModelSurveyFilterInput, $limit: Int, $nextToken: String) {
        listSurveys(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            organizationID
            objectID
            surveyType
            surveyDate
            respondentEmail
            participantEmail
            respondentAttended
            surveyResponse
            feedback
            responseReceived
            responseReceivedDate
            status
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListSurveysQuery>response.data.listSurveys;
  }
  async GetExternalRespondent(id: string): Promise<GetExternalRespondentQuery> {
    const statement = `query GetExternalRespondent($id: ID!) {
        getExternalRespondent(id: $id) {
          __typename
          id
          externalRespondentEmail
          externalRespondentName
          externalRespondentStatus
          statusUpdates {
            __typename
            status
            ipAddress
            externalRespondentStatusDate
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetExternalRespondentQuery>response.data.getExternalRespondent;
  }
  async ListExternalRespondents(
    filter?: ModelExternalRespondentFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListExternalRespondentsQuery> {
    const statement = `query ListExternalRespondents($filter: ModelExternalRespondentFilterInput, $limit: Int, $nextToken: String) {
        listExternalRespondents(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            externalRespondentEmail
            externalRespondentName
            externalRespondentStatus
            statusUpdates {
              __typename
              status
              ipAddress
              externalRespondentStatusDate
            }
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListExternalRespondentsQuery>response.data.listExternalRespondents;
  }
  async GetDoNotDisturbRequest(
    id: string
  ): Promise<GetDoNotDisturbRequestQuery> {
    const statement = `query GetDoNotDisturbRequest($id: ID!) {
        getDoNotDisturbRequest(id: $id) {
          __typename
          id
          externalRespondentID
          externalRespondentEmail
          ipAddress
          status
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetDoNotDisturbRequestQuery>response.data.getDoNotDisturbRequest;
  }
  async ListDoNotDisturbRequests(
    filter?: ModelDoNotDisturbRequestFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListDoNotDisturbRequestsQuery> {
    const statement = `query ListDoNotDisturbRequests($filter: ModelDoNotDisturbRequestFilterInput, $limit: Int, $nextToken: String) {
        listDoNotDisturbRequests(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            externalRespondentID
            externalRespondentEmail
            ipAddress
            status
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListDoNotDisturbRequestsQuery>(
      response.data.listDoNotDisturbRequests
    );
  }
  async GetProject(id: string): Promise<GetProjectQuery> {
    const statement = `query GetProject($id: ID!) {
        getProject(id: $id) {
          __typename
          id
          organizationID
          title
          description
          startDate
          endDate
          cadence
          projectManagerID
          status
          team
          nextSurveyDate
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetProjectQuery>response.data.getProject;
  }
  async ListProjects(
    filter?: ModelProjectFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListProjectsQuery> {
    const statement = `query ListProjects($filter: ModelProjectFilterInput, $limit: Int, $nextToken: String) {
        listProjects(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            organizationID
            title
            description
            startDate
            endDate
            cadence
            projectManagerID
            status
            team
            nextSurveyDate
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListProjectsQuery>response.data.listProjects;
  }
  async GetMilestoneComment(id: string): Promise<GetMilestoneCommentQuery> {
    const statement = `query GetMilestoneComment($id: ID!) {
        getMilestoneComment(id: $id) {
          __typename
          id
          organizationID
          milestoneID
          projectMilestoneAssignment {
            __typename
            id
            projectID
            organizationID
            assignedBy
            assignmentDate
            startDate
            endDate
            cadence
            milestoneType
            title
            nextSurveyDate
            description
            team
            comments {
              __typename
              items {
                __typename
                id
                organizationID
                milestoneID
                projectMilestoneAssignment {
                  __typename
                  id
                  projectID
                  organizationID
                  assignedBy
                  assignmentDate
                  startDate
                  endDate
                  cadence
                  milestoneType
                  title
                  nextSurveyDate
                  description
                  team
                  comments {
                    __typename
                    items {
                      __typename
                      id
                      organizationID
                      milestoneID
                      projectMilestoneAssignment {
                        __typename
                        id
                        projectID
                        organizationID
                        assignedBy
                        assignmentDate
                        startDate
                        endDate
                        cadence
                        milestoneType
                        title
                        nextSurveyDate
                        description
                        team
                        comments {
                          __typename
                          items {
                            __typename
                            id
                            organizationID
                            milestoneID
                            comment
                            createdAt
                            createdBy
                            updatedAt
                          }
                          nextToken
                        }
                        createdAt
                        updatedAt
                      }
                      comment
                      createdAt
                      createdBy
                      updatedAt
                    }
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                comment
                createdAt
                createdBy
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          comment
          createdAt
          createdBy
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetMilestoneCommentQuery>response.data.getMilestoneComment;
  }
  async ListMilestoneComments(
    filter?: ModelMilestoneCommentFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListMilestoneCommentsQuery> {
    const statement = `query ListMilestoneComments($filter: ModelMilestoneCommentFilterInput, $limit: Int, $nextToken: String) {
        listMilestoneComments(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            organizationID
            milestoneID
            projectMilestoneAssignment {
              __typename
              id
              projectID
              organizationID
              assignedBy
              assignmentDate
              startDate
              endDate
              cadence
              milestoneType
              title
              nextSurveyDate
              description
              team
              comments {
                __typename
                items {
                  __typename
                  id
                  organizationID
                  milestoneID
                  projectMilestoneAssignment {
                    __typename
                    id
                    projectID
                    organizationID
                    assignedBy
                    assignmentDate
                    startDate
                    endDate
                    cadence
                    milestoneType
                    title
                    nextSurveyDate
                    description
                    team
                    comments {
                      __typename
                      items {
                        __typename
                        id
                        organizationID
                        milestoneID
                        projectMilestoneAssignment {
                          __typename
                          id
                          projectID
                          organizationID
                          assignedBy
                          assignmentDate
                          startDate
                          endDate
                          cadence
                          milestoneType
                          title
                          nextSurveyDate
                          description
                          team
                          comments {
                            __typename
                            nextToken
                          }
                          createdAt
                          updatedAt
                        }
                        comment
                        createdAt
                        createdBy
                        updatedAt
                      }
                      nextToken
                    }
                    createdAt
                    updatedAt
                  }
                  comment
                  createdAt
                  createdBy
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            comment
            createdAt
            createdBy
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListMilestoneCommentsQuery>response.data.listMilestoneComments;
  }
  async GetProjectMilestoneAssignment(
    id: string
  ): Promise<GetProjectMilestoneAssignmentQuery> {
    const statement = `query GetProjectMilestoneAssignment($id: ID!) {
        getProjectMilestoneAssignment(id: $id) {
          __typename
          id
          projectID
          organizationID
          assignedBy
          assignmentDate
          startDate
          endDate
          cadence
          milestoneType
          title
          nextSurveyDate
          description
          team
          comments {
            __typename
            items {
              __typename
              id
              organizationID
              milestoneID
              projectMilestoneAssignment {
                __typename
                id
                projectID
                organizationID
                assignedBy
                assignmentDate
                startDate
                endDate
                cadence
                milestoneType
                title
                nextSurveyDate
                description
                team
                comments {
                  __typename
                  items {
                    __typename
                    id
                    organizationID
                    milestoneID
                    projectMilestoneAssignment {
                      __typename
                      id
                      projectID
                      organizationID
                      assignedBy
                      assignmentDate
                      startDate
                      endDate
                      cadence
                      milestoneType
                      title
                      nextSurveyDate
                      description
                      team
                      comments {
                        __typename
                        items {
                          __typename
                          id
                          organizationID
                          milestoneID
                          projectMilestoneAssignment {
                            __typename
                            id
                            projectID
                            organizationID
                            assignedBy
                            assignmentDate
                            startDate
                            endDate
                            cadence
                            milestoneType
                            title
                            nextSurveyDate
                            description
                            team
                            createdAt
                            updatedAt
                          }
                          comment
                          createdAt
                          createdBy
                          updatedAt
                        }
                        nextToken
                      }
                      createdAt
                      updatedAt
                    }
                    comment
                    createdAt
                    createdBy
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              comment
              createdAt
              createdBy
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetProjectMilestoneAssignmentQuery>(
      response.data.getProjectMilestoneAssignment
    );
  }
  async ListProjectMilestoneAssignments(
    filter?: ModelProjectMilestoneAssignmentFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListProjectMilestoneAssignmentsQuery> {
    const statement = `query ListProjectMilestoneAssignments($filter: ModelProjectMilestoneAssignmentFilterInput, $limit: Int, $nextToken: String) {
        listProjectMilestoneAssignments(
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            projectID
            organizationID
            assignedBy
            assignmentDate
            startDate
            endDate
            cadence
            milestoneType
            title
            nextSurveyDate
            description
            team
            comments {
              __typename
              items {
                __typename
                id
                organizationID
                milestoneID
                projectMilestoneAssignment {
                  __typename
                  id
                  projectID
                  organizationID
                  assignedBy
                  assignmentDate
                  startDate
                  endDate
                  cadence
                  milestoneType
                  title
                  nextSurveyDate
                  description
                  team
                  comments {
                    __typename
                    items {
                      __typename
                      id
                      organizationID
                      milestoneID
                      projectMilestoneAssignment {
                        __typename
                        id
                        projectID
                        organizationID
                        assignedBy
                        assignmentDate
                        startDate
                        endDate
                        cadence
                        milestoneType
                        title
                        nextSurveyDate
                        description
                        team
                        comments {
                          __typename
                          items {
                            __typename
                            id
                            organizationID
                            milestoneID
                            comment
                            createdAt
                            createdBy
                            updatedAt
                          }
                          nextToken
                        }
                        createdAt
                        updatedAt
                      }
                      comment
                      createdAt
                      createdBy
                      updatedAt
                    }
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                comment
                createdAt
                createdBy
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListProjectMilestoneAssignmentsQuery>(
      response.data.listProjectMilestoneAssignments
    );
  }
  async GetNoteComment(id: string): Promise<GetNoteCommentQuery> {
    const statement = `query GetNoteComment($id: ID!) {
        getNoteComment(id: $id) {
          __typename
          id
          organizationID
          noteID
          note {
            __typename
            id
            organizationID
            objectID
            createdAt
            createdBy
            updatedAt
            updatedBy
            text
            noteType
            options
            comments {
              __typename
              items {
                __typename
                id
                organizationID
                noteID
                note {
                  __typename
                  id
                  organizationID
                  objectID
                  createdAt
                  createdBy
                  updatedAt
                  updatedBy
                  text
                  noteType
                  options
                  comments {
                    __typename
                    items {
                      __typename
                      id
                      organizationID
                      noteID
                      note {
                        __typename
                        id
                        organizationID
                        objectID
                        createdAt
                        createdBy
                        updatedAt
                        updatedBy
                        text
                        noteType
                        options
                        comments {
                          __typename
                          items {
                            __typename
                            id
                            organizationID
                            noteID
                            comment
                            createdAt
                            createdBy
                            updatedAt
                          }
                          nextToken
                        }
                        historys {
                          __typename
                          items {
                            __typename
                            id
                            organizationID
                            noteID
                            createdAt
                            createdBy
                            text
                            updatedAt
                          }
                          nextToken
                        }
                      }
                      comment
                      createdAt
                      createdBy
                      updatedAt
                    }
                    nextToken
                  }
                  historys {
                    __typename
                    items {
                      __typename
                      id
                      organizationID
                      noteID
                      note {
                        __typename
                        id
                        organizationID
                        objectID
                        createdAt
                        createdBy
                        updatedAt
                        updatedBy
                        text
                        noteType
                        options
                        comments {
                          __typename
                          items {
                            __typename
                            id
                            organizationID
                            noteID
                            comment
                            createdAt
                            createdBy
                            updatedAt
                          }
                          nextToken
                        }
                        historys {
                          __typename
                          items {
                            __typename
                            id
                            organizationID
                            noteID
                            createdAt
                            createdBy
                            text
                            updatedAt
                          }
                          nextToken
                        }
                      }
                      createdAt
                      createdBy
                      text
                      updatedAt
                    }
                    nextToken
                  }
                }
                comment
                createdAt
                createdBy
                updatedAt
              }
              nextToken
            }
            historys {
              __typename
              items {
                __typename
                id
                organizationID
                noteID
                note {
                  __typename
                  id
                  organizationID
                  objectID
                  createdAt
                  createdBy
                  updatedAt
                  updatedBy
                  text
                  noteType
                  options
                  comments {
                    __typename
                    items {
                      __typename
                      id
                      organizationID
                      noteID
                      note {
                        __typename
                        id
                        organizationID
                        objectID
                        createdAt
                        createdBy
                        updatedAt
                        updatedBy
                        text
                        noteType
                        options
                        comments {
                          __typename
                          items {
                            __typename
                            id
                            organizationID
                            noteID
                            comment
                            createdAt
                            createdBy
                            updatedAt
                          }
                          nextToken
                        }
                        historys {
                          __typename
                          items {
                            __typename
                            id
                            organizationID
                            noteID
                            createdAt
                            createdBy
                            text
                            updatedAt
                          }
                          nextToken
                        }
                      }
                      comment
                      createdAt
                      createdBy
                      updatedAt
                    }
                    nextToken
                  }
                  historys {
                    __typename
                    items {
                      __typename
                      id
                      organizationID
                      noteID
                      note {
                        __typename
                        id
                        organizationID
                        objectID
                        createdAt
                        createdBy
                        updatedAt
                        updatedBy
                        text
                        noteType
                        options
                        comments {
                          __typename
                          items {
                            __typename
                            id
                            organizationID
                            noteID
                            comment
                            createdAt
                            createdBy
                            updatedAt
                          }
                          nextToken
                        }
                        historys {
                          __typename
                          items {
                            __typename
                            id
                            organizationID
                            noteID
                            createdAt
                            createdBy
                            text
                            updatedAt
                          }
                          nextToken
                        }
                      }
                      createdAt
                      createdBy
                      text
                      updatedAt
                    }
                    nextToken
                  }
                }
                createdAt
                createdBy
                text
                updatedAt
              }
              nextToken
            }
          }
          comment
          createdAt
          createdBy
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetNoteCommentQuery>response.data.getNoteComment;
  }
  async ListNoteComments(
    filter?: ModelNoteCommentFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListNoteCommentsQuery> {
    const statement = `query ListNoteComments($filter: ModelNoteCommentFilterInput, $limit: Int, $nextToken: String) {
        listNoteComments(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            organizationID
            noteID
            note {
              __typename
              id
              organizationID
              objectID
              createdAt
              createdBy
              updatedAt
              updatedBy
              text
              noteType
              options
              comments {
                __typename
                items {
                  __typename
                  id
                  organizationID
                  noteID
                  note {
                    __typename
                    id
                    organizationID
                    objectID
                    createdAt
                    createdBy
                    updatedAt
                    updatedBy
                    text
                    noteType
                    options
                    comments {
                      __typename
                      items {
                        __typename
                        id
                        organizationID
                        noteID
                        note {
                          __typename
                          id
                          organizationID
                          objectID
                          createdAt
                          createdBy
                          updatedAt
                          updatedBy
                          text
                          noteType
                          options
                          comments {
                            __typename
                            nextToken
                          }
                          historys {
                            __typename
                            nextToken
                          }
                        }
                        comment
                        createdAt
                        createdBy
                        updatedAt
                      }
                      nextToken
                    }
                    historys {
                      __typename
                      items {
                        __typename
                        id
                        organizationID
                        noteID
                        note {
                          __typename
                          id
                          organizationID
                          objectID
                          createdAt
                          createdBy
                          updatedAt
                          updatedBy
                          text
                          noteType
                          options
                          comments {
                            __typename
                            nextToken
                          }
                          historys {
                            __typename
                            nextToken
                          }
                        }
                        createdAt
                        createdBy
                        text
                        updatedAt
                      }
                      nextToken
                    }
                  }
                  comment
                  createdAt
                  createdBy
                  updatedAt
                }
                nextToken
              }
              historys {
                __typename
                items {
                  __typename
                  id
                  organizationID
                  noteID
                  note {
                    __typename
                    id
                    organizationID
                    objectID
                    createdAt
                    createdBy
                    updatedAt
                    updatedBy
                    text
                    noteType
                    options
                    comments {
                      __typename
                      items {
                        __typename
                        id
                        organizationID
                        noteID
                        note {
                          __typename
                          id
                          organizationID
                          objectID
                          createdAt
                          createdBy
                          updatedAt
                          updatedBy
                          text
                          noteType
                          options
                          comments {
                            __typename
                            nextToken
                          }
                          historys {
                            __typename
                            nextToken
                          }
                        }
                        comment
                        createdAt
                        createdBy
                        updatedAt
                      }
                      nextToken
                    }
                    historys {
                      __typename
                      items {
                        __typename
                        id
                        organizationID
                        noteID
                        note {
                          __typename
                          id
                          organizationID
                          objectID
                          createdAt
                          createdBy
                          updatedAt
                          updatedBy
                          text
                          noteType
                          options
                          comments {
                            __typename
                            nextToken
                          }
                          historys {
                            __typename
                            nextToken
                          }
                        }
                        createdAt
                        createdBy
                        text
                        updatedAt
                      }
                      nextToken
                    }
                  }
                  createdAt
                  createdBy
                  text
                  updatedAt
                }
                nextToken
              }
            }
            comment
            createdAt
            createdBy
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListNoteCommentsQuery>response.data.listNoteComments;
  }
  async GetNote(id: string): Promise<GetNoteQuery> {
    const statement = `query GetNote($id: ID!) {
        getNote(id: $id) {
          __typename
          id
          organizationID
          objectID
          createdAt
          createdBy
          updatedAt
          updatedBy
          text
          noteType
          options
          comments {
            __typename
            items {
              __typename
              id
              organizationID
              noteID
              note {
                __typename
                id
                organizationID
                objectID
                createdAt
                createdBy
                updatedAt
                updatedBy
                text
                noteType
                options
                comments {
                  __typename
                  items {
                    __typename
                    id
                    organizationID
                    noteID
                    note {
                      __typename
                      id
                      organizationID
                      objectID
                      createdAt
                      createdBy
                      updatedAt
                      updatedBy
                      text
                      noteType
                      options
                      comments {
                        __typename
                        items {
                          __typename
                          id
                          organizationID
                          noteID
                          note {
                            __typename
                            id
                            organizationID
                            objectID
                            createdAt
                            createdBy
                            updatedAt
                            updatedBy
                            text
                            noteType
                            options
                          }
                          comment
                          createdAt
                          createdBy
                          updatedAt
                        }
                        nextToken
                      }
                      historys {
                        __typename
                        items {
                          __typename
                          id
                          organizationID
                          noteID
                          note {
                            __typename
                            id
                            organizationID
                            objectID
                            createdAt
                            createdBy
                            updatedAt
                            updatedBy
                            text
                            noteType
                            options
                          }
                          createdAt
                          createdBy
                          text
                          updatedAt
                        }
                        nextToken
                      }
                    }
                    comment
                    createdAt
                    createdBy
                    updatedAt
                  }
                  nextToken
                }
                historys {
                  __typename
                  items {
                    __typename
                    id
                    organizationID
                    noteID
                    note {
                      __typename
                      id
                      organizationID
                      objectID
                      createdAt
                      createdBy
                      updatedAt
                      updatedBy
                      text
                      noteType
                      options
                      comments {
                        __typename
                        items {
                          __typename
                          id
                          organizationID
                          noteID
                          note {
                            __typename
                            id
                            organizationID
                            objectID
                            createdAt
                            createdBy
                            updatedAt
                            updatedBy
                            text
                            noteType
                            options
                          }
                          comment
                          createdAt
                          createdBy
                          updatedAt
                        }
                        nextToken
                      }
                      historys {
                        __typename
                        items {
                          __typename
                          id
                          organizationID
                          noteID
                          note {
                            __typename
                            id
                            organizationID
                            objectID
                            createdAt
                            createdBy
                            updatedAt
                            updatedBy
                            text
                            noteType
                            options
                          }
                          createdAt
                          createdBy
                          text
                          updatedAt
                        }
                        nextToken
                      }
                    }
                    createdAt
                    createdBy
                    text
                    updatedAt
                  }
                  nextToken
                }
              }
              comment
              createdAt
              createdBy
              updatedAt
            }
            nextToken
          }
          historys {
            __typename
            items {
              __typename
              id
              organizationID
              noteID
              note {
                __typename
                id
                organizationID
                objectID
                createdAt
                createdBy
                updatedAt
                updatedBy
                text
                noteType
                options
                comments {
                  __typename
                  items {
                    __typename
                    id
                    organizationID
                    noteID
                    note {
                      __typename
                      id
                      organizationID
                      objectID
                      createdAt
                      createdBy
                      updatedAt
                      updatedBy
                      text
                      noteType
                      options
                      comments {
                        __typename
                        items {
                          __typename
                          id
                          organizationID
                          noteID
                          note {
                            __typename
                            id
                            organizationID
                            objectID
                            createdAt
                            createdBy
                            updatedAt
                            updatedBy
                            text
                            noteType
                            options
                          }
                          comment
                          createdAt
                          createdBy
                          updatedAt
                        }
                        nextToken
                      }
                      historys {
                        __typename
                        items {
                          __typename
                          id
                          organizationID
                          noteID
                          note {
                            __typename
                            id
                            organizationID
                            objectID
                            createdAt
                            createdBy
                            updatedAt
                            updatedBy
                            text
                            noteType
                            options
                          }
                          createdAt
                          createdBy
                          text
                          updatedAt
                        }
                        nextToken
                      }
                    }
                    comment
                    createdAt
                    createdBy
                    updatedAt
                  }
                  nextToken
                }
                historys {
                  __typename
                  items {
                    __typename
                    id
                    organizationID
                    noteID
                    note {
                      __typename
                      id
                      organizationID
                      objectID
                      createdAt
                      createdBy
                      updatedAt
                      updatedBy
                      text
                      noteType
                      options
                      comments {
                        __typename
                        items {
                          __typename
                          id
                          organizationID
                          noteID
                          note {
                            __typename
                            id
                            organizationID
                            objectID
                            createdAt
                            createdBy
                            updatedAt
                            updatedBy
                            text
                            noteType
                            options
                          }
                          comment
                          createdAt
                          createdBy
                          updatedAt
                        }
                        nextToken
                      }
                      historys {
                        __typename
                        items {
                          __typename
                          id
                          organizationID
                          noteID
                          note {
                            __typename
                            id
                            organizationID
                            objectID
                            createdAt
                            createdBy
                            updatedAt
                            updatedBy
                            text
                            noteType
                            options
                          }
                          createdAt
                          createdBy
                          text
                          updatedAt
                        }
                        nextToken
                      }
                    }
                    createdAt
                    createdBy
                    text
                    updatedAt
                  }
                  nextToken
                }
              }
              createdAt
              createdBy
              text
              updatedAt
            }
            nextToken
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetNoteQuery>response.data.getNote;
  }
  async ListNotes(
    filter?: ModelNoteFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListNotesQuery> {
    const statement = `query ListNotes($filter: ModelNoteFilterInput, $limit: Int, $nextToken: String) {
        listNotes(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            organizationID
            objectID
            createdAt
            createdBy
            updatedAt
            updatedBy
            text
            noteType
            options
            comments {
              __typename
              items {
                __typename
                id
                organizationID
                noteID
                note {
                  __typename
                  id
                  organizationID
                  objectID
                  createdAt
                  createdBy
                  updatedAt
                  updatedBy
                  text
                  noteType
                  options
                  comments {
                    __typename
                    items {
                      __typename
                      id
                      organizationID
                      noteID
                      note {
                        __typename
                        id
                        organizationID
                        objectID
                        createdAt
                        createdBy
                        updatedAt
                        updatedBy
                        text
                        noteType
                        options
                        comments {
                          __typename
                          items {
                            __typename
                            id
                            organizationID
                            noteID
                            comment
                            createdAt
                            createdBy
                            updatedAt
                          }
                          nextToken
                        }
                        historys {
                          __typename
                          items {
                            __typename
                            id
                            organizationID
                            noteID
                            createdAt
                            createdBy
                            text
                            updatedAt
                          }
                          nextToken
                        }
                      }
                      comment
                      createdAt
                      createdBy
                      updatedAt
                    }
                    nextToken
                  }
                  historys {
                    __typename
                    items {
                      __typename
                      id
                      organizationID
                      noteID
                      note {
                        __typename
                        id
                        organizationID
                        objectID
                        createdAt
                        createdBy
                        updatedAt
                        updatedBy
                        text
                        noteType
                        options
                        comments {
                          __typename
                          items {
                            __typename
                            id
                            organizationID
                            noteID
                            comment
                            createdAt
                            createdBy
                            updatedAt
                          }
                          nextToken
                        }
                        historys {
                          __typename
                          items {
                            __typename
                            id
                            organizationID
                            noteID
                            createdAt
                            createdBy
                            text
                            updatedAt
                          }
                          nextToken
                        }
                      }
                      createdAt
                      createdBy
                      text
                      updatedAt
                    }
                    nextToken
                  }
                }
                comment
                createdAt
                createdBy
                updatedAt
              }
              nextToken
            }
            historys {
              __typename
              items {
                __typename
                id
                organizationID
                noteID
                note {
                  __typename
                  id
                  organizationID
                  objectID
                  createdAt
                  createdBy
                  updatedAt
                  updatedBy
                  text
                  noteType
                  options
                  comments {
                    __typename
                    items {
                      __typename
                      id
                      organizationID
                      noteID
                      note {
                        __typename
                        id
                        organizationID
                        objectID
                        createdAt
                        createdBy
                        updatedAt
                        updatedBy
                        text
                        noteType
                        options
                        comments {
                          __typename
                          items {
                            __typename
                            id
                            organizationID
                            noteID
                            comment
                            createdAt
                            createdBy
                            updatedAt
                          }
                          nextToken
                        }
                        historys {
                          __typename
                          items {
                            __typename
                            id
                            organizationID
                            noteID
                            createdAt
                            createdBy
                            text
                            updatedAt
                          }
                          nextToken
                        }
                      }
                      comment
                      createdAt
                      createdBy
                      updatedAt
                    }
                    nextToken
                  }
                  historys {
                    __typename
                    items {
                      __typename
                      id
                      organizationID
                      noteID
                      note {
                        __typename
                        id
                        organizationID
                        objectID
                        createdAt
                        createdBy
                        updatedAt
                        updatedBy
                        text
                        noteType
                        options
                        comments {
                          __typename
                          items {
                            __typename
                            id
                            organizationID
                            noteID
                            comment
                            createdAt
                            createdBy
                            updatedAt
                          }
                          nextToken
                        }
                        historys {
                          __typename
                          items {
                            __typename
                            id
                            organizationID
                            noteID
                            createdAt
                            createdBy
                            text
                            updatedAt
                          }
                          nextToken
                        }
                      }
                      createdAt
                      createdBy
                      text
                      updatedAt
                    }
                    nextToken
                  }
                }
                createdAt
                createdBy
                text
                updatedAt
              }
              nextToken
            }
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListNotesQuery>response.data.listNotes;
  }
  async GetNoteHistory(id: string): Promise<GetNoteHistoryQuery> {
    const statement = `query GetNoteHistory($id: ID!) {
        getNoteHistory(id: $id) {
          __typename
          id
          organizationID
          noteID
          note {
            __typename
            id
            organizationID
            objectID
            createdAt
            createdBy
            updatedAt
            updatedBy
            text
            noteType
            options
            comments {
              __typename
              items {
                __typename
                id
                organizationID
                noteID
                note {
                  __typename
                  id
                  organizationID
                  objectID
                  createdAt
                  createdBy
                  updatedAt
                  updatedBy
                  text
                  noteType
                  options
                  comments {
                    __typename
                    items {
                      __typename
                      id
                      organizationID
                      noteID
                      note {
                        __typename
                        id
                        organizationID
                        objectID
                        createdAt
                        createdBy
                        updatedAt
                        updatedBy
                        text
                        noteType
                        options
                        comments {
                          __typename
                          items {
                            __typename
                            id
                            organizationID
                            noteID
                            comment
                            createdAt
                            createdBy
                            updatedAt
                          }
                          nextToken
                        }
                        historys {
                          __typename
                          items {
                            __typename
                            id
                            organizationID
                            noteID
                            createdAt
                            createdBy
                            text
                            updatedAt
                          }
                          nextToken
                        }
                      }
                      comment
                      createdAt
                      createdBy
                      updatedAt
                    }
                    nextToken
                  }
                  historys {
                    __typename
                    items {
                      __typename
                      id
                      organizationID
                      noteID
                      note {
                        __typename
                        id
                        organizationID
                        objectID
                        createdAt
                        createdBy
                        updatedAt
                        updatedBy
                        text
                        noteType
                        options
                        comments {
                          __typename
                          items {
                            __typename
                            id
                            organizationID
                            noteID
                            comment
                            createdAt
                            createdBy
                            updatedAt
                          }
                          nextToken
                        }
                        historys {
                          __typename
                          items {
                            __typename
                            id
                            organizationID
                            noteID
                            createdAt
                            createdBy
                            text
                            updatedAt
                          }
                          nextToken
                        }
                      }
                      createdAt
                      createdBy
                      text
                      updatedAt
                    }
                    nextToken
                  }
                }
                comment
                createdAt
                createdBy
                updatedAt
              }
              nextToken
            }
            historys {
              __typename
              items {
                __typename
                id
                organizationID
                noteID
                note {
                  __typename
                  id
                  organizationID
                  objectID
                  createdAt
                  createdBy
                  updatedAt
                  updatedBy
                  text
                  noteType
                  options
                  comments {
                    __typename
                    items {
                      __typename
                      id
                      organizationID
                      noteID
                      note {
                        __typename
                        id
                        organizationID
                        objectID
                        createdAt
                        createdBy
                        updatedAt
                        updatedBy
                        text
                        noteType
                        options
                        comments {
                          __typename
                          items {
                            __typename
                            id
                            organizationID
                            noteID
                            comment
                            createdAt
                            createdBy
                            updatedAt
                          }
                          nextToken
                        }
                        historys {
                          __typename
                          items {
                            __typename
                            id
                            organizationID
                            noteID
                            createdAt
                            createdBy
                            text
                            updatedAt
                          }
                          nextToken
                        }
                      }
                      comment
                      createdAt
                      createdBy
                      updatedAt
                    }
                    nextToken
                  }
                  historys {
                    __typename
                    items {
                      __typename
                      id
                      organizationID
                      noteID
                      note {
                        __typename
                        id
                        organizationID
                        objectID
                        createdAt
                        createdBy
                        updatedAt
                        updatedBy
                        text
                        noteType
                        options
                        comments {
                          __typename
                          items {
                            __typename
                            id
                            organizationID
                            noteID
                            comment
                            createdAt
                            createdBy
                            updatedAt
                          }
                          nextToken
                        }
                        historys {
                          __typename
                          items {
                            __typename
                            id
                            organizationID
                            noteID
                            createdAt
                            createdBy
                            text
                            updatedAt
                          }
                          nextToken
                        }
                      }
                      createdAt
                      createdBy
                      text
                      updatedAt
                    }
                    nextToken
                  }
                }
                createdAt
                createdBy
                text
                updatedAt
              }
              nextToken
            }
          }
          createdAt
          createdBy
          text
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetNoteHistoryQuery>response.data.getNoteHistory;
  }
  async ListNoteHistorys(
    filter?: ModelNoteHistoryFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListNoteHistorysQuery> {
    const statement = `query ListNoteHistorys($filter: ModelNoteHistoryFilterInput, $limit: Int, $nextToken: String) {
        listNoteHistorys(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            organizationID
            noteID
            note {
              __typename
              id
              organizationID
              objectID
              createdAt
              createdBy
              updatedAt
              updatedBy
              text
              noteType
              options
              comments {
                __typename
                items {
                  __typename
                  id
                  organizationID
                  noteID
                  note {
                    __typename
                    id
                    organizationID
                    objectID
                    createdAt
                    createdBy
                    updatedAt
                    updatedBy
                    text
                    noteType
                    options
                    comments {
                      __typename
                      items {
                        __typename
                        id
                        organizationID
                        noteID
                        note {
                          __typename
                          id
                          organizationID
                          objectID
                          createdAt
                          createdBy
                          updatedAt
                          updatedBy
                          text
                          noteType
                          options
                          comments {
                            __typename
                            nextToken
                          }
                          historys {
                            __typename
                            nextToken
                          }
                        }
                        comment
                        createdAt
                        createdBy
                        updatedAt
                      }
                      nextToken
                    }
                    historys {
                      __typename
                      items {
                        __typename
                        id
                        organizationID
                        noteID
                        note {
                          __typename
                          id
                          organizationID
                          objectID
                          createdAt
                          createdBy
                          updatedAt
                          updatedBy
                          text
                          noteType
                          options
                          comments {
                            __typename
                            nextToken
                          }
                          historys {
                            __typename
                            nextToken
                          }
                        }
                        createdAt
                        createdBy
                        text
                        updatedAt
                      }
                      nextToken
                    }
                  }
                  comment
                  createdAt
                  createdBy
                  updatedAt
                }
                nextToken
              }
              historys {
                __typename
                items {
                  __typename
                  id
                  organizationID
                  noteID
                  note {
                    __typename
                    id
                    organizationID
                    objectID
                    createdAt
                    createdBy
                    updatedAt
                    updatedBy
                    text
                    noteType
                    options
                    comments {
                      __typename
                      items {
                        __typename
                        id
                        organizationID
                        noteID
                        note {
                          __typename
                          id
                          organizationID
                          objectID
                          createdAt
                          createdBy
                          updatedAt
                          updatedBy
                          text
                          noteType
                          options
                          comments {
                            __typename
                            nextToken
                          }
                          historys {
                            __typename
                            nextToken
                          }
                        }
                        comment
                        createdAt
                        createdBy
                        updatedAt
                      }
                      nextToken
                    }
                    historys {
                      __typename
                      items {
                        __typename
                        id
                        organizationID
                        noteID
                        note {
                          __typename
                          id
                          organizationID
                          objectID
                          createdAt
                          createdBy
                          updatedAt
                          updatedBy
                          text
                          noteType
                          options
                          comments {
                            __typename
                            nextToken
                          }
                          historys {
                            __typename
                            nextToken
                          }
                        }
                        createdAt
                        createdBy
                        text
                        updatedAt
                      }
                      nextToken
                    }
                  }
                  createdAt
                  createdBy
                  text
                  updatedAt
                }
                nextToken
              }
            }
            createdAt
            createdBy
            text
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListNoteHistorysQuery>response.data.listNoteHistorys;
  }
  async GetShoutOutType(id: string): Promise<GetShoutOutTypeQuery> {
    const statement = `query GetShoutOutType($id: ID!) {
        getShoutOutType(id: $id) {
          __typename
          id
          title
          formalDescription
          informalDescription
          icon
          attributes
          status
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetShoutOutTypeQuery>response.data.getShoutOutType;
  }
  async ListShoutOutTypes(
    filter?: ModelShoutOutTypeFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListShoutOutTypesQuery> {
    const statement = `query ListShoutOutTypes($filter: ModelShoutOutTypeFilterInput, $limit: Int, $nextToken: String) {
        listShoutOutTypes(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            title
            formalDescription
            informalDescription
            icon
            attributes
            status
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListShoutOutTypesQuery>response.data.listShoutOutTypes;
  }
  async GetOrganizationShoutOutType(
    id: string
  ): Promise<GetOrganizationShoutOutTypeQuery> {
    const statement = `query GetOrganizationShoutOutType($id: ID!) {
        getOrganizationShoutOutType(id: $id) {
          __typename
          id
          organizationID
          overrideTitle
          organizationShoutOutTypeShoutOuTypeId
          shoutOutType {
            __typename
            id
            title
            formalDescription
            informalDescription
            icon
            attributes
            status
            createdAt
            updatedAt
          }
          description
          overrideIcon
          overrideIconIdentityId
          enabled
          organizationAttributes
          disabledAttributes
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetOrganizationShoutOutTypeQuery>(
      response.data.getOrganizationShoutOutType
    );
  }
  async ListOrganizationShoutOutTypes(
    filter?: ModelOrganizationShoutOutTypeFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListOrganizationShoutOutTypesQuery> {
    const statement = `query ListOrganizationShoutOutTypes($filter: ModelOrganizationShoutOutTypeFilterInput, $limit: Int, $nextToken: String) {
        listOrganizationShoutOutTypes(
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            organizationID
            overrideTitle
            organizationShoutOutTypeShoutOuTypeId
            shoutOutType {
              __typename
              id
              title
              formalDescription
              informalDescription
              icon
              attributes
              status
              createdAt
              updatedAt
            }
            description
            overrideIcon
            overrideIconIdentityId
            enabled
            organizationAttributes
            disabledAttributes
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListOrganizationShoutOutTypesQuery>(
      response.data.listOrganizationShoutOutTypes
    );
  }
  async GetStandupSurveyConfiguration(
    id: string
  ): Promise<GetStandupSurveyConfigurationQuery> {
    const statement = `query GetStandupSurveyConfiguration($id: ID!) {
        getStandupSurveyConfiguration(id: $id) {
          __typename
          id
          organizationID
          employeeID
          questions {
            __typename
            id
            questionTextArray
            enabled
          }
          shared
          excluded
          frequency
          lastSurveyedDate
          isoWeekday
          status
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetStandupSurveyConfigurationQuery>(
      response.data.getStandupSurveyConfiguration
    );
  }
  async ListStandupSurveyConfigurations(
    filter?: ModelStandupSurveyConfigurationFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListStandupSurveyConfigurationsQuery> {
    const statement = `query ListStandupSurveyConfigurations($filter: ModelStandupSurveyConfigurationFilterInput, $limit: Int, $nextToken: String) {
        listStandupSurveyConfigurations(
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            organizationID
            employeeID
            questions {
              __typename
              id
              questionTextArray
              enabled
            }
            shared
            excluded
            frequency
            lastSurveyedDate
            isoWeekday
            status
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListStandupSurveyConfigurationsQuery>(
      response.data.listStandupSurveyConfigurations
    );
  }
  async GetStandupSurvey(id: string): Promise<GetStandupSurveyQuery> {
    const statement = `query GetStandupSurvey($id: ID!) {
        getStandupSurvey(id: $id) {
          __typename
          id
          organizationID
          standupSurveyConfigurationID
          questionMaps {
            __typename
            id
            version
          }
          respondentEmail
          respondentID
          surveyDate
          feedbackArray
          chatRequest
          meetingRequest
          responseReceived
          responseReceivedDate
          status
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetStandupSurveyQuery>response.data.getStandupSurvey;
  }
  async ListStandupSurveys(
    filter?: ModelStandupSurveyFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListStandupSurveysQuery> {
    const statement = `query ListStandupSurveys($filter: ModelStandupSurveyFilterInput, $limit: Int, $nextToken: String) {
        listStandupSurveys(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            organizationID
            standupSurveyConfigurationID
            questionMaps {
              __typename
              id
              version
            }
            respondentEmail
            respondentID
            surveyDate
            feedbackArray
            chatRequest
            meetingRequest
            responseReceived
            responseReceivedDate
            status
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListStandupSurveysQuery>response.data.listStandupSurveys;
  }
  async EmployeeByOrg(
    orgId?: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelEmployeeFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<EmployeeByOrgQuery> {
    const statement = `query EmployeeByOrg($orgId: String, $sortDirection: ModelSortDirection, $filter: ModelEmployeeFilterInput, $limit: Int, $nextToken: String) {
        employeeByOrg(
          orgId: $orgId
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            authId
            email
            orgId
            groups
            consulting
            managerID
            subordinates
            roles
            firstName
            lastName
            title
            department
            disabled
            disabledDate
            disabledBy
            hireDate
            managerChangeDate
            lastAnnual
            lastOneOnOne
            positionDate
            previousPositionStartDate
            previousPositionTitle
            validEmailToken
            inviteSent
            readiness
            criticalPosition
            createdAt
            updatedAt
            readinessAssessmentDate
            image
            imageIdentityId
            bio
            careerGoals
            relocate
            relocateDate
            workStatus
            workStatusDate
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (orgId) {
      gqlAPIServiceArguments.orgId = orgId;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <EmployeeByOrgQuery>response.data.employeeByOrg;
  }
  async ItemsByEmployee(
    employeeID?: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelConsultantAssociationFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ItemsByEmployeeQuery> {
    const statement = `query ItemsByEmployee($employeeID: String, $sortDirection: ModelSortDirection, $filter: ModelConsultantAssociationFilterInput, $limit: Int, $nextToken: String) {
        itemsByEmployee(
          employeeID: $employeeID
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            employeeID
            organizationID
            incentive
            status
            options
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (employeeID) {
      gqlAPIServiceArguments.employeeID = employeeID;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ItemsByEmployeeQuery>response.data.itemsByEmployee;
  }
  async ObjectiveByOrg(
    organizationID?: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelObjectiveFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ObjectiveByOrgQuery> {
    const statement = `query ObjectiveByOrg($organizationID: String, $sortDirection: ModelSortDirection, $filter: ModelObjectiveFilterInput, $limit: Int, $nextToken: String) {
        objectiveByOrg(
          organizationID: $organizationID
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            display
            definition
            observables
            hints
            organizationID
            organizations
            status
            domain {
              __typename
              id
              key
              display
              excludeFromTPS
              sites
              createdAt
              updatedAt
            }
            objectiveDomainId
            qrCodes {
              __typename
              type
              imageSrc
              title
              label
              qrdata
            }
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (organizationID) {
      gqlAPIServiceArguments.organizationID = organizationID;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ObjectiveByOrgQuery>response.data.objectiveByOrg;
  }
  async SurveysByRespondentTypeDate(
    respondentEmail?: string,
    surveyTypeSurveyDateId?: ModelSurveyByRespondentTypeDateCompositeKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelSurveyFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<SurveysByRespondentTypeDateQuery> {
    const statement = `query SurveysByRespondentTypeDate($respondentEmail: String, $surveyTypeSurveyDateId: ModelSurveyByRespondentTypeDateCompositeKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelSurveyFilterInput, $limit: Int, $nextToken: String) {
        surveysByRespondentTypeDate(
          respondentEmail: $respondentEmail
          surveyTypeSurveyDateId: $surveyTypeSurveyDateId
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            organizationID
            objectID
            surveyType
            surveyDate
            respondentEmail
            participantEmail
            respondentAttended
            surveyResponse
            feedback
            responseReceived
            responseReceivedDate
            status
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (respondentEmail) {
      gqlAPIServiceArguments.respondentEmail = respondentEmail;
    }
    if (surveyTypeSurveyDateId) {
      gqlAPIServiceArguments.surveyTypeSurveyDateId = surveyTypeSurveyDateId;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <SurveysByRespondentTypeDateQuery>(
      response.data.surveysByRespondentTypeDate
    );
  }
  async SurveysByParticipantTypeDate(
    participantEmail?: string,
    surveyTypeSurveyDateId?: ModelSurveyByParticipantTypeDateCompositeKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelSurveyFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<SurveysByParticipantTypeDateQuery> {
    const statement = `query SurveysByParticipantTypeDate($participantEmail: String, $surveyTypeSurveyDateId: ModelSurveyByParticipantTypeDateCompositeKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelSurveyFilterInput, $limit: Int, $nextToken: String) {
        surveysByParticipantTypeDate(
          participantEmail: $participantEmail
          surveyTypeSurveyDateId: $surveyTypeSurveyDateId
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            organizationID
            objectID
            surveyType
            surveyDate
            respondentEmail
            participantEmail
            respondentAttended
            surveyResponse
            feedback
            responseReceived
            responseReceivedDate
            status
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (participantEmail) {
      gqlAPIServiceArguments.participantEmail = participantEmail;
    }
    if (surveyTypeSurveyDateId) {
      gqlAPIServiceArguments.surveyTypeSurveyDateId = surveyTypeSurveyDateId;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <SurveysByParticipantTypeDateQuery>(
      response.data.surveysByParticipantTypeDate
    );
  }
  async SurveysByOrgObjectDate(
    organizationID?: string,
    objectIDSurveyDateId?: ModelSurveyByOrgObjectDateCompositeKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelSurveyFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<SurveysByOrgObjectDateQuery> {
    const statement = `query SurveysByOrgObjectDate($organizationID: String, $objectIDSurveyDateId: ModelSurveyByOrgObjectDateCompositeKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelSurveyFilterInput, $limit: Int, $nextToken: String) {
        surveysByOrgObjectDate(
          organizationID: $organizationID
          objectIDSurveyDateId: $objectIDSurveyDateId
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            organizationID
            objectID
            surveyType
            surveyDate
            respondentEmail
            participantEmail
            respondentAttended
            surveyResponse
            feedback
            responseReceived
            responseReceivedDate
            status
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (organizationID) {
      gqlAPIServiceArguments.organizationID = organizationID;
    }
    if (objectIDSurveyDateId) {
      gqlAPIServiceArguments.objectIDSurveyDateId = objectIDSurveyDateId;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <SurveysByOrgObjectDateQuery>response.data.surveysByOrgObjectDate;
  }
  async SurveysByOrgTypeDate(
    organizationID?: string,
    surveyTypeSurveyDateId?: ModelSurveyByOrgTypeDateCompositeKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelSurveyFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<SurveysByOrgTypeDateQuery> {
    const statement = `query SurveysByOrgTypeDate($organizationID: String, $surveyTypeSurveyDateId: ModelSurveyByOrgTypeDateCompositeKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelSurveyFilterInput, $limit: Int, $nextToken: String) {
        surveysByOrgTypeDate(
          organizationID: $organizationID
          surveyTypeSurveyDateId: $surveyTypeSurveyDateId
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            organizationID
            objectID
            surveyType
            surveyDate
            respondentEmail
            participantEmail
            respondentAttended
            surveyResponse
            feedback
            responseReceived
            responseReceivedDate
            status
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (organizationID) {
      gqlAPIServiceArguments.organizationID = organizationID;
    }
    if (surveyTypeSurveyDateId) {
      gqlAPIServiceArguments.surveyTypeSurveyDateId = surveyTypeSurveyDateId;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <SurveysByOrgTypeDateQuery>response.data.surveysByOrgTypeDate;
  }
  async GetEmployeeImage(employeeID: string): Promise<GetEmployeeImageQuery> {
    const statement = `query GetEmployeeImage($employeeID: String!) {
        getEmployeeImage(employeeID: $employeeID) {
          __typename
          employeeID
          organizationID
          groups
          image
          imageIdentityId
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      employeeID
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetEmployeeImageQuery>response.data.getEmployeeImage;
  }
  async ListEmployeeImages(
    employeeID?: string,
    filter?: ModelEmployeeImageFilterInput,
    limit?: number,
    nextToken?: string,
    sortDirection?: ModelSortDirection
  ): Promise<ListEmployeeImagesQuery> {
    const statement = `query ListEmployeeImages($employeeID: String, $filter: ModelEmployeeImageFilterInput, $limit: Int, $nextToken: String, $sortDirection: ModelSortDirection) {
        listEmployeeImages(
          employeeID: $employeeID
          filter: $filter
          limit: $limit
          nextToken: $nextToken
          sortDirection: $sortDirection
        ) {
          __typename
          items {
            __typename
            employeeID
            organizationID
            groups
            image
            imageIdentityId
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (employeeID) {
      gqlAPIServiceArguments.employeeID = employeeID;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListEmployeeImagesQuery>response.data.listEmployeeImages;
  }
  async GetExternalSurvey(id: string): Promise<GetExternalSurveyQuery> {
    const statement = `query GetExternalSurvey($id: ID!) {
        getExternalSurvey(id: $id) {
          __typename
          id
          surveyType
          surveyDate
          externalRespondentID
          participant {
            __typename
            id
            participantName
          }
          objectiveDetails {
            __typename
            id
            objectiveDisplay
            objectiveDefinition
            businessObjective
            managerDescription
            objectiveObservables
          }
          status
          createdAt
          updatedAt
          respondentAttended
          surveyResponse
          feedback
          responseReceived
          responseReceivedDate
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetExternalSurveyQuery>response.data.getExternalSurvey;
  }
  async ListExternalSurveys(
    filter?: ModelExternalSurveyFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListExternalSurveysQuery> {
    const statement = `query ListExternalSurveys($filter: ModelExternalSurveyFilterInput, $limit: Int, $nextToken: String) {
        listExternalSurveys(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            surveyType
            surveyDate
            externalRespondentID
            participant {
              __typename
              id
              participantName
            }
            objectiveDetails {
              __typename
              id
              objectiveDisplay
              objectiveDefinition
              businessObjective
              managerDescription
              objectiveObservables
            }
            status
            createdAt
            updatedAt
            respondentAttended
            surveyResponse
            feedback
            responseReceived
            responseReceivedDate
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListExternalSurveysQuery>response.data.listExternalSurveys;
  }
  OnCreateOrganizationListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateOrganization">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateOrganization {
        onCreateOrganization {
          __typename
          id
          orgName
          domains
          site
          disabled
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateOrganization">>
  >;

  OnUpdateOrganizationListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateOrganization">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateOrganization {
        onUpdateOrganization {
          __typename
          id
          orgName
          domains
          site
          disabled
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateOrganization">>
  >;

  OnDeleteOrganizationListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteOrganization">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteOrganization {
        onDeleteOrganization {
          __typename
          id
          orgName
          domains
          site
          disabled
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteOrganization">>
  >;

  OnCreateStripeListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateStripe">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateStripe {
        onCreateStripe {
          __typename
          organizationID
          customerID
          subscriptionID
          subscriptionItemID
          planID
          billingName
          billingEmail
          cardToken
          subscriptionStatus
          hmac
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateStripe">>
  >;

  OnUpdateStripeListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateStripe">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateStripe {
        onUpdateStripe {
          __typename
          organizationID
          customerID
          subscriptionID
          subscriptionItemID
          planID
          billingName
          billingEmail
          cardToken
          subscriptionStatus
          hmac
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateStripe">>
  >;

  OnDeleteStripeListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteStripe">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteStripe {
        onDeleteStripe {
          __typename
          organizationID
          customerID
          subscriptionID
          subscriptionItemID
          planID
          billingName
          billingEmail
          cardToken
          subscriptionStatus
          hmac
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteStripe">>
  >;

  OnCreateProcessLogListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateProcessLog">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateProcessLog {
        onCreateProcessLog {
          __typename
          process
          lastRunTime
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateProcessLog">>
  >;

  OnUpdateProcessLogListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateProcessLog">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateProcessLog {
        onUpdateProcessLog {
          __typename
          process
          lastRunTime
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateProcessLog">>
  >;

  OnDeleteProcessLogListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteProcessLog">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteProcessLog {
        onDeleteProcessLog {
          __typename
          process
          lastRunTime
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteProcessLog">>
  >;

  OnCreateOrganizationConfigurationListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateOrganizationConfiguration">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateOrganizationConfiguration {
        onCreateOrganizationConfiguration {
          __typename
          id
          organizationID
          configCode
          configStringValue
          configIntValue
          configBooleanValue
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateOrganizationConfiguration">
    >
  >;

  OnUpdateOrganizationConfigurationListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateOrganizationConfiguration">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateOrganizationConfiguration {
        onUpdateOrganizationConfiguration {
          __typename
          id
          organizationID
          configCode
          configStringValue
          configIntValue
          configBooleanValue
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateOrganizationConfiguration">
    >
  >;

  OnDeleteOrganizationConfigurationListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteOrganizationConfiguration">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteOrganizationConfiguration {
        onDeleteOrganizationConfiguration {
          __typename
          id
          organizationID
          configCode
          configStringValue
          configIntValue
          configBooleanValue
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteOrganizationConfiguration">
    >
  >;

  OnCreateEmployeeListener(
    email?: string
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateEmployee">>
  > {
    const statement = `subscription OnCreateEmployee($email: String) {
        onCreateEmployee(email: $email) {
          __typename
          id
          authId
          email
          orgId
          groups
          consulting
          managerID
          subordinates
          roles
          firstName
          lastName
          title
          department
          disabled
          disabledDate
          disabledBy
          hireDate
          managerChangeDate
          lastAnnual
          lastOneOnOne
          positionDate
          previousPositionStartDate
          previousPositionTitle
          validEmailToken
          inviteSent
          readiness
          criticalPosition
          createdAt
          updatedAt
          readinessAssessmentDate
          image
          imageIdentityId
          bio
          careerGoals
          relocate
          relocateDate
          workStatus
          workStatusDate
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (email) {
      gqlAPIServiceArguments.email = email;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateEmployee">>
    >;
  }

  OnUpdateEmployeeListener(
    email?: string
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateEmployee">>
  > {
    const statement = `subscription OnUpdateEmployee($email: String) {
        onUpdateEmployee(email: $email) {
          __typename
          id
          authId
          email
          orgId
          groups
          consulting
          managerID
          subordinates
          roles
          firstName
          lastName
          title
          department
          disabled
          disabledDate
          disabledBy
          hireDate
          managerChangeDate
          lastAnnual
          lastOneOnOne
          positionDate
          previousPositionStartDate
          previousPositionTitle
          validEmailToken
          inviteSent
          readiness
          criticalPosition
          createdAt
          updatedAt
          readinessAssessmentDate
          image
          imageIdentityId
          bio
          careerGoals
          relocate
          relocateDate
          workStatus
          workStatusDate
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (email) {
      gqlAPIServiceArguments.email = email;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateEmployee">>
    >;
  }

  OnDeleteEmployeeListener(
    email?: string
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteEmployee">>
  > {
    const statement = `subscription OnDeleteEmployee($email: String) {
        onDeleteEmployee(email: $email) {
          __typename
          id
          authId
          email
          orgId
          groups
          consulting
          managerID
          subordinates
          roles
          firstName
          lastName
          title
          department
          disabled
          disabledDate
          disabledBy
          hireDate
          managerChangeDate
          lastAnnual
          lastOneOnOne
          positionDate
          previousPositionStartDate
          previousPositionTitle
          validEmailToken
          inviteSent
          readiness
          criticalPosition
          createdAt
          updatedAt
          readinessAssessmentDate
          image
          imageIdentityId
          bio
          careerGoals
          relocate
          relocateDate
          workStatus
          workStatusDate
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (email) {
      gqlAPIServiceArguments.email = email;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteEmployee">>
    >;
  }

  OnCreateEmployeeDataListener(
    employeeID?: string
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateEmployeeData">>
  > {
    const statement = `subscription OnCreateEmployeeData($employeeID: String) {
        onCreateEmployeeData(employeeID: $employeeID) {
          __typename
          employeeID
          organizationID
          dataCode
          groups
          stringValue
          intValue
          booleanValue
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (employeeID) {
      gqlAPIServiceArguments.employeeID = employeeID;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onCreateEmployeeData">
      >
    >;
  }

  OnUpdateEmployeeDataListener(
    employeeID?: string
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateEmployeeData">>
  > {
    const statement = `subscription OnUpdateEmployeeData($employeeID: String) {
        onUpdateEmployeeData(employeeID: $employeeID) {
          __typename
          employeeID
          organizationID
          dataCode
          groups
          stringValue
          intValue
          booleanValue
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (employeeID) {
      gqlAPIServiceArguments.employeeID = employeeID;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onUpdateEmployeeData">
      >
    >;
  }

  OnDeleteEmployeeDataListener(
    employeeID?: string
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteEmployeeData">>
  > {
    const statement = `subscription OnDeleteEmployeeData($employeeID: String) {
        onDeleteEmployeeData(employeeID: $employeeID) {
          __typename
          employeeID
          organizationID
          dataCode
          groups
          stringValue
          intValue
          booleanValue
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (employeeID) {
      gqlAPIServiceArguments.employeeID = employeeID;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onDeleteEmployeeData">
      >
    >;
  }

  OnCreateReadinessHistoryListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateReadinessHistory">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateReadinessHistory {
        onCreateReadinessHistory {
          __typename
          id
          organizationID
          groups
          employeeID
          readiness
          comments
          opportunities
          createdAt
          createdBy
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateReadinessHistory">
    >
  >;

  OnUpdateReadinessHistoryListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateReadinessHistory">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateReadinessHistory {
        onUpdateReadinessHistory {
          __typename
          id
          organizationID
          groups
          employeeID
          readiness
          comments
          opportunities
          createdAt
          createdBy
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateReadinessHistory">
    >
  >;

  OnDeleteReadinessHistoryListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteReadinessHistory">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteReadinessHistory {
        onDeleteReadinessHistory {
          __typename
          id
          organizationID
          groups
          employeeID
          readiness
          comments
          opportunities
          createdAt
          createdBy
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteReadinessHistory">
    >
  >;

  OnCreateConsultantAssociationListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateConsultantAssociation">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateConsultantAssociation {
        onCreateConsultantAssociation {
          __typename
          employeeID
          organizationID
          incentive
          status
          options
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateConsultantAssociation">
    >
  >;

  OnUpdateConsultantAssociationListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateConsultantAssociation">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateConsultantAssociation {
        onUpdateConsultantAssociation {
          __typename
          employeeID
          organizationID
          incentive
          status
          options
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateConsultantAssociation">
    >
  >;

  OnDeleteConsultantAssociationListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteConsultantAssociation">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteConsultantAssociation {
        onDeleteConsultantAssociation {
          __typename
          employeeID
          organizationID
          incentive
          status
          options
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteConsultantAssociation">
    >
  >;

  OnCreateConsultantFavoritesListener(
    employeeID: string
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateConsultantFavorites">
    >
  > {
    const statement = `subscription OnCreateConsultantFavorites($employeeID: String!) {
        onCreateConsultantFavorites(employeeID: $employeeID) {
          __typename
          employeeID
          organizationID
          favorites
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      employeeID
    };
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onCreateConsultantFavorites">
      >
    >;
  }

  OnUpdateConsultantFavoritesListener(
    employeeID: string
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateConsultantFavorites">
    >
  > {
    const statement = `subscription OnUpdateConsultantFavorites($employeeID: String!) {
        onUpdateConsultantFavorites(employeeID: $employeeID) {
          __typename
          employeeID
          organizationID
          favorites
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      employeeID
    };
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onUpdateConsultantFavorites">
      >
    >;
  }

  OnDeleteConsultantFavoritesListener(
    employeeID: string
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteConsultantFavorites">
    >
  > {
    const statement = `subscription OnDeleteConsultantFavorites($employeeID: String!) {
        onDeleteConsultantFavorites(employeeID: $employeeID) {
          __typename
          employeeID
          organizationID
          favorites
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      employeeID
    };
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onDeleteConsultantFavorites">
      >
    >;
  }

  OnCreateUserActionListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateUserAction">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateUserAction {
        onCreateUserAction {
          __typename
          employeeID
          organizationID
          action
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateUserAction">>
  >;

  OnUpdateUserActionListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateUserAction">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateUserAction {
        onUpdateUserAction {
          __typename
          employeeID
          organizationID
          action
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateUserAction">>
  >;

  OnDeleteUserActionListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteUserAction">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteUserAction {
        onDeleteUserAction {
          __typename
          employeeID
          organizationID
          action
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteUserAction">>
  >;

  OnCreateEmployeeCalendarCodeListener(
    employeeID: string
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateEmployeeCalendarCode">
    >
  > {
    const statement = `subscription OnCreateEmployeeCalendarCode($employeeID: String!) {
        onCreateEmployeeCalendarCode(employeeID: $employeeID) {
          __typename
          employeeID
          code
          redirectUrl
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      employeeID
    };
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onCreateEmployeeCalendarCode">
      >
    >;
  }

  OnUpdateEmployeeCalendarCodeListener(
    employeeID: string
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateEmployeeCalendarCode">
    >
  > {
    const statement = `subscription OnUpdateEmployeeCalendarCode($employeeID: String!) {
        onUpdateEmployeeCalendarCode(employeeID: $employeeID) {
          __typename
          employeeID
          code
          redirectUrl
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      employeeID
    };
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onUpdateEmployeeCalendarCode">
      >
    >;
  }

  OnDeleteEmployeeCalendarCodeListener(
    employeeID: string
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteEmployeeCalendarCode">
    >
  > {
    const statement = `subscription OnDeleteEmployeeCalendarCode($employeeID: String!) {
        onDeleteEmployeeCalendarCode(employeeID: $employeeID) {
          __typename
          employeeID
          code
          redirectUrl
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      employeeID
    };
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onDeleteEmployeeCalendarCode">
      >
    >;
  }

  OnCreateEmployeeScoreListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateEmployeeScore">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateEmployeeScore {
        onCreateEmployeeScore {
          __typename
          id
          employeeID
          organizationID
          score
          scoreType
          scoreID
          scoreStart
          scoreEnd
          specifier
          details
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateEmployeeScore">>
  >;

  OnUpdateEmployeeScoreListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateEmployeeScore">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateEmployeeScore {
        onUpdateEmployeeScore {
          __typename
          id
          employeeID
          organizationID
          score
          scoreType
          scoreID
          scoreStart
          scoreEnd
          specifier
          details
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateEmployeeScore">>
  >;

  OnDeleteEmployeeScoreListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteEmployeeScore">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteEmployeeScore {
        onDeleteEmployeeScore {
          __typename
          id
          employeeID
          organizationID
          score
          scoreType
          scoreID
          scoreStart
          scoreEnd
          specifier
          details
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteEmployeeScore">>
  >;

  OnCreateInspHRationsResponseCounterListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateInspHRationsResponseCounter">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateInspHRationsResponseCounter {
        onCreateInspHRationsResponseCounter {
          __typename
          organizationID
          employeeID
          organizationShoutOutTypeID
          type
          group
          count
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateInspHRationsResponseCounter">
    >
  >;

  OnUpdateInspHRationsResponseCounterListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateInspHRationsResponseCounter">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateInspHRationsResponseCounter {
        onUpdateInspHRationsResponseCounter {
          __typename
          organizationID
          employeeID
          organizationShoutOutTypeID
          type
          group
          count
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateInspHRationsResponseCounter">
    >
  >;

  OnDeleteInspHRationsResponseCounterListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteInspHRationsResponseCounter">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteInspHRationsResponseCounter {
        onDeleteInspHRationsResponseCounter {
          __typename
          organizationID
          employeeID
          organizationShoutOutTypeID
          type
          group
          count
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteInspHRationsResponseCounter">
    >
  >;

  OnCreateInspHRationsOrganizationResponseCounterListener: Observable<
    SubscriptionResponse<
      Pick<
        __SubscriptionContainer,
        "onCreateInspHRationsOrganizationResponseCounter"
      >
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateInspHRationsOrganizationResponseCounter {
        onCreateInspHRationsOrganizationResponseCounter {
          __typename
          organizationID
          employeeID
          organizationShoutOutTypeID
          type
          group
          count
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<
        __SubscriptionContainer,
        "onCreateInspHRationsOrganizationResponseCounter"
      >
    >
  >;

  OnUpdateInspHRationsOrganizationResponseCounterListener: Observable<
    SubscriptionResponse<
      Pick<
        __SubscriptionContainer,
        "onUpdateInspHRationsOrganizationResponseCounter"
      >
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateInspHRationsOrganizationResponseCounter {
        onUpdateInspHRationsOrganizationResponseCounter {
          __typename
          organizationID
          employeeID
          organizationShoutOutTypeID
          type
          group
          count
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<
        __SubscriptionContainer,
        "onUpdateInspHRationsOrganizationResponseCounter"
      >
    >
  >;

  OnDeleteInspHRationsOrganizationResponseCounterListener: Observable<
    SubscriptionResponse<
      Pick<
        __SubscriptionContainer,
        "onDeleteInspHRationsOrganizationResponseCounter"
      >
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteInspHRationsOrganizationResponseCounter {
        onDeleteInspHRationsOrganizationResponseCounter {
          __typename
          organizationID
          employeeID
          organizationShoutOutTypeID
          type
          group
          count
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<
        __SubscriptionContainer,
        "onDeleteInspHRationsOrganizationResponseCounter"
      >
    >
  >;

  OnCreateIntHRactionCounterListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateIntHRactionCounter">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateIntHRactionCounter {
        onCreateIntHRactionCounter {
          __typename
          employeeID
          participantID
          organizationID
          count
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateIntHRactionCounter">
    >
  >;

  OnUpdateIntHRactionCounterListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateIntHRactionCounter">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateIntHRactionCounter {
        onUpdateIntHRactionCounter {
          __typename
          employeeID
          participantID
          organizationID
          count
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateIntHRactionCounter">
    >
  >;

  OnDeleteIntHRactionCounterListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteIntHRactionCounter">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteIntHRactionCounter {
        onDeleteIntHRactionCounter {
          __typename
          employeeID
          participantID
          organizationID
          count
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteIntHRactionCounter">
    >
  >;

  OnCreatePriorityListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreatePriority">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreatePriority {
        onCreatePriority {
          __typename
          id
          key
          display
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreatePriority">>
  >;

  OnUpdatePriorityListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdatePriority">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdatePriority {
        onUpdatePriority {
          __typename
          id
          key
          display
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdatePriority">>
  >;

  OnDeletePriorityListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeletePriority">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeletePriority {
        onDeletePriority {
          __typename
          id
          key
          display
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeletePriority">>
  >;

  OnCreateFocusListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateFocus">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateFocus {
        onCreateFocus {
          __typename
          id
          key
          display
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateFocus">>
  >;

  OnUpdateFocusListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateFocus">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateFocus {
        onUpdateFocus {
          __typename
          id
          key
          display
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateFocus">>
  >;

  OnDeleteFocusListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteFocus">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteFocus {
        onDeleteFocus {
          __typename
          id
          key
          display
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteFocus">>
  >;

  OnCreateObjectiveDomainListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateObjectiveDomain">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateObjectiveDomain {
        onCreateObjectiveDomain {
          __typename
          id
          key
          display
          excludeFromTPS
          sites
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateObjectiveDomain">
    >
  >;

  OnUpdateObjectiveDomainListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateObjectiveDomain">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateObjectiveDomain {
        onUpdateObjectiveDomain {
          __typename
          id
          key
          display
          excludeFromTPS
          sites
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateObjectiveDomain">
    >
  >;

  OnDeleteObjectiveDomainListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteObjectiveDomain">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteObjectiveDomain {
        onDeleteObjectiveDomain {
          __typename
          id
          key
          display
          excludeFromTPS
          sites
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteObjectiveDomain">
    >
  >;

  OnCreateObjectiveListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateObjective">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateObjective {
        onCreateObjective {
          __typename
          id
          display
          definition
          observables
          hints
          organizationID
          organizations
          status
          domain {
            __typename
            id
            key
            display
            excludeFromTPS
            sites
            createdAt
            updatedAt
          }
          objectiveDomainId
          qrCodes {
            __typename
            type
            imageSrc
            title
            label
            qrdata
          }
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateObjective">>
  >;

  OnUpdateObjectiveListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateObjective">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateObjective {
        onUpdateObjective {
          __typename
          id
          display
          definition
          observables
          hints
          organizationID
          organizations
          status
          domain {
            __typename
            id
            key
            display
            excludeFromTPS
            sites
            createdAt
            updatedAt
          }
          objectiveDomainId
          qrCodes {
            __typename
            type
            imageSrc
            title
            label
            qrdata
          }
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateObjective">>
  >;

  OnDeleteObjectiveListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteObjective">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteObjective {
        onDeleteObjective {
          __typename
          id
          display
          definition
          observables
          hints
          organizationID
          organizations
          status
          domain {
            __typename
            id
            key
            display
            excludeFromTPS
            sites
            createdAt
            updatedAt
          }
          objectiveDomainId
          qrCodes {
            __typename
            type
            imageSrc
            title
            label
            qrdata
          }
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteObjective">>
  >;

  OnCreateOrganizationObjectiveListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateOrganizationObjective">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateOrganizationObjective {
        onCreateOrganizationObjective {
          __typename
          id
          organizationID
          objective {
            __typename
            id
            display
            definition
            observables
            hints
            organizationID
            organizations
            status
            domain {
              __typename
              id
              key
              display
              excludeFromTPS
              sites
              createdAt
              updatedAt
            }
            objectiveDomainId
            qrCodes {
              __typename
              type
              imageSrc
              title
              label
              qrdata
            }
            createdAt
            updatedAt
          }
          organizationObjectiveObjectiveId
          enabled
          businessObjective
          status
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateOrganizationObjective">
    >
  >;

  OnUpdateOrganizationObjectiveListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateOrganizationObjective">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateOrganizationObjective {
        onUpdateOrganizationObjective {
          __typename
          id
          organizationID
          objective {
            __typename
            id
            display
            definition
            observables
            hints
            organizationID
            organizations
            status
            domain {
              __typename
              id
              key
              display
              excludeFromTPS
              sites
              createdAt
              updatedAt
            }
            objectiveDomainId
            qrCodes {
              __typename
              type
              imageSrc
              title
              label
              qrdata
            }
            createdAt
            updatedAt
          }
          organizationObjectiveObjectiveId
          enabled
          businessObjective
          status
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateOrganizationObjective">
    >
  >;

  OnDeleteOrganizationObjectiveListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteOrganizationObjective">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteOrganizationObjective {
        onDeleteOrganizationObjective {
          __typename
          id
          organizationID
          objective {
            __typename
            id
            display
            definition
            observables
            hints
            organizationID
            organizations
            status
            domain {
              __typename
              id
              key
              display
              excludeFromTPS
              sites
              createdAt
              updatedAt
            }
            objectiveDomainId
            qrCodes {
              __typename
              type
              imageSrc
              title
              label
              qrdata
            }
            createdAt
            updatedAt
          }
          organizationObjectiveObjectiveId
          enabled
          businessObjective
          status
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteOrganizationObjective">
    >
  >;

  OnCreateObjectiveAssignmentListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateObjectiveAssignment">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateObjectiveAssignment {
        onCreateObjectiveAssignment {
          __typename
          id
          employeeId
          organizationID
          objectiveAssignmentAssignedById
          assignmentDate
          priority {
            __typename
            id
            key
            display
            createdAt
            updatedAt
          }
          focus {
            __typename
            id
            key
            display
            createdAt
            updatedAt
          }
          orgObjective {
            __typename
            id
            organizationID
            objective {
              __typename
              id
              display
              definition
              observables
              hints
              organizationID
              organizations
              status
              domain {
                __typename
                id
                key
                display
                excludeFromTPS
                sites
                createdAt
                updatedAt
              }
              objectiveDomainId
              qrCodes {
                __typename
                type
                imageSrc
                title
                label
                qrdata
              }
              createdAt
              updatedAt
            }
            organizationObjectiveObjectiveId
            enabled
            businessObjective
            status
            createdAt
            updatedAt
          }
          startDate
          endDate
          cadence
          managerDescription
          objectiveType
          title
          quantity
          consultantId
          nextSurveyDate
          status
          externalEmail
          externalEmails
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateObjectiveAssignment">
    >
  >;

  OnUpdateObjectiveAssignmentListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateObjectiveAssignment">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateObjectiveAssignment {
        onUpdateObjectiveAssignment {
          __typename
          id
          employeeId
          organizationID
          objectiveAssignmentAssignedById
          assignmentDate
          priority {
            __typename
            id
            key
            display
            createdAt
            updatedAt
          }
          focus {
            __typename
            id
            key
            display
            createdAt
            updatedAt
          }
          orgObjective {
            __typename
            id
            organizationID
            objective {
              __typename
              id
              display
              definition
              observables
              hints
              organizationID
              organizations
              status
              domain {
                __typename
                id
                key
                display
                excludeFromTPS
                sites
                createdAt
                updatedAt
              }
              objectiveDomainId
              qrCodes {
                __typename
                type
                imageSrc
                title
                label
                qrdata
              }
              createdAt
              updatedAt
            }
            organizationObjectiveObjectiveId
            enabled
            businessObjective
            status
            createdAt
            updatedAt
          }
          startDate
          endDate
          cadence
          managerDescription
          objectiveType
          title
          quantity
          consultantId
          nextSurveyDate
          status
          externalEmail
          externalEmails
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateObjectiveAssignment">
    >
  >;

  OnDeleteObjectiveAssignmentListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteObjectiveAssignment">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteObjectiveAssignment {
        onDeleteObjectiveAssignment {
          __typename
          id
          employeeId
          organizationID
          objectiveAssignmentAssignedById
          assignmentDate
          priority {
            __typename
            id
            key
            display
            createdAt
            updatedAt
          }
          focus {
            __typename
            id
            key
            display
            createdAt
            updatedAt
          }
          orgObjective {
            __typename
            id
            organizationID
            objective {
              __typename
              id
              display
              definition
              observables
              hints
              organizationID
              organizations
              status
              domain {
                __typename
                id
                key
                display
                excludeFromTPS
                sites
                createdAt
                updatedAt
              }
              objectiveDomainId
              qrCodes {
                __typename
                type
                imageSrc
                title
                label
                qrdata
              }
              createdAt
              updatedAt
            }
            organizationObjectiveObjectiveId
            enabled
            businessObjective
            status
            createdAt
            updatedAt
          }
          startDate
          endDate
          cadence
          managerDescription
          objectiveType
          title
          quantity
          consultantId
          nextSurveyDate
          status
          externalEmail
          externalEmails
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteObjectiveAssignment">
    >
  >;

  OnCreateCalendarEventListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateCalendarEvent">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateCalendarEvent {
        onCreateCalendarEvent {
          __typename
          id
          employeeID
          organizationID
          calendarId
          start
          end
          eventCreated
          eventUpdated
          summary
          description
          status
          private
          deleted
          recurring
          locationDescription
          participationStatus
          eventStatus
          organizerEmail
          organizer {
            __typename
            email
            displayName
            status
            createdAt
            updatedAt
          }
          attendees {
            __typename
            email
            displayName
            status
            createdAt
            updatedAt
          }
          attendeeEmails
          meetingURL
          options {
            __typename
            changeParticipationStatus
            delete
            update
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateCalendarEvent">>
  >;

  OnUpdateCalendarEventListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateCalendarEvent">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateCalendarEvent {
        onUpdateCalendarEvent {
          __typename
          id
          employeeID
          organizationID
          calendarId
          start
          end
          eventCreated
          eventUpdated
          summary
          description
          status
          private
          deleted
          recurring
          locationDescription
          participationStatus
          eventStatus
          organizerEmail
          organizer {
            __typename
            email
            displayName
            status
            createdAt
            updatedAt
          }
          attendees {
            __typename
            email
            displayName
            status
            createdAt
            updatedAt
          }
          attendeeEmails
          meetingURL
          options {
            __typename
            changeParticipationStatus
            delete
            update
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateCalendarEvent">>
  >;

  OnDeleteCalendarEventListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteCalendarEvent">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteCalendarEvent {
        onDeleteCalendarEvent {
          __typename
          id
          employeeID
          organizationID
          calendarId
          start
          end
          eventCreated
          eventUpdated
          summary
          description
          status
          private
          deleted
          recurring
          locationDescription
          participationStatus
          eventStatus
          organizerEmail
          organizer {
            __typename
            email
            displayName
            status
            createdAt
            updatedAt
          }
          attendees {
            __typename
            email
            displayName
            status
            createdAt
            updatedAt
          }
          attendeeEmails
          meetingURL
          options {
            __typename
            changeParticipationStatus
            delete
            update
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteCalendarEvent">>
  >;

  OnCreateSurveyListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateSurvey">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateSurvey {
        onCreateSurvey {
          __typename
          id
          organizationID
          objectID
          surveyType
          surveyDate
          respondentEmail
          participantEmail
          respondentAttended
          surveyResponse
          feedback
          responseReceived
          responseReceivedDate
          status
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateSurvey">>
  >;

  OnUpdateSurveyListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateSurvey">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateSurvey {
        onUpdateSurvey {
          __typename
          id
          organizationID
          objectID
          surveyType
          surveyDate
          respondentEmail
          participantEmail
          respondentAttended
          surveyResponse
          feedback
          responseReceived
          responseReceivedDate
          status
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateSurvey">>
  >;

  OnDeleteSurveyListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteSurvey">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteSurvey {
        onDeleteSurvey {
          __typename
          id
          organizationID
          objectID
          surveyType
          surveyDate
          respondentEmail
          participantEmail
          respondentAttended
          surveyResponse
          feedback
          responseReceived
          responseReceivedDate
          status
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteSurvey">>
  >;

  OnCreateExternalRespondentListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateExternalRespondent">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateExternalRespondent {
        onCreateExternalRespondent {
          __typename
          id
          externalRespondentEmail
          externalRespondentName
          externalRespondentStatus
          statusUpdates {
            __typename
            status
            ipAddress
            externalRespondentStatusDate
          }
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateExternalRespondent">
    >
  >;

  OnUpdateExternalRespondentListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateExternalRespondent">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateExternalRespondent {
        onUpdateExternalRespondent {
          __typename
          id
          externalRespondentEmail
          externalRespondentName
          externalRespondentStatus
          statusUpdates {
            __typename
            status
            ipAddress
            externalRespondentStatusDate
          }
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateExternalRespondent">
    >
  >;

  OnDeleteExternalRespondentListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteExternalRespondent">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteExternalRespondent {
        onDeleteExternalRespondent {
          __typename
          id
          externalRespondentEmail
          externalRespondentName
          externalRespondentStatus
          statusUpdates {
            __typename
            status
            ipAddress
            externalRespondentStatusDate
          }
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteExternalRespondent">
    >
  >;

  OnCreateDoNotDisturbRequestListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateDoNotDisturbRequest">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateDoNotDisturbRequest {
        onCreateDoNotDisturbRequest {
          __typename
          id
          externalRespondentID
          externalRespondentEmail
          ipAddress
          status
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateDoNotDisturbRequest">
    >
  >;

  OnUpdateDoNotDisturbRequestListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateDoNotDisturbRequest">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateDoNotDisturbRequest {
        onUpdateDoNotDisturbRequest {
          __typename
          id
          externalRespondentID
          externalRespondentEmail
          ipAddress
          status
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateDoNotDisturbRequest">
    >
  >;

  OnDeleteDoNotDisturbRequestListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteDoNotDisturbRequest">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteDoNotDisturbRequest {
        onDeleteDoNotDisturbRequest {
          __typename
          id
          externalRespondentID
          externalRespondentEmail
          ipAddress
          status
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteDoNotDisturbRequest">
    >
  >;

  OnCreateProjectListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateProject">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateProject {
        onCreateProject {
          __typename
          id
          organizationID
          title
          description
          startDate
          endDate
          cadence
          projectManagerID
          status
          team
          nextSurveyDate
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateProject">>
  >;

  OnUpdateProjectListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateProject">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateProject {
        onUpdateProject {
          __typename
          id
          organizationID
          title
          description
          startDate
          endDate
          cadence
          projectManagerID
          status
          team
          nextSurveyDate
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateProject">>
  >;

  OnDeleteProjectListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteProject">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteProject {
        onDeleteProject {
          __typename
          id
          organizationID
          title
          description
          startDate
          endDate
          cadence
          projectManagerID
          status
          team
          nextSurveyDate
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteProject">>
  >;

  OnCreateMilestoneCommentListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateMilestoneComment">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateMilestoneComment {
        onCreateMilestoneComment {
          __typename
          id
          organizationID
          milestoneID
          projectMilestoneAssignment {
            __typename
            id
            projectID
            organizationID
            assignedBy
            assignmentDate
            startDate
            endDate
            cadence
            milestoneType
            title
            nextSurveyDate
            description
            team
            comments {
              __typename
              items {
                __typename
                id
                organizationID
                milestoneID
                projectMilestoneAssignment {
                  __typename
                  id
                  projectID
                  organizationID
                  assignedBy
                  assignmentDate
                  startDate
                  endDate
                  cadence
                  milestoneType
                  title
                  nextSurveyDate
                  description
                  team
                  comments {
                    __typename
                    items {
                      __typename
                      id
                      organizationID
                      milestoneID
                      projectMilestoneAssignment {
                        __typename
                        id
                        projectID
                        organizationID
                        assignedBy
                        assignmentDate
                        startDate
                        endDate
                        cadence
                        milestoneType
                        title
                        nextSurveyDate
                        description
                        team
                        comments {
                          __typename
                          items {
                            __typename
                            id
                            organizationID
                            milestoneID
                            comment
                            createdAt
                            createdBy
                            updatedAt
                          }
                          nextToken
                        }
                        createdAt
                        updatedAt
                      }
                      comment
                      createdAt
                      createdBy
                      updatedAt
                    }
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                comment
                createdAt
                createdBy
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          comment
          createdAt
          createdBy
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateMilestoneComment">
    >
  >;

  OnUpdateMilestoneCommentListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateMilestoneComment">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateMilestoneComment {
        onUpdateMilestoneComment {
          __typename
          id
          organizationID
          milestoneID
          projectMilestoneAssignment {
            __typename
            id
            projectID
            organizationID
            assignedBy
            assignmentDate
            startDate
            endDate
            cadence
            milestoneType
            title
            nextSurveyDate
            description
            team
            comments {
              __typename
              items {
                __typename
                id
                organizationID
                milestoneID
                projectMilestoneAssignment {
                  __typename
                  id
                  projectID
                  organizationID
                  assignedBy
                  assignmentDate
                  startDate
                  endDate
                  cadence
                  milestoneType
                  title
                  nextSurveyDate
                  description
                  team
                  comments {
                    __typename
                    items {
                      __typename
                      id
                      organizationID
                      milestoneID
                      projectMilestoneAssignment {
                        __typename
                        id
                        projectID
                        organizationID
                        assignedBy
                        assignmentDate
                        startDate
                        endDate
                        cadence
                        milestoneType
                        title
                        nextSurveyDate
                        description
                        team
                        comments {
                          __typename
                          items {
                            __typename
                            id
                            organizationID
                            milestoneID
                            comment
                            createdAt
                            createdBy
                            updatedAt
                          }
                          nextToken
                        }
                        createdAt
                        updatedAt
                      }
                      comment
                      createdAt
                      createdBy
                      updatedAt
                    }
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                comment
                createdAt
                createdBy
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          comment
          createdAt
          createdBy
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateMilestoneComment">
    >
  >;

  OnDeleteMilestoneCommentListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteMilestoneComment">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteMilestoneComment {
        onDeleteMilestoneComment {
          __typename
          id
          organizationID
          milestoneID
          projectMilestoneAssignment {
            __typename
            id
            projectID
            organizationID
            assignedBy
            assignmentDate
            startDate
            endDate
            cadence
            milestoneType
            title
            nextSurveyDate
            description
            team
            comments {
              __typename
              items {
                __typename
                id
                organizationID
                milestoneID
                projectMilestoneAssignment {
                  __typename
                  id
                  projectID
                  organizationID
                  assignedBy
                  assignmentDate
                  startDate
                  endDate
                  cadence
                  milestoneType
                  title
                  nextSurveyDate
                  description
                  team
                  comments {
                    __typename
                    items {
                      __typename
                      id
                      organizationID
                      milestoneID
                      projectMilestoneAssignment {
                        __typename
                        id
                        projectID
                        organizationID
                        assignedBy
                        assignmentDate
                        startDate
                        endDate
                        cadence
                        milestoneType
                        title
                        nextSurveyDate
                        description
                        team
                        comments {
                          __typename
                          items {
                            __typename
                            id
                            organizationID
                            milestoneID
                            comment
                            createdAt
                            createdBy
                            updatedAt
                          }
                          nextToken
                        }
                        createdAt
                        updatedAt
                      }
                      comment
                      createdAt
                      createdBy
                      updatedAt
                    }
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                comment
                createdAt
                createdBy
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          comment
          createdAt
          createdBy
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteMilestoneComment">
    >
  >;

  OnCreateProjectMilestoneAssignmentListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateProjectMilestoneAssignment">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateProjectMilestoneAssignment {
        onCreateProjectMilestoneAssignment {
          __typename
          id
          projectID
          organizationID
          assignedBy
          assignmentDate
          startDate
          endDate
          cadence
          milestoneType
          title
          nextSurveyDate
          description
          team
          comments {
            __typename
            items {
              __typename
              id
              organizationID
              milestoneID
              projectMilestoneAssignment {
                __typename
                id
                projectID
                organizationID
                assignedBy
                assignmentDate
                startDate
                endDate
                cadence
                milestoneType
                title
                nextSurveyDate
                description
                team
                comments {
                  __typename
                  items {
                    __typename
                    id
                    organizationID
                    milestoneID
                    projectMilestoneAssignment {
                      __typename
                      id
                      projectID
                      organizationID
                      assignedBy
                      assignmentDate
                      startDate
                      endDate
                      cadence
                      milestoneType
                      title
                      nextSurveyDate
                      description
                      team
                      comments {
                        __typename
                        items {
                          __typename
                          id
                          organizationID
                          milestoneID
                          projectMilestoneAssignment {
                            __typename
                            id
                            projectID
                            organizationID
                            assignedBy
                            assignmentDate
                            startDate
                            endDate
                            cadence
                            milestoneType
                            title
                            nextSurveyDate
                            description
                            team
                            createdAt
                            updatedAt
                          }
                          comment
                          createdAt
                          createdBy
                          updatedAt
                        }
                        nextToken
                      }
                      createdAt
                      updatedAt
                    }
                    comment
                    createdAt
                    createdBy
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              comment
              createdAt
              createdBy
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateProjectMilestoneAssignment">
    >
  >;

  OnUpdateProjectMilestoneAssignmentListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateProjectMilestoneAssignment">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateProjectMilestoneAssignment {
        onUpdateProjectMilestoneAssignment {
          __typename
          id
          projectID
          organizationID
          assignedBy
          assignmentDate
          startDate
          endDate
          cadence
          milestoneType
          title
          nextSurveyDate
          description
          team
          comments {
            __typename
            items {
              __typename
              id
              organizationID
              milestoneID
              projectMilestoneAssignment {
                __typename
                id
                projectID
                organizationID
                assignedBy
                assignmentDate
                startDate
                endDate
                cadence
                milestoneType
                title
                nextSurveyDate
                description
                team
                comments {
                  __typename
                  items {
                    __typename
                    id
                    organizationID
                    milestoneID
                    projectMilestoneAssignment {
                      __typename
                      id
                      projectID
                      organizationID
                      assignedBy
                      assignmentDate
                      startDate
                      endDate
                      cadence
                      milestoneType
                      title
                      nextSurveyDate
                      description
                      team
                      comments {
                        __typename
                        items {
                          __typename
                          id
                          organizationID
                          milestoneID
                          projectMilestoneAssignment {
                            __typename
                            id
                            projectID
                            organizationID
                            assignedBy
                            assignmentDate
                            startDate
                            endDate
                            cadence
                            milestoneType
                            title
                            nextSurveyDate
                            description
                            team
                            createdAt
                            updatedAt
                          }
                          comment
                          createdAt
                          createdBy
                          updatedAt
                        }
                        nextToken
                      }
                      createdAt
                      updatedAt
                    }
                    comment
                    createdAt
                    createdBy
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              comment
              createdAt
              createdBy
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateProjectMilestoneAssignment">
    >
  >;

  OnDeleteProjectMilestoneAssignmentListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteProjectMilestoneAssignment">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteProjectMilestoneAssignment {
        onDeleteProjectMilestoneAssignment {
          __typename
          id
          projectID
          organizationID
          assignedBy
          assignmentDate
          startDate
          endDate
          cadence
          milestoneType
          title
          nextSurveyDate
          description
          team
          comments {
            __typename
            items {
              __typename
              id
              organizationID
              milestoneID
              projectMilestoneAssignment {
                __typename
                id
                projectID
                organizationID
                assignedBy
                assignmentDate
                startDate
                endDate
                cadence
                milestoneType
                title
                nextSurveyDate
                description
                team
                comments {
                  __typename
                  items {
                    __typename
                    id
                    organizationID
                    milestoneID
                    projectMilestoneAssignment {
                      __typename
                      id
                      projectID
                      organizationID
                      assignedBy
                      assignmentDate
                      startDate
                      endDate
                      cadence
                      milestoneType
                      title
                      nextSurveyDate
                      description
                      team
                      comments {
                        __typename
                        items {
                          __typename
                          id
                          organizationID
                          milestoneID
                          projectMilestoneAssignment {
                            __typename
                            id
                            projectID
                            organizationID
                            assignedBy
                            assignmentDate
                            startDate
                            endDate
                            cadence
                            milestoneType
                            title
                            nextSurveyDate
                            description
                            team
                            createdAt
                            updatedAt
                          }
                          comment
                          createdAt
                          createdBy
                          updatedAt
                        }
                        nextToken
                      }
                      createdAt
                      updatedAt
                    }
                    comment
                    createdAt
                    createdBy
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              comment
              createdAt
              createdBy
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteProjectMilestoneAssignment">
    >
  >;

  OnCreateNoteCommentListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateNoteComment">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateNoteComment {
        onCreateNoteComment {
          __typename
          id
          organizationID
          noteID
          note {
            __typename
            id
            organizationID
            objectID
            createdAt
            createdBy
            updatedAt
            updatedBy
            text
            noteType
            options
            comments {
              __typename
              items {
                __typename
                id
                organizationID
                noteID
                note {
                  __typename
                  id
                  organizationID
                  objectID
                  createdAt
                  createdBy
                  updatedAt
                  updatedBy
                  text
                  noteType
                  options
                  comments {
                    __typename
                    items {
                      __typename
                      id
                      organizationID
                      noteID
                      note {
                        __typename
                        id
                        organizationID
                        objectID
                        createdAt
                        createdBy
                        updatedAt
                        updatedBy
                        text
                        noteType
                        options
                        comments {
                          __typename
                          items {
                            __typename
                            id
                            organizationID
                            noteID
                            comment
                            createdAt
                            createdBy
                            updatedAt
                          }
                          nextToken
                        }
                        historys {
                          __typename
                          items {
                            __typename
                            id
                            organizationID
                            noteID
                            createdAt
                            createdBy
                            text
                            updatedAt
                          }
                          nextToken
                        }
                      }
                      comment
                      createdAt
                      createdBy
                      updatedAt
                    }
                    nextToken
                  }
                  historys {
                    __typename
                    items {
                      __typename
                      id
                      organizationID
                      noteID
                      note {
                        __typename
                        id
                        organizationID
                        objectID
                        createdAt
                        createdBy
                        updatedAt
                        updatedBy
                        text
                        noteType
                        options
                        comments {
                          __typename
                          items {
                            __typename
                            id
                            organizationID
                            noteID
                            comment
                            createdAt
                            createdBy
                            updatedAt
                          }
                          nextToken
                        }
                        historys {
                          __typename
                          items {
                            __typename
                            id
                            organizationID
                            noteID
                            createdAt
                            createdBy
                            text
                            updatedAt
                          }
                          nextToken
                        }
                      }
                      createdAt
                      createdBy
                      text
                      updatedAt
                    }
                    nextToken
                  }
                }
                comment
                createdAt
                createdBy
                updatedAt
              }
              nextToken
            }
            historys {
              __typename
              items {
                __typename
                id
                organizationID
                noteID
                note {
                  __typename
                  id
                  organizationID
                  objectID
                  createdAt
                  createdBy
                  updatedAt
                  updatedBy
                  text
                  noteType
                  options
                  comments {
                    __typename
                    items {
                      __typename
                      id
                      organizationID
                      noteID
                      note {
                        __typename
                        id
                        organizationID
                        objectID
                        createdAt
                        createdBy
                        updatedAt
                        updatedBy
                        text
                        noteType
                        options
                        comments {
                          __typename
                          items {
                            __typename
                            id
                            organizationID
                            noteID
                            comment
                            createdAt
                            createdBy
                            updatedAt
                          }
                          nextToken
                        }
                        historys {
                          __typename
                          items {
                            __typename
                            id
                            organizationID
                            noteID
                            createdAt
                            createdBy
                            text
                            updatedAt
                          }
                          nextToken
                        }
                      }
                      comment
                      createdAt
                      createdBy
                      updatedAt
                    }
                    nextToken
                  }
                  historys {
                    __typename
                    items {
                      __typename
                      id
                      organizationID
                      noteID
                      note {
                        __typename
                        id
                        organizationID
                        objectID
                        createdAt
                        createdBy
                        updatedAt
                        updatedBy
                        text
                        noteType
                        options
                        comments {
                          __typename
                          items {
                            __typename
                            id
                            organizationID
                            noteID
                            comment
                            createdAt
                            createdBy
                            updatedAt
                          }
                          nextToken
                        }
                        historys {
                          __typename
                          items {
                            __typename
                            id
                            organizationID
                            noteID
                            createdAt
                            createdBy
                            text
                            updatedAt
                          }
                          nextToken
                        }
                      }
                      createdAt
                      createdBy
                      text
                      updatedAt
                    }
                    nextToken
                  }
                }
                createdAt
                createdBy
                text
                updatedAt
              }
              nextToken
            }
          }
          comment
          createdAt
          createdBy
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateNoteComment">>
  >;

  OnUpdateNoteCommentListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateNoteComment">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateNoteComment {
        onUpdateNoteComment {
          __typename
          id
          organizationID
          noteID
          note {
            __typename
            id
            organizationID
            objectID
            createdAt
            createdBy
            updatedAt
            updatedBy
            text
            noteType
            options
            comments {
              __typename
              items {
                __typename
                id
                organizationID
                noteID
                note {
                  __typename
                  id
                  organizationID
                  objectID
                  createdAt
                  createdBy
                  updatedAt
                  updatedBy
                  text
                  noteType
                  options
                  comments {
                    __typename
                    items {
                      __typename
                      id
                      organizationID
                      noteID
                      note {
                        __typename
                        id
                        organizationID
                        objectID
                        createdAt
                        createdBy
                        updatedAt
                        updatedBy
                        text
                        noteType
                        options
                        comments {
                          __typename
                          items {
                            __typename
                            id
                            organizationID
                            noteID
                            comment
                            createdAt
                            createdBy
                            updatedAt
                          }
                          nextToken
                        }
                        historys {
                          __typename
                          items {
                            __typename
                            id
                            organizationID
                            noteID
                            createdAt
                            createdBy
                            text
                            updatedAt
                          }
                          nextToken
                        }
                      }
                      comment
                      createdAt
                      createdBy
                      updatedAt
                    }
                    nextToken
                  }
                  historys {
                    __typename
                    items {
                      __typename
                      id
                      organizationID
                      noteID
                      note {
                        __typename
                        id
                        organizationID
                        objectID
                        createdAt
                        createdBy
                        updatedAt
                        updatedBy
                        text
                        noteType
                        options
                        comments {
                          __typename
                          items {
                            __typename
                            id
                            organizationID
                            noteID
                            comment
                            createdAt
                            createdBy
                            updatedAt
                          }
                          nextToken
                        }
                        historys {
                          __typename
                          items {
                            __typename
                            id
                            organizationID
                            noteID
                            createdAt
                            createdBy
                            text
                            updatedAt
                          }
                          nextToken
                        }
                      }
                      createdAt
                      createdBy
                      text
                      updatedAt
                    }
                    nextToken
                  }
                }
                comment
                createdAt
                createdBy
                updatedAt
              }
              nextToken
            }
            historys {
              __typename
              items {
                __typename
                id
                organizationID
                noteID
                note {
                  __typename
                  id
                  organizationID
                  objectID
                  createdAt
                  createdBy
                  updatedAt
                  updatedBy
                  text
                  noteType
                  options
                  comments {
                    __typename
                    items {
                      __typename
                      id
                      organizationID
                      noteID
                      note {
                        __typename
                        id
                        organizationID
                        objectID
                        createdAt
                        createdBy
                        updatedAt
                        updatedBy
                        text
                        noteType
                        options
                        comments {
                          __typename
                          items {
                            __typename
                            id
                            organizationID
                            noteID
                            comment
                            createdAt
                            createdBy
                            updatedAt
                          }
                          nextToken
                        }
                        historys {
                          __typename
                          items {
                            __typename
                            id
                            organizationID
                            noteID
                            createdAt
                            createdBy
                            text
                            updatedAt
                          }
                          nextToken
                        }
                      }
                      comment
                      createdAt
                      createdBy
                      updatedAt
                    }
                    nextToken
                  }
                  historys {
                    __typename
                    items {
                      __typename
                      id
                      organizationID
                      noteID
                      note {
                        __typename
                        id
                        organizationID
                        objectID
                        createdAt
                        createdBy
                        updatedAt
                        updatedBy
                        text
                        noteType
                        options
                        comments {
                          __typename
                          items {
                            __typename
                            id
                            organizationID
                            noteID
                            comment
                            createdAt
                            createdBy
                            updatedAt
                          }
                          nextToken
                        }
                        historys {
                          __typename
                          items {
                            __typename
                            id
                            organizationID
                            noteID
                            createdAt
                            createdBy
                            text
                            updatedAt
                          }
                          nextToken
                        }
                      }
                      createdAt
                      createdBy
                      text
                      updatedAt
                    }
                    nextToken
                  }
                }
                createdAt
                createdBy
                text
                updatedAt
              }
              nextToken
            }
          }
          comment
          createdAt
          createdBy
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateNoteComment">>
  >;

  OnDeleteNoteCommentListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteNoteComment">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteNoteComment {
        onDeleteNoteComment {
          __typename
          id
          organizationID
          noteID
          note {
            __typename
            id
            organizationID
            objectID
            createdAt
            createdBy
            updatedAt
            updatedBy
            text
            noteType
            options
            comments {
              __typename
              items {
                __typename
                id
                organizationID
                noteID
                note {
                  __typename
                  id
                  organizationID
                  objectID
                  createdAt
                  createdBy
                  updatedAt
                  updatedBy
                  text
                  noteType
                  options
                  comments {
                    __typename
                    items {
                      __typename
                      id
                      organizationID
                      noteID
                      note {
                        __typename
                        id
                        organizationID
                        objectID
                        createdAt
                        createdBy
                        updatedAt
                        updatedBy
                        text
                        noteType
                        options
                        comments {
                          __typename
                          items {
                            __typename
                            id
                            organizationID
                            noteID
                            comment
                            createdAt
                            createdBy
                            updatedAt
                          }
                          nextToken
                        }
                        historys {
                          __typename
                          items {
                            __typename
                            id
                            organizationID
                            noteID
                            createdAt
                            createdBy
                            text
                            updatedAt
                          }
                          nextToken
                        }
                      }
                      comment
                      createdAt
                      createdBy
                      updatedAt
                    }
                    nextToken
                  }
                  historys {
                    __typename
                    items {
                      __typename
                      id
                      organizationID
                      noteID
                      note {
                        __typename
                        id
                        organizationID
                        objectID
                        createdAt
                        createdBy
                        updatedAt
                        updatedBy
                        text
                        noteType
                        options
                        comments {
                          __typename
                          items {
                            __typename
                            id
                            organizationID
                            noteID
                            comment
                            createdAt
                            createdBy
                            updatedAt
                          }
                          nextToken
                        }
                        historys {
                          __typename
                          items {
                            __typename
                            id
                            organizationID
                            noteID
                            createdAt
                            createdBy
                            text
                            updatedAt
                          }
                          nextToken
                        }
                      }
                      createdAt
                      createdBy
                      text
                      updatedAt
                    }
                    nextToken
                  }
                }
                comment
                createdAt
                createdBy
                updatedAt
              }
              nextToken
            }
            historys {
              __typename
              items {
                __typename
                id
                organizationID
                noteID
                note {
                  __typename
                  id
                  organizationID
                  objectID
                  createdAt
                  createdBy
                  updatedAt
                  updatedBy
                  text
                  noteType
                  options
                  comments {
                    __typename
                    items {
                      __typename
                      id
                      organizationID
                      noteID
                      note {
                        __typename
                        id
                        organizationID
                        objectID
                        createdAt
                        createdBy
                        updatedAt
                        updatedBy
                        text
                        noteType
                        options
                        comments {
                          __typename
                          items {
                            __typename
                            id
                            organizationID
                            noteID
                            comment
                            createdAt
                            createdBy
                            updatedAt
                          }
                          nextToken
                        }
                        historys {
                          __typename
                          items {
                            __typename
                            id
                            organizationID
                            noteID
                            createdAt
                            createdBy
                            text
                            updatedAt
                          }
                          nextToken
                        }
                      }
                      comment
                      createdAt
                      createdBy
                      updatedAt
                    }
                    nextToken
                  }
                  historys {
                    __typename
                    items {
                      __typename
                      id
                      organizationID
                      noteID
                      note {
                        __typename
                        id
                        organizationID
                        objectID
                        createdAt
                        createdBy
                        updatedAt
                        updatedBy
                        text
                        noteType
                        options
                        comments {
                          __typename
                          items {
                            __typename
                            id
                            organizationID
                            noteID
                            comment
                            createdAt
                            createdBy
                            updatedAt
                          }
                          nextToken
                        }
                        historys {
                          __typename
                          items {
                            __typename
                            id
                            organizationID
                            noteID
                            createdAt
                            createdBy
                            text
                            updatedAt
                          }
                          nextToken
                        }
                      }
                      createdAt
                      createdBy
                      text
                      updatedAt
                    }
                    nextToken
                  }
                }
                createdAt
                createdBy
                text
                updatedAt
              }
              nextToken
            }
          }
          comment
          createdAt
          createdBy
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteNoteComment">>
  >;

  OnCreateNoteListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateNote">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateNote {
        onCreateNote {
          __typename
          id
          organizationID
          objectID
          createdAt
          createdBy
          updatedAt
          updatedBy
          text
          noteType
          options
          comments {
            __typename
            items {
              __typename
              id
              organizationID
              noteID
              note {
                __typename
                id
                organizationID
                objectID
                createdAt
                createdBy
                updatedAt
                updatedBy
                text
                noteType
                options
                comments {
                  __typename
                  items {
                    __typename
                    id
                    organizationID
                    noteID
                    note {
                      __typename
                      id
                      organizationID
                      objectID
                      createdAt
                      createdBy
                      updatedAt
                      updatedBy
                      text
                      noteType
                      options
                      comments {
                        __typename
                        items {
                          __typename
                          id
                          organizationID
                          noteID
                          note {
                            __typename
                            id
                            organizationID
                            objectID
                            createdAt
                            createdBy
                            updatedAt
                            updatedBy
                            text
                            noteType
                            options
                          }
                          comment
                          createdAt
                          createdBy
                          updatedAt
                        }
                        nextToken
                      }
                      historys {
                        __typename
                        items {
                          __typename
                          id
                          organizationID
                          noteID
                          note {
                            __typename
                            id
                            organizationID
                            objectID
                            createdAt
                            createdBy
                            updatedAt
                            updatedBy
                            text
                            noteType
                            options
                          }
                          createdAt
                          createdBy
                          text
                          updatedAt
                        }
                        nextToken
                      }
                    }
                    comment
                    createdAt
                    createdBy
                    updatedAt
                  }
                  nextToken
                }
                historys {
                  __typename
                  items {
                    __typename
                    id
                    organizationID
                    noteID
                    note {
                      __typename
                      id
                      organizationID
                      objectID
                      createdAt
                      createdBy
                      updatedAt
                      updatedBy
                      text
                      noteType
                      options
                      comments {
                        __typename
                        items {
                          __typename
                          id
                          organizationID
                          noteID
                          note {
                            __typename
                            id
                            organizationID
                            objectID
                            createdAt
                            createdBy
                            updatedAt
                            updatedBy
                            text
                            noteType
                            options
                          }
                          comment
                          createdAt
                          createdBy
                          updatedAt
                        }
                        nextToken
                      }
                      historys {
                        __typename
                        items {
                          __typename
                          id
                          organizationID
                          noteID
                          note {
                            __typename
                            id
                            organizationID
                            objectID
                            createdAt
                            createdBy
                            updatedAt
                            updatedBy
                            text
                            noteType
                            options
                          }
                          createdAt
                          createdBy
                          text
                          updatedAt
                        }
                        nextToken
                      }
                    }
                    createdAt
                    createdBy
                    text
                    updatedAt
                  }
                  nextToken
                }
              }
              comment
              createdAt
              createdBy
              updatedAt
            }
            nextToken
          }
          historys {
            __typename
            items {
              __typename
              id
              organizationID
              noteID
              note {
                __typename
                id
                organizationID
                objectID
                createdAt
                createdBy
                updatedAt
                updatedBy
                text
                noteType
                options
                comments {
                  __typename
                  items {
                    __typename
                    id
                    organizationID
                    noteID
                    note {
                      __typename
                      id
                      organizationID
                      objectID
                      createdAt
                      createdBy
                      updatedAt
                      updatedBy
                      text
                      noteType
                      options
                      comments {
                        __typename
                        items {
                          __typename
                          id
                          organizationID
                          noteID
                          note {
                            __typename
                            id
                            organizationID
                            objectID
                            createdAt
                            createdBy
                            updatedAt
                            updatedBy
                            text
                            noteType
                            options
                          }
                          comment
                          createdAt
                          createdBy
                          updatedAt
                        }
                        nextToken
                      }
                      historys {
                        __typename
                        items {
                          __typename
                          id
                          organizationID
                          noteID
                          note {
                            __typename
                            id
                            organizationID
                            objectID
                            createdAt
                            createdBy
                            updatedAt
                            updatedBy
                            text
                            noteType
                            options
                          }
                          createdAt
                          createdBy
                          text
                          updatedAt
                        }
                        nextToken
                      }
                    }
                    comment
                    createdAt
                    createdBy
                    updatedAt
                  }
                  nextToken
                }
                historys {
                  __typename
                  items {
                    __typename
                    id
                    organizationID
                    noteID
                    note {
                      __typename
                      id
                      organizationID
                      objectID
                      createdAt
                      createdBy
                      updatedAt
                      updatedBy
                      text
                      noteType
                      options
                      comments {
                        __typename
                        items {
                          __typename
                          id
                          organizationID
                          noteID
                          note {
                            __typename
                            id
                            organizationID
                            objectID
                            createdAt
                            createdBy
                            updatedAt
                            updatedBy
                            text
                            noteType
                            options
                          }
                          comment
                          createdAt
                          createdBy
                          updatedAt
                        }
                        nextToken
                      }
                      historys {
                        __typename
                        items {
                          __typename
                          id
                          organizationID
                          noteID
                          note {
                            __typename
                            id
                            organizationID
                            objectID
                            createdAt
                            createdBy
                            updatedAt
                            updatedBy
                            text
                            noteType
                            options
                          }
                          createdAt
                          createdBy
                          text
                          updatedAt
                        }
                        nextToken
                      }
                    }
                    createdAt
                    createdBy
                    text
                    updatedAt
                  }
                  nextToken
                }
              }
              createdAt
              createdBy
              text
              updatedAt
            }
            nextToken
          }
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateNote">>
  >;

  OnUpdateNoteListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateNote">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateNote {
        onUpdateNote {
          __typename
          id
          organizationID
          objectID
          createdAt
          createdBy
          updatedAt
          updatedBy
          text
          noteType
          options
          comments {
            __typename
            items {
              __typename
              id
              organizationID
              noteID
              note {
                __typename
                id
                organizationID
                objectID
                createdAt
                createdBy
                updatedAt
                updatedBy
                text
                noteType
                options
                comments {
                  __typename
                  items {
                    __typename
                    id
                    organizationID
                    noteID
                    note {
                      __typename
                      id
                      organizationID
                      objectID
                      createdAt
                      createdBy
                      updatedAt
                      updatedBy
                      text
                      noteType
                      options
                      comments {
                        __typename
                        items {
                          __typename
                          id
                          organizationID
                          noteID
                          note {
                            __typename
                            id
                            organizationID
                            objectID
                            createdAt
                            createdBy
                            updatedAt
                            updatedBy
                            text
                            noteType
                            options
                          }
                          comment
                          createdAt
                          createdBy
                          updatedAt
                        }
                        nextToken
                      }
                      historys {
                        __typename
                        items {
                          __typename
                          id
                          organizationID
                          noteID
                          note {
                            __typename
                            id
                            organizationID
                            objectID
                            createdAt
                            createdBy
                            updatedAt
                            updatedBy
                            text
                            noteType
                            options
                          }
                          createdAt
                          createdBy
                          text
                          updatedAt
                        }
                        nextToken
                      }
                    }
                    comment
                    createdAt
                    createdBy
                    updatedAt
                  }
                  nextToken
                }
                historys {
                  __typename
                  items {
                    __typename
                    id
                    organizationID
                    noteID
                    note {
                      __typename
                      id
                      organizationID
                      objectID
                      createdAt
                      createdBy
                      updatedAt
                      updatedBy
                      text
                      noteType
                      options
                      comments {
                        __typename
                        items {
                          __typename
                          id
                          organizationID
                          noteID
                          note {
                            __typename
                            id
                            organizationID
                            objectID
                            createdAt
                            createdBy
                            updatedAt
                            updatedBy
                            text
                            noteType
                            options
                          }
                          comment
                          createdAt
                          createdBy
                          updatedAt
                        }
                        nextToken
                      }
                      historys {
                        __typename
                        items {
                          __typename
                          id
                          organizationID
                          noteID
                          note {
                            __typename
                            id
                            organizationID
                            objectID
                            createdAt
                            createdBy
                            updatedAt
                            updatedBy
                            text
                            noteType
                            options
                          }
                          createdAt
                          createdBy
                          text
                          updatedAt
                        }
                        nextToken
                      }
                    }
                    createdAt
                    createdBy
                    text
                    updatedAt
                  }
                  nextToken
                }
              }
              comment
              createdAt
              createdBy
              updatedAt
            }
            nextToken
          }
          historys {
            __typename
            items {
              __typename
              id
              organizationID
              noteID
              note {
                __typename
                id
                organizationID
                objectID
                createdAt
                createdBy
                updatedAt
                updatedBy
                text
                noteType
                options
                comments {
                  __typename
                  items {
                    __typename
                    id
                    organizationID
                    noteID
                    note {
                      __typename
                      id
                      organizationID
                      objectID
                      createdAt
                      createdBy
                      updatedAt
                      updatedBy
                      text
                      noteType
                      options
                      comments {
                        __typename
                        items {
                          __typename
                          id
                          organizationID
                          noteID
                          note {
                            __typename
                            id
                            organizationID
                            objectID
                            createdAt
                            createdBy
                            updatedAt
                            updatedBy
                            text
                            noteType
                            options
                          }
                          comment
                          createdAt
                          createdBy
                          updatedAt
                        }
                        nextToken
                      }
                      historys {
                        __typename
                        items {
                          __typename
                          id
                          organizationID
                          noteID
                          note {
                            __typename
                            id
                            organizationID
                            objectID
                            createdAt
                            createdBy
                            updatedAt
                            updatedBy
                            text
                            noteType
                            options
                          }
                          createdAt
                          createdBy
                          text
                          updatedAt
                        }
                        nextToken
                      }
                    }
                    comment
                    createdAt
                    createdBy
                    updatedAt
                  }
                  nextToken
                }
                historys {
                  __typename
                  items {
                    __typename
                    id
                    organizationID
                    noteID
                    note {
                      __typename
                      id
                      organizationID
                      objectID
                      createdAt
                      createdBy
                      updatedAt
                      updatedBy
                      text
                      noteType
                      options
                      comments {
                        __typename
                        items {
                          __typename
                          id
                          organizationID
                          noteID
                          note {
                            __typename
                            id
                            organizationID
                            objectID
                            createdAt
                            createdBy
                            updatedAt
                            updatedBy
                            text
                            noteType
                            options
                          }
                          comment
                          createdAt
                          createdBy
                          updatedAt
                        }
                        nextToken
                      }
                      historys {
                        __typename
                        items {
                          __typename
                          id
                          organizationID
                          noteID
                          note {
                            __typename
                            id
                            organizationID
                            objectID
                            createdAt
                            createdBy
                            updatedAt
                            updatedBy
                            text
                            noteType
                            options
                          }
                          createdAt
                          createdBy
                          text
                          updatedAt
                        }
                        nextToken
                      }
                    }
                    createdAt
                    createdBy
                    text
                    updatedAt
                  }
                  nextToken
                }
              }
              createdAt
              createdBy
              text
              updatedAt
            }
            nextToken
          }
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateNote">>
  >;

  OnDeleteNoteListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteNote">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteNote {
        onDeleteNote {
          __typename
          id
          organizationID
          objectID
          createdAt
          createdBy
          updatedAt
          updatedBy
          text
          noteType
          options
          comments {
            __typename
            items {
              __typename
              id
              organizationID
              noteID
              note {
                __typename
                id
                organizationID
                objectID
                createdAt
                createdBy
                updatedAt
                updatedBy
                text
                noteType
                options
                comments {
                  __typename
                  items {
                    __typename
                    id
                    organizationID
                    noteID
                    note {
                      __typename
                      id
                      organizationID
                      objectID
                      createdAt
                      createdBy
                      updatedAt
                      updatedBy
                      text
                      noteType
                      options
                      comments {
                        __typename
                        items {
                          __typename
                          id
                          organizationID
                          noteID
                          note {
                            __typename
                            id
                            organizationID
                            objectID
                            createdAt
                            createdBy
                            updatedAt
                            updatedBy
                            text
                            noteType
                            options
                          }
                          comment
                          createdAt
                          createdBy
                          updatedAt
                        }
                        nextToken
                      }
                      historys {
                        __typename
                        items {
                          __typename
                          id
                          organizationID
                          noteID
                          note {
                            __typename
                            id
                            organizationID
                            objectID
                            createdAt
                            createdBy
                            updatedAt
                            updatedBy
                            text
                            noteType
                            options
                          }
                          createdAt
                          createdBy
                          text
                          updatedAt
                        }
                        nextToken
                      }
                    }
                    comment
                    createdAt
                    createdBy
                    updatedAt
                  }
                  nextToken
                }
                historys {
                  __typename
                  items {
                    __typename
                    id
                    organizationID
                    noteID
                    note {
                      __typename
                      id
                      organizationID
                      objectID
                      createdAt
                      createdBy
                      updatedAt
                      updatedBy
                      text
                      noteType
                      options
                      comments {
                        __typename
                        items {
                          __typename
                          id
                          organizationID
                          noteID
                          note {
                            __typename
                            id
                            organizationID
                            objectID
                            createdAt
                            createdBy
                            updatedAt
                            updatedBy
                            text
                            noteType
                            options
                          }
                          comment
                          createdAt
                          createdBy
                          updatedAt
                        }
                        nextToken
                      }
                      historys {
                        __typename
                        items {
                          __typename
                          id
                          organizationID
                          noteID
                          note {
                            __typename
                            id
                            organizationID
                            objectID
                            createdAt
                            createdBy
                            updatedAt
                            updatedBy
                            text
                            noteType
                            options
                          }
                          createdAt
                          createdBy
                          text
                          updatedAt
                        }
                        nextToken
                      }
                    }
                    createdAt
                    createdBy
                    text
                    updatedAt
                  }
                  nextToken
                }
              }
              comment
              createdAt
              createdBy
              updatedAt
            }
            nextToken
          }
          historys {
            __typename
            items {
              __typename
              id
              organizationID
              noteID
              note {
                __typename
                id
                organizationID
                objectID
                createdAt
                createdBy
                updatedAt
                updatedBy
                text
                noteType
                options
                comments {
                  __typename
                  items {
                    __typename
                    id
                    organizationID
                    noteID
                    note {
                      __typename
                      id
                      organizationID
                      objectID
                      createdAt
                      createdBy
                      updatedAt
                      updatedBy
                      text
                      noteType
                      options
                      comments {
                        __typename
                        items {
                          __typename
                          id
                          organizationID
                          noteID
                          note {
                            __typename
                            id
                            organizationID
                            objectID
                            createdAt
                            createdBy
                            updatedAt
                            updatedBy
                            text
                            noteType
                            options
                          }
                          comment
                          createdAt
                          createdBy
                          updatedAt
                        }
                        nextToken
                      }
                      historys {
                        __typename
                        items {
                          __typename
                          id
                          organizationID
                          noteID
                          note {
                            __typename
                            id
                            organizationID
                            objectID
                            createdAt
                            createdBy
                            updatedAt
                            updatedBy
                            text
                            noteType
                            options
                          }
                          createdAt
                          createdBy
                          text
                          updatedAt
                        }
                        nextToken
                      }
                    }
                    comment
                    createdAt
                    createdBy
                    updatedAt
                  }
                  nextToken
                }
                historys {
                  __typename
                  items {
                    __typename
                    id
                    organizationID
                    noteID
                    note {
                      __typename
                      id
                      organizationID
                      objectID
                      createdAt
                      createdBy
                      updatedAt
                      updatedBy
                      text
                      noteType
                      options
                      comments {
                        __typename
                        items {
                          __typename
                          id
                          organizationID
                          noteID
                          note {
                            __typename
                            id
                            organizationID
                            objectID
                            createdAt
                            createdBy
                            updatedAt
                            updatedBy
                            text
                            noteType
                            options
                          }
                          comment
                          createdAt
                          createdBy
                          updatedAt
                        }
                        nextToken
                      }
                      historys {
                        __typename
                        items {
                          __typename
                          id
                          organizationID
                          noteID
                          note {
                            __typename
                            id
                            organizationID
                            objectID
                            createdAt
                            createdBy
                            updatedAt
                            updatedBy
                            text
                            noteType
                            options
                          }
                          createdAt
                          createdBy
                          text
                          updatedAt
                        }
                        nextToken
                      }
                    }
                    createdAt
                    createdBy
                    text
                    updatedAt
                  }
                  nextToken
                }
              }
              createdAt
              createdBy
              text
              updatedAt
            }
            nextToken
          }
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteNote">>
  >;

  OnCreateNoteHistoryListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateNoteHistory">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateNoteHistory {
        onCreateNoteHistory {
          __typename
          id
          organizationID
          noteID
          note {
            __typename
            id
            organizationID
            objectID
            createdAt
            createdBy
            updatedAt
            updatedBy
            text
            noteType
            options
            comments {
              __typename
              items {
                __typename
                id
                organizationID
                noteID
                note {
                  __typename
                  id
                  organizationID
                  objectID
                  createdAt
                  createdBy
                  updatedAt
                  updatedBy
                  text
                  noteType
                  options
                  comments {
                    __typename
                    items {
                      __typename
                      id
                      organizationID
                      noteID
                      note {
                        __typename
                        id
                        organizationID
                        objectID
                        createdAt
                        createdBy
                        updatedAt
                        updatedBy
                        text
                        noteType
                        options
                        comments {
                          __typename
                          items {
                            __typename
                            id
                            organizationID
                            noteID
                            comment
                            createdAt
                            createdBy
                            updatedAt
                          }
                          nextToken
                        }
                        historys {
                          __typename
                          items {
                            __typename
                            id
                            organizationID
                            noteID
                            createdAt
                            createdBy
                            text
                            updatedAt
                          }
                          nextToken
                        }
                      }
                      comment
                      createdAt
                      createdBy
                      updatedAt
                    }
                    nextToken
                  }
                  historys {
                    __typename
                    items {
                      __typename
                      id
                      organizationID
                      noteID
                      note {
                        __typename
                        id
                        organizationID
                        objectID
                        createdAt
                        createdBy
                        updatedAt
                        updatedBy
                        text
                        noteType
                        options
                        comments {
                          __typename
                          items {
                            __typename
                            id
                            organizationID
                            noteID
                            comment
                            createdAt
                            createdBy
                            updatedAt
                          }
                          nextToken
                        }
                        historys {
                          __typename
                          items {
                            __typename
                            id
                            organizationID
                            noteID
                            createdAt
                            createdBy
                            text
                            updatedAt
                          }
                          nextToken
                        }
                      }
                      createdAt
                      createdBy
                      text
                      updatedAt
                    }
                    nextToken
                  }
                }
                comment
                createdAt
                createdBy
                updatedAt
              }
              nextToken
            }
            historys {
              __typename
              items {
                __typename
                id
                organizationID
                noteID
                note {
                  __typename
                  id
                  organizationID
                  objectID
                  createdAt
                  createdBy
                  updatedAt
                  updatedBy
                  text
                  noteType
                  options
                  comments {
                    __typename
                    items {
                      __typename
                      id
                      organizationID
                      noteID
                      note {
                        __typename
                        id
                        organizationID
                        objectID
                        createdAt
                        createdBy
                        updatedAt
                        updatedBy
                        text
                        noteType
                        options
                        comments {
                          __typename
                          items {
                            __typename
                            id
                            organizationID
                            noteID
                            comment
                            createdAt
                            createdBy
                            updatedAt
                          }
                          nextToken
                        }
                        historys {
                          __typename
                          items {
                            __typename
                            id
                            organizationID
                            noteID
                            createdAt
                            createdBy
                            text
                            updatedAt
                          }
                          nextToken
                        }
                      }
                      comment
                      createdAt
                      createdBy
                      updatedAt
                    }
                    nextToken
                  }
                  historys {
                    __typename
                    items {
                      __typename
                      id
                      organizationID
                      noteID
                      note {
                        __typename
                        id
                        organizationID
                        objectID
                        createdAt
                        createdBy
                        updatedAt
                        updatedBy
                        text
                        noteType
                        options
                        comments {
                          __typename
                          items {
                            __typename
                            id
                            organizationID
                            noteID
                            comment
                            createdAt
                            createdBy
                            updatedAt
                          }
                          nextToken
                        }
                        historys {
                          __typename
                          items {
                            __typename
                            id
                            organizationID
                            noteID
                            createdAt
                            createdBy
                            text
                            updatedAt
                          }
                          nextToken
                        }
                      }
                      createdAt
                      createdBy
                      text
                      updatedAt
                    }
                    nextToken
                  }
                }
                createdAt
                createdBy
                text
                updatedAt
              }
              nextToken
            }
          }
          createdAt
          createdBy
          text
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateNoteHistory">>
  >;

  OnUpdateNoteHistoryListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateNoteHistory">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateNoteHistory {
        onUpdateNoteHistory {
          __typename
          id
          organizationID
          noteID
          note {
            __typename
            id
            organizationID
            objectID
            createdAt
            createdBy
            updatedAt
            updatedBy
            text
            noteType
            options
            comments {
              __typename
              items {
                __typename
                id
                organizationID
                noteID
                note {
                  __typename
                  id
                  organizationID
                  objectID
                  createdAt
                  createdBy
                  updatedAt
                  updatedBy
                  text
                  noteType
                  options
                  comments {
                    __typename
                    items {
                      __typename
                      id
                      organizationID
                      noteID
                      note {
                        __typename
                        id
                        organizationID
                        objectID
                        createdAt
                        createdBy
                        updatedAt
                        updatedBy
                        text
                        noteType
                        options
                        comments {
                          __typename
                          items {
                            __typename
                            id
                            organizationID
                            noteID
                            comment
                            createdAt
                            createdBy
                            updatedAt
                          }
                          nextToken
                        }
                        historys {
                          __typename
                          items {
                            __typename
                            id
                            organizationID
                            noteID
                            createdAt
                            createdBy
                            text
                            updatedAt
                          }
                          nextToken
                        }
                      }
                      comment
                      createdAt
                      createdBy
                      updatedAt
                    }
                    nextToken
                  }
                  historys {
                    __typename
                    items {
                      __typename
                      id
                      organizationID
                      noteID
                      note {
                        __typename
                        id
                        organizationID
                        objectID
                        createdAt
                        createdBy
                        updatedAt
                        updatedBy
                        text
                        noteType
                        options
                        comments {
                          __typename
                          items {
                            __typename
                            id
                            organizationID
                            noteID
                            comment
                            createdAt
                            createdBy
                            updatedAt
                          }
                          nextToken
                        }
                        historys {
                          __typename
                          items {
                            __typename
                            id
                            organizationID
                            noteID
                            createdAt
                            createdBy
                            text
                            updatedAt
                          }
                          nextToken
                        }
                      }
                      createdAt
                      createdBy
                      text
                      updatedAt
                    }
                    nextToken
                  }
                }
                comment
                createdAt
                createdBy
                updatedAt
              }
              nextToken
            }
            historys {
              __typename
              items {
                __typename
                id
                organizationID
                noteID
                note {
                  __typename
                  id
                  organizationID
                  objectID
                  createdAt
                  createdBy
                  updatedAt
                  updatedBy
                  text
                  noteType
                  options
                  comments {
                    __typename
                    items {
                      __typename
                      id
                      organizationID
                      noteID
                      note {
                        __typename
                        id
                        organizationID
                        objectID
                        createdAt
                        createdBy
                        updatedAt
                        updatedBy
                        text
                        noteType
                        options
                        comments {
                          __typename
                          items {
                            __typename
                            id
                            organizationID
                            noteID
                            comment
                            createdAt
                            createdBy
                            updatedAt
                          }
                          nextToken
                        }
                        historys {
                          __typename
                          items {
                            __typename
                            id
                            organizationID
                            noteID
                            createdAt
                            createdBy
                            text
                            updatedAt
                          }
                          nextToken
                        }
                      }
                      comment
                      createdAt
                      createdBy
                      updatedAt
                    }
                    nextToken
                  }
                  historys {
                    __typename
                    items {
                      __typename
                      id
                      organizationID
                      noteID
                      note {
                        __typename
                        id
                        organizationID
                        objectID
                        createdAt
                        createdBy
                        updatedAt
                        updatedBy
                        text
                        noteType
                        options
                        comments {
                          __typename
                          items {
                            __typename
                            id
                            organizationID
                            noteID
                            comment
                            createdAt
                            createdBy
                            updatedAt
                          }
                          nextToken
                        }
                        historys {
                          __typename
                          items {
                            __typename
                            id
                            organizationID
                            noteID
                            createdAt
                            createdBy
                            text
                            updatedAt
                          }
                          nextToken
                        }
                      }
                      createdAt
                      createdBy
                      text
                      updatedAt
                    }
                    nextToken
                  }
                }
                createdAt
                createdBy
                text
                updatedAt
              }
              nextToken
            }
          }
          createdAt
          createdBy
          text
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateNoteHistory">>
  >;

  OnDeleteNoteHistoryListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteNoteHistory">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteNoteHistory {
        onDeleteNoteHistory {
          __typename
          id
          organizationID
          noteID
          note {
            __typename
            id
            organizationID
            objectID
            createdAt
            createdBy
            updatedAt
            updatedBy
            text
            noteType
            options
            comments {
              __typename
              items {
                __typename
                id
                organizationID
                noteID
                note {
                  __typename
                  id
                  organizationID
                  objectID
                  createdAt
                  createdBy
                  updatedAt
                  updatedBy
                  text
                  noteType
                  options
                  comments {
                    __typename
                    items {
                      __typename
                      id
                      organizationID
                      noteID
                      note {
                        __typename
                        id
                        organizationID
                        objectID
                        createdAt
                        createdBy
                        updatedAt
                        updatedBy
                        text
                        noteType
                        options
                        comments {
                          __typename
                          items {
                            __typename
                            id
                            organizationID
                            noteID
                            comment
                            createdAt
                            createdBy
                            updatedAt
                          }
                          nextToken
                        }
                        historys {
                          __typename
                          items {
                            __typename
                            id
                            organizationID
                            noteID
                            createdAt
                            createdBy
                            text
                            updatedAt
                          }
                          nextToken
                        }
                      }
                      comment
                      createdAt
                      createdBy
                      updatedAt
                    }
                    nextToken
                  }
                  historys {
                    __typename
                    items {
                      __typename
                      id
                      organizationID
                      noteID
                      note {
                        __typename
                        id
                        organizationID
                        objectID
                        createdAt
                        createdBy
                        updatedAt
                        updatedBy
                        text
                        noteType
                        options
                        comments {
                          __typename
                          items {
                            __typename
                            id
                            organizationID
                            noteID
                            comment
                            createdAt
                            createdBy
                            updatedAt
                          }
                          nextToken
                        }
                        historys {
                          __typename
                          items {
                            __typename
                            id
                            organizationID
                            noteID
                            createdAt
                            createdBy
                            text
                            updatedAt
                          }
                          nextToken
                        }
                      }
                      createdAt
                      createdBy
                      text
                      updatedAt
                    }
                    nextToken
                  }
                }
                comment
                createdAt
                createdBy
                updatedAt
              }
              nextToken
            }
            historys {
              __typename
              items {
                __typename
                id
                organizationID
                noteID
                note {
                  __typename
                  id
                  organizationID
                  objectID
                  createdAt
                  createdBy
                  updatedAt
                  updatedBy
                  text
                  noteType
                  options
                  comments {
                    __typename
                    items {
                      __typename
                      id
                      organizationID
                      noteID
                      note {
                        __typename
                        id
                        organizationID
                        objectID
                        createdAt
                        createdBy
                        updatedAt
                        updatedBy
                        text
                        noteType
                        options
                        comments {
                          __typename
                          items {
                            __typename
                            id
                            organizationID
                            noteID
                            comment
                            createdAt
                            createdBy
                            updatedAt
                          }
                          nextToken
                        }
                        historys {
                          __typename
                          items {
                            __typename
                            id
                            organizationID
                            noteID
                            createdAt
                            createdBy
                            text
                            updatedAt
                          }
                          nextToken
                        }
                      }
                      comment
                      createdAt
                      createdBy
                      updatedAt
                    }
                    nextToken
                  }
                  historys {
                    __typename
                    items {
                      __typename
                      id
                      organizationID
                      noteID
                      note {
                        __typename
                        id
                        organizationID
                        objectID
                        createdAt
                        createdBy
                        updatedAt
                        updatedBy
                        text
                        noteType
                        options
                        comments {
                          __typename
                          items {
                            __typename
                            id
                            organizationID
                            noteID
                            comment
                            createdAt
                            createdBy
                            updatedAt
                          }
                          nextToken
                        }
                        historys {
                          __typename
                          items {
                            __typename
                            id
                            organizationID
                            noteID
                            createdAt
                            createdBy
                            text
                            updatedAt
                          }
                          nextToken
                        }
                      }
                      createdAt
                      createdBy
                      text
                      updatedAt
                    }
                    nextToken
                  }
                }
                createdAt
                createdBy
                text
                updatedAt
              }
              nextToken
            }
          }
          createdAt
          createdBy
          text
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteNoteHistory">>
  >;

  OnCreateShoutOutTypeListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateShoutOutType">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateShoutOutType {
        onCreateShoutOutType {
          __typename
          id
          title
          formalDescription
          informalDescription
          icon
          attributes
          status
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateShoutOutType">>
  >;

  OnUpdateShoutOutTypeListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateShoutOutType">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateShoutOutType {
        onUpdateShoutOutType {
          __typename
          id
          title
          formalDescription
          informalDescription
          icon
          attributes
          status
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateShoutOutType">>
  >;

  OnDeleteShoutOutTypeListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteShoutOutType">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteShoutOutType {
        onDeleteShoutOutType {
          __typename
          id
          title
          formalDescription
          informalDescription
          icon
          attributes
          status
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteShoutOutType">>
  >;

  OnCreateOrganizationShoutOutTypeListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateOrganizationShoutOutType">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateOrganizationShoutOutType {
        onCreateOrganizationShoutOutType {
          __typename
          id
          organizationID
          overrideTitle
          organizationShoutOutTypeShoutOuTypeId
          shoutOutType {
            __typename
            id
            title
            formalDescription
            informalDescription
            icon
            attributes
            status
            createdAt
            updatedAt
          }
          description
          overrideIcon
          overrideIconIdentityId
          enabled
          organizationAttributes
          disabledAttributes
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateOrganizationShoutOutType">
    >
  >;

  OnUpdateOrganizationShoutOutTypeListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateOrganizationShoutOutType">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateOrganizationShoutOutType {
        onUpdateOrganizationShoutOutType {
          __typename
          id
          organizationID
          overrideTitle
          organizationShoutOutTypeShoutOuTypeId
          shoutOutType {
            __typename
            id
            title
            formalDescription
            informalDescription
            icon
            attributes
            status
            createdAt
            updatedAt
          }
          description
          overrideIcon
          overrideIconIdentityId
          enabled
          organizationAttributes
          disabledAttributes
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateOrganizationShoutOutType">
    >
  >;

  OnDeleteOrganizationShoutOutTypeListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteOrganizationShoutOutType">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteOrganizationShoutOutType {
        onDeleteOrganizationShoutOutType {
          __typename
          id
          organizationID
          overrideTitle
          organizationShoutOutTypeShoutOuTypeId
          shoutOutType {
            __typename
            id
            title
            formalDescription
            informalDescription
            icon
            attributes
            status
            createdAt
            updatedAt
          }
          description
          overrideIcon
          overrideIconIdentityId
          enabled
          organizationAttributes
          disabledAttributes
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteOrganizationShoutOutType">
    >
  >;

  OnCreateStandupSurveyConfigurationListener(
    employeeID?: string
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateStandupSurveyConfiguration">
    >
  > {
    const statement = `subscription OnCreateStandupSurveyConfiguration($employeeID: String) {
        onCreateStandupSurveyConfiguration(employeeID: $employeeID) {
          __typename
          id
          organizationID
          employeeID
          questions {
            __typename
            id
            questionTextArray
            enabled
          }
          shared
          excluded
          frequency
          lastSurveyedDate
          isoWeekday
          status
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (employeeID) {
      gqlAPIServiceArguments.employeeID = employeeID;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onCreateStandupSurveyConfiguration">
      >
    >;
  }

  OnUpdateStandupSurveyConfigurationListener(
    employeeID?: string
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateStandupSurveyConfiguration">
    >
  > {
    const statement = `subscription OnUpdateStandupSurveyConfiguration($employeeID: String) {
        onUpdateStandupSurveyConfiguration(employeeID: $employeeID) {
          __typename
          id
          organizationID
          employeeID
          questions {
            __typename
            id
            questionTextArray
            enabled
          }
          shared
          excluded
          frequency
          lastSurveyedDate
          isoWeekday
          status
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (employeeID) {
      gqlAPIServiceArguments.employeeID = employeeID;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onUpdateStandupSurveyConfiguration">
      >
    >;
  }

  OnDeleteStandupSurveyConfigurationListener(
    employeeID?: string
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteStandupSurveyConfiguration">
    >
  > {
    const statement = `subscription OnDeleteStandupSurveyConfiguration($employeeID: String) {
        onDeleteStandupSurveyConfiguration(employeeID: $employeeID) {
          __typename
          id
          organizationID
          employeeID
          questions {
            __typename
            id
            questionTextArray
            enabled
          }
          shared
          excluded
          frequency
          lastSurveyedDate
          isoWeekday
          status
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (employeeID) {
      gqlAPIServiceArguments.employeeID = employeeID;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onDeleteStandupSurveyConfiguration">
      >
    >;
  }

  OnCreateStandupSurveyListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateStandupSurvey">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateStandupSurvey {
        onCreateStandupSurvey {
          __typename
          id
          organizationID
          standupSurveyConfigurationID
          questionMaps {
            __typename
            id
            version
          }
          respondentEmail
          respondentID
          surveyDate
          feedbackArray
          chatRequest
          meetingRequest
          responseReceived
          responseReceivedDate
          status
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateStandupSurvey">>
  >;

  OnUpdateStandupSurveyListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateStandupSurvey">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateStandupSurvey {
        onUpdateStandupSurvey {
          __typename
          id
          organizationID
          standupSurveyConfigurationID
          questionMaps {
            __typename
            id
            version
          }
          respondentEmail
          respondentID
          surveyDate
          feedbackArray
          chatRequest
          meetingRequest
          responseReceived
          responseReceivedDate
          status
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateStandupSurvey">>
  >;

  OnDeleteStandupSurveyListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteStandupSurvey">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteStandupSurvey {
        onDeleteStandupSurvey {
          __typename
          id
          organizationID
          standupSurveyConfigurationID
          questionMaps {
            __typename
            id
            version
          }
          respondentEmail
          respondentID
          surveyDate
          feedbackArray
          chatRequest
          meetingRequest
          responseReceived
          responseReceivedDate
          status
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteStandupSurvey">>
  >;

  OnCreateEmployeeImageListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateEmployeeImage">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateEmployeeImage {
        onCreateEmployeeImage {
          __typename
          employeeID
          organizationID
          groups
          image
          imageIdentityId
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateEmployeeImage">>
  >;

  OnUpdateEmployeeImageListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateEmployeeImage">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateEmployeeImage {
        onUpdateEmployeeImage {
          __typename
          employeeID
          organizationID
          groups
          image
          imageIdentityId
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateEmployeeImage">>
  >;

  OnDeleteEmployeeImageListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteEmployeeImage">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteEmployeeImage {
        onDeleteEmployeeImage {
          __typename
          employeeID
          organizationID
          groups
          image
          imageIdentityId
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteEmployeeImage">>
  >;

  OnCreateExternalSurveyListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateExternalSurvey">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateExternalSurvey {
        onCreateExternalSurvey {
          __typename
          id
          surveyType
          surveyDate
          externalRespondentID
          participant {
            __typename
            id
            participantName
          }
          objectiveDetails {
            __typename
            id
            objectiveDisplay
            objectiveDefinition
            businessObjective
            managerDescription
            objectiveObservables
          }
          status
          createdAt
          updatedAt
          respondentAttended
          surveyResponse
          feedback
          responseReceived
          responseReceivedDate
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateExternalSurvey">
    >
  >;

  OnUpdateExternalSurveyListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateExternalSurvey">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateExternalSurvey {
        onUpdateExternalSurvey {
          __typename
          id
          surveyType
          surveyDate
          externalRespondentID
          participant {
            __typename
            id
            participantName
          }
          objectiveDetails {
            __typename
            id
            objectiveDisplay
            objectiveDefinition
            businessObjective
            managerDescription
            objectiveObservables
          }
          status
          createdAt
          updatedAt
          respondentAttended
          surveyResponse
          feedback
          responseReceived
          responseReceivedDate
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateExternalSurvey">
    >
  >;

  OnDeleteExternalSurveyListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteExternalSurvey">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteExternalSurvey {
        onDeleteExternalSurvey {
          __typename
          id
          surveyType
          surveyDate
          externalRespondentID
          participant {
            __typename
            id
            participantName
          }
          objectiveDetails {
            __typename
            id
            objectiveDisplay
            objectiveDefinition
            businessObjective
            managerDescription
            objectiveObservables
          }
          status
          createdAt
          updatedAt
          respondentAttended
          surveyResponse
          feedback
          responseReceived
          responseReceivedDate
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteExternalSurvey">
    >
  >;
}
