import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { ObjectiveAssignment } from "@inthraction/data-models";
import { ObjectiveSurveyResponseOptionsDisplay } from "@inthraction/labels";
import { TableData } from "../TableData";

@Component({
  selector: "app-objective-distribution-list",
  templateUrl: "./objective-distribution-list.component.html",
  styleUrls: ["./objective-distribution-list.component.scss"]
})
export class ObjectiveDistributionListComponent implements OnInit {

  displayedColumns: string[] = ["department", "manager", "name", "objectiveType", "focus", "priority", "score", "rank", "start", "end"];

  readonly domain: string;
  readonly title: string;

  tableData: TableData[];

  readonly OBJECTIVE_SURVEY_RESPONSE_LABELS = ObjectiveSurveyResponseOptionsDisplay;

  constructor(
    public dialogRef: MatDialogRef<ObjectiveDistributionListComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { assignments: { assignment: ObjectiveAssignment, score: number, rank: number }[], tableData: TableData[], domain: string, title: string }
  ) {
    this.tableData = this.sortTableData(data.tableData);
    this.domain = data.domain;
    this.title = data.title;
  }

  async ngOnInit() {
  }

  private sortTableData(tableData: TableData[]): TableData[] {
    return tableData.sort((a, b) => {
      if (a.department < b.department) {
        return -1;
      }
      if (a.department > b.department) {
        return 1;
      }
      if (a.manager < b.manager) {
        return -1;
      }
      if (a.manager > b.manager) {
        return 1;
      }
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    });
  }
}
