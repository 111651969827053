import * as awsConfig from "./aws-exports.js";
import { Auth } from 'aws-amplify';


export const AWSConfig = {
  ...awsConfig.default,
  // Switch to IDToken so email and custom claims are available, cognito pretoken function only updates the ID Token, not the Access Token... why?
  graphql_headers: async () => {
    try {
      const token = (await Auth.currentSession()).getIdToken().getJwtToken();
      return { Authorization: token };
    } catch (e) {
      console.error(e);
      return {};
    }
  }
};
