<form>
  <mat-form-field class="full-width" appearance="outline">
    <mat-label>Search for an Organization</mat-label>
    <input type="text" matInput
           [formControl]="control"
           [matAutocomplete]="auto">
    <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" class="organization-search-auto">
      <mat-option *ngFor="let organization of filteredOrganizations | async" [value]="organization" class="organization-search-option">
        <span>{{organization.orgName}}</span>
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
</form>
