import { Component, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Location } from "@angular/common";
import { EmployeeService } from "@inthraction/services";
import { ConsultantFavorites, Employee} from "@inthraction/data-models";
import { CONSULTANT_FAVORITES_HELP } from "@inthraction/labels";

@Component({
  selector: "inthraction-consultant-favorites",
  templateUrl: "./consultant-favorites.component.html",
  styleUrls: ["./consultant-favorites.component.scss"]
})
export class ConsultantFavoritesComponent implements OnInit {

  readonly CONSULTANT_FAVORITES_HELP = CONSULTANT_FAVORITES_HELP;

  @Input() private organizationID: string;
  @Input() private consultantFavorites: ConsultantFavorites;

  topEmployees: Employee[] = [];
  favorites: string[] = [];
  navToEmployeeID: string;

  constructor(
    private router: Router,
    private location: Location,
    private employeeService: EmployeeService
  ) {
  }

  async ngOnInit() {
    await this.initializeData(this.consultantFavorites);
  }

  private async initializeData(consultantFavorites: ConsultantFavorites) {
    this.topEmployees = [];
    const top = [];
    this.favorites = this.consultantFavorites?.favorites;
    for (const id of this.favorites) {
      const employee = await this.employeeService.getEmployeeByIDMemoize(id);
      if (!employee.disabled) {
        top.push(employee);
      }
    }
    this.topEmployees = top;
  }

  employeeChanged(event: string) {
    this.location.go(`consultant/${this.organizationID}/workforce/${event}`);
  }

  hrEditEmployee(employee: Employee) {
    this.router.navigate([`consultant/${employee.orgId}/workforce/${employee.id}/edit`]);
  }

  async favoriteToggle(employee: Employee) {
    if (this.favorites.includes(employee.id)) {
      this.favorites = this.favorites.filter(id => id !== employee.id);
    } else {
      this.favorites.push(employee.id);
    }
    if (this.consultantFavorites) {
      this.consultantFavorites.favorites = this.favorites;
      this.consultantFavorites = await this.employeeService.updateConsultantFavorites(this.consultantFavorites);
    } else {
      this.consultantFavorites = await this.employeeService.createConsultantFavorites({
          employeeID: null,
          organizationID: this.organizationID,
          favorites: this.favorites
        }
      );
    }
  }
}
