import { Component } from '@angular/core';
import { HrEmployeeEditComponent, PageMode } from "../../hr/hr-employee-edit/hr-employee-edit.component";

@Component({
  selector: 'app-consultant-employee-edit',
  templateUrl: '../../hr/hr-employee-edit/hr-employee-edit.component.html',
  styleUrls: ['../../hr/hr-employee-edit/hr-employee-edit.component.scss']
})
export class ConsultantEmployeeEditComponent extends HrEmployeeEditComponent {

  get mode(): PageMode {
    return PageMode.CONSULTANT;
  }

}
