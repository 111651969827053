import { Component, HostListener, Inject, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { OrganizationShoutOutType, ShoutOutType, UpdateOrganizationShoutOutType } from "@inthraction/data-models";
import { ShoutOutService } from "@inthraction/services";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { MatSelectChange } from "@angular/material/select";
import { MatSlideToggleChange } from "@angular/material/slide-toggle";

@Component({
  selector: "inthraction-edit-organization-shout-out-type-dialog",
  templateUrl: "./edit-organization-shout-out-type-dialog.component.html",
  styleUrls: ["./edit-organization-shout-out-type-dialog.component.scss"]
})
export class EditOrganizationShoutOutTypeDialogComponent implements OnInit {

  addMode: boolean;
  shoutOutTypeForm: FormGroup;
  selectShoutOutTypeFG: FormGroup;
  disabledAttributes: number[];
  organizationAttributes: string[];
  selectedShoutOutType: ShoutOutType;
  readonly shoutOutTypes: ShoutOutType[] = [];
  private titleFC = new FormControl("", [Validators.required]);
  private descriptionFC = new FormControl("", [Validators.required]);
  private shoutOutIdFC = new FormControl("", [Validators.required]);
  private shoutOutId2FC = new FormControl("", [Validators.required]);
  private organizationShoutOutType: OrganizationShoutOutType;
  private organizationID: string;

  constructor(
    public dialogRef: MatDialogRef<EditOrganizationShoutOutTypeDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private shoutOutService: ShoutOutService
  ) {

    this.selectShoutOutTypeFG = new FormGroup({
      shoutOutId: this.shoutOutIdFC
    });

    this.shoutOutTypeForm = new FormGroup({
      title: this.titleFC,
      description: this.descriptionFC,
      shoutOutId: this.shoutOutId2FC
    });

    if (data.addMode) {
      this.addMode = true;
      this.organizationID = this.data.organizationID;
      this.organizationShoutOutType = {
        __typename: "OrganizationShoutOutType",
        id: null,
        overrideTitle: null,
        description: null,
        organizationID: this.data.organizationID,
        organizationShoutOutTypeShoutOuTypeId: null,
        shoutOutType: null,
        overrideIcon: undefined,
        overrideIconIdentityId: undefined,
        enabled: false
      };
      this.disabledAttributes = [];
      this.organizationAttributes = [];
    } else {
      this.organizationID = this.data.organizationShoutOutType.organizationID;
      this.addMode = false;
      this.organizationShoutOutType = JSON.parse((JSON.stringify(data.organizationShoutOutType)));
      this.selectedShoutOutType = this.organizationShoutOutType.shoutOutType;
      this.titleFC.setValue(this.organizationShoutOutType.overrideTitle);
      this.descriptionFC.setValue(this.organizationShoutOutType.description);
      this.shoutOutId2FC.setValue(this.organizationShoutOutType.organizationShoutOutTypeShoutOuTypeId);
      this.disabledAttributes = [];
      if (this.organizationShoutOutType.disabledAttributes) {
        this.disabledAttributes.push(...this.organizationShoutOutType.disabledAttributes);
      }
      this.organizationAttributes = [];
      if (this.organizationShoutOutType.organizationAttributes) {
        this.organizationAttributes.push(...this.organizationShoutOutType.organizationAttributes);
      }
    }
  }

  async ngOnInit() {
    const currentOrgShoutOutTypes = await this.shoutOutService.getOrganizationShoutOutTypes(this.organizationID);
    const currentShoutOutTypeIDs: string[] = currentOrgShoutOutTypes.map(st => st.organizationShoutOutTypeShoutOuTypeId);
    const shoutOutTypes = await this.shoutOutService.getShoutOutTypes();
    this.shoutOutTypes.push(...shoutOutTypes.filter(sot => !currentShoutOutTypeIDs.includes(sot.id)));
  }

  async onSaveClick(value: any) {
    let result: OrganizationShoutOutType;
    if (this.addMode) {
      result = await this.shoutOutService.createOrganizationShoutOutType({
        organizationID: this.organizationID,
        overrideTitle: value.title,
        description: value.description,
        organizationShoutOutTypeShoutOuTypeId: this.selectedShoutOutType.id,
        disabledAttributes: this.disabledAttributes,
        organizationAttributes: this.organizationAttributes
      });
    } else {
      const updateType: UpdateOrganizationShoutOutType = { id: this.organizationShoutOutType.id };
      if (this.organizationShoutOutType.overrideTitle !== value.title) {
        updateType.overrideTitle = value.title;
      }
      if (this.organizationShoutOutType.description !== value.description) {
        updateType.description = value.description;
      }
      this.organizationAttributes = this.organizationAttributes.filter(value1 => value1.trim().length > 0);
      updateType.organizationAttributes = this.organizationAttributes;
      updateType.disabledAttributes = this.disabledAttributes;
      result = await this.shoutOutService.updateOrganizationShoutOutType(updateType);
    }
    this.dialogRef.close({ shoutOutType: result });
  }


  onCancelClick() {
    this.dialogRef.close(null);
  }

  @HostListener("keydown.esc")
  public onEsc() {
    this.onCancelClick();
  }

  public hasError = (controlName: string, errorName: string) =>
    this.shoutOutTypeForm.controls[controlName].hasError(errorName);

  setDescription(formal?: boolean) {
    if (formal) {
      this.shoutOutTypeForm.get("description").setValue(this.selectedShoutOutType.formalDescription);
    } else {
      this.shoutOutTypeForm.get("description").setValue(this.selectedShoutOutType.informalDescription);
    }
  }

  shoutOutChange($event: MatSelectChange) {
    this.selectedShoutOutType = this.shoutOutTypes.filter(t => t.id === $event.value)[0];
    this.shoutOutTypeForm.reset();
    this.shoutOutTypeForm.get("shoutOutId").setValue(this.selectedShoutOutType.id);
    this.shoutOutTypeForm.get("title").setValue(this.selectedShoutOutType.title);
  }

  updateAttributeStatus(event: MatSlideToggleChange, i: number) {
    if (event.checked) {
      this.disabledAttributes = this.disabledAttributes.filter(value => value !== i);
    } else {
      this.disabledAttributes.push(i);
    }
  }
}
