import { OrganizationDataMapper } from "@inthraction/data-mappers";

export class Organization implements OrganizationDataMapper {
  id: string;
  __typename = "Organization";
  orgName: string;
  domains: string[];
  site: string;
  disabled?: boolean;
}
