/**
 *
 * Duplicate implementation of InspHRationCounterType from API.service.ts
 */
export enum InspHRationCounterTypeCodes {
  WEEK = "WEEK",
  MONTH = "MONTH",
  QTR = "QTR",
  YEAR = "YEAR"
}

export enum ShoutOutTypeStatus {
  DELETED = "DELETED"
}

export enum ShoutOutIcons {
  ACCOUNTABILITY = "accountability",
  CHANGE_CHAMPION = "change-champion",
  COMMUNICATION = "communication",
  COURAGE = "courage",
  CREATING = "creating",
  CUSTOMER_EXPERIENCE = "customer-experience",
  EMPATHY = "empathy",
  EMPOWERMENT = "empowerment",
  FUN = "fun",
  GROWTH_MINDSET = "growth-mindset",
  HUMILITY = "humility",
  INCLUSION = "inclusion",
  INITIATIVE = "initiative",
  INNOVATION = "innovation",
  INTEGRITY = "integrity",
  LEADERSHIP = "leadership",
  PASSION = "passion",
  QUALITY = "quality",
  RESPECT = "respect",
  SAFETY = "safety",
  TEAMWORK = "teamwork",
  VOLUNTEER = "volunteer"
}
