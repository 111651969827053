export const OBJECTIVE_TYPE_LABELS = {
  DEFINED: "Pre-Defined (Peer Rated)",
  DEFINED_SELF: "Pre-Defined (Self Rated)",
  DEFINED_MANAGER: "Pre-Defined (Manager Rated)",
  DEFINED_EXTERNAL: "Pre-Defined (External Rated)",
  QUANTIFIABLE: "Manager Defined / Quantifiable"
} as const;

export const CADENCE_TYPE_LABELS = {
  WEEKLY: "Weekly",
  BI_WEEKLY: "Bi-Weekly",
  MONTHLY: "Monthly",
  QUARTERLY: "Quarterly",
  ANNUALLY: "Annually"
} as const;

export const ProgressStatusLabels = {
  NOCHANGE: "No Change",
  AWARENESS: "Greater Awareness",
  PROGRESS: "Made Progress",
  GOAL: "Reached Goal"
};
