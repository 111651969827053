<div class="survey-page-container">
  <div></div>
  <mat-card appearance="outlined" *ngIf="employee && standupConfiguration && survey">
    <mat-card-header>]
      <inthraction-profile-image [employeeImage]="employeeImage"></inthraction-profile-image>
      <div class="employee-info">
        <mat-card-title>Stand-Up Survey</mat-card-title>
        <mat-card-subtitle>{{employee.firstName}}&nbsp;{{employee.lastName}}</mat-card-subtitle>
        <mat-card-subtitle>{{survey.surveyDate | amParse:'YYYY-MM-DDTHH:mm:ssZ' | amDateFormat:'LLL'}}</mat-card-subtitle>
      </div>
    </mat-card-header>
    <form (ngSubmit)="submitSurvey(surveyForm.value, submitBtn)" [formGroup]="surveyForm" autocomplete="off" novalidate>
      <mat-card-content>
        <div class="survey-container">
          <ng-container *ngFor="let questionMap of survey.questionMaps; let i = index">
            <mat-form-field class="full-width">
              <mat-label>{{getStandupQuestion(standupConfiguration, questionMap)}}</mat-label>
              <textarea matInput [formControlName]="'feedback'+ i" [id]="'feedback'+i" maxlength="500" [name]="getStandupQuestion(standupConfiguration, questionMap)" rows="3"></textarea>
              <mat-error *ngIf="hasError('feedback'+i, 'required')">A response is required</mat-error>
            </mat-form-field>
          </ng-container>
          <section>
            <mat-checkbox #chatRequest formControlName="chatRequest" (change)="changeCheckBoxEvent($event, meetingRequest, 'meetingRequest')">Can you stop by for a chat</mat-checkbox>
          </section>
          <section>
            <mat-checkbox #meetingRequest formControlName="meetingRequest" (change)="changeCheckBoxEvent($event, chatRequest,'chatRequest')">Can we setup a meeting</mat-checkbox>
          </section>
        </div>
      </mat-card-content>
      <mat-card-actions>
        <button #submitBtn [disabled]="!surveyForm.valid" color="primary" mat-raised-button type="submit">Submit</button>
      </mat-card-actions>
    </form>
    <mat-card-content class="footer">
<!--      <mat-card-subtitle>Your comments are only shared with your Manager and Human Resources.</mat-card-subtitle>-->
      <span class="btm-cnr-right" *ngIf="remainingSurveysCount && remainingSurveysCount > 0">1 of {{remainingSurveysCount}}</span>
    </mat-card-content>
  </mat-card>
  <div></div>
</div>
