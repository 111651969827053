import { NgModule } from "@angular/core";
import { PreloadAllModules, RouterModule, Routes } from "@angular/router";
import { ProfileComponent } from "./pages/profile/profile.component";
import { InactiveEmployeeComponent } from "./components/inactive-employee/inactive-employee.component";
import { ManagerWorkforceComponent } from "./pages/manager/manager-workforce/manager-workforce.component";
import { EmployeeSettingsComponent } from "./components/employee-settings/employee-settings.component";
import { OrgchartComponent } from "./pages/admin/orgchart/orgchart.component";
import {
  ObjectiveConfigurationComponent
} from "./components/objective-configuration/objective-configuration.component";
import {
  EmployeeDashboardLoaderComponent
} from "./pages/dashboard/employee-dashboard/employee-dashboard-loader.component";
import {
  AuthorizeCalendarAccessComponent
} from "./components/authorize-calendar-access/authorize-calendar-access.component";
import {
  SystemObjectiveEditorComponent
} from "./pages/super-admin/system-objective-editor/system-objective-editor.component";
import { SubscriptionComponent } from "./pages/admin/subscription/subscription.component";
import { ProjectDashboardComponent } from "./pages/dashboard/project-dashboard/project-dashboard.component";
import { HrWorkforceComponent } from "./pages/hr/hr-workforce/hr-workforce.component";
import { ExecutiveDashboardComponent } from "./pages/dashboard/executive-dashboard/executive-dashboard.component";
import { HrEmployeeEditComponent } from "./pages/hr/hr-employee-edit/hr-employee-edit.component";
import { OneOnOneComponent } from "./components/shared/one-on-one/one-on-one.component";
import { SystemConfigurationComponent } from "./pages/admin/system-configuration/system-configuration.component";
import { ShoutoutConfigurationComponent } from "./pages/admin/shoutout-configuration/shoutout-configuration.component";
import {
  SystemShoutoutEditorComponent
} from "./pages/super-admin/system-shoutout-editor/system-shoutout-editor.component";
import {
  InsphrationLeaderBoardComponent
} from "./pages/reports/insphration-leader-board/insphration-leader-board.component";
import { InsphrationSurveyComponent } from "./pages/insphration-survey/insphration-survey.component";
import {
  ActiveUserGuard,
  AdminUserGuard,
  AuthGuard,
  ConsultantUserGuard,
  HRUserGuard,
  ManagerUserGuard,
  SuperAdminGuard,
  UnauthGuard
} from "@inthraction/guards";
import { ManageOrganizationsComponent } from "./pages/support/manage-organizations/manage-organizations.component";
import {
  ManageOrganizationEmployeesComponent
} from "./pages/support/manage-organization-employees/manage-organization-employees.component";
import { ManageConsultantsComponent } from "./pages/support/manage-consultants/manage-consultants.component";
import { ConsultantDashboardComponent } from "./pages/dashboard/consultant-dashboard/consultant-dashboard.component";
import {
  ConsultantObjectiveConfigurationComponent
} from "./pages/consultant/consultant-objective-configuration/consultant-objective-configuration.component";
import { SurveyComponent } from "./pages/surveys/survey/survey.component";
import {
  HrObjectiveDistributionComponent
} from "./pages/hr/hr-objective-distribution/hr-objective-distribution.component";
import {
  ManagerObjectiveDistributionComponent
} from "./pages/manager/manager-objective-distribution/manager-objective-distribution.component";
import { ReadinessReportComponent } from "./pages/hr/readiness-report/readiness-report.component";
import {
  HrObjectiveResponseDistributionComponent
} from "./pages/hr/hr-objective-response-distribution/hr-objective-response-distribution.component";
import {
  ManagerObjectiveResponseDistributionComponent
} from "./pages/manager/manager-objective-response-distribution/manager-objective-response-distribution.component";
import { ExternalSurveyComponent } from "./pages/public/external-survey/external-survey.component";
import { UnsubscribeComponent } from "./pages/public/unsubscribe/unsubscribe.component";
import { TermsOfUseComponent } from "./components/shared/layout/terms-of-use/terms-of-use.component";
import {
  EmployeeObjectiveAssignmentsComponent
} from "./pages/reports/employee-objective-assignments/employee-objective-assignments.component";
import { UsersReportComponent } from "./pages/reports/users-report/users-report.component";
import {
  OrganizationSurveyStatisticsComponent
} from "./pages/super-admin/organization-survey-statistics/organization-survey-statistics.component";
import { AuthComponent } from "./pages/auth/auth.component";
import {
  OrganizationExperienceReportComponent
} from "./pages/reports/organization-experience-report/organization-experience-report.component";
import { StandupHistoryComponent } from "./pages/manager/standup-history/standup-history.component";
import { HrDashboardComponent } from "./pages/dashboard/hr-dashboard/hr-dashboard.component";
import { ManagerDashboardComponent } from "./pages/dashboard/manager-dashboard/manager-dashboard.component";
import { AdminDashboardComponent } from "./pages/dashboard/admin-dashboard/admin-dashboard.component";
import { SupportDashboardComponent } from "./pages/dashboard/support-dashboard/support-dashboard.component";
import {
  ConsultantRoutingDashboardComponent
} from "./pages/dashboard/consultant-routing-dashboard/consultant-routing-dashboard.component";
import {
  ConsultantEmployeeEditComponent
} from "./pages/consultant/consultant-employee-edit/consultant-employee-edit.component";
import { ConsultantOneOnOneComponent } from "./pages/consultant/consultant-one-on-one/consultant-one-on-one.component";
import { HrOneOnOneComponent } from "./pages/hr/hr-one-on-one/hr-one-on-one.component";
import { StandupOneOnOneComponent } from "./pages/manager/standup-one-on-one/standup-one-on-one.component";
import { ManagerOneOnOneComponent } from "./pages/manager/manager-one-on-one/manager-one-on-one.component";

const routes: Routes = [
  {
    path: "profile",
    component: ProfileComponent,
    canActivate: [AuthGuard, ActiveUserGuard]
  },
  {
    path: "dashboard",
    component: EmployeeDashboardLoaderComponent,
    canActivate: [AuthGuard, ActiveUserGuard]
  },
  {
    path: "reports/inspHRation-board",
    component: InsphrationLeaderBoardComponent,
    canActivate: [AuthGuard, ActiveUserGuard]
  },
  {
    path: "reports/insphration-board",
    component: InsphrationLeaderBoardComponent,
    canActivate: [AuthGuard, ActiveUserGuard]
  },
  {
    path: "manager",
    component: ManagerDashboardComponent,
    canActivate: [AuthGuard, ActiveUserGuard, ManagerUserGuard],
    children: [
      {
        path: "dashboard",
        component: ManagerWorkforceComponent,
        canActivate: [AuthGuard, ActiveUserGuard, ManagerUserGuard]
      },
      {
        path: "stand-up-history",
        component: StandupHistoryComponent,
        canActivate: [AuthGuard, ActiveUserGuard, ManagerUserGuard]
      },
      {
        path: `stand-up-history/:${EmployeeSettingsComponent.EMPLOYEE_ID_PARAMETER}/communication/:${OneOnOneComponent.STANDUP_ID_PARAMETER}/:${OneOnOneComponent.NOTE_ID_PARAMETER}`,
        component: StandupOneOnOneComponent,
        canActivate: [AuthGuard, ActiveUserGuard, ManagerUserGuard]
      },
      {
        path: `objective-distribution`,
        component: ManagerObjectiveDistributionComponent,
        canActivate: [AuthGuard, ActiveUserGuard, ManagerUserGuard]
      },
      {
        path: `objective-response-distribution`,
        component: ManagerObjectiveResponseDistributionComponent,
        canActivate: [AuthGuard, ActiveUserGuard, ManagerUserGuard]
      },
      {
        path: `objective-assignments-report`,
        component: EmployeeObjectiveAssignmentsComponent,
        canActivate: [AuthGuard, ActiveUserGuard, ManagerUserGuard]
      },
      {
        path: `:${EmployeeSettingsComponent.EMPLOYEE_ID_PARAMETER}/communication/:${OneOnOneComponent.STANDUP_ID_PARAMETER}`,
        component: StandupOneOnOneComponent,
        canActivate: [AuthGuard, ActiveUserGuard, ManagerUserGuard]
      },
      {
        path: `:${EmployeeSettingsComponent.EMPLOYEE_ID_PARAMETER}/communication/:${OneOnOneComponent.STANDUP_ID_PARAMETER}/:${OneOnOneComponent.NOTE_ID_PARAMETER}`,
        component: StandupOneOnOneComponent,
        canActivate: [AuthGuard, ActiveUserGuard, ManagerUserGuard]
      },
      {
        path: `:${EmployeeSettingsComponent.EMPLOYEE_ID_PARAMETER}/view-communication/:${OneOnOneComponent.STANDUP_ID_PARAMETER}/:${OneOnOneComponent.NOTE_ID_PARAMETER}`,
        component: StandupOneOnOneComponent,
        canActivate: [AuthGuard, ActiveUserGuard, ManagerUserGuard]
      },
      {
        path: `:${EmployeeSettingsComponent.EMPLOYEE_ID_PARAMETER}/workforce`,
        component: EmployeeSettingsComponent,
        canActivate: [AuthGuard, ActiveUserGuard, ManagerUserGuard]
      },
      {
        path: `:${EmployeeSettingsComponent.EMPLOYEE_ID_PARAMETER}/workforce/communication`,
        component: ManagerOneOnOneComponent,
        canActivate: [AuthGuard, ActiveUserGuard, ManagerUserGuard]
      },
      {
        path: `:${EmployeeSettingsComponent.EMPLOYEE_ID_PARAMETER}/workforce/communication/:${OneOnOneComponent.NOTE_ID_PARAMETER}`,
        component: ManagerOneOnOneComponent,
        canActivate: [AuthGuard, ActiveUserGuard, ManagerUserGuard]
      },
      {
        path: `:${EmployeeSettingsComponent.EMPLOYEE_ID_PARAMETER}/workforce/view-communication/:${OneOnOneComponent.NOTE_ID_PARAMETER}`,
        component: ManagerOneOnOneComponent,
        canActivate: [AuthGuard, ActiveUserGuard, ManagerUserGuard]
      },
      {
        path: `:${EmployeeSettingsComponent.EMPLOYEE_ID_PARAMETER}/workforce/one-on-one`,
        component: ManagerOneOnOneComponent,
        canActivate: [AuthGuard, ActiveUserGuard, ManagerUserGuard]
      },
      {
        path: `:${EmployeeSettingsComponent.EMPLOYEE_ID_PARAMETER}/workforce/one-on-one/:${OneOnOneComponent.NOTE_ID_PARAMETER}`,
        component: ManagerOneOnOneComponent,
        canActivate: [AuthGuard, ActiveUserGuard, ManagerUserGuard]
      },
      {
        path: `:${EmployeeSettingsComponent.EMPLOYEE_ID_PARAMETER}/workforce/view-one-on-one/:${OneOnOneComponent.NOTE_ID_PARAMETER}`,
        component: ManagerOneOnOneComponent,
        canActivate: [AuthGuard, ActiveUserGuard, ManagerUserGuard]
      },
      {
        path: `:${EmployeeSettingsComponent.EMPLOYEE_ID_PARAMETER}/workforce/quarterly-review`,
        component: ManagerOneOnOneComponent,
        canActivate: [AuthGuard, ActiveUserGuard, ManagerUserGuard]
      },
      {
        path: `:${EmployeeSettingsComponent.EMPLOYEE_ID_PARAMETER}/workforce/quarterly-review/:${OneOnOneComponent.NOTE_ID_PARAMETER}`,
        component: ManagerOneOnOneComponent,
        canActivate: [AuthGuard, ActiveUserGuard, ManagerUserGuard]
      },
      {
        path: `:${EmployeeSettingsComponent.EMPLOYEE_ID_PARAMETER}/workforce/view-quarterly-review/:${OneOnOneComponent.NOTE_ID_PARAMETER}`,
        component: ManagerOneOnOneComponent,
        canActivate: [AuthGuard, ActiveUserGuard, ManagerUserGuard]
      },
      {
        path: `:${EmployeeSettingsComponent.EMPLOYEE_ID_PARAMETER}/workforce/annual-review`,
        component: ManagerOneOnOneComponent,
        canActivate: [AuthGuard, ActiveUserGuard, ManagerUserGuard]
      },
      {
        path: `:${EmployeeSettingsComponent.EMPLOYEE_ID_PARAMETER}/workforce/annual-review/:${OneOnOneComponent.NOTE_ID_PARAMETER}`,
        component: ManagerOneOnOneComponent,
        canActivate: [AuthGuard, ActiveUserGuard, ManagerUserGuard]
      },
      {
        path: `:${EmployeeSettingsComponent.EMPLOYEE_ID_PARAMETER}/workforce/view-annual-review/:${OneOnOneComponent.NOTE_ID_PARAMETER}`,
        component: ManagerOneOnOneComponent,
        canActivate: [AuthGuard, ActiveUserGuard, ManagerUserGuard]
      },
      {
        path: "",
        redirectTo: "dashboard",
        pathMatch: "full"
      }
    ]
  },
  {
    path: "manager-dashboard",
    redirectTo: "manager"
  },
  {
    path: `manager-objective-distribution`,
    redirectTo: "manager/objective-distribution"
  },
  {
    path: `manager-objective-response-distribution`,
    redirectTo: "manager/objective-response-distribution"
  },
  {
    path: `manager-objective-assignments-report`,
    redirectTo: "manager/objective-assignments-report"
  },
  {
    path: `workforce-settings/:${EmployeeSettingsComponent.EMPLOYEE_ID_PARAMETER}`,
    redirectTo: `manager/:${EmployeeSettingsComponent.EMPLOYEE_ID_PARAMETER}/workforce`
  },
  {
    path: `manager-dashboard/:${EmployeeSettingsComponent.EMPLOYEE_ID_PARAMETER}/communication/:${OneOnOneComponent.STANDUP_ID_PARAMETER}`,
    redirectTo: `manager/:${EmployeeSettingsComponent.EMPLOYEE_ID_PARAMETER}/communication/:${OneOnOneComponent.STANDUP_ID_PARAMETER}`
  },
  {
    path: `manager-dashboard/:${EmployeeSettingsComponent.EMPLOYEE_ID_PARAMETER}/communication/:${OneOnOneComponent.STANDUP_ID_PARAMETER}/:${OneOnOneComponent.NOTE_ID_PARAMETER}`,
    redirectTo: `manager/:${EmployeeSettingsComponent.EMPLOYEE_ID_PARAMETER}/communication/:${OneOnOneComponent.STANDUP_ID_PARAMETER}/:${OneOnOneComponent.NOTE_ID_PARAMETER}`
  },
  {
    path: `manager-dashboard/:${EmployeeSettingsComponent.EMPLOYEE_ID_PARAMETER}/view-communication/:${OneOnOneComponent.STANDUP_ID_PARAMETER}/:${OneOnOneComponent.NOTE_ID_PARAMETER}`,
    redirectTo:  `manager/:${EmployeeSettingsComponent.EMPLOYEE_ID_PARAMETER}/view-communication/:${OneOnOneComponent.STANDUP_ID_PARAMETER}/:${OneOnOneComponent.NOTE_ID_PARAMETER}`,
  },
  {
    path: `workforce-settings/:${EmployeeSettingsComponent.EMPLOYEE_ID_PARAMETER}/communication`,
    redirectTo: `manager/:${EmployeeSettingsComponent.EMPLOYEE_ID_PARAMETER}/workforce/communication`
  },
  {
    path: `workforce-settings/:${EmployeeSettingsComponent.EMPLOYEE_ID_PARAMETER}/communication/:${OneOnOneComponent.NOTE_ID_PARAMETER}`,
    redirectTo: `manager/:${EmployeeSettingsComponent.EMPLOYEE_ID_PARAMETER}/workforce/communication/:${OneOnOneComponent.NOTE_ID_PARAMETER}`
  },
  {
    path: `workforce-settings/:${EmployeeSettingsComponent.EMPLOYEE_ID_PARAMETER}/view-communication/:${OneOnOneComponent.NOTE_ID_PARAMETER}`,
    redirectTo:  `manager/:${EmployeeSettingsComponent.EMPLOYEE_ID_PARAMETER}/settings/view-communication/:${OneOnOneComponent.NOTE_ID_PARAMETER}`
  },
  {
    path: `workforce-settings/:${EmployeeSettingsComponent.EMPLOYEE_ID_PARAMETER}/one-on-one`,
    redirectTo: `manager/:${EmployeeSettingsComponent.EMPLOYEE_ID_PARAMETER}/workforce/one-on-one`
  },
  {
    path: `workforce-settings/:${EmployeeSettingsComponent.EMPLOYEE_ID_PARAMETER}/one-on-one/:${OneOnOneComponent.NOTE_ID_PARAMETER}`,
    redirectTo: `manager/:${EmployeeSettingsComponent.EMPLOYEE_ID_PARAMETER}/workforce/one-on-one/:${OneOnOneComponent.NOTE_ID_PARAMETER}`
  },
  {
    path: `workforce-settings/:${EmployeeSettingsComponent.EMPLOYEE_ID_PARAMETER}/view-one-on-one/:${OneOnOneComponent.NOTE_ID_PARAMETER}`,
    redirectTo: `manager/:${EmployeeSettingsComponent.EMPLOYEE_ID_PARAMETER}/workforce/view-one-on-one/:${OneOnOneComponent.NOTE_ID_PARAMETER}`
  },
  {
    path: `workforce-settings/:${EmployeeSettingsComponent.EMPLOYEE_ID_PARAMETER}/quarterly-review`,
    redirectTo: `manager/:${EmployeeSettingsComponent.EMPLOYEE_ID_PARAMETER}/workforce/quarterly-review`,
  },
  {
    path: `workforce-settings/:${EmployeeSettingsComponent.EMPLOYEE_ID_PARAMETER}/quarterly-review/:${OneOnOneComponent.NOTE_ID_PARAMETER}`,
    redirectTo: `manager/:${EmployeeSettingsComponent.EMPLOYEE_ID_PARAMETER}/workforce/quarterly-review/:${OneOnOneComponent.NOTE_ID_PARAMETER}`
  },
  {
    path: `workforce-settings/:${EmployeeSettingsComponent.EMPLOYEE_ID_PARAMETER}/view-quarterly-review/:${OneOnOneComponent.NOTE_ID_PARAMETER}`,
    redirectTo: `manager/:${EmployeeSettingsComponent.EMPLOYEE_ID_PARAMETER}/workforce/view-quarterly-review/:${OneOnOneComponent.NOTE_ID_PARAMETER}`
  },
  {
    path: `workforce-settings/:${EmployeeSettingsComponent.EMPLOYEE_ID_PARAMETER}/annual-review`,
    redirectTo: `manager/:${EmployeeSettingsComponent.EMPLOYEE_ID_PARAMETER}/workforce/annual-review`
  },
  {
    path: `workforce-settings/:${EmployeeSettingsComponent.EMPLOYEE_ID_PARAMETER}/annual-review/:${OneOnOneComponent.NOTE_ID_PARAMETER}`,
    redirectTo: `manager/:${EmployeeSettingsComponent.EMPLOYEE_ID_PARAMETER}/workforce/annual-review/:${OneOnOneComponent.NOTE_ID_PARAMETER}`
  },
  {
    path: `workforce-settings/:${EmployeeSettingsComponent.EMPLOYEE_ID_PARAMETER}/view-annual-review/:${OneOnOneComponent.NOTE_ID_PARAMETER}`,
    redirectTo: `manager/:${EmployeeSettingsComponent.EMPLOYEE_ID_PARAMETER}/workforce/view-annual-review/:${OneOnOneComponent.NOTE_ID_PARAMETER}`
  },
  {
    path: "project-dashboard",
    component: ProjectDashboardComponent,
    canActivate: [AuthGuard, ActiveUserGuard]
  },
  {
    path: `project-dashboard/:${ProjectDashboardComponent.PROJECT_ID_PARAMETER}`,
    component: ProjectDashboardComponent,
    canActivate: [AuthGuard, ActiveUserGuard]
  },
  {
    path: "hr",
    component: HrDashboardComponent,
    canActivate: [AuthGuard, ActiveUserGuard, HRUserGuard],
    children: [
      {
        path: `experience-report`,
        component: OrganizationExperienceReportComponent,
        canActivate: [AuthGuard, ActiveUserGuard, HRUserGuard]
      },
      {
        path: `readiness-report`,
        component: ReadinessReportComponent,
        canActivate: [AuthGuard, ActiveUserGuard, HRUserGuard]
      },
      {
        path: `objective-assignments-report`,
        component: EmployeeObjectiveAssignmentsComponent,
        canActivate: [AuthGuard, ActiveUserGuard, HRUserGuard]
      },
      {
        path: "objective-distribution",
        component: HrObjectiveDistributionComponent,
        canActivate: [AuthGuard, ActiveUserGuard, HRUserGuard]
      },
      {
        path: `objective-response-distribution`,
        component: HrObjectiveResponseDistributionComponent,
        canActivate: [AuthGuard, ActiveUserGuard, HRUserGuard]
      },
      {
        path: "favorites",
        component: HrWorkforceComponent,
        canActivate: [AuthGuard, ActiveUserGuard, HRUserGuard]
      },
      {
        path: "workforce",
        component: HrWorkforceComponent,
        canActivate: [AuthGuard, ActiveUserGuard, HRUserGuard]
      },
      {
        path: `workforce/:${HrDashboardComponent.EMPLOYEE_ID_PARAMETER}`,
        component: HrWorkforceComponent,
        canActivate: [AuthGuard, ActiveUserGuard, HRUserGuard]
      },
      {
        path: `workforce/:${HrDashboardComponent.EMPLOYEE_ID_PARAMETER}/edit`,
        component: HrEmployeeEditComponent,
        canActivate: [AuthGuard, ActiveUserGuard, HRUserGuard]
      },
      {
        path: `workforce/:${HrDashboardComponent.EMPLOYEE_ID_PARAMETER}/edit/one-on-one`,
        component: HrOneOnOneComponent,
        canActivate: [AuthGuard, ActiveUserGuard, HRUserGuard]
      },
      {
        path: `workforce/:${HrDashboardComponent.EMPLOYEE_ID_PARAMETER}/edit/one-on-one/:${OneOnOneComponent.NOTE_ID_PARAMETER}`,
        component: HrOneOnOneComponent,
        canActivate: [AuthGuard, ActiveUserGuard, HRUserGuard]
      },
      {
        path: `workforce/:${HrDashboardComponent.EMPLOYEE_ID_PARAMETER}/edit/view-one-on-one/:${OneOnOneComponent.NOTE_ID_PARAMETER}`,
        component: HrOneOnOneComponent,
        canActivate: [AuthGuard, ActiveUserGuard, HRUserGuard]
      },
      {
        path: "",
        redirectTo: "favorites",
        pathMatch: "full"
      }
    ]
  },
  {
    path: "hr-dashboard",
    redirectTo: "hr"
  },
  {
    path: "hr-dashboard/workforce",
    redirectTo: "hr/workforce"
  },
  {
    path: "hr-dashboard/favorites",
    redirectTo: "hr/favorites"
  },
  {
    path: `hr-dashboard/workforce/:${HrDashboardComponent.EMPLOYEE_ID_PARAMETER}`,
    redirectTo: `hr/workforce/:${HrDashboardComponent.EMPLOYEE_ID_PARAMETER}`
  },
  {
    path: `hr-dashboard/workforce/:${HrEmployeeEditComponent.EMPLOYEE_ID_PARAMETER}/edit`,
    redirectTo: `hr/workforce/:${HrEmployeeEditComponent.EMPLOYEE_ID_PARAMETER}/edit`
  },
  {
    path: `hr-dashboard/workforce/:${HrEmployeeEditComponent.EMPLOYEE_ID_PARAMETER}/edit/one-on-one`,
    redirectTo: `hr/workforce/:${HrEmployeeEditComponent.EMPLOYEE_ID_PARAMETER}/edit/one-on-one`
  },
  {
    path: `hr-dashboard/workforce/:${HrEmployeeEditComponent.EMPLOYEE_ID_PARAMETER}/edit/one-on-one/:${OneOnOneComponent.NOTE_ID_PARAMETER}`,
    redirectTo: `hr/workforce/:${HrEmployeeEditComponent.EMPLOYEE_ID_PARAMETER}/edit/one-on-one/:${OneOnOneComponent.NOTE_ID_PARAMETER}`
  },
  {
    path: `hr-dashboard/workforce/:${HrEmployeeEditComponent.EMPLOYEE_ID_PARAMETER}/edit/view-one-on-one/:${OneOnOneComponent.NOTE_ID_PARAMETER}`,
    redirectTo: `hr/workforce/:${HrEmployeeEditComponent.EMPLOYEE_ID_PARAMETER}/edit/view-one-on-one/:${OneOnOneComponent.NOTE_ID_PARAMETER}`
  },
  {
    path: `hr-objective-distribution`,
    redirectTo: "hr/objective-distribution"
  },
  {
    path: `hr-objective-response-distribution`,
    redirectTo: "hr/objective-response-distribution"
  },
  {
    path: `readiness-report`,
    component: ReadinessReportComponent,
    canActivate: [AuthGuard, ActiveUserGuard, HRUserGuard]
  },
  {
    path: `hr-objective-assignments-report`,
    redirectTo: "hr/objective-assignments-report"
  },
  {
    path: `experience-report`,
    component: OrganizationExperienceReportComponent,
    canActivate: [AuthGuard, ActiveUserGuard, HRUserGuard]
  },
  {
    path: "executive-dashboard",
    component: ExecutiveDashboardComponent,
    canActivate: [AuthGuard, ActiveUserGuard]
  },
  {
    path: "inactive",
    component: InactiveEmployeeComponent,
    canActivate: [AuthGuard]
  },
  {
    path: `inspHRation-survey/:${InsphrationSurveyComponent.surveyIDParameterName}`,
    component: InsphrationSurveyComponent,
    canActivate: [AuthGuard, ActiveUserGuard]
  },
  {
    path: `insphration-survey/:${InsphrationSurveyComponent.surveyIDParameterName}`,
    component: InsphrationSurveyComponent,
    canActivate: [AuthGuard, ActiveUserGuard]
  },
  {
    path: `survey`,
    component: SurveyComponent,
    canActivate: [AuthGuard, ActiveUserGuard]
  },
  {
    path: `survey/:${SurveyComponent.surveyIDParameterName}`,
    component: SurveyComponent,
    canActivate: [AuthGuard, ActiveUserGuard]
  },
  {
    path: `survey/:${SurveyComponent.surveyIDParameterName}/:${SurveyComponent.surveyResponseParameterName}`,
    component: SurveyComponent,
    canActivate: [AuthGuard, ActiveUserGuard]
  },
  {
    path: `stand-up-survey/:${SurveyComponent.surveyIDParameterName}`,
    component: SurveyComponent,
    canActivate: [AuthGuard, ActiveUserGuard]
  },
  {
    path: `event-survey/:${SurveyComponent.surveyIDParameterName}`,
    component: SurveyComponent,
    canActivate: [AuthGuard, ActiveUserGuard]
  },
  {
    path: `event-survey/:${SurveyComponent.surveyIDParameterName}/:${SurveyComponent.surveyResponseParameterName}`,
    component: SurveyComponent,
    canActivate: [AuthGuard, ActiveUserGuard]
  },
  {
    path: `objective-survey/:${SurveyComponent.surveyIDParameterName}`,
    component: SurveyComponent,
    canActivate: [AuthGuard, ActiveUserGuard]
  },
  {
    path: `objective-survey/:${SurveyComponent.surveyIDParameterName}/:${SurveyComponent.surveyResponseParameterName}`,
    component: SurveyComponent,
    canActivate: [AuthGuard, ActiveUserGuard]
  },
  {
    path: `project-survey/:${SurveyComponent.surveyIDParameterName}`,
    component: SurveyComponent,
    canActivate: [AuthGuard, ActiveUserGuard]
  },
  {
    path: `project-survey/:${SurveyComponent.surveyIDParameterName}/:${SurveyComponent.surveyResponseParameterName}`,
    component: SurveyComponent,
    canActivate: [AuthGuard, ActiveUserGuard]
  },
  {
    path: `milestone-survey/:${SurveyComponent.surveyIDParameterName}`,
    component: SurveyComponent,
    canActivate: [AuthGuard, ActiveUserGuard]
  },
  {
    path: `milestone-survey/:${SurveyComponent.surveyIDParameterName}/:${SurveyComponent.surveyResponseParameterName}`,
    component: SurveyComponent,
    canActivate: [AuthGuard, ActiveUserGuard]
  },
  {
    path: `experience-survey/:${SurveyComponent.surveyIDParameterName}`,
    component: SurveyComponent,
    canActivate: [AuthGuard, ActiveUserGuard]
  },
  {
    path: `experience-survey/:${SurveyComponent.surveyIDParameterName}/:${SurveyComponent.surveyResponseParameterName}`,
    component: SurveyComponent,
    canActivate: [AuthGuard, ActiveUserGuard]
  },
  {
    path: "calendar-access",
    component: AuthorizeCalendarAccessComponent,
    canActivate: [AuthGuard, ActiveUserGuard]
  },
  // ------ Consultant Components --------- //
  {
    path: "consultant",
    component: ConsultantRoutingDashboardComponent,
    canActivate: [AuthGuard, ActiveUserGuard, ConsultantUserGuard],
    children:[
      {
        path: "objective-configuration",
        component: ConsultantObjectiveConfigurationComponent,
        canActivate: [AuthGuard, ActiveUserGuard, ConsultantUserGuard]
      },
      {
        path: `objective-config`,
        redirectTo: "objective-configuration"
      },
      {
        path: `:${ConsultantDashboardComponent.organizationIDParameterName}`,
        component: ConsultantDashboardComponent,
        canActivate: [AuthGuard, ActiveUserGuard, ConsultantUserGuard],
      },
      {
        path: `:${ConsultantDashboardComponent.organizationIDParameterName}/favorites`,
        component: ConsultantDashboardComponent,
        canActivate: [AuthGuard, ActiveUserGuard, ConsultantUserGuard]
      },
      {
        path: `:${ConsultantDashboardComponent.organizationIDParameterName}/project`,
        component: ConsultantDashboardComponent,
        canActivate: [AuthGuard, ActiveUserGuard, ConsultantUserGuard]
      },
      {
        path: `:${ConsultantDashboardComponent.organizationIDParameterName}/project/:${ProjectDashboardComponent.PROJECT_ID_PARAMETER}`,
        component: ConsultantDashboardComponent,
        canActivate: [AuthGuard, ActiveUserGuard, ConsultantUserGuard]
      },
      {
        path: `:${ConsultantDashboardComponent.organizationIDParameterName}/workforce`,
        component: ConsultantDashboardComponent,
        canActivate: [AuthGuard, ActiveUserGuard, ConsultantUserGuard]
      },
      {
        path: `:${ConsultantDashboardComponent.organizationIDParameterName}/workforce/:${ConsultantDashboardComponent.employeeIDParameterName}`,
        component: ConsultantDashboardComponent,
        canActivate: [AuthGuard, ActiveUserGuard, ConsultantUserGuard]
      },
      {
        path: `:${ConsultantDashboardComponent.organizationIDParameterName}/workforce/:${ConsultantDashboardComponent.employeeIDParameterName}/edit`,
        component: ConsultantEmployeeEditComponent,
        canActivate: [AuthGuard, ActiveUserGuard, ConsultantUserGuard]
      },
      {
        path: `:${ConsultantDashboardComponent.organizationIDParameterName}/workforce/:${ConsultantDashboardComponent.employeeIDParameterName}/edit/one-on-one`,
        component: ConsultantOneOnOneComponent,
        canActivate: [AuthGuard, ActiveUserGuard, ConsultantUserGuard]
      },
      {
        path: `:${ConsultantDashboardComponent.organizationIDParameterName}/workforce/:${ConsultantDashboardComponent.employeeIDParameterName}/edit/one-on-one/:${OneOnOneComponent.NOTE_ID_PARAMETER}`,
        component: ConsultantOneOnOneComponent,
        canActivate: [AuthGuard, ActiveUserGuard, ConsultantUserGuard]
      },
      {
        path: `:${ConsultantDashboardComponent.organizationIDParameterName}/workforce/:${ConsultantDashboardComponent.employeeIDParameterName}/edit/view-one-on-one/:${OneOnOneComponent.NOTE_ID_PARAMETER}`,
        component: ConsultantOneOnOneComponent,
        canActivate: [AuthGuard, ActiveUserGuard, ConsultantUserGuard]
      },
      {
        path: `:${EmployeeObjectiveAssignmentsComponent.organizationIDParameterName}/objective-assignments-report`,
        component: ConsultantDashboardComponent,
        canActivate: [AuthGuard, ActiveUserGuard, ConsultantUserGuard]
      },
      {
        path: `:${ConsultantDashboardComponent.organizationIDParameterName}/standups`,
        component: ConsultantDashboardComponent,
        canActivate: [AuthGuard, ActiveUserGuard, ConsultantUserGuard]
      },
      {
        path: "",
        redirectTo: "objective-configuration",
        pathMatch: "full"
      }
    ]
  },
  // ------ Consultant Components --------- //
  // ------ Admin Components --------- //
  {
    path: "admin",
    component: AdminDashboardComponent,
    canActivate: [AuthGuard, ActiveUserGuard, AdminUserGuard],
    children: [
      {
        path: "inspHRation-config",
        redirectTo: "insphration-configuration"
      },
      {
        path: "insphration-config",
        redirectTo: "insphration-configuration"
      },
      {
        path: "insphration-configuration",
        component: ShoutoutConfigurationComponent,
        canActivate: [AuthGuard, ActiveUserGuard, AdminUserGuard]
      },
      {
        path: "system-config",
        redirectTo: "system-configuration"
      },
      {
        path: "system-configuration",
        component: SystemConfigurationComponent,
        canActivate: [AuthGuard, ActiveUserGuard, AdminUserGuard]
      },
      {
        path: "orgchart",
        component: OrgchartComponent,
        canActivate: [AuthGuard, ActiveUserGuard, AdminUserGuard]
      },
      {
        path: "objective-config",
        redirectTo: "objective-configuration"
      },
      {
        path: "objective-configuration",
        component: ObjectiveConfigurationComponent,
        canActivate: [AuthGuard, ActiveUserGuard, AdminUserGuard]
      },
      {
        path: "users-report",
        redirectTo: "users"
      },
      {
        path: "users",
        component: UsersReportComponent,
        canActivate: [AuthGuard, ActiveUserGuard, AdminUserGuard]
      },
      {
        path: "subscription",
        component: SubscriptionComponent,
        canActivate: [AuthGuard, ActiveUserGuard, AdminUserGuard]
      },
      {
        path: "",
        redirectTo: "users",
        pathMatch: "full"
      }
    ]
  },
  {
    path: "subscription",
    redirectTo: "admin/subscription"
  },
  // ------ Admin Components --------- //
  // ------ Super Admin Components --- //
  {
    path: "support",
    component: SupportDashboardComponent,
    canActivate: [AuthGuard, ActiveUserGuard, SuperAdminGuard],
    canLoad: [SuperAdminGuard],
    children: [
      {
        path: "manage-organizations",
        component: ManageOrganizationsComponent,
        canActivate: [AuthGuard, ActiveUserGuard, SuperAdminGuard],
        canLoad: [SuperAdminGuard]
      },
      {
        path: "system-objective-editor",
        component: SystemObjectiveEditorComponent,
        canActivate: [AuthGuard, ActiveUserGuard, SuperAdminGuard],
        canLoad: [SuperAdminGuard]
      },
      {
        path: "system-insphration-editor",
        component: SystemShoutoutEditorComponent,
        canActivate: [AuthGuard, ActiveUserGuard, SuperAdminGuard],
        canLoad: [SuperAdminGuard]
      },
      {
        path: "organization-survey-stats",
        component: OrganizationSurveyStatisticsComponent,
        canActivate: [AuthGuard, ActiveUserGuard, SuperAdminGuard],
        canLoad: [SuperAdminGuard]
      },
      {
        path: `manage-workforce/:${ManageOrganizationEmployeesComponent.organizationIDParameterName}`,
        component: ManageOrganizationEmployeesComponent,
        canActivate: [AuthGuard, ActiveUserGuard, SuperAdminGuard],
        canLoad: [SuperAdminGuard]
      },
      {
        path: `manage-consultants/:${ManageConsultantsComponent.organizationIDParameterName}`,
        component: ManageConsultantsComponent,
        canActivate: [AuthGuard, ActiveUserGuard, SuperAdminGuard],
        canLoad: [SuperAdminGuard]
      },
      {
        path: `manage-configurations/:${SystemConfigurationComponent.organizationIDParameterName}`,
        component: SystemConfigurationComponent,
        canActivate: [AuthGuard, ActiveUserGuard, SuperAdminGuard],
        canLoad: [SuperAdminGuard]
      },
      {
        path: `manage-insphrations/:${ShoutoutConfigurationComponent.organizationIDParameterName}`,
        component: ShoutoutConfigurationComponent,
        canActivate: [AuthGuard, ActiveUserGuard, SuperAdminGuard],
        canLoad: [SuperAdminGuard]
      },
      {
        path: `manage-objective-configuration/:${ObjectiveConfigurationComponent.organizationIDParameterName}`,
        component: ObjectiveConfigurationComponent,
        canActivate: [AuthGuard, ActiveUserGuard, SuperAdminGuard],
        canLoad: [SuperAdminGuard]
      },
      {
        path: "",
        redirectTo: "manage-organizations",
        pathMatch: "full"
      }
    ]
  },
  {
    path: "super/organization-survey-stats",
    redirectTo: "support/organization-survey-stats"
  },
  {
    path: "super/system-insphration-editor",
    redirectTo: "support/system-insphration-editor"
  },
  {
    path: "super/system-objective-editor",
    redirectTo: "support/system-objective-editor"
  },
  {
    path: "super/manage-organizations",
    redirectTo: "support/manage-organizations"
  },
  {
    path: `super/manage-workforce/:${ManageOrganizationEmployeesComponent.organizationIDParameterName}`,
    redirectTo: `support/manage-workforce/:${ManageOrganizationEmployeesComponent.organizationIDParameterName}`
  },
  {
    path: `super/manage-consultants/:${ManageConsultantsComponent.organizationIDParameterName}`,
    redirectTo: `support/manage-consultants/:${ManageConsultantsComponent.organizationIDParameterName}`
  },
  {
    path: `super/manage-configurations/:${SystemConfigurationComponent.organizationIDParameterName}`,
    redirectTo: `support/manage-configurations/:${SystemConfigurationComponent.organizationIDParameterName}`
  },
  {
    path: `super/manage-insphrations/:${ShoutoutConfigurationComponent.organizationIDParameterName}`,
    redirectTo: `support/manage-insphrations/:${ShoutoutConfigurationComponent.organizationIDParameterName}`
  },
  {
    path: `super/manage-objective-configuration/:${ObjectiveConfigurationComponent.organizationIDParameterName}`,
    redirectTo: `support/manage-objective-configuration/:${ObjectiveConfigurationComponent.organizationIDParameterName}`
  },
  // ------ Super Admin Components --- //
  // ------ Deprecated Routes ------- //

  {
    path: "employee-dashboard",
    redirectTo: "dashboard"
  },
  {
    path: `hr-dashboard/employee/:${HrEmployeeEditComponent.EMPLOYEE_ID_PARAMETER}`,
    redirectTo: `hr-dashboard/workforce/:${HrEmployeeEditComponent.EMPLOYEE_ID_PARAMETER}`
  },
  {
    path: `consultant/:${ConsultantDashboardComponent.organizationIDParameterName}/employee/:${ConsultantDashboardComponent.employeeIDParameterName}`,
    redirectTo: `consultant/:${ConsultantDashboardComponent.organizationIDParameterName}/workforce/:${ConsultantDashboardComponent.employeeIDParameterName}`
  },
  {
    path: `employee-settings/:${EmployeeSettingsComponent.EMPLOYEE_ID_PARAMETER}`,
    redirectTo: `workforce-settings/:${EmployeeSettingsComponent.EMPLOYEE_ID_PARAMETER}`
  },

  // ------ Depreccated Routes ------ //
  // ------ Public --- //
  {
    path: "login",
    component: AuthComponent,
    canActivate: [UnauthGuard]
  },
  {
    path: `tos`,
    component: TermsOfUseComponent
  },
  {
    path: `feedback/:${SurveyComponent.surveyIDParameterName}`,
    component: ExternalSurveyComponent
  },
  {
    path: `feedback/:${SurveyComponent.surveyIDParameterName}/:${SurveyComponent.surveyResponseParameterName}`,
    component: ExternalSurveyComponent
  },
  {
    path: `unsubscribe`,
    component: UnsubscribeComponent
  },
  {
    path: `unsubscribe/:${UnsubscribeComponent.externalRespondentIDParameterName}/:${UnsubscribeComponent.externalRespondentEmailParameterName}`,
    component: UnsubscribeComponent
  },
  // ------ Public --- //
  // ------ Default Route --- //
  {
    path: "",
    redirectTo: "login",
    pathMatch: "full"
  },
  {
    path: "**",
    redirectTo: "login"
    // pathMatch: "full"
  }];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
