<h1 mat-dialog-title>Add Project</h1>
<div class="add-project-container">
  <form (ngSubmit)="onSaveClick(form.value)" [formGroup]="form" autocomplete="off" novalidate>
    <div mat-dialog-content>

      <mat-form-field class="full-width">
        <mat-label>Title</mat-label>
        <textarea matInput formControlName="title" maxlength="256"></textarea>
        <mat-error *ngIf="hasError('title', 'required')">Title is required</mat-error>
      </mat-form-field>

      <mat-form-field class="full-width">
        <mat-label>Description</mat-label>
        <textarea matInput formControlName="description" maxlength="256"></textarea>
        <mat-error *ngIf="hasError('description', 'required')">Description is required</mat-error>
      </mat-form-field>

      <mat-form-field class="half-width">
        <mat-label>Start Date</mat-label>
        <input [matDatepicker]="startDate" formControlName="startDate" matInput>
        <mat-datepicker-toggle [for]="startDate" matSuffix></mat-datepicker-toggle>
        <mat-datepicker #startDate></mat-datepicker>
        <mat-error *ngIf="hasError('startDate', 'required')">Start Date is required</mat-error>
        <mat-error *ngIf="hasError('startDate', 'notCurrent')">Start Date must be greater than or equal to the current date</mat-error>
      </mat-form-field>

      <mat-form-field class="half-width">
        <mat-label>End Date</mat-label>
        <input [matDatepicker]="endDate" formControlName="endDate" matInput>
        <mat-datepicker-toggle [for]="endDate" matSuffix></mat-datepicker-toggle>
        <mat-datepicker #endDate></mat-datepicker>
        <mat-error *ngIf="hasError('endDate', 'required')">End Date is required</mat-error>
        <mat-error *ngIf="hasError('endDate', 'notCurrent')">End Date must be greater than or equal to the current date</mat-error>
      </mat-form-field>

      <mat-form-field class="half-width">
        <mat-label>Check-in Frequency</mat-label>
        <mat-select formControlName="cadence">
          <mat-option *ngFor="let id of cadenceTypes"
                      [value]="id">{{cadenceTypeLabels[id]}}</mat-option>
        </mat-select>
        <mat-error *ngIf="hasError('cadence', 'required')">Check-in Frequency is required</mat-error>
      </mat-form-field>

    </div>
    <div mat-dialog-actions>
      <button (click)="onCancelClick()" mat-button type="button">Cancel</button>
      <button [disabled]="!form.valid" color="primary" mat-raised-button type="submit">Save</button>
    </div>
  </form>
</div>
