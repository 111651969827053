import { Component, Inject, OnInit } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { ActivatedRoute, Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { DOCUMENT } from "@angular/common";
import { EmployeeService } from "@inthraction/services";
import { environment } from "src/environments/environment";

enum errorCodes {
  access_denied = "access_denied",
  invalid_scope = "invalid_scope"
}

@Component({
  selector: "inthraction-authorize-calendar-access",
  templateUrl: "./authorize-calendar-access.component.html",
  styleUrls: ["./authorize-calendar-access.component.scss"]
})
export class AuthorizeCalendarAccessComponent implements OnInit {

  /**
   * A space-separated String of named scopes, eg. read_events create_event delete_event.
   *
   * create_calendar to allow Create Calendar requests
   * read_events to allow Read Events requests
   * delete_event to allow Delete Event requests
   * read_free_busy to allow Free Busy requests
   * change_participation_status to allow the Participation Status of a user in an event to be set
   *
   * read_only to allow Read Events and Free Busy requests. This scope is equivalent to read_events and read_free_busy scopes.
   * write_only to allow Create Calendar, Create or Update Events and Delete Event requests. This scope is equivalent to create_calendar and create_event scopes.
   * read_write to allow all requests mentioned in read_only scope and write_only.
   * free_busy to allow Free Busy requests. This scope is equivalent to read_free_busy scope.
   * free_busy_write to allow all requests mentioned in free_busy scope and write_only. This scope is equivalent to create_event, create_calendar and read_free_busy scopes.
   */
  scope = "read_write";

  /**
   * A value that will be returned to you unaltered along with the user’s authorization request decision.
   * The OAuth 2.0 RFC recommends using this to prevent cross-site request forgery.
   *
   */
  state: string;
  redirectURI: string;
  clientID: string = environment.cronofyClientID;
  authorizationURL: string;
  code: string;
  returnedState: string;
  error: string;

  constructor(
    private domSanitizer: DomSanitizer,
    private route: ActivatedRoute,
    public router: Router,
    private toastr: ToastrService,
    private employeeService: EmployeeService,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.redirectURI = encodeURIComponent(`${this.document.location.protocol}//${this.document.location.hostname}${!this.document.location.port || this.document.location.port === "443" || this.document.location.port === "80" ? "" : ":" + this.document.location.port}/calendar-access`);
    this.route.queryParams.subscribe(params => {
      this.code = params.code;
      this.returnedState = params.state;  // TODO check that state matches what is stored in browser session store.
      this.error = params.error;
    });
  }

  async ngOnInit(): Promise<void> {
    if (this.code && !this.error) {
      const employee = await this.employeeService.getCurrentEmployee();
      await this.employeeService.createEmployeeCalendarCode({ code: this.code, employeeID: employee.id, redirectUrl: this.redirectURI });
      await this.employeeService.updateEmployee({ id: employee.id, validEmailToken: true });
      this.toastr.success("Your Calendar has been Authorized");
      await this.router.navigate(["/profile"]);
    } else if (this.error) {
      this.toastr.error("Unable to authorize Calendar Access, please try again.");
      console.error("Unable to authorize Calendar access", this.error);
    } else {
      this.state = "intHRaction";
      this.authorizationURL = `https://app.cronofy.com/oauth/authorize?response_type=code&client_id=${this.clientID}&redirect_uri=${this.redirectURI}&scope=${this.scope}&state=${this.state}`;
      // TODO generate random value, and store in session store for CSRF verification
    }

  }

  redirectToAuthorization(): void {
    if (this.authorizationURL) {
      window.location.href = this.authorizationURL;
    } else {
      console.error("AuthorizeCalendarAccessComponent: Authorization URL not initialized");
      this.toastr.error("Unable to authorize Calendar Access, please try again.");
    }
  }

}
