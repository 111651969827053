import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { EmployeeService } from "@inthraction/services";
import { Employee } from "@inthraction/data-models";
import { MatStepper } from "@angular/material/stepper";
import { Observable } from "rxjs";
import { map, startWith } from "rxjs/operators";

@Component({
  selector: "inthraction-add-team-member",
  templateUrl: "./add-team-member.component.html",
  styleUrls: ["./add-team-member.component.scss"]
})
export class AddTeamMemberComponent implements OnInit {

  @ViewChild("stepper") private addEmployeeStepper: MatStepper;


  private email = new FormControl("", [Validators.required, Validators.email]);
  private emails: string[];
  filteredEmails: Observable<string[]>;

  searchForEmployeeFG = new FormGroup({
    email: this.email,
    isValidEmail: new FormControl(false, [Validators.requiredTrue])
  });
  employee: Employee;

  constructor(
    private employeeService: EmployeeService,
    private dialogRef: MatDialogRef<AddTeamMemberComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
  }

  async ngOnInit() {
    const employees = await this.employeeService.getEmployeesForOrganizationByOrganization({includeDisabled:false, organizationID:this.data.project.organizationID, memoize:true});
    this.emails = employees.map(emp => emp.email);
    this.filteredEmails = this.email.valueChanges.pipe(
      startWith(""),
      map(value => this._filter(value))
    );
  }


  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.emails.filter(option => option.toLowerCase().includes(filterValue));
  }

  onCancelClick() {
    this.dialogRef.close();
  }

  async onSaveClick() {
    const team: string[] = this.data.project.team ? this.data.project.team : [];
    if (!team.includes(this.employee.id)) {
      team.push(this.employee.id);
    }
    this.dialogRef.close({ team });
  }


  public hasError = (controlName: string, errorName: string) => {
    if (controlName && this.searchForEmployeeFG.controls[controlName]) {
      return this.searchForEmployeeFG.controls[controlName].hasError(errorName);
    }
    return this.searchForEmployeeFG.hasError(errorName);
  };

  async onSearchClick() {
    const emailSearchFC = this.searchForEmployeeFG.get("email");
    const employee = await this.employeeService.getEmployeeByEmailMemoize(emailSearchFC.value.toLowerCase(), this.data.project.organizationID);
    if (employee) {
      this.employee = employee;
      this.searchForEmployeeFG.setErrors({});
      this.searchForEmployeeFG.get("isValidEmail").setValue(true);
      this.addEmployeeStepper.next();
    } else {
      this.searchForEmployeeFG.get("isValidEmail").setValue(false);
      this.searchForEmployeeFG.setErrors({ notfound: true });
    }
  }
}
