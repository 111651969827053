import { Injectable } from "@angular/core";
import { APIService, ModelExternalRespondentFilterInput } from "./API.service";
import { GetAllOptions } from "./base.service";
import { ExternalRespondent, ExternalSurvey } from "@inthraction/data-models";
import { Amplify } from "@aws-amplify/core";
import { ExternalRespondentStatus } from "@inthraction/codes";

@Injectable({ providedIn: "root" })
export class ExternalService {
  constructor(
    private api: APIService
  ) {
  }

  async getAll<T>(fn: Function, filter: any, options?: GetAllOptions): Promise<T[]> {
    let results: T[] = [];
    let response: ResponseInterface<T>;
    const limit = options?.limit ? options.limit : 1000;
    do {
      response = await fn(filter, limit, (response?.nextToken ? response.nextToken : null));
      results.push(...response.items);
    } while (response.nextToken);
    return results.filter((elm: T, pos: number, array: T[]) => {
      return array.indexOf(elm) == pos;
    });
  }

  async getEmployeeImageMemoize(id: string) {
    Amplify.configure({
      aws_appsync_authenticationType: "AWS_IAM"
    });
    return this.api.GetEmployeeImage(id);
  }

  async updateExternalSurvey(param: { id: string; surveyResponse: number; responseReceived: boolean; responseReceivedDate: string; feedback?: string; }) {
    Amplify.configure({
      aws_appsync_authenticationType: "AWS_IAM"
    });
    return this.api.UpdateExternalSurvey(param, { or: [{ responseReceived: { attributeExists: false } }, { responseReceived: { eq: false } }] });
  }

  async getSurvey(surveyID: string): Promise<ExternalSurvey> {
    Amplify.configure({
      aws_appsync_authenticationType: "AWS_IAM"
    });
    return this.api.GetExternalSurvey(surveyID);
  }

  async getExternalRespondentByEmail(email: string, status?: ExternalRespondentStatus): Promise<ExternalRespondent> | null {
    const filter: ModelExternalRespondentFilterInput = {
      and: [
        { externalRespondentEmail: { eq: email } }
      ]
    };
    if (status) {
      filter.and.push({ externalRespondentStatus: { eq: status } });
    }
    const respondents = await this.api.ListExternalRespondents(filter);
    return respondents.items.length ? respondents.items[0] : null;
  }

  async createDoNotDisturbRequest(ipAddress: string, externalRespondentID: string, externalRespondentEmail, doNotDisturb: boolean) {
    Amplify.configure({
      aws_appsync_authenticationType: "AWS_IAM"
    });
    await this.api.CreateDoNotDisturbRequest({
      externalRespondentID,
      externalRespondentEmail,
      ipAddress,
      status: doNotDisturb ? "DO_NOT_DISTURB" : "ACTIVE"
    });
  }
}

interface ResponseInterface<T> {
  __typename: string;
  items?: T[];
  nextToken?: string;
}
