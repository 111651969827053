import { Component, OnInit } from "@angular/core";
import {
  SideNavDrawerMenuOption,
  SideNavDrawerMenuOptionType
} from "../../../components/shared/side-nav-drawer/side-nav-drawer.component";
import { environment } from "../../../../environments/environment";
import { OrganizationService } from "@inthraction/services";

@Component({
  selector: 'app-admin-dashboard',
  templateUrl: './admin-dashboard.component.html',
  styleUrls: ['./admin-dashboard.component.scss']
})
export class AdminDashboardComponent implements OnInit {

  private stripeCustomerPortal = environment.stripePortal;
  private encodedBillingEmail: string;

  menuOptions: SideNavDrawerMenuOption[] = [];

  constructor(
    private organizationService: OrganizationService,
  ) {}

  async ngOnInit(): Promise<void> {
    const organizatoin = await this.organizationService.getOrganizationForCurrentUser();
    if(!organizatoin.disabled) {
      this.menuOptions.push(  {
          type: SideNavDrawerMenuOptionType.LINK,
          routerLink: 'users',
          title: 'Users',
          icon: 'person_search'
        },
        {
          type: SideNavDrawerMenuOptionType.LINK,
          routerLink: 'orgchart',
          title: 'Org Chart',
          icon: 'groups'
        },
        {
          type: SideNavDrawerMenuOptionType.LINK,
          routerLink: 'objective-configuration',
          title: 'Objective Configuration',
          icon: 'domain_add'
        },
        {
          type: SideNavDrawerMenuOptionType.LINK,
          routerLink: 'insphration-configuration',
          title: 'inspHRation Configuration',
          icon: 'person_celebrate'
        },
        {
          type: SideNavDrawerMenuOptionType.LINK,
          routerLink: 'system-configuration',
          title: 'System Configuration',
          icon: 'manufacturing'
        });
    }
    const stripeRecord = await this.organizationService.getOrganizationStripeCustomer();
    if (stripeRecord && stripeRecord.billingEmail) {
      this.encodedBillingEmail = encodeURIComponent(stripeRecord.billingEmail);
    }
    this.menuOptions.push({
      type: SideNavDrawerMenuOptionType.ANCHOR,
      href: `${this.stripeCustomerPortal}${this.encodedBillingEmail ? '?prefilled_email='+this.encodedBillingEmail : ''}`,
      title: 'Billing',
      icon: 'payments'
    });
  }

}
