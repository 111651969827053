<mat-card appearance="outlined" class="bio-goal-card" *ngIf="employee">
  <mat-card-content>
    <mat-tab-group mat-stretch-tabs="false">
      <mat-tab label="Personal Biography" *ngIf="employeeDataMap">
        <mat-form-field class="near-full-width">
          <textarea [value]="employeeDataMap.get(employeeDataTypes.BIOGRAPHY).stringValue" matInput maxlength="500" readonly rows="6"></textarea>
        </mat-form-field>
      </mat-tab>
      <mat-tab label="Career Aspirations" *ngIf="employeeDataMap">
        <inthraction-career-aspirations [managerAccess]="managerAccess" [employeeID]="employee.id"></inthraction-career-aspirations>
      </mat-tab>
    </mat-tab-group>
  </mat-card-content>
</mat-card>
