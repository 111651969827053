import { Component, HostListener, Inject, OnInit } from "@angular/core";
import { UserDefinedObjectiveDomainTypes } from "@inthraction/codes";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { ObjectiveService, OrganizationService } from "@inthraction/services";
import { ToastrService } from "ngx-toastr";
import { MatButton } from "@angular/material/button";
import { Objective, Organization } from "@inthraction/data-models";
import { FormGroup } from "@angular/forms";
import { DataSource } from "@angular/cdk/collections";
import { Observable, ReplaySubject } from "rxjs";

@Component({
  selector: 'app-filter-organization-dialog',
  templateUrl: './filter-organization-dialog.component.html',
  styleUrls: ['./filter-organization-dialog.component.scss']
})
export class FilterOrganizationDialogComponent implements OnInit {

  protected readonly UserDefinedObjectiveDomainTypes = UserDefinedObjectiveDomainTypes;
  private objective: Objective;
  readonly sites: string[];
  form: FormGroup;
  private organizations:Organization[] = [];

  readonly displayedColumns: string[] = ['name', 'delete'];
  dataSource:OrganizationsDataSource = new OrganizationsDataSource(this.organizations);
  protected showGrid = false;


  constructor(
    private dialogRef: MatDialogRef<FilterOrganizationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private objectiveService: ObjectiveService,
    private organizationService: OrganizationService,
    private toastr: ToastrService
  ) {
    dialogRef.disableClose = true;
    this.objective = JSON.parse((JSON.stringify(data.objective)));
    this.sites = this.objective.domain.sites;
    this.form = new FormGroup({});
  }

  @HostListener('window:keyup.esc') onKeyUp() {
    this.onCancelClick();
  }

  get objectiveDisplay(): string {
    return this.objective.display;
  }

  async ngOnInit() {

    if (this.objective.organizations?.length) {
      for (let organizationID of this.objective.organizations) {
        const organization = await this.organizationService.getOrganizationByIDMemoize(organizationID);
        if (!organization.disabled) {
          this.addOrganization(organization)
        }
      }
    }

  }

  addOrganization(org: Organization): void {
    if (!this.organizations.map(o => o.id).includes(org.id)) {
      this.organizations.push(org);
      this.organizations = this.organizations.sort((a,b) => a.orgName.localeCompare(b.orgName));
      this.dataSource.setData(this.organizations);
      this.showGrid = true;
    }
  }

  removeOrganization(org: Organization): void {
    if (org) {
      this.organizations = this.organizations.filter(o => o.id != org.id);
      this.dataSource.setData(this.organizations);
      if (!this.organizations.length) {
        this.showGrid = false;
      }
    }
  }

  onCancelClick(): void {
    this.dialogRef.close(null);
  }


  async onSaveClick(formValue: any, submitBtn: MatButton | HTMLButtonElement): Promise<void> {

    this.objective.organizations = this.organizations.map(o => o.id);
    submitBtn.disabled = true;
    try {
      const objectiveResult = await this.objectiveService.updateObjective(this.objective);
      this.toastr.success("Objective Updated");
      this.dialogRef.close({ objective: objectiveResult });
    } catch (err) {
        submitBtn.disabled = false;
        console.error(err);
        this.toastr.error("Unable to update Objective");
        throw err;
      }
  }

  searchOrganization(event: Organization) {
    if(event && !this.organizations.includes(event)) {
      this.addOrganization(event)
    }
  }

}

class OrganizationsDataSource extends DataSource<Organization> {
  private _dataStream = new ReplaySubject<Organization[]>();

  constructor(initialData: Organization[]) {
    super();
    this.setData(initialData);
  }

  connect(): Observable<Organization[]> {
    return this._dataStream;
  }

  disconnect() {}

  setData(data: Organization[]) {
    this._dataStream.next(data);
  }
}
