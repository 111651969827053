import { ConsultantAssociationDataMapper } from "@inthraction/data-mappers/consultant/ConsultantAssociationDataMapper";
import {
  CreateConsultantAssociationMutation,
  GetConsultantAssociationQuery,
  UpdateConsultantAssociationMutation
} from "@inthraction/services";

export class ConsultantAssociation implements ConsultantAssociationDataMapper {
  createdAt?: string;
  employeeID: string;
  incentive: boolean;
  organizationID: string;
  status?: string;
  updatedAt?: string;
  options?;

  constructor() {
  }

  static constructFromCreateMutation(createMutation: CreateConsultantAssociationMutation | GetConsultantAssociationQuery | UpdateConsultantAssociationMutation): ConsultantAssociation {
    const consultantAssociation = new ConsultantAssociation();
    consultantAssociation.organizationID = createMutation.organizationID;
    consultantAssociation.employeeID = createMutation.employeeID;
    consultantAssociation.createdAt = createMutation.createdAt;
    consultantAssociation.updatedAt = createMutation.updatedAt;
    consultantAssociation.incentive = createMutation.incentive;
    consultantAssociation.status = createMutation.status;
    consultantAssociation.options = ConsultantAssociation.convertOptionsToObject(createMutation.options);
    return consultantAssociation;
  }

  private static convertOptionsToObject(options: any): any {
    if (options && (typeof options) === "string") {
      return JSON.parse(options);
    } else {
      return options;
    }
  }

}
