<ng-container *ngIf="employeeDataMap">
<mat-form-field class="near-full-width">
  <mat-label>Do you have aspirations to move outside your current role?</mat-label>
  <textarea *ngIf="managerAccess" matInput maxlength="500" readonly rows="2" [value]="employeeDataMap.get(employeeDataTypes.MOVE_ROLE)?.stringValue"></textarea>
  <textarea *ngIf="!managerAccess && employeeDataMap.get(employeeDataTypes.MOVE_ROLE)" matInput maxlength="500" rows="2" [(ngModel)]="employeeDataMap.get(employeeDataTypes.MOVE_ROLE).stringValue"></textarea>
</mat-form-field>
<mat-form-field class="near-full-width">
  <mat-label>Describe your ideal career role.</mat-label>
  <textarea *ngIf="managerAccess" matInput maxlength="500" readonly rows="2" [value]="employeeDataMap.get(employeeDataTypes.IDEAL_ROLE)?.stringValue"></textarea>
  <textarea *ngIf="!managerAccess && employeeDataMap.get(employeeDataTypes.IDEAL_ROLE)" matInput maxlength="500" rows="2" [(ngModel)]="employeeDataMap.get(employeeDataTypes.IDEAL_ROLE).stringValue"></textarea>
</mat-form-field>
<mat-form-field class="near-full-width">
  <mat-label>What knowledge and skills would assist you in obtaining your ideal career role?</mat-label>
  <textarea *ngIf="managerAccess" matInput maxlength="500" readonly rows="2" [value]="employeeDataMap.get(employeeDataTypes.KNOWLEDGE)?.stringValue"></textarea>
  <textarea *ngIf="!managerAccess && employeeDataMap.get(employeeDataTypes.KNOWLEDGE)" matInput maxlength="500" rows="2" [(ngModel)]="employeeDataMap.get(employeeDataTypes.KNOWLEDGE).stringValue"></textarea>
</mat-form-field>
<mat-form-field class="near-full-width">
  <mat-label>What skills would you like to focus on developing?</mat-label>
  <textarea *ngIf="managerAccess" matInput maxlength="500" readonly rows="2" [value]="employeeDataMap.get(employeeDataTypes.SKILLS)?.stringValue"></textarea>
  <textarea *ngIf="!managerAccess && employeeDataMap.get(employeeDataTypes.SKILLS)" matInput maxlength="500" rows="2" [(ngModel)]="employeeDataMap.get(employeeDataTypes.SKILLS).stringValue"></textarea>
</mat-form-field>
<mat-form-field class="near-full-width">
  <mat-label>What are your career progression expectations?</mat-label>
  <textarea *ngIf="managerAccess" matInput maxlength="500" readonly rows="2" [value]="employeeDataMap.get(employeeDataTypes.CAREER_PATH)?.stringValue"></textarea>
  <textarea *ngIf="!managerAccess && employeeDataMap.get(employeeDataTypes.CAREER_PATH)" matInput maxlength="500" rows="2" [(ngModel)]="employeeDataMap.get(employeeDataTypes.CAREER_PATH).stringValue"></textarea>
</mat-form-field>
<mat-form-field class="near-full-width">
  <mat-label>What strengths could you share to assist others?</mat-label>
  <textarea *ngIf="managerAccess" matInput maxlength="500" readonly rows="2" [value]="employeeDataMap.get(employeeDataTypes.STRENGTHS)?.stringValue"></textarea>
  <textarea *ngIf="!managerAccess && employeeDataMap.get(employeeDataTypes.STRENGTHS)" matInput maxlength="500" rows="2" [(ngModel)]="employeeDataMap.get(employeeDataTypes.STRENGTHS).stringValue"></textarea>
</mat-form-field>
<mat-form-field class="near-full-width">
  <mat-label>What other Career Aspiration details should others know about you?</mat-label>
  <textarea *ngIf="managerAccess" [value]="employeeDataMap.get(employeeDataTypes.CAREER_GOALS)?.stringValue" matInput maxlength="500" readonly rows="6"></textarea>
  <textarea *ngIf="!managerAccess && employeeDataMap.get(employeeDataTypes.CAREER_GOALS)" [(ngModel)]="employeeDataMap.get(employeeDataTypes.CAREER_GOALS).stringValue" matInput maxlength="500" rows="6"></textarea>
</mat-form-field>
</ng-container>
<div class="button-wrapper">
  <button (click)="cancelEditCareerGoals()" *ngIf="!managerAccess" aria-label="Cancel Editing Career Goals"
          class="cancelCareerGoal-btn" mat-button>Cancel
  </button>
  <button (click)="submitCareerGoals()" *ngIf="!managerAccess" aria-label="Save Career Goals"
          class="saveCareerGoal-btn" color="primary" mat-raised-button>
    Save
  </button>
</div>
