<div class="manager-dashboard dashboard">
  <div class="dashboard-page-container">
    <div class="employee-list-container">
      <mat-grid-list rowHeight="240px" [cols]="breakpoint" (window:resize)="onResize($event)">
        <mat-grid-tile *ngFor="let subordinate of subordinates">
          <inthraction-employee-card-component compact [employee]="subordinate" [options]="{hideEmail:true, showCalendarToggle:true, showEdit:true, showOpenIntHRactionCount:true, showRatesOthersChart:true, showIntHRactionsChart:true}"
                                               (calendarToggleEvent)="toggleEmployee($event)" (profileImageClickEvent)="editEmployee($event)" (editClickEvent)="editEmployee($event)"
                                               (showIntHRactionsChartClickEvent)="showIntHRactionsChart($event)" (showRatesOthersChartClickEvent)="showRatesOthersChart($event)">
          </inthraction-employee-card-component>
        </mat-grid-tile>
      </mat-grid-list>
    </div>
    <div [style.position]="'relative'" class="standup-container">
      <mat-card appearance="outlined" class="standup-card">
        <mat-card-header>
          <div class="half-header">
            <mat-card-title>Stand-Up Survey</mat-card-title>
            <mat-card-subtitle *ngIf="standupDetails?.surveyDate">{{standupDetails.surveyDate | amParse:'YYYY-MM-DDTHH:mm:ssZ' | amDateFormat:'dddd, MMMM Do YYYY'}}</mat-card-subtitle>
          </div>
          <div class="half-header">
            <button mat-icon-button class="right" (click)="showStandupConfiguration()">
              <mat-icon>settings</mat-icon>
            </button>
            <button mat-icon-button *ngIf="standupDetails?.surveyDate" class="right" (click)="showStandupHistory()">
              <mat-icon>history</mat-icon>
            </button>
          </div>
        </mat-card-header>
        <mat-card-content class="card-wrapper">
          <ng-container *ngFor="let survey of standupDetails?.surveys">
            <inthraction-stand-up-survey-display [survey]="survey" [note]="standupDetails.notes.get(survey.id)" [user]="currentUser" showCommentButton="true" (commentButtonClickEvent)="openStandupComment(survey)"></inthraction-stand-up-survey-display>
          </ng-container>
        </mat-card-content>
        <inthraction-loading className="" [initDone]="!loadingStandupDetails"></inthraction-loading>
      </mat-card>
    </div>
    <div class="calendar-container">
      <mat-card appearance="outlined">
        <mat-card-header class="calendar-header-container">
          <div class="mat-card-header-text d1">
            <button (viewDateChange)="closeOpenMonthViewDay()" [(viewDate)]="viewDate" [view]="view" aria-label="previous" class="previous-month" mat-icon-button mwlCalendarPreviousView>
              <mat-icon>navigate_before</mat-icon>
            </button>
          </div>
          <div class="mat-card-header-text d2">
            <mat-card-title>{{ viewDate | calendarDate:(view + 'ViewTitle'):'en' }}</mat-card-title>
          </div>
          <div class="mat-card-header-text d3">
            <button (viewDateChange)="closeOpenMonthViewDay()" [(viewDate)]="viewDate" [view]="view" aria-label="next" class="next-month btn btn-primary" mat-icon-button mwlCalendarNextView>
              <mat-icon>navigate_next</mat-icon>
            </button>
          </div>
          <div class="mat-card-header-text"></div>
        </mat-card-header>
        <mat-card-content>
          <mwl-calendar-month-view
            (dayClicked)="dayClicked($event.day)"
            (eventClicked)="handleEvent('Clicked', $event.event)"
            (eventTimesChanged)="eventTimesChanged($event)"
            [events]="userEvents"
            [refresh]="refresh"
            [viewDate]="viewDate"
            (beforeViewRender)="beforeCalRender($event)"
          >
          </mwl-calendar-month-view>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>
