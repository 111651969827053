<div *ngIf="surveyNamesMap">
<mat-card appearance="outlined" class="survey-comment-card" *ngFor="let survey of surveys">
  <mat-card-subtitle>int<span class="inthraction-highlight">HR</span>action Comment</mat-card-subtitle>
  <div class="two-columns">
    <mat-card-title class="">{{surveyNamesMap.get(survey.id)}}</mat-card-title>
    <div>
      <mat-card-subtitle *ngIf="isEventSurvey" class="event-color-{{survey.surveyResponse}}">{{surveyResponseOptionsDisplay[survey.surveyResponse]}}</mat-card-subtitle>
      <mat-card-subtitle *ngIf="!isEventSurvey" class="color-{{survey.surveyResponse}}">{{objectiveResponseOptionsDisplay[survey.surveyResponse]}}</mat-card-subtitle>
      <mat-card-subtitle>{{survey.surveyDate | amParse:'YYYY-MM-DDTHH:mm:ssZ' | amDateFormat:'ll' }}</mat-card-subtitle>
    </div>
  </div>
  <mat-card-content>
    <textarea matInput readonly value="{{survey.feedback}}" cdkTextareaAutosize></textarea>
  </mat-card-content>
</mat-card>
</div>
