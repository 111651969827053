import { Injectable } from "@angular/core";
import { Memoize } from "@inthraction/utils";
import { APIService } from "./API.service";
import { CalEvent } from "@inthraction/data-models";
import { BaseService } from "./base.service";
import { AuthService } from "./auth.service";

@Injectable({ providedIn: "root" })
export class CalendarEventService extends BaseService {

  constructor(
    protected api: APIService,
    protected authService: AuthService
  ) {
    super(api, authService);
  }

  async getCalendarEventByID(id: string): Promise<CalEvent> {
    return this.api.GetCalendarEvent(id);
  }

  @Memoize()
  async getCalendarEventByIDMemoize(id: string): Promise<CalEvent> {
    return this.getCalendarEventByID(id);
  }

  async listCalendarEventsByEmail(email: string, start: string, end: string): Promise<CalEvent[]> {
    const filter = {
      and: [
        {
          or: [
            { attendeeEmails: { contains: email } },
            { organizerEmail: { eq: email } }
          ]
        },
        { start: { between: [start, end] } },
        { private: { eq: false } },
        {
          or: [
            { deleted: { eq: false } },
            { deleted: { attributeExists: false } }
          ]
        }
      ]
    };

    let events = await this.getAll<CalEvent>(this.api.ListCalendarEvents, filter);
    //Filter declined events
    events = events.filter(e => {
      if (e.attendeeEmails.includes(email)) {
        for (const attendee of e.attendees) {
          if (attendee.email == email && attendee.status == "declined") {
            return false;
          }
        }
      }
      return true;
    });
    return events;
  }

  @Memoize({ maxAge: 600000, preFetch: true })
  async listCalendarEventsByEmailMemoize(email: string, start: string, end: string): Promise<CalEvent[]> {
    return this.listCalendarEventsByEmail(email, start, end);
  }

}
