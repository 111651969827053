import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "inthraction-loading",
  templateUrl: "./loading.component.html",
  styleUrls: ["./loading.component.scss"]
})
export class LoadingComponent implements OnInit {

  @Input() className: string;
  @Input() initDone: boolean;

  constructor() {
  }

  ngOnInit(): void {
  }

}
