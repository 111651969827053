<div class="survey-page-container" *ngIf="!invalidSurvey">
  <div></div>
  <mat-card appearance="outlined" *ngIf="survey">
    <inthraction-profile-image [employeeImage]="attendeeImage"></inthraction-profile-image>
    <div class="employee-info">
      <mat-card-title>{{survey.participant.participantName}}</mat-card-title>
      <mat-card-title>{{survey.objectiveDetails.objectiveDisplay}}</mat-card-title>
      <mat-card-subtitle>{{survey.objectiveDetails.objectiveDefinition}}</mat-card-subtitle>
      <mat-card-subtitle>{{survey.objectiveDetails.businessObjective}}</mat-card-subtitle>
      <mat-card-subtitle>{{survey.objectiveDetails.managerDescription}}</mat-card-subtitle>
    </div>
    <div *ngIf="survey.objectiveDetails.objectiveObservables && survey.objectiveDetails.objectiveObservables.length > 0" class="observables-wrapper">
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>Observables</mat-panel-title>
        </mat-expansion-panel-header>
        <mat-panel-description>
          <ul class="objectiveObservables">
            <li *ngFor="let observable of survey.objectiveDetails.objectiveObservables">{{observable}}</li>
          </ul>
        </mat-panel-description>
      </mat-expansion-panel>
    </div>
    <div class="survey-container">
      <label id="survey-radio-group-label">
        <span>You have had several intHRactions with {{survey.participant.participantName}}.  Based on your intHRaction with {{survey.participant.participantName}}, please provide feedback on the objective "{{survey.objectiveDetails.objectiveDispla}}".</span>
      </label>
      <div class="survey-group">
        <ng-container *ngFor="let id of objectiveSurveyResponseTypes">
          <div id="{{id}}-btn" (click)="surveyValue.setValue(objectiveSurveyResponseValues[id])"
               [className]="surveyValue.value === objectiveSurveyResponseValues[id] ? 'selected':''"
               [matTooltipShowDelay]="500" [matTooltipPosition]="'left'"
               matTooltip="{{objectiveSurveyResponseTips[id]}}">
            <button mat-mini-fab id="{{id}}-icon" type="button">
              <mat-icon svgIcon="{{objectiveSurveyResponseIcons[id]}}"></mat-icon>
            </button>
            <span>{{objectiveSurveyResponseLabels[id] + (objectiveSurveyResponseValues[id] === 1 || objectiveSurveyResponseValues[id] === 5 ? ' (Feedback Required)' : '')}}</span>
          </div>
        </ng-container>
      </div>
      <form (ngSubmit)="submitSurvey(surveyForm.value, submitButtonElement)" [formGroup]="surveyForm" autocomplete="off" novalidate>
        <mat-form-field class="full-width">
          <mat-label>Feedback</mat-label>
          <textarea matInput formControlName="feedback" id="feedback" maxlength="500" name="Feedback" rows="3"></textarea>
          <mat-error *ngIf="hasError('feedback', 'required')">Feedback is required</mat-error>
        </mat-form-field>
        <button #submitButtonElement [disabled]="!surveyForm.valid" color="primary" mat-raised-button type="submit">Submit</button>
      </form>
    </div>
  </mat-card>
  <div></div>
</div>
<div class="survey-page-container" *ngIf="invalidSurvey">
  <div></div>
  <mat-card appearance="outlined">
    <mat-card-title>Invalid Survey</mat-card-title>
    <mat-card-subtitle>This survey is no longer valid.</mat-card-subtitle>
  </mat-card>
  <div></div>
</div>
<inthraction-loading className="survey-page-container" [initDone]="invalidSurvey || (survey ? true : false)"></inthraction-loading>
