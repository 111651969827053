import { Component, OnInit } from "@angular/core";
import {
  ObjectiveResponseDistributionMode
} from "../../../components/objective-response-distribution/objective-response-distribution.component";

@Component({
  selector: "app-hr-objective-response-distribution",
  templateUrl: "./hr-objective-response-distribution.component.html",
  styleUrls: ["./hr-objective-response-distribution.component.scss"]
})
export class HrObjectiveResponseDistributionComponent implements OnInit {

  readonly ObjectiveResponseDistributionMode = ObjectiveResponseDistributionMode;

  constructor() {
  }

  ngOnInit(): void {
  }

}
