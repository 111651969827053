<div class="changePasswordWrapper">
  <h1 mat-dialog-title>Change Password</h1>
  <form (ngSubmit)="submitChangePassword(changePasswordForm.value)" [formGroup]="changePasswordForm" autocomplete="off"
        novalidate>
    <div mat-dialog-content>
      <div *ngIf="invalid">
        <mat-icon style="color: red">error</mat-icon>
        <span>Error Changing Password: {{errorMessage}}</span>
      </div>
      <ng-container *ngIf="!success">
        <mat-form-field class="full-width">
          <mat-label>Old Password</mat-label>
          <input matInput formControlName="oldPassword" id="oldPassword" type="password" />
          <mat-error *ngIf="hasError('oldPassword', 'required')">Old Password is required</mat-error>
          <mat-error *ngIf="hasError('oldPassword', 'maxlength')">You have more than 60 characters</mat-error>
        </mat-form-field>
        <mat-form-field class="full-width">
          <mat-label>New Password</mat-label>
          <input matInput formControlName="newPassword" id="newPassword" type="password" />
          <mat-error *ngIf="hasError('newPassword', 'required')">New Password is required</mat-error>
          <mat-error *ngIf="hasError('newPassword', 'maxlength')">You have more than 60 characters</mat-error>
          <mat-error *ngIf="hasError('newPassword', 'minlength')">You must have more than 7 characters</mat-error>
        </mat-form-field>
        <mat-form-field class="full-width">
          <mat-label>Confirm New Password</mat-label>
          <input matInput formControlName="confirmPassword" id="confirmPassword" type="password" />
          <mat-error *ngIf="hasError('confirmPassword', 'required')">Confirm Password is required</mat-error>
          <mat-error *ngIf="hasError('confirmPassword', 'validateConfirmPassword')">Confirm Password must match New
            Password
          </mat-error>
          <mat-error *ngIf="hasError('confirmPassword', 'maxlength')">You have more than 60 characters</mat-error>
        </mat-form-field>
      </ng-container>
      <ng-container *ngIf="success">
        <mat-icon style="color: green">done_outline</mat-icon>
        <span>Password Changed</span>
      </ng-container>
    </div>
    <div mat-dialog-actions>
      <button *ngIf="!success" [disabled]="!changePasswordForm.valid" mat-button>Submit</button>
      <button (click)="close()" mat-button>Close</button>
    </div>
  </form>
</div>
