<h1 mat-dialog-title>Add Team Member</h1>
<mat-horizontal-stepper [linear]="true" #stepper>
  <mat-step [stepControl]="searchForEmployeeFG">
    <form [formGroup]="searchForEmployeeFG">
      <ng-template matStepLabel>Search</ng-template>
      <div mat-dialog-content>
        <mat-form-field class="full-width">
          <mat-label>Search for User by Email</mat-label>
          <mat-autocomplete #emailSearch="matAutocomplete">
            <mat-option *ngFor="let email of filteredEmails | async" [value]="email">{{email}}</mat-option>
          </mat-autocomplete>
          <input [matAutocomplete]="emailSearch" matInput placeholder="jane.doe@example.com" #emailSearchFC formControlName="email"
                 (input)="emailSearchFC.value = emailSearchFC.value.toLowerCase()"
                 (change)="searchForEmployeeFG.get('isValidEmail').setValue(false)">
          <mat-error *ngIf="hasError('required', 'email')">Email is required</mat-error>
          <mat-error *ngIf="hasError('email', 'email')">Must be a valid email address</mat-error>
        </mat-form-field>
      </div>
      <div mat-dialog-actions>
        <button (click)="onSearchClick()" mat-raised-button [disabled]="hasError(null, 'email')">Search</button>
        <div class="form-error">
          <mat-error *ngIf="hasError(null,'notfound')">Email address was not found.</mat-error>
        </div>
      </div>
    </form>
  </mat-step>
  <mat-step>
    <div class="add-team-member-container">
      <ng-template matStepLabel>Add</ng-template>
      <div mat-dialog-content class="team-member-card-container">
        <inthraction-mini-employee-card *ngIf="employee" [employee]="employee"></inthraction-mini-employee-card>
      </div>
      <div mat-dialog-actions>
        <button (click)="onCancelClick()" mat-button type="button">Cancel</button>
        <button (click)="onSaveClick()" color="primary" mat-raised-button type="submit">Add</button>
      </div>
    </div>
  </mat-step>
</mat-horizontal-stepper>
