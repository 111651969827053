export const ONE_ON_ONE_HELP = "Creates a progress check for all Objectives based on the number of days that have elapsed since the \"Last One-On-One Date\" displayed next to the user’s image.";

export const QUARTERLY_REVIEW_HELP = "Creates a progress check for all Objectives based on the last 90 days (from today’s date).  Date ranges may be manually overridden to cover any time frame that covers the user's time with the Organization.";

export const ANNUAL_REVIEW_HELP = "Creates a progress check for all Objectives based on the last 12 months (from today’s date).  Date ranges may be manually overridden to cover any time frame that covers the user's time with the Organization.";

export const OPEN_MANAGER_COMMUNICATION_HELP = "By clicking on the Communication button you, as a Manager, can create notes and communicate in a private channel with your direct report.";

export const MANAGER_NOTES_HELP = "This a place to record general notes and observations when completing a check-in or review.  It is the same interface that you will see when you select the Communication button.";

export const OBJECTIVE_PROGRESS_HELP = "During One-On-Ones, review sessions and check-ins, you can select one or many assigned Objectives and update their progress individually.";

export const OBJECTIVE_PROGRESS_CHECK = "Select the Objective, indicate the progress, and add any notes or observations about the Objective and click on the “Add” button.  If you wish to report on additional Objectives, repeat the process.  This will add your edits to the person’s page. You MUST then select the highlighted “Save” button located on the page to save all of the updates. Once you save your edits, the person will receive a notification of your comments and they will be able to view and respond to them from their Dashboard.";
