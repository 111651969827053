<inthraction-page-header [pageTitle]="'Objective Configuration' + (isShowOrganizationName ? ' for ' + organizationName : '')" [addBtnDisabled]="!organizationObjectiveDomainsMap"  addBtnTitle="Add Organization Defined Objective" (addBtnClickEvent)="addNewObjective()">
</inthraction-page-header>

<div *ngIf="organizationObjectiveDomainsMap" class="objectiveConfigurationContainer {{selectedObjectiveDomain.key == UserDefinedObjectiveDomainTypes.ORG_DEFINED? 'shiftRightChevron' : ''}}">
  <mat-tab-group [(selectedIndex)]="selectedIndex" (selectedTabChange)="tabChanged($event)" mat-stretch-tabs="false" class="objective-configuration-tab-group">
    <mat-tab *ngFor="let domain of objectiveDomains">
      <ng-template mat-tab-label><span title="{{domain.display}}: {{getObjectiveTitles(domain.id)}}">{{domain.display}}</span></ng-template>
      <div class="objectivesContainer">
        <mat-card appearance="outlined" *ngFor="let orgObjective of organizationObjectiveDomainsMap.get(domain.id) | keyvalue | orderBy: 'value.objective.display'">
          <mat-card-header>
            <div class="title-wrapper">
              <mat-card-title>{{orgObjective.value.objective.display}}</mat-card-title>
              <mat-slide-toggle (change)="updateOrgObjective(orgObjective.value, $event)"
                                [checked]="orgObjective.value.enabled"
                                title="Enable Objective for Use"></mat-slide-toggle>
            </div>
            <div class="objectiveSubtitleWrapper">
              <mat-card-subtitle>{{orgObjective.value.objective.definition}}</mat-card-subtitle>
            </div>
          </mat-card-header>
          <mat-card-content>
            <ul class="objectiveObservables">
              <li *ngFor="let observable of orgObjective.value.objective.observables">{{observable}}</li>
            </ul>
            <mat-form-field class="full-width">
              <mat-label>Business Objective</mat-label>
              <input matInput (blur)="updateBusinessObjective(orgObjective.value, $event)" [value]="orgObjective.value.businessObjective">
            </mat-form-field>
            <mat-accordion>
            <mat-expansion-panel *ngIf="orgObjective.value.objective.qrCodes?.length">
              <mat-expansion-panel-header>
                <mat-panel-title>QR Codes</mat-panel-title>
              </mat-expansion-panel-header>
              <ng-container *ngFor="let qr of orgObjective.value.objective.qrCodes">
                <inthraction-qr [qr]="qr"></inthraction-qr>
              </ng-container>
            </mat-expansion-panel>
            </mat-accordion>
          </mat-card-content>
          <mat-card-actions *ngIf="domain.key == UserDefinedObjectiveDomainTypes.ORG_DEFINED">
            <button (click)="editObjective(orgObjective.value.objective)" color="primary" mat-stroked-button>Edit</button>
            <div class="spacer"></div>
            <button mat-button (click)="deleteObjective(orgObjective.value.objective.id)" *ngIf="!activeObjectiveIdSet.has(orgObjective.value.objective.id)">Delete</button>
          </mat-card-actions>
        </mat-card>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>

<inthraction-loading className="objectiveConfigurationContainer" [initDone]="organizationObjectiveDomainsMap ? true : false"></inthraction-loading>
