import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { FormControl, FormGroup, ValidatorFn, Validators } from "@angular/forms";
import { AuthService } from "@inthraction/services";

function validateConfirmPasswordFactory(newPassword: FormControl): ValidatorFn {
  return (c: FormControl) =>
    newPassword.value === c.value ? null : {
      validateConfirmPassword: {
        valid: false
      }
    };
}

@Component({
  selector: "inthraction-change-password",
  templateUrl: "./change-password.component.html",
  styleUrls: ["./change-password.component.scss"]
})
export class ChangePasswordComponent implements OnInit {

  oldPassword = new FormControl("", [Validators.required, Validators.maxLength(60)]);
  newPassword = new FormControl("", [Validators.required, Validators.maxLength(60), Validators.minLength(8)]);
  confirmPassword = new FormControl("", [Validators.required, Validators.maxLength(60), validateConfirmPasswordFactory(this.newPassword)]);
  invalid: boolean;
  success: boolean;
  errorMessage = "Please try again.";

  public changePasswordForm: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<ChangePasswordComponent>,
    private authService: AuthService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.success = false;
    this.invalid = false;
  }

  ngOnInit(): void {
    this.changePasswordForm = new FormGroup({
      oldPassword: this.oldPassword,
      newPassword: this.newPassword,
      confirmPassword: this.confirmPassword
    });
    this.changePasswordForm.get("confirmPassword").disable();
    this.onChanges();
  }

  onChanges(): void {
    this.changePasswordForm.get("newPassword").valueChanges.subscribe(np => {
      if (np && np.length > 0) {
        this.changePasswordForm.get("confirmPassword").reset();
        this.changePasswordForm.get("confirmPassword").enable();
      } else {
        this.changePasswordForm.get("confirmPassword").reset();
        this.changePasswordForm.get("confirmPassword").disable();
      }
    });
  }

  public hasError = (controlName: string, errorName: string) =>
    this.changePasswordForm.controls[controlName].hasError(errorName);

  async submitChangePassword(formValue: any) {
    if (this.changePasswordForm.valid) {
      this.invalid = false;
      try {
        await this.authService.changePassword(formValue.oldPassword, formValue.newPassword);
        this.success = true;
      } catch (err) {
        console.error("Change Password Error", err);
        this.invalid = true;
        this.success = false;
        this.errorMessage = err.message;
      }
    } else {
      this.invalid = true;
      this.success = false;
      this.errorMessage = "Please try again.";
    }
  }

  close(): void {
    this.dialogRef.close();
  }

}
