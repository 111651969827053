import * as memoizee from "memoizee";

export class MemoizeMap<A, B> extends Map<A, B> {

  clear() {
    console.debug("Preventing clear of Memoize Functions");
  }

  delete(key: A): boolean {
    console.debug("Preventing delete of Memoize Functions");
    return true;
  }

  constructor() {
    super();
  }

}

export interface MemoizeConfig {
  maxAge?: number, // time in milliseconds
  preFetch?:  number|true|undefined
}

export const MEMOIZE_FN_MAP = new MemoizeMap<string, any>();

export function Memoize(config?: MemoizeConfig): any {
  // eslint-disable-next-line prefer-arrow/prefer-arrow-functions
  return function(target: any, key: any, descriptor: any): any {
    const oldFunction = descriptor.value;
    const newFunction = memoizee(oldFunction, config);
    MEMOIZE_FN_MAP.set(key, newFunction);
    descriptor.value = function(): any {
      // eslint-disable-next-line no-invalid-this
      return newFunction.apply(this, arguments);
    };
  };
}
