import { Component, Input, OnInit } from "@angular/core";
import { Employee, ObjectiveDomain, Organization, OrganizationObjective } from "@inthraction/data-models";
import { EmployeeService, ObjectiveService, OrganizationService } from "@inthraction/services";
import { UserDefinedObjectiveDomainTypes } from "@inthraction/codes";
import * as moment from "moment";
import { Moment } from "moment";
import {
  ObjectiveDistributionCsvExportAbstractComponent
} from "../objective-distribution-csv-export.abstract-component";
import { DistributionDetails, ObjectiveDistributionDetailsModes } from "../DistributionDetails";


@Component({
  selector: "inthraction-objective-distribution-summary",
  templateUrl: "./objective-distribution-summary.component.html",
  styleUrls: ["./objective-distribution-summary.component.scss"]
})
export class ObjectiveDistributionSummaryComponent extends ObjectiveDistributionCsvExportAbstractComponent implements OnInit {

  @Input() mode?: ObjectiveDistributionDetailsModes;

  pageTitle: string = "Objective Distribution";
  startDate: Moment;
  endDate: Moment;
  organization: Organization;
  objectiveDomains: ObjectiveDomain[];
  selectedObjectiveDomain: ObjectiveDomain;
  organizationObjectiveDomainsMap: Map<string, Map<string, OrganizationObjective>>;

  showAll = true;

  readonly UserDefinedObjectiveDomainTypes = UserDefinedObjectiveDomainTypes;
  readonly ObjectiveDistributionDetailsModes = ObjectiveDistributionDetailsModes;
  disableDownload = false;

  constructor(
    protected organizationService: OrganizationService,
    protected objectiveService: ObjectiveService,
    protected employeeService: EmployeeService
  ) {
    super(objectiveService, employeeService, organizationService);
  }

  async ngOnInit() {
    this.organization = await this.organizationService.getOrganizationForCurrentUser();
    this.startDate = await this.getScoreStartMoment(this.organization.id);
    this.endDate = moment();
    const objectiveDomains: ObjectiveDomain[] = [].concat((await this.objectiveService.getObjectiveDomainsMemoize()).filter(d => d.key !== UserDefinedObjectiveDomainTypes.CONSULTANT_DEFINED)); // Don't directly use the memoize array because OrgDefined type will be added below and we don't want that showing up other places.
    this.organizationObjectiveDomainsMap = await this.refreshOrganizationObjectivesList(objectiveDomains);
    this.objectiveDomains = objectiveDomains.filter(d => this.organizationObjectiveDomainsMap.get(d.id)?.size > 0);
    this.selectedObjectiveDomain = this.objectiveDomains[0];
  }

  private async refreshOrganizationObjectivesList(objectiveDomains: ObjectiveDomain[]): Promise<Map<string, Map<string, OrganizationObjective>>> {
    const _organizationObjectives = new Array(...await this.objectiveService.getOrganizationObjectivesByOrganizationID(this.organization.id, false));
    // const activeObjectiveIdSet: Set<string> = await this.objectiveService.getActiveObjectives(this.organization.id);
    // const organizationObjectives = _organizationObjectives.filter(o => activeObjectiveIdSet.has(o.objective.id)); // Filter to only used objectives.
    const organizationObjectives = _organizationObjectives;

    const orgObjectiveMap = new Map<string, Map<string, OrganizationObjective>>();
    for (const objective of organizationObjectives) {
      if (!orgObjectiveMap.has(objective.objective.objectiveDomainId)) {
        orgObjectiveMap.set(objective.objective.objectiveDomainId, new Map<string, OrganizationObjective>());
      }
      orgObjectiveMap.get(objective.objective.objectiveDomainId).set(objective.objective.id, objective);
    }

    // TODO Add functionality for Custom Organization Specific Domains / Objectives
    let orgDefinedDomain: ObjectiveDomain;
    for (const orgObjectiveDomain of objectiveDomains) {
      if (orgObjectiveDomain.key === UserDefinedObjectiveDomainTypes.ORG_DEFINED) {
        orgDefinedDomain = orgObjectiveDomain;
        break;
      }
    }
    if (!orgObjectiveMap.has(orgDefinedDomain.id)) {
      orgObjectiveMap.set(orgDefinedDomain.id, new Map<string, OrganizationObjective>());
    }
    return orgObjectiveMap;
  }

  tabChanged(index: number) {
    this.selectedObjectiveDomain = this.objectiveDomains[index];
  }

  getObjectiveTitles(id: string): string {
    const objectiveDisplays: string[] = [];
    if (this.organizationObjectiveDomainsMap.get(id)) {
      for (const entry of this.organizationObjectiveDomainsMap.get(id).entries()) {
        objectiveDisplays.push(entry[1].objective.display);
      }
    }
    return objectiveDisplays.join(", ");
  }

  assignmentTotalChange(total: number, organizationObjective: OrganizationObjective, ref: HTMLDivElement) {
    if (!total) {
      if (this.showAll && organizationObjective.enabled) {
        ref.hidden = false;
      } else {
        ref.hidden = true;
      }
    } else {
      ref.hidden = false;
    }
  }

  async downloadCSV(startDate?: Moment, endDate?: Moment) {
    this.disableDownload = true;
    try {
      let _employeeList: Employee[] = [];
      if (this.mode && (this.mode === ObjectiveDistributionDetailsModes.HR || this.mode === ObjectiveDistributionDetailsModes.CONSULTANT)) {
        _employeeList = await this.employeeService.getEmployeesForOrganizationByOrganization({includeDisabled:false, organizationID:this.organization.id, memoize:true});
      } else {
        _employeeList = await this.employeeService.getSubordinatesByEmployeeIDForOrganization({managerID: (await this.employeeService.getCurrentEmployee()).id, memoize:true});
      }
      const detailsMap = new Map<string, DistributionDetails>();
      for (const domain of this.objectiveDomains) {
        for (const orgObjectiveEntry of this.organizationObjectiveDomainsMap.get(domain.id).entries()) {
          const map = await this.gatherStats(_employeeList, orgObjectiveEntry[1], startDate, endDate);
          for (const entry of map.entries()) {
            detailsMap.set(`${entry[0]}_${orgObjectiveEntry[1].id}`, entry[1]);
          }
        }
      }
      super.download(detailsMap, `${this.pageTitle}-${startDate?.format("l")} to ${endDate?.format("l")}.csv`);
    } finally {
      this.disableDownload = false;
    }
  }

}
