import { Component, Input } from "@angular/core";
import { QR } from "@inthraction/data-mappers";

@Component({
  selector: 'inthraction-qr',
  templateUrl: './qr.component.html',
  styleUrls: ['./qr.component.scss']
})
export class QrComponent {

  @Input() qr: QR;

}
