import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from "@angular/core";
import { EmployeeService, ObjectiveService, SurveyService, SurveyType } from "@inthraction/services";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import {
  CadenceObjectiveAssignment,
  Employee,
  EmployeeImageInterface,
  ObjectiveAssignment,
  ObjectiveSurvey,
  QuantifiableObjectiveAssignment
} from "@inthraction/data-models";
import { OBJECTIVE_SURVEY_RESPONSE_LABELS, OBJECTIVE_SURVEY_RESPONSE_TIPS } from "@inthraction/labels";
import * as moment from "moment";
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";
import {
  OBJECTIVE_SURVEY_RESPONSE_ICONS,
  OBJECTIVE_SURVEY_RESPONSE_TYPES,
  OBJECTIVE_SURVEY_RESPONSE_VALUES,
  SurveyStatus
} from "@inthraction/codes";
import { Subscription } from "rxjs";
import { MatButton } from "@angular/material/button";
import { SurveyCompleteEvent } from "../survey/survey.component";

@Component({
  selector: "inthraction-objective-survey",
  templateUrl: "./objective-survey.component.html",
  styleUrls: ["./objective-survey.component.scss"]
})
export class ObjectiveSurveyComponent implements OnInit, OnDestroy, OnChanges {

  static surveyIDParameterName = "survey-id";
  static surveyResponseParameterName = "survey-response";
  @ViewChild("submitButtonElement") submitButtonElm: ElementRef;
  attendee: Employee;
  attendeeImage: EmployeeImageInterface;
  surveyForm: FormGroup;
  objectiveAssignment: ObjectiveAssignment | QuantifiableObjectiveAssignment | CadenceObjectiveAssignment;
  readonly objectiveSurveyResponseTypes = Object.assign([], OBJECTIVE_SURVEY_RESPONSE_TYPES).reverse();
  readonly objectiveSurveyResponseLabels = OBJECTIVE_SURVEY_RESPONSE_LABELS;
  readonly objectiveSurveyResponseValues = OBJECTIVE_SURVEY_RESPONSE_VALUES;
  readonly objectiveSurveyResponseTips = OBJECTIVE_SURVEY_RESPONSE_TIPS;
  readonly objectiveSurveyResponseIcons = OBJECTIVE_SURVEY_RESPONSE_ICONS;
  feedback = new FormControl("", []);
  surveyValue = new FormControl("", [Validators.required]);
  private surveyValueChangeSubscription: Subscription;

  @Input() survey: ObjectiveSurvey;
  @Input() employee: Employee;
  @Input() remainingSurveysCount? = 0;
  @Input() surveyResponse?: number;
  @Output() surveyComplete: EventEmitter<SurveyCompleteEvent> = new EventEmitter<SurveyCompleteEvent>();

  constructor(
    private employeeService: EmployeeService,
    protected surveyService: SurveyService,
    private objectiveService: ObjectiveService,
    protected toastr: ToastrService,
    protected iconRegistry: MatIconRegistry,
    protected sanitizer: DomSanitizer
  ) {
    iconRegistry.addSvgIcon("north", sanitizer.bypassSecurityTrustResourceUrl("assets/font-icons/arrow_north-24px.svg"));
    iconRegistry.addSvgIcon("north-east", sanitizer.bypassSecurityTrustResourceUrl("assets/font-icons/arrow_north_east-24px.svg"));
    iconRegistry.addSvgIcon("east", sanitizer.bypassSecurityTrustResourceUrl("assets/font-icons/arrow_east-24px.svg"));
    iconRegistry.addSvgIcon("south-east", sanitizer.bypassSecurityTrustResourceUrl("assets/font-icons/arrow_south_east-24px.svg"));
    iconRegistry.addSvgIcon("south", sanitizer.bypassSecurityTrustResourceUrl("assets/font-icons/arrow_south-24px.svg"));

    this.surveyForm = new FormGroup(
      {
        surveyValue: this.surveyValue,
        feedback: this.feedback
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.survey && changes.survey.currentValue) {
      this.surveyForm.reset();
      this.attendee = null;
      this.attendeeImage = null;
      this.objectiveAssignment = null;
      this.initialize(changes.survey.currentValue, this.surveyResponse);
    }
    if (changes.surveyResponse && changes.surveyResponse.currentValue) {
      this.initializeResponse(changes.surveyResponse.currentValue);
    }
  }

  ngOnDestroy(): void {
    if (this.surveyValueChangeSubscription) {
      this.surveyValueChangeSubscription.unsubscribe();
    }
  }

  async ngOnInit(): Promise<void> {

    this.surveyValueChangeSubscription = this.surveyForm.get("surveyValue").valueChanges.subscribe(surveyValue => {
      if (surveyValue === OBJECTIVE_SURVEY_RESPONSE_VALUES.NEEDS_IMPROVEMENT || surveyValue === OBJECTIVE_SURVEY_RESPONSE_VALUES.EXCEEDS) {
        this.surveyForm.get("feedback").setValidators([Validators.required]);
        this.surveyForm.get("feedback").markAsTouched();
      } else {
        this.surveyForm.get("feedback").setValidators(null);
      }
      this.surveyForm.get("feedback").updateValueAndValidity();

      /*
       * Move focus to submit button after selecting an option to allow enter button to submit the form.
       * This is necessary because the options are using a custom styling that makes them not part of the form.
       */
      if (this.submitButtonElm) {
        setTimeout((elementRef) => {
            elementRef.nativeElement.focus();
          }, 100,
          // @ts-ignore //Submit button is wrapped with mat-button and is not a standard element.
          this.submitButtonElm._elementRef);
      }

    });

    await this.initialize(this.survey, this.surveyResponse);
  }

  async initialize(survey: ObjectiveSurvey, response: number) {
    if (survey) {
      this.objectiveAssignment = await this.objectiveService.getObjectiveAssignment(survey.objectID);
      if (!this.objectiveAssignment) {
        // Objective Assignment Must Exist

        await this.surveyService.updateSurvey({
          id: survey.id,
          objectID: survey.objectID,
          status: SurveyStatus.DELETED,
          surveyDate: survey.surveyDate,
          surveyType: SurveyType[survey.surveyType]
        });

        this.toastr.error("The Objective for this survey no longer exist");
        this.surveyComplete.emit({ completedSurveyIDs: [survey.id] });
        return;
      }
      this.attendee = await this.employeeService.getEmployeeByEmailMemoize(survey.participantEmail);
      this.attendeeImage = await this.employeeService.getEmployeeImageMemoize(this.attendee.id, this.attendee.orgId);
    }
    await this.initializeResponse(response);
  }

  async initializeResponse(response: number) {
    if ((response || response === 0) && !Number.isNaN(response) && response <= OBJECTIVE_SURVEY_RESPONSE_VALUES.EXCEEDS && response >= OBJECTIVE_SURVEY_RESPONSE_VALUES.NEEDS_IMPROVEMENT) {
      if (response === OBJECTIVE_SURVEY_RESPONSE_VALUES.NEEDS_IMPROVEMENT || response === OBJECTIVE_SURVEY_RESPONSE_VALUES.EXCEEDS) {
        this.surveyForm.get("feedback").markAsTouched();
      }
      this.surveyForm.get("surveyValue").setValue(response);
    } else {
      this.surveyResponse = null;
    }
  }

  public hasError = (controlName: string, errorName: string) =>
    this.surveyForm.controls[controlName].hasError(errorName);

  async submitSurvey(formValue: any, submitBtn: MatButton | HTMLButtonElement): Promise<void> {
    submitBtn.disabled = true;
    if (this.surveyForm.valid) {
      this.survey.surveyResponse = formValue.surveyValue;
      this.survey.feedback = formValue.feedback;
      this.survey.responseReceived = true;
      this.survey.responseReceivedDate = moment().milliseconds(0).toISOString().replace(".000Z", "Z");

      await this.surveyService.updateSurvey({
        id: this.survey.id,
        objectID: this.survey.objectID,
        feedback: ((this.survey.feedback == null || this.survey.feedback.length <= 0) ? null : this.survey.feedback),
        surveyResponse: this.survey.surveyResponse,
        responseReceived: true,
        responseReceivedDate: this.survey.responseReceivedDate,
        surveyDate: this.survey.surveyDate,
        surveyType: SurveyType[this.survey.surveyType]
      });
      this.toastr.success("Thank you for your response", "intHRaction Received");
      this.surveyComplete.emit({ completedSurveyIDs: [this.survey.id] });
    } else {
      submitBtn.disabled = !this.surveyForm.valid;
      this.toastr.error("Please try again.");
    }
  }

}
