import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { AddProjectComponent } from "../../../components/add-project/add-project.component";
import { MatDialog } from "@angular/material/dialog";
import { Employee, EmployeeImpl, Note, Project, ProjectMilestoneAssignment } from "@inthraction/data-models";
import { EmployeeService, ProjectService, SurveyService, UpdateProject } from "@inthraction/services";
import { CADENCE_TYPES } from "@inthraction/codes";
import {
  CADENCE_TYPE_LABELS,
  PROJECT_DESCRIPTION_HELP,
  PROJECT_MILESTONES_HELP,
  PROJECT_NOTES_HELP,
  PROJECT_TEAM_HELP,
  PROJECT_TEAM_INTHRACTION_HELP
} from "@inthraction/labels";
import { MatTabChangeEvent } from "@angular/material/tabs";
import { AddTeamMemberComponent } from "../../../components/add-team-member/add-team-member.component";
import { AddEditProjectMilestoneComponent } from "../../../components/add-edit-project-milestone/add-edit-project-milestone.component";
import { AddEditProjectNoteComponent } from "../../../components/shared/add-edit-project-note/add-edit-project-note.component";
import { ToastrService } from "ngx-toastr";
import * as moment from "moment";
import { ConfirmationDialogComponent } from "../../../components/shared/dialogs/confirmation-dialog/confirmation-dialog.component";
import { ProjectSurveyResultsComponent } from "../../../components/shared/dialogs/project-survey-results/project-survey-results.component";
import { ProjectScoreChartSelection } from "../../../components/shared/project-score-chart/project-score-chart.component";
import { Subscription } from "rxjs";
import { ActivatedRoute } from "@angular/router";
import { Location } from "@angular/common";

@Component({
  selector: "inthraction-project-dashboard",
  templateUrl: "./project-dashboard.component.html",
  styleUrls: ["./project-dashboard.component.scss"]
})
export class ProjectDashboardComponent implements OnInit, OnDestroy {

  static PROJECT_ID_PARAMETER = "project-id";

  readonly cadenceTypeLabels = CADENCE_TYPE_LABELS;
  readonly cadenceTypes = CADENCE_TYPES;
  readonly PROJECT_DESCRIPTION_HELP = PROJECT_DESCRIPTION_HELP;
  readonly PROJECT_NOTES_HELP = PROJECT_NOTES_HELP;
  readonly PROJECT_TEAM_HELP = PROJECT_TEAM_HELP;
  readonly PROJECT_TEAM_INTHRACTION_HELP = PROJECT_TEAM_INTHRACTION_HELP;
  readonly PROJECT_MILESTONES_HELP = PROJECT_MILESTONES_HELP;

  @Input() organizationID: string;
  @Input() mode: ProjectDashboardMode;

  employee: EmployeeImpl;
  editMode = false;
  projectManagerAccess = false;
  projects: Project[] = [];
  currentProject: Project;
  currentProjectScore = 0;
  milestones: ProjectMilestoneAssignment[] = [];
  notes: Note[];
  employeeMap: Map<string, Employee> = new Map<string, Employee>();
  private cloneProject: Project;
  private currentProjectIndex: number;
  private subscriptions: Subscription[] = [];

  constructor(
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private projectService: ProjectService,
    private employeeService: EmployeeService,
    private toastrService: ToastrService,
    private location: Location
  ) {}

  get canEdit(): boolean {
    return this.projectManagerAccess && this.employee.id === this.currentProject?.projectManagerID;
  }

  ngOnDestroy(): void {
    if (this.subscriptions.length) {
      for (const sub of this.subscriptions) {
        sub.unsubscribe();
      }
    }
  }

  async ngOnInit() {
    if(!this.mode){
      this.mode = ProjectDashboardMode.DEFAULT;
    }
    this.employee = new EmployeeImpl(await this.employeeService.getCurrentEmployee());
    if (this.mode === ProjectDashboardMode.CONSULTANT) { // Consultant Mode
      this.projectManagerAccess = true;
      this.projects = await this.projectService.getAllProjectsForConsultantUserMemoized(this.employee.id, this.organizationID);
    } else {
      this.projectManagerAccess = this.employee.isProjectManager();
      this.projects = await this.projectService.getAllProjectsForUserMemoized(this.employee.id);
    }

    if (this.projects && this.projects.length > 0) {
      this.projects.sort((a, b) => (b.endDate > a.endDate) ? 1 : ((a.endDate > b.endDate) ? -1 : 0));
      this.currentProjectIndex = 0;
      if (this.route.snapshot.paramMap.get(ProjectDashboardComponent.PROJECT_ID_PARAMETER)) {
        let index = 0;
        while (index < this.projects.length && this.projects[index].id !== this.route.snapshot.paramMap.get(ProjectDashboardComponent.PROJECT_ID_PARAMETER)) {
          index++;
        }
        if (this.projects[index] && this.projects[index].id === this.route.snapshot.paramMap.get(ProjectDashboardComponent.PROJECT_ID_PARAMETER)) {
          this.currentProjectIndex = index;
        }
      }
      await this.initializeProject(this.projects[this.currentProjectIndex]);
    }
  }

  async initializeProject(project: Project): Promise<void> {
    let urlSegments: string[] = this.location.path().split('/');
    urlSegments = urlSegments.filter(s=> s.length > 0);
    let url = "";
    let i = 0;
    const projectNotSpecified = this.location.path().endsWith('/project-dashboard') || this.location.path().endsWith('/project')
    const stop = !projectNotSpecified ? urlSegments.length - 1 : urlSegments.length;
    while (i < stop && i < 10) {
      url = url + `/${urlSegments[i]}`;
      i++;
    }
    this.location.replaceState(`${url}/${project.id}`);
    this.cloneProject = null;
    this.editMode = false;
    this.milestones = [];
    this.currentProjectScore = 0;
    this.currentProject = project;
    const teamMap = await this.initializeTeam(project.team);
    if (!teamMap.has(project.projectManagerID)) {
      teamMap.set(project.projectManagerID, await this.employeeService.getEmployeeByIDMemoize(project.projectManagerID));
    }
    this.employeeMap = teamMap;
    await this.initializeMilestones();
    await this.initializeNotes();
  }

  async tabChanged(event: MatTabChangeEvent) {
    this.currentProjectIndex = event.index;
    this.currentProject = this.projects[event.index];
    await this.initializeProject(this.currentProject);
  }

  openAddProject() {

    if (this.editMode) {
      this.onCancelProjectEditClick();
    }

    const addDialog = this.dialog.open(AddProjectComponent, {
      width: "600px",
      data: {
        employee: this.employee,
        organizationID: this.mode === ProjectDashboardMode.CONSULTANT ? this.organizationID : null
      }
    });

    this.subscriptions.push(
      addDialog.afterClosed().subscribe(result => {
        if (result && result.project) {
          this.projects.push(result.project);
          this.projectService.clearMemoizedProjectsForUser(this.employee.id);
        }
      }));

  }

  toggleEditProject() {
    if (this.editMode) {
      this.onCancelProjectEditClick();
    } else {
      this.cloneProject = this.currentProject;
      this.currentProject = this.deepCopy(this.currentProject);
      this.editMode = true;
    }
  }

  onCancelProjectEditClick() {
    this.currentProject = this.cloneProject;
    this.editMode = false;
    this.toastrService.info("Project update canceled");
  }

  async onSaveProjectEditClick() {
    let needsUpdate = false;
    let needsUpdateNextSurveyDate = false;
    const updateProjectInput: UpdateProject = {
      id: this.currentProject.id
    };

    if (this.currentProject.title && this.currentProject.title !== this.cloneProject.title) {
      updateProjectInput.title = this.currentProject.title;
      needsUpdate = true;
    }

    if (this.currentProject.description && this.currentProject.description !== this.cloneProject.description) {
      updateProjectInput.description = this.currentProject.description;
      needsUpdate = true;
    }

    if (this.currentProject.startDate && this.currentProject.startDate !== this.cloneProject.startDate) {
      updateProjectInput.startDate = moment(this.currentProject.startDate).format("YYYY-MM-DD");
      needsUpdate = true;
      needsUpdateNextSurveyDate = true;
    }

    if (this.currentProject.endDate && this.currentProject.endDate !== this.cloneProject.endDate) {
      updateProjectInput.endDate = moment(this.currentProject.endDate).format("YYYY-MM-DD");
      needsUpdate = true;
      needsUpdateNextSurveyDate = true;
    }

    if (this.currentProject.cadence && this.currentProject.cadence !== this.cloneProject.cadence) {
      updateProjectInput.cadence = this.currentProject.cadence;
      needsUpdate = true;
      needsUpdateNextSurveyDate = true;
    }

    if (needsUpdate) {

      if (needsUpdateNextSurveyDate) {
        updateProjectInput.nextSurveyDate = SurveyService.calculateNextSurveyDate(this.currentProject.startDate, this.currentProject.cadence, this.currentProject.endDate);
      }

      this.currentProject = await this.projectService.updateProject(updateProjectInput);
      this.projects[this.currentProjectIndex] = this.currentProject;
      this.toastrService.success("Project updated");
    } else {
      this.onCancelProjectEditClick();
    }
    this.editMode = false;
  }

  openAddTeamMember() {

    if (this.editMode) {
      this.onCancelProjectEditClick();
    }

    const addDialog = this.dialog.open(AddTeamMemberComponent, {
      width: "600px",
      data: { project: this.currentProject }
    });

    this.subscriptions.push(
      addDialog.afterClosed().subscribe(async result => {
        if (result && result.team) {
          this.currentProject.team = result.team;
          await this.initializeTeam(result.team);
          await this.projectService.updateProjectTeam(this.currentProject);
        }
      }));
  }

  openAddMilestone() {

    if (this.editMode) {
      this.onCancelProjectEditClick();
    }

    const addDialog = this.dialog.open(AddEditProjectMilestoneComponent, {
      width: "600px",
      data: { project: this.currentProject }
    });

    this.subscriptions.push(
      addDialog.afterClosed().subscribe(result => {
        if (result && result.milestone) {
          this.milestones.push(result.milestone);
          this.milestones.sort((a, b) => a.startDate < b.startDate ? -1 : a.startDate > b.startDate ? 1 : a.endDate < b.endDate ? -1 : a.endDate > b.endDate ? 1 : 0);
        }
      }));
  }

  openEditMilestone(milestoneID: string) {
    if (this.editMode) {
      this.onCancelProjectEditClick();
    }

    const addDialog = this.dialog.open(AddEditProjectMilestoneComponent, {
      width: "600px",
      data: { project: this.currentProject, milestoneID }
    });

    this.subscriptions.push(
      addDialog.afterClosed().subscribe(async result => {
        if (result && result.milestone) {
          this.projectService.clearMemoizedMilestones(this.currentProject.id);
          await this.initializeMilestones();
        }
      }));

  }

  openAddNote() {

    if (this.editMode) {
      this.onCancelProjectEditClick();
    }

    const addDialog = this.dialog.open(AddEditProjectNoteComponent, {
      width: "600px",
      data: { project: this.currentProject }
    });

    this.subscriptions.push(
      addDialog.afterClosed().subscribe(result => {
        if (result && result.note) {
          this.notes.unshift(result.note);
          const notes = [...this.notes];
          this.notes = notes;
        }
      }));
  }

  openEditNote($event) {

    if (this.editMode) {
      this.onCancelProjectEditClick();
    }
    const addDialog = this.dialog.open(AddEditProjectNoteComponent, {
      width: "600px",
      data: { project: this.currentProject, noteId: $event.noteId }
    });

    this.subscriptions.push(
      addDialog.afterClosed().subscribe(async result => {
        if (result && result.note) {
          this.projectService.clearMemoizedNotes(this.currentProject.id);
          await this.initializeNotes();
        }
      }));
  }

  openRemoveTeamMember(teamMemberId: string) {
    if (this.editMode) {
      this.onCancelProjectEditClick();
    }

    const deleteDialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: "350px",
      data: "Are you sure you want to Remove this Team Member from the Project?"
    });
    this.subscriptions.push(
      deleteDialogRef.afterClosed().subscribe(async result => {
        if (result) {
          const index = this.currentProject.team.indexOf(teamMemberId, 0);
          if (index > -1) {
            this.currentProject.team.splice(index, 1);
          }
          for (const stone of this.milestones) {
            if (stone.team && stone.team.includes(teamMemberId)) {
              const stoneTeamIndex = stone.team.indexOf(teamMemberId, 0);
              if (stoneTeamIndex > -1) {
                stone.team.splice(stoneTeamIndex, 1);
              }
              this.projectService.clearMemoizedMilestones(this.currentProject.id);
              await this.projectService.updateMilestone({
                id: stone.id,
                team: stone.team.length > 0 ? stone.team : null
              });
            }
          }
          this.projectService.clearMemoizedProjectsForUser(this.employee.id);
          await this.projectService.updateProjectTeam(this.currentProject);
        }
      }));
  }

  openProjectSurveyResults($event: ProjectScoreChartSelection) {
    this.dialog.open(ProjectSurveyResultsComponent, {
      width: "600px",
      data: { selection: $event, isProjectManager: this.projectManagerAccess }
    });
  }

  setCurrentProjectScore(newScore: number) {
    this.currentProjectScore = newScore;
  }

  private async initializeTeam(team: string[]): Promise<Map<string, Employee>> {
    const teamMap = new Map<string, Employee>();
    if (team) {
      for (const employeeId of team) {
        if (!teamMap.has(employeeId)) {
          teamMap.set(employeeId, await this.employeeService.getEmployeeByIDMemoize(employeeId));
        }
      }
    }
    return teamMap;
  }

  private async initializeMilestones() {
    const milestones: ProjectMilestoneAssignment[] = await this.projectService.getProjectMilestoneAssignmentsByProjectIDMemoized(this.currentProject.id);
    if (milestones) {
      milestones.sort((a, b) => a.startDate < b.startDate ? -1 : a.startDate > b.startDate ? 1 : a.endDate < b.endDate ? -1 : a.endDate > b.endDate ? 1 : 0);
      this.milestones = milestones;
    } else {
      this.milestones = [];
    }
  }

  private async initializeNotes() {
    this.notes = await this.projectService.getProjectNotesByProjectIDMemoized(this.currentProject.id, this.employee.id === this.currentProject.projectManagerID);
  }

  private deepCopy(obj) {
    let copy;

    // Handle the 3 simple types, and null or undefined
    if (null == obj || "object" !== typeof obj) return obj;

    // Handle Date
    if (obj instanceof Date) {
      copy = new Date();
      copy.setTime(obj.getTime());
      return copy;
    }

    // Handle Array
    if (obj instanceof Array) {
      copy = [];
      for (let i = 0, len = obj.length; i < len; i++) {
        copy[i] = this.deepCopy(obj[i]);
      }
      return copy;
    }

    // Handle Object
    if (obj instanceof Object) {
      copy = {};
      for (const attr in obj) {
        if (obj.hasOwnProperty(attr)) copy[attr] = this.deepCopy(obj[attr]);
      }
      return copy;
    }

    throw new Error("Unable to copy obj! Its type isn't supported.");
  }

}

export enum ProjectDashboardMode {
  DEFAULT,
  CONSULTANT
}
