import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { firstValueFrom } from "rxjs";

@Injectable({ providedIn: "root" })
export class IPService {


  constructor(private http: HttpClient) {
  }

  async getIP(): Promise<string> {
    const response: ipResponse = await firstValueFrom(this.http.get<ipResponse>("https://api.ipify.org/?format=json"));
    return response.ip;
  }
}

export interface ipResponse {
  ip: string;
}
