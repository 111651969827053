<h1 mat-dialog-title>{{addMode ? 'Add' : 'Edit'}} insp<span class="inthraction-highlight">HR</span>ation Type</h1>
<ng-container *ngIf="addMode">
  <mat-horizontal-stepper [linear]="true" #stepper>
    <mat-step [stepControl]="selectShoutOutTypeFG">
      <form [formGroup]="selectShoutOutTypeFG">
        <ng-template matStepLabel>Select insp<span class="inthraction-highlight">HR</span>ation Template</ng-template>
        <div mat-dialog-content>
          <mat-form-field>
            <mat-select formControlName="shoutOutId" (selectionChange)="shoutOutChange($event)">
              <mat-select-trigger>
                <div *ngIf="selectedShoutOutType">
                  <mat-icon [svgIcon]="selectedShoutOutType.icon"></mat-icon>
                  {{selectedShoutOutType.title}}
                </div>
              </mat-select-trigger>
              <mat-option *ngFor="let shoutOutType of shoutOutTypes" [value]="shoutOutType.id">
                <mat-icon [svgIcon]="shoutOutType.icon"></mat-icon>
                {{shoutOutType.title}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div mat-dialog-actions>
          <button (click)="onCancelClick()" mat-button type="button">Cancel</button>
          <button matStepperNext mat-raised-button type="submit">Next</button>
        </div>
      </form>
    </mat-step>
    <mat-step [stepControl]="shoutOutTypeForm">
      <form (ngSubmit)="onSaveClick(shoutOutTypeForm.value)" [formGroup]="shoutOutTypeForm" autocomplete="off" novalidate>
        <ng-template matStepLabel>Configure Details</ng-template>
        <div mat-dialog-content>
          <div class="icon-wrapper">
            <mat-card-title>{{selectedShoutOutType?.title}}</mat-card-title>
            <mat-icon [svgIcon]="selectedShoutOutType?.icon" class="inspHRation-icon"></mat-icon>
          </div>
          <div class="title-wrapper">
            <mat-form-field>
              <mat-label>Title</mat-label>
              <input matInput formControlName="title">
              <mat-error *ngIf="hasError('title', 'required')">Title is required</mat-error>
            </mat-form-field>
          </div>

          <div class="formalSelectorContainer">
            <button mat-raised-button type="button" (click)="setDescription(true)" [disabled]="!shoutOutTypeForm.get('shoutOutId').value">Formal</button>
            <button mat-raised-button type="button" (click)="setDescription()" [disabled]="!shoutOutTypeForm.get('shoutOutId').value">Informal</button>
          </div>

          <mat-form-field>
            <mat-label>Description</mat-label>
            <textarea matInput formControlName="description" maxlength="500" rows="3" cdkTextareaAutosize></textarea>
            <mat-error *ngIf="hasError('description', 'required')">Description is required</mat-error>
          </mat-form-field>

          <mat-card appearance="outlined" *ngIf="selectedShoutOutType">
            <mat-card-header>
              <mat-card-subtitle>Attributes</mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
              <ng-container *ngFor="let attribute of selectedShoutOutType.attributes; let i = index">
                <div class="system-attributes">
                  <mat-form-field>
                    <mat-label>Attribute {{i + 1}}</mat-label>
                    <textarea matInput [value]="attribute" readonly cdkTextareaAutosize></textarea>
                  </mat-form-field>
                  <div>
                    <mat-slide-toggle matInput (change)="updateAttributeStatus($event, i)" [checked]="!disabledAttributes.includes(i)"></mat-slide-toggle>
                  </div>
                </div>
              </ng-container>
              <ng-container *ngFor="let attribute of organizationAttributes; let i = index">
                <mat-form-field>
                  <mat-label>Attribute {{i + selectedShoutOutType.attributes.length + 1}}</mat-label>
                  <textarea matInput [(ngModel)]="organizationAttributes[i]" [ngModelOptions]="{standalone: true, updateOn: 'blur'}" cdkTextareaAutosize></textarea>
                </mat-form-field>
              </ng-container>
            </mat-card-content>
            <mat-card-actions>
              <button (click)="organizationAttributes.push('')" aria-label="Add New Attribute" mat-icon-button title="Add New Attribute">
                <mat-icon>add</mat-icon>
              </button>
            </mat-card-actions>
          </mat-card>

        </div>
        <div mat-dialog-actions>
          <button matStepperPrevious mat-raised-button type="button">Back</button>
          <div class="spacer"></div>
          <button (click)="onCancelClick()" mat-button type="button">Cancel</button>
          <button [disabled]="!shoutOutTypeForm.valid" color="primary" mat-raised-button type="submit">Save</button>
        </div>
      </form>
    </mat-step>
  </mat-horizontal-stepper>
</ng-container>
<ng-container *ngIf="!addMode">
  <form (ngSubmit)="onSaveClick(shoutOutTypeForm.value)" [formGroup]="shoutOutTypeForm" autocomplete="off" novalidate>
    <div mat-dialog-content>
      <div class="icon-wrapper">
        <mat-card-title>{{selectedShoutOutType.title}}</mat-card-title>
        <mat-icon class="inspHRation-icon" [svgIcon]="selectedShoutOutType.icon"></mat-icon>
      </div>
      <div class="title-wrapper">
        <mat-form-field>
          <mat-label>Title</mat-label>
          <input matInput formControlName="title">
          <mat-error *ngIf="hasError('title', 'required')">Title is required</mat-error>
        </mat-form-field>
      </div>

      <div class="formalSelectorContainer">
        <button mat-raised-button type="button" (click)="setDescription(true)" [disabled]="!shoutOutTypeForm.get('shoutOutId').value">Formal</button>
        <button mat-raised-button type="button" (click)="setDescription()" [disabled]="!shoutOutTypeForm.get('shoutOutId').value">Informal</button>
      </div>

      <mat-form-field>
        <mat-label>Description</mat-label>
        <textarea matInput formControlName="description" maxlength="500" rows="3" cdkTextareaAutosize></textarea>
        <mat-error *ngIf="hasError('description', 'required')">Description is required</mat-error>
      </mat-form-field>

      <mat-card appearance="outlined">
        <mat-card-header>
          <mat-card-subtitle>Attributes</mat-card-subtitle>
        </mat-card-header>

        <mat-card-content>
          <ng-container *ngFor="let attribute of selectedShoutOutType.attributes; let i = index">
            <div class="system-attributes">
              <mat-form-field>
                <mat-label>Attribute {{i + 1}}</mat-label>
                <textarea matInput [value]="attribute" readonly cdkTextareaAutosize></textarea>
              </mat-form-field>
              <div>
                <mat-slide-toggle matInput (change)="updateAttributeStatus($event, i)" [checked]="!disabledAttributes.includes(i)"></mat-slide-toggle>
              </div>
            </div>
          </ng-container>
          <ng-container *ngFor="let attribute of organizationAttributes; let i = index">
            <mat-form-field>
              <mat-label>Attribute {{i + selectedShoutOutType.attributes.length + 1}}</mat-label>
              <textarea matInput [(ngModel)]="organizationAttributes[i]" [ngModelOptions]="{standalone: true, updateOn: 'blur'}" cdkTextareaAutosize></textarea>
            </mat-form-field>
          </ng-container>
        </mat-card-content>
        <mat-card-actions>
          <a (click)="organizationAttributes.push('')" aria-label="Add New Attribute" mat-icon-button title="Add New Attribute">
            <mat-icon>add</mat-icon>
          </a>
        </mat-card-actions>
      </mat-card>

    </div>
    <div mat-dialog-actions>
      <div class="spacer"></div>
      <button (click)="onCancelClick()" mat-button type="button">Cancel</button>
      <button [disabled]="!shoutOutTypeForm.valid" color="primary" mat-raised-button type="submit">Save</button>
    </div>
  </form>
</ng-container>
