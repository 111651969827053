 <inthraction-help-bubble [message]="CONSULTANT_FAVORITES_HELP" title="Favorites" tip="Favorites Help" class="zero-top zero-right"></inthraction-help-bubble>
    <inthraction-team-view-component
      *ngIf="topEmployees && topEmployees.length > 0"
      [employees]="topEmployees"
      (selectedEmployeeID)="employeeChanged($event)"
      [navToEmployeeID]="navToEmployeeID"
      [employeeCardOptions]="{showCriticalPosition:false, showReadiness:false, showEdit:true, showOpenIntHRactionCount:true, showFavoriteToggle:true, favorites:favorites, showOrgNav:false}"
      (employeeEditClickEvent)="hrEditEmployee($event)"
      (profileImageClickEvent)="hrEditEmployee($event)"
      (favoriteClickEvent)="favoriteToggle($event)"
    >
    </inthraction-team-view-component>
