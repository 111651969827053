import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

export interface StandardDialogData {
  title: string;
  message: string;
}

@Component({
  selector: "inthraction-standard-dialog-component",
  templateUrl: "./standard-dialog-component.component.html",
  styleUrls: ["./standard-dialog-component.component.scss"]
})
export class StandardDialogComponentComponent {

  constructor(
    public dialogRef: MatDialogRef<StandardDialogComponentComponent>,
    @Inject(MAT_DIALOG_DATA) public data: StandardDialogData
  ) {
  }

  onCloseClick(): void {
    this.dialogRef.close();
  }

}
