<inthraction-page-header pageTitle="Organization Experience Report" downloadBtn="true" (downloadBtnClickEvent)="downloadCSV()" [downloadBtnDisabled]="!dataSource.data.length"></inthraction-page-header>
<div class="chart" *ngIf="dataSource.data.length">
  <ngx-charts-line-chart
    [view]="view"
    [scheme]="colorScheme"
    [legend]="legend"
    [showXAxisLabel]="showXAxisLabel"
    [showYAxisLabel]="showYAxisLabel"
    [xAxis]="xAxis"
    [yAxis]="yAxis"
    [xAxisLabel]="xAxisLabel"
    [yAxisLabel]="yAxisLabel"
    [timeline]="timeline"
    [results]="multi"
    (select)="onSelect($event)"
  >
  </ngx-charts-line-chart>
</div>
<div [hidden]="!dataSource.data.length">
  <mat-table matSort [dataSource]="dataSource" class="mat-elevation-z8">
    <ng-container matColumnDef="experienceName">
      <mat-header-cell mat-sort-header *matHeaderCellDef sortingDataAccessor>Experience</mat-header-cell>
      <mat-cell *matCellDef="let element"> {{element.experienceName}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="manager">
      <mat-header-cell mat-sort-header *matHeaderCellDef sortingDataAccessor>Manager</mat-header-cell>
      <mat-cell *matCellDef="let element"> {{element.manager?.firstName}} {{element.manager?.lastName}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="name">
      <mat-header-cell mat-sort-header *matHeaderCellDef sortingDataAccessor>Name</mat-header-cell>
      <mat-cell *matCellDef="let element"> {{element.employee.firstName}} {{element.employee.lastName}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="date">
      <mat-header-cell mat-sort-header *matHeaderCellDef sortingDataAccessor>Date</mat-header-cell>
      <mat-cell *matCellDef="let element"> {{element.name}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="score">
      <mat-header-cell mat-sort-header *matHeaderCellDef sortingDataAccessor>Score</mat-header-cell>
      <mat-cell *matCellDef="let element"> {{element.value}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="response">
      <mat-header-cell mat-sort-header *matHeaderCellDef sortingDataAccessor>Response</mat-header-cell>
      <mat-cell *matCellDef="let element"> {{element.response}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="comment">
      <mat-header-cell *matHeaderCellDef [ngClass]="'comment-cells'">Comments</mat-header-cell>
      <mat-cell *matCellDef="let element" [ngClass]="'comment-cells'"> {{element.comment}} </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;" [ngClass]="selectedRowIndex == row.surveyID? 'highlight': ''"></mat-row>
  </mat-table>
</div>

<inthraction-loading className="wrapper" [initDone]="dataSource.data.length > 0 || noResults"></inthraction-loading>
<div *ngIf="noResults" class="no-records">
  <h3>Sorry, there were no records found.</h3>
</div>
