import { environment } from "../environments/environment";
import { SentryErrorHandler } from "./sentry-error-handler";
import { ErrorHandler } from "@angular/core";

export function errorHandlerFactory() {
  if (environment.production || environment.environmentName == "test") {
    return new SentryErrorHandler();
  }
  return new ErrorHandler();
}
