<h1 mat-dialog-title>Stand-Up History</h1>
<ng-container *ngIf="initialized">
  <mat-toolbar>
    <span>{{selectedSurveyDate | amParse:'YYYY-MM-DDTHH:mm:ssZ' | amDateFormat:'dddd, MMMM Do YYYY'}}</span>
    <span class="spacer"></span>
    <button mat-icon-button class="" (click)="moveSurvey('up', index)" type="button" title="Previous" [disabled]="index == 0">
      <mat-icon>keyboard_arrow_up</mat-icon>
    </button>
    <button mat-icon-button class="" (click)="moveSurvey('down', index)" type="button" title="Next" [disabled]="index == surveyDates.length-1">
      <mat-icon>keyboard_arrow_down</mat-icon>
    </button>
  </mat-toolbar>
  <ng-container class="standup-card">
    <mat-card-content class="card-wrapper">
      <ng-container *ngFor="let survey of surveysByDateMap.get(selectedSurveyDate)">
        <inthraction-stand-up-survey-display [survey]="survey" [hideAvatar]="true" [user]="employee" [note]="notesMap.get(survey.id)"></inthraction-stand-up-survey-display>
      </ng-container>
    </mat-card-content>
  </ng-container>
</ng-container>
<inthraction-loading className="" [initDone]="initialized"></inthraction-loading>
