import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { CADENCE_TYPE_LABELS, OBJECTIVE_TYPE_LABELS } from "@inthraction/labels";
import { CadenceObjectiveAssignment, Employee, ExternalCadenceObjectiveAssignment } from "@inthraction/data-models";
import { MatDialog } from "@angular/material/dialog";
import { ObjectiveResponseComponent } from "../objective-response/objectiveResponse.component";
import { EmployeeService } from "@inthraction/services";
import { ObjectiveScoreChartOptions } from "../objective-score-chart/objective-score-chart.component";
import * as moment from "moment";

@Component({
  selector: "inthraction-cadence-objective",
  templateUrl: "./cadenceObjective.component.html",
  styleUrls: ["./cadenceObjective.component.scss"]
})
export class CadenceObjectiveComponent implements OnInit {

  @Input() objectiveAssignment: CadenceObjectiveAssignment | ExternalCadenceObjectiveAssignment;
  @Input() employee: Employee;
  @Input() options: CadenceObjectiveOptions = {};
  @Output() openEdit: EventEmitter<any> = new EventEmitter();

  readonly objectiveCadenceTypeLabels = CADENCE_TYPE_LABELS;
  readonly OBJECTIVE_TYPE_LABELS = OBJECTIVE_TYPE_LABELS;
  expired: boolean = false;

  constructor(
    public dialog: MatDialog,
    private employeeService: EmployeeService
  ) {
  }

  ngOnInit(): void {
    if (this.objectiveAssignment?.endDate && this.objectiveAssignment?.endDate < moment().format("YYYY-MM-DD")) {
      this.expired = true;
    }
  }

  openEditClick(editId: string): void {
    this.openEdit.emit(editId);
  }

  async openObjectiveSurveyResponse(objectiveResponseID: any, employeeID: any) {
    this.dialog.open(ObjectiveResponseComponent, {
      width: "600px",
      data: {
        objectiveResponseID,
        employee: employeeID ? await this.employeeService.getEmployeeByIDMemoize(employeeID) : this.employee
      }
    });
  }
}

export interface CadenceObjectiveOptions extends ObjectiveScoreChartOptions {
  managerAccess?: boolean;
  hideChart?: boolean;
}
