<div *ngIf="survey" class="insphration-results-container">
  <h1 mat-dialog-title>insp<span class="inthraction-highlight">HR</span>ation</h1>
  <mat-dialog-content>
    <div class="row space">
      <div class="column center small-photo">
        <inthraction-profile-image [employeeImage]="respondentImage"></inthraction-profile-image>
        <h3>{{respondent.firstName}} {{respondent.lastName}}</h3>
      </div>
      <div class="row center arrow">
        <mat-icon>forward</mat-icon>
      </div>
      <div class="column center small-photo">
        <inthraction-profile-image [employeeImage]="participantImage"></inthraction-profile-image>
        <h3>{{participant.firstName}} {{participant.lastName}}</h3>
      </div>
    </div>
    <div class="row room">
      <inthraction-employee-insphration [inspHRation]="inspHRation"></inthraction-employee-insphration>
      <mat-form-field class="full-width">
        <mat-label>Description</mat-label>
        <textarea matInput readonly [value]="inspHRation.description" cdkTextareaAutosize></textarea>
      </mat-form-field>
    </div>
    <mat-form-field class="full-width">
      <mat-label>Feedback</mat-label>
      <textarea matInput readonly [value]="survey.feedback" cdkTextareaAutosize></textarea>
    </mat-form-field>
    <span class="event-date">{{ survey.responseReceivedDate | amParse:'YYYY-MM-DDTHH:mm:ssZ' | amDateFormat:'ll'}}</span>
  </mat-dialog-content>
</div>

<inthraction-loading className="insphration-results-container" [initDone]="(survey ? true : false)"></inthraction-loading>
