<inthraction-page-header pageTitle="Readiness Report" downloadBtn="true" (downloadBtnClickEvent)="downloadCSV()" [downloadBtnDisabled]="!tableData.data.length">
  <mat-form-field class="no-subscript-wrapper">
    <mat-label>Filter</mat-label>
    <input matInput (keyup)="applyFilter($event)" [placeholder]="'Ex. ' + (filterExample || 'Ready Now')" #input>
  </mat-form-field>
</inthraction-page-header>
<div class="wrapper mat-elevation-z8" [hidden]="!tableData.data.length">
  <table mat-table matSort cdkDropList [dataSource]="tableData"
         multiTemplateDataRows
         cdkDropListOrientation="horizontal"
         (cdkDropListDropped)="drop($event)">
    <ng-container matColumnDef="department">
      <th mat-header-cell mat-sort-header cdkDrag *matHeaderCellDef sortActionDescription="Sort by Department"> Department</th>
      <td mat-cell *matCellDef="let element"> {{element.department}} </td>
    </ng-container>

    <ng-container matColumnDef="manager">
      <th mat-header-cell mat-sort-header cdkDrag *matHeaderCellDef sortActionDescription="Sort by Manager"> Manager</th>
      <td mat-cell *matCellDef="let element"> {{element.manager}} </td>
    </ng-container>

    <ng-container matColumnDef="name">
      <th mat-header-cell mat-sort-header cdkDrag *matHeaderCellDef sortActionDescription="Sort by Name"> Name</th>
      <td mat-cell *matCellDef="let element"> {{element.name}} </td>
    </ng-container>

    <ng-container matColumnDef="currentReadiness">
      <th mat-header-cell mat-sort-header cdkDrag *matHeaderCellDef sortActionDescription="Sort by Current Readiness"> Current Readiness</th>
      <td mat-cell *matCellDef="let element"> {{element.currentReadiness}} </td>
    </ng-container>

    <ng-container matColumnDef="currentReadinessDate">
      <th mat-header-cell mat-sort-header cdkDrag *matHeaderCellDef sortActionDescription="Sort by Current Readiness Date"> Current Readiness Date</th>
      <td mat-cell *matCellDef="let element"> {{element.currentReadinessDate ? (element.currentReadinessDate | amParse: 'YYYY-MM-DDTHH:mm:ssZ' | amDateFormat:'ll') : ''}} </td>
    </ng-container>

    <ng-container matColumnDef="previousReadiness">
      <th mat-header-cell mat-sort-header cdkDrag *matHeaderCellDef sortActionDescription="Sort by Previous Readiness"> Previous Readiness</th>
      <td mat-cell *matCellDef="let element"> {{element.previousReadiness}} </td>
    </ng-container>

    <ng-container matColumnDef="previousReadinessDate">
      <th mat-header-cell mat-sort-header cdkDrag *matHeaderCellDef sortActionDescription="Sort by Previous Readiness Date"> Previous Readiness Date</th>
      <td mat-cell *matCellDef="let element"> {{element.previousReadinessDate ? (element.previousReadinessDate | amParse: 'YYYY-MM-DDTHH:mm:ssZ' | amDateFormat:'ll') : ''}} </td>
    </ng-container>

    <ng-container matColumnDef="daysInPosition">
      <th mat-header-cell mat-sort-header cdkDrag *matHeaderCellDef sortActionDescription="Sort by Days in Position"> Days in Position</th>
      <td mat-cell *matCellDef="let element"> {{element.daysInPosition}} </td>
    </ng-container>

    <ng-container matColumnDef="last1on1Date">
      <th mat-header-cell mat-sort-header cdkDrag *matHeaderCellDef sortActionDescription="Sort by Last One on One Date"> Last One on One Date</th>
      <td mat-cell *matCellDef="let element"> {{element.last1on1Date ? (element.last1on1Date | amParse: 'YYYY-MM-DDTHH:mm:ssZ' | amDateFormat:'ll') : ''}} </td>
    </ng-container>

    <ng-container matColumnDef="criticalPosition">
      <th mat-header-cell mat-sort-header cdkDrag *matHeaderCellDef sortActionDescription="Sort by Critical Position"> Critical Position</th>
      <td mat-cell *matCellDef="let element"> {{element.criticalPosition ? 'Yes' : 'No'}} </td>
    </ng-container>

    <ng-container matColumnDef="totalPerformanceScore">
      <th mat-header-cell mat-sort-header cdkDrag *matHeaderCellDef sortActionDescription="Sort by Total Performance Score"> Total Performance Score</th>
      <td mat-cell *matCellDef="let element"> {{element.totalPerformanceScore}} </td>
    </ng-container>

    <ng-container matColumnDef="inthractionScore">
      <th mat-header-cell mat-sort-header cdkDrag *matHeaderCellDef sortActionDescription="Sort by intHRaction Score"> intHRaction Score</th>
      <td mat-cell *matCellDef="let element"> {{element.inthractionScore}} </td>
    </ng-container>

    <ng-container matColumnDef="objectiveScore">
      <th mat-header-cell mat-sort-header cdkDrag *matHeaderCellDef sortActionDescription="Sort by ObjectiveScore Score"> ObjectiveScore Score</th>
      <td mat-cell *matCellDef="let element"> {{element.objectiveScore}} </td>
    </ng-container>

    <ng-container matColumnDef="expandedDetail">
      <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
        <div [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'" class="element-detail">
          <mat-form-field class="view-width" *ngIf="element.currentReadinessComments">
            <mat-label>Current Readiness Comments</mat-label>
            <textarea matInput readonly [value]="element.currentReadinessComments" cdkTextareaAutosize></textarea>
          </mat-form-field>
          <mat-form-field class="view-width" *ngIf="element.currentReadinessOpportunity1">
            <mat-label>Current Opportunity 1</mat-label>
            <textarea matInput readonly [value]="element.currentReadinessOpportunity1" cdkTextareaAutosize></textarea>
          </mat-form-field>
          <mat-form-field class="view-width" *ngIf="element.currentReadinessOpportunity2">
            <mat-label>Current Opportunity 2</mat-label>
            <textarea matInput readonly [value]="element.currentReadinessOpportunity2" cdkTextareaAutosize></textarea>
          </mat-form-field>
          <mat-form-field class="view-width" *ngIf="element.currentReadinessOpportunity3">
            <mat-label>Current Opportunity 3</mat-label>
            <textarea matInput readonly [value]="element.currentReadinessOpportunity3" cdkTextareaAutosize></textarea>
          </mat-form-field>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let element; columns: displayedColumns;" [class.clickable]="element.currentReadinessComments || element.currentReadinessOpportunity1" [class.expanded-row]="expandedElement === element" (click)="expandedElement = expandedElement === element ? null : element" class="element-row"></tr>
    <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="detail-row"></tr>
  </table>
</div>

<inthraction-loading className="wrapper" [initDone]="tableData.data.length > 0"></inthraction-loading>
