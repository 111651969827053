export const DEPARTMENT_TYPE_LABELS = {
  MARKETING: "Sales & Marketing",
  OPERATIONS: "Operations",
  IT: "Information Technology",
  FINANCIAL: "Financial",
  HR: "Human Resources",
  EXECUTIVE: "Executive Leadership"
};
export const ROLE_TYPE_LABELS = {
  HR: "Human Resources",
  ADMIN: "System Administrator",
  PM: "Project Manager"
} as const;

export const WORK_STATUS_TYPE_LABELS = {
  OFFICE: "Full Time Office",
  REMOTE: "Full Time Remote",
  HYBRID: "Hybrid Office/Remote"
};
export const SHORT_WORK_STATUS_TYPE_LABELS = {
  OFFICE: "Office",
  REMOTE: "Remote",
  HYBRID: "Hybrid"
};
