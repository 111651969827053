<h1 mat-dialog-title>Calendar Access Authorization</h1>
<div mat-dialog-content>
  <div *ngIf="!code">
    When you click the "Authorize" button below you will be redirected to the Calendar Authorization Portal. Once you
    complete the steps to authorize intHRaction to access your calendar you will be redirected back to the intHRaction
    application.
  </div>
  <div></div>
</div>
<div mat-dialog-actions>
  <button (click)="redirectToAuthorization()" *ngIf="!code" color="primary" mat-button>Authorize</button>
</div>
