import { Component } from "@angular/core";
import { environment } from "../../../../../environments/environment";
import { AuthenticatorService } from "@aws-amplify/ui-angular";

@Component({
  selector: "inthraction-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.scss"]
})
export class FooterComponent {

  today: number = Date.now();
  public readonly version: string = environment.version;

  constructor(
    public authenticator: AuthenticatorService
  ) {
  }

}
