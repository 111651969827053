import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from "@angular/router";
import { AuthService } from "@inthraction/services";

@Injectable({
  providedIn: "root"
})
export class UnauthGuard implements CanActivate {
  constructor(
    private router: Router,
    private authService: AuthService
  ) {
  }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    let securityUser = this.authService.currentUser;
    if (!securityUser) {
      securityUser = await this.authService.currentAuthenticatedUser(true);
      if (securityUser) {
        this.authService.currentUser = securityUser;
      }
    }
    if (!securityUser) {
      return true;
    } else { // Authenticated, redirect to dashboard
      return this.router.parseUrl("/dashboard");
    }
  }


}
