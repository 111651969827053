<ng-template [cdkPortalOutlet]="selectedPortal"></ng-template>

<ng-template #favoritesContent>
  <inthraction-page-header [pageTitle]="organization?.orgName + ' Favorites'"></inthraction-page-header>
  <inthraction-consultant-favorites [organizationID]="organization.id" [consultantFavorites]="consultantFavorites"></inthraction-consultant-favorites>
</ng-template>

<ng-template #workforceContent>
  <inthraction-page-header [pageTitle]="organization?.orgName + ' Workforce'">
    <div class="search-wrapper no-subscript-wrapper" *ngIf="organization">
      <inthraction-employee-search [options]="{clearAfterSelect: true, organizationID: organization.id}" (employeeSelected)="searchEmployee($event)"></inthraction-employee-search>
    </div>
  </inthraction-page-header>
  <inthraction-help-bubble [message]="CONSULTANT_EMPLOYEES_HELP" title="Workforce" tip="Workforce Help" class="zero-top zero-right"></inthraction-help-bubble>
  <inthraction-team-view-component
    *ngIf="topEmployees && topEmployees.length > 0"
    [employees]="topEmployees"
    (selectedEmployeeID)="employeeChanged($event)"
    [navToEmployeeID]="navToEmployeeID"
    [employeeCardOptions]="{showCriticalPosition:false, showReadiness:false, showEdit:true, showOpenIntHRactionCount:true, showFavoriteToggle:true, favorites:favorites, showOrgNav:true}"
    (employeeEditClickEvent)="hrEditEmployee($event)"
    (profileImageClickEvent)="hrEditEmployee($event)"
    (favoriteClickEvent)="favoriteToggle($event)"
  >
  </inthraction-team-view-component>
</ng-template>

<ng-template #projectsContent>
  <inthraction-project-dashboard [organizationID]="organization.id" [mode]="ProjectDashboardMode.CONSULTANT"></inthraction-project-dashboard>
</ng-template>

<ng-template #standupsContent>
  <inthraction-consultant-stand-up [standupConfigurations]="standupConfigrations"></inthraction-consultant-stand-up>
</ng-template>

<ng-template #objectivesAssignmentsReportContent>
  <inthraction-employee-objective-assignments></inthraction-employee-objective-assignments>
</ng-template>
