import { Component, Inject } from '@angular/core';
import { QRType, QRTypes } from '@inthraction/codes';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ObjectiveService } from '@inthraction/services';
import { Objective } from '@inthraction/data-models';
import { MatButton } from '@angular/material/button';
import { ToastrService } from 'ngx-toastr';
import { QR } from '@inthraction/data-mappers';

@Component({
  selector: 'inthraction-add-qr-code-dialog',
  templateUrl: './add-qr-code-dialog.component.html',
  styleUrls: ['./add-qr-code-dialog.component.scss']
})
export class AddQrCodeDialogComponent {

  qrForm: FormGroup;
  addMode = true;
  objective: Objective;

  protected readonly QRTypes = QRTypes;

  private qrTypeFC = new FormControl("", [Validators.required]);
  private qrImageSrcFC= new FormControl("", [Validators.required]);
  private qrTitleFC= new FormControl("", [Validators.required]);
  private qrLabelFC= new FormControl("", [Validators.required]);
  private qrDataFC= new FormControl("", [Validators.required]);

  constructor(
    public dialogRef: MatDialogRef<AddQrCodeDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private objectiveService: ObjectiveService,
    private toastr: ToastrService
  ) {
    this.qrForm = new FormGroup({
      qrType: this.qrTypeFC,
      qrTitle: this.qrTitleFC,
      qrImageSrc: this.qrImageSrcFC,
      qrLabel: this.qrLabelFC,
      qrData: this.qrDataFC
    });

    this.objective = JSON.parse((JSON.stringify(data.objective)));
    if(!this.objective.qrCodes?.length) {
      this.objective.qrCodes = [];
    }
    // if (data.addMode) {
    //   this.addMode = true;
    // } else {
    //   this.addMode = false;
    //   //TODO Edit Mode: Populate form with data
    // }

  }

  onCancelClick(): void {
    this.dialogRef.close(null);
  }

  public hasError = (controlName: string, errorName: string) =>
    this.qrForm.controls[controlName].hasError(errorName);

  async onSaveClick(formValue: any, submitBtn: MatButton | HTMLButtonElement): Promise<void> {
    submitBtn.disabled = true;

    if(!this.objective.qrCodes) {
      this.objective.qrCodes = [];
    }
    this.objective.qrCodes.push({
      type: QRType[formValue.qrType],
      title: formValue.qrTitle,
      label: formValue.qrLabel,
      imageSrc: formValue.qrImageSrc,
      qrdata: formValue.qrData
    } as QR);

    try {
      let objectiveResult: Objective;
      // if (this.addMode) {
        objectiveResult = await this.objectiveService.updateObjective(this.objective);
        this.toastr.success("QR Code added to Objective");
      // }
      this.dialogRef.close({ objective: objectiveResult });
    } catch (err) {
      submitBtn.disabled = !this.qrForm.valid;
      console.error(err);
      this.toastr.error("Unable to Add QR Code to Objective");
      throw err;
    }

  }

}
