import { Component } from '@angular/core';
import {
  SideNavDrawerMenuOption,
  SideNavDrawerMenuOptionType
} from "../../../components/shared/side-nav-drawer/side-nav-drawer.component";

@Component({
  selector: 'app-hr-dashboard',
  templateUrl: './hr-dashboard.component.html',
  styleUrls: ['./hr-dashboard.component.scss']
})
export class HrDashboardComponent {

  static EMPLOYEE_ID_PARAMETER = "employee-id";

  menuOptions: SideNavDrawerMenuOption[] = [
    {
      type: SideNavDrawerMenuOptionType.LINK,
      routerLink: 'favorites',
      title: 'Favorites',
      icon: 'star'
    },
    {
      type: SideNavDrawerMenuOptionType.LINK,
      routerLink: 'workforce',
      title: 'Workforce',
      icon: 'person'
    },
    {
      type: SideNavDrawerMenuOptionType.LINK,
      routerLink: 'objective-distribution',
      title: 'Objective Distribution',
      icon: 'domain'
    },
    {
      type: SideNavDrawerMenuOptionType.LINK,
      routerLink: 'objective-response-distribution',
      title: 'Objective Response Distribution',
      icon: 'dataset'
    },
    {
      type: SideNavDrawerMenuOptionType.LINK,
      routerLink: 'objective-assignments-report',
      title: 'Objective Assignments',
      icon: 'patient_list'
    },
    {
      type: SideNavDrawerMenuOptionType.LINK,
      routerLink: 'readiness-report',
      title: 'Readiness',
      icon: 'question_exchange'
    },
    {
      type: SideNavDrawerMenuOptionType.LINK,
      routerLink: 'experience-report',
      title: 'Experience',
      icon: 'data_thresholding'
    }

  ]

}
