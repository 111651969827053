import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";
import { Employee } from "@inthraction/data-models";
import { EmployeeService } from "@inthraction/services";
import { EmployeeCardOptions } from "../employee-card-component/employee-card-component.component";

@Component({
  selector: "inthraction-team-view-component",
  templateUrl: "./team-view-component.component.html",
  styleUrls: ["./team-view-component.component.scss"]
})
export class TeamViewComponentComponent implements OnInit, OnChanges {

  @Input()
  set navToEmployeeID(id: string) {
    this._navToEmployeeID = id;
  }

  get navToEmployeeID(): string {
    return this._navToEmployeeID;
  }

  constructor(
    private employeeService: EmployeeService) {
  }


  get employee() {
    return this._employee;
  }

  set employee(employee: Employee) {
    this._employee = employee;
    if (this._employee) {
      this.selectedEmployeeID.emit(this._employee.id);
    } else {
      this.selectedEmployeeID.emit("");
    }
  }

  @Input() employees: Employee[];
  @Input() employeeCardOptions: EmployeeCardOptions;

  _navToEmployeeID: string;

  @Output() selectedEmployeeID = new EventEmitter<string>();
  @Output() employeeEditClickEvent = new EventEmitter<Employee>();
  @Output() profileImageClickEvent = new EventEmitter<Employee>();
  @Output() favoriteClickEvent = new EventEmitter<Employee>();
  @Output() showObjectiveResponseDistributionsClickEvent = new EventEmitter<Employee>();

  topEmployeeIDs: string[] = [];
  private _employee: Employee;
  parent: Employee;
  private _subordinates: Employee[];
  breakpoint: number;
  Object = Object;


  private static calcBreakpoint(width: number): number {
    const bp = Math.floor(width / 435);
    return bp ? bp : 1;
  }

  async ngOnChanges(changes: SimpleChanges) {
    if (changes.navToEmployeeID) {
      const id = changes.navToEmployeeID.currentValue;
      if (id) {
        const employee = await this.employeeService.getEmployeeByIDMemoize(id);
        if (this.topEmployeeIDs.includes(employee.id)) {
          this.employee = employee;
          this._subordinates = await this.employeeService.getSubordinatesByEmployeeIDForOrganization({managerID:employee.id, memoize:true});
        } else {
          const parent = await this.employeeService.getEmployeeByIDMemoize(employee.managerID);
          this._subordinates = await this.employeeService.getSubordinatesByEmployeeIDForOrganization({managerID:employee.id, memoize: true});
          if (this._subordinates?.length > 0) {
            this.employee = employee;
            this.parent = parent;
          } else {
            this.employee = parent;
            this._subordinates = await this.employeeService.getSubordinatesByEmployeeIDForOrganization({managerID:parent?.id, memoize:true});
            this.parent = await this.employeeService.getEmployeeByIDMemoize(parent.managerID);
          }
        }
      }
    }
  }

  get subordinates(): Employee[] {
    return this._subordinates?.sort((a, b) => (a.department + a.lastName + a.firstName).localeCompare(b.department + b.lastName + b.firstName));
  }

  get sortedEmployees(): Employee[] {
    return this.employees?.sort((a, b) => (a.department + a.lastName + a.firstName).localeCompare(b.department + b.lastName + b.firstName));
  }

  async ngOnInit() {

    for (const topEmployee of this.employees) {
      this.topEmployeeIDs.push(topEmployee.id);
    }

    if (this.employees.length === 1) {
      this.employee = this.employees[0];
      if (this.employeeCardOptions.showOrgNav) {
        this._subordinates = await this.employeeService.getSubordinatesByEmployeeIDForOrganization({managerID: this.employee.id, memoize:true});
      }
    }
  }

  async loadPrevious() {
    if (this.parent) {
      this.employee = this.parent;
      this._subordinates = await this.employeeService.getSubordinatesByEmployeeIDForOrganization({managerID:this.parent.id, memoize:true});
      if (this.parent.managerID) {
        this.parent = await this.employeeService.getEmployeeByIDMemoize(this.parent.managerID);
      } else {
        this.parent = null;
      }
    } else {
      this.employee = null;
      this._subordinates = null;
      this.parent = null;
    }
  }

  async loadNext(employee: Employee) {
    this._subordinates = await this.employeeService.getSubordinatesByEmployeeIDForOrganization({managerID:employee.id, memoize: true});
    if (this._subordinates?.length > 0) {
      this.parent = this.employee;
      this.employee = employee;
    }
  }

  async loadParent(employee: Employee) {
    this.employee = employee;
    this.parent = null;
    this._subordinates = await this.employeeService.getSubordinatesByEmployeeIDForOrganization({managerID:employee.id, memoize:true});
  }

  onResize(entry: ResizeObserverEntry | any) {
    if (entry) {
      this.breakpoint = TeamViewComponentComponent.calcBreakpoint(entry.contentRect.width);
    }
  }

}
