<mat-card appearance="outlined" class="dashboard-employee-info-card">
  <mat-card-content>
    <div class="card-flex">
      <button class="fav-btn" mat-icon-button *ngIf="options.showFavoriteToggle" (click)="options.isFavorite = !options.isFavorite; favoriteClickEvent.emit(employee)" [title]="options.isFavorite ? 'Remove from Favorites' : 'Add to Favorites'">
        <mat-icon [class]="options.isFavorite ? 'fav-icon' : 'fav-icon hollow'" [svgIcon]="'star'"></mat-icon>
      </button>
      <inthraction-profile-image [employeeImage]="employeeImage" (click)="profileImageClickEvent.emit(employee)" class="compact w80"></inthraction-profile-image>
      <inthraction-profile-image [employeeImage]="employeeImage" (click)="profileImageClickEvent.emit(employee)" class="fullsize"></inthraction-profile-image>
      <div class="employee-info">
        <mat-card-title class="employee-name">{{employee.firstName}}&nbsp;{{employee.lastName}}</mat-card-title>
        <hr *ngIf="options.showCalendarToggle" [color]="getEmployeeColor(employee)" class="employee-color-bar">
        <mat-card-subtitle>{{employee.title}}</mat-card-subtitle>
        <mat-card-subtitle *ngIf="employee.positionDate">{{daysInPosition}} Days In Position</mat-card-subtitle>
        <mat-card-subtitle *ngIf="!options.hideEmail" title="email: {{employee.email}}" class="email">{{employee.email}}</mat-card-subtitle>
        <mat-card-subtitle *ngIf="employee.hireDate" class="fullsize">Hire Date: {{employee.hireDate | amParse:'YYYY-MM-DD' | amDateFormat:'ll'}}</mat-card-subtitle>
        <mat-card-subtitle *ngIf="employee.hireDate" class="compact">Hire Date: {{employee.hireDate | amParse:'YYYY-MM-DD' | amDateFormat:'l'}}</mat-card-subtitle>
        <mat-card-subtitle *ngIf="employee.lastAnnual" class="fullsize">Last Annual Review: {{employee.lastAnnual | amParse:'YYYY-MM-DD' | amDateFormat:'ll'}}</mat-card-subtitle>
        <mat-card-subtitle *ngIf="employee.lastAnnual" class="compact">Last Annual Review: {{employee.lastAnnual | amParse:'YYYY-MM-DD' | amDateFormat:'l'}}</mat-card-subtitle>
        <mat-card-subtitle *ngIf="employee.lastOneOnOne" class="fullsize">Last One-On-One: {{employee.lastOneOnOne | amParse:'YYYY-MM-DD' | amDateFormat:'ll'}} - {{daysSinceLastOneOnOne}} Days</mat-card-subtitle>
        <mat-card-subtitle *ngIf="employee.lastOneOnOne" class="compact">Last 1-On-1: {{employee.lastOneOnOne | amParse:'YYYY-MM-DD' | amDateFormat:'l'}} - {{daysSinceLastOneOnOne}} Days</mat-card-subtitle>
        <mat-card-subtitle *ngIf="options.showOpenIntHRactionCount">Open intHRactions: {{openIntHRactionsCount}}</mat-card-subtitle>
      </div>
      <div class="indicators-right">
        <inthraction-score-card *ngIf="totalPerformanceScore && totalPerformanceScore > 0 && !(options?.endingScore && options?.startingScore && options?.endingScore > 0 && options?.startingScore > 0)" [score]="totalPerformanceScore" scale="6" subTitle="Personal"></inthraction-score-card>
        <div *ngIf="options?.endingScore && options?.startingScore && options?.endingScore > 0 && options?.startingScore > 0" class="trend-scores-wrapper">
          <inthraction-score-card [score]="options.startingScore" scale="6" class="starting-score"></inthraction-score-card>
          <mat-icon *ngIf="options.startingScore > options.endingScore" style="color: red" class="trend-icon">trending_down</mat-icon>
          <mat-icon *ngIf="options.startingScore == options.endingScore" class="trend-icon">trending_flat</mat-icon>
          <mat-icon *ngIf="options.startingScore < options.endingScore" style="color: green" class="trend-icon">trending_up</mat-icon>
          <inthraction-score-card [score]="options.endingScore" scale="6" class="ending-score"></inthraction-score-card>
        </div>
        <inthraction-score-card *ngIf="subordinates?.length > 0 && ytdTeamScore && ytdTeamScore > 0" [score]="ytdTeamScore" scale="6" subTitle="Team"></inthraction-score-card>
        <div *ngIf="relocate" title="Willing to Relocate" [class]="'boxStyle ' + (relocate == 'yes' ? 'green' : 'red')"><span>Relo: {{relocate == 'yes' ? 'Y' : 'N'}}</span></div>
        <div *ngIf="workStatus" title="Work Status" class="boxStyle"><span class="black">{{SHORT_WORK_STATUS_TYPE_LABELS[workStatus]}}</span></div>
      </div>
    </div>
  </mat-card-content>
  <mat-card-actions *ngIf="options.showEdit || options.showOrgNav || options.showReadiness|| options.showCalendarToggle">
    <button *ngIf="options.showEdit" mat-icon-button (click)="editClickEvent.emit(employee)" class="org-nav-button left" title="Edit">
      <mat-icon>edit</mat-icon>
    </button>
    <ng-container *ngIf="options.showCriticalPosition">
      <mat-slide-toggle [disabled]="options.showEdit" (change)="setCriticalPosition($event)" [(ngModel)]="employee.criticalPosition">Critical Position</mat-slide-toggle>
    </ng-container>
    <button *ngIf="options.showRatesOthersChart" mat-raised-button (click)="showRatesOthersChartClickEvent.emit(employee)">Rates Others</button>
    <button *ngIf="options.showIntHRactionsChart" mat-raised-button (click)="showIntHRactionsChartClickEvent.emit(employee)">int<span class="inthraction-highlight" style="vertical-align: unset;">HR</span>actions</button>
    <!-- Split Actions Here -->

    <mat-slide-toggle *ngIf="options.showCalendarToggle" (change)="calendarToggleEvent.emit({employee: employee, checked: $event.checked})" [checked]="true" [id]="'toggle-'+ color?.replace('#', '')" title="Show/Hide on team calendar"></mat-slide-toggle>
    <div *ngIf="options.showReadiness && employee.readiness" title="Readiness" [class]="'right boxStyle readiness '+employeeReadiness">
      <inthraction-help-bubble [message]="READINESS_HELP" title="Readiness" tip="Readiness Help"></inthraction-help-bubble>
      <span>{{readinessLabels[employeeReadiness]}}</span>
    </div>
    <div *ngIf="options.showReadiness && !employee.readiness" style="visibility: hidden;" class="right boxStyle readiness">
      <span></span>
    </div>
    <button mat-icon-button *ngIf="options.showOrgNav && !options.disableOrgNav && subordinates?.length > 0" (click)="loadSubordinates(employee)" class="org-nav-button" title="Show direct reports">
      <mat-icon>keyboard_arrow_down</mat-icon>
    </button>
    <button mat-icon-button *ngIf="options.showOrgNav && (options.disableOrgNav || !subordinates || subordinates.length <= 0)" class="org-nav-button" style="visibility: hidden;" disabled>
      <mat-icon>keyboard_arrow_down</mat-icon>
    </button>
  </mat-card-actions>
</mat-card>
