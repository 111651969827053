import { Injectable } from "@angular/core";
import { CanActivate, Router } from "@angular/router";
import { Employee } from "../../data-models/employee/employee";
import { EmployeeService } from "../../services/employee.service";

@Injectable({
  providedIn: "root"
})
export class ManagerUserGuard implements CanActivate {

  constructor(
    private router: Router,
    private employeeService: EmployeeService
  ) {
  }

  canActivate(): Promise<boolean> {
    return new Promise(async (resolve) => {
      const user: Employee = await this.employeeService.getCurrentEmployee();
      const subordinates = await this.employeeService.getSubordinatesByEmployeeIDForOrganization({managerID: user?.id, memoize:true});
      if (user && subordinates?.length > 0) {
        resolve(true);
      } else {
        this.router.navigate(["/dashboard"]);
        resolve(false);
      }
    });
  }
}
