import {
  ProjectDataMapper,
  ProjectMilestoneAssignmentDataMapper,
  ProjectMilestoneCommentDataMapper
} from "@inthraction/data-mappers";
import { MilestoneType } from "@inthraction/codes";
import { CreateProjectMilestoneAssignmentMutation, GetProjectMilestoneAssignmentQuery } from "@inthraction/services";

export interface Project extends ProjectDataMapper {
  organizationID: string;
  projectManagerID: string;
  status?: string;
}

export class ProjectMilestoneAssignment implements ProjectMilestoneAssignmentDataMapper {
  milestoneType: MilestoneType | string;
  assignedBy: string;
  assignmentDate: string;
  comments?: MilestoneComment[];
  cadence: string;
  description: string;
  endDate: string;
  id: string;
  nextSurveyDate: string;
  organizationID: string;
  projectID: string;
  startDate: string;
  team: string[];
  title: string;
  updatedAt?: string;

  constructor() {
  }

  static constructFromCreateProjectMilestoneAssignmentMutation(createProjectMilestoneAssignmentMutation: CreateProjectMilestoneAssignmentMutation | GetProjectMilestoneAssignmentQuery): ProjectMilestoneAssignment {
    const projectMilestoneAssignment = new ProjectMilestoneAssignment();
    projectMilestoneAssignment.id = createProjectMilestoneAssignmentMutation.id;
    projectMilestoneAssignment.organizationID = createProjectMilestoneAssignmentMutation.organizationID;
    projectMilestoneAssignment.comments = createProjectMilestoneAssignmentMutation.comments.items.sort((b, a) => a.createdAt < b.createdAt ? -1 : a.createdAt > b.createdAt ? 1 : 0);
    projectMilestoneAssignment.projectID = createProjectMilestoneAssignmentMutation.projectID;
    projectMilestoneAssignment.assignmentDate = createProjectMilestoneAssignmentMutation.assignmentDate;
    projectMilestoneAssignment.assignedBy = createProjectMilestoneAssignmentMutation.assignedBy;
    projectMilestoneAssignment.cadence = createProjectMilestoneAssignmentMutation.cadence;
    projectMilestoneAssignment.description = createProjectMilestoneAssignmentMutation.description;
    projectMilestoneAssignment.endDate = createProjectMilestoneAssignmentMutation.endDate;
    projectMilestoneAssignment.nextSurveyDate = createProjectMilestoneAssignmentMutation.nextSurveyDate;
    projectMilestoneAssignment.startDate = createProjectMilestoneAssignmentMutation.startDate;
    projectMilestoneAssignment.team = createProjectMilestoneAssignmentMutation.team;
    projectMilestoneAssignment.updatedAt = createProjectMilestoneAssignmentMutation.updatedAt;
    projectMilestoneAssignment.title = createProjectMilestoneAssignmentMutation.title;
    projectMilestoneAssignment.milestoneType = createProjectMilestoneAssignmentMutation.milestoneType;

    return projectMilestoneAssignment;
  }


}


export interface MilestoneComment extends ProjectMilestoneCommentDataMapper {
}
