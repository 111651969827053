<mat-card appearance="outlined" class="progress-check-card">
  <mat-card-subtitle>Progress Check</mat-card-subtitle>
  <mat-card-content>
    <div>
      <div class="half-width left">
        <mat-radio-group readonly class="progress-radio-group">
          <mat-radio-button *ngFor="let statusValue of progressStatusValues" [checked]="statusValue === progressCheckData?.statusCode" [disabled]="statusValue != progressCheckData?.statusCode" [value]="statusValue">{{progressStatusLabels[statusValue]}}</mat-radio-button>
        </mat-radio-group>
      </div>
    </div>
    <div class="full-width">
      <mat-form-field class="full-width">
        <textarea matInput cdkTextareaAutosize readonly [value]="progressCheckData?.note"></textarea>
      </mat-form-field>
    </div>
  </mat-card-content>
</mat-card>
