import { Component, OnInit } from "@angular/core";
import {
  SideNavDrawerMenuOption,
  SideNavDrawerMenuOptionType
} from "../../../components/shared/side-nav-drawer/side-nav-drawer.component";
import { EmployeeService, OrganizationService } from "@inthraction/services";
import { Organization } from "@inthraction/data-models";

@Component({
  selector: 'app-consultant-routing-dashboard',
  templateUrl: './consultant-routing-dashboard.component.html',
  styleUrls: ['./consultant-routing-dashboard.component.scss']
})
export class ConsultantRoutingDashboardComponent implements OnInit {

  static organizationIDParameterName = "organization-id";
  static employeeIDParameterName = "employee-id";

  menuOptions: SideNavDrawerMenuOption[] = []

  consultantOrganizations: Organization[];

  constructor(
    private employeeService: EmployeeService,
    private organizationService: OrganizationService
  ) {}

  async ngOnInit() {
    const employee = await this.employeeService.getCurrentEmployee();
    const consultantOrganizations: Organization[] = [];
    const associations = await this.employeeService.getConsultantAssociationsByEmployee(employee.id, false);
    for await (const association of associations) {
      const organization = await this.organizationService.getOrganizationByIDMemoize(association.organizationID);
      consultantOrganizations.push(organization);
    }
    consultantOrganizations.sort((a,b) => a.orgName.localeCompare(b.orgName));
    this.consultantOrganizations = consultantOrganizations;
    consultantOrganizations.forEach(organization => this.setMenuOptions(organization));
    this.menuOptions.push(...[
      {
        type: SideNavDrawerMenuOptionType.DIVIDER,
        title: "divider"
      },
      {
        type: SideNavDrawerMenuOptionType.LINK,
        routerLink: "objective-configuration",
        title: "Objective Configuration",
        icon: "domain_add"
      }
    ]);
  }

  private setMenuOptions(organization: Organization) {
    this.menuOptions.push({
      type: SideNavDrawerMenuOptionType.GROUP,
      title: organization.orgName,
      children: [
      {
        type: SideNavDrawerMenuOptionType.LINK,
        routerLink: `${organization.id}/favorites`,
        title: `${organization.orgName} Favorites`,
        label: "Favorites",
        icon: "star",
        child: true
      },
      {
        type: SideNavDrawerMenuOptionType.LINK,
        routerLink: `${organization.id}/workforce`,
        title: `${organization.orgName} Workforce`,
        label: "Workforce",
        icon: "person",
        child: true
      },
      {
        type: SideNavDrawerMenuOptionType.LINK,
        routerLink: `${organization.id}/project`,
        title: `${organization.orgName} Projects`,
        label: "Projects",
        icon: "domain",
        child: true
      },
      {
        type: SideNavDrawerMenuOptionType.LINK,
        routerLink: `${organization.id}/objective-assignments-report`,
        title: `${organization.orgName} Objective Assignments`,
        label: "Objective Assignments",
        icon: "patient_list",
        child: true
      },
      {
        type: SideNavDrawerMenuOptionType.LINK,
        routerLink: `${organization.id}/standups`,
        title: `${organization.orgName} Stand-Up Surveys`,
        label: "Stand-Up Surveys",
        icon: "calendar_clock",
        child: true
      }
    ]});
  }
}
