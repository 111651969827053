<div class="survey-page-container insphration-survey-form">
  <div></div>
  <mat-card appearance="outlined" *ngIf="survey">
    <form (ngSubmit)="submitSurvey(surveyForm.value)" [formGroup]="surveyForm" autocomplete="off" novalidate>
      <mat-card-content>
        <mat-card-title>Give an insp<span class="inthraction-highlight">HR</span>ation</mat-card-title>
        <div class="full-width">
          <div class="half-width left clear-left search-wrapper no-subscript-wrapper">
            <inthraction-employee-search [options]="{clearAfterSelect: true}" (employeeSelected)="searchEmployee($event)"></inthraction-employee-search>
            <mat-error *ngIf="hasError('participantEmail', 'required')">Email is required</mat-error>
            <mat-error *ngIf="hasError('participantEmail', 'email')">Must be a valid email address</mat-error>
            <mat-error *ngIf="hasError('participantEmail','notFound')">Email address was not found.</mat-error>
            <mat-error *ngIf="hasError('participantEmail', 'notSelf')">Nominee must not be yourself.</mat-error>
          </div>
          <div class="half-width right participant-container">
            <ng-container *ngIf="participant">
              <inthraction-profile-image [employeeImage]="participantImage"></inthraction-profile-image>
              <mat-card-subtitle>{{participant.firstName}} {{participant.lastName}}</mat-card-subtitle>
            </ng-container>
          </div>
        </div>
        <div class="full-width">
          <div class="half-width left clear-left">
            <mat-form-field class="full-width">
              <mat-label>insp<span class="inthraction-highlight">HR</span>ation Type</mat-label>
              <mat-select formControlName="surveyValue">
                <mat-select-trigger>
                  <inthraction-employee-insphration *ngIf="surveyForm.get('surveyValue').value" [inspHRation]="surveyForm.get('surveyValue').value" horizontal center></inthraction-employee-insphration>
                </mat-select-trigger>
                <mat-option *ngFor="let shoutOutType of organizationInspHRations" [value]="shoutOutType" matTooltip="{{shoutOutType.description}}">
                  <inthraction-employee-insphration [inspHRation]="shoutOutType" horizontal></inthraction-employee-insphration>
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="half-width right inspHRation-details">
            <ng-container *ngIf="surveyForm.get('surveyValue').value">
              <mat-form-field class="full-width">
                <mat-label>Description</mat-label>
                <textarea matInput readonly [value]="surveyForm.get('surveyValue').value.description" cdkTextareaAutosize></textarea>
              </mat-form-field>
              <div class="attributes-wrapper">
                <ng-container *ngFor="let attribute of surveyForm.get('surveyValue').value.shoutOutType.attributes; let i = index">
                  <span *ngIf="!surveyForm.get('surveyValue').value.disabledAttributes?.includes(i)">{{attribute}}</span>
                </ng-container>
                <span *ngFor="let attribute of surveyForm.get('surveyValue').value.organizationAttributes">{{attribute}}</span>
              </div>
            </ng-container>
          </div>
        </div>
        <mat-form-field class="full-width">
          <mat-label>Feedback</mat-label>
          <textarea matInput formControlName="feedback" id="feedback" maxlength="500" name="Feedback" rows="3"></textarea>
          <mat-error *ngIf="hasError('feedback', 'required')">Feedback is required</mat-error>
        </mat-form-field>
      </mat-card-content>
      <mat-card-actions>
        <button type="submit" [disabled]="!surveyForm.valid" color="primary" mat-raised-button>Submit</button>
        <button type="button" mat-button (click)="onCancelClick()">Cancel</button>
      </mat-card-actions>
    </form>
  </mat-card>
  <div></div>
</div>
